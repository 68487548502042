import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import CreateJiraIssueModal from './CreateJiraIssueModal'
import fromIntegrationJira from '../../../store/integrations/jira/integrations.jira.selectors'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import useLocalStorage from '../../hooks/useLocalStorage'
import useJira from '../../hooks/useJira'
import api from '../../../api'

const CreateJiraIssueModalContainer = ({ ...props }) => {
  const projects = useSelector(fromIntegrationJira.getJiraProjects)
  const issueTypes = useSelector(fromIntegrationJira.getJiraIssueTypes)
  const requiredFields = useSelector(fromIntegrationJira.getJiraRequiredFields)

  const [jiraProjectId, saveJiraProjectId] = useLocalStorage(
    'jira-issue-last-project-id',
    ''
  )

  const dispatch = useDispatch()
  const { onCreateJiraIssue } = useJira()

  const [projectsWithAvatar, setProjectsWithAvatar] = useState(projects)
  async function fetchProjectAvatars() {
    const updatedProjects = await Promise.all(
      projects.map(async project => {
        const response = await api.getJiraAvatar(project.avatarUrl)
        if (response?.data?.image) {
          return {
            ...project,
            avatarBase64: response.data.image,
            avatarContentType: response.data.contentType,
          }
        }
        return project
      })
    )
    setProjectsWithAvatar(updatedProjects)
  }

  useEffect(() => {
    fetchProjectAvatars()
  }, [])

  return (
    <CreateJiraIssueModal
      {...props}
      projects={projectsWithAvatar}
      issueTypes={issueTypes}
      requiredFields={requiredFields}
      initialJiraProjectId={jiraProjectId}
      onProjectChange={selectedProject => {
        saveJiraProjectId(selectedProject.id)
      }}
      onCreate={values =>
        onCreateJiraIssue(values).then(jiraIssue =>
          dispatch(
            addNotification({
              notificationId: uuidv1(),
              text: 'Jira issue created',
              isPersisted: true,
              callToAction: {
                type: 'link',
                link: jiraIssue?.data?.url,
                text: jiraIssue?.data?.key,
              },
            })
          )
        )
      }
    />
  )
}

export default connect(
  null,
  null
)(CreateJiraIssueModalContainer)
