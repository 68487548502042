import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LG, MD } from '@zendeskgarden/react-typography'
import { Field, Label, Radio } from '@zendeskgarden/react-forms'
import { NOTIFICATIONS } from './NotificationConstants'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)``

const StyledTitle = styled(LG)`
  padding: 16px;
`

const Paragraph = styled(MD)`
  padding: 0 16px 16px 16px;
`

const Row = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 16px;
  margin-left: 16px;
  text-align: center;
  align-items: flex-start;
`

const NotificationSetting = ({ value, onChange, ...props }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const onChangeHandler = useCallback(e => {
    onChange(e.target.value)
    setCurrentValue(e.target.value)
  }, [])
  return (
    <Wrapper {...props}>
      <StyledTitle>Email notifications</StyledTitle>
      <Paragraph>
        Configure the frequency in which comments and mentions are sent to your
        inbox.
      </Paragraph>
      <Row>
        <Field>
          <Radio
            name="notifications"
            value={NOTIFICATIONS.instant}
            onChange={onChangeHandler}
            checked={currentValue === NOTIFICATIONS.instant}
          >
            <Label regular>Instant</Label>
          </Radio>
        </Field>
      </Row>
      <Row>
        <Field>
          <Radio
            name="notifications"
            value={NOTIFICATIONS.never}
            onChange={onChangeHandler}
            checked={currentValue === NOTIFICATIONS.never}
          >
            <Label regular>Never</Label>
          </Radio>
        </Field>
      </Row>
    </Wrapper>
  )
}

NotificationSetting.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(Object.values(NOTIFICATIONS)),
}

NotificationSetting.defaultProps = {
  onChange: () => {},
  value: '',
}

export default NotificationSetting
