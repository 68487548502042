import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import CloseIcon from '../../../atoms/Icons/navigation/close.svg'
import Flex from '../../../atoms/Flex'
import OnboardingMobile from '../../../organisms/OnboardingProxy/OnboardingMobile'
import MobileModal from '../../../molecules/MobileModal'
import MobileTopBar from '../../../molecules/MobileTopBar'
import MobileTemplate from '../../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../../templates/MobileTemplate/MobileTemplate'
import RegistrationFormContainer from './RegistrationFormContainer'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: ${prop('theme.style.colors.grey500')};
`

const RegistrationModalMobile = ({ onClose }) => {
  const [isOnboardingVisible, setOnboardingVisible] = useState(false)
  const onModalClose = useCallback(() => {
    if (isOnboardingVisible) {
      return null
    }
    onClose()
    return true
  }, [isOnboardingVisible])

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        rightIcon={!isOnboardingVisible ? CloseIcon : null}
        onRightButtonClick={onModalClose}
      >
        Sign in to leave unlimited feedback for free
      </StyledMobileTopBar>
    ),
    [isOnboardingVisible]
  )

  const body = useMemo(
    () => (
      <Body>
        {!isOnboardingVisible && (
          <RegistrationFormContainer
            onComplete={() => setOnboardingVisible(true)}
          />
        )}
        {isOnboardingVisible && <OnboardingMobile />}
      </Body>
    ),
    [isOnboardingVisible]
  )

  return (
    <MobileModal>
      <MobileTemplate header={header} body={body} footer={null} />
    </MobileModal>
  )
}

RegistrationModalMobile.propTypes = {
  onClose: PropTypes.func,
}

RegistrationModalMobile.defaultProps = {
  onClose: () => {},
}

export default RegistrationModalMobile
