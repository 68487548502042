import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import { useHistory } from 'react-router-dom'
import api from '../../../api'
import SessionViewMobileNew from './SessionViewMobileNew'
import useSessionView from './useSessionView'
import useCRUDSession from '../../../store/hooks/useCRUDSession'
import useCanAddCommentsToSession from '../../hooks/useCanAddCommentsToSession'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import { getFolderPathName } from '../../../util/paths'
import { PAGE_EVENT_TYPES } from '../PageEvents/pageEvents.constants'

const SessionViewMobileNewContainer = ({
  isEditMode,
  onExit,
  readOnly,
  isStandalone,
  isAnonymous,
  initialCommentId,
  ...props
}) => {
  const {
    areCommentsAllowed,
    comments,
    deleteSessionButtonNode,
    dispatchCreateReply,
    dispatchDeleteComment,
    getCommentsDispatch,
    getVideoInfo,
    isUserOwnSession,
    onAnonymourReplyFocus,
    onAnonymousVideoClick,
    onResolveCommentClick,
    renameSessionButtonNode,
    session,
    sessionId,
    sessionTitle,
    setVideoDuration,
    setVideoUrl,
    sprites,
    userCanContribute,
    userIdentityId,
    userName,
    videoDuration,
    videoUrl,
    pageEvents,
    sessionCreatorUserName,
    sessionCreatorPictureUrl,
  } = useSessionView({ isEditMode, onExit, isAnonymous })

  const filteredPageEvents = pageEvents.filter(pageEvent =>
    [PAGE_EVENT_TYPES.navigation, PAGE_EVENT_TYPES.click].includes(
      pageEvent.type
    )
  )

  const history = useHistory()
  const onBack = () => history.push(getFolderPathName(session?.projectId))

  const { onUpdateSessionHandler, onDeleteSessionHandler } = useCRUDSession(
    sessionId
  )

  const [isVideoEncodingOnTheCloud, setIsVideoEncodingOnTheCloud] = useState(
    false
  )
  const [isVideoMissing, setVideoMissing] = useState(false)

  const [videoWidth, setVideoWidth] = useState(0)
  const [videoHeight, setVideoHeight] = useState(0)

  useEffect(() => {
    const getRecordingFn = isAnonymous
      ? api.getPublicRecording
      : api.getRecording

    getRecordingFn(sessionId).then(({ data }) => {
      const url = get(data, 'url')
      if (url) {
        getVideoInfo(url).then(info => {
          setVideoDuration(info.duration)
          setVideoWidth(info.width)
          setVideoHeight(info.height)
          setVideoUrl(url)
        })
      }

      if (get(data, 'result') === 'polling') {
        setIsVideoEncodingOnTheCloud(true)
      }
      if (get(data, 'result') === 'no_video_available') {
        if (!isUserOwnSession) {
          setVideoMissing(true)
        }
      }
    })
  }, [])

  let isVideoEncodingOnTheCloudInterval = -1
  useEffect(() => {
    if (isVideoEncodingOnTheCloud) {
      isVideoEncodingOnTheCloudInterval = setInterval(() => {
        const getRecordingFn = isAnonymous
          ? api.getPublicRecording
          : api.getRecording
        console.log('>>> get recording')
        getRecordingFn(sessionId).then(({ data }) => {
          console.log('>>> get recording response', data)
          const url = get(data, 'url')
          if (url) {
            getVideoInfo(url).then(info => {
              setVideoDuration(info.duration)
              setIsVideoEncodingOnTheCloud(false)
              setVideoWidth(info.width)
              setVideoHeight(info.height)
              setVideoUrl(url)
              clearInterval(isVideoEncodingOnTheCloudInterval)
            })
          }
        })
      }, 2000)
    }
  }, [isVideoEncodingOnTheCloud])

  const userCanAddCommentsToSession = useCanAddCommentsToSession()

  const dispatch = useDispatch()
  const showCopySnackbar = () =>
    dispatch(
      addNotification({
        notificationId: uuidv1(),
        text: 'Link copied to clipboard',
      })
    )

  return (
    <SessionViewMobileNew
      {...props}
      areCommentsAllowed={areCommentsAllowed}
      comments={comments}
      deleteSessionButtonNode={deleteSessionButtonNode}
      dispatchCreateReply={dispatchCreateReply}
      dispatchDeleteComment={dispatchDeleteComment}
      getCommentsDispatch={getCommentsDispatch}
      initialCommentId={initialCommentId}
      isAnonymous={isAnonymous}
      isUserOwnSession={isUserOwnSession}
      isVideoEncodingOnTheCloud={isVideoEncodingOnTheCloud}
      isVideoMissing={isVideoMissing}
      onAnonymourReplyFocus={onAnonymourReplyFocus}
      onAnonymousVideoClick={onAnonymousVideoClick}
      onBack={onBack}
      onDeleteSessionHandler={onDeleteSessionHandler}
      onResolveCommentClick={onResolveCommentClick}
      onUpdateSessionHandler={onUpdateSessionHandler}
      pageEvents={filteredPageEvents}
      renameSessionButtonNode={renameSessionButtonNode}
      session={session}
      sessionCreatorPictureUrl={sessionCreatorPictureUrl}
      sessionCreatorUserName={sessionCreatorUserName}
      sessionId={sessionId}
      sessionTitle={sessionTitle}
      showCopySnackbar={showCopySnackbar}
      sprites={sprites}
      userCanAddCommentsToSession={userCanAddCommentsToSession}
      userCanContribute={userCanContribute}
      userIdentityId={userIdentityId}
      userName={userName}
      videoDuration={videoDuration}
      videoHeight={videoHeight}
      videoUrl={videoUrl}
      videoWidth={videoWidth}
    />
  )
}

SessionViewMobileNewContainer.propTypes = {
  isEditMode: PropTypes.bool,
  onExit: PropTypes.func,
  readOnly: PropTypes.bool,
  isStandalone: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  initialCommentId: PropTypes.string,
}

SessionViewMobileNewContainer.defaultProps = {
  isEditMode: false,
  onExit: () => {},
  readOnly: true,
  isStandalone: false,
  isAnonymous: true,
  initialCommentId: '',
}

export default SessionViewMobileNewContainer
