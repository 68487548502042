import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useUserInfo from '../../store/hooks/useUserInfo'
import fromSessions from '../../store/sessions/sessions.selectors'
import fromProjects from '../../store/projects/projects.selectors'
import fromOrganizations from '../../store/organizations/organizations.selectors'
import { cleanUserIdentityId } from '../../util/utils'
import { SHARE_TYPES } from '../molecules/ProjectShareModal/ProjectShare.constants'

const useCanAddCommentsToSession = () => {
  const session = useSelector(fromSessions.getCurrentSession)
  const project = useSelector(fromProjects.getCurrentProject)
  const organization = useSelector(fromOrganizations.getCurrentOrganization)
  const { userIdentityId, userEmail, isLogged } = useUserInfo()
  const [canAddComments, setCanAddComments] = useState(false)
  useEffect(() => {
    const cleanedUserIdentityId = cleanUserIdentityId(userIdentityId)

    const isSessionPublic = session?.sharing?.isPublic
    const areCommentsAllowed = session?.sharing?.areCommentsAllowed

    const sharingRule = isSessionPublic && areCommentsAllowed

    const isRemoteTestRecording = !!session?.remoteTestId

    if (session?.projectId && session?.projectId !== project?.pk) {
      setCanAddComments(sharingRule)
      return
    }

    const isProjectOwner =
      project?.createdBy && project?.createdBy === cleanedUserIdentityId

    const isProjectAdministrator = (project?.admin || []).includes(userEmail)

    const isProjectEditor = (project?.edit || []).includes(userEmail)

    const isSessionOwner =
      session?.owner?.pk &&
      cleanUserIdentityId(session?.owner?.pk) === cleanedUserIdentityId
    const isSessionAdministrator = (session?.admin || []).includes(userEmail)
    const isSessionEditor = (session?.edit || []).includes(userEmail)

    const isProjectSharedWithTheWholeOrganization =
      project?.shareType === SHARE_TYPES.anyone

    const organizationMembers = (organization?.members || []).concat(
      organization?.admins || []
    )
    const isCurrentUserOrganizationMember = organizationMembers.includes(
      userIdentityId
    )

    setCanAddComments(
      sharingRule ||
        isProjectOwner ||
        isProjectAdministrator ||
        isProjectEditor ||
        isSessionOwner ||
        isSessionAdministrator ||
        isSessionEditor ||
        (isRemoteTestRecording && organization?.pk === 'personal') ||
        (isLogged &&
          isProjectSharedWithTheWholeOrganization &&
          isCurrentUserOrganizationMember)
    )
  }, [session, userIdentityId, userEmail, organization, isLogged])

  return canAddComments
}

export default useCanAddCommentsToSession
