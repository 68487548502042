import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import Flex from '../../../atoms/Flex'
import UserAvatar from '../../../atoms/UserAvatar'
import Anchor from '../../../atoms/Anchor'
import theme from '../../../theme/old'
import { ternary } from '../../../../util/utils'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  max-width: 553px;
  width: 100%;
`

const Title = styled.h3`
  margin-bottom: 16px;
`

const Subtitle = styled.h5`
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
`

const StyledAnchor = styled(Anchor)`
  cursor: pointer;
`

const Tile = styled(Flex)`
  position: relative;
  width: 100%;
  align-self: center;
  border: 2px solid grey;
  border-color: ${({ isSelected, theme: _theme }) =>
    isSelected ? `${_theme.colors.primary} !important` : _theme.colors.grey200};
  box-sizing: border-box;

  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;

  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;

  align-items: center;
  justify-content: center;
  cursor: ${ternary('isInvited')('default', 'pointer')};

  :hover {
    border-color: ${prop('theme.colors.grey300')};
  }
`

const TileIcon = styled(Flex)`
  width: 48px;
  height: auto;

  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`

const TileContent = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 32px;
  flex-direction: row;
`

const TileDescription = styled(Flex)`
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${prop('primary400')};
  text-align: left;
  flex-direction: row;
`

const StyledUserAvatar = styled(UserAvatar).attrs({
  size: 'large',
})`
  user-select: none;
  cursor: pointer;

  && figure,
  img {
    border-radius: 6px !important;
  }
`

const UserRoleMobile = ({
  workspaces,
  onNext,
  onCreateNewWorkspace,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>Join your teammates on iterspace</Title>
        <Subtitle>
          You have been invited to these workspaces. Select one, or create a new
          workspace
        </Subtitle>
      </CenteredBox>
      <CenteredBox>
        {workspaces.map(workspace => {
          const workspaceId = workspace.pk
          return (
            <Tile key={workspaceId} onClick={() => onNext(workspaceId)}>
              <TileIcon>
                <StyledUserAvatar
                  name={workspace.title}
                  size="small"
                  imgUrl={workspace.pictureUrl}
                  backgroundColor={workspace.backgroundColor}
                />
              </TileIcon>
              <TileContent>
                <TileDescription>
                  <span>Open</span>&nbsp;
                  <b>{workspace.title}</b>
                </TileDescription>
              </TileContent>
              <ArrowRightIcon size="24" color={theme.colors.primary} />
            </Tile>
          )
        })}
      </CenteredBox>
      <CenteredBox direction="row">
        or&nbsp;
        <StyledAnchor onClick={onCreateNewWorkspace}>
          Create a new workspace
        </StyledAnchor>
      </CenteredBox>
    </Wrapper>
  )
}

UserRoleMobile.propTypes = {
  workspaces: PropTypes.arrayOf(PropTypes.shape({})),
  onNext: PropTypes.func,
  onCreateNewWorkspace: PropTypes.func,
}

UserRoleMobile.defaultProps = {
  workspaces: [],
  onNext: () => {},
  onCreateNewWorkspace: () => {},
}

export default UserRoleMobile
