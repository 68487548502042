import React from 'react'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import Card from '../Card'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  padding: 16px 16px 10px 16px;
  transition: background-color 0.3s;
  position: relative;
  margin: 0 0 16px 0;
`

const SingleSkeleton = () => (
  <ContentLoader
    speed={2}
    width={320}
    height={48}
    viewBox="0 0 320 48"
    backgroundColor="#e0e3e6"
    foregroundColor="#ffffff"
  >
    <rect x="5" y="61" rx="2" ry="2" width="84" height="56" />
    <circle cx="104" cy="99" r="6" />
    <rect x="117" y="94" rx="0" ry="0" width="39" height="10" />
    <rect x="50" y="178" rx="0" ry="0" width="162" height="92" />
    <rect x="0" y="0" rx="2" ry="2" width="72" height="40" />
    <rect x="87" y="6" rx="0" ry="0" width="148" height="8" />
    <circle cx="94" cy="30" r="7" />
    <rect x="110" y="26" rx="0" ry="0" width="35" height="8" />
  </ContentLoader>
)

const SessionSkeleton = props => (
  <>
    <StyledCard {...props}>
      <SingleSkeleton />
    </StyledCard>
    <StyledCard {...props}>
      <SingleSkeleton />
    </StyledCard>
    <StyledCard {...props}>
      <SingleSkeleton />
    </StyledCard>
  </>
)

export default SessionSkeleton
