import { GET_USERS_REQUEST_SUCCESS } from './users.actions'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import { buildReducerForActions } from '../../util/reduxTools'

export const STATE_KEY = 'users'

const INITIAL_STATE = {
  byId: {},
  byEmail: {},
}

const reducer = {
  [GET_USERS_REQUEST_SUCCESS]: (state, { payload: { users } }) => ({
    byId: {
      ...state.byId,
      ...users.reduce((acc, user) => {
        acc[user.pk.replace('user:', '')] = user
        return acc
      }, {}),
    },
    byEmail: {
      ...state.byEmail,
      ...users.reduce((acc, user) => {
        acc[user.email] = user
        return acc
      }, {}),
    },
  }),
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
