import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'

import { createSelector } from 'reselect'
import { STATE_KEY } from './reducers'

const getState = state => getImmutable(state, STATE_KEY)

const getDownloadProgress = createSelector(
  getState,
  get('downloadProgress')
)

const getUploadProgress = createSelector(
  getState,
  get('uploadProgress.progress')
)

const getUploadSessionId = createSelector(
  getState,
  get('uploadProgress.sessionId')
)

const getUploadVideoConversionProgress = createSelector(
  getState,
  get('uploadProgress.conversionProgress')
)

const getUploadVideoSpritesProgress = createSelector(
  getState,
  get('uploadProgress.spritesProgress')
)

export default {
  getDownloadProgress,
  getUploadProgress,
  getUploadSessionId,
  getUploadVideoConversionProgress,
  getUploadVideoSpritesProgress,
}
