import jwt from 'jsonwebtoken'
import axios from 'axios'
import get from 'lodash/get'
import pick from 'lodash/pick'
import last from 'lodash/last'
import { v1 as uuidv1 } from 'uuid'
import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import createActivityDetector from 'activity-detector'
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import Cache from '@aws-amplify/cache'
import gql from 'graphql-tag'
import { afterLogin } from '../graphql/mutations'
import appConfig from '../config'
import {
  REGISTRATION_REQUEST,
  VERIFICATION_REQUEST,
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  loginRequestSuccess,
  loginRequestFailure,
  logoutRequestSuccess,
  logoutRequestFailure,
  registrationRequestSuccess,
  registrationRequestFailure,
  activeUser,
  inactiveUser,
  START_APP_DOWNLOAD,
  updateDownloadProgress,
  DOWNLOAD_APP,
  UPLOAD_VIDEO_FILES,
  UPLOAD_SCREEN_RECORDING,
  UPLOAD_EXTENSION_RECORDING,
  resetUploadProgress,
  updateUploadProgress,
  AFTER_LOGIN,
} from './actions'
import {
  setUserData,
  setUserLogged,
  setRegistrationData,
} from './user/user.actions'
import { addNotification } from './ui-notifications/ui-notifications.actions'
import desktopSagas from '../desktop/store/sagas'
import localSessionsSagas from '../desktop/store/localSessions/localSessions.sagas'
import {
  createNewSessionRequest,
  CREATE_NEW_SESSION_REQUEST_SUCCESS,
  CREATE_NEW_SESSION_REQUEST_FAILURE,
  getSessionRequest,
  GET_SESSION_REQUEST_SUCCESS,
  GET_SESSION_REQUEST_FAILURE,
  DELETE_SESSION_REQUEST_SUCCESS,
  updateSessionRequest,
  deleteSessionRequest,
} from './sessions/sessions.actions'
import fromOrganizations from './organizations/organizations.selectors'
import fromProjects from './projects/projects.selectors'
import fromSessions from './sessions/sessions.selectors'
import fromSelectors from './selectors'
import { trackSessionCreate } from './analytics/analytics.actions'
import { getJWT, getIdentityId, setNotGoogleLogin } from '../util/auth'
import { getDefaultSessionTitle } from '../util/utils'
import { getRedirectUrl } from '../lib/urls'
import { SESSION_CREATE_ORIGIN } from '../lib/analytics'
import browserExtension from '../lib/browserExtension'
import MessageTypes from '../browser-extension/messageTypes'
import {
  createPendingUploads,
  deletePendingUploads,
  setPendingUploads,
} from './uploads/upload.actions'
import {
  UPLOAD_STATE_ERROR,
  UPLOAD_STATE_UPLOADED,
  UPLOAD_STATE_UPLOADING,
  UPLOAD_STATE_WAITING,
} from './uploads/upload.constants'

/* eslint-disable require-yield */
function* handleRegistration({
  payload: { name, surname, email, password },
  meta: { thunk },
}) {
  const channel = eventChannel(emitter => {
    Auth.configure(appConfig.AWS.Auth)
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        family_name: surname,
        // other custom attributes
      },
      validationData: [],
    })
      .then(() => {
        emitter({
          result: 'success',
        })
        emitter(END)
      })
      .catch(err => {
        console.error(err)
        emitter({
          result: 'error',
          error: err,
        })
        emitter(END)
      })

    return () => {}
  })

  const result = yield take(channel)
  if (result.result === 'success') {
    yield put(registrationRequestSuccess(thunk))
  } else {
    yield put(registrationRequestFailure({ error: result.error }, thunk))
  }
}

function* handleVerification({ payload: { email, code } }) {
  const poolData = {
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  }

  const userPool = new CognitoUserPool(poolData)
  const userData = {
    Username: email,
    Pool: userPool,
  }

  const cognitoUser = new CognitoUser(userData)

  cognitoUser.confirmRegistration(
    code,
    true,
    function confirmRegistrationCallback(err, result) {
      if (err) {
        console.error(err.message || JSON.stringify(err))
        return
      }
      console.log(`call result: ${result}`)
    }
  )
}

function* handleLogin({ payload: { email, password }, meta: { thunk } }) {
  const channel = eventChannel(emitter => {
    Auth.configure(appConfig.AWS.Auth)
    Auth.signIn(email, password)
      .then(user => {
        console.log(user)
        emitter({
          result: 'success',
        })
        emitter(END)
      })
      .catch(err => {
        console.error(err)
        if (err.code === 'UserNotConfirmedException') {
          //
        } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
        }

        emitter({
          result: 'error',
          error: err,
        })
        emitter(END)
      })

    return () => {}
  })
  const result = yield take(channel)
  if (result.result === 'success') {
    yield put(setUserLogged())
    yield put(
      loginRequestSuccess(
        {
          username: email,
          accessToken: result.accessToken,
          isCognitoUser: true,
        },
        thunk
      )
    )
  } else {
    yield put(loginRequestFailure({ error: result.error }, thunk))
  }
}

function* handleLogout({ meta: { thunk } }) {
  const getItemsToPreserve = exceptions => {
    const itemsToPreserve = []
    Object.keys(localStorage).forEach(key => {
      exceptions.forEach(exc => {
        if (key.startsWith(exc)) {
          itemsToPreserve.push({ key, value: localStorage.getItem(key) })
        }
      })
    })
    return itemsToPreserve
  }
  const restoreLocalStorageItems = itemsToPreserve => {
    itemsToPreserve.forEach(item => localStorage.setItem(item.key, item.value))
  }
  const clearStorageWithExceptions = exceptions => {
    const itemsToPreserve = getItemsToPreserve(exceptions)
    localStorage.clear()
    restoreLocalStorageItems(itemsToPreserve)
  }
  const channel = eventChannel(emitter => {
    Auth.configure(appConfig.AWS.Auth)
    Auth.signOut()
      .then(() => {
        emitter({
          result: 'success',
        })
        emitter(END)
      })
      .catch(err => {
        emitter({
          result: 'error',
          error: err,
        })
        emitter(END)
        console.error(err)
      })

    return () => {}
  })
  const result = yield take(channel)
  if (result.result === 'success') {
    Cache.clear()
    yield put(logoutRequestSuccess({ accessToken: result.accessToken }, thunk))
    setNotGoogleLogin()
    clearStorageWithExceptions(['HW_'])
    window.location.href = '/'
  } else {
    yield put(logoutRequestFailure({ error: result.error }, thunk))
  }
}

function* handleAfterLogin({ apolloClient }, { payload: { username } }) {
  try {
    const redirectUrl = getRedirectUrl(window.location.href)

    const userData = {}

    const jwtToken = yield call(getJWT)
    const tokenContent = jwt.decode(jwtToken)

    const tokenKeys = ['picture', 'name', 'email']

    const identityId = yield call(getIdentityId)
    userData.userIdentityId = identityId

    const _userData = pick(tokenContent, tokenKeys)
    yield put(
      setUserData({
        ..._userData,
        identityId,
      })
    )

    // eslint-disable-next-line no-undef
    if (window.FS) {
      // eslint-disable-next-line no-undef
      FS.identify(identityId, { ..._userData, appVersion: appConfig.version })
    }

    // if (!isCognitoUser) {
    try {
      yield call(apolloClient.mutate, {
        mutation: gql(afterLogin),
        variables: {
          email: username,
        },
      })
    } catch (err) {
      console.error(err)
    }
    // }

    const isLoginPath =
      window.location.pathname === '/login' || window.location.pathname === '/'

    if (redirectUrl) {
      window.location.href = redirectUrl
    }

    if (isLoginPath && !redirectUrl) {
      window.location.href = '/app'
    }

    yield put({
      type: AFTER_LOGIN,
    })
  } catch (err) {
    console.error(err)
    yield put({
      type: AFTER_LOGIN,
    })
  }
}

function* userInactivityMonitor() {
  const activityDetector = createActivityDetector()

  const channel = eventChannel(emitter => {
    activityDetector.on('idle', () => {
      emitter('inactive')
    })

    activityDetector.on('active', () => {
      emitter('active')
    })

    return () => {
      activityDetector.stop()
      emitter(END)
    }
  })

  while (true) {
    const result = yield take(channel)
    if (result === 'active') {
      yield put(activeUser())
    }
    if (result === 'inactive') {
      yield put(inactiveUser())
    }
  }
}

let downloadData = null
export function* startAppDownload({ payload: { os } }) {
  let url
  if (os === 'mac') {
    url = appConfig.appUrls.mac
  }
  const channel = eventChannel(emitter => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important
      onDownloadProgress: progressEvent => {
        if (progressEvent.lengthComputable) {
          const percentage = (100 / progressEvent.total) * progressEvent.loaded
          emitter({
            progress: percentage < 0 ? 0 : percentage,
          })
        }
      },
    })
      .then(response => {
        emitter({
          progress: 100,
          response,
        })
        emitter(END)
      })
      .catch(e => {
        console.error(e)
        emitter(END)
      })

    return () => {
      emitter(END)
    }
  })

  try {
    while (true) {
      const result = yield take(channel)
      if (result.progress) {
        yield put(updateDownloadProgress(result.progress))
      }
      if (result.progress && result.progress === 100 && result.response) {
        yield put(updateDownloadProgress(result.progress))
        downloadData = result.response.data
      }
    }
  } catch (err) {
    console.error(err)
  } finally {
    console.log('>>> download terminated')
  }
}

export function* downloadApp() {
  if (downloadData) {
    const _url = window.URL.createObjectURL(new Blob([downloadData]))
    const link = document.createElement('a')
    link.href = _url
    link.setAttribute('download', 'iterspace.dmg')
    document.body.appendChild(link)
    link.click()
  }
}

function* watchSessionFileProcessing(sessionId) {
  while (true) {
    const uploadSessionId = yield select(fromSelectors.getUploadSessionId)
    if (!uploadSessionId || uploadSessionId !== sessionId) {
      return
    }
    yield put(getSessionRequest(sessionId))
    const { success, failure } = yield race({
      success: take(GET_SESSION_REQUEST_SUCCESS),
      failure: take(GET_SESSION_REQUEST_FAILURE),
    })

    if (success) {
      const {
        payload: { session },
      } = success
      const videoConversionProgress =
        get(session, 'videoConversionProgress', 0) || 0
      const spritesCreationProgress =
        get(session, 'spritesCreationProgress', 0) || 0
      if (
        videoConversionProgress === 100 /* && spritesCreationProgress === 100 */
      ) {
        yield put(
          updateUploadProgress(
            100,
            sessionId,
            videoConversionProgress,
            spritesCreationProgress
          )
        )
        yield delay(5000)
        yield put(resetUploadProgress())
        break
      }
    }

    if (failure) {
      yield put(resetUploadProgress())
      break
    }

    yield delay(5000)
  }
}

function* uploadScreenRecording({ payload: { content, mimeType } }) {
  try {
    const organizationId = yield select(
      fromOrganizations.getCurrentOrganizationId
    )
    const projectId = yield select(fromProjects.getCurrentProjectId)
    const project = yield select(fromProjects.getCurrentProject)

    yield put(createNewSessionRequest())
    const { success, failure } = yield race({
      success: take(CREATE_NEW_SESSION_REQUEST_SUCCESS),
      failure: take(CREATE_NEW_SESSION_REQUEST_FAILURE),
    })

    if (failure) {
      // eslint-disable-next-line no-continue
      console.error('Create New Session failure', failure)
      return
    }

    yield put(
      trackSessionCreate({
        projectId,
        organizationId,
        origin: SESSION_CREATE_ORIGIN.screenRecording,
      })
    )

    const sessionId = get(success, 'payload.data.createSession.pk', '')
    if (sessionId) {
      const sessionTitle = getDefaultSessionTitle(project)
      yield put(
        updateSessionRequest({
          sessionId,
          title: sessionTitle,
        })
      )

      yield put(resetUploadProgress())

      const uploadConf = {
        level: 'private',
        content,
        contentType: mimeType,
        metadata: { sessionId, projectId },
      }

      const progressChannel = eventChannel(emitter => {
        uploadConf.progressCallback = progress => {
          console.log(`Video upload: ${progress.loaded}/${progress.total}`)
          emitter(progress)
        }
        return () => {}
      })

      const fileExtension = mimeType.includes('webm') ? 'webm' : 'mp4'
      const fileName = `${sessionId}.${fileExtension}`

      const uploadCompleteChannel = eventChannel(emitter => {
        Storage.put(fileName, content, uploadConf)
          .then(() => {
            emitter('ok')
          })
          .catch(console.error)
        return () => {}
      })

      while (true) {
        const { progress, complete, deleteSession } = yield race({
          progress: take(progressChannel),
          complete: take(uploadCompleteChannel),
          deleteSession: take(DELETE_SESSION_REQUEST_SUCCESS),
        })
        console.log('>>>', { progress, complete, deleteSession })
        if (
          deleteSession &&
          get(deleteSession, 'payload.sessionId') === sessionId
        ) {
          return
        }
        if (complete) {
          yield put(updateUploadProgress(100, sessionId))
          break
        }
        const percentage = Math.ceil((progress.loaded / progress.total) * 100)
        yield put(updateUploadProgress(percentage, sessionId))
      }

      console.log('>>> forking watchSessionFileProcessing')
      yield fork(watchSessionFileProcessing, sessionId)
    }
  } catch (err) {
    console.error(err)
  }
}

const sendExtensionUploadCompletedEvent = sessionId => {
  browserExtension.sendMessageToBackground({
    type: MessageTypes.UPLOAD_COMPLETED,
    payload: {
      sessionId,
    },
  })
}

const fetchBlob = blobUrl => fetch(blobUrl).then(response => response.blob())
const getBlobArrayBuffer = blob =>
  new Promise(resolve => blob.arrayBuffer().then(resolve))

function* uploadExtensionRecording({ payload: { blobUrl } }) {
  try {
    console.log('>>> uploadExtensionRecording')
    const videoBlob = yield call(fetchBlob, blobUrl)
    console.log('>>> videoBlob', videoBlob)

    const projectId = yield select(fromProjects.getCurrentProjectId)
    const sessionId = yield select(fromSessions.getCurrentSessionId)

    yield put(resetUploadProgress())

    const content = yield call(getBlobArrayBuffer, videoBlob)
    const mimeType = videoBlob.type

    const uploadConf = {
      level: 'private',
      content,
      contentType: mimeType,
      metadata: { sessionId, projectId },
    }

    const progressChannel = eventChannel(emitter => {
      uploadConf.progressCallback = progress => {
        console.log(`Video upload: ${progress.loaded}/${progress.total}`)
        emitter(progress)
      }
      return () => {}
    })

    const fileExtension = mimeType.includes('webm') ? 'webm' : 'mp4'
    const fileName = `${sessionId}.${fileExtension}`

    const uploadCompleteChannel = eventChannel(emitter => {
      Storage.put(fileName, content, uploadConf)
        .then(() => {
          emitter('ok')
        })
        .catch(console.error)
      return () => {}
    })

    while (true) {
      const { progress, complete, deleteSession } = yield race({
        progress: take(progressChannel),
        complete: take(uploadCompleteChannel),
        deleteSession: take(DELETE_SESSION_REQUEST_SUCCESS),
      })
      console.log('>>>', { progress, complete, deleteSession })
      if (
        deleteSession &&
        get(deleteSession, 'payload.sessionId') === sessionId
      ) {
        return
      }
      if (complete) {
        yield put(
          addNotification({
            notificationId: uuidv1(),
            text: 'Upload completed!',
          })
        )
        yield put(updateUploadProgress(100, sessionId))
        yield call(sendExtensionUploadCompletedEvent, sessionId)
        break
      }
      const percentage = Math.ceil((progress.loaded / progress.total) * 100)
      yield put(updateUploadProgress(percentage, sessionId))
    }

    console.log('>>> forking watchSessionFileProcessing')
    yield fork(watchSessionFileProcessing, sessionId)
  } catch (err) {
    console.error(err)
  }
}

const getUploadSize = value => {
  return value / 1e6
}

function* startUploadFile(sessionId, projectId, project, file) {
  let startTime
  let shouldUpdateSpeed
  let speed = 0

  const sessionTitle = getDefaultSessionTitle(project)
  yield put(
    updateSessionRequest({
      sessionId,
      title: sessionTitle,
    })
  )

  const channel = eventChannel(emitter => {
    const reader = new FileReader()
    reader.onload = evt => {
      emitter({ file, fileContent: evt.target.result })
      emitter(END)
    }
    reader.readAsArrayBuffer(file)
    return () => {}
  })
  const data = yield take(channel)
  console.log('>>> file', data.file)

  // This is different from resetPendingUploadProgress()
  // Leave it as it is due to design constraints
  // It reset the app.uploadProgress redux path.
  yield put(resetUploadProgress())

  const uploadConf = {
    level: 'private',
    content: data.fileContent,
    contentType: file.type,
    metadata: { sessionId, projectId },
  }

  // Upload video channels

  const progressChannel = eventChannel(emitter => {
    uploadConf.progressCallback = progress => {
      console.log(`Video upload: ${progress.loaded}/${progress.total}`)

      // If upload has started
      if (!shouldUpdateSpeed) {
        // Evaluate speed each second (ndr. it's an approximation)
        startTime = new Date()
        const current = new Date()
        shouldUpdateSpeed = current.setSeconds(current.getSeconds() + 1)
      }
      // If one second has passed
      else if (new Date() >= shouldUpdateSpeed) {
        // Evaluate next speed update
        const current = new Date()
        shouldUpdateSpeed = current.setSeconds(current.getSeconds() + 1)
        // Calculate time
        const elapsed = new Date(current - startTime).getSeconds()
        // Get bytes per second, then convert to megabits
        speed = (progress.loaded / elapsed) * 8e-6
      }

      emitter(progress)
    }
    return () => {}
  })

  const errorChannel = eventChannel(emitter => {
    uploadConf.errorCallback = err => {
      console.error('Unexpected error while uploading', err)
      emitter(err)
    }
    return () => {}
  })

  const uploadCompleteChannel = eventChannel(emitter => {
    uploadConf.completeCallback = event => {
      console.log(`Successfully uploaded ${event.key}`)
      emitter('ok')
    }
    return () => {}
  })

  const fileExtension = last(file.name.split('.')).toLowerCase()
  const fileName = `${sessionId}.${fileExtension}`

  Storage.put(fileName, data.fileContent, uploadConf)

  while (true) {
    const { progress, complete, deleteSession, error } = yield race({
      progress: take(progressChannel),
      error: take(errorChannel),
      complete: take(uploadCompleteChannel),
      deleteSession: take(DELETE_SESSION_REQUEST_SUCCESS),
    })
    console.log('>>>', { progress, complete, deleteSession })
    if (
      deleteSession &&
      get(deleteSession, 'payload.sessionId') === sessionId
    ) {
      yield put(deletePendingUploads(sessionId))
      return
    }
    if (progress.loaded === progress.total) {
      yield put(updateUploadProgress(100, sessionId))
      yield put(
        setPendingUploads(sessionId, {
          progress: 100,
          status: UPLOAD_STATE_UPLOADED,
          uploaded: getUploadSize(progress.total),
          speed: 0,
        })
      )
      yield put(
        addNotification({
          notificationId: uuidv1(),
          text: 'Upload completed!',
        })
      )
      break
    }

    const percentage = Math.ceil((progress.loaded / progress.total) * 100)
    yield put(updateUploadProgress(percentage, sessionId))
    yield put(
      setPendingUploads(sessionId, {
        progress: percentage,
        uploaded: getUploadSize(progress.loaded),
        speed,
      })
    )

    if (error) {
      console.log('>>> upload error', error)
      yield put(
        setPendingUploads(sessionId, { status: UPLOAD_STATE_ERROR, speed: 0 })
      )
      // Delete pending session request
      yield put(deleteSessionRequest(sessionId))
      yield put(
        addNotification({
          notificationId: uuidv1(),
          text: 'Upload failed!',
        })
      )
      break
    }
  }

  console.log('>>> forking watchSessionFileProcessing')
  yield fork(watchSessionFileProcessing, sessionId)
}

function* uploadVideoFiles({ payload: { files } }) {
  try {
    const organizationId = yield select(
      fromOrganizations.getCurrentOrganizationId
    )
    const projectId = yield select(fromProjects.getCurrentProjectId)
    const project = yield select(fromProjects.getCurrentProject)

    const sessions = []

    for (let index = 0; index < files.length; index += 1) {
      yield put(createNewSessionRequest())
      const { success, failure } = yield race({
        success: take(CREATE_NEW_SESSION_REQUEST_SUCCESS),
        failure: take(CREATE_NEW_SESSION_REQUEST_FAILURE),
      })
      if (failure) {
        // eslint-disable-next-line no-continue
        continue
      }

      yield put(
        trackSessionCreate({
          projectId,
          organizationId,
          origin: SESSION_CREATE_ORIGIN.uploadRecording,
        })
      )

      const sessionId = get(success, 'payload.data.createSession.pk', '')

      if (sessionId)
        sessions.push({ sessionId, projectId, project, file: files[index] })

      yield put(
        createPendingUploads(sessionId, {
          name: files[index].name,
          status: UPLOAD_STATE_WAITING,
          size: getUploadSize(files[index].size),
        })
      )
    }

    for (let index = 0; index < sessions.length; index += 1) {
      const obj = sessions[index]
      yield put(
        setPendingUploads(obj.sessionId, { status: UPLOAD_STATE_UPLOADING })
      )
      yield startUploadFile(obj.sessionId, obj.projectId, obj.project, obj.file)
    }
  } catch (err) {
    console.error(err)
  }
}

function* cleanUserRegistrationData() {
  yield put(setRegistrationData({}))
}

function* handleAfterLoginEvent() {
  const uploadSessionId = yield select(fromSelectors.getUploadSessionId)
  if (uploadSessionId) {
    yield fork(watchSessionFileProcessing, uploadSessionId)
  }

  yield delay(250)
  yield cleanUserRegistrationData()
}

export const loadSagas = () =>
  require.context('.', true, /\.\/.+\/.+\.sagas\.js$/)

const req = loadSagas()

const sagas = req.keys().map(key => req(key).default)

export default function* rootSaga(services = {}) {
  yield all([
    yield takeEvery(REGISTRATION_REQUEST, handleRegistration),
    yield takeEvery(VERIFICATION_REQUEST, handleVerification),
    yield takeEvery(LOGIN_REQUEST, handleLogin),
    yield takeEvery(LOGIN_REQUEST_SUCCESS, handleAfterLogin, services),
    yield takeEvery(AFTER_LOGIN, handleAfterLoginEvent),
    yield takeEvery(LOGOUT_REQUEST, handleLogout),
    yield takeEvery(START_APP_DOWNLOAD, startAppDownload),
    yield takeEvery(DOWNLOAD_APP, downloadApp),
    yield takeEvery(UPLOAD_VIDEO_FILES, uploadVideoFiles),
    yield takeEvery(UPLOAD_SCREEN_RECORDING, uploadScreenRecording),
    yield takeEvery(UPLOAD_EXTENSION_RECORDING, uploadExtensionRecording),
    yield fork(userInactivityMonitor),
    yield fork(desktopSagas),
    yield fork(localSessionsSagas),
    ...sagas.map(saga => fork(saga, services)),
  ])
}
