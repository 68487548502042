import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import prop from 'lodash/fp/prop'
import 'emoji-mart/css/emoji-mart.css'
import TextareaAutosize from 'react-textarea-autosize'
import AddCommentIcon from '../../atoms/Icons/editor/add_comment.svg'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
import EmojiButton from '../EmojiButton'
import useEmojiPicker from '../EmojiButton/useEmojiPicker'

const StyledCard = styled(Flex)`
  width: 100%;
  border-radius: 0;
  padding: 8px;
  z-index: 1;
  box-sizing: border-box;
  margin: 0;
`

const TextAreaWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0;
`

const StyledTextArea = styled(TextareaAutosize)`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  border-radius: 4px;
  border: none;
  display: flex;
  flex: 1 1 auto;
  font-size: 14px;
  resize: none;
  width: calc(100% - 32px);
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f8f9f9;

  &:focus {
    outline: none;
    background-color: ${prop('theme.colors.grey100')};
  }

  && {
    padding: 8px;
  }
`

const ButtonWrapper = styled.div`
  vertical-align: top;
  margin-right: 8px;
  width: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  align-items: flex-end;
`

const EmojiButtonFlex = styled(Flex)`
  margin: 0 4px 4px 4px;
`

const removeLineBreaks = string => string.replace(/(\r\n|\n|\r)/gm, '')

const CLASSNAME = 'editable-comment'

const EditableCommentMobile = ({
  startTime,
  inputRef,
  onInput,
  onReturn,
  showSubmitButton,
  showEmojiButton,
  textAreaProps,
  ...props
}) => {
  const [isSelected, setSelected] = useState(false)
  const [isEdited, setEdited] = useState(false)

  const clickListener = e => {
    if (
      e.path &&
      e.path.find &&
      !e.path.find(
        el =>
          el.className &&
          el.className.includes &&
          el.className.includes(CLASSNAME)
      )
    ) {
      setSelected(false)
    }
  }
  useEffect(() => {
    window.document.addEventListener('click', clickListener)
    return () => {
      window.document.removeEventListener('click', clickListener)
    }
  }, [])

  const {
    isEmojiPickerVisible,
    setEmojiPickerVisible,
    textareaCursorPosition,
    setTextareaCursorPosition,
    onTextKeyDown,
    insertEmoji,
  } = useEmojiPicker(inputRef)

  const onKeyDown = () => onTextKeyDown()

  const onSubmit = cb => {
    if (get(inputRef, 'current.value')) {
      cb(inputRef.current.value)
      // eslint-disable-next-line no-param-reassign
      inputRef.current.value = ''
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        inputRef.current.value = ''
      }, 100)
      // eslint-disable-next-line no-param-reassign
      inputRef.current.value = removeLineBreaks(inputRef.current.value)
    }
  }

  return (
    <StyledCard
      {...props}
      isSelected={isSelected}
      className={CLASSNAME}
      onClick={() => setSelected(true)}
    >
      <TextAreaWrapper>
        <StyledTextArea
          inputRef={inputRef}
          placeholder={textAreaProps?.placeholder || 'Write a message...'}
          onKeyUp={onKeyDown}
          onMouseUp={onKeyDown}
          onInput={() => {
            onInput(removeLineBreaks(inputRef.current.value))
            setEdited(inputRef.current.value !== '')
          }}
          onChange={() => {
            onInput(removeLineBreaks(inputRef.current.value))
            setEdited(inputRef.current.value !== '')
          }}
          {...textAreaProps}
        />
        <ButtonWrapper>
          {showEmojiButton && (
            <EmojiButtonFlex>
              <EmojiButton
                size="small"
                placement="bottom"
                textRef={inputRef}
                isEmojiPickerVisible={isEmojiPickerVisible}
                setEmojiPickerVisible={setEmojiPickerVisible}
                textareaCursorPosition={textareaCursorPosition}
                setTextareaCursorPosition={setTextareaCursorPosition}
                insertEmoji={insertEmoji}
              />
            </EmojiButtonFlex>
          )}
          {showSubmitButton && isEdited && (
            <IconButton
              style={{ width: 'auto', marginBottom: '2px' }}
              title="Add note"
              onClick={() => onSubmit(onReturn)}
              icon={AddCommentIcon}
            />
          )}
        </ButtonWrapper>
      </TextAreaWrapper>
    </StyledCard>
  )
}

EditableCommentMobile.propTypes = {
  onInput: PropTypes.func,
  onReturn: PropTypes.func,
  startTime: PropTypes.string,
  inputRef: PropTypes.shape({
    current: PropTypes.shape({ value: PropTypes.string }),
  }),
  showSubmitButton: PropTypes.bool,
  showEmojiButton: PropTypes.bool,
  textAreaProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
}

EditableCommentMobile.defaultProps = {
  onInput: () => {},
  onReturn: () => {},
  startTime: '',
  inputRef: null,
  showSubmitButton: false,
  showEmojiButton: true,
  textAreaProps: {},
}

export default EditableCommentMobile
