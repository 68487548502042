import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import last from 'lodash/last'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import PageEvents from './PageEvents'
import { PAGE_EVENT_TYPES } from './pageEvents.constants'
import useDeepEffect from '../../hooks/useDeepEffect'
import Anchor from '../../atoms/Anchor'

const getClickEventTextNode = eventData => {
  if (eventData?.targetText?.length > 100) {
    return `<${eventData?.targetTagName} />`
  }

  return eventData?.targetString
}

const StyledAnchor = styled(Anchor)`
  display: contents;
  color: ${prop('theme.colors.primary')} !important;

  :hover,
  :active {
    color: ${prop('theme.colors.primary900')} !important;
  }
`

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  margin: 0 !important;
  padding: 4px !important;
  border-radius: 4px;
  background-color: white !important;

  && pre {
    padding: 4px !important;
    background-color: white !important;
  }

  && pre,
  && code {
    font-family: ${prop('theme.fontFamily')} !important;
  }

  .token.tag {
    color: #6e15e0;
  }
  .token.tag.attr-name {
    color: #00848e;
  }
  .token.tag.attr-value {
    color: #a15908;
  }
  .token.tag.punctuation,
  .token.tag.attr-value.punctuation.attr-equals,
  .token.tag.attr-value.punctuation {
    color: #848f9a;
  }
`

export const mapPageEvents = event => {
  if (event.type === PAGE_EVENT_TYPES.click) {
    return {
      ...event,
      textNode: (
        <>
          Clicked on
          <StyledSyntaxHighlighter
            language="markup"
            wrapLongLines
            useInlineStyles={false}
          >
            {getClickEventTextNode(event?.data)}
          </StyledSyntaxHighlighter>
        </>
      ),
    }
  }

  if (event.type === PAGE_EVENT_TYPES.navigation) {
    return {
      ...event,
      textNode: (
        <>
          Navigated to{' '}
          <StyledAnchor href={event?.url} target="_blank">
            {event?.url}
          </StyledAnchor>
        </>
      ),
    }
  }

  if (
    [
      PAGE_EVENT_TYPES.consoleLog,
      PAGE_EVENT_TYPES.consoleInfo,
      PAGE_EVENT_TYPES.consoleDebug,
      PAGE_EVENT_TYPES.consoleWarn,
      PAGE_EVENT_TYPES.consoleError,
    ].includes(event.type)
  ) {
    return {
      ...event,
      textNode: event.values.join(' '),
    }
  }

  if (event.type === PAGE_EVENT_TYPES.error) {
    return {
      ...event,
      textNode: (
        <span>
          {event?.data?.message} {event?.data?.source} {event?.data?.lineNumber}
          :{event?.data?.columnNumber}
        </span>
      ),
    }
  }

  if (event.type === PAGE_EVENT_TYPES.webRequest) {
    const urlResource = last(event?.url.split('/'))
    return {
      ...event,
      textNode: (
        <>
          <span>
            {event.data.method} {event.data.statusLine}
          </span>{' '}
          <StyledAnchor href={event?.url} target="_blank" title={event?.url}>
            {urlResource || '/'}
          </StyledAnchor>
        </>
      ),
    }
  }

  if (event.type === PAGE_EVENT_TYPES.webErrorRequest) {
    const urlResource = last(event?.url.split('/'))
    return {
      ...event,
      textNode: (
        <>
          <span>
            {event?.data?.method} {event?.data?.error}
          </span>{' '}
          <StyledAnchor href={event?.url} target="_blank" title={event?.url}>
            {urlResource || '/'}
          </StyledAnchor>
        </>
      ),
    }
  }

  return event
}

export const isPageEventCurrent = (event, currentVideoTime) =>
  event.videoTime >= currentVideoTime - 0.1 &&
  event.videoTime <= currentVideoTime + 0.3

export const mapCurrentPageEvent = currentVideoTime => event => ({
  ...event,
  isCurrent: isPageEventCurrent(event, currentVideoTime),
})

export const filterAndMapPageEvents = events =>
  events
    .filter(event => Object.values(PAGE_EVENT_TYPES).includes(event.type))
    .map(mapPageEvents)

export const adaptPageEvents = (events = [], currentVideoTime) =>
  filterAndMapPageEvents(events).map(mapCurrentPageEvent(currentVideoTime))

const PageEventsContainer = ({
  events,
  currentVideoTime,
  onEventsFiltered,
  ...props
}) => {
  const adaptedEvents = adaptPageEvents(events, currentVideoTime)
  useDeepEffect(() => {
    onEventsFiltered(adaptedEvents)
  }, [adaptedEvents])
  return <PageEvents events={adaptedEvents} {...props} />
}

PageEventsContainer.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentVideoTime: PropTypes.number,
  onEventsFiltered: PropTypes.func,
}

PageEventsContainer.defaultProps = {
  currentVideoTime: 0,
  onEventsFiltered: () => {},
}

export default PageEventsContainer
