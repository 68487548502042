import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import capitalize from 'lodash/capitalize'
import { Field, Hint, Label, Input, Message } from '@zendeskgarden/react-forms'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Button } from '@zendeskgarden/react-buttons'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import InfinityIcon from 'mdi-react/InfinityIcon'
import IconButton from '../../../molecules/IconButton'
import {
  getRandomAvatars,
  getAvatarUrl,
} from '../../../molecules/AvatarSelector/AvatarSelector'
import Flex from '../../../atoms/Flex'
import theme from '../../../theme/old'
import UserAvatar from '../../../atoms/UserAvatar/UserAvatar'
import { RoleDropdown } from '../../../molecules/OrganizationMembers/OrganizationMembers'
import { ROLES } from '../../../molecules/OrganizationMembers/useOrganizationMembers'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  max-width: 500px;
  width: 100%;
`

const MembersBox = styled(CenteredBox)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 40px;
`

const Left = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  text-align: left;
`

const StyledHint = styled(Hint)`
  flex-direction: row;
  display: flex;
  font-size: 12px;
  margin-top: 6px;
  color: ${prop('theme.colors.green')};
  align-items: center;
  align-self: flex-start;
  line-height: 20px;
`

const StyledInfinityIcon = styled(InfinityIcon)`
  margin-right: 4px;
`

const Title = styled.h3`
  margin-bottom: 16px;
`

const NextButton = styled(IconButton)`
  align-self: center;
`

const ButtonsCenteredBox = styled(CenteredBox)`
  min-height: 'fit-content';
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const RolesGrid = styled(Grid)`
  margin-top: 16px;
`

const RolesRow = styled(Row)`
  padding: 16px 0 !important;
  line-height: 32px;
  border-bottom: 1px solid ${prop('theme.colors.grey100')};
`

const RoleCol = styled(Col)`
  text-align: right;
`

const Email = styled(Flex)`
  line-height: 32px;
  padding-left: 16px;
`

const InviteMembers = ({ onBack, onNext, ...props }) => {
  const [members, setMembers] = useState([])
  const [emailPlaceholder, setEmailPlaceholder] = useState('Email')
  const [emailValidation, setEmailValidation] = useState(undefined)
  const [isEmailInvalid, setEmailInvalid] = useState(false)
  const inputRef = useRef()

  const onAddMember = useCallback(() => {
    const inputValue = inputRef?.current?.value
    if (!inputValue) {
      setEmailPlaceholder('Type a valid email')
      setEmailValidation('warning')
      return
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputValue)) {
      setEmailValidation('error')
      setEmailInvalid(true)
      return
    }

    setEmailInvalid(false)
    setEmailPlaceholder('Email')
    setEmailValidation(undefined)
    const randomAvatarUrl = getAvatarUrl(getRandomAvatars()[0])

    setMembers([
      ...members,
      { email: inputValue, role: ROLES.member, picture: randomAvatarUrl },
    ])
    inputRef.current.value = ''
  }, [inputRef, members])

  const onChangeRole = useCallback(
    ({ userEmail, userRole }) => {
      if (userRole === ROLES.remove) {
        const newMembers = members.filter(({ email }) => email !== userEmail)
        setMembers(newMembers)
        return
      }

      const newMembers = members.map(member => {
        if (member.email !== userEmail) {
          return member
        }

        return {
          ...member,
          role: userRole,
        }
      })
      setMembers(newMembers)
    },
    [members]
  )

  const onNextHandler = useCallback(
    _members => {
      const filteredMembers = _members.map(member => ({
        email: member.email,
        role: member.role,
      }))
      onNext({ members: filteredMembers })
    },
    [members]
  )

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>Onboard your teammates</Title>
      </CenteredBox>
      <CenteredBox style={{ width: '100%' }}>
        <Left>
          <Field>
            <Label>Add new member</Label>
            <Flex direction="row" flexGrow={1}>
              <Input
                ref={inputRef}
                validation={emailValidation}
                placeholder={emailPlaceholder}
                style={{ width: '335px', marginRight: '16px' }}
                data-test="add-member-input"
              />
              <Button
                isPrimary
                onClick={onAddMember}
                data-test="add-member-button"
              >
                Send invitation
              </Button>
            </Flex>
            <StyledHint>
              {!isEmailInvalid && (
                <>
                  <StyledInfinityIcon color={theme.colors.green} size="16" />
                  <Flex>You can invite unlimited team members</Flex>
                </>
              )}
              {isEmailInvalid && (
                <Message validation="error">Invalid email address</Message>
              )}
            </StyledHint>
          </Field>
        </Left>
      </CenteredBox>
      {members.length > 0 && (
        <MembersBox>
          <Flex>
            {members.length} pending invitation
            {members.length > 1 ? 's' : ''}
          </Flex>
          <RolesGrid>
            {members.map(({ email, role, picture }) => (
              <RolesRow key={`${email}-${role}`}>
                <Col
                  size={2}
                  xs={1}
                  style={{
                    minWidth: '32px',
                    textAlign: 'right',
                  }}
                >
                  <UserAvatar imgUrl={picture} name={email} status={null} />
                </Col>
                <Col size={6} xs={7} style={{ textAlign: 'left' }}>
                  <Email>{email}</Email>
                </Col>
                <RoleCol size={4} xs={4}>
                  <>
                    <span data-test="role-label">{capitalize(role)}</span>
                    <RoleDropdown
                      userEmail={email}
                      currentRole={role}
                      onChangeRole={(assignerUserEmail, newRole) =>
                        onChangeRole({
                          userEmail: assignerUserEmail,
                          userRole: newRole,
                        })
                      }
                    />
                  </>
                </RoleCol>
              </RolesRow>
            ))}
          </RolesGrid>
        </MembersBox>
      )}
      <ButtonsCenteredBox>
        <NextButton
          isPrimary
          isBasic
          type="submit"
          icon={ArrowLeftIcon}
          size="medium"
          onClick={() => null}
        >
          Back
        </NextButton>
        {members.length === 0 && (
          <NextButton
            isPrimary
            isBasic
            type="submit"
            icon={ArrowRightIcon}
            isRightIcon
            size="medium"
            onClick={() => onNextHandler([])}
          >
            I&apos;ll do it later
          </NextButton>
        )}
        {members.length > 0 && (
          <NextButton
            isPrimary
            type="submit"
            icon={ArrowRightIcon}
            isRightIcon
            size="medium"
            onClick={() => onNextHandler(members)}
          >
            Send {members.length} invitation{members.length === 1 ? '' : 's'}
          </NextButton>
        )}
      </ButtonsCenteredBox>
    </Wrapper>
  )
}

InviteMembers.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
}

InviteMembers.defaultProps = {
  onNext: () => {},
  onBack: () => {},
}

export default InviteMembers
