// eslint-disable-next-line no-undef
const theChrome = window?.chrome || {}

const isExtension = !!theChrome?.storage

export const storageLocalSet = (key, value) =>
  new Promise(resolve => {
    if (isExtension) {
      theChrome.storage.local.set({ [key]: JSON.stringify(value) }, () => {
        resolve()
      })
      return
    }
    localStorage.setItem(key, JSON.stringify(value))
    resolve()
  })

export const storageLocalGet = key =>
  new Promise(resolve => {
    if (isExtension) {
      theChrome.storage.local.get(key, result => {
        resolve(JSON.parse(result[key]))
      })
      return
    }
    resolve(JSON.parse(localStorage.getItem(key)))
  })

export const isChromeStorageAvailable = () => !!theChrome?.storage?.local
