import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import NotFoundMobile from '../NotFoundMobile'
import RequestRecordingPage from '../../pages/RequestRecordingPage'
import { uploadRemoteTestScreenRecording } from '../../../store/remoteTests/remoteTests.actions'
import api from '../../../api'

const RequestRecordingProxy = ({ match }) => {
  const dispatch = useDispatch()

  const requestRecordingId = get(match, 'params.requestRecordingId')
  const [isLoading, setLoading] = useState(true)
  const [isRemoteTestFound, setRemoteTestFound] = useState(false)
  const [remoteTest, setRemoteTest] = useState(null)

  const fetchRemoteTest = () =>
    api
      .getRemoteTest(requestRecordingId)
      .then(response => {
        setRemoteTest(response.data)
        setRemoteTestFound(true)
      })
      .catch(err => {
        console.error(err)
        const errorMessage = err.toString().toLowerCase()
        if (errorMessage.includes('not found')) {
          setRemoteTestFound(false)
        }
      })
      .finally(() => {
        setLoading(false)
      })

  useEffect(() => {
    fetchRemoteTest()
  }, [])

  const [mediaBlob, setMediaBlob] = useState(null)
  const [mimeType, setMimeType] = useState(null)
  useEffect(() => {
    const remoteTestId = requestRecordingId.replace('remote-test', '')
    window.onRequestRecordingUpload = () => {
      dispatch(
        uploadRemoteTestScreenRecording({
          projectId: remoteTest.projectId,
          remoteTestId,
          content: mediaBlob,
          mimeType,
        })
      )
    }
  }, [mediaBlob, mimeType])

  if (!requestRecordingId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !isRemoteTestFound) {
    return <NotFoundMobile />
  }

  if (!isLoading && remoteTest) {
    const remoteTestId = requestRecordingId.replace('remote-test', '')
    return (
      <RequestRecordingPage
        sections={remoteTest.sections}
        remoteTestId={remoteTestId}
        onSave={({ mediaBlob: _mediaBlob, mimeType: _mimeType }) => {
          setMediaBlob(_mediaBlob)
          setMimeType(_mimeType)
        }}
      />
    )
  }

  return null
}

RequestRecordingProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

export default RequestRecordingProxy
