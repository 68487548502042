const NAMESPACE = 'PROJECT'

export const SET_CURRENT_PROJECT = `${NAMESPACE}/SET_CURRENT`
export const setCurrentProject = projectId => ({
  type: SET_CURRENT_PROJECT,
  payload: { projectId },
})

export const CREATE_NEW_PROJECT = `${NAMESPACE}/CREATE_NEW`
export const CREATE_NEW_PROJECT_REQUEST = `${NAMESPACE}/CREATE_NEW/REQUEST`
export const CREATE_NEW_PROJECT_REQUEST_SUCCESS = `${NAMESPACE}/CREATE_NEW/REQUEST_SUCCESS`
export const CREATE_NEW_PROJECT_REQUEST_FAILURE = `${NAMESPACE}/CREATE_NEW/REQUEST_FAILURE`

export const createNewProjectRequest = ({ ...project }) => ({
  type: CREATE_NEW_PROJECT_REQUEST,
  payload: { ...project },
  meta: { thunk: CREATE_NEW_PROJECT_REQUEST },
})

export const createNewProjectRequestSuccess = (data, thunk) => ({
  type: CREATE_NEW_PROJECT_REQUEST_SUCCESS,
  payload: data,
  meta: { thunk },
})

export const createNewProjectRequestFailure = (e, thunk) => ({
  type: CREATE_NEW_PROJECT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const GET_PROJECT = `${NAMESPACE}/GET_PROJECT`
export const GET_PROJECT_REQUEST = `${GET_PROJECT}/REQUEST`
export const GET_PROJECT_REQUEST_SUCCESS = `${GET_PROJECT}/REQUEST_SUCCESS`
export const GET_PROJECT_REQUEST_FAILURE = `${GET_PROJECT}/REQUEST_FAILURE`

export const getProjectRequest = projectId => ({
  type: GET_PROJECT_REQUEST,
  payload: { projectId },
  meta: { thunk: GET_PROJECT_REQUEST },
})

export const getProjectRequestSuccess = (project, thunk) => ({
  type: GET_PROJECT_REQUEST_SUCCESS,
  payload: { project },
  meta: { thunk },
})

export const getProjectRequestFailure = (e, thunk) => ({
  type: GET_PROJECT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE`
export const UPDATE_PROJECT_REQUEST = `${NAMESPACE}/UPDATE/REQUEST`
export const UPDATE_PROJECT_REQUEST_SUCCESS = `${NAMESPACE}/UPDATE/REQUEST_SUCCESS`
export const UPDATE_PROJECT_REQUEST_FAILURE = `${NAMESPACE}/UPDATE/REQUEST_FAILURE`

export const updateProjectRequest = ({ ...project }) => ({
  type: UPDATE_PROJECT_REQUEST,
  payload: { ...project },
  meta: { thunk: UPDATE_PROJECT_REQUEST },
})

export const updateProjectRequestSuccess = (
  { projectId, ...project },
  thunk
) => ({
  type: UPDATE_PROJECT_REQUEST_SUCCESS,
  payload: { projectId, ...project },
  meta: { thunk },
})

export const updateProjectRequestFailure = (e, thunk) => ({
  type: UPDATE_PROJECT_REQUEST_FAILURE,
  payload: e,
  meta: { thunk },
})

export const DELETE_PROJECT = `${NAMESPACE}/DELETE`
export const DELETE_PROJECT_REQUEST = `${NAMESPACE}/DELETE/REQUEST`
export const DELETE_PROJECT_REQUEST_SUCCESS = `${NAMESPACE}/DELETE/REQUEST_SUCCESS`
export const DELETE_PROJECT_REQUEST_FAILURE = `${NAMESPACE}/DELETE/REQUEST_FAILURE`

export const deleteProjectRequest = projectId => ({
  type: DELETE_PROJECT_REQUEST,
  payload: { projectId },
  meta: { thunk: DELETE_PROJECT_REQUEST },
})

export const deleteProjectRequestSuccess = ({ projectId }, thunk) => ({
  type: DELETE_PROJECT_REQUEST_SUCCESS,
  payload: { projectId },
  meta: { thunk },
})

export const deleteProjectRequestFailure = (e, thunk) => ({
  type: DELETE_PROJECT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPSERT_PROJECT = `${NAMESPACE}/UPSERT_PROJECT`
export const upsertProject = project => ({
  type: UPSERT_PROJECT,
  payload: { project },
})

export const UPDATE_PROJECTS = `${NAMESPACE}S/UPDATE`
export const UPDATE_PROJECTS_REQUEST = `${UPDATE_PROJECTS}/REQUEST`
export const UPDATE_PROJECTS_REQUEST_SUCCESS = `${UPDATE_PROJECTS_REQUEST}_SUCCESS`
export const UPDATE_PROJECTS_REQUEST_FAILURE = `${UPDATE_PROJECTS_REQUEST}_FAILURE`

export const updateProjectsRequest = () => ({
  type: UPDATE_PROJECTS_REQUEST,
  payload: {},
  meta: { thunk: UPDATE_PROJECTS_REQUEST },
})

export const updateProjectsRequestSuccess = (
  { organizationId, projects },
  thunk
) => ({
  type: UPDATE_PROJECTS_REQUEST_SUCCESS,
  payload: { organizationId, projects },
  meta: { thunk },
})

export const updateProjectsRequestFailure = (e, thunk) => ({
  type: UPDATE_PROJECTS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const GET_PROJECTS = `${NAMESPACE}S/GET`
export const GET_PROJECTS_REQUEST = `${GET_PROJECTS}/REQUEST`
export const GET_PROJECTS_REQUEST_SUCCESS = `${GET_PROJECTS_REQUEST}_SUCCESS`
export const GET_PROJECTS_REQUEST_FAILURE = `${GET_PROJECTS_REQUEST}_FAILURE`

export const getProjectsRequest = organizationId => ({
  type: GET_PROJECTS_REQUEST,
  payload: { organizationId },
  meta: { thunk: GET_PROJECTS_REQUEST },
})

export const getProjectsRequestSuccess = (
  { organizationId, projects },
  thunk
) => ({
  type: GET_PROJECTS_REQUEST_SUCCESS,
  payload: { organizationId, projects },
  meta: { thunk },
})

export const getProjectsRequestFailure = (e, thunk) => ({
  type: GET_PROJECTS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const SET_PROJECT_TITLE = `${NAMESPACE}/SET_TITLE`

export const setProjectTitle = ({ projectId, title }) => ({
  type: SET_PROJECT_TITLE,
  payload: { projectId, title },
})
