import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import RecordIcon from 'mdi-react/RecordIcon'
import StopCircleIcon from 'mdi-react/StopCircleIcon'
import { Label, Field, Toggle } from '@zendeskgarden/react-forms'
import { Button } from '@zendeskgarden/react-buttons'
import { Progress } from '@zendeskgarden/react-loaders'
import { XL, LG } from '@zendeskgarden/react-typography'
import prop from 'lodash/fp/prop'
import { useSpring, config } from 'react-spring'
import Brand from '../../atoms/Brand'
import Flex from '../../atoms/Flex'
import RequestRecordingCard from './RequestRecordingCard'
import useRecording from '../../hooks/useRecording'
import IconButton from '../../molecules/IconButton'
import { ternary } from '../../../util/utils'
import theme from '../../theme/old'
import appConfig from '../../../config'

const Wrapper = styled(Flex)`
  max-width: 672px;
  padding: 16px 16px 40px 16px;
  align-items: center;
`

const StyledBrand = styled(Brand)`
  width: 220px;
  z-index: 10;
  padding: 8px 16px;
  background-color: hsl(210, 10%, 96%);
  box-shadow: 0 10px 100px hsl(210, 10%, 96%);
`

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const fadeInCss = css`
  opacity: 0;
  animation: ${fadeIn} 500ms;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
`

const DoNotClose = styled(XL)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`

const UploadBox = styled(Flex)`
  margin-bottom: 16px;
  color: ${prop('theme.colors.green600')};
  font-style: italic;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`

const StyledProgress = styled(Progress)`
  width: 100%;
`

const Controls = styled(Flex)`
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  align-self: flex-end;
  ${fadeInCss}

  > * {
    margin-left: 24px;
  }

  @media only screen and (max-width: 400px) {
    flex-direction: column-reverse;
    align-items: flex-end;

    > * {
      margin-top: 16px;
    }
  }
`

const BaseCard = styled(RequestRecordingCard)`
  margin-top: 12px;
  width: 100%;
  font-size: 20px;
  box-sizing: border-box;
  position: relative;
  ${fadeInCss}
`

const RecordingCard = styled(BaseCard)`
  margin-top: 24px;
  background-color: #c0f0d3;
`

const StyledVideo = styled.video`
  width: 100%;
  max-height: 529px;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
`

const CardOverlay = styled(Flex)`
  position: absolute;
  width: 100%;
  height: ${ternary('increaseHeight')('100%', '50%')};
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #f4f5f6 0%, rgba(244, 245, 248, 0) 100%);
  z-index: 100;
  ${fadeInCss}
`

const Box = styled(Flex)`
  padding-top: 32px;
  position: relative;
  transition: transform ease-in-out 500ms;
  transition-delay: 500ms;
`

const getStep = (isRecording, mediaBlobUrl, uploadProgress) => {
  if (!isRecording && !mediaBlobUrl) {
    return 0
  }
  if (isRecording) {
    return 1
  }
  if (
    !isRecording &&
    mediaBlobUrl &&
    (!uploadProgress || uploadProgress < 100)
  ) {
    return 2
  }
  if (!isRecording && mediaBlobUrl && uploadProgress >= 100) {
    return 3
  }
  return null
}

const RequestRecording = ({
  sections,
  uploadProgress,
  onSave,
  onScroll,
  ...props
}) => {
  const {
    isMicAvailable,
    isMicEnabled,
    isRecording,
    mediaBlob,
    mediaBlobUrl,
    mimeType,
    resetHandler,
    startRecordingHandler,
    stopRecordingHandler,
    toggleMicAudio,
    isRecordingAvailable,
  } = useRecording()

  useEffect(() => {
    if (mediaBlob && mimeType) {
      onSave({ mediaBlob, mimeType })
      window.grecaptcha.execute()
    }
  }, [window.grecaptcha, mediaBlob, mimeType])

  const [isRecordingStopped, setRecordingStopped] = useState(false)

  const showControls = isRecording || (!isRecording && !isRecordingStopped)
  const currentStep = getStep(isRecording, mimeType, uploadProgress)

  const [scrollTopState, setScrollTopState] = useState(0)
  const scrollTopSpring = useSpring({
    config: config.slow,
    scroll: scrollTopState,
    from: { scroll: 0 },
  })
  useEffect(() => {
    onScroll(scrollTopSpring)
  }, [scrollTopSpring])
  const wrapperRef = useRef(null)
  useEffect(() => {
    if (currentStep > 0 && wrapperRef?.current?.parentNode) {
      const node = wrapperRef.current.parentNode
      setScrollTopState(node.scrollHeight)
    }
  }, [currentStep, wrapperRef?.current])

  const sectionIntro = sections.find(x => x.id === 'intro')
  const sectionTasks = sections.find(x => x.id === 'tasks')
  const sectionThanks = sections.find(x => x.id === 'thank-you')

  useEffect(() => {
    const scriptNode = document.createElement('script')
    scriptNode.setAttribute('async', 'async')
    scriptNode.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    document.body.appendChild(scriptNode)
  }, [])

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <StyledBrand />
      <div
        id="recaptcha"
        className="g-recaptcha"
        data-sitekey={appConfig.recaptcha.siteKey}
        data-callback="onRequestRecordingUpload"
        data-size="invisible"
      />
      {sectionIntro && (
        <Box step={currentStep}>
          <Flex style={{ position: 'relative' }}>
            {(isRecording || isRecordingStopped) && (
              <CardOverlay increaseHeight={!!isRecordingStopped} />
            )}
            <BaseCard style={{ marginTop: 0 }} html={sectionIntro.text} />
            {(isRecording || isRecordingStopped) && sectionTasks.text && (
              <BaseCard html={sectionTasks.text} />
            )}
            {!isRecordingAvailable && (
              <BaseCard
                isWarning
                html={`
                  ⚠️ Screen recording is not supported on this browser yet.<br>
                  Please use a recent version of 
                  <a href="https://www.google.com/chrome/" target="_blank" title="Download Google Chrome browser">Chrome</a>, 
                  <a href="https://www.mozilla.org/it/" target="_blank" title="Download Mozilla Firefox browser">Firefox</a>, or 
                  <a href="https://www.microsoft.com/en-us/edge" target="_blank" title="Download Microsoft Edge browser">Edge</a>.
                `}
              />
            )}
            {showControls && (
              <Controls>
                {!mediaBlobUrl && isMicAvailable && isRecordingAvailable && (
                  <Field>
                    <Toggle
                      checked={isMicEnabled}
                      onChange={() => {
                        toggleMicAudio()
                      }}
                      data-test="mic-toggle"
                    >
                      <Label>Microphone audio</Label>
                    </Toggle>
                  </Field>
                )}
                {!isRecording && !mediaBlobUrl && (
                  <IconButton
                    onClick={startRecordingHandler}
                    disabled={!isRecordingAvailable}
                    icon={RecordIcon}
                    isPrimary
                    size="medium"
                    isRightIcon
                    iconProps={{
                      style: {
                        fill: '#EB5757',
                      },
                    }}
                    data-test="start-recording-button"
                  >
                    Start Recording
                  </IconButton>
                )}
                {isRecording && (
                  <>
                    <Button
                      onClick={resetHandler}
                      isBasic
                      size="medium"
                      data-test="delete-recording-button"
                    >
                      Delete and restart
                    </Button>
                    <IconButton
                      onClick={() => {
                        setRecordingStopped(true)
                        stopRecordingHandler()
                      }}
                      icon={StopCircleIcon}
                      isPrimary
                      isRightIcon
                      size="medium"
                      data-test="stop-recording-button"
                    >
                      End recording
                    </IconButton>
                  </>
                )}
              </Controls>
            )}
          </Flex>
          {!isRecording && mediaBlobUrl && sectionThanks && (
            <>
              <RecordingCard>
                {uploadProgress && (
                  <>
                    {uploadProgress < 100 && (
                      <DoNotClose>Do not close the window</DoNotClose>
                    )}
                    <UploadBox>
                      {uploadProgress < 100 && <LG>Upload recording...</LG>}
                      {uploadProgress >= 100 && <LG>Upload complete</LG>}
                      <StyledProgress
                        value={uploadProgress}
                        size="small"
                        color={theme.colors.green600}
                      />
                    </UploadBox>
                  </>
                )}
                <StyledVideo src={mediaBlobUrl} controls inline autoPlay />
              </RecordingCard>
              {uploadProgress >= 100 && <BaseCard html={sectionThanks.text} />}
            </>
          )}
        </Box>
      )}
    </Wrapper>
  )
}

const section = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
})

RequestRecording.propTypes = {
  sections: PropTypes.arrayOf(section).isRequired,
  onSave: PropTypes.func,
  onScroll: PropTypes.func,
  uploadProgress: PropTypes.number,
}

RequestRecording.defaultProps = {
  onSave: () => {},
  onScroll: () => {},
  uploadProgress: undefined,
}

export default RequestRecording
