import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Field, Label, Toggle, Input } from '@zendeskgarden/react-forms'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
import Sharing from '../Sharing'
import CreateWorkspaceCommunication from './CreateWorkspaceCommunication'
import ProjectOwner from '../ProjectShareModal/ProjectOwner'
import { PERSONAL_ORGANIZATION_ID } from '../../../store/organizations/organizations.selectors'

const StyledBody = styled(Body)`
  padding: 24px 40px;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareableLinkTextField = EmailTextField

const CopyButton = styled(IconButton)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
`

const NormalLabel = styled(Label)`
  font-weight: normal;
`

const NormalText = styled.span`
  font-weight: normal;
`

export const getLinkTitle = ({
  isSessionProjectSharedWithAnyone,
  workspaceName,
  resourceName,
  totalContributors,
  isPublic,
}) => {
  if (isPublic) {
    return 'Anyone can open the recording with this link'
  }

  if (workspaceName?.toLowerCase() === PERSONAL_ORGANIZATION_ID) {
    return 'Private (Only you can access this)'
  }

  if (isSessionProjectSharedWithAnyone) {
    return (
      <>
        <b>Anyone at {workspaceName}</b>{' '}
        <NormalText>can open the {resourceName} with this link</NormalText>
      </>
    )
  }

  return `Only ${totalContributors || 0} member${
    totalContributors === 1 ? '' : 's'
  } of ${workspaceName} can open with this link`
}

const ShareModal = ({
  areCommentsAllowedState,
  copyButtonText,
  enrichedUsers,
  isCurrentUserOwner,
  isFormLoading,
  isLogged,
  isModalVisible,
  isPersonalWorkspace,
  isPublicState,
  isShareButtonEnabled,
  isUserContributor,
  isSessionProjectSharedWithAnyone,
  onAddUser,
  onCreateWorkspace,
  onModalClose,
  onRemoveUser,
  remoteTestId,
  resourceName,
  session,
  setCommentsAllowed,
  setCopyButtonText,
  setIsPublic,
  setShareValue,
  shareableLink,
  title,
  totalContributors,
  totalWorkspaceMembers,
  updateSessionSharing,
  workspaceName,
  sessionOwnerUser,
  remoteTestOwnerUser,
  onShowLinkCopyNotification,
}) => {
  const shareLinkLabel = getLinkTitle({
    isSessionProjectSharedWithAnyone,
    workspaceName,
    resourceName,
    totalContributors,
    isPublic: isPublicState,
  })

  const isListOfContributorsVisible =
    isLogged &&
    (isUserContributor || isCurrentUserOwner) &&
    !isPersonalWorkspace &&
    !isSessionProjectSharedWithAnyone &&
    !isPublicState

  return (
    <div>
      {isModalVisible && (
        <Modal isAnimated={false}>
          <Header style={{ textAlign: 'left', fontWeight: 'normal' }}>
            <div style={{ width: '80%' }}>
              Share {resourceName}
              <br />
              <b>{title}</b>
            </div>
          </Header>
          <StyledBody>
            <Flex>
              {!remoteTestId && (
                <Flex style={{ marginBottom: '16px' }}>
                  <Field style={{ marginBottom: '4px' }}>
                    <NormalLabel>{shareLinkLabel}</NormalLabel>
                  </Field>
                  <Flex direction="row">
                    <ShareableLinkTextField>
                      <Input type="text" defaultValue={shareableLink} />
                    </ShareableLinkTextField>
                    <CopyButton
                      isPrimary
                      icon={ContentCopyIcon}
                      iconProps={{
                        size: 20,
                      }}
                      onClick={() => {
                        copy(shareableLink)
                        setCopyButtonText('Copied')
                        setTimeout(() => {
                          if (copyButtonText) {
                            setCopyButtonText('Copy')
                          }
                        }, 2000)
                        onShowLinkCopyNotification()
                      }}
                    >
                      {copyButtonText}
                    </CopyButton>
                  </Flex>
                </Flex>
              )}
              {isLogged && isCurrentUserOwner && session && (
                <Grid style={{ marginBottom: '16px' }}>
                  <Row style={{ padding: '8px 0' }}>
                    <Col>
                      <Field>
                        <Toggle
                          checked={isPublicState}
                          onChange={event => {
                            const newValue = event.target.checked
                            setIsPublic(newValue)
                            let commentsAllowed = areCommentsAllowedState
                            if (!newValue) {
                              commentsAllowed = false
                              setCommentsAllowed(commentsAllowed)
                            }
                            updateSessionSharing({
                              isPublic: newValue,
                              areCommentsAllowed: commentsAllowed,
                            })
                          }}
                        >
                          <Label regular>Public</Label>
                        </Toggle>
                      </Field>
                    </Col>
                  </Row>

                  {isPublicState && (
                    <Row style={{ padding: '8px 0' }}>
                      <Col>
                        <Field>
                          <Toggle
                            disabled={!isPublicState}
                            checked={areCommentsAllowedState}
                            onChange={event => {
                              setCommentsAllowed(event.target.checked)
                              updateSessionSharing({
                                isPublic: isPublicState,
                                areCommentsAllowed: event.target.checked,
                              })
                            }}
                          >
                            <Label regular>Allow comments</Label>
                          </Toggle>
                        </Field>
                      </Col>
                    </Row>
                  )}
                </Grid>
              )}
            </Flex>
            {isListOfContributorsVisible && (
              <Sharing
                users={enrichedUsers}
                isInviteButtonDisabled={!isShareButtonEnabled || isFormLoading}
                onInput={e => setShareValue(e.target.value)}
                onAdd={userEmail => onAddUser(userEmail)}
                onRemove={userEmail => onRemoveUser(userEmail)}
                totalWorkspaceMembers={totalWorkspaceMembers}
                totalContributors={totalContributors}
              />
            )}
            {isLogged && isPersonalWorkspace && (
              <CreateWorkspaceCommunication onCreateClick={onCreateWorkspace} />
            )}
            {sessionOwnerUser && session && (
              <>
                <Field style={{ marginTop: '8px', marginBottom: '4px' }}>
                  <NormalLabel>
                    Only the owner can change sharing settings
                  </NormalLabel>
                </Field>
                <ProjectOwner
                  name={sessionOwnerUser.name}
                  email={
                    isPublicState && !isCurrentUserOwner && !isUserContributor
                      ? null
                      : sessionOwnerUser.email
                  }
                  picture={sessionOwnerUser.picture}
                  isOwner
                />
              </>
            )}
            {remoteTestOwnerUser && remoteTestId && (
              <>
                <Field style={{ marginTop: '8px', marginBottom: '4px' }}>
                  <NormalLabel>
                    Only the owner can change sharing settings
                  </NormalLabel>
                </Field>
                <ProjectOwner
                  name={remoteTestOwnerUser.name}
                  email={
                    isPublicState && !isCurrentUserOwner && !isUserContributor
                      ? null
                      : remoteTestOwnerUser.email
                  }
                  picture={remoteTestOwnerUser.picture}
                  isOwner
                />
              </>
            )}
          </StyledBody>
          <Close aria-label="Close modal" onClick={onModalClose} />
        </Modal>
      )}
    </div>
  )
}

ShareModal.propTypes = {
  remoteTestId: PropTypes.string,
  areCommentsAllowedState: PropTypes.bool.isRequired,
  copyButtonText: PropTypes.string.isRequired,
  enrichedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCurrentUserOwner: PropTypes.bool.isRequired,
  isFormLoading: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  isPersonalWorkspace: PropTypes.bool.isRequired,
  isPublicState: PropTypes.bool.isRequired,
  isShareButtonEnabled: PropTypes.bool.isRequired,
  isUserContributor: PropTypes.bool.isRequired,
  isSessionProjectSharedWithAnyone: PropTypes.bool.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onCreateWorkspace: PropTypes.func,
  onModalClose: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  onShowLinkCopyNotification: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  session: PropTypes.shape({}).isRequired,
  setCommentsAllowed: PropTypes.func.isRequired,
  setCopyButtonText: PropTypes.func.isRequired,
  setIsPublic: PropTypes.func.isRequired,
  setShareValue: PropTypes.func.isRequired,
  shareableLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalContributors: PropTypes.number.isRequired,
  totalWorkspaceMembers: PropTypes.number.isRequired,
  updateSessionSharing: PropTypes.func.isRequired,
  workspaceName: PropTypes.string.isRequired,
  sessionOwnerUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
  }),
  remoteTestOwnerUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
  }),
}

ShareModal.defaultProps = {
  onCreateWorkspace: () => {},
  remoteTestId: '',
  sessionOwnerUser: {},
  remoteTestOwnerUser: {},
}

export default ShareModal
