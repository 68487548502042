import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import get from 'lodash/get'
import CellphoneLinkIcon from 'mdi-react/CellphoneLinkIcon'
import CircleIcon from 'mdi-react/CircleIcon'
import AndroidIcon from 'mdi-react/AndroidIcon'
import AppleIcon from 'mdi-react/AppleIcon'
import { Button } from '@zendeskgarden/react-buttons'
import {
  Dropdown,
  Menu,
  Item,
  Select,
  Field,
} from '@zendeskgarden/react-dropdowns'
import Flex from '../../atoms/Flex/Flex'

const Wrapper = styled(Flex)`
  display: inline-flex;
`

const Box = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${prop('theme.colors.grey300')};
  background-color: white;
  width: auto;
  display: inline-flex;
  flex-direction: row;
`

const InlineFlex = styled(Flex)`
  display: inline-flex;
  flex: 0 0 auto;
`

const InlineFlexWithPadding = styled(InlineFlex)`
  padding: 8px 16px;
`

const IconBox = styled(InlineFlexWithPadding)`
  padding-right: 0;
  svg {
    fill: ${prop('theme.colors.grey500')};
  }
`

const Text = styled(InlineFlexWithPadding)`
  line-height: 24px;
`

const StartSessionButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 0 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const StyledCircleIcon = styled(CircleIcon)`
  fill: #27ae60;
`

const selectStyle = css`
  box-shadow: none !important;
  border: 1px solid ${prop('theme.colors.grey300')} !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
`

const StyledSelect = styled(Select)`
  ${selectStyle}

  && {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    display: flex;
    align-items: center;

    ${selectStyle}
  }

  &&:focus {
    ${selectStyle}
  }
`

const SelectIconFlex = styled(InlineFlex)`
  margin-right: 16px;
`

const IconModifier = styled(Flex)`
  svg {
    fill: ${prop('theme.colors.grey500')};
  }
`

const DeviceSelector = ({
  onSessionStart,
  devices,
  selectedDevice,
  selectedDeviceId,
  setSelectedDevice,
  currentProjectId,
  onSetCurrentDevice,
  onCreateNewSession,
  ...props
}) => (
  <Wrapper {...props}>
    {devices.length === 0 && (
      <Box>
        <IconBox>
          <CellphoneLinkIcon />
        </IconBox>
        <Text>No device connected</Text>
      </Box>
    )}
    {devices.length > 0 && (
      <InlineFlex direction="row">
        <InlineFlex>
          <Dropdown
            selectedItem={selectedDevice}
            onSelect={selectedItem => {
              setSelectedDevice(selectedItem)
              onSetCurrentDevice(selectedItem.id)
            }}
            downshiftProps={{ itemToString: item => item && item.name }}
          >
            <Field>
              <StyledSelect>
                <SelectIconFlex>
                  <StyledCircleIcon size={16} />
                </SelectIconFlex>
                <InlineFlex>
                  {get(
                    devices.find(_device => _device.id === selectedDeviceId),
                    'name',
                    ''
                  )}
                </InlineFlex>
              </StyledSelect>
            </Field>
            <Menu>
              {devices.map(device => (
                <Item key={device.id} value={device}>
                  <Flex style={{ flexDirection: 'row' }}>
                    <InlineFlex>
                      {device.type === 'android' && (
                        <IconModifier>
                          <AndroidIcon size={16} />
                        </IconModifier>
                      )}
                      {device.type === 'ios' && (
                        <IconModifier>
                          <AppleIcon size={16} />
                        </IconModifier>
                      )}
                    </InlineFlex>
                    <InlineFlex style={{ marginLeft: '8px' }}>
                      {device.name}
                    </InlineFlex>
                  </Flex>
                </Item>
              ))}
            </Menu>
          </Dropdown>
        </InlineFlex>
        <InlineFlex>
          <StartSessionButton
            isPrimary
            onClick={() => {
              onSetCurrentDevice(selectedDeviceId)
              onCreateNewSession().then(data => {
                const sessionId = get(data, 'data.createSession.pk', '')
                if (sessionId) {
                  onSessionStart({
                    projectId: currentProjectId,
                    sessionId,
                    isNew: true,
                  })
                }
              })
            }}
          >
            Start session
          </StartSessionButton>
        </InlineFlex>
      </InlineFlex>
    )}
  </Wrapper>
)

DeviceSelector.propTypes = {
  onSessionStart: PropTypes.func,
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDevice: PropTypes.shape({}),
  selectedDeviceId: PropTypes.string,
  setSelectedDevice: PropTypes.func,
  currentProjectId: PropTypes.string,
  onSetCurrentDevice: PropTypes.func,
  onCreateNewSession: PropTypes.func,
}

DeviceSelector.defaultProps = {
  onSessionStart: () => {},
  devices: [],
  selectedDevice: {},
  selectedDeviceId: '',
  setSelectedDevice: () => {},
  currentProjectId: '',
  onSetCurrentDevice: () => {},
  onCreateNewSession: () => {},
}

export default DeviceSelector
