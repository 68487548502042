import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import { createSelector } from 'reselect'
import { STATE_KEY, STATE_SUB_KEY } from './integrations.jira.reducers'

const getState = state => getImmutable(state, `${STATE_KEY}\\.${STATE_SUB_KEY}`)

const getHasJira = createSelector(
  getState,
  get('hasJira')
)

const getJiraProjects = createSelector(
  getState,
  get('projects')
)

const getJiraIssueTypes = createSelector(
  getState,
  get('issueTypes')
)

const getJiraRequiredFields = createSelector(
  getState,
  get('requiredFields')
)

export default {
  getHasJira,
  getJiraProjects,
  getJiraIssueTypes,
  getJiraRequiredFields,
}
