import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '@zendeskgarden/react-accordions'
import styled from 'styled-components'
import CloseIcon from 'mdi-react/CloseIcon'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
// import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import { useSpring, animated } from 'react-spring'
import AlertRow from '../../molecules/AlertRow/AlertRow'
import theme from '../../theme/old'
import UploadingItem from '../../molecules/UploadingItem/UploadingItem'
import Flex from '../../atoms/Flex/Flex'
import IconButton from '../../molecules/IconButton/IconButton'
import useEventsListener from '../../../store/hooks/useEventsListener'

const { colors } = theme

function getHeadingColor(props) {
  if (props.errorsNumber) {
    return colors.warning700
  }
  if (props.poorConnection) {
    return colors.yellow700
  }
  return colors.primary900
}

const FlexLabel = styled(Flex)``

const StyledAccordionHeader = styled(Accordion.Header)`
  padding: 20px 16px;
`

const FixedAccordion = styled(Accordion)`
  position: fixed;
  bottom: 0;
  max-width: 540px;
  margin: 0 auto;
  left: 0;
  right: 0;
  box-shadow: 0 24px 32px 0 rgba(7, 8, 34, 0.04);
  font-family: 'DM Sans';

  [role='heading'] {
    //   this should be => background: #171A1C;
    background-color: ${props => getHeadingColor(props)};
    border-radius: 8px 8px 0px 0px;
    box-shadow: inset 0px -1px 0px #d8dbdf;
    height: 20px;

    > button {
      padding: 0;
    }

    > * {
      color: ${colors.grey100};
    }
  }

  .divider {
    height: 1px;
    background-color: ${colors.grey100};
    margin: 0 8px;
  }

  [data-garden-id='accordions.rotate_icon'] {
    display: none;
  }
`

const StyledAccordionPanel = styled(Accordion.Panel)`
  background: ${colors.background};
  padding: 0 8px 8px;

  &[aria-hidden='true'] {
    padding: 0;
  }
`

const AccordionHeaderButton = styled(IconButton)`
  background-color: transparent !important;
  height: 40px;
  width: 40px;
  min-width: 40px;

  svg {
    width: 16px;
  }
  :hover,
  :focus {
    // background-color: initial;
  }

  &[aria-label='close'] {
    margin-left: 8px;
  }
`

const AccordionInfoWrapper = styled.div`
  margin-top: 8px;
  background: ${colors.background};
`

const AccordionListWrapper = styled.div`
  max-height: 160px;
  overflow-y: auto;
`

const ErrorsNumber = styled.div`
  padding: 0 8px;
`
const AnimatedAccordion = animated(FixedAccordion)

const getAlerts = props => {
  const alerts = [
    {
      type: 'info',
      title: "Please don't leave the window.",
    },
  ]
  if (props.poorConnection) {
    alerts.push({
      type: 'warning',
      title: 'Poor connection.',
    })
  }
  return alerts
}

const UploadAccordion = ({ ...props }) => {
  const [isAccordionVisible, setIsAccordionVisible] = useState(true)
  const [open, setOpen] = useState(true)
  const alertsToShow = getAlerts(props)

  const showHideAnimation = useSpring({
    config: { duration: 320 },
    from: {
      marginBottom: -400,
    },
    to: {
      marginBottom: isAccordionVisible ? 0 : -400,
    },
  })
  const hideAccordion = e => {
    e.stopPropagation()
    e.preventDefault()
    setIsAccordionVisible(false)
  }

  const toggleIconAnimation = useSpring({
    config: { duration: 120 },
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  })
  const handleToggle = () => {
    setOpen(!open)
  }

  const { addBeforeUnloadEvent, removeBeforeUnloadEvent } = useEventsListener()

  useEffect(() => {
    if (props.completed) {
      removeBeforeUnloadEvent()
    } else {
      addBeforeUnloadEvent()
    }
  }, [props.completed])

  return (
    <AnimatedAccordion
      {...props}
      level={4}
      onChange={handleToggle}
      style={showHideAnimation}
    >
      <Accordion.Section>
        <StyledAccordionHeader>
          <Accordion.Label>
            <FlexLabel direction="row" justifyContent="space-between">
              <div>
                {props.completed
                  ? `Uploading complete`
                  : `Uploading: ${props.remaining} of ${props.total} videos left`}
              </div>
              {!!props.errorsNumber && (
                <ErrorsNumber>
                  {props.errorsNumber} error{props.errorsNumber > 1 && 's'}
                </ErrorsNumber>
              )}
            </FlexLabel>
          </Accordion.Label>
          <animated.div style={toggleIconAnimation}>
            <AccordionHeaderButton
              icon={ChevronUpIcon}
              isPrimary
              aria-label="toggle"
            />
          </animated.div>
          {props.completed && (
            <AccordionHeaderButton
              icon={CloseIcon}
              isPrimary
              onClick={e => {
                hideAccordion(e)
                props.onExit()
              }}
              aria-label="close"
            />
          )}
        </StyledAccordionHeader>
        <StyledAccordionPanel>
          {!props.completed && (
            <AccordionInfoWrapper>
              <AlertRow items={alertsToShow} />
            </AccordionInfoWrapper>
          )}
          <AccordionListWrapper>
            {props.items.map((item, index) => (
              <div key={item.title}>
                <UploadingItem
                  hasError={item.hasError}
                  uploading={item.uploading}
                  poorConnection={props.poorConnection}
                  {...item}
                />
                {index !== props.items.length - 1 && (
                  <div className="divider" />
                )}
              </div>
            ))}
          </AccordionListWrapper>
        </StyledAccordionPanel>
      </Accordion.Section>
    </AnimatedAccordion>
  )
}

UploadAccordion.propTypes = {
  onExit: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  remaining: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  completed: PropTypes.bool,
  poorConnection: PropTypes.bool,
  errorsNumber: PropTypes.number,
}

UploadAccordion.defaultProps = {
  onExit: () => {},
  completed: false,
  poorConnection: false,
  errorsNumber: 0,
}

export default UploadAccordion
