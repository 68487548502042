import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../Flex'

const Wrapper = styled(Flex)`
  width: 90%;
  max-width: 460px;
  height: 4px;
  background-color: ${prop('theme.colors.grey200')};
  position: relative;
  margin-top: 16px;
`

const ProgressBarProgress = styled(Flex)`
  width: ${prop('width')}%;
  height: 4px;
  background-color: ${prop('theme.colors.green')};
  position: absolute;
  left: 0;
  top: 0;
`

const ProgressBar = ({ width, ...props }) => (
  <Wrapper {...props}>
    <ProgressBarProgress className="progress-bar" width={width} />
  </Wrapper>
)

ProgressBar.propTypes = {
  width: PropTypes.number,
}

ProgressBar.defaultProps = {
  width: 0,
}

export default ProgressBar
