import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import prop from 'lodash/fp/prop'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import { Input } from '@zendeskgarden/react-forms'
import { XL } from '@zendeskgarden/react-typography'
import { isMobile } from '../../../lib/platform'
import MobileModal from '../MobileModal'
import IconButton from '../IconButton'
import Flex from '../../atoms/Flex'

const StyledBody = styled(Body)`
  padding: 0 40px 24px 40px;
  align-items: center;
  justify-content: center;
  height: auto;
`

const CopyButton = styled(IconButton).attrs({
  size: 'large',
  isStretched: false,
})`
  order: 0;
  align-self: auto;
  width: fit-content;
  margin-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
`

const StyledXL = styled(XL)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: center;
`

const StyledInput = styled(Input)`
  background-color: ${prop('theme.colors.grey100')};
  padding: 18px;
  text-align: center;
  font-size: 16px;
`

const BUTTON_LABELS = {
  default: 'Copy to clipboard',
  copied: 'Copied!',
}

const RequestRecordingLinkModal = ({ title, url, isVisible, onClose }) => {
  const [isModalVisible, setModalVisible] = useState(isVisible)

  useEffect(() => {
    setModalVisible(isVisible)
  }, [isVisible])

  const onModalClose = () => {
    setModalVisible(false)
    onClose()
  }

  const [copyButtonText, setCopyButtonText] = useState(BUTTON_LABELS.default)

  const ModalComponent = isMobile() ? MobileModal : Modal
  return (
    <div>
      {isModalVisible && (
        <ModalComponent isAnimated={false}>
          <Header>{title}</Header>
          <StyledBody>
            <Flex style={{ marginBottom: '24px' }}>
              <StyledXL>Recruit participants by sharing this link </StyledXL>
              <Flex direction="row">
                <StyledInput type="text" defaultValue={url} />
              </Flex>
              <Flex alignItems="center">
                <CopyButton
                  isPrimary
                  icon={ContentCopyIcon}
                  onClick={() => {
                    copy(url)
                    setCopyButtonText(BUTTON_LABELS.copied)
                    setTimeout(() => {
                      if (copyButtonText) {
                        setCopyButtonText(BUTTON_LABELS.default)
                      }
                    }, 2000)
                  }}
                >
                  {copyButtonText}
                </CopyButton>
              </Flex>
            </Flex>
          </StyledBody>
          <Close aria-label="Close modal" onClick={onModalClose} />
        </ModalComponent>
      )}
    </div>
  )
}

RequestRecordingLinkModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
}

RequestRecordingLinkModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  title: '',
  url: '',
}

export default RequestRecordingLinkModal
