import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Player from './Player'
import { PAGE_EVENT_TYPES } from '../../organisms/PageEvents/pageEvents.constants'

export const filterEvents = events =>
  events.filter(
    event =>
      event.type === PAGE_EVENT_TYPES.click ||
      event.type === PAGE_EVENT_TYPES.navigation
  )

export const isPageEventCurrent = (event, currentVideoTime) =>
  event.videoTime >= currentVideoTime

export const mapCurrentPageEvent = currentVideoTime => event => {
  const isCurrent = isPageEventCurrent(event, currentVideoTime)
  return {
    ...event,
    isCurrent,
  }
}

export const adaptPageEvents = (events = [], currentVideoTime) =>
  filterEvents(events).map(mapCurrentPageEvent(currentVideoTime))

const PageEventsContainer = ({
  pageEvents,
  currentPositionSecs,
  videoLengthSecs,
  tickers,
  ...props
}) => {
  const filteredTickers = useMemo(
    () =>
      tickers.map(ticker => {
        if (ticker.videoPositionSecs > videoLengthSecs) {
          return {
            ...ticker,
            videoPositionSecs: videoLengthSecs,
          }
        }
        return ticker
      }),
    [videoLengthSecs, tickers?.length]
  )

  const filteredEvents = useMemo(
    () =>
      pageEvents.filter(pageEvent => pageEvent.videoTime <= videoLengthSecs),
    [videoLengthSecs, pageEvents?.length]
  )
  const adaptedEvents = adaptPageEvents(filteredEvents, currentPositionSecs)
  return (
    <Player
      pageEvents={adaptedEvents}
      currentPositionSecs={currentPositionSecs}
      videoLengthSecs={videoLengthSecs}
      tickers={filteredTickers}
      {...props}
    />
  )
}

PageEventsContainer.propTypes = {
  pageEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentPositionSecs: PropTypes.number,
  videoLengthSecs: PropTypes.number,
  tickers: PropTypes.arrayOf(PropTypes.shape({ startTime: PropTypes.number })),
}

PageEventsContainer.defaultProps = {
  currentPositionSecs: 0,
  videoLengthSecs: 100000000000,
  tickers: [],
}

export default PageEventsContainer
