import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import LockIcon from 'mdi-react/LockIcon'
import EmptyStateMobile from '../../molecules/EmptyStateMobile'
import Flex from '../../atoms/Flex'
import useUserInfo from '../../../store/hooks/useUserInfo'

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const NotFoundMobile = ({ ...props }) => {
  const { isLogged } = useUserInfo()
  const history = useHistory()

  const button = isLogged
    ? {
        text: 'Back to Home',
        onClick: () => {
          history.push('/')
        },
      }
    : {
        text: 'Login',
        onClick: () => {
          history.push('/login')
        },
      }

  const secondaryButton = !isLogged
    ? {
        text: 'Join now',
        onClick: () => {
          history.push('/register')
        },
      }
    : null

  return (
    <Wrapper {...props}>
      <EmptyStateMobile
        icon={<LockIcon size={48} />}
        button={button}
        secondaryButton={secondaryButton}
      >
        You need permission to access
      </EmptyStateMobile>
    </Wrapper>
  )
}

export default NotFoundMobile
