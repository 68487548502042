import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import PasswordSettingModal from './PasswordSettingModal'
import { isGoogleLogin } from '../../../util/auth'

const Wrapper = styled(Flex)``

const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`
const Label = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  font-weight: 500;
`
const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const Edit = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  margin-left: 16px;
`

const PasswordSetting = ({ ...props }) => {
  const [isModalVisible, setModalVisible] = useState(false)
  return isGoogleLogin() ? null : (
    <>
      <Wrapper {...props}>
        <SettingRow>
          <Label>Password</Label>
          <Edited>
            <Edit>
              <Button isBasic onClick={() => setModalVisible(true)}>
                Edit
              </Button>
            </Edit>
          </Edited>
        </SettingRow>
      </Wrapper>
      <PasswordSettingModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}

PasswordSetting.propTypes = {
  name: PropTypes.string,
}

PasswordSetting.defaultProps = {
  name: '',
}

export default PasswordSetting
