import { useState, useLayoutEffect, useRef } from 'react'
import { createPopper } from '@popperjs/core'

/*
export interface IUsePopperOptions {
  referenceRef: React.RefObject<HTMLElement>;
  popperRef: React.RefObject<HTMLElement>;
  modifiers?: Modifiers;
  placement?: Placement;
}

export interface IUsePopperState {
  data?: Data;
  placement?: Placement;
}
*/

const usePopper = conf => {
  const {
    referenceRef,
    popperRef,
    modifiers = [],
    placement = 'top',
    eventsEnabled = true,
    positionFixed = false,
  } = conf

  const [state] = useState(() => ({
    data: undefined,
    placement: undefined,
  }))
  const popperInstanceRef = useRef()

  useLayoutEffect(() => {
    if (referenceRef.current && popperRef.current) {
      popperInstanceRef.current = createPopper(
        referenceRef.current,
        popperRef.current,
        {
          placement,
          strategy: 'absolute',

          modifiers: [
            ...modifiers,
            /* 
            {
              name: 'missingTrigger',
              enabled: true,
              phase: 'main',
              fn: data => {
                return data
              },
            },
            {
              name: 'updateStateModifier',
              enabled: true,
              phase: 'main',
              fn: data => {
                const { placement: _placement } = data
                console.log('>>> updateStateModifier placement', placement)

                setState({ data, placement: _placement })

                return data
              },
            }, 
            */
          ],
        }
      )
    }

    return () => {
      if (popperInstanceRef.current) {
        popperInstanceRef.current.destroy()
        popperInstanceRef.current = null
      }
    }
  }, [
    popperRef?.current,
    referenceRef?.current,
    modifiers,
    placement,
    positionFixed,
    eventsEnabled,
  ])

  const style =
    !popperRef.current || !state.data
      ? {
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          pointerEvents: 'none',
        }
      : {
          ...state.data.styles,
        }

  return {
    style,
    placement: state.placement,
    outOfBoundaries: state.data && state.data.hide,
    scheduleUpdate:
      popperInstanceRef.current && popperInstanceRef.current.scheduleUpdate,
  }
}

export default usePopper
