import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import { getCommentUrl, getSessionUrl } from '../../../lib/urls'
import CreateTrelloCardModal from './CreateTrelloCardModal'
import fromIntegrationTrello from '../../../store/integrations/trello/integrations.trello.selectors'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import useLocalStorage from '../../hooks/useLocalStorage'
import useTrello from '../../hooks/useTrello'

const CreateTrelloCardModalContainer = ({ sessionId, commentId, ...props }) => {
  const boards = useSelector(fromIntegrationTrello.getTrelloBoards)
  const lists = useSelector(fromIntegrationTrello.getTrelloLists)

  const [trelloBoardId, saveTrelloBoardId] = useLocalStorage(
    'trello-last-board-id',
    ''
  )

  const dispatch = useDispatch()
  const { fetchTrelloResources, onCreateTrelloCard } = useTrello()
  useEffect(() => {
    fetchTrelloResources()
  }, [])

  return (
    <CreateTrelloCardModal
      {...props}
      sessionId={sessionId}
      boards={boards}
      lists={lists}
      initialTrelloBoardId={trelloBoardId}
      onBoardChange={selectedBoard => {
        saveTrelloBoardId(selectedBoard.id)
      }}
      onCreate={values =>
        onCreateTrelloCard({
          ...values,
          sourceUrl: commentId
            ? getCommentUrl(commentId)
            : getSessionUrl(sessionId),
        }).then(trelloCard =>
          dispatch(
            addNotification({
              notificationId: uuidv1(),
              text: 'Trello card created',
              isPersisted: true,
              callToAction: {
                type: 'link',
                link: trelloCard?.data?.url,
                text: 'OPEN',
              },
            })
          )
        )
      }
    />
  )
}

CreateTrelloCardModalContainer.propTypes = {
  sessionId: PropTypes.string,
  commentId: PropTypes.string,
}

CreateTrelloCardModalContainer.defaultProps = {
  sessionId: null,
  commentId: null,
}

export default connect(
  null,
  null
)(CreateTrelloCardModalContainer)
