/* eslint-disable no-unused-vars */
import get from 'lodash/get'
import moment from 'moment'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
  race,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import Storage from '@aws-amplify/storage'
import gql from 'graphql-tag'
import { listRemoteTests as listRemoteTestsGql } from '../../graphql/queries'
import {
  createRemoteTest as createRemoteTestGql,
  updateRemoteTest as updateRemoteTestGql,
  deleteRemoteTest as deleteRemoteTestGql,
  deleteRemoteTestSession as deleteRemoteTestSessionGql,
} from '../../graphql/mutations'
import {
  onCreateRemoteTest,
  onUpdateRemoteTest,
  onDeleteRemoteTest,
} from '../../graphql/subscriptions'
import fromProjects from '../projects/projects.selectors'
import fromUser from '../user/user.selectors'
import { SET_CURRENT_PROJECT } from '../projects/projects.actions'
import { deleteSessionRequestSuccess } from '../sessions/sessions.actions'
import { LOGIN_REQUEST_SUCCESS } from '../actions'
import {
  CREATE_REMOTE_TEST_REQUEST,
  createRemoteTestRequestSuccess,
  createRemoteTestRequestFailure,
  UPDATE_REMOTE_TEST_REQUEST,
  updateRemoteTestRequestSuccess,
  updateRemoteTestRequestFailure,
  DELETE_REMOTE_TEST_REQUEST,
  deleteRemoteTestRequestSuccess,
  deleteRemoteTestRequestFailure,
  DELETE_REMOTE_TEST_SESSION_REQUEST,
  deleteRemoteTestSessionRequestSuccess,
  deleteRemoteTestSessionRequestFailure,
  READ_ALL_REMOTE_TEST_REQUEST,
  readAllRemoteTestRequest,
  readAllRemoteTestRequestSuccess,
  readAllRemoteTestRequestFailure,
  UPLOAD_REMOTE_TEST_SCREEN_RECORDING,
  updateRemoteTestUploadProgress,
} from './remoteTests.actions'
import { activityBasedAction } from '../utils.sagas'
import { getBrowserName, getBrowserVersion } from '../../lib/platform'

export function* fetchRemoteTests({ apolloClient, projectId }) {
  let _projectId = projectId
  if (!_projectId) {
    _projectId = yield select(fromProjects.getCurrentProjectId)
  }

  const remoteTestsResponse = yield call(apolloClient.query, {
    query: gql(listRemoteTestsGql),
    fetchPolicy: 'no-cache',
    variables: {
      projectId: _projectId,
    },
  })

  const items = get(remoteTestsResponse, 'data.listRemoteTests.items')
  return items
}

function* createRemoteTest(
  { apolloClient },
  { payload: { projectId, title, sections }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(createRemoteTestGql),
      fetchPolicy: 'no-cache',
      variables: {
        projectId,
        title,
        sections,
      },
    })

    if (data) {
      yield put(createRemoteTestRequestSuccess(data, thunk))
    } else {
      yield put(
        createRemoteTestRequestFailure(
          { error: 'Failed to create a new session' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(createRemoteTestRequestFailure(e, thunk))
  }
}

function* updateRemoteTest(
  { apolloClient },
  { payload: { remoteTestId, title, sections }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(updateRemoteTestGql),
      fetchPolicy: 'no-cache',
      variables: {
        remoteTestId,
        title,
        sections,
      },
    })

    if (data) {
      yield put(updateRemoteTestRequestSuccess(data, thunk))
    } else {
      yield put(
        updateRemoteTestRequestFailure(
          { error: 'Failed to update a new session' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(updateRemoteTestRequestFailure(e, thunk))
  }
}

function* deleteRemoteTest(
  { apolloClient },
  { payload: { remoteTestId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(deleteRemoteTestGql),
      fetchPolicy: 'no-cache',
      variables: {
        remoteTestId,
      },
    })

    if (data) {
      yield put(deleteRemoteTestRequestSuccess(data, thunk))
    } else {
      yield put(
        deleteRemoteTestRequestFailure(
          { error: 'Failed to delete a new session' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(deleteRemoteTestRequestFailure(e, thunk))
  }
}

function* deleteRemoteTestSession(
  { apolloClient },
  { payload: { sessionId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(deleteRemoteTestSessionGql),
      fetchPolicy: 'no-cache',
      variables: {
        sessionId,
      },
    })

    if (data) {
      yield put(deleteRemoteTestSessionRequestSuccess(data, thunk))
      yield put(deleteSessionRequestSuccess({ sessionId }, 'thunk'))
    } else {
      yield put(
        deleteRemoteTestSessionRequestFailure(
          { error: 'Failed to delete a new session' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(deleteRemoteTestSessionRequestFailure(e, thunk))
  }
}

function* readAllRemoteTests(
  { apolloClient },
  { payload: { projectId }, meta: { thunk } }
) {
  try {
    const data = yield call(fetchRemoteTests, { apolloClient, projectId })
    yield put(
      readAllRemoteTestRequestSuccess({ remoteTests: data, projectId }, thunk)
    )
  } catch (e) {
    console.error(e)
    yield put(readAllRemoteTestRequestFailure({}, thunk))
  }
}

function* watchForRemoteTests({ apolloClient }) {
  try {
    yield call(fetchRemoteTests, { apolloClient })

    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateRemoteTest),
        })
        .subscribe({
          next: () => {
            emitter('new-user-test-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateRemoteTest),
        })
        .subscribe({
          next: () => {
            emitter('update-user-test-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const deleteSubscription = apolloClient
        .subscribe({
          query: gql(onDeleteRemoteTest),
        })
        .subscribe({
          next: () => {
            emitter('delete-user-test-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        updateSubscription.unsubscribe()
        deleteSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      const projectId = yield select(fromProjects.getCurrentProjectId)
      yield put(readAllRemoteTestRequest({ projectId }))
    }
  } catch (e) {
    console.error(e)
  }
}

function* uploadRemoteTestVideo(action) {
  const {
    payload: { projectId, remoteTestId, content, mimeType },
  } = action
  const uploadConf = {
    level: 'public',
    content,
    contentType: mimeType,
    metadata: {
      remoteTestId,
      projectId: projectId.replace('project:', ''),
      browserName: getBrowserName(),
      browserVersion: getBrowserVersion(),
    },
  }

  const progressChannel = eventChannel(emitter => {
    uploadConf.progressCallback = progress => {
      console.log(`Video upload: ${progress.loaded}/${progress.total}`)
      emitter(progress)
    }
    return () => {}
  })

  const fileExtension = mimeType.indexOf('webm') !== -1 ? 'webm' : 'mp4'
  const fileName = `${remoteTestId}-${moment().format(
    'YYYYMMDDHHmmss'
  )}.${fileExtension}`

  const uploadCompleteChannel = eventChannel(emitter => {
    Storage.put(fileName, content, uploadConf).then(() => {
      emitter('ok')
    })
    return () => {}
  })

  while (true) {
    const { progress, complete } = yield race({
      progress: take(progressChannel),
      complete: take(uploadCompleteChannel),
    })
    console.log('>>>', { progress, complete })

    if (complete) {
      yield put(updateRemoteTestUploadProgress(100, remoteTestId))
      break
    }
    const percentage = Math.ceil((progress.loaded / progress.total) * 100)
    yield put(updateRemoteTestUploadProgress(percentage, remoteTestId))
  }
}

function* dispatchFetchRemoteTests() {
  const isLogged = yield select(fromUser.isLogged)
  if (!isLogged) {
    return
  }
  const projectId = yield select(fromProjects.getCurrentProjectId)
  yield put(readAllRemoteTestRequest({ projectId }))
}

function* startSessionsFork({ apolloClient }) {
  yield fork(watchForRemoteTests, { apolloClient })
  yield call(dispatchFetchRemoteTests)
  yield fork(activityBasedAction, dispatchFetchRemoteTests, 26000, {
    apolloClient,
  })
}

export default function* remoteTestSagas({ apolloClient }) {
  /*
  yield all([
    yield takeLatest(LOGIN_REQUEST_SUCCESS, startSessionsFork, {
      apolloClient,
    }),
    yield takeLatest(CREATE_REMOTE_TEST_REQUEST, createRemoteTest, {
      apolloClient,
    }),
    yield takeLatest(UPDATE_REMOTE_TEST_REQUEST, updateRemoteTest, {
      apolloClient,
    }),
    yield takeLatest(DELETE_REMOTE_TEST_REQUEST, deleteRemoteTest, {
      apolloClient,
    }),
    yield takeLatest(
      DELETE_REMOTE_TEST_SESSION_REQUEST,
      deleteRemoteTestSession,
      {
        apolloClient,
      }
    ),
    yield takeLatest(READ_ALL_REMOTE_TEST_REQUEST, readAllRemoteTests, {
      apolloClient,
    }),
    yield takeLatest(SET_CURRENT_PROJECT, dispatchFetchRemoteTests),
    yield takeLatest(
      UPLOAD_REMOTE_TEST_SCREEN_RECORDING,
      uploadRemoteTestVideo
    ),
  ])
  */
}
