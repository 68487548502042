import React from 'react'
import styled from 'styled-components'
import Brand from '../../atoms/Brand'

const StyledBrand = styled(Brand)`
  width: 50%;
`

const OnboardingBrand = ({ ...props }) => <StyledBrand {...props} />

export default OnboardingBrand
