import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadVideoFiles, uploadScreenRecording } from '../../store/actions'
import fromSelectors from '../../store/selectors'
import { resetPendingUploads } from '../../store/uploads/upload.actions'

const useRecordingUpload = inputRef => {
  const dispatch = useDispatch()

  const onUpload = useCallback(() => {
    inputRef.current.click()
  }, [inputRef])

  const createNewSessionHandler = useCallback(
    files => {
      dispatch(resetPendingUploads())
      dispatch(uploadVideoFiles(files))
    },
    [dispatch]
  )

  const screenRecordingHandler = useCallback(
    ({ content, mimeType }) => {
      dispatch(uploadScreenRecording({ content, mimeType }))
    },
    [dispatch]
  )

  const handleFiles = callback => event => {
    // Create new reference to store file items
    // So data won't be lost while cleaning the input value
    const files = [...event.target.files]
    // Create a handler for the new upcoming session
    createNewSessionHandler(files)
    // Let the callee know everything is cool now
    if (callback) callback()
    // This is needed to reset the file type input and allow multiple submissions
    // eslint-disable-next-line no-param-reassign
    inputRef.current.value = ''
  }

  const uploadProgress = useSelector(fromSelectors.getUploadProgress)
  const remoteVideoConversionProgress = useSelector(
    fromSelectors.getUploadVideoConversionProgress
  )
  const remoteVideoSpritesProgress = useSelector(
    fromSelectors.getUploadVideoSpritesProgress
  )
  const uploadSessionId = useSelector(fromSelectors.getUploadSessionId)

  return {
    createNewSessionHandler,
    handleFiles,
    onUpload,
    remoteVideoConversionProgress,
    remoteVideoSpritesProgress,
    uploadProgress,
    uploadSessionId,
    screenRecordingHandler,
  }
}

export default useRecordingUpload
