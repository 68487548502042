import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import take from 'lodash/fp/take'
import sortBy from 'lodash/fp/sortBy'
import find from 'lodash/fp/find'
import values from 'lodash/fp/values'
import reverse from 'lodash/reverse'
import { createSelector } from 'reselect'
import { STATE_KEY } from './remoteTests.reducers'
import fromProjects from '../projects/projects.selectors'

const getState = state => getImmutable(state, STATE_KEY)

const getCurrentRemoteTestId = createSelector(
  getState,
  get('current')
)

const getSessionsMap = createSelector(
  getState,
  pipe(
    get('byId'),
    values
  )
)

const getRemoteTests = createSelector(
  getState,
  state => {
    const sessionsMap = get('byId')(state)
    const res = pipe(
      values,
      sortBy([el => el.createdAt]),
      reverse
    )(sessionsMap)

    return res
  }
)

const getRemoteTestById = remoteTestId =>
  createSelector(
    getRemoteTests,
    remoteTests =>
      remoteTests.find(remoteTest => remoteTest.pk === remoteTestId) ||
      undefined
  )

const getRemoteTestsByCurrentProject = createSelector(
  getRemoteTests,
  fromProjects.getCurrentProjectId,
  (remoteTests, projectId) =>
    remoteTests.filter(remoteTest => remoteTest.projectId === projectId)
)

const getRemoteTestsByProjectId = projectId =>
  createSelector(
    getRemoteTests,
    remoteTests =>
      remoteTests.filter(remoteTest => remoteTest.projectId === projectId)
  )

const getCurrentRemoteTest = createSelector(
  getCurrentRemoteTestId,
  getRemoteTests,
  (remoteTestId, remoteTests) => find(x => x.pk === remoteTestId)(remoteTests)
)

export const byUpdate = value => {
  return value.updatedAt ? value.updatedAt : value.createdAt
}

const getRemoteTestUploadProgress = remoteTestId =>
  createSelector(
    getState,
    get(`publicUploads.${remoteTestId}.progress`)
  )

const getRecentRemoteTests = (limit = 10) =>
  createSelector(
    getRemoteTests,
    pipe(
      sortBy(byUpdate),
      reverse,
      take(limit)
    )
  )

const getAllSessionsId = createSelector(
  getState,
  get('allIds')
)

export default {
  getAllSessionsId,
  getCurrentRemoteTest,
  getCurrentRemoteTestId,
  getRecentRemoteTests,
  getRemoteTestById,
  getRemoteTests,
  getRemoteTestsByCurrentProject,
  getRemoteTestsByProjectId,
  getSessionsMap,
  getRemoteTestUploadProgress,
}
