import compact from 'lodash/compact'
import concat from 'lodash/concat'
import first from 'lodash/first'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import getFp from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import pipe from 'lodash/fp/pipe'
import sortedUniq from 'lodash/sortedUniq'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { done, fulfilled, pending, rejected } from 'redux-saga-thunk'
import api from '../../../api'
import video from '../../../lib/video'
import {
  DESKTOP_PULL_RECORDING_REQUEST,
  startRecordingRequest,
  stopRecording,
} from '../../../desktop/store/actions'
import {
  createSessionResourcesRequest,
  DELETE_SESSION_REQUEST,
  deleteSessionRequest,
  setCurrentSession,
  UPDATE_SESSION_REQUEST,
  updateSessionRequest,
} from '../../../store/sessions/sessions.actions'
import {
  createCommentResolutionRequest,
  deleteCommentRequest,
  getSessionCommentsRequest,
} from '../../../store/comments/comments.actions'
import { createReplyRequest } from '../../../store/replies/replies.actions'
import { setRegistrationData } from '../../../store/user/user.actions'
import { getUsersRequest } from '../../../store/users/users.actions'
import { trackSessionView } from '../../../store/analytics/analytics.actions'
import { uploadExtensionRecording } from '../../../store/actions'
import fromDesktop from '../../../desktop/store/selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromUser from '../../../store/user/user.selectors'
import fromUsers from '../../../store/users/users.selectors'
import fromComments from '../../../store/comments/comments.selectors'
import useUpdateSession from '../../../store/hooks/useUpdateSession'
import useReduxSagaThunkButton from '../../../store/hooks/useReduxSagaThunkButton'
import useFetchPublicSessionUsers from '../../../store/hooks/useFetchPublicSessionUsers'
import useCRUDComment from '../../../store/hooks/useCRUDComment'
import useCRUDPageEvents from '../../../store/hooks/useCRUDPageEvents'
import { COMMENT_RESOLUTION_STATES } from '../../../store/comments/comments.constants'
import { getDefaultSessionTitle } from '../../../util/utils'
import { sortCommentsByPositionFn } from './SessionView.utils'

const PERMISSIONS = {
  admin: 'admin',
  edit: 'edit',
  view: 'view',
}

const getCreators = arr => (arr ? arr.map(getFp('createdBy')) : [])
const getCommentsAndRepliesUserIds = comments =>
  sortedUniq(
    concat(
      getCreators(comments),
      flatMap(
        comments.map(
          pipe(
            getOr([], 'replies'),
            getCreators
          )
        )
      )
    )
  )

const getCurrentUserPermission = (
  userIdentityId,
  userEmail,
  session = {},
  project = {}
) => {
  if (session?.createdBy === userIdentityId) {
    return PERMISSIONS.admin
  }

  if (project?.createdBy === userIdentityId) {
    return PERMISSIONS.admin
  }

  const projectPermission = Object.keys(PERMISSIONS).reduce(
    (acc, value) =>
      project[value] && project[value].includes(userEmail) ? value : acc,
    PERMISSIONS.view
  )

  if ([PERMISSIONS.admin, PERMISSIONS.edit].includes(projectPermission)) {
    return projectPermission
  }

  return Object.keys(PERMISSIONS).reduce(
    (acc, value) =>
      session[value] && session[value].includes(userEmail) ? value : acc,
    PERMISSIONS.view
  )
}

const isPending = state => pending(state, DESKTOP_PULL_RECORDING_REQUEST)
const isComplete = state => done(state, DESKTOP_PULL_RECORDING_REQUEST)
const isFullfilled = state => fulfilled(state, DESKTOP_PULL_RECORDING_REQUEST)
const isRejected = state => rejected(state, DESKTOP_PULL_RECORDING_REQUEST)

const getVideoMimeType = url => {
  if (url.startsWith('blob:')) {
    return 'video/webm'
  }
  const baseUrl = first(url.split('?'))
  return `video/${last(baseUrl.split('.'))}`
}

const getVideoInfo = videoUrl =>
  // eslint-disable-next-line no-new
  new Promise(resolve => {
    const videoNode = document.createElement('video')
    // needed to work on iOS
    videoNode.setAttribute('loop', false)
    videoNode.setAttribute('autoplay', false)
    videoNode.setAttribute('controls', true)
    videoNode.setAttribute('width', '100%')
    videoNode.setAttribute('height', '100%')
    const videoMimeType = getVideoMimeType(videoUrl)
    videoNode.setAttribute('type', videoMimeType)

    videoNode.style.display = 'none'
    videoNode.addEventListener('canplay', () => {
      const videoInfo = {
        width: videoNode.videoWidth,
        height: videoNode.videoHeight,
        duration: videoNode.duration,
      }
      document.body.removeChild(videoNode)
      resolve(videoInfo)
    })
    videoNode.src = videoUrl
    document.body.appendChild(videoNode)
  })

const useSessionView = ({ isEditMode, isAnonymous, isTemporaryRecording }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userId = useSelector(fromUser.getId)
  const userName = useSelector(fromUser.getName)
  const userEmail = useSelector(fromUser.getEmail)
  const userPicture = useSelector(fromUser.getPicture)
  const userIdentityId = useSelector(fromUser.getIdentityId)
  const isUserLogged = useSelector(fromUser.isLogged)
  const currentDeviceId = useSelector(fromDesktop.getCurrentDevice)
  const sessionId = useSelector(
    !isTemporaryRecording ? fromSessions.getCurrentSessionId : () => null
  )
  const session = useSelector(fromSessions.getCurrentSession)
  const currentProjectId = session?.projectId
  const currentProject = useSelector(
    fromProjects.getProjectById(currentProjectId)
  )

  useEffect(() => {
    if (!isTemporaryRecording) {
      dispatch(
        trackSessionView({
          sessionId,
          projectId: currentProjectId,
          organizationId: currentProject?.organizationId,
        })
      )
    }
  }, [sessionId, currentProjectId, isTemporaryRecording])

  const isRecording = useSelector(fromDesktop.getIsRecording)
  const isRecordingPullInProgress = useSelector(isPending)
  const isRecordingPullError = useSelector(isRejected)
  const isRecordingPullSuccess = useSelector(isFullfilled)
  const isRecordingPullDone = useSelector(isComplete)
  const comments = useSelector(fromComments.getSessionComments(sessionId))

  const commentsWithNumber = useMemo(
    () =>
      comments
        .sort((a, b) => a.createdAt - b.createdAt)
        .map((comment, index) => ({
          ...comment,
          number: index + 1,
        }))
        .sort(sortCommentsByPositionFn),
    [comments]
  )

  const sessionCreatorUser = useSelector(
    fromUsers.getUserById(get(session, 'createdBy'))
  )

  const isUserOwnSession = get(session, 'createdBy') === userIdentityId
  const areCommentsAllowed = get(session, 'sharing.areCommentsAllowed', false)
  const userCanContribute = useMemo(() => {
    const _userPermission = getCurrentUserPermission(
      userIdentityId,
      userEmail || userId,
      session,
      currentProject
    )

    return (
      [PERMISSIONS.admin, PERMISSIONS.edit].includes(_userPermission) ||
      areCommentsAllowed
    )
  }, [session, areCommentsAllowed, currentProject])

  const onStartRecording = useCallback(
    () => dispatch(startRecordingRequest()),
    [dispatch]
  )
  const onStopRecording = useCallback(() => dispatch(stopRecording()), [
    dispatch,
  ])
  const setSession = useCallback(
    _sessionId => dispatch(setCurrentSession(_sessionId)),
    [dispatch]
  )

  const [areCommentsRetrieved, setCommentsRetrieved] = useState(null)
  const getCommentsDispatch = useCallback(
    _sessionId => {
      dispatch(getSessionCommentsRequest(_sessionId))
      setCommentsRetrieved(new Date().getTime())
    },
    [dispatch, setCommentsRetrieved]
  )

  useEffect(() => {
    if (sessionId) {
      getCommentsDispatch(sessionId)
    }
  }, [])

  const commentUserIds = useMemo(() => getCommentsAndRepliesUserIds(comments), [
    comments?.length,
    areCommentsRetrieved,
  ])
  const [userListIds, setUserListIds] = useState([])
  useEffect(() => {
    const usersList = compact(commentUserIds.concat(session?.createdBy))
    if (areCommentsRetrieved && !isEqual(usersList, userListIds)) {
      setUserListIds(usersList)
      dispatch(getUsersRequest(usersList))
    }
    return () => {}
  }, [areCommentsRetrieved, commentUserIds, session?.createdBy])

  const isPublic = session?.sharing?.isPublic
  useFetchPublicSessionUsers({
    isPublicSession: isPublic,
    currentProject,
    currentProjectId,
  })

  const sessionTitle = get(session, 'title')

  const { updateSession } = useUpdateSession(sessionId)

  const videoPath = session?.videoPath || ''
  const duration = session?.duration || 0

  const [editMode, setEditMode] = useState(isEditMode)
  const [videoUrl, setVideoUrl] = useState(videoPath)
  const [videoDuration, setVideoDuration] = useState(duration)

  useEffect(() => {
    setEditMode(isEditMode || !!videoPath)
  }, [isEditMode])

  useEffect(() => {
    setEditMode(isEditMode || !!videoPath)
    if (videoPath) {
      video.getVideoDuration(videoPath).then(setVideoDuration)
    }
  }, [videoPath])

  const [sprites, setSprites] = useState([])
  useEffect(() => {
    if (sessionId) {
      const getSprites = () => {
        const getSpritesFn = isAnonymous ? api.getPublicSprites : api.getSprites
        getSpritesFn(sessionId).then(({ data }) => {
          setSprites(data.sprites)
        })
      }
      getSprites()
      const spritesInterval = setInterval(() => getSprites(), 3 * 60 * 1000)
      return () => {
        clearInterval(spritesInterval)
      }
    }

    return () => {}
  }, [])

  const [isLinkModalVisible, setLinkModalVisible] = useState(false)

  const dispatchDeleteComment = useCallback(
    commentId => dispatch(deleteCommentRequest(commentId)),
    [dispatch]
  )
  const dispatchCreateReply = useCallback(
    (commentId, text) => dispatch(createReplyRequest(commentId, text)),
    [dispatch]
  )

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const onSessionDelete = useCallback(() => {
    dispatch(deleteSessionRequest(sessionId))
      .then(() => {
        history.push('/app')
      })
      .catch(err => {
        console.error('session deleted but an error occurred', err)
      })
  }, [dispatch])

  const [linkModalUrl, setLinkModalUrl] = useState('')

  const { buttonNode: renameSessionButtonNode } = useReduxSagaThunkButton(
    UPDATE_SESSION_REQUEST,
    {
      initialNode: 'Rename',
      pendingNode: 'Updating...',
      fulfilledNode: 'Updated!',
      rejectedNode: 'An error occurred',
    }
  )

  const { buttonNode: deleteSessionButtonNode } = useReduxSagaThunkButton(
    DELETE_SESSION_REQUEST,
    {
      initialNode: 'Delete',
      pendingNode: 'Deleting...',
      fulfilledNode: 'Deleted!',
      rejectedNode: 'An error occurred',
    }
  )

  const onAnonymousVideoClick = useCallback(
    ({ time, point: { percentage, absolute } }) => {
      dispatch(
        setRegistrationData({
          sessionId,
          time,
          point: { percentage, absolute },
        })
      )
    },
    [dispatch, sessionId]
  )

  const onAnonymourReplyFocus = useCallback(
    ({ commentId }) => {
      dispatch(
        setRegistrationData({
          commentId,
        })
      )
    },
    [dispatch]
  )

  const onUploadExtensionRecording = useCallback(
    blobUrl => dispatch(uploadExtensionRecording({ blobUrl })),
    [dispatch]
  )

  const { onCreateCommentHandler } = useCRUDComment()
  const onCreateComment = onCreateCommentHandler

  const { onReadPageEventsHandler } = useCRUDPageEvents()

  const [pageEvents, setPageEvents] = useState([])

  const onReadPageEvents = useCallback(
    () =>
      onReadPageEventsHandler(sessionId).then(pageEventsResponse => {
        const _pageEvents = pageEventsResponse?.pageEvents || []
        if (_pageEvents.length > 0) {
          setPageEvents(_pageEvents.sort((a, b) => a.timestamp < b.timestamp))
        }
      }),
    []
  )

  const onCreateSessionResourcesHandler = useCallback(
    (_comments, _pageEvents, _deviceMetaInfo) =>
      dispatch(
        createSessionResourcesRequest({
          sessionId,
          comments: _comments,
          pageEvents: _pageEvents,
          deviceMetaInfo: _deviceMetaInfo,
        })
      ),
    [dispatch]
  )

  const onUpdateSessionDescription = useCallback(
    debounce(
      sessionDescription =>
        dispatch(
          updateSessionRequest({ sessionId, description: sessionDescription })
        ),
      1500
    )
  )

  const onUpdateSessionTitle = useCallback(title =>
    dispatch(updateSessionRequest({ sessionId, title }))
  )

  useEffect(() => {
    if (!sessionTitle) {
      onUpdateSessionTitle(getDefaultSessionTitle(currentProject))
    }
  }, [sessionTitle, currentProject?.pk])

  useEffect(() => {
    if (sessionId) onReadPageEvents()
  }, [])

  const onResolveCommentClick = useCallback(({ commentId, isResolved }) =>
    dispatch(
      createCommentResolutionRequest({
        commentId,
        resolutionState: isResolved
          ? COMMENT_RESOLUTION_STATES.resolved
          : COMMENT_RESOLUTION_STATES.reopened,
      })
    )
  )

  const allUsers = useSelector(fromUsers.getAllUsers)

  return {
    isLinkModalVisible,
    setLinkModalVisible,
    dispatchDeleteComment,
    dispatchCreateReply,
    isDeleteModalVisible,
    setDeleteModalVisible,
    linkModalUrl,
    setLinkModalUrl,
    onSessionDelete,
    editMode,
    setEditMode,
    videoUrl,
    videoPath,
    setVideoUrl,
    videoDuration,
    setVideoDuration,
    comments: commentsWithNumber,
    currentDeviceId,
    currentProject,
    currentProjectId,
    getCommentsDispatch,
    isUserOwnSession,
    areCommentsAllowed,
    isRecording,
    isRecordingPullDone,
    isRecordingPullError,
    isRecordingPullInProgress,
    isRecordingPullSuccess,
    onStartRecording,
    onStopRecording,
    session: session || {},
    sessionId: sessionId || null,
    sessionTitle,
    setSession,
    userEmail: userEmail || '',
    userId: userId || '',
    userIdentityId: userIdentityId || '',
    userName: userName || '',
    userPicture: userPicture || '',
    isUserLogged,
    userCanContribute,
    getVideoInfo,
    sprites,
    setSprites,
    updateSession,
    renameSessionButtonNode,
    deleteSessionButtonNode,
    onAnonymousVideoClick,
    onAnonymourReplyFocus,
    onUploadExtensionRecording,
    onCreateComment,
    pageEvents,
    onCreateSessionResourcesHandler,
    onUpdateSessionDescription,
    onUpdateSessionTitle,
    onResolveCommentClick,
    allUsers,
    sessionCreatorUserName: sessionCreatorUser?.name,
    sessionCreatorPictureUrl: sessionCreatorUser?.picture,
  }
}

export default useSessionView
