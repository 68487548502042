const util = require('util')
const path = require('path')
const platform = require('./platform')

if (!window.require) {
  window.require = () => ({})
}

const IOS_DEVICE_SERVER = {
  host: '127.0.0.1',
  port: 1337,
  process: 'ios-recorder',
}
const SEPARATOR = '#'

const COMMANDS = {
  listDevices: 'LIST_DEVICES',
  startRecording: `START_RECORDING${SEPARATOR}`,
  stopRecording: `STOP_RECORDING${SEPARATOR}`,
}
const RESPONSES = {
  error: 'ERROR',
  devices: 'DEVICES',
}

const getBasePath = () => window.require('electron').remote.app.getAppPath()
const getAppPath = () =>
  getBasePath().indexOf('app.asar') > 0
    ? getBasePath().replace('/app.asar', '')
    : getBasePath()

const getDependencies = () => {
  if (!platform.isElectron()) return { adbPath: '', execAsync: () => {} }

  const basePath = getBasePath()
  const executablePaths =
    basePath.indexOf('app.asar') > 0
      ? [basePath.replace('/app.asar', ''), 'bin', IOS_DEVICE_SERVER.process]
      : [basePath, 'platform-tools', 'mac', IOS_DEVICE_SERVER.process]

  const executablePath = path.join(...executablePaths)

  const childProcess = window.require('child_process')
  const fs = window.require('fs')
  const net = window.require('net')
  const execAsync = util.promisify(childProcess.exec)
  const { spawn } = childProcess
  return { executablePath, execAsync, spawn, fs, net }
}

const openQuickTime = async () => {
  const command = `osascript -e 'tell application "QuickTime Player" to activate' -e 'tell application "QuickTime Player" to open (new movie recording)'`
  const { execAsync } = getDependencies()
  await execAsync(command)
}

const startRecorder = async () => {
  try {
    const { executablePath, spawn } = getDependencies()
    spawn(executablePath, [], { detached: true })
  } catch (err) {
    console.error(err)
  }
}

const isRecorderRunning = async () => {
  try {
    const { execAsync } = getDependencies()
    const psResult = await execAsync(
      `ps aux | grep ${IOS_DEVICE_SERVER.process} | grep -v grep`
    )
    return psResult.length > 0
  } catch (err) {
    return false
  }
}
const isQuickTimeRunning = async () => {
  try {
    const { execAsync } = getDependencies()
    const psResult = await execAsync(`ps aux | grep QuickTime | grep -v grep`)
    return psResult.length > 0
  } catch (err) {
    return false
  }
}

const killRecorder = async () => {
  try {
    const { execAsync } = getDependencies()
    const psResult = await execAsync(
      `ps aux | grep ${IOS_DEVICE_SERVER.process} | grep -v grep | awk '!/awk/ { print $2 }'`
    )
    if (psResult.length > 0) {
      await execAsync(`kill -9 ${psResult}`)
    }
  } catch (err) {
    console.error(err)
  }
}

const getLastRecordingPath = async targetPath => {
  try {
    const { execAsync } = getDependencies()
    const command = `ls -plt ${targetPath} | grep -v '/$' | awk 'FNR == 2 {print $9}'`
    const result = await execAsync(command)
    const fileName = result && result.trim ? result.trim() : result
    return path.join(targetPath, fileName)
  } catch (err) {
    console.error(err)
    return ''
  }
}

const connector = async ({
  onConnect = () => {},
  onData = () => {},
  onClose = () => {},
}) => {
  try {
    const { net } = getDependencies()
    const client = new net.Socket()
    client.connect(IOS_DEVICE_SERVER.port, IOS_DEVICE_SERVER.host, () => {
      console.log(
        `connected to ${IOS_DEVICE_SERVER.host}:${IOS_DEVICE_SERVER.port}`
      )
      onConnect(client)
    })

    // Add a 'data' event handler for the client socket
    // data is what the server sent to this socket
    client.on('data', function onDataCallback(data) {
      console.log(`DATA: ${data}`)
      // Close the client socket completely
      /* eslint-disable-next-line */
      onData('' + data, client)
    })

    // Add a 'close' event handler for the client socket
    client.on('close', function onCloseCallback() {
      console.log('Connection closed')
      onClose()
    })
  } catch (err) {
    console.error(err)
  }
}

const listDevices = util.promisify(cb => {
  try {
    connector({
      onConnect: client => {
        client.write(COMMANDS.listDevices)
      },
      onData: (data, client) => {
        if (data.startsWith(RESPONSES.devices)) {
          /* eslint-disable-next-line */
          const [prefix, jsonDevices] = data.split(SEPARATOR)
          const { devices } = JSON.parse(jsonDevices)
          const formattedDevices = devices.map(d => {
            return {
              id: d.uuid,
              type: 'ios',
              name: d.deviceName,
            }
          })
          cb(null, formattedDevices)
        } else if (data.startsWith(RESPONSES.error)) {
          /* eslint-disable-next-line */
          const [prefix, error] = data.split(SEPARATOR)
          cb(error)
        } else {
          cb('generic error')
        }
        client.destroy()
      },
    })
  } catch (err) {
    console.error(err)
    cb(Error(''))
  }
})

const startRecording = (deviceId, _path) => {
  connector({
    onConnect: client => {
      client.write(`${COMMANDS.startRecording}${deviceId}${SEPARATOR}${_path}`)
    },
    onData: (data, client) => {
      client.destroy()
    },
  })
}

const stopRecording = deviceId => {
  connector({
    onConnect: client => {
      client.write(`${COMMANDS.stopRecording}${deviceId}`)
    },
    onData: (data, client) => {
      client.destroy()
    },
  })
}

export default {
  getAppPath,
  getLastRecordingPath,
  isQuickTimeRunning,
  isRecorderRunning,
  killRecorder,
  listDevices,
  openQuickTime,
  startRecorder,
  startRecording,
  stopRecording,
}
