import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { MAIN_VIEWS } from './MainAppPage.constants'
import CreateProjectModal from '../../molecules/CreateProjectModal'
import CreateOrganizationModal from '../../molecules/CreateOrganizationModal'
import OrganizationModalMobile from '../../molecules/OrganizationModalMobile'
import EntryViewMobile from '../../organisms/EntryView/EntryViewMobile'
import SessionViewMobileNew from '../../organisms/SessionView/SessionViewMobileNewContainer'
import Notifications from '../../organisms/Notifications'
import useMainAppPage from './useMainAppPage'

const MainAppPageMobile = ({ initialView, username }) => {
  const { currentOrganization, onSetUserId } = useMainAppPage()

  const [isProjectModalVisible, setProjectModalVisible] = useState(false)
  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)
  const [isOrganizationModalVisible, setOrganizationModalVisible] = useState(
    false
  )
  const [currentView] = useState(initialView)
  useEffect(() => {
    onSetUserId(username)
    return () => {}
  }, [])

  return (
    <>
      {currentView.name === MAIN_VIEWS.entry && (
        <EntryViewMobile isVisible {...currentView.props} />
      )}
      {currentView.name === MAIN_VIEWS.session && (
        <SessionViewMobileNew
          {...currentView.props}
          readOnly={false}
          isAnonymous={false}
        />
      )}
      {isProjectModalVisible && (
        <CreateProjectModal
          isVisible={isProjectModalVisible}
          onClose={() => {
            setProjectModalVisible(false)
          }}
        />
      )}
      <CreateOrganizationModal
        isVisible={isCreateOrganizationModalVisible}
        onClose={() => {
          setCreateOrganizationModalVisible(false)
        }}
      />
      {isOrganizationModalVisible && (
        <OrganizationModalMobile
          organizationId={get(currentOrganization, 'pk')}
          isVisible={isOrganizationModalVisible}
          onClose={() => {
            setOrganizationModalVisible(false)
          }}
        />
      )}
      <Notifications />
    </>
  )
}

MainAppPageMobile.propTypes = {
  initialView: PropTypes.shape({
    name: PropTypes.string.isRequired,
    props: PropTypes.shape({}),
  }),
  onLogout: PropTypes.func,
  username: PropTypes.string,
}

MainAppPageMobile.defaultProps = {
  initialView: { name: MAIN_VIEWS.entry, props: { isEditMode: false } },
  onLogout: () => {},
  username: '',
}

MainAppPageMobile.whyDidYouRender = false

export default withRouter(MainAppPageMobile)
