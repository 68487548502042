import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import useProjectsHook from '../../../store/hooks/useProjectsHook'
import RequestRecordingCreatePage from './RequestRecordingCreatePage'
import {
  createRemoteTestRequest,
  updateRemoteTestRequest,
} from '../../../store/remoteTests/remoteTests.actions'
import fromRemoteTests from '../../../store/remoteTests/remoteTests.selectors'
import { getDefaultRemoteTestTitle } from '../../../util/utils'

const RequestRecordingCreatePageContainer = ({ remoteTestId, ...props }) => {
  const { currentProject } = useProjectsHook()
  const remoteTestTitle = getDefaultRemoteTestTitle(currentProject)
  const dispatch = useDispatch()
  const onCreate = ({ titleText, introText, tasksText, thankYouText }) =>
    dispatch(
      createRemoteTestRequest({
        projectId: currentProject.pk,
        title: titleText,
        sections: [
          {
            id: 'intro',
            order: 1,
            text: introText,
          },
          {
            id: 'tasks',
            order: 2,
            text: tasksText,
          },
          {
            id: 'thank-you',
            order: 3,
            text: thankYouText,
          },
        ],
      })
    )

  const remoteTest = useSelector(
    fromRemoteTests.getRemoteTestById(remoteTestId)
  )

  const onUpdate = ({
    remoteTestId: _remoteTestId,
    titleText,
    introText,
    tasksText,
    thankYouText,
  }) =>
    dispatch(
      updateRemoteTestRequest({
        remoteTestId: _remoteTestId,
        title: titleText,
        sections: [
          {
            id: 'intro',
            order: 1,
            text: introText,
          },
          {
            id: 'tasks',
            order: 2,
            text: tasksText,
          },
          {
            id: 'thank-you',
            order: 3,
            text: thankYouText,
          },
        ],
      })
    )

  return (
    <RequestRecordingCreatePage
      {...props}
      projectTitle={currentProject?.title}
      remoteTestTitle={remoteTestTitle}
      onCreate={onCreate}
      onUpdate={onUpdate}
      remoteTest={remoteTest}
    />
  )
}

RequestRecordingCreatePageContainer.propTypes = {
  remoteTestId: PropTypes.string,
}

RequestRecordingCreatePageContainer.defaultProps = {
  remoteTestId: null,
}

export default RequestRecordingCreatePageContainer
