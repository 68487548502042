import React from 'react'
import PropTypes from 'prop-types'
import SendSlackMessageModal from './SendSlackMessageModal'
import useSlack from '../../hooks/useSlack'
import useLocalStorage from '../../hooks/useLocalStorage'

const SendSlackMessageModalContainer = ({
  sessionId,
  commentId,
  replyId,
  ...props
}) => {
  const { onPostSlackMessage, slackChannels, slackUsers } = useSlack()
  const [slackDestinationId, saveSlackDestinationId] = useLocalStorage(
    'slack-message-last-destination-id',
    ''
  )

  const onSend = ({ destination, isChannel, isUser }) =>
    onPostSlackMessage({
      sessionId,
      commentId,
      replyId,
      channelId: isChannel ? destination : null,
      userId: isUser ? destination : null,
    })

  return (
    <SendSlackMessageModal
      {...props}
      onSend={onSend}
      channels={slackChannels}
      users={slackUsers}
      initialDestinationId={slackDestinationId}
      onSelect={destinationId => saveSlackDestinationId(destinationId)}
    />
  )
}

SendSlackMessageModalContainer.propTypes = {
  sessionId: PropTypes.string,
  commentId: PropTypes.string,
  replyId: PropTypes.string,
}

SendSlackMessageModalContainer.defaultProps = {
  sessionId: '',
  commentId: '',
  replyId: '',
}

export default SendSlackMessageModalContainer
