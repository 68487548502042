import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  position: relative;
  border-radius: 6px;
  border: 1px solid ${prop('theme.colors.yellow')};
  padding: 12px;
`

const FakeInputText = styled(Flex)`
  padding: 16px;
  color: ${prop('theme.colors.grey400')};
  border: 1px solid ${prop('theme.colors.grey200')};
  border-radius: 4px;
  flex-grow: 1;
`

const Row = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
`

const NewCommentMobile = ({ onCancel, onComment, ...props }) => {
  return (
    <Wrapper {...props}>
      <FakeInputText onClick={onComment}>Leave a feedback</FakeInputText>
      <Row>
        <Button
          isBasic
          isStretched
          onClick={onCancel}
          style={{ marginRight: '8px' }}
        >
          Cancel
        </Button>
        <Button isPrimary isStretched disabled onClick={onComment}>
          Add comment
        </Button>
      </Row>
    </Wrapper>
  )
}

NewCommentMobile.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComment: PropTypes.func.isRequired,
}

export default NewCommentMobile
