import PropTypes from 'prop-types'
import styled from 'styled-components'

export const directionValues = [
  'row',
  'row-reverse',
  'column',
  'column-reverse',
]
export const wrapValues = ['nowrap', 'wrap', 'wrap-reverse']
export const justifyContentValues = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'space-evenly',
]
export const alignItemsValues = [
  'flex-start',
  'flex-end',
  'center',
  'baseline',
  'stretch',
]
export const alignContentValues = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'stretch',
]

const Flex = styled.div`
  display: flex;
  align-content: ${({ alignContent }) => alignContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-grow: ${({ flexGrow }) => flexGrow};
`

Flex.propTypes = {
  alignContent: PropTypes.oneOf(alignContentValues),
  alignItems: PropTypes.oneOf(alignItemsValues),
  direction: PropTypes.oneOf(directionValues),
  justifyContent: PropTypes.oneOf(justifyContentValues),
  wrap: PropTypes.oneOf(wrapValues),
  flexGrow: PropTypes.number,
}

Flex.defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  direction: 'column',
  justifyContent: 'flex-start',
  wrap: 'nowrap',
  flexGrow: 0,
}

export default Flex
