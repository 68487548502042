import React from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: inline-flex;
  justify-content: flex-end;
  color: ${prop('theme.colors.grey600')};
  font-size: 12px;
  font-weight: 400;
`

const CommentDate = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

CommentDate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CommentDate
