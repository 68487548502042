import { useSelector } from 'react-redux'
import fromRemoteTests from '../remoteTests/remoteTests.selectors'

function useGetRemoteTest(remoteTestId) {
  const remoteTest = useSelector(
    fromRemoteTests.getRemoteTestById(remoteTestId)
  )
  return remoteTest
}

export default useGetRemoteTest
