import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import { Modal, Header, Footer, Body, Close } from '@zendeskgarden/react-modals'
import { Field, Toggle, Label } from '@zendeskgarden/react-forms'
import { Button } from '@zendeskgarden/react-buttons'
import RecordIcon from 'mdi-react/RecordIcon'
import StopCircleIcon from 'mdi-react/StopCircleIcon'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'
import IconButton from '../IconButton/IconButton'
import Flex from '../../atoms/Flex'
import useRecording from '../../hooks/useRecording'

const StyledBody = styled(Body)`
  padding: 0;
  min-height: 220px;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  font-weight: 600;
  font-size: 20px;
`

const StyledFooter = styled(Footer)`
  justify-content: center;
`

const Controls = styled(Flex)`
  flex-direction: row;
  align-items: center;
`

const StyledLabel = styled(Label)`
  margin-right: 32px;
`

const SaveIconButton = styled(IconButton)`
  && {
    background-color: ${prop('theme.colors.green')};
  }
`

const videoCss = css`
  width: 100%;
  max-height: 529px;
  outline: none;
`

const StyledVideo = styled.video`
  ${videoCss}
`

const PreviewVideo = styled.video`
  ${videoCss}
`

const RecordingModal = ({ isVisible, onClose, onSave }) => {
  const {
    currentPreviewStream,
    isMicAvailable,
    isMicEnabled,
    isRecording,
    mediaBlob,
    mediaBlobUrl,
    mimeType,
    previewRef,
    resetHandler,
    resetState,
    startRecordingHandler,
    stopRecordingHandler,
    toggleMicAudio,
  } = useRecording()

  useEffect(() => {
    if (isVisible) {
      resetState()
    }
  }, [isVisible])

  const onModalClose = () => {
    try {
      stopRecordingHandler()
    } catch (err) {
      console.warn(err)
    }
    resetState()
    onClose()
  }

  return (
    <>
      {isVisible && (
        <Modal isAnimated={false}>
          <Header style={{ textAlign: 'center' }}>New screen recording</Header>
          <StyledBody>
            {!isRecording && !mediaBlobUrl && <>Ready when you are</>}
            {!isRecording && mediaBlobUrl && (
              <StyledVideo src={mediaBlobUrl} controls inline autoPlay />
            )}
            {isRecording && !currentPreviewStream && (
              <>Your screen is now being recorded</>
            )}
            {isRecording && currentPreviewStream && (
              <PreviewVideo ref={previewRef} autoPlay />
            )}
          </StyledBody>
          <StyledFooter>
            <Controls>
              {!mediaBlobUrl && isMicAvailable && (
                <Field>
                  <Toggle
                    checked={isMicEnabled}
                    onChange={() => {
                      toggleMicAudio()
                    }}
                    data-test="mic-toggle"
                  >
                    <StyledLabel>Microphone audio</StyledLabel>
                  </Toggle>
                </Field>
              )}
              {!isRecording && !mediaBlobUrl && (
                <IconButton
                  onClick={startRecordingHandler}
                  icon={RecordIcon}
                  isPrimary
                  size="medium"
                  isRightIcon
                  iconProps={{
                    style: {
                      fill: '#EB5757',
                    },
                  }}
                  data-test="start-recording-button"
                >
                  Start recording
                </IconButton>
              )}
              {isRecording && (
                <IconButton
                  onClick={stopRecordingHandler}
                  icon={StopCircleIcon}
                  isPrimary
                  isRightIcon
                  size="medium"
                  data-test="stop-recording-button"
                >
                  Stop recording
                </IconButton>
              )}
              {!isRecording && mediaBlobUrl && (
                <>
                  <Button
                    onClick={resetHandler}
                    isBasic
                    size="medium"
                    style={{ marginRight: '16px' }}
                    data-test="delete-and-redo-recording-button"
                  >
                    Delete and Redo
                  </Button>
                  <SaveIconButton
                    icon={CloudUploadIcon}
                    isRightIcon
                    isPrimary
                    size="medium"
                    onClick={() => onSave({ content: mediaBlob, mimeType })}
                    data-test="save-recording-button"
                  >
                    Save recording
                  </SaveIconButton>
                </>
              )}
            </Controls>
          </StyledFooter>
          <Close aria-label="Close modal" onClick={onModalClose} />
        </Modal>
      )}
    </>
  )
}

RecordingModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

RecordingModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onSave: () => {},
}

export default RecordingModal
