import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/values'
import concat from 'lodash/fp/concat'
import uniq from 'lodash/uniq'
import { createSelector } from 'reselect'
import { STATE_KEY } from './organizations.reducers'
import { sortOrganizations } from './organizations.utils'
import appConfig from '../../config'

export const PERSONAL_ORGANIZATION_ID = 'personal'
export const PERSONAL_ORGANIZATION = {
  pk: PERSONAL_ORGANIZATION_ID,
  title: 'Personal',
}

const getState = state => getImmutable(state, STATE_KEY)

const getAllOrganizationsId = createSelector(
  getState,
  get('allIds')
)

const getOrganizations = createSelector(
  getState,
  pipe(
    get('byId'),
    values,
    concat([PERSONAL_ORGANIZATION]),
    sortOrganizations([PERSONAL_ORGANIZATION_ID, appConfig.libraryWorkspaceId])
  )
)

const getOrganizationById = organizationId =>
  createSelector(
    getState,
    get(`byId.${organizationId}`)
  )

const getCurrentOrganizationId = createSelector(
  getState,
  get('current')
)

const getCurrentOrganization = createSelector(
  getState,
  getCurrentOrganizationId,
  (state, organizationId) =>
    getOr(PERSONAL_ORGANIZATION, `byId.${organizationId}`)(state)
)

const isCurrentOrganizationPersonal = createSelector(
  getCurrentOrganizationId,
  organizationId => organizationId === PERSONAL_ORGANIZATION.pk
)

const getOtherOrganizations = createSelector(
  getOrganizations,
  getCurrentOrganizationId,
  (organizations, currentOrganizationId) =>
    organizations.filter(
      organization => organization.pk !== currentOrganizationId
    )
)

const getCurrentOrganizationMembers = createSelector(
  getCurrentOrganization,
  organization => {
    const admins = organization?.admins || []
    const members = organization?.members || []
    return uniq(admins.concat(members))
  }
)

export default {
  getAllOrganizationsId,
  getOrganizations,
  getCurrentOrganization,
  getCurrentOrganizationId,
  getOrganizationById,
  isCurrentOrganizationPersonal,
  getOtherOrganizations,
  getCurrentOrganizationMembers,
}
