import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { STATE_KEY } from './localSessions.reducers'

const getState = state => getImmutable(state, STATE_KEY)

const getAllLocalSessionsId = createSelector(
  getState,
  get('allIds')
)

const getLocalSessionsMap = createSelector(
  getState,
  get('byId')
)

const getLocalSessions = createSelector(
  getState,
  pipe(
    get('byId'),
    values
  )
)

const getLocalSessionById = localSessionId =>
  createSelector(
    getState,
    get(`byId.${localSessionId}`)
  )

export default {
  getAllLocalSessionsId,
  getLocalSessions,
  getLocalSessionsMap,
  getLocalSessionById,
}
