import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import MobileModal from '../MobileModal'
import Flex from '../../atoms/Flex'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import WorkspaceUpsellIMage from '../../../assets/workspace_upsell_image.svg'
import MobileTemplate from '../../templates/MobileTemplate'
import MobileTopBar from '../MobileTopBar/MobileTopBar'

const Wrapper = styled(Flex)`
  padding: 16px;
  flex-grow: 1;
  justify-content: center;
`

const Title = styled(Flex)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${prop('theme.colors.grey800')};
`
const Description = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${prop('theme.colors.grey600')};
  padding: 16px;
  margin-bottom: 24px;
  line-height: 24px;
  max-width: 320px;
  align-self: center;
`

const Image = styled.img`
  margin-bottom: 32px;
`

const WorkspaceUpsellModal = ({ isVisible, onCreate, onClose }) => {
  const header = useMemo(
    () => (
      <MobileTopBar
        rightIcon={CloseIcon}
        onRightButtonClick={onClose}
        variant="transparent"
      />
    ),
    []
  )
  const body = useMemo(
    () => (
      <Wrapper>
        <Image src={WorkspaceUpsellIMage} alt="image" />
        <Flex>
          <Title justifyContent="center" alignItems="center">
            Do more with sharable projects
          </Title>
          <Description>
            Create a team workspace to share projects with your teammates
          </Description>
        </Flex>
      </Wrapper>
    ),
    []
  )

  const footer = useMemo(
    () => (
      <Flex style={{ padding: '16px' }}>
        <Button
          isPrimary
          onClick={() => {
            onCreate()
            onClose()
          }}
        >
          Create Team Workspace
        </Button>
      </Flex>
    ),

    []
  )

  return (
    <>
      {isVisible && (
        <MobileModal
          onClose={onClose}
          isAnimated={false}
          isLarge
          style={{ width: '400px' }}
        >
          <MobileTemplate header={header} body={body} footer={footer} />
        </MobileModal>
      )}
    </>
  )
}

WorkspaceUpsellModal.propTypes = {
  isVisible: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
}

WorkspaceUpsellModal.defaultProps = {
  isVisible: false,
  onCreate: () => {},
  onClose: () => {},
}

export default WorkspaceUpsellModal
