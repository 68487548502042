import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import Flex from '../../atoms/Flex/Flex'
import AlertBox from '../AlertBox/AlertBox'

const Wrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 0 8px 8px;
`

const AlertRow = ({ ...props }) => (
  <Wrapper {...props}>
    {props.items.map(item => (
      <AlertBox type={item.type} title={item.title} key={item.title} />
    ))}
  </Wrapper>
)

AlertRow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

AlertRow.defaultProps = {
  items: [],
}

export default AlertRow
