import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { formatDistanceToNow } from 'date-fns'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'
import ResolveIcon from '../../atoms/ResolveIcon'
import CommentDate from '../CommentDate'
import CommentUser from '../CommentUser'
import CommentText from '../CommentText'
import CommentSettings from './CommentSettings'
import formatMentionSuggestions from '../MentionSuggestion/formatMentionSuggestions'
import { MentionSuggestionType } from '../MentionSuggestion/MentionSuggestion'
import CommentEditableText from './CommentEditableText'

const WIDTH_BREAKPOINT = 850

const Wrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  position: relative;
`

const LeftBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-top: 2px;
  margin-right: 8px;
`

const StyledUserAvatar = styled(UserAvatar)`
  align-self: flex-start;
`

const RightBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 8px;
`

const FirstRow = styled(Flex)`
  margin-top: 4px;
  flex-direction: row;

  @media only screen and (max-width: 1060px) {
    flex-direction: column;
  }
`

const StyledCommentDateTime = styled(CommentDate)`
  justify-content: flex-end;
`

const Settings = styled(Flex)`
  display: inline-flex;
  font-size: 14px;
  align-self: flex-end;
`

const EditCommentBox = styled(Flex)`
  flex-grow: 1;
`

const EditCommentButtonsBox = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 16px;
  margin-top: 8px;
`

const CancelButton = styled(Button)`
  margin-right: 16px !important;
`

const SaveButton = styled(Button)``

const StyledCommentText = styled(CommentText)`
  padding: 4px 16px 0 0;
`

const EventText = styled(Flex)`
  font-style: italic;
`

const ResolvedText = styled(EventText)`
  color: ${prop('theme.colors.green')};
`

const StyledCommentEditableText = styled(CommentEditableText)`
  width: 100%;
  margin-top: 8px;
`

const StyledCommentUser = styled(CommentUser)`
  justify-items: flex-start;
  margin-right: 8px;
  @media only screen and (max-width: ${WIDTH_BREAKPOINT}px) {
    margin-right: 0;
  }
`

const StyledResolveIcon = styled(ResolveIcon)`
  position: absolute;
  top: 4px;
  right: 32px;
  z-index: 21;
`

const formatDate = timestamp =>
  timestamp
    ? `${formatDistanceToNow(new Date(timestamp * 1000)).replace(
        'about ',
        ''
      )} ago`
    : ''

const getBadgeColor = (tagText, isSelected, isBlurred, theme) => {
  if (!tagText) {
    return undefined
  }

  if (isSelected) {
    return theme.colors.primary
  }

  if (isBlurred) {
    return theme.colors.grey800
  }

  return theme.colors.white
}

const getBadgeBgColor = (tagText, isSelected, isBlurred, theme) => {
  if (!tagText) {
    return undefined
  }

  if (isSelected) {
    return theme.colors.yellow
  }

  if (isBlurred) {
    return theme.colors.grey200
  }

  return theme.colors.primary
}

export const EVENT_TYPE = {
  threadResolved: 'resolved',
  threadReOpened: 'reopened',
  jiraCreated: 'jira-created',
  trelloCreated: 'trello-created',
}

const SingleComment = ({
  canEdit,
  createdAt,
  eventType,
  hideResolve,
  isBlurred,
  isCopyLinkEnabled,
  isInlineEdit,
  isJiraEnabled,
  isResolved,
  isSelected,
  isSlackEnabled,
  isTrelloEnabled,
  mentions,
  onCopyLink,
  onCreateJiraIssue,
  onCreateTrelloCard,
  onDelete,
  onEdit,
  onResolveClick,
  onSendSlackMessage,
  onUpdate,
  pictureUrl,
  showJira,
  showSettings,
  showSlack,
  showTrello,
  tagText,
  text,
  theme,
  userName,
  isEditMode,
  ...props
}) => {
  const [autoFocusToken, setAutoFocusToken] = useState(null)
  useEffect(() => {
    if (isEditMode) {
      setAutoFocusToken(Math.random().toString())
    }
  }, [isEditMode])

  const [currentText, setCurrentText] = useState(text)
  const [currentMentions, setCurrentMentions] = useState(mentions || [])
  useEffect(() => setCurrentMentions(mentions || []), [mentions])

  useEffect(() => {
    setCurrentText(text)
  }, [text])
  const [isEdit, setEdit] = useState(isEditMode)
  const [updatedText, setUpdateText] = useState(text)

  const badgeColor = getBadgeColor(tagText, isSelected, isBlurred, theme)
  const badgeBgColor = getBadgeBgColor(tagText, isSelected, isBlurred, theme)

  return (
    <Wrapper {...props}>
      <LeftBox>
        <StyledUserAvatar
          name={userName}
          imgUrl={pictureUrl}
          size="medium"
          badgeColor={badgeColor}
          badgeBgColor={badgeBgColor}
          badge={tagText}
        />
      </LeftBox>
      <RightBox direction="column">
        <FirstRow alignItems="baseline">
          <StyledCommentUser>{userName}</StyledCommentUser>
          <StyledCommentDateTime>{formatDate(createdAt)}</StyledCommentDateTime>
          {showSettings && (
            <Settings>
              {!hideResolve && (
                <StyledResolveIcon
                  isResolved={isResolved}
                  onClick={onResolveClick}
                />
              )}
              <CommentSettings
                isDeleteEnabled={canEdit}
                isEditEnabled={canEdit}
                isCopyLinkEnabled={isCopyLinkEnabled}
                isJiraEnabled={isJiraEnabled}
                isTrelloEnabled={isTrelloEnabled}
                isSlackEnabled={isSlackEnabled}
                showJira={showJira}
                showTrello={showTrello}
                showSlack={showSlack}
                onEdit={() => {
                  if (!isInlineEdit) {
                    onEdit()
                    return
                  }
                  setEdit(true)
                }}
                onDelete={onDelete}
                onCopyLink={onCopyLink}
                onCreateJiraIssue={onCreateJiraIssue}
                onCreateTrelloCard={onCreateTrelloCard}
                onSendSlackMessage={onSendSlackMessage}
                data-test="comment-settings-button"
              />
            </Settings>
          )}
        </FirstRow>
        <Flex direction="row">
          {!isEdit && (
            <StyledCommentText>
              {eventType === EVENT_TYPE.threadResolved && (
                <ResolvedText>Resolved this thread</ResolvedText>
              )}
              {eventType === EVENT_TYPE.threadReOpened && (
                <EventText>Re-opened this thread</EventText>
              )}
              {!eventType && formatMentionSuggestions(currentText, mentions)}
            </StyledCommentText>
          )}
          {isEdit && (
            <EditCommentBox>
              <StyledCommentEditableText
                initialValue={currentText}
                autoFocusToken={autoFocusToken}
                onChange={setUpdateText}
                mentions={currentMentions}
                onAddedMention={newMention =>
                  setCurrentMentions([...currentMentions, newMention])
                }
                onRemovedMention={removedMention =>
                  setCurrentMentions([
                    ...currentMentions.filter(
                      ({ pk }) => pk !== removedMention.pk
                    ),
                  ])
                }
              />
              <EditCommentButtonsBox>
                <CancelButton
                  isBasic
                  size="small"
                  onClick={() => {
                    setEdit(false)
                    setUpdateText(text)
                  }}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  isPrimary
                  size="small"
                  onClick={() => {
                    onUpdate(updatedText, currentMentions)
                    setCurrentText(updatedText)
                    setEdit(false)
                  }}
                >
                  Save
                </SaveButton>
              </EditCommentButtonsBox>
            </EditCommentBox>
          )}
        </Flex>
      </RightBox>
    </Wrapper>
  )
}

SingleComment.propTypes = {
  canEdit: PropTypes.bool,
  createdAt: PropTypes.number,
  eventType: PropTypes.oneOf(Object.values(EVENT_TYPE)),
  hideResolve: PropTypes.bool,
  isBlurred: PropTypes.bool,
  isCopyLinkEnabled: PropTypes.bool,
  isInlineEdit: PropTypes.bool,
  isJiraEnabled: PropTypes.bool,
  isResolved: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSlackEnabled: PropTypes.bool,
  isTrelloEnabled: PropTypes.bool,
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  onCopyLink: PropTypes.func,
  onCreateJiraIssue: PropTypes.func,
  onCreateTrelloCard: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onResolveClick: PropTypes.func,
  onSendSlackMessage: PropTypes.func,
  onUpdate: PropTypes.func,
  pictureUrl: PropTypes.string,
  showJira: PropTypes.bool,
  showSettings: PropTypes.bool,
  showSlack: PropTypes.bool,
  showTrello: PropTypes.bool,
  tagText: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.shape({}),
  userName: PropTypes.string,
  isEditMode: PropTypes.bool,
}

SingleComment.defaultProps = {
  canEdit: false,
  createdAt: null,
  eventType: null,
  hideResolve: false,
  isBlurred: false,
  isCopyLinkEnabled: true,
  isInlineEdit: true,
  isJiraEnabled: false,
  isResolved: false,
  isSelected: false,
  isSlackEnabled: false,
  isTrelloEnabled: false,
  mentions: [],
  onCopyLink: () => {},
  onCreateJiraIssue: () => {},
  onCreateTrelloCard: () => {},
  onDelete: () => {},
  onEdit: null,
  onResolveClick: () => {},
  onSendSlackMessage: () => {},
  onUpdate: () => {},
  pictureUrl: '',
  showJira: true,
  showSettings: true,
  showSlack: true,
  showTrello: true,
  isEditMode: false,
  tagText: undefined,
  text: '',
  theme: {},
  userName: '',
}

export default withTheme(SingleComment)
