import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { useHistory } from 'react-router-dom'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Alert, Title } from '@zendeskgarden/react-notifications'
import { LG } from '@zendeskgarden/react-typography'
import { Formik, Form, ErrorMessage } from 'formik'
import CenterTemplate from '../../templates/CenterTemplate'
import Flex from '../../atoms/Flex'
import Link from '../../atoms/Link'
import Anchor from '../../atoms/Anchor'
import Brand from '../../atoms/Brand'
import { StyledErrorMessage } from '../../commonStyledComponents'
import { forgotPassword } from '../../../util/auth'
import { filteredGmailUrl } from '../../../util/utils'

const StyledCenterTemplate = styled(CenterTemplate)`
  padding: 24px;
  box-sizing: border-box;
`

const StyledBrand = styled(Brand)`
  width: 60%;
  max-width: 300px;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const StyledForm = styled(Form)`
  margin: 64px 64px 0 64px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledAlert = styled(Alert)`
  margin-top: 16px;
  text-align: left;
`

const WarningAlert = styled(Alert)`
  margin-top: 16px;
  color: ${prop('theme.colors.yellow800')};
  background-color: ${prop('theme.colors.yellow100')};
  border-color: ${prop('theme.colors.yellow300')};
  text-align: left;
`

const SuccessAlert = styled(Alert)`
  margin-top: 16px;
  color: ${prop('theme.colors.green800')};
  background-color: ${prop('theme.colors.green100')};
  border-color: ${prop('theme.colors.green300')};
  text-align: left;
`

const WarningTitle = styled(Title)`
  color: ${prop('theme.colors.yellow900')} !important;
`

const formInitialValues = {
  email: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'The email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const LoginPage = ({ onComplete, ...props }) => {
  const history = useHistory()
  const [isEmailNotConfirmed, setEmailNotConfirmed] = useState(false)
  const [isUserNotPresent, setUserNotPresent] = useState(false)
  const [isEmailSent, setEmailSent] = useState(false)
  const [isError, setError] = useState(false)
  const [email, setEmail] = useState('')
  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true)
    forgotPassword(values.email)
      .then(() => {
        setEmailNotConfirmed(false)
        setUserNotPresent(false)
        setError(false)
        setEmailSent(true)
      })
      .catch(error => {
        console.error('forgotPassword error', error)
        setSubmitting(false)
        if (error.code === 'UserNotConfirmedException') {
          setEmailNotConfirmed(true)
          return
        }
        if (error.code === 'UserNotFoundException') {
          setUserNotPresent(true)
          return
        }
        setError(error)
      })
  }

  const onEmailInput = e => setEmail(e?.target?.value)
  const onResendConfirmationEmail = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      forgotPassword(email)
    },
    [email]
  )

  return (
    <StyledCenterTemplate {...props}>
      <StyledBrand />
      <Formik
        initialValues={formInitialValues}
        validate={validateFormValues}
        onSubmit={onSubmitHandler}
        render={({
          values,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
        }) => (
          <React.Fragment>
            <StyledForm>
              <CenteredBox style={{ marginTop: '32px' }} direction="row">
                <LG>Enter your email to reset password</LG>
              </CenteredBox>
              <CenteredBox>
                <Field>
                  <Input
                    type="email"
                    name="email"
                    autocomplete="email"
                    onChange={handleChange}
                    onInput={onEmailInput}
                    onBlur={handleBlur}
                    value={values.email}
                    validation={errors.email ? 'warning' : undefined}
                    placeholder="Email"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                </Field>
              </CenteredBox>
              <CenteredBox>
                <Button
                  isStretched
                  isPrimary
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Send me the link
                </Button>
                {isError && (
                  <WarningAlert type="warning">
                    Ops, an unexpected error occurred, please retry later
                  </WarningAlert>
                )}
                {isEmailNotConfirmed && (
                  <WarningAlert type="warning">
                    <WarningTitle>Verify your email</WarningTitle>
                    Check your inbox and verify your email to start using
                    iterspace.{' '}
                    <Link onClick={onResendConfirmationEmail}>
                      Resend confirmation email
                    </Link>
                  </WarningAlert>
                )}
                {isUserNotPresent && (
                  <StyledAlert type="info">
                    <Title>Email address not found</Title>
                    Do you need to join? <Link to="/register">Sign up now</Link>
                  </StyledAlert>
                )}
                {isEmailSent && (
                  <SuccessAlert type="success">
                    We successfully sent you the password reset link
                    {email.endsWith('@gmail.com') && (
                      <>
                        {' '}
                        <Anchor href={filteredGmailUrl()} target="_blank">
                          Open my inbox
                        </Anchor>
                      </>
                    )}
                  </SuccessAlert>
                )}
                <Button
                  isBasic
                  onClick={() => history.push('/login')}
                  style={{ marginTop: '16px' }}
                >
                  Cancel
                </Button>
              </CenteredBox>
            </StyledForm>
          </React.Fragment>
        )}
      />
    </StyledCenterTemplate>
  )
}

LoginPage.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

export default LoginPage
