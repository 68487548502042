import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import FolderIcon from 'mdi-react/FolderIcon'
import FolderAccountIcon from 'mdi-react/FolderAccountIcon'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import Card from '../../atoms/Card'
import DetailsLabel from '../DetailsLabel'
import ProjectSettings from './ProjectSettings'
import { ternary } from '../../../util/utils'

const Wrapper = styled(Flex)``

const StyledProjectSettings = styled(ProjectSettings)`
  display: none;
  visibility: hidden;
`

const hoverCss = css`
  background-color: ${prop('theme.colors.grey100')};
`

const activeCss = css`
  background-color: ${prop('theme.colors.grey100')};
  box-shadow: none;
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  padding: 8px 16px;
  position: relative;
  transition: background-color 0.3s;
  cursor: pointer;

  &&:hover {
    ${hoverCss}
  }

  &&:hover ${StyledProjectSettings} {
    display: flex;
    visibility: visible;
  }

  ${ternary('isActive')(activeCss, '')}
`

const LeftBox = styled(Flex)`
  align-self: center;
  order: 0;
  flex: 0 1 auto;
`

const RightBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  margin-left: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
`

const Text = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  font-weight: 600;
  color: ${prop('theme.colors.grey900')};
`

const Subtitle = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  flex-direction: row;
  margin-top: 8px;
`

const commonIcons = css`
  fill: ${prop('theme.colors.grey300')};
`

const StyledFolderIcon = styled(FolderIcon)`
  ${commonIcons}
`
const StyledFolderAccountIcon = styled(FolderAccountIcon)`
  ${commonIcons}
`

const ProjectCard = ({
  counter,
  hasSettings,
  isActive,
  isPrivate,
  isPublic,
  isShared,
  onDelete,
  onShare,
  sharedWithCounter,
  title,
  showDelete,
  showDetailsTag,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <StyledCard isActive={isActive}>
        <LeftBox>
          {isPrivate && <StyledFolderIcon size={32} />}
          {isShared && <StyledFolderAccountIcon size={32} />}
        </LeftBox>
        <RightBox>
          <Text>{title}</Text>
          <Subtitle>
            <DetailsLabel
              isPrivate={isPrivate}
              isShared={isShared}
              sharedWithCounter={sharedWithCounter}
              counter={counter}
              label={counter === 1 ? 'recording' : 'recordings'}
              data-test="project-details-label"
              showTag={showDetailsTag}
            />
          </Subtitle>
        </RightBox>
        {hasSettings && (
          <StyledProjectSettings
            onShare={onShare}
            onDelete={onDelete}
            data-test="project-settings"
            showDelete={showDelete}
          />
        )}
      </StyledCard>
    </Wrapper>
  )
}

ProjectCard.propTypes = {
  counter: PropTypes.number,
  hasSettings: PropTypes.bool,
  isActive: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isShared: PropTypes.bool,
  onDelete: PropTypes.func,
  onShare: PropTypes.func,
  sharedWithCounter: PropTypes.number,
  title: PropTypes.string,
  showDelete: PropTypes.bool,
  showDetailsTag: PropTypes.bool,
}

ProjectCard.defaultProps = {
  counter: 0,
  hasSettings: false,
  isActive: false,
  isPrivate: false,
  isPublic: false,
  isShared: false,
  onDelete: () => {},
  onShare: () => {},
  sharedWithCounter: 0,
  title: '',
  showDelete: false,
  showDetailsTag: true,
}

export default ProjectCard
