/* eslint-disable no-irregular-whitespace */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import BugOutlineIcon from 'mdi-react/BugOutlineIcon'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'
import InformationIcon from 'mdi-react/InformationIcon'
import LightbulbOnOutlineIcon from 'mdi-react/LightbulbOnOutlineIcon'
import LinkIcon from 'mdi-react/LinkIcon'
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon'
import VideoOffIcon from 'mdi-react/VideoOffIcon'
import VideoPlusIcon from 'mdi-react/VideoPlusIcon'
import { TooltipModal } from '@zendeskgarden/react-modals'
import { Anchor } from '@zendeskgarden/react-buttons'
import { isChromeLikeBrowser } from '../../../lib/platform'
import Flex from '../../atoms/Flex'
import Card from '../../atoms/Card'
import useRecordingUpload from '../../hooks/useRecordingUpload'
import RecordingPanel from './RecordingPanel'

const Wrapper = styled(Card)`
  padding: 8px;
`

const productDisabledCss = css`
  opacity: 0.6;
  cursor: not-allowed;
`

const Product = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  outline: none;
  background-color: white;
  display: flex;
  border: none;

  :hover {
    background-color: ${prop('theme.colors.grey100')};

    svg {
      fill: ${prop('theme.colors.yellow600')};
    }
  }

  ${({ isDisabled }) => (isDisabled ? productDisabledCss : '')}
`

const Left = styled(Flex)`
  order: 0;
  flex: 0 1 36px;
  align-self: auto;
  width: 36px;
  margin-right: 16px;
`

const Right = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
`
const Icon = styled(Flex)`
  margin-top: 0;
  width: 32px;

  svg {
    fill: ${prop('theme.colors.yellow400')};
  }
`
const Title = styled(Flex)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${prop('theme.colors.primary')};
`
const Description = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
  color: ${prop('theme.colors.grey500')};
`

const Warning = styled(Product)`
  background-color: ${prop('theme.colors.yellow100')};
  border: 1px solid ${prop('theme.colors.yellow400')};
  cursor: default;
  ${Title}  {
    color: ${prop('theme.colors.primary')};
  }
  ${Description}  {
    color: ${prop('theme.colors.primary300')};
  }

  svg {
    fill: ${prop('theme.colors.yellow500')} !important;
  }

  :hover {
    background-color: ${prop('theme.colors.yellow100')};
  }
`

const DisabledProduct = styled(Product)`
  background-color: ${prop('theme.colors.warning100')};

  ${Title}  {
    color: ${prop('theme.colors.warning800')};
  }
  ${Description}  {
    color: ${prop('theme.colors.warning600')};
  }

  svg {
    fill: ${prop('theme.colors.warning800')} !important;
  }

  :hover {
    background-color: ${prop('theme.colors.warning100')};
  }
`

const StyledVideoPlusIcon = styled(VideoPlusIcon)`
  fill: ${prop('theme.colors.secondary')} !important;
`

const StyledCloudUploadIcon = styled(CloudUploadIcon)`
  fill: ${prop('theme.colors.green')} !important;
`

const Separator = styled(Flex)`
  height: 0;
  width: 100%;
  margin: 8px 0;
  align-self: center;
  border-bottom: 1px solid ${prop('theme.colors.grey100')};
`

const TooltipModalTitle = styled(TooltipModal.Title)`
  text-align: center;
  margin-bottom: 16px;
  padding-right: 0;
`

const StyledAnchor = styled(Anchor)`
  padding: ${({ theme }) => theme.space.sm};
  margin-top: ${({ theme }) => theme.space.sm};
  color: ${({ theme }) => theme.colors.secondary600};
  text-align: center;
`

const getIcon = productIconName => {
  if (productIconName === 'BugOutlineIcon') {
    return BugOutlineIcon
  }

  if (productIconName === 'PencilOutlineIcon') {
    return PencilOutlineIcon
  }

  if (productIconName === 'LightbulbOnOutlineIcon') {
    return LightbulbOnOutlineIcon
  }

  return LightbulbOnOutlineIcon
}

const ProductSelector = ({
  onRemoteTestClick,
  onScreenRecordingClick,
  onSpecificProductClick,
  isScreenRecordingSupported,
  isDisabled,
  additionalProducts,
  ...props
}) => {
  const inputRef = useRef(null)
  const { handleFiles, onUpload } = useRecordingUpload(inputRef)

  const buttonRef = useRef(null)
  const [referenceElement, setReferenceElement] = useState()

  const isChrome = isChromeLikeBrowser()

  return (
    <>
      <Wrapper {...props}>
        {isDisabled && (
          <Warning>
            <Left>
              <Icon>
                <InformationIcon size={22} />
              </Icon>
            </Left>
            <Right>
              <Title>You need permissions to create</Title>
              <Description>
                Want to record or upload to this project? Ask the owner to share
                this project with you.
              </Description>
            </Right>
          </Warning>
        )}
        <Product
          onClick={onRemoteTestClick}
          data-test="new-remote-test"
          style={{ display: 'none' }}
          isDisabled={isDisabled}
        >
          <Left>
            <Icon>
              <LinkIcon size={22} />
            </Icon>
          </Left>
          <Right>
            <Title>Request recordings</Title>
            <Description>
              Setup instructions, share link with your audience, and get
              recordings
            </Description>
          </Right>
        </Product>
        {isScreenRecordingSupported && (
          <>
            <Product
              ref={buttonRef}
              data-test="new-screen-recording-card"
              onClick={() => {
                if (isDisabled) {
                  return
                }

                if (onScreenRecordingClick()) {
                  setReferenceElement(buttonRef.current)
                }
              }}
              isDisabled={isDisabled}
            >
              <Left>
                <Icon>
                  <StyledVideoPlusIcon size={32} />
                </Icon>
              </Left>
              <Right>
                <Title>Record your screen</Title>
              </Right>
            </Product>
            <TooltipModal
              referenceElement={referenceElement}
              onClose={() => setReferenceElement(null)}
              placement="top"
            >
              <TooltipModalTitle>New Recording</TooltipModalTitle>
              <TooltipModal.Body>
                <RecordingPanel
                  onStartRecordButtonClick={() => {
                    setReferenceElement(null)
                  }}
                />
              </TooltipModal.Body>
            </TooltipModal>
          </>
        )}
        {!isScreenRecordingSupported && (
          <DisabledProduct data-test="no-new-screen-recording-card">
            <Left>
              <Icon>
                <VideoOffIcon size={20} />
              </Icon>
            </Left>
            <Right>
              <Title>Screen recording</Title>
              <Description style={{ display: 'block' }}>
                Not supported on this browser yet.
                <br />
                Please use a recent version of{' '}
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download Google Chrome browser"
                >
                  Chrome
                </a>
                , or{' '}
                <a
                  href="https://www.microsoft.com/en-us/edge"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download Microsoft Edge browser"
                >
                  Edge
                </a>
                .
              </Description>
            </Right>
          </DisabledProduct>
        )}
        <Product
          onClick={() => {
            if (isDisabled) {
              return
            }
            onUpload()
          }}
          isDisabled={isDisabled}
        >
          <input
            ref={inputRef}
            type="file"
            accept=".mov,.mp4,.webm"
            multiple
            onChange={handleFiles(null)}
            style={{ display: 'none' }}
          />
          <Left>
            <Icon>
              <StyledCloudUploadIcon size={28} />
            </Icon>
          </Left>
          <Right>
            <Title>Upload a video</Title>
          </Right>
        </Product>
        {additionalProducts.length > 0 && <Separator />}
        {additionalProducts.map(product => {
          const CurrentIcon = getIcon(product.iconName)
          return (
            <Product
              key={product.title}
              onClick={() => onSpecificProductClick(product)}
              isDisabled={isDisabled}
            >
              <Left>
                <Icon>
                  <CurrentIcon size={28} />
                </Icon>
              </Left>
              <Right>
                <Title>{product.title}</Title>
              </Right>
            </Product>
          )
        })}
      </Wrapper>
      {isChrome && isScreenRecordingSupported && (
        <StyledAnchor
          isExternal
          href="https://www.iterspace.com/how-to-fix-extension-issues"
          target="_blank"
        >
          Experiencing issues?
        </StyledAnchor>
      )}
    </>
  )
}

export const AdditionalProductShape = PropTypes.shape({
  descriptionHtml: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  libraryRecordingId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})

ProductSelector.propTypes = {
  additionalProducts: PropTypes.arrayOf(AdditionalProductShape),
  isDisabled: PropTypes.bool,
  isScreenRecordingSupported: PropTypes.bool,
  onRemoteTestClick: PropTypes.func,
  onScreenRecordingClick: PropTypes.func,
  onSpecificProductClick: PropTypes.func,
}

ProductSelector.defaultProps = {
  additionalProducts: [],
  isDisabled: false,
  isScreenRecordingSupported: true,
  onRemoteTestClick: () => {},
  onScreenRecordingClick: () => {},
  onSpecificProductClick: () => {},
}

export default ProductSelector
