const NAMESPACE = 'ANALYTICS'

export const TRACK_LOGIN = `${NAMESPACE}/TRACK/LOGIN`
export const TRACK_LOGIN_REQUEST = `${TRACK_LOGIN}/REQUEST`
export const TRACK_LOGIN_REQUEST_SUCCESS = `${TRACK_LOGIN_REQUEST}/REQUEST_SUCCESS`
export const TRACK_LOGIN_REQUEST_FAILURE = `${TRACK_LOGIN_REQUEST}/REQUEST_FAILURE`
export const trackLoginRequest = payload => ({
  type: TRACK_LOGIN_REQUEST,
  payload,
  meta: { thunk: TRACK_LOGIN_REQUEST },
})

export const trackLoginRequestSuccess = (payload, thunk) => ({
  type: TRACK_LOGIN_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const trackLoginRequestFailure = (e, thunk) => ({
  type: TRACK_LOGIN_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const TRACK_SIGN_UP = `${NAMESPACE}/TRACK/SIGN_UP`
export const TRACK_SIGN_UP_REQUEST = `${TRACK_SIGN_UP}/REQUEST`
export const TRACK_SIGN_UP_REQUEST_SUCCESS = `${TRACK_SIGN_UP_REQUEST}/REQUEST_SUCCESS`
export const TRACK_SIGN_UP_REQUEST_FAILURE = `${TRACK_SIGN_UP_REQUEST}/REQUEST_FAILURE`
export const trackSignUpRequest = payload => ({
  type: TRACK_SIGN_UP_REQUEST,
  payload,
  meta: { thunk: TRACK_SIGN_UP_REQUEST },
})

export const trackSignUpRequestSuccess = (payload, thunk) => ({
  type: TRACK_SIGN_UP_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const trackSignUpRequestFailure = (e, thunk) => ({
  type: TRACK_SIGN_UP_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const TRACK_SESSION_VIEW = `${NAMESPACE}/TRACK/SESSION_VIEW`
export const trackSessionView = payload => ({
  type: TRACK_SESSION_VIEW,
  payload,
})

export const TRACK_PROJECT_VIEW = `${NAMESPACE}/TRACK/PROJECT_VIEW`
export const trackProjectView = payload => ({
  type: TRACK_PROJECT_VIEW,
  payload,
})

export const TRACK_PROJECT_CREATE = `${NAMESPACE}/TRACK/PROJECT_CREATE`
export const trackProjectCreate = payload => ({
  type: TRACK_PROJECT_CREATE,
  payload,
})

export const TRACK_SESSION_CREATE = `${NAMESPACE}/TRACK/SESSION_CREATE`
export const trackSessionCreate = payload => ({
  type: TRACK_SESSION_CREATE,
  payload,
})

export const TRACK_COMMENT_CREATE = `${NAMESPACE}/TRACK/COMMENT_CREATE`
export const trackCommentCreate = payload => ({
  type: TRACK_COMMENT_CREATE,
  payload,
})
