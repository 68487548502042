import React, { useCallback, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import first from 'lodash/first'
import Flex from '../../atoms/Flex'
import fromNotifications from '../../../store/ui-notifications/ui-notifications.selectors'
import { removeNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import Snackbar from '../../molecules/Snackbar'

const Wrapper = styled(Flex)`
  width: auto;
  transform: translateX(-50%);
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0;
  position: fixed;
  top: 0;
  left: 50%;
  text-align: center;
  z-index: 100000;

  @media only screen and (max-width: 640px) {
    left: 0;
    width: calc(100% - 16px);
    transform: translateX(0);
  }
`
const animatetop = keyframes`
0% {
  top: -300px;
  opacity: 0;
}
100% {
  top: 0;
  opacity: 1;
}
`

const StyledSnackbar = styled(Snackbar)`
  align-self: center;
  position: relative;
  animation: ${animatetop} 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
`

const updateServiceWorker = serviceWorkerRegistration => {
  const registrationWaiting = serviceWorkerRegistration.waiting
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
    registrationWaiting.addEventListener('statechange', e => {
      if (e.target.state === 'activated') {
        window.location.reload()
      }
    })
  }
}

const getCtaFn = ({ type: actionType, link, serviceWorkerRegistration }) => {
  if (actionType === 'refresh') {
    return () => {
      window.location.reload()
    }
  }
  if (actionType === 'link') {
    return () => {
      window.open(link)
    }
  }
  if (actionType === 'update') {
    return () => updateServiceWorker(serviceWorkerRegistration)
  }
  return () => {}
}

const Notifications = ({ ...props }) => {
  const dispatch = useDispatch()
  const notifications = useSelector(fromNotifications.getAllNotifications)
  const notification = first(notifications)
  const [isVisible, setIsVisible] = useState(!!notification)
  const removeNotificationCallback = useCallback(notificationId => {
    dispatch(removeNotification({ notificationId }))
  })
  const onCtaClick = useCallback(() => {
    const ctaFn = getCtaFn(notification?.callToAction)
    setIsVisible(false)
    setTimeout(() => {
      removeNotificationCallback(notification?.notificationId)
      ctaFn()
    }, 300)
  }, [notification, getCtaFn, setIsVisible, removeNotificationCallback])

  useEffect(() => {
    if (notification?.notificationId) {
      setIsVisible(true)
    }
  }, [notification?.notificationId])

  const callToAction = notification?.callToAction?.text
    ? {
        text: notification?.callToAction?.text,
        onClick: onCtaClick,
      }
    : null

  return (
    <Wrapper {...props}>
      {notification && (
        <StyledSnackbar
          isVisible={isVisible}
          key={notification.notificationId}
          text={notification.text}
          onClick={() => {
            setIsVisible(false)
            setTimeout(
              () => removeNotificationCallback(notification.notificationId),
              300
            )
          }}
          callToAction={callToAction}
        />
      )}
    </Wrapper>
  )
}

export default Notifications
