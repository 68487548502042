import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'
import Loader from '../../atoms/Loader'

const Wrapper = styled(Flex)`
  flex-direction: row;
  font-size: 14px;
`

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 16px;
  width: 32px;
`

const Email = styled(Flex)`
  flex-grow: 1;
  line-height: 32px;
  ${({ isPending }) => (isPending ? 'line-height: 16px;' : '')}
`

const Right = styled(Flex)`
  flex-grow: 0;
  line-height: 32px;
  color: ${prop('theme.colors.grey600')};
`

const RemoveText = styled(Flex)`
  color: ${prop('theme.colors.warning')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const AddButton = styled(Button)`
  padding: 0 16px;
`

const Pending = styled(Flex)`
  line-height: 16px;
  color: ${prop('theme.colors.secondary')};
  font-style: italic;
  margin-top: 4px;
`

const StyledLoader = styled(Loader)`
  margin: 4px 0;
`

const InvitedUserRow = ({
  email,
  picture,
  isOwner,
  isProjectContributor,
  isInvited,
  isPending,
  onRemoveUser,
  onAddUser,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const onRemoveClick = useCallback(() => {
    setIsLoading(true)
    onRemoveUser().finally(() => setIsLoading(false))
  })
  const onAddClick = useCallback(() => {
    setIsLoading(true)
    onAddUser().finally(() => setIsLoading(false))
  })
  return (
    <Wrapper {...props}>
      <StyledUserAvatar imgUrl={picture} name={email} status={null} />
      <Email isPending={isPending}>
        {email}
        {isPending && <Pending>pending</Pending>}
      </Email>
      <Right>
        {!isLoading && isOwner && 'Owner'}
        {!isLoading && isProjectContributor && 'Project contributor'}
        {!isLoading &&
          !isOwner &&
          !isProjectContributor &&
          (isInvited || isPending) && (
            <RemoveText
              onClick={onRemoveClick}
              data-test="invited-user-delete-button"
            >
              Remove
            </RemoveText>
          )}
        {!isLoading &&
          !isOwner &&
          !isProjectContributor &&
          !isInvited &&
          !isPending && (
            <AddButton
              isPrimary
              size="small"
              onClick={onAddClick}
              data-test="invited-user-add-button"
            >
              Add
            </AddButton>
          )}
        {isLoading && <StyledLoader size="24px" />}
      </Right>
    </Wrapper>
  )
}

InvitedUserRow.propTypes = {
  email: PropTypes.string.isRequired,
  picture: PropTypes.string,
  isOwner: PropTypes.bool,
  isProjectContributor: PropTypes.bool,
  isInvited: PropTypes.bool,
  isPending: PropTypes.bool,
  onRemoveUser: PropTypes.func,
  onAddUser: PropTypes.func,
}

InvitedUserRow.defaultProps = {
  picture: null,
  isOwner: false,
  isProjectContributor: false,
  isInvited: false,
  isPending: false,
  onRemoveUser: () => {},
  onAddUser: () => {},
}

export default InvitedUserRow
