import Quill from 'quill'

Quill.debug('error')

const Inline = Quill.import('blots/inline')

class MentionBlot extends Inline {}

MentionBlot.blotName = 'user-mention'
MentionBlot.tagName = 'em'
MentionBlot.className = 'ql-user-mention'

Quill.register(MentionBlot)

class SuggestionBlot extends Inline {}

SuggestionBlot.blotName = 'user-suggestion'
SuggestionBlot.tagName = 'strong'
SuggestionBlot.className = 'ql-user-suggestion'

Quill.register(MentionBlot)
Quill.register(SuggestionBlot)
