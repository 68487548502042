import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import AWSAppSyncClient from 'aws-appsync'
import appConfig from '../config'

const { AWS } = appConfig

const client = {
  instance: null,
}

export const getAppSyncClient = () => {
  if (!client.instance) {
    Auth.configure(AWS.Auth)
    Storage.configure(AWS.Storage)
    client.instance = new AWSAppSyncClient({
      url: AWS.Auth.aws_appsync_graphqlEndpoint,
      region: AWS.Auth.aws_appsync_region,
      disableOffline: true,
      auth: {
        type: AWS.Auth.aws_appsync_authenticationType,
        credentials: () => {
          const credentials = Auth.currentCredentials()
          return credentials
        },
      },
      complexObjectsCredentials: () => {
        const currentCredentials = Auth.currentCredentials()
        return currentCredentials
      },
    })
  }

  return client.instance
}

export default {
  getAppSyncClient,
}
