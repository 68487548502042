import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending } from 'redux-saga-thunk'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import prop from 'lodash/fp/prop'
import { withApollo } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'
import CogIcon from 'mdi-react/CogIcon'
import LogoutIcon from 'mdi-react/LogoutIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import BellIcon from 'mdi-react/BellIcon'
import { Tag } from '@zendeskgarden/react-tags'
import Flex from '../../atoms/Flex'
import DotsVerticalIcon from '../../atoms/Icons/dots_vertical.svg'
import SheetList from '../../atoms/SheetList'
import CreateProjectModalMobile from '../../molecules/CreateProjectModalMobile'
import MobileModal from '../../molecules/MobileModal'
import MobileTopBar from '../../molecules/MobileTopBar'
import OrganizationSheet from '../../molecules/OrganizationSheet'
import OrganizationModalMobile from '../../molecules/OrganizationModalMobile'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import ProjectsListMobile from '../ProjectsListMobile'
import ProjectPageMobile from '../../pages/ProjectPageMobile'
import CreateOrganizationPage from '../../pages/CreateOrganizationPage'
import {
  setCurrentProject,
  UPDATE_PROJECTS_REQUEST,
} from '../../../store/projects/projects.actions'
import fromSessions from '../../../store/sessions/sessions.selectors'
import useOrganization from '../../../store/hooks/useOrganization'
import useLoggedSession from '../../../store/hooks/useLoggedSession'
import { getFolderPathName } from '../../../util/paths'
import theme from '../../theme/old'
import ActivitiesPageMobile from '../../pages/ActivitiesPageMobile'
import ActivitiesList from '../ActivitiesList'
import useNotifications from '../../../store/hooks/useNotifications'

const isPending = state => pending(state, UPDATE_PROJECTS_REQUEST)

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const StyledProjectsListMobile = styled(ProjectsListMobile)`
  margin-top: 8px;
  flex-grow: 1;
`

const Organization = styled(Flex)`
  flex-direction: row;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: ${({ isPersonal, theme: _theme }) =>
    _theme.colors[isPersonal ? 'primary100' : 'secondary200']};
`

const OrganizationTitle = styled(Flex)`
  align-self: center;
  margin-left: 8px;
  color: ${({ isPersonal, theme: _theme }) =>
    _theme.colors[isPersonal ? 'primary' : 'secondary800']};
`

const OrganizationIcon = styled(ChevronDownIcon)`
  align-self: center;
  margin-left: 8px;
  fill: ${({ isPersonal, theme: _theme }) =>
    _theme.colors[isPersonal ? 'primary' : 'secondary800']};
`

const StyledTag = styled(Tag)`
  border-radius: 4px;
  margin-left: 16px;
  background-color: ${prop('theme.colors.secondary')};
  color: white;
`

const UnreadNotificationsIcon = styled(BellIcon)`
  && {
    fill: ${prop('theme.colors.secondary')};
  }
`

const EntryViewMobile = ({ isVisible, showProject }) => {
  const history = useHistory()
  const {
    currentOrganization,
    currentOrganizationId,
    isCurrentOrganizationPersonal,
  } = useOrganization()

  const { onLogoutHandler } = useLoggedSession()

  const currentOrganizationTitle = get(currentOrganization, 'title')

  const dispatch = useDispatch()
  const onProjectClick = useCallback(
    projectId => {
      dispatch(setCurrentProject(projectId))
      history.push(getFolderPathName(projectId))
    },
    [dispatch]
  )

  const {
    unreadNotificationsCount,
    onMarkAllNotificationsAsRead,
  } = useNotifications()

  const [isProjectPageVisible, setProjectPageVisible] = useState(showProject)
  useEffect(() => setProjectPageVisible(showProject), [showProject])

  const [
    isOrganizationSelectSheetVisible,
    setOrganizationSelectSheetVisible,
  ] = useState(false)

  const [isUserSettingsSheetVisible, setUserSettingsSheetVisible] = useState(
    false
  )

  const [isOrganizationSheetVisible, setOrganizationSheetVisible] = useState(
    false
  )
  const [isCreateProjectModalVisible, setCreateProjectModalVisible] = useState(
    false
  )

  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)

  const [isNotificationsPageVisible, setNotificationsPageVisible] = useState(
    false
  )

  const [isOrganizationModalVisible, setOrganizationModalVisible] = useState(
    false
  )

  const header = useMemo(() => {
    const isPersonal = currentOrganizationTitle.toLowerCase() === 'personal'
    return (
      <StyledMobileTopBar
        showUserAvatar
        rightIcon={DotsVerticalIcon}
        onUserAvatarClick={() => setUserSettingsSheetVisible(true)}
        onRightButtonClick={() => setOrganizationSheetVisible(true)}
      >
        <Organization
          isPersonal={isPersonal}
          onClick={() => setOrganizationSelectSheetVisible(true)}
        >
          <OrganizationTitle isPersonal={isPersonal}>
            {currentOrganizationTitle}
          </OrganizationTitle>
          <OrganizationIcon isPersonal={isPersonal} size={16} />
        </Organization>
      </StyledMobileTopBar>
    )
  }, [currentOrganizationTitle])

  const enrichedProjects = useSelector(fromSessions.getProjectsMergedSessions)

  const projects = useMemo(
    () =>
      enrichedProjects.map(project => {
        const { admin, edit, view } = project
        const _admin = admin || []
        const _edit = edit || []
        const _view = view || []
        const sharedWith = _admin.concat(_edit, _view)
        const isPrivate = sharedWith.length === 0
        const isShared = sharedWith.length > 0
        return {
          ...project,
          isPrivate,
          isShared,
          sharedWithCounter: sharedWith.length,
        }
      }),

    [enrichedProjects]
  )

  const isLoadingProjects = useSelector(isPending)

  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true)
  const [lastSkeletonOrganizationId, setLastSkeletonOrganizationId] = useState(
    null
  )
  useEffect(() => {
    if (
      projects?.length === 0 &&
      isLoadingProjects &&
      currentOrganizationId !== lastSkeletonOrganizationId
    ) {
      setShouldShowSkeleton(true)
      setLastSkeletonOrganizationId(currentOrganizationId)
      return
    }
    setShouldShowSkeleton(false)
  }, [currentOrganizationId, projects?.length, isLoadingProjects])

  const userSettingsSheetItems = [
    {
      text:
        unreadNotificationsCount > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Notifications
            <StyledTag>
              <span>{unreadNotificationsCount}</span>
            </StyledTag>
          </div>
        ) : (
          'Notifications'
        ),
      icon:
        unreadNotificationsCount > 0 ? (
          <UnreadNotificationsIcon />
        ) : (
          <BellIcon />
        ),
      onClick: () => setNotificationsPageVisible(true),
    },
    {
      text: 'Settings',
      icon: <CogIcon />,
      onClick: () => {
        history.push('/settings')
      },
    },
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      onClick: () => onLogoutHandler(),
    },
  ]

  const organizationSheetItems = [
    {
      text: 'Add project',
      icon: <FolderPlusIcon />,
      onClick: () => setCreateProjectModalVisible(true),
    },
  ]

  if (!isCurrentOrganizationPersonal) {
    organizationSheetItems.push({
      text: 'Members',
      icon: <AccountMultipleIcon />,
      onClick: () => setOrganizationModalVisible(true),
    })
  }

  return (
    <Flex>
      {isVisible && !isProjectPageVisible && (
        <MobileModal>
          <MobileTemplate
            header={header}
            body={
              <StyledProjectsListMobile
                projects={projects}
                showLoader={shouldShowSkeleton}
                onProjectClick={projectId => {
                  onProjectClick(projectId)
                  setProjectPageVisible(true)
                }}
                onCreateProject={() => setCreateProjectModalVisible(true)}
              />
            }
            footer={null}
            bodyStyle={{ backgroundColor: theme.colors.grey100 }}
          />
        </MobileModal>
      )}
      {isProjectPageVisible && (
        <ProjectPageMobile
          isVisible
          onBack={() => {
            // setProjectPageVisible(false)
            history.push('/app')
          }}
        />
      )}
      <OrganizationSheet
        onClose={() => setOrganizationSelectSheetVisible(false)}
        isVisible={isOrganizationSelectSheetVisible}
        onCreate={() => {
          setOrganizationSelectSheetVisible(false)
          setCreateOrganizationModalVisible(true)
        }}
      />
      {isOrganizationModalVisible && (
        <OrganizationModalMobile
          isVisible={isOrganizationModalVisible}
          onClose={() => {
            setOrganizationModalVisible(false)
          }}
        />
      )}
      <SheetList
        onClose={() => setOrganizationSheetVisible(false)}
        isVisible={isOrganizationSheetVisible}
        title="Workspace"
        items={organizationSheetItems}
      />
      <SheetList
        onClose={() => setUserSettingsSheetVisible(false)}
        isVisible={isUserSettingsSheetVisible}
        items={userSettingsSheetItems}
      />
      {isCreateProjectModalVisible && (
        <CreateProjectModalMobile
          isVisible={isCreateProjectModalVisible}
          onClose={() => {
            setCreateProjectModalVisible(false)
          }}
        />
      )}
      {isCreateOrganizationModalVisible && (
        <CreateOrganizationPage
          onClose={() => {
            setCreateOrganizationModalVisible(false)
          }}
        />
      )}
      {isNotificationsPageVisible && (
        <ActivitiesPageMobile
          unreadNotificationsCount={unreadNotificationsCount}
          notificationItems={<ActivitiesList />}
          onMarkAllAsReadClick={onMarkAllNotificationsAsRead}
          onClose={() => {
            setNotificationsPageVisible(false)
          }}
        />
      )}
    </Flex>
  )
}

EntryViewMobile.propTypes = {
  isVisible: PropTypes.bool,
  showProject: PropTypes.bool,
}

EntryViewMobile.defaultProps = {
  isVisible: false,
  showProject: false,
}

EntryViewMobile.whyDidYouRender = false

export default withApollo(EntryViewMobile)
