import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'

const useReduxSagaThunkButton = (
  thunk,
  { initialNode, pendingNode, fulfilledNode, rejectedNode, doneNode },
  finalTimeout = 2000
) => {
  const isPendingSelector = state => pending(state, thunk)
  const isFulfilledSelector = state => fulfilled(state, thunk)
  const isRejectedSelector = state => rejected(state, thunk)
  const isDoneSelector = state => done(state, thunk)

  const isPending = useSelector(isPendingSelector)
  const isDone = useSelector(isDoneSelector)
  const isFulfilled = useSelector(isFulfilledSelector)
  const isRejected = useSelector(isRejectedSelector)

  const [buttonNode, setButtonNode] = useState('Update')
  const [isButtonUsed, setButtonUsed] = useState(false)
  useEffect(() => {
    if (isPending && pendingNode) {
      setButtonNode(pendingNode)
      setButtonUsed(true)
      return
    }
    if (!isButtonUsed && initialNode) {
      setButtonNode(initialNode)
      return
    }
    if (isRejected && rejectedNode) {
      setButtonNode(rejectedNode)
    }
    if (isFulfilled && fulfilledNode) {
      setButtonNode(fulfilledNode)
    }
    if (isDone) {
      if (doneNode) {
        setButtonNode(doneNode)
      }
      setTimeout(() => setButtonUsed(false), finalTimeout)
    }
  }, [isButtonUsed, isPending, isRejected, isFulfilled, isDone])

  return {
    buttonNode,
    isButtonDisabled: isPending,
  }
}

export default useReduxSagaThunkButton
