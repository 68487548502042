import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'
import CloudSyncIcon from 'mdi-react/CloudSyncIcon'
import CloudCheckIcon from 'mdi-react/CloudCheckIcon'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: block;
  width: 100%;
  min-width: 160px;
  margin: auto;
  box-sizing: border-box;

  * {
    transition: all 0.3s linear;
  }
`

const ButtonBox = styled(Flex)`
  width: 100%;
  justify-content: center;
  flex-direction: row;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid ${prop('theme.colors.primary')};
  color: white;
  padding: 4px 8px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  transition: border-color 0.25s ease-in-out, box-shadow 0.1s ease-in-out,
    background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  background: linear-gradient(
    90deg,
    ${prop('theme.colors.green')} 0%,
    ${prop('theme.colors.green')} ${prop('percentage')}%,
    ${prop('theme.colors.primary')} ${prop('percentage')}%,
    ${prop('theme.colors.primary')} 100%
  );
  cursor: pointer;
`

const IconBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const UploadButton = ({ children, percentage, ...props }) => (
  <Wrapper {...props}>
    <ButtonBox percentage={percentage}>
      <IconBox>
        {percentage === 0 && <CloudUploadIcon />}
        {percentage > 0 && percentage < 100 && <CloudSyncIcon />}
        {percentage === 100 && <CloudCheckIcon />}
      </IconBox>
      {percentage === 0 && 'Upload recording'}
      {percentage > 0 && percentage < 100 && 'Uploading...'}
      {percentage === 100 && 'Upload complete'}
      {children}
    </ButtonBox>
  </Wrapper>
)

UploadButton.propTypes = {
  children: PropTypes.node,
  percentage: PropTypes.number,
}

UploadButton.defaultProps = {
  children: null,
  percentage: 0,
}

export default UploadButton
