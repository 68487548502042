import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import concat from 'lodash/concat'
import difference from 'lodash/difference'
import uniqBy from 'lodash/uniqBy'
import isNil from 'lodash/isNil'
import fromUsers from '../users/users.selectors'
import useGetSession from './useGetSession'
import useGetProject from './useGetProject'
import useGetRemoteTest from './useGetRemoteTest'

const useSessionOrRemoteTestUsers = ({ sessionId, remoteTestId }) => {
  const session = useGetSession(sessionId)
  const remoteTest = useGetRemoteTest(remoteTestId)

  const sessionProject = useGetProject(session?.projectId)
  const remoteTestProject = useGetProject(remoteTest?.projectId)

  const resource = session || remoteTest

  const resourceOwnerUser = useSelector(
    fromUsers.getUserById(resource?.createdBy)
  )

  const admin = get(resource, 'admin', []) || []
  const edit = get(resource, 'edit', []) || []
  const view = get(resource, 'view', []) || []

  const resourceSharedUserEmail = (resource?.admin || [])
    .concat(resource?.edit || [])
    .concat(resource?.view || [])

  const projectsSharedUserEmails = sessionId
    ? (sessionProject?.admin || [])
        .concat(sessionProject?.edit || [])
        .concat(sessionProject?.view || [])
    : (remoteTestProject?.admin || [])
        .concat(remoteTestProject?.edit || [])
        .concat(remoteTestProject?.view || [])

  const uniqProjectSharedUserEmails = difference(
    projectsSharedUserEmails,
    resourceSharedUserEmail
  )

  const users = useMemo(
    () =>
      concat(
        (admin || []).map(email => ({ email, permission: 'admin' })),
        (edit || []).map(email => ({ email, permission: 'edit' })),
        (view || []).map(email => ({ email, permission: 'view' })),
        uniqProjectSharedUserEmails.map(email => ({
          email,
          permission: 'edit',
        }))
      ),
    [admin, edit, view, uniqProjectSharedUserEmails]
  )

  const resourceUsers = useSelector(
    fromUsers.getUsersByEmail(
      concat(users.map(x => x.email), uniqProjectSharedUserEmails)
    )
  )

  const uniqResourceUsers = uniqBy(
    concat(resourceUsers, resourceOwnerUser),
    user => user?.email
  )

  return uniqResourceUsers.filter(x => !isNil(x))
}

export default useSessionOrRemoteTestUsers
