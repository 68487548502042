import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import {
  Dropdown,
  Menu,
  Item,
  PreviousItem,
  NextItem,
  Trigger,
  Separator,
} from '@zendeskgarden/react-dropdowns'
import { Button } from '@zendeskgarden/react-buttons'
import TrashIcon from 'mdi-react/TrashIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
// import FolderMoveOutlineIcon from 'mdi-react/FolderMoveOutlineIcon'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import EyeIcon from 'mdi-react/EyeIcon'
import LinkIcon from 'mdi-react/LinkIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import { v1 as uuidv1 } from 'uuid'
import Loader from '../../atoms/Loader'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import { getProjectsRequest } from '../../../store/projects/projects.actions'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import { moveSessionRequest } from '../../../store/sessions/sessions.actions'

const baseWidth = 32
const ActionsContainer = styled(Button).attrs({
  isBasic: true,
})`
  flex: 0 0 24px;
  width: ${baseWidth}px;
  height: ${baseWidth}px;
  cursor: pointer;
  padding: 8px;
  border-radius: ${baseWidth}px;
  align-items: center;
  visibility: ${ternary('isVisible')('visible', 'hidden')};
  position: absolute;
  top: calc(50% - ${baseWidth / 2}px);
  right: 8px;
  outline: none;

  && {
    outline: none;
    padding: 0;
    width: ${baseWidth}px;
    height: ${baseWidth}px;
    border-radius: ${baseWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && svg {
    fill: ${prop('theme.colors.grey400')};
  }
`
const menuItemCss = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 24px 8px 16px;

  && svg {
    margin-right: 16px;
  }
`
const StyledMenuItem = styled(Item)`
  ${menuItemCss}
`
const StyledNextItem = styled(NextItem)`
  ${menuItemCss}
`

const StyledLoader = styled(Loader).attrs({
  size: '24px',
})`
  padding: 24px 48px;
  align-self: center;
`

const EmptyItem = styled(Flex)`
  padding: 8px 36px 12px 36px;
  color: ${prop('theme.colors.grey600')};
  font-style: italic;
`

const stopEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const RemoteTestSettings = ({
  remoteTestId,
  isVisible,
  onShare,
  onPreview,
  onLink,
  onDelete,
  onEdit,
  ...props
}) => {
  const organizations = useSelector(fromOrganizations.getOrganizations)
  const [menuTree, setMenuTree] = useState(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isLoadingProjects, setLoadingProjects] = useState(false)
  const [projects, setProjects] = useState(false)
  const [selectedMoveToProject, setSelectedMoveToProject] = useState(null)

  const dispatch = useDispatch()
  const getOrganizationProjects = useCallback(
    organizationId => {
      setLoadingProjects(true)
      dispatch(getProjectsRequest(organizationId)).then(data => {
        const _projects = data?.projects || []
        setProjects(_projects)
        setLoadingProjects(false)
      })
    },
    [dispatch]
  )

  const renderItems = () => {
    if (menuTree === 'move') {
      return (
        <>
          <PreviousItem value="root">Workspaces</PreviousItem>
          <Separator />
          {organizations.map(organization => (
            <NextItem
              key={organization.pk}
              value="workspace"
              onClick={() => {
                getOrganizationProjects(organization.pk)
              }}
            >
              {organization.title}
            </NextItem>
          ))}
        </>
      )
    }

    if (menuTree === 'workspace') {
      return (
        <>
          <PreviousItem value="move">Projects</PreviousItem>
          <Separator />
          {isLoadingProjects && <StyledLoader />}
          {!isLoadingProjects && projects.length === 0 && (
            <EmptyItem>Empty workspace</EmptyItem>
          )}
          {!isLoadingProjects &&
            projects.length > 0 &&
            projects.map(project => {
              return (
                <Item
                  key={project.pk}
                  value="move-to-project"
                  onClick={() => setSelectedMoveToProject(project)}
                >
                  {project.title}
                </Item>
              )
            })}
        </>
      )
    }

    return (
      <>
        <StyledMenuItem value="share" onClick={stopEvent}>
          <AccountMultipleIcon size={20} />
          Share
        </StyledMenuItem>
        <StyledNextItem value="preview" onClick={stopEvent}>
          <EyeIcon size={20} />
          Preview
        </StyledNextItem>
        <StyledNextItem value="link" onClick={stopEvent}>
          <LinkIcon size={20} />
          Recruitment link
        </StyledNextItem>
        <StyledNextItem value="edit" onClick={stopEvent}>
          <PencilIcon size={20} />
          Edit
        </StyledNextItem>
        {/* <StyledNextItem value="move" onClick={stopEvent}>
          <FolderMoveOutlineIcon size={20} />
          Move to
        </StyledNextItem> */}
        <StyledMenuItem value="delete" onClick={stopEvent}>
          <TrashIcon size={20} />
          Delete
        </StyledMenuItem>
      </>
    )
  }

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      onClick={stopEvent}
      highlightedIndex={null}
      onStateChange={(changes, stateAndHelpers) => {
        if (Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
          const shouldBeOpen =
            changes.selectedItem === 'root' ||
            changes.selectedItem === 'move' ||
            changes.selectedItem === 'workspace' ||
            changes.isOpen
          setDropdownOpen(shouldBeOpen)

          if (!shouldBeOpen) {
            stateAndHelpers.clearSelection()
            stateAndHelpers.clearItems()
          }
        }

        if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
          setMenuTree(changes.selectedItem)
        }
      }}
      onSelect={item => {
        if (item === 'share') {
          onShare()
        }
        if (item === 'preview') {
          onPreview()
        }
        if (item === 'link') {
          onLink()
        }
        if (item === 'edit') {
          onEdit()
        }
        if (item === 'delete') {
          onDelete()
        }
        if (item === 'move-to-project') {
          setTimeout(() => {
            dispatch(
              moveSessionRequest({
                remoteTestId,
                destinationProjectId: selectedMoveToProject.pk,
              })
            )
              .then(() => {
                dispatch(
                  addNotification({
                    notificationId: uuidv1(),
                    text: `Recording successfully moved to project ${selectedMoveToProject.title}`,
                  })
                )
              })
              .catch(err => {
                console.error(err)
                dispatch(
                  addNotification({
                    notificationId: uuidv1(),
                    text: `Something went wrong`,
                  })
                )
              })
          }, 100)
        }
      }}
    >
      <Trigger>
        <ActionsContainer isVisible={isVisible} onClick={stopEvent} {...props}>
          <DotsVerticalIcon size={24} />
        </ActionsContainer>
      </Trigger>
      <Menu
        placement="end-top"
        hasArrow
        popperModifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
        }}
        onClick={stopEvent}
      >
        {renderItems()}
      </Menu>
    </Dropdown>
  )
}

RemoteTestSettings.propTypes = {
  remoteTestId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onShare: PropTypes.func,
  onPreview: PropTypes.func,
  onLink: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

RemoteTestSettings.defaultProps = {
  isVisible: false,
  onShare: () => {},
  onPreview: () => {},
  onLink: () => {},
  onDelete: () => {},
  onEdit: () => {},
}

export default RemoteTestSettings
