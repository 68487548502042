import React from 'react'
import PropTypes from 'prop-types'
import WorkspaceLogoPicker from './WorkspaceLogoPicker'
import pictureUpload from './pictureUpload'

const WorkspaceLogoPickerContainer = ({ onPictureChange, name, ...props }) => (
  <WorkspaceLogoPicker
    {...props}
    name={name}
    onFile={async file => {
      const pictureUrl = await pictureUpload(file)
      console.log('>>> pictureUrl', pictureUrl)
      onPictureChange(pictureUrl)
    }}
  />
)

WorkspaceLogoPickerContainer.propTypes = {
  onPictureChange: PropTypes.func,
  name: PropTypes.string,
}

WorkspaceLogoPickerContainer.defaultProps = {
  onPictureChange: () => {},
  name: '',
}

export default WorkspaceLogoPickerContainer
