import React from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import SingleComment from './SingleComment'
import { MentionSuggestionType } from '../MentionSuggestion/MentionSuggestion'
import { getReplyUrl } from '../../../lib/urls'
import useReply from '../../../store/hooks/useReply'
import useUserInfo from '../../../store/hooks/useUserInfo'
import useCRUDReply from '../../../store/hooks/useCRUDReply'
import useSlack from '../../hooks/useSlack'

const CommentReply = ({
  commentId,
  isInlineEdit,
  isEditMode,
  mentions,
  onCopyLink,
  onEdit,
  replyId,
  showSlack,
  ...props
}) => {
  const { reply, replyUser } = useReply(commentId, replyId)
  const { onUpdateReplyHandler, onDeleteReplyHandler } = useCRUDReply(
    commentId,
    replyId
  )
  const { userIdentityId, isLogged: isUserLogged } = useUserInfo()
  const { hasSlack } = useSlack()

  return (
    <SingleComment
      {...props}
      isEditMode={isEditMode}
      userName={replyUser?.name}
      pictureUrl={replyUser?.picture}
      text={reply?.text}
      mentions={mentions}
      createdAt={reply?.createdAt}
      hideResolve
      showJira={false}
      showTrello={false}
      showSlack={isUserLogged && showSlack}
      isSlackEnabled={hasSlack}
      canEdit={reply?.createdBy === userIdentityId}
      onEdit={onEdit}
      isInlineEdit={isInlineEdit}
      onUpdate={(text, _mentions) => onUpdateReplyHandler(text, _mentions)}
      onDelete={() => onDeleteReplyHandler()}
      onCopyLink={() => {
        copy(getReplyUrl(commentId, replyId))
        onCopyLink()
      }}
    />
  )
}

CommentReply.propTypes = {
  commentId: PropTypes.string.isRequired,
  isInlineEdit: PropTypes.bool,
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  onCopyLink: PropTypes.func,
  onEdit: PropTypes.func,
  replyId: PropTypes.string.isRequired,
  showSlack: PropTypes.bool,
  isEditMode: PropTypes.bool,
}

CommentReply.defaultProps = {
  isInlineEdit: true,
  mentions: [],
  onCopyLink: () => {},
  onEdit: () => {},
  showSlack: true,
  isEditMode: false,
}

export default CommentReply
