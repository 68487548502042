import { useEffect } from 'react'

const useScrollIntoView = (ref, isSomething, config = {}) => {
  useEffect(() => {
    if (ref?.current && isSomething) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        ...config,
      })
    }
  }, [isSomething, ref])
}

export default useScrollIntoView
