import React from 'react'
import { useSelector } from 'react-redux'
import axios, { post } from 'axios'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import fromUser from '../../../store/user/user.selectors'
import appConfig, { S3_PICTURE_URL } from '../../../config'
import AvatarPicker from './AvatarPicker'

const fileHandler = async (file, email) => {
  const mimeType = get(file, 'type')
  const imageType = mimeType.split('/')[1]
  const url = appConfig.Lambdas.pictureUpload
    .replace('{email}', email)
    .replace('{imageType}', imageType)

  const response = await axios.get(url)

  const { url: uploadUrl, fields } = response.data

  const formData = new FormData()
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key])
  })
  formData.append('file', file)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  await post(uploadUrl, formData, config)
  return `${S3_PICTURE_URL}/${btoa(email)}.${imageType}`
}

const AvatarPickerContainer = ({ onPictureChange, name, ...props }) => {
  const email = useSelector(fromUser.getEmail)
  const currentUserName = useSelector(fromUser.getName)
  return (
    <AvatarPicker
      {...props}
      name={name || currentUserName}
      onFile={async file => {
        const pictureUrl = await fileHandler(file, email)
        onPictureChange(pictureUrl)
      }}
    />
  )
}

AvatarPickerContainer.propTypes = {
  onPictureChange: PropTypes.func,
  name: PropTypes.string,
}

AvatarPickerContainer.defaultProps = {
  onPictureChange: () => {},
  name: '',
}

export default AvatarPickerContainer
