import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import AccountArrowRightIcon from 'mdi-react/AccountArrowRightIcon'
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import Flex from '../../atoms/Flex'
import ProgressBar from '../../atoms/ProgressBar'
import SheetList from '../../atoms/SheetList'
import Loader from '../../atoms/Loader'
import MobileModal from '../../molecules/MobileModal'
import MobileTopBar from '../../molecules/MobileTopBar'
import Member from '../../molecules/Member'
import IconButton from '../../molecules/IconButton'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import useCRUDOrganization from '../../../store/hooks/useCRUDOrganization'
import useUserInfo from '../../../store/hooks/useUserInfo'
import api from '../../../api'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const FullWidthFlex = styled(Flex)`
  width: calc(100vw - 48px);
`

const MembersBox = styled(Flex)`
  margin-top: 24px;
  width: 100%;
`

const Title = styled.h3`
  text-align: center;
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const CenteredBox = styled(Flex)`
  width: calc(100vw - 48px);
  max-width: 460px;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
`

const StyledField = styled(Field)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
`

const StyledInput = styled(Input)`
  display: flex;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`
const StyledIconButton = styled(IconButton)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  && {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    flex-grow: 0;
    padding: 0;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`

const getEmailValid = email =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)

const ROLES = {
  member: 'member',
  admin: 'admin',
}

const CreateOrganizationPage = ({ onClose: onModalClose }) => {
  const [organizationName, setOrganizationName] = useState('')
  const [isCreatingOrganization, setCreatingOrganization] = useState(false)
  const [members, setMembers] = useState([])
  const { userIdentityId: inviterIdentityId } = useUserInfo()

  const { onCreateOrganizationHandler } = useCRUDOrganization()

  const createOrganization = useCallback(() => {
    setCreatingOrganization(true)
    return onCreateOrganizationHandler(organizationName)
      .then(organizationData => {
        const organizationId = organizationData?.data?.createOrganization?.pk
        if (!organizationId) {
          return false
        }
        return Promise.all(
          members.map(member => {
            return api.addOrganizationMember({
              organizationId,
              userRole: member.role,
              userEmail: member.email,
              inviterIdentityId,
            })
          })
        )
      })
      .catch(err => {
        console.error(
          'An error occurred during the creation of the organization',
          organizationName,
          err
        )
        return false
      })
      .finally(() => {
        setCreatingOrganization(false)
        onModalClose()
      })
  }, [
    organizationName,
    members,
    onCreateOrganizationHandler,
    onModalClose,
    inviterIdentityId,
  ])

  const inputEmailRef = useRef(null)

  const [isFirstStep, setFirstStep] = useState(true)
  const [isNameEmpty, setNameEmpty] = useState(true)
  const [memberEmail, setMemberEmail] = useState(true)
  const [isEmailValid, setEmailValid] = useState(false)
  const [isMemberSheetVisible, setMemberSheetVisible] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)

  const memberSheetItems = useMemo(() => {
    if (!selectedMember) {
      return []
    }

    const moveToRole =
      selectedMember.role === ROLES.member ? ROLES.admin : ROLES.member

    const roleDescription =
      moveToRole === ROLES.member
        ? 'Can create projects and recordings within the workspace'
        : 'Can invite new members, plus all the member capabilities'

    return [
      {
        text: `Change role to ${capitalize(moveToRole)}`,
        subtext: roleDescription,
        icon: <AccountArrowRightIcon />,
        onClick: () => {
          setMemberSheetVisible(false)
          const newMembers = members.map(member => {
            if (member.email === selectedMember.email) {
              return {
                ...member,
                role: moveToRole.toLowerCase(),
              }
            }
            return member
          })
          setMembers(newMembers)
        },
      },
      {
        text: 'Remove',
        icon: <AccountRemoveIcon />,
        style: { color: 'red' },
        iconColor: 'red',
        onClick: () => {
          setMemberSheetVisible(false)
          const filteredMembers = members.filter(
            member => member.email !== selectedMember.email
          )
          setMembers(filteredMembers)
        },
      },
    ]
  }, [selectedMember, members])

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        rightIcon={CloseIcon}
        onRightButtonClick={onModalClose}
      >
        Create Team Workspace
      </StyledMobileTopBar>
    ),
    []
  )

  const body = useMemo(
    () => (
      <>
        <CenteredBox>
          <ProgressBar width={isFirstStep ? 50 : 100} />
        </CenteredBox>
        {isFirstStep && (
          <CenteredBox>
            <FullWidthFlex>
              <Title>What&apos;s your team name?</Title>
              <Field>
                <Input
                  placeholder="Superhuman team..."
                  onInput={e => {
                    setNameEmpty(e.target.value === '')
                    setOrganizationName(e.target.value)
                  }}
                />
              </Field>
            </FullWidthFlex>
          </CenteredBox>
        )}
        {!isFirstStep && (
          <>
            <CenteredBox>
              <FullWidthFlex>
                <Title>Start inviting your teammates</Title>
                <StyledField>
                  <Flex
                    direction="row"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    <StyledInput
                      placeholder="Teammate email..."
                      ref={inputEmailRef}
                      onInput={e => {
                        const email = e.target.value
                        setMemberEmail(email)
                        setEmailValid(getEmailValid(email))
                      }}
                    />
                    <StyledIconButton
                      icon={AccountPlusIcon}
                      disabled={!isEmailValid}
                      onClick={() => {
                        setMembers([
                          ...members,
                          {
                            email: memberEmail,
                            role: ROLES.member,
                          },
                        ])
                        inputEmailRef.current.value = ''
                        setMemberEmail('')
                        setEmailValid(false)
                      }}
                    />
                  </Flex>
                </StyledField>
              </FullWidthFlex>
            </CenteredBox>
            <MembersBox>
              {members.map(({ email, role }) => (
                <Member
                  email={email}
                  name={email}
                  role={role}
                  onSettingsClick={() => {
                    setSelectedMember({ email, role })
                    setMemberSheetVisible(true)
                  }}
                />
              ))}
            </MembersBox>
          </>
        )}
      </>
    ),
    [isFirstStep, inputEmailRef, isNameEmpty, memberEmail, members]
  )

  const footer = useMemo(
    () => (
      <Footer>
        {isFirstStep && (
          <Button
            isPrimary
            disabled={isNameEmpty}
            onClick={() => setFirstStep(false)}
          >
            Next
          </Button>
        )}
        {!isFirstStep && (
          <Button
            isPrimary
            onClick={createOrganization}
            disabled={isCreatingOrganization}
          >
            {isCreatingOrganization && <Loader size="16px" />}
            {!isCreatingOrganization && 'Create'}
          </Button>
        )}
      </Footer>
    ),
    [isFirstStep, isNameEmpty, createOrganization, isCreatingOrganization]
  )

  return (
    <>
      <MobileModal>
        <MobileTemplate header={header} body={body} footer={footer} />
      </MobileModal>
      <SheetList
        title={`Member ${selectedMember?.email}`}
        onClose={() => setMemberSheetVisible(false)}
        isVisible={isMemberSheetVisible}
        items={memberSheetItems}
      />
    </>
  )
}

CreateOrganizationPage.propTypes = {
  onClose: PropTypes.func,
}

CreateOrganizationPage.defaultProps = {
  onClose: () => {},
}

export default CreateOrganizationPage
