import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import { createSelector } from 'reselect'
import { STATE_KEY, STATE_SUB_KEY } from './integrations.trello.reducers'

const getState = state => getImmutable(state, `${STATE_KEY}\\.${STATE_SUB_KEY}`)

const getHasTrello = createSelector(
  getState,
  get('hasTrello')
)

const getTrelloBoards = createSelector(
  getState,
  get('boards')
)

const getTrelloLists = createSelector(
  getState,
  get('lists')
)

export default {
  getHasTrello,
  getTrelloBoards,
  getTrelloLists,
}
