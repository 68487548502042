import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input, Message } from '@zendeskgarden/react-forms'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
} from '@zendeskgarden/react-modals'

const WorkspaceNameModal = ({
  isVisible,
  onClose,
  workspaceTitle,
  onTitleUpdate,
  isRequestLoading,
  isRequestRejected,
}) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if (isVisible && inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])

  const [title, setTitle] = useState(workspaceTitle)
  const onInput = e => setTitle(e.target.value)

  return (
    <Modal isAnimated={false} data-test="name-settings-modal">
      <Header>Modify the workspace name</Header>
      <Body>
        <Field>
          <Input
            ref={inputRef}
            placeholder="Workspace name"
            autoFocus
            focused
            onInput={onInput}
            defaultValue={workspaceTitle}
          />
          {isRequestRejected && (
            <Message validation="Error">An error occurred</Message>
          )}
        </Field>
      </Body>
      <Footer>
        <FooterItem>
          <Button isBasic style={{ marginRight: '16px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isPrimary
            disabled={isRequestLoading}
            data-test="save-button"
            onClick={() => onTitleUpdate(title).then(() => onClose())}
          >
            Save
          </Button>
        </FooterItem>
      </Footer>
    </Modal>
  )
}

WorkspaceNameModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  workspaceTitle: PropTypes.string,
  onTitleUpdate: PropTypes.func,
  isRequestLoading: PropTypes.bool,
  isRequestRejected: PropTypes.bool,
}

WorkspaceNameModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  workspaceTitle: '',
  onTitleUpdate: () => {},
  isRequestLoading: false,
  isRequestRejected: false,
}

export default WorkspaceNameModal
