import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import {
  Dropdown,
  Menu,
  MediaItem,
  MediaBody,
  MediaFigure,
  Trigger,
} from '@zendeskgarden/react-dropdowns'
import Tippy from '@tippyjs/react'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import ShareVariantIcon from 'mdi-react/ShareVariantIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import JiraIconSvg from '../../../assets/mark-gradient-neutral-jira-software.svg'
import TrelloIconSvg from '../../../assets/mark-gradient-neutral-trello.svg'
import SlackIconSvg from '../../../assets/Slack_Mark_Monochrome_Grey.svg'
import Flex from '../../atoms/Flex'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const CommentSettingsButton = styled(Flex)`
  position: absolute;
  top: 4px;
  right: 2px;
  z-index: 21;
  cursor: pointer;

  && svg {
    fill: ${prop('theme.colors.grey400')};
  }

  &&:hover svg {
    fill: ${prop('theme.colors.secondary')};
  }
`

const StyledMediaItem = styled(MediaItem)`
  && {
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 8px 16px !important;
  }

  && svg {
    fill: ${prop('theme.colors.grey600')} !important;
    width: 16px !important;
    height: 16px !important;
    margin-right: 8px !important;
  }
`

const StyledMediaItemDelete = styled(StyledMediaItem)`
  && {
    color: rgb(211, 69, 91) !important;
  }
  && svg {
    fill: rgb(211, 69, 91) !important;
  }
`

const JiraLogo = styled.img`
  width: 16px;
  height: 16px;
  transform: scale(1.2);
  margin-right: 8px !important;
`

const TrelloLogo = styled.img`
  width: 16px;
  height: 16px;
  transform: scale(1.2);
  margin-right: 8px !important;
`

const SlackLogo = styled.img`
  width: 16px;
  height: 16px;
  transform: scale(1);
  margin-right: 8px !important;
`

const CommentSettings = ({
  onEdit,
  onDelete,
  onCopyLink,
  isDeleteEnabled,
  isEditEnabled,
  isCopyLinkEnabled,
  isJiraEnabled,
  showJira,
  showTrello,
  showSlack,
  isTrelloEnabled,
  isSlackEnabled,
  onCreateJiraIssue,
  onCreateTrelloCard,
  onSendSlackMessage,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClickEvent = () => setIsOpen(false)
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onClickEvent)

      return () => {
        document.removeEventListener('click', onClickEvent)
      }
    }

    document.removeEventListener('click', onClickEvent)
    return () => {
      document.removeEventListener('click', onClickEvent)
    }
  }, [isOpen])
  return (
    <Dropdown
      isOpen={isOpen}
      onSelect={item => {
        if (item === 'option-edit') {
          onEdit()
        }
        if (item === 'option-delete') {
          onDelete()
        }
        if (item === 'option-copy-link') {
          onCopyLink()
        }
        if (item === 'option-create-jira') {
          onCreateJiraIssue()
        }
        if (item === 'option-create-trello') {
          onCreateTrelloCard()
        }
        if (item === 'option-send-slack-message') {
          onSendSlackMessage()
        }
      }}
    >
      <Trigger>
        <Tippy content="Settings" placement="top" arrow={false} touch={false}>
          <CommentSettingsButton {...props} onClick={() => setIsOpen(!isOpen)}>
            <DotsVerticalIcon size="16" />
          </CommentSettingsButton>
        </Tippy>
      </Trigger>
      <Menu isCompact placement="start-top">
        {isEditEnabled && (
          <StyledMediaItem value="option-edit">
            <MediaFigure>
              <PencilIcon size={16} />
            </MediaFigure>
            <MediaBody>Edit</MediaBody>
          </StyledMediaItem>
        )}
        {isCopyLinkEnabled && (
          <StyledMediaItem value="option-copy-link">
            <MediaFigure>
              <ShareVariantIcon size={16} />
            </MediaFigure>
            <MediaBody>Copy Link</MediaBody>
          </StyledMediaItem>
        )}
        {showJira && (
          <StyledMediaItem value="option-create-jira">
            <MediaFigure>
              <JiraLogo src={JiraIconSvg} alt="jira" />
            </MediaFigure>
            <MediaBody>
              {isJiraEnabled ? 'Create Jira Issue' : 'Connect Jira'}
            </MediaBody>
          </StyledMediaItem>
        )}
        {showTrello && (
          <StyledMediaItem value="option-create-trello">
            <MediaFigure>
              <TrelloLogo src={TrelloIconSvg} alt="trello" />
            </MediaFigure>
            <MediaBody>
              {isTrelloEnabled ? 'Create Trello Card' : 'Connect Trello'}
            </MediaBody>
          </StyledMediaItem>
        )}
        {showSlack && (
          <StyledMediaItem value="option-send-slack-message">
            <MediaFigure>
              <SlackLogo src={SlackIconSvg} alt="slack" />
            </MediaFigure>
            <MediaBody>
              {isSlackEnabled ? 'Send to Slack' : 'Connect Slack'}
            </MediaBody>
          </StyledMediaItem>
        )}
        {isDeleteEnabled && (
          <StyledMediaItemDelete
            value="option-delete"
            data-test="comment-setting-delete-option"
          >
            <MediaFigure>
              <DeleteIcon size={16} />
            </MediaFigure>
            <MediaBody>Delete</MediaBody>
          </StyledMediaItemDelete>
        )}
      </Menu>
    </Dropdown>
  )
}

CommentSettings.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopyLink: PropTypes.func,
  onCreateJiraIssue: PropTypes.func,
  onCreateTrelloCard: PropTypes.func,
  onSendSlackMessage: PropTypes.func,
  isDeleteEnabled: PropTypes.bool,
  isEditEnabled: PropTypes.bool,
  isCopyLinkEnabled: PropTypes.bool,
  isJiraEnabled: PropTypes.bool,
  isTrelloEnabled: PropTypes.bool,
  isSlackEnabled: PropTypes.bool,
  showJira: PropTypes.bool,
  showTrello: PropTypes.bool,
  showSlack: PropTypes.bool,
}

CommentSettings.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  onCopyLink: () => {},
  onCreateJiraIssue: () => {},
  onCreateTrelloCard: () => {},
  onSendSlackMessage: () => {},
  isDeleteEnabled: false,
  isEditEnabled: false,
  isCopyLinkEnabled: true,
  isJiraEnabled: false,
  isTrelloEnabled: false,
  isSlackEnabled: false,
  showJira: false,
  showTrello: false,
  showSlack: false,
}

export default CommentSettings
