import get from 'lodash/get'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { eventChannel, END } from 'redux-saga'
import gql from 'graphql-tag'
import { listUserOrganizations } from '../../graphql/queries'
import {
  onCreateOrganization,
  onUpdateOrganization,
} from '../../graphql/subscriptions'
import {
  updateOrganization as updateOrganizationGql,
  updateOrganizationEmailDomains as updateOrganizationEmailDomainsGql,
  deleteOrganization as deleteOrganizationGql,
} from '../../graphql/mutations'
import {
  GET_ORGANIZATIONS_REQUEST,
  getOrganizationsRequest,
  getOrganizationsRequestSuccess,
  getOrganizationsRequestFailure,
  SET_CURRENT_ORGANIZATION,
  UPDATE_ORGANIZATION_REQUEST,
  updateOrganizationRequestSuccess,
  updateOrganizationRequestFailure,
  DELETE_ORGANIZATION_REQUEST,
  deleteOrganizationRequestSuccess,
  deleteOrganizationRequestFailure,
  UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST,
  updateOrganizationEmailDomainsRequestSuccess,
  updateOrganizationEmailDomainsRequestFailure,
} from './organizations.actions'
import { getUsersRequest } from '../users/users.actions'
import fromUser from '../user/user.selectors'
import { addNotification } from '../ui-notifications/ui-notifications.actions'
import fromOrganizations from './organizations.selectors'
import { LOGIN_REQUEST_SUCCESS, AFTER_LOGIN } from '../actions'
import { activityBasedAction } from '../utils.sagas'
import { getOrganizationMemberIds } from '../../components/molecules/OrganizationMembers/useOrganizationMembers'

function* fetchOrganizations({ apolloClient }) {
  const data = yield call(apolloClient.query, {
    query: gql(listUserOrganizations),
    fetchPolicy: 'network-only',
  })

  return get(data, 'data.listUserOrganizations.items')
}

function* getOrganizations({ apolloClient }, { meta: { thunk } }) {
  try {
    const items = yield call(fetchOrganizations, { apolloClient })
    yield put(
      getOrganizationsRequestSuccess({ organizations: items || [] }, thunk)
    )
  } catch (err) {
    console.error(err)
    yield put(getOrganizationsRequestFailure(err, thunk))
  }
}

function* dispatchUpdateOrganizations() {
  const isLogged = yield select(fromUser.isLogged)
  if (!isLogged) {
    return
  }
  yield put(getOrganizationsRequest())
}

function* watchForOrganization({ apolloClient }) {
  try {
    yield dispatchUpdateOrganizations()

    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateOrganization),
        })
        .subscribe({
          next: () => {
            emitter('new-organization-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateOrganization),
        })
        .subscribe({
          next: () => {
            emitter('update-organization-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        updateSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      yield put(getOrganizationsRequest())
    }
  } catch (e) {
    console.error(e)
  }
}

function* onSetCurrentOrganization({ payload: { organizationId } }) {
  const currentOrganization = yield select(
    fromOrganizations.getOrganizationById(organizationId)
  )
  const orgMembersIds = getOrganizationMemberIds(currentOrganization)
  if (orgMembersIds.length > 0) {
    yield put(getUsersRequest(orgMembersIds))
  }
}

function* startOrganizationsFork({ apolloClient }) {
  yield fork(watchForOrganization, { apolloClient })
  yield fork(activityBasedAction, dispatchUpdateOrganizations, 26000, {
    apolloClient,
  })
}

function* updateOrganization({ apolloClient }, action) {
  const {
    meta: { thunk },
    payload: { organizationId, title, pictureUrl, backgroundColor },
  } = action
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(updateOrganizationGql),
      variables: {
        input: {
          organizationId,
          title,
          pictureUrl,
          backgroundColor,
        },
      },
    })

    if (result.data.updateOrganization.pk) {
      yield put(
        updateOrganizationRequestSuccess(
          {
            organizationId,
            title,
            pictureUrl,
            backgroundColor,
          },
          thunk
        )
      )
      return
    }
    yield put(updateOrganizationRequestFailure('Error', thunk))
  } catch (err) {
    console.error(err)
    yield put(updateOrganizationRequestFailure(err, thunk))
  }
}

function* updateOrganizationEmailDomains({ apolloClient }, action) {
  const {
    meta: { thunk },
    payload: { organizationId, emailDomains },
  } = action
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(updateOrganizationEmailDomainsGql),
      variables: {
        input: {
          organizationId,
          emailDomains,
        },
      },
    })

    if (result.data.updateOrganizationEmailDomains.pk) {
      yield put(
        updateOrganizationEmailDomainsRequestSuccess(
          {
            organizationId,
            emailDomains,
          },
          thunk
        )
      )
      yield put(
        addNotification({
          notificationId: uuidv4(),
          text: 'Email domains updated',
          isPersisted: false,
        })
      )
      return
    }
    yield put(updateOrganizationEmailDomainsRequestFailure('Error', thunk))
  } catch (err) {
    console.error(err)
    yield put(updateOrganizationEmailDomainsRequestFailure(err, thunk))
  }
}

function* deleteOrganization({ apolloClient }, action) {
  const {
    meta: { thunk },
    payload: { organizationId },
  } = action
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(deleteOrganizationGql),
      variables: {
        organizationId,
      },
    })

    if (result.data.deleteOrganization.pk) {
      yield put(deleteOrganizationRequestSuccess(organizationId, thunk))
      yield dispatchUpdateOrganizations()
      return
    }
    yield put(deleteOrganizationRequestFailure('Error', thunk))
  } catch (err) {
    console.error(err)
    yield put(deleteOrganizationRequestFailure(err, thunk))
  }
}

export default function* organizationsSagas({ apolloClient }) {
  yield all([
    yield takeEvery(AFTER_LOGIN, dispatchUpdateOrganizations, {
      apolloClient,
    }),
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startOrganizationsFork, {
      apolloClient,
    }),
    yield takeEvery(GET_ORGANIZATIONS_REQUEST, getOrganizations, {
      apolloClient,
    }),
    yield takeEvery(SET_CURRENT_ORGANIZATION, onSetCurrentOrganization),
    yield takeEvery(UPDATE_ORGANIZATION_REQUEST, updateOrganization, {
      apolloClient,
    }),
    yield takeEvery(
      UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST,
      updateOrganizationEmailDomains,
      {
        apolloClient,
      }
    ),
    yield takeEvery(DELETE_ORGANIZATION_REQUEST, deleteOrganization, {
      apolloClient,
    }),
  ])
}
