const NAMESPACE = 'INTEGRATIONS/JIRA'

export const GET_JIRA = `${NAMESPACE}/GET`
export const GET_JIRA_REQUEST = `${GET_JIRA}/REQUEST`
export const GET_JIRA_REQUEST_SUCCESS = `${GET_JIRA_REQUEST}_SUCCESS`
export const GET_JIRA_REQUEST_FAILURE = `${GET_JIRA_REQUEST}_FAILURE`

export const getJiraRequest = () => ({
  type: GET_JIRA_REQUEST,
  payload: {},
  meta: { thunk: GET_JIRA_REQUEST },
})

export const getJiraRequestSuccess = (data, thunk) => ({
  type: GET_JIRA_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const getJiraRequestFailure = (e, thunk) => ({
  type: GET_JIRA_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_JIRA = `${NAMESPACE}/UPDATE`
export const UPDATE_JIRA_REQUEST = `${UPDATE_JIRA}/REQUEST`
export const UPDATE_JIRA_REQUEST_SUCCESS = `${UPDATE_JIRA_REQUEST}_SUCCESS`
export const UPDATE_JIRA_REQUEST_FAILURE = `${UPDATE_JIRA_REQUEST}_FAILURE`

export const updateJiraRequest = () => ({
  type: UPDATE_JIRA_REQUEST,
  payload: {},
  meta: { thunk: UPDATE_JIRA_REQUEST },
})

export const updateJiraRequestSuccess = (data, thunk) => ({
  type: UPDATE_JIRA_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const updateJiraRequestFailure = (e, thunk) => ({
  type: UPDATE_JIRA_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_JIRA = `${NAMESPACE}/CREATE`
export const CREATE_JIRA_REQUEST = `${CREATE_JIRA}/REQUEST`
export const CREATE_JIRA_REQUEST_SUCCESS = `${CREATE_JIRA_REQUEST}_SUCCESS`
export const CREATE_JIRA_REQUEST_FAILURE = `${CREATE_JIRA_REQUEST}_FAILURE`

export const createJiraRequest = ({
  summary,
  description,
  issueTypeId,
  projectId,
  resourceId,
  resourceUrl,
  sessionId,
  customFields,
}) => ({
  type: CREATE_JIRA_REQUEST,
  payload: {
    summary,
    description,
    issueTypeId,
    projectId,
    resourceId,
    resourceUrl,
    sessionId,
    customFields,
  },
  meta: { thunk: CREATE_JIRA_REQUEST },
})

export const createJiraRequestSuccess = (data, thunk) => ({
  type: CREATE_JIRA_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const createJiraRequestFailure = (e, thunk) => ({
  type: CREATE_JIRA_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const REMOVE_JIRA = `${NAMESPACE}/REMOVE`
export const REMOVE_JIRA_REQUEST = `${REMOVE_JIRA}/REQUEST`
export const REMOVE_JIRA_REQUEST_SUCCESS = `${REMOVE_JIRA_REQUEST}_SUCCESS`
export const REMOVE_JIRA_REQUEST_FAILURE = `${REMOVE_JIRA_REQUEST}_FAILURE`

export const removeJiraRequest = () => ({
  type: REMOVE_JIRA_REQUEST,
  payload: {},
  meta: { thunk: REMOVE_JIRA_REQUEST },
})

export const removeJiraRequestSuccess = (data, thunk) => ({
  type: REMOVE_JIRA_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const removeJiraRequestFailure = (e, thunk) => ({
  type: REMOVE_JIRA_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
