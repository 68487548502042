import axios, { post } from 'axios'
import get from 'lodash/get'
import { v1 as uuidv1 } from 'uuid'
import appConfig, { S3_PICTURE_URL } from '../../../config'

const pictureUpload = async file => {
  const mimeType = get(file, 'type')
  const imageType = mimeType.split('/')[1]
  const pictureId = uuidv1()
  const url = appConfig.Lambdas.pictureUpload
    .replace('{email}', pictureId)
    .replace('{imageType}', imageType)

  const response = await axios.get(url)

  const { url: uploadUrl, fields } = response.data

  const formData = new FormData()
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key])
  })
  formData.append('file', file)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  await post(uploadUrl, formData, config)
  return `${S3_PICTURE_URL}/${btoa(pictureId)}.${imageType}`
}

export default pictureUpload
