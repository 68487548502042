import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PERSONAL_ORGANIZATION_ID } from '../../../store/organizations/organizations.selectors'
import useMainAppPage from '../../pages/MainAppPage/useMainAppPage'
import OrganizationsSelect from './OrganizationsSelect'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'
import appConfig from '../../../config'

const getOrganizationButtonSharingVisible = (
  currentOrganizationId,
  showOrganizationButtonSharingVisible,
  isLibraryWorkspaceAndUserIsMember
) => {
  if (!showOrganizationButtonSharingVisible) {
    return false
  }

  if (isLibraryWorkspaceAndUserIsMember) {
    return false
  }

  if (currentOrganizationId === PERSONAL_ORGANIZATION_ID) {
    return false
  }

  return true
}

const getOrganizations = (
  organizations,
  checkLibraryWorkspaceAdmin,
  isCurrentUserLibraryWorkspaceAdmin
) => {
  if (!checkLibraryWorkspaceAdmin) {
    return organizations
  }

  if (checkLibraryWorkspaceAdmin && isCurrentUserLibraryWorkspaceAdmin) {
    return organizations
  }

  return organizations.filter(
    organization => organization.pk !== appConfig.libraryWorkspaceId
  )
}

const OrganizationsSelectContainer = ({
  showNewOrganizationButton,
  showOrganizationButtonSharingVisible,
  onChangeOrganizationInSession,
  shouldNavigateToWorkspace,
  checkLibraryWorkspaceAdmin,
  ...props
}) => {
  const {
    currentOrganization,
    organizations,
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
    setOrganizationModalVisible,
    onSetCurrentOrganization,
    onGoToOrganization,
    goToOrganizationSettings,
  } = useMainAppPage()

  const { isLibraryWorkspaceAndUserIsMember } = useLibraryWorkspace()

  const isOrganizationButtonSharingVisible = getOrganizationButtonSharingVisible(
    currentOrganization?.pk,
    showOrganizationButtonSharingVisible,
    isLibraryWorkspaceAndUserIsMember
  )

  useEffect(() => {
    onChangeOrganizationInSession(currentOrganization.pk)
  }, [currentOrganization?.pk])

  const onSetCurrentOrganizationHandler = organizationId => {
    onSetCurrentOrganization(organizationId)
    if (shouldNavigateToWorkspace) {
      onGoToOrganization(organizationId)
    }
  }

  const { isCurrentUserLibraryWorkspaceAdmin } = useLibraryWorkspace()

  const filteredOrganizations = getOrganizations(
    organizations,
    checkLibraryWorkspaceAdmin,
    isCurrentUserLibraryWorkspaceAdmin
  )

  return (
    <OrganizationsSelect
      {...props}
      currentOrganization={currentOrganization}
      setCreateOrganizationModalVisible={setCreateOrganizationModalVisible}
      onSetCurrentOrganization={onSetCurrentOrganizationHandler}
      organizations={filteredOrganizations}
      isOrganizationButtonSharingVisible={isOrganizationButtonSharingVisible}
      setOrganizationModalVisible={setOrganizationModalVisible}
      isCreateOrganizationModalVisible={isCreateOrganizationModalVisible}
      showNewOrganizationButton={showNewOrganizationButton}
      onSettingsClick={goToOrganizationSettings}
    />
  )
}

OrganizationsSelectContainer.propTypes = {
  onChangeOrganizationInSession: PropTypes.func,
  showNewOrganizationButton: PropTypes.bool,
  showOrganizationButtonSharingVisible: PropTypes.bool,
  shouldNavigateToWorkspace: PropTypes.bool,
  checkLibraryWorkspaceAdmin: PropTypes.bool,
}

OrganizationsSelectContainer.defaultProps = {
  onChangeOrganizationInSession: () => {},
  showNewOrganizationButton: true,
  showOrganizationButtonSharingVisible: true,
  shouldNavigateToWorkspace: true,
  checkLibraryWorkspaceAdmin: false,
}

export default OrganizationsSelectContainer
