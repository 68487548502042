import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, rejected, done } from 'redux-saga-thunk'
import fromUser from '../../../../store/user/user.selectors'
import useUserInfo from '../../../../store/hooks/useUserInfo'
import {
  UPDATE_USER,
  updateUserRequest,
} from '../../../../store/user/user.actions'

const isPending = state => pending(state, UPDATE_USER)
const isComplete = state => done(state, UPDATE_USER)
const isRejected = state => rejected(state, UPDATE_USER)

const useNameSettings = () => {
  const [name, setName] = useState('')
  const { userName, userEmail } = useUserInfo()
  const identityId = useSelector(fromUser.getIdentityId)
  const isRequestLoading = useSelector(isPending)
  const isRequestComplete = useSelector(isComplete)
  const isRequestRejected = useSelector(isRejected)

  const onInput = e => {
    setName(e.target.value)
  }
  const dispatch = useDispatch()
  const updateUser = useCallback(
    userData => {
      dispatch(updateUserRequest(userData))
    },
    [dispatch, updateUserRequest]
  )

  return {
    identityId,
    isRequestComplete,
    isRequestLoading,
    isRequestRejected,
    name,
    onInput,
    setName,
    updateUser,
    userEmail,
    userName,
  }
}

export default useNameSettings
