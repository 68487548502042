/* eslint-disable import/prefer-default-export */
import pipe from 'lodash/fp/pipe'
import uniqBy from 'lodash/fp/uniqBy'
import map from 'lodash/fp/map'
import omit from 'lodash/fp/omit'

export const filterMentions = pipe(
  uniqBy('pk'),
  map(omit(['__typename']))
)
