import { set } from 'dot-prop-immutable'
import { buildReducerForActions } from '../../util/reduxTools'
import { GET_SUGGESTIONS_REQUEST_SUCCESS } from './smartAssistant.actions'

export const STATE_KEY = 'smartAssistant'

export const INITIAL_STATE = {
  suggestions: [],
}

const reducer = {
  [GET_SUGGESTIONS_REQUEST_SUCCESS]: (state, { payload: { suggestions } }) =>
    set(state, 'suggestions', suggestions),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
