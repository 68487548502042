import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { pending } from 'redux-saga-thunk'
import { getRecordingPathName } from '../../../util/paths'
import SessionDestinationSelector from './SessionDestinationSelector'
import fromProjects from '../../../store/projects/projects.selectors'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import { UPDATE_PROJECTS_REQUEST } from '../../../store/projects/projects.actions'
import { GET_ORGANIZATIONS_REQUEST } from '../../../store/organizations/organizations.actions'
import useCRUDProject from '../../../store/hooks/useCRUDProject'
import useCRUDSession from '../../../store/hooks/useCRUDSession'
import messageTypes from '../../../browser-extension/messageTypes'
import browserExtension from '../../../lib/browserExtension'
import useFirstLanding from '../../hooks/useFirstLanding'
import { canUserAddSessionsToProject } from '../../../store/hooks/useCanAddSessions'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { SHARE_TYPES } from '../../molecules/ProjectShareModal/ProjectShare.constants'

const associateRecordingWithSession = (sessionId, history, recording) => {
  browserExtension
    .sendMessageToBackground({
      type: messageTypes.SESSION_CREATED,
      sessionId: sessionId.replace('session:', ''),
      recording: recording,
    })
    .then(() => {
      history.push(getRecordingPathName(sessionId))
    })
}

const getLoadingOrganizations = state =>
  pending(state, GET_ORGANIZATIONS_REQUEST)
const getLoadingProjects = state => pending(state, UPDATE_PROJECTS_REQUEST)

const SessionDestinationSelectorContainer = ({ ...props }) => {
  useFirstLanding({ localStorageKey: 'project-selector-was-redirected' })
  const history = useHistory()

  const { userEmail, userIdentityId } = useUserInfo()

  const currentOrganization = useSelector(
    fromOrganizations.getCurrentOrganization
  )

  const projects = (useSelector(fromProjects.getProjects) || []).filter(
    project =>
      canUserAddSessionsToProject({
        project,
        organization: currentOrganization,
        userIdentityId,
        userEmail,
      })
  )

  const allOrganizationIds = useSelector(
    fromOrganizations.getAllOrganizationsId
  )

  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )
  const currentProject = useSelector(fromProjects.getCurrentProject)
  const initialProjectId = currentProject?.pk

  const isLoadingOrganizations = useSelector(getLoadingOrganizations)
  const isLoadingProjects = useSelector(getLoadingProjects)

  const { onCreateProjectHandler } = useCRUDProject()
  const { onCreateSessionHandler } = useCRUDSession()

  const onSaveHandler = useCallback(
    ({ projectId, projectTitle }) => {
      if (projectId === 'new-project') {
        onCreateProjectHandler(projectTitle, SHARE_TYPES.anyone, []).then(
          newProject => {
            const newProjectId = newProject.data.createProject.pk
            onCreateSessionHandler(newProjectId).then(newSession => {
              const newSessionId = newSession.data.createSession.pk
              associateRecordingWithSession(
                newSessionId,
                history,
                props.recording
              )
            })
          }
        )
        return
      }

      onCreateSessionHandler(projectId).then(newSession => {
        const newSessionId = newSession.data.createSession.pk
        associateRecordingWithSession(newSessionId, history, props.recording)
      })
    },
    [onCreateProjectHandler, onCreateSessionHandler, history]
  )

  return (
    <SessionDestinationSelector
      {...props}
      projects={projects}
      hasOrganizations={allOrganizationIds.length > 0}
      initialProjectId={initialProjectId}
      currentOrganizationId={currentOrganizationId}
      isLoading={isLoadingOrganizations || isLoadingProjects}
      onDelete={() => history.push('/app')}
      onSave={({ organizationId, projectId, projectTitle }) => {
        onSaveHandler({ organizationId, projectId, projectTitle })
      }}
    />
  )
}

export default SessionDestinationSelectorContainer
