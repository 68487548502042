import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google'
import { googleSignIn, isGaPresent, createGaScript } from '../../../util/auth'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const GoogleButton = ({ children, onComplete, ...props }) => {
  useEffect(() => {
    if (!isGaPresent()) {
      createGaScript().catch(console.error)
    }
  }, [])

  const dispatch = useDispatch()
  const onGoogleSignInComplete = useCallback(() => {
    onComplete()
  }, [dispatch])

  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: codeResponse => googleSignIn(codeResponse, onGoogleSignInComplete),
  //   flow: 'auth-code',
  // })

  return (
    <ButtonContainer>
      <GoogleLogin
        onSuccess={credentialResponse => {
          googleSignIn(credentialResponse, onGoogleSignInComplete)
        }}
        onError={() => {
          console.log('Google Login Failed')
        }}
        size="large"
        locale="en"
        width="300px"
      />
    </ButtonContainer>
  )
}
GoogleButton.propTypes = {
  children: PropTypes.node,
  onComplete: PropTypes.func,
}

GoogleButton.defaultProps = {
  children: '',
  onComplete: () => {},
}

export default GoogleButton
