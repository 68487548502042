/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { ternary } from '../../../util/utils'

const Rectangle = styled.div.attrs({
  className: 'itrs-rectangle',
})`
  display: none;
  position: absolute;
  border: 2px solid ${prop('theme.colors.yellow')};
  background-color: rgba(246, 210, 116, 0.4);
  box-sizing: border-box;
  resize: both;
  overflow: hidden;
  visibility: ${ternary('isVisible')('visible', 'hidden')};
  z-index: 1000;

  ::after {
    content: '';
    position: absolute;
    left: calc(100% - 16px);
    transform: rotate(-45deg);
    top: calc(100% - 11px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${prop('theme.colors.yellow')};
    clear: both;
  }

  ::-webkit-resizer {
    display: none;
  }
`

const pxToNum = cssProp => parseInt(cssProp.replace('px', ''), 10)

const getAreaSelectionPoints = (rectangleRef, containerRef) => {
  const containerWidth = containerRef.current.clientWidth
  const containerHeight = containerRef.current.clientHeight

  const topLeftX = pxToNum(rectangleRef.current.style.left)
  const topLeftY = pxToNum(rectangleRef.current.style.top)

  const topLeftPercX = (topLeftX * 100) / containerWidth
  const topLeftPercY = (topLeftY * 100) / containerHeight

  const bottomRightX = topLeftX + pxToNum(rectangleRef.current.style.width)
  const bottomRightY = topLeftY + pxToNum(rectangleRef.current.style.height)

  const bottomRightPercX = (bottomRightX * 100) / containerWidth
  const bottomRightPercY = (bottomRightY * 100) / containerHeight

  const points = {
    topLeft: {
      absolute: {
        x: topLeftX,
        y: topLeftY,
      },
      percentage: {
        x: topLeftPercX,
        y: topLeftPercY,
      },
    },
    bottomRight: {
      absolute: {
        x: bottomRightX,
        y: bottomRightY,
      },
      percentage: {
        x: bottomRightPercX,
        y: bottomRightPercY,
      },
    },
  }
  return points
}

const arePointsValid = point =>
  point.topLeft.percentage.x !== point.bottomRight.percentage.x &&
  point.topLeft.percentage.y !== point.bottomRight.percentage.y

const isInBlackList = (target, mouseDownBlackList) => {
  let isIn = false
  mouseDownBlackList.forEach(blackListDomNode => {
    if (!blackListDomNode?.contains || blackListDomNode.contains(target)) {
      isIn = true
    }
  })
  return isIn
}

const mouseDownListenerGenerator = ({
  rectangleRef,
  containerRef,
  mutableState,
  mouseDownBlackList,
  onMouseDown,
}) => e => {
  if (isInBlackList(e.target, mouseDownBlackList)) {
    return
  }
  if (!containerRef?.current?.contains(e.target)) {
    return
  }
  if (rectangleRef?.current?.contains(e.target)) {
    return
  }
  onMouseDown()
  mutableState.current.wasMouseDown = true
  mutableState.current.hasMoved = false

  const mouseX = e.pageX - containerRef.current.getBoundingClientRect().left
  const mouseY = e.pageY - containerRef.current.getBoundingClientRect().top
  mutableState.current.mouseDownEvent = {
    mouseX,
    mouseY,
  }
}

const mouseMoveListenerGenerator = ({
  rectangleRef,
  containerRef,
  mutableState,
  onDrag,
}) => e => {
  if (!containerRef?.current || !containerRef?.current?.contains(e.target)) {
    return
  }
  if (mutableState.current.wasMouseDown) {
    const mouseX = e.pageX - containerRef.current.getBoundingClientRect().left
    const mouseY = e.pageY - containerRef.current.getBoundingClientRect().top

    const width = mouseX - mutableState.current.mouseDownEvent.mouseX
    const height = mouseY - mutableState.current.mouseDownEvent.mouseY

    if (Math.abs(width) <= 1 && Math.abs(height) <= 1) {
      rectangleRef.current.style.display = 'none'
      return
    }
    mutableState.current.hasMoved = true
    rectangleRef.current.style.display = 'block'
    onDrag()

    if (width < 0) {
      rectangleRef.current.style.left = `${mutableState.current.mouseDownEvent
        .mouseX + width}px`
      rectangleRef.current.style.width = `${-width}px`
    } else {
      rectangleRef.current.style.left = `${mutableState.current.mouseDownEvent.mouseX}px`
      rectangleRef.current.style.width = `${width}px`
    }

    if (height < 0) {
      rectangleRef.current.style.top = `${mutableState.current.mouseDownEvent
        .mouseY + height}px`
      rectangleRef.current.style.height = `${-height}px`
    } else {
      rectangleRef.current.style.top = `${mutableState.current.mouseDownEvent.mouseY}px`
      rectangleRef.current.style.height = `${height}px`
    }
  }
}

const mouseUpListenerGenerator = ({
  rectangleRef,
  mutableState,
  containerRef,
  onAreaSelection,
}) => e => {
  mutableState.current.wasMouseDown = false
  if (!mutableState.current.hasMoved) {
    return
  }
  mutableState.current.hasMoved = false
  mutableState.current.mouseUpEvent = e
  const areaSelectionPoints = getAreaSelectionPoints(rectangleRef, containerRef)
  if (arePointsValid(areaSelectionPoints)) {
    onAreaSelection(areaSelectionPoints)
    return
  }
  onAreaSelection(null)
}

const onResizeObserveGenerator = ({
  rectangleRef,
  mutableState,
  containerRef,
  onAreaSelection,
  onDrag,
}) => () => {
  onDrag()
  if (mutableState.current.hasMoved === false) {
    const areaSelectionPoints = getAreaSelectionPoints(
      rectangleRef,
      containerRef
    )
    if (arePointsValid(areaSelectionPoints)) {
      onAreaSelection(areaSelectionPoints)
      return
    }
    onAreaSelection(null)
  }
}

const AreaSelection = ({
  containerRef,
  onAreaSelection,
  onDrag,
  isVisible,
  mouseDownBlackList,
  onMouseDown,
  ...props
}) => {
  const rectangleRef = useRef(null)
  const mutableState = useRef({})
  useEffect(() => {
    if (!isVisible) {
      rectangleRef.current.style.width = '0'
      rectangleRef.current.style.height = '0'
      rectangleRef.current.style.top = '-10px'
      rectangleRef.current.style.left = '-10px'
    }
  }, [isVisible])
  const mouseDownListener = mouseDownListenerGenerator({
    rectangleRef,
    containerRef,
    mutableState,
    mouseDownBlackList,
    onMouseDown,
  })

  const mouseMoveListener = mouseMoveListenerGenerator({
    rectangleRef,
    containerRef,
    mutableState,
    onDrag,
  })

  const mouseUpListener = mouseUpListenerGenerator({
    rectangleRef,
    mutableState,
    containerRef,
    onAreaSelection,
  })

  const onResizeObserve = onResizeObserveGenerator({
    rectangleRef,
    mutableState,
    containerRef,
    onAreaSelection,
    onDrag,
  })

  let rectangleResizeObserver = null
  useEffect(() => {
    if (containerRef?.current) {
      window.addEventListener('mousedown', mouseDownListener)
      window.addEventListener('mousemove', mouseMoveListener)
      window.addEventListener('mouseup', mouseUpListener)
      rectangleResizeObserver = new ResizeObserver(onResizeObserve)
      rectangleResizeObserver.observe(rectangleRef.current)
    }

    return () => {
      if (containerRef?.current) {
        window.addEventListener('mousedown', mouseDownListener)
        window.addEventListener('mousemove', mouseMoveListener)
        window.addEventListener('mouseup', mouseUpListener)
      }
      if (rectangleResizeObserver && rectangleRef?.current) {
        rectangleResizeObserver.unobserve(rectangleRef.current)
      }
    }
  }, [containerRef?.current, rectangleRef?.current])
  return <Rectangle ref={rectangleRef} isVisible={isVisible} {...props} />
}

AreaSelection.propTypes = {
  containerRef: PropTypes.shape(),
  isVisible: PropTypes.bool,
  onAreaSelection: PropTypes.func,
  onDrag: PropTypes.func,
  mouseDownBlackList: PropTypes.arrayOf(PropTypes.any),
  onMouseDown: PropTypes.func,
}

AreaSelection.defaultProps = {
  containerRef: {},
  isVisible: true,
  onAreaSelection: () => {},
  onDrag: () => {},
  mouseDownBlackList: [],
  onMouseDown: () => {},
}

export default AreaSelection
