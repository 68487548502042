import React from 'react'
import PropTypes from 'prop-types'
import NavigationIcon from 'mdi-react/NavigationIcon'
import NavigationOutlineIcon from 'mdi-react/NavigationOutlineIcon'
import CursorDefaultClickIcon from 'mdi-react/CursorDefaultClickIcon'
import CursorDefaultClickOutlineIcon from 'mdi-react/CursorDefaultClickOutlineIcon'
import AlertCircleIcon from 'mdi-react/AlertCircleIcon'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import AlertIcon from 'mdi-react/AlertIcon'
import AlertOutlineIcon from 'mdi-react/AlertOutlineIcon'
import TextBoxOutlineIcon from 'mdi-react/TextBoxOutlineIcon'
import TextBoxIcon from 'mdi-react/TextBoxIcon'
import LanConnectIcon from 'mdi-react/LanConnectIcon'
import LanDisconnectIcon from 'mdi-react/LanDisconnectIcon'
import NavigationSvg from '../../../assets/navigate.svg'
import Flex from '../../atoms/Flex'
import { PAGE_EVENT_TYPES } from './pageEvents.constants'

const PageEventIcon = ({
  eventType,
  isCurrent,
  isAlternative,
  size,
  ...props
}) => (
  <Flex {...props}>
    {!isCurrent &&
      !isAlternative &&
      eventType === PAGE_EVENT_TYPES.navigation && (
        <NavigationOutlineIcon size={size} />
      )}
    {!isCurrent &&
      isAlternative &&
      eventType === PAGE_EVENT_TYPES.navigation && (
        <img
          src={NavigationSvg}
          style={{ width: `${size}px` }}
          alt="navigate"
        />
      )}
    {isCurrent &&
      !isAlternative &&
      eventType === PAGE_EVENT_TYPES.navigation && (
        <NavigationIcon size={size} />
      )}
    {!isCurrent && eventType === PAGE_EVENT_TYPES.click && (
      <CursorDefaultClickOutlineIcon size={size} />
    )}
    {isCurrent && eventType === PAGE_EVENT_TYPES.click && (
      <CursorDefaultClickIcon size={size} />
    )}
    {!isCurrent &&
      [PAGE_EVENT_TYPES.consoleError, PAGE_EVENT_TYPES.error].includes(
        eventType
      ) && <AlertCircleOutlineIcon size={size} />}
    {isCurrent &&
      [PAGE_EVENT_TYPES.consoleError, PAGE_EVENT_TYPES.error].includes(
        eventType
      ) && <AlertCircleIcon size={size} />}
    {!isCurrent && eventType === PAGE_EVENT_TYPES.consoleWarn && (
      <AlertOutlineIcon size={size} />
    )}
    {isCurrent && eventType === PAGE_EVENT_TYPES.consoleWarn && (
      <AlertIcon size={size} />
    )}
    {!isCurrent && eventType === PAGE_EVENT_TYPES.webRequest && (
      <LanConnectIcon size={size} />
    )}
    {isCurrent && eventType === PAGE_EVENT_TYPES.webRequest && (
      <LanConnectIcon size={size} />
    )}
    {!isCurrent && eventType === PAGE_EVENT_TYPES.webErrorRequest && (
      <LanDisconnectIcon size={size} />
    )}
    {isCurrent && eventType === PAGE_EVENT_TYPES.webErrorRequest && (
      <LanDisconnectIcon size={size} />
    )}
    {!isCurrent &&
      [
        PAGE_EVENT_TYPES.consoleLog,
        PAGE_EVENT_TYPES.consoleDebug,
        PAGE_EVENT_TYPES.consoleInfo,
      ].includes(eventType) && <TextBoxOutlineIcon size={size} />}
    {isCurrent &&
      [
        PAGE_EVENT_TYPES.consoleLog,
        PAGE_EVENT_TYPES.consoleDebug,
        PAGE_EVENT_TYPES.consoleInfo,
      ].includes(eventType) && <TextBoxIcon size={size} />}
  </Flex>
)

PageEventIcon.propTypes = {
  eventType: PropTypes.oneOf(Object.values(PAGE_EVENT_TYPES)).isRequired,
  isCurrent: PropTypes.bool,
  isAlternative: PropTypes.bool,
  size: PropTypes.number,
}

PageEventIcon.defaultProps = {
  isCurrent: false,
  isAlternative: false,
  size: 24,
}

export default PageEventIcon
