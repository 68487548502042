import { useEffect, useState } from 'react'
import messageTypes from '../../browser-extension/messageTypes'
import browserExtension from '../../lib/browserExtension'

const useBrowserExtensionRecording = () => {
  const [isBrowserExtensionRecording, setExtensionRecording] = useState(false)

  const update = () =>
    browserExtension
      .sendMessageToBackground({ type: messageTypes.IS_RECORDING })
      .then(response => {
        setExtensionRecording(!!response?.isRecording)
      })
      .catch(() => setExtensionRecording(false))

  useEffect(() => {
    setInterval(() => update(), 5000)
    update()
  }, [])

  return isBrowserExtensionRecording
}
export default useBrowserExtensionRecording
