import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import sortBy from 'lodash/fp/sortBy'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { STATE_KEY } from './projects.reducers'
import fromOrganizations from '../organizations/organizations.selectors'

const getState = state => getImmutable(state, STATE_KEY)

const getAllProjectsId = createSelector(
  getState,
  get('allIds')
)

const getProjects = createSelector(
  getState,
  fromOrganizations.getCurrentOrganizationId,
  (state, organizationId) =>
    pipe(
      get('byId'),
      filter(x => x.organizationId === organizationId && x.deleted !== true),
      sortBy([el => el.updatedAt]),
      values
    )(state)
)

const getProjectById = projectId =>
  createSelector(
    getState,
    get(`byId.${projectId}`)
  )

const getCurrentProjectId = createSelector(
  getState,
  get('current')
)

const getCurrentProject = createSelector(
  getState,
  getCurrentProjectId,
  (state, projectId) => get(`byId.${projectId}`)(state)
)

export default {
  getAllProjectsId,
  getProjects,
  getCurrentProject,
  getCurrentProjectId,
  getProjectById,
}
