const NAMESPACE = 'SESSION'

export const SET_CURRENT_SESSION = `${NAMESPACE}/SET_CURRENT`
export const setCurrentSession = sessionId => ({
  type: SET_CURRENT_SESSION,
  payload: { sessionId },
})

export const CREATE_NEW_SESSION = `${NAMESPACE}/CREATE_NEW`
export const CREATE_NEW_SESSION_REQUEST = `${NAMESPACE}/CREATE_NEW/REQUEST`
export const CREATE_NEW_SESSION_REQUEST_SUCCESS = `${NAMESPACE}/CREATE_NEW/REQUEST_SUCCESS`
export const CREATE_NEW_SESSION_REQUEST_FAILURE = `${NAMESPACE}/CREATE_NEW/REQUEST_FAILURE`

export const createNewSessionRequest = () => ({
  type: CREATE_NEW_SESSION_REQUEST,
  payload: {},
  meta: { thunk: CREATE_NEW_SESSION_REQUEST },
})

export const createNewSessionRequestSuccess = (payload, thunk) => ({
  type: CREATE_NEW_SESSION_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const createNewSessionRequestFailure = (e, thunk) => ({
  type: CREATE_NEW_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const GET_SESSION = `${NAMESPACE}/GET`
export const GET_SESSION_REQUEST = `${NAMESPACE}/GET/REQUEST`
export const GET_SESSION_REQUEST_SUCCESS = `${NAMESPACE}/GET/REQUEST_SUCCESS`
export const GET_SESSION_REQUEST_FAILURE = `${NAMESPACE}/GET/REQUEST_FAILURE`

export const getSessionRequest = sessionId => ({
  type: GET_SESSION_REQUEST,
  payload: { sessionId },
  meta: { thunk: GET_SESSION_REQUEST },
})

export const getSessionRequestSuccess = (session, thunk) => ({
  type: GET_SESSION_REQUEST_SUCCESS,
  payload: { session },
  meta: { thunk },
})

export const getSessionRequestFailure = (e, thunk) => ({
  type: GET_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_SESSION = `${NAMESPACE}/DELETE`
export const DELETE_SESSION_REQUEST = `${NAMESPACE}/DELETE/REQUEST`
export const DELETE_SESSION_REQUEST_SUCCESS = `${NAMESPACE}/DELETE/REQUEST_SUCCESS`
export const DELETE_SESSION_REQUEST_FAILURE = `${NAMESPACE}/DELETE/REQUEST_FAILURE`

export const deleteSessionRequest = sessionId => ({
  type: DELETE_SESSION_REQUEST,
  payload: { sessionId },
  meta: { thunk: DELETE_SESSION_REQUEST },
})

export const deleteSessionRequestSuccess = (payload, thunk) => ({
  type: DELETE_SESSION_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteSessionRequestFailure = (e, thunk) => ({
  type: DELETE_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_SESSION = `${NAMESPACE}/UPDATE`
export const UPDATE_SESSION_REQUEST = `${NAMESPACE}/UPDATE/REQUEST`
export const UPDATE_SESSION_REQUEST_SUCCESS = `${NAMESPACE}/UPDATE/REQUEST_SUCCESS`
export const UPDATE_SESSION_REQUEST_FAILURE = `${NAMESPACE}/UPDATE/REQUEST_FAILURE`

export const updateSessionRequest = ({ ...session }) => ({
  type: UPDATE_SESSION_REQUEST,
  payload: { ...session },
  meta: { thunk: UPDATE_SESSION_REQUEST },
})

export const updateSessionRequestSuccess = (
  { sessionId, ...session },
  thunk
) => ({
  type: UPDATE_SESSION_REQUEST_SUCCESS,
  payload: { sessionId, ...session },
  meta: { thunk },
})

export const updateSessionRequestFailure = (e, thunk) => ({
  type: UPDATE_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPSERT_SESSION = `${NAMESPACE}/UPSERT_SESSION`
export const upsertSession = session => ({
  type: UPSERT_SESSION,
  payload: { session },
})

export const UPSERT_SESSIONS = `${NAMESPACE}/UPSERT_SESSIONS`
export const upsertSessions = (sessions, projectId) => ({
  type: UPSERT_SESSIONS,
  payload: { sessions, projectId },
})

export const UPDATE_SESSIONS = `${NAMESPACE}/UPDATE_SESSIONS`
export const UPDATE_SESSIONS_REQUEST = `${UPDATE_SESSIONS}/REQUEST`
export const UPDATE_SESSIONS_REQUEST_SUCCESS = `${UPDATE_SESSIONS_REQUEST}_SUCCESS`
export const UPDATE_SESSIONS_REQUEST_FAILURE = `${UPDATE_SESSIONS_REQUEST}_FAILURE`

export const updateSessionsRequest = () => ({
  type: UPDATE_SESSIONS_REQUEST,
  payload: {},
  meta: { thunk: UPDATE_SESSIONS },
})

export const updateSessionsRequestSuccess = (payload, thunk) => ({
  type: UPDATE_SESSIONS_REQUEST_SUCCESS,
  payload: {},
  meta: { thunk },
})

export const updateSessionsRequestFailure = (e, thunk) => ({
  type: UPDATE_SESSIONS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_SESSION_SHARING = `${NAMESPACE}/UPDATE_SESSION_SHARING`
export const UPDATE_SESSION_SHARING_REQUEST = `${UPDATE_SESSION_SHARING}/REQUEST`
export const UPDATE_SESSION_SHARING_REQUEST_SUCCESS = `${UPDATE_SESSION_SHARING}/UPDATE/REQUEST_SUCCESS`
export const UPDATE_SESSION_SHARING_REQUEST_FAILURE = `${UPDATE_SESSION_SHARING}/UPDATE/REQUEST_FAILURE`

export const updateSessionSharingRequest = data => ({
  type: UPDATE_SESSION_SHARING_REQUEST,
  payload: data,
  meta: { thunk: UPDATE_SESSION_SHARING },
})

export const updateSessionSharingRequestSuccess = (data, thunk) => ({
  type: UPDATE_SESSION_SHARING_REQUEST_SUCCESS,
  payload: data,
  meta: { thunk },
})

export const updateSessionSharingRequestFailure = (e, thunk) => ({
  type: UPDATE_SESSION_SHARING_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const MOVE_SESSION = `${NAMESPACE}/MOVE_SESSION`
export const MOVE_SESSION_REQUEST = `${MOVE_SESSION}/REQUEST`
export const MOVE_SESSION_REQUEST_SUCCESS = `${MOVE_SESSION_REQUEST}_SUCCESS`
export const MOVE_SESSION_REQUEST_FAILURE = `${MOVE_SESSION_REQUEST}_FAILURE`

export const moveSessionRequest = ({ sessionId, destinationProjectId }) => ({
  type: MOVE_SESSION_REQUEST,
  payload: { sessionId, destinationProjectId },
  meta: { thunk: MOVE_SESSION },
})

export const moveSessionRequestSuccess = (
  { sessionId, oldProjectId, newProjectId },
  thunk
) => ({
  type: MOVE_SESSION_REQUEST_SUCCESS,
  payload: { sessionId, oldProjectId, newProjectId },
  meta: { thunk },
})

export const moveSessionRequestFailure = (e, thunk) => ({
  type: MOVE_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_SESSION_RESOURCES = `${NAMESPACE}/CREATE_RESOURCES`
export const CREATE_SESSION_RESOURCES_REQUEST = `${CREATE_SESSION_RESOURCES}/REQUEST`
export const CREATE_SESSION_RESOURCES_SUCCESS = `${CREATE_SESSION_RESOURCES}_SUCCESS`
export const CREATE_SESSION_RESOURCES_FAILURE = `${CREATE_SESSION_RESOURCES}_FAILURE`

export const createSessionResourcesRequest = ({
  sessionId,
  comments,
  pageEvents,
  deviceMetaInfo,
}) => ({
  type: CREATE_SESSION_RESOURCES_REQUEST,
  payload: { sessionId, comments, pageEvents, deviceMetaInfo },
  meta: { thunk: CREATE_SESSION_RESOURCES },
})

export const createSessionResourcesRequestSuccess = thunk => ({
  type: CREATE_SESSION_RESOURCES_SUCCESS,
  payload: {},
  meta: { thunk },
})

export const createSessionResourcesRequestFailure = (e, thunk) => ({
  type: CREATE_SESSION_RESOURCES_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
