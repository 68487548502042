import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import take from 'lodash/fp/take'
import sortBy from 'lodash/fp/sortBy'
import find from 'lodash/fp/find'
import values from 'lodash/fp/values'
import reverse from 'lodash/reverse'
import merge from 'lodash/fp/merge'
import { createSelector } from 'reselect'
import { STATE_KEY } from './sessions.reducers'
import fromLocalSessions from '../../desktop/store/localSessions/localSessions.selectors'
import fromProjects from '../projects/projects.selectors'
import fromOrganizations from '../organizations/organizations.selectors'

const getState = state => getImmutable(state, STATE_KEY)

const getCurrentSessionId = createSelector(
  getState,
  get('current')
)

const getSessionsMap = createSelector(
  getState,
  pipe(
    get('byId'),
    values
  )
)

const getProjectsMergedSessions = createSelector(
  fromProjects.getProjects,
  fromOrganizations.getCurrentOrganizationId,
  getSessionsMap,
  (projects, organizationId, sessions) => {
    return sessions.reduce((acc, session) => {
      if (
        !acc.find(
          el => el && session && session.project && el.pk === session.project.pk
        ) &&
        session.organizationId === organizationId
      ) {
        acc.push({ ...session.project, isShared: true })
      }
      return acc
    }, projects)
  }
)

const getCurrentProject = createSelector(
  getProjectsMergedSessions,
  fromProjects.getCurrentProjectId,
  (projects, projectId) => projects.find(project => project.pk === projectId)
)

const getSessions = createSelector(
  getState,
  fromLocalSessions.getLocalSessionsMap,
  (state, localSessionsMap) => {
    const sessionsMap = get('byId')(state)
    const res = pipe(
      merge(localSessionsMap),
      values,
      sortBy([el => el.createdAt]),
      reverse
    )(sessionsMap)

    return res
  }
)

const getSessionById = sessionId =>
  createSelector(
    getSessions,
    sessions => sessions.find(session => session.pk === sessionId) || undefined
  )

const getSessionsByCurrentProject = createSelector(
  getSessions,
  fromProjects.getCurrentProjectId,
  (sessions, projectId) =>
    sessions.filter(session => session.projectId === projectId)
)

const getSessionsByProjectId = projectId =>
  createSelector(
    getSessions,
    sessions => sessions.filter(session => session.projectId === projectId)
  )

const getCurrentSession = createSelector(
  getCurrentSessionId,
  getSessions,
  (sessionId, sessions) => find(x => x.pk === sessionId)(sessions)
)

export const byUpdate = value => {
  return value.updatedAt ? value.updatedAt : value.createdAt
}
const getRecentSessions = (limit = 10) =>
  createSelector(
    getSessions,
    pipe(
      sortBy(byUpdate),
      reverse,
      take(limit)
    )
  )

const getAllSessionsId = createSelector(
  getState,
  get('allIds')
)

const getSessionsGroupedByRemoteTest = createSelector(
  getSessionsByCurrentProject,
  sessions =>
    sessions.reduce((acc, session) => {
      if (session.remoteTestId) {
        if (!acc[session.remoteTestId]) {
          acc[session.remoteTestId] = []
        }
        acc[session.remoteTestId].push(session)
        acc[session.remoteTestId].sort((a, b) => a.updatedAt - b.updatedAt)
      }
      return acc
    }, {})
)

export default {
  getSessions,
  getSessionsMap,
  getSessionById,
  getRecentSessions,
  getSessionsByCurrentProject,
  getSessionsByProjectId,
  getAllSessionsId,
  getCurrentSessionId,
  getCurrentSession,
  getProjectsMergedSessions,
  getCurrentProject,
  getSessionsGroupedByRemoteTest,
}
