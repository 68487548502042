import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Label, Field, Radio, Input } from '@zendeskgarden/react-forms'

const StyledField = styled(Field)`
  margin: 6px 0;
`

const StyledLabel = styled(Label)`
  font-weight: normal;
`

const NEW_PROJECT_DEFAULT_TITLE = 'Untitled Project'

const ProjectCheckbox = ({
  isChecked,
  isInput,
  onClick,
  onInput,
  projectId,
  projectTitle,
}) => {
  const inputRef = useRef(null)
  const onInputClick = useCallback(() => {
    if (inputRef?.current) {
      if (inputRef.current.value === '') {
        inputRef.current.value = NEW_PROJECT_DEFAULT_TITLE
        inputRef.current.select()
        onInput(NEW_PROJECT_DEFAULT_TITLE)
        return NEW_PROJECT_DEFAULT_TITLE
      }
      return inputRef.current.value
    }
    return ''
  }, [inputRef?.current])

  const onRadioChange = event => {
    const _isChecked = event.currentTarget.checked
    if (_isChecked) {
      onClick(isInput ? 'new-project' : projectId)
    }
    if (isInput) {
      const value = onInputClick()
      onInput(value)
    }
  }

  return (
    <StyledField key={projectId}>
      <Radio
        name="project"
        value={projectId}
        checked={isChecked}
        onClick={onRadioChange}
        onChange={onRadioChange}
      >
        {!isInput && <StyledLabel>{projectTitle}</StyledLabel>}
        {isInput && (
          <StyledLabel onClick={() => onClick('new-project')}>
            <Input
              ref={inputRef}
              isBare
              placeholder="New project..."
              onInput={e => onInput(e.currentTarget.value)}
              onClick={() => {
                const value = onInputClick()
                onInput(value)
              }}
            />
          </StyledLabel>
        )}
      </Radio>
    </StyledField>
  )
}

ProjectCheckbox.propTypes = {
  projectId: PropTypes.string,
  projectTitle: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  isInput: PropTypes.bool,
  onInput: PropTypes.func,
}

ProjectCheckbox.defaultProps = {
  projectId: null,
  projectTitle: null,
  isChecked: false,
  isInput: false,
  onInput: () => {},
  onClick: () => {},
}
export default ProjectCheckbox
