import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import concat from 'lodash/concat'
import isNil from 'lodash/isNil'
import difference from 'lodash/difference'
import uniq from 'lodash/uniq'
import pipe from 'lodash/fp/pipe'
import mapFp from 'lodash/fp/map'
import sortByFp from 'lodash/fp/sortBy'
import { readAllRemoteTestRequest } from '../../../store/remoteTests/remoteTests.actions'
import { getOrganizationsRequest } from '../../../store/organizations/organizations.actions'
import { updateProjectsRequest } from '../../../store/projects/projects.actions'
import {
  updateSessionsRequest,
  updateSessionSharingRequest,
} from '../../../store/sessions/sessions.actions'
import fromUsers from '../../../store/users/users.selectors'
import api from '../../../api'
import useUserInfo from '../../../store/hooks/useUserInfo'
import useGetSession from '../../../store/hooks/useGetSession'
import useGetProject from '../../../store/hooks/useGetProject'
import useGetRemoteTest from '../../../store/hooks/useGetRemoteTest'
import {
  getProjectUrl,
  getSessionUrl,
  getRemoteTestUrl,
} from '../../../lib/urls'
import useOrganization from '../../../store/hooks/useOrganization'
import useSessionOrRemoteTestUsers from '../../../store/hooks/useSessionOrRemoteTestUsers'
import useCRUDProject from '../../../store/hooks/useCRUDProject'
import { SHARE_TYPES } from '../ProjectShareModal/ProjectShare.constants'

export const PERMISSIONS = {
  view: 'view',
  edit: 'edit',
  admin: 'admin',
  remove: 'remove',
}

const onShareSubmit = ({
  projectId,
  sessionId,
  remoteTestId,
  email,
  permission,
  inviterIdentityId,
  cb,
}) =>
  api
    .share({
      projectId,
      sessionId,
      remoteTestId,
      permission,
      userEmail: email,
      inviterIdentityId,
    })
    .then(data => {
      if (cb) {
        cb()
      }
      return data
    })
    .catch(err => {
      console.error(err)
      return false
    })

const useShareModal = ({
  sessionId,
  projectId,
  remoteTestId,
  isVisible,
  onClose,
}) => {
  const { isLogged, userEmail, userIdentityId } = useUserInfo()
  const session = useGetSession(sessionId)
  const sessionProject = useGetProject(session?.projectId)
  const sessionRemoteTest = useGetRemoteTest(session?.remoteTestId)
  const project = useGetProject(projectId)
  const remoteTest = useGetRemoteTest(remoteTestId)
  const remoteTestProject = useGetProject(remoteTest?.projectId)
  const { currentOrganization, currentOrganizationUsers } = useOrganization()
  const workspaceInvitedUsers = currentOrganization?.invitedUsers || []
  const isPersonalWorkspace = currentOrganization.pk === 'personal'
  const isProjectOwner = project?.createdBy === userIdentityId
  const isSessionOwner = session?.createdBy === userIdentityId
  const isSessionProjectSharedWithAnyone =
    sessionProject?.shareType === SHARE_TYPES.anyone
  const isSessionProjectOwner = sessionProject?.createdBy === userIdentityId
  const isRemoteTestOwner = remoteTest?.createdBy === userIdentityId

  const resource = project || session || remoteTest
  const title = get(resource, 'title', '')
  const admin = get(resource, 'admin', []) || []
  const edit = get(resource, 'edit', []) || []
  const view = get(resource, 'view', []) || []
  const sharing = get(resource, 'sharing', []) || []

  const sessionUsers = useSessionOrRemoteTestUsers({ sessionId })
  const remoteTestUsers = useSessionOrRemoteTestUsers({ remoteTestId })

  const projectOwnerUser = useSelector(
    fromUsers.getUserById(project?.createdBy || sessionProject?.createdBy)
  )
  const sessionOwnerUser = useSelector(
    fromUsers.getUserById(session?.createdBy)
  )
  const sessionRemoteTestOwnerUser = useSelector(
    fromUsers.getUserById(sessionRemoteTest?.createdBy)
  )
  const remoteTestOwnerUser = useSelector(
    fromUsers.getUserById(remoteTest?.createdBy)
  )

  const sessionSharedUserEmails = sessionId
    ? (session?.admin || [])
        .concat(session?.edit || [])
        .concat(session?.view || [])
    : []

  const remoteTestSharedUserEmails = remoteTest
    ? (remoteTest?.admin || [])
        .concat(remoteTest?.edit || [])
        .concat(remoteTest?.view || [])
    : []

  let projectsSharedUserEmails = []
  if (sessionId) {
    projectsSharedUserEmails = (sessionProject?.admin || [])
      .concat(sessionProject?.edit || [])
      .concat(sessionProject?.view || [])
  }
  if (remoteTestId) {
    projectsSharedUserEmails = (remoteTestProject?.admin || [])
      .concat(remoteTestProject?.edit || [])
      .concat(remoteTestProject?.view || [])
  }

  let uniqProjectSharedUserEmails = []
  if (sessionId) {
    uniqProjectSharedUserEmails = difference(
      projectsSharedUserEmails,
      sessionSharedUserEmails
    )
  }
  if (remoteTestId) {
    uniqProjectSharedUserEmails = difference(
      projectsSharedUserEmails,
      remoteTestSharedUserEmails
    )
  }

  const organizationUserEmails = currentOrganizationUsers.map(
    user => user?.email
  )

  const isPublic = get(sharing, 'isPublic', false)
  const areCommentsAllowed = get(sharing, 'areCommentsAllowed', false)

  const dispatch = useDispatch()

  const dispatchGetOrganizations = useCallback(
    () => dispatch(getOrganizationsRequest()),
    [dispatch]
  )

  const dispatchUpdateProjects = useCallback(
    () => dispatch(updateProjectsRequest()),
    [dispatch]
  )

  const dispatchUpdateSessions = useCallback(
    () => dispatch(updateSessionsRequest()),
    [dispatch]
  )

  const dispatchUpdateRemoteTests = useCallback(
    () =>
      dispatch(
        readAllRemoteTestRequest({ projectId: remoteTestProject?.projectId })
      ),
    [dispatch]
  )

  const updateSessionSharing = useCallback(
    ({ isPublic: _isPublic, areCommentsAllowed: _areCommentsAllowed }) => {
      dispatch(
        updateSessionSharingRequest({
          sessionId,
          isPublic: _isPublic,
          areCommentsAllowed: _areCommentsAllowed,
        })
      )
    },
    [dispatch]
  )

  const resourceUsersEmails = useMemo(
    () => concat(admin || [], edit || [], view || []),
    [admin, edit, view]
  )

  const editorUsersEmails = useMemo(
    () =>
      concat(resourceUsersEmails, uniqProjectSharedUserEmails, [
        sessionOwnerUser?.email,
        projectOwnerUser?.email,
      ]).filter(x => !isNil(x)),
    [
      resourceUsersEmails,
      uniqProjectSharedUserEmails,
      sessionOwnerUser,
      projectOwnerUser,
    ]
  )

  const allUsersEmail = useMemo(
    () =>
      uniq(
        concat(editorUsersEmails, organizationUserEmails).filter(x => !isNil(x))
      ),
    [editorUsersEmails, organizationUserEmails]
  )

  const isEditor = useMemo(() => editorUsersEmails.includes(userEmail), [
    editorUsersEmails,
    userEmail,
  ])

  const usersWithInfo = useSelector(fromUsers.getUsersByEmail(allUsersEmail))
  const enrichedUsers = useMemo(
    () =>
      pipe(
        mapFp(email => {
          const userInfo = usersWithInfo.find(x => x?.email === email) || {}
          const isPendingRegistration = !!workspaceInvitedUsers.find(
            invitedUser => invitedUser.userEmail === email
          )
          const isInheritedFromProject = uniqProjectSharedUserEmails.find(
            _email => _email === email
          )

          const _isSessionOwner = email === sessionOwnerUser?.email
          const _isProjectOwner = email === projectOwnerUser?.email

          const isOwner = _isSessionOwner || _isProjectOwner

          const isInvited =
            !isInheritedFromProject &&
            !isPendingRegistration &&
            resourceUsersEmails.includes(email)

          return {
            ...userInfo,
            email,
            isPending: isPendingRegistration,
            isProjectContributor: !isOwner && isInheritedFromProject,
            isInvited,
            isOwner,
          }
        }),
        sortByFp(['email'])
      )(allUsersEmail),
    [
      allUsersEmail,
      usersWithInfo,
      sessionUsers,
      remoteTestUsers,
      sessionId,
      remoteTestId,
      sessionOwnerUser,
      projectOwnerUser,
      workspaceInvitedUsers,
    ]
  )

  const totalContributors = useMemo(() => {
    return enrichedUsers.filter(
      user => user.isInvited || user.isProjectContributor || user.isOwner
    ).length
  }, [enrichedUsers])

  const isCurrentUserOwner =
    isProjectOwner ||
    isSessionOwner ||
    isRemoteTestOwner ||
    sessionRemoteTestOwnerUser?.email === userEmail

  const isUserContributor =
    isProjectOwner ||
    isSessionOwner ||
    isSessionProjectOwner ||
    isRemoteTestOwner ||
    isEditor

  const [isModalVisible, setModalVisible] = useState(isVisible)
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    setModalVisible(isVisible)
  }, [isVisible])

  const onModalClose = () => {
    setModalVisible(false)
    onClose()
  }

  let shareableLink
  if (projectId) {
    shareableLink = getProjectUrl(projectId)
  }
  if (sessionId) {
    shareableLink = getSessionUrl(sessionId)
  }
  if (remoteTestId) {
    shareableLink = getRemoteTestUrl(remoteTestId)
  }

  const [copyButtonText, setCopyButtonText] = useState('Copy')

  const [isPublicState, setIsPublic] = useState(isPublic)
  const [areCommentsAllowedState, setCommentsAllowed] = useState(
    isPublic && areCommentsAllowed
  )

  const shareCallback = () => {
    if (projectId) {
      return dispatchUpdateProjects().then(() => dispatchGetOrganizations())
    }
    if (sessionId) {
      return dispatchUpdateSessions().then(() => dispatchGetOrganizations())
    }
    if (remoteTestId) {
      return dispatchUpdateRemoteTests().then(() => dispatchGetOrganizations())
    }
    return Promise.resolve()
  }

  const [shareValue, setShareValue] = useState('')
  const [isShareButtonEnabled, setShareButtonEnabled] = useState(false)
  useEffect(() => {
    setShareButtonEnabled(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(shareValue)
    )
  }, [shareValue])

  const onSubmitUser = useCallback(
    (_userEmail, permission) => {
      setIsFormLoading(true)
      return onShareSubmit({
        email: _userEmail,
        permission,
        sessionId,
        projectId,
        remoteTestId,
        inviterIdentityId: userIdentityId,
      }).then(res => {
        return shareCallback().finally(() => {
          setIsFormLoading(false)
          return res
        })
      })
    },
    [sessionId, projectId, remoteTestId, userIdentityId, shareCallback]
  )

  const onAddUser = useCallback(
    _userEmail => {
      if (_userEmail) {
        return onSubmitUser(_userEmail, PERMISSIONS.edit).then(res => {
          const result = get(res, 'data', '')
          const message = get(res, 'data.message', '')
          if (result === 'ok') {
            if (message === 'invitation_email') {
              // TODO: show message to the user, an invitation email has been sent
            }
          }
          return res
        })
      }
      return Promise.reject()
    },
    [onSubmitUser]
  )

  const onRemoveUser = useCallback(
    _userEmail => {
      return onSubmitUser(_userEmail, PERMISSIONS.remove).then(res => {
        // const result = get(res, 'data', '')
        // result === 'ok'
        return res
      })
    },
    [onSubmitUser]
  )

  const projectUsers = (project?.admin || [])
    .concat(project?.edit || [])
    .concat(project?.view || [])
  const sessionsUsers = (session?.admin || [])
    .concat(session?.edit || [])
    .concat(session?.view || [])
  const remoteTestsUsers = (remoteTest?.admin || [])
    .concat(remoteTest?.edit || [])
    .concat(remoteTest?.view || [])
  const invitedUserEmails = [
    ...new Set(
      projectUsers
        .concat(sessionsUsers)
        .concat(remoteTestsUsers)
        .concat(isCurrentUserOwner ? [userEmail] : [])
        .concat(uniqProjectSharedUserEmails)
    ),
  ]

  const { onUpdateProjectShareTypeHandler } = useCRUDProject(projectId)

  return {
    areCommentsAllowedState,
    copyButtonText,
    enrichedUsers,
    isCurrentUserOwner,
    isFormLoading,
    isLogged,
    isModalVisible,
    isPublicState,
    isUserContributor,
    isSessionProjectSharedWithAnyone,
    onModalClose,
    onAddUser,
    onRemoveUser,
    onShareSubmit,
    onUpdateProjectShareTypeHandler,
    session,
    remoteTest,
    setCommentsAllowed,
    setCopyButtonText,
    setIsPublic,
    setShareValue,
    shareableLink,
    shareCallback,
    title,
    updateSessionSharing,
    isShareButtonEnabled,
    invitedUserEmails,
    isPersonalWorkspace,
    projectOwnerUser,
    sessionOwnerUser,
    remoteTestOwnerUser,
    userEmail,
    totalWorkspaceMembers: currentOrganizationUsers.length,
    totalContributors,
    workspaceName: currentOrganization?.title,
    shareType: project?.shareType || SHARE_TYPES.selectedMembers,
  }
}

export default useShareModal
