import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import MentionSuggestion, {
  MentionSuggestionType,
} from '../MentionSuggestion/MentionSuggestion'
import { ternary } from '../../../util/utils'

const stopEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const Wrapper = styled(Flex)``

const hoverStyle = css`
  background-color: ${prop('theme.colors.secondary100')};
  border-color: ${prop('theme.colors.secondary300')};
`

const StyledMentionSuggestion = styled(MentionSuggestion)`
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${prop('theme.colors.grey100')};
  background-color: white;
  cursor: pointer;

  && {
    ${ternary('isSelected')(hoverStyle, '')}
  }

  &&:last-child {
    border-bottom: none;
  }
`

const MentionSuggestionsList = ({ mentions, onSelect, ...props }) => {
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(null)
  useEffect(() => {
    const keyDownHandler = e => {
      if (e.key === 'ArrowUp' && mentions.length > 0) {
        stopEvent(e)
        if (selectedMentionIndex === null) {
          setSelectedMentionIndex(mentions.length - 1)
        } else if (selectedMentionIndex > 0) {
          setSelectedMentionIndex(selectedMentionIndex - 1)
        }
      }
      if (e.key === 'ArrowDown' && mentions.length > 0) {
        stopEvent(e)
        if (selectedMentionIndex === null) {
          setSelectedMentionIndex(0)
        } else if (selectedMentionIndex < mentions.length - 1) {
          setSelectedMentionIndex(selectedMentionIndex + 1)
        }
      }

      if (e.key === 'Enter' && mentions.length > 0) {
        stopEvent(e)
        if (selectedMentionIndex !== null) {
          onSelect(mentions[selectedMentionIndex])
        }
      }
    }
    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [selectedMentionIndex, mentions])

  useEffect(() => {
    setSelectedMentionIndex(null)
  }, [mentions.length])

  return (
    <Wrapper {...props} onMouseOut={() => setSelectedMentionIndex(null)}>
      {mentions.map((mention, index) => {
        const { picture, name, email } = mention
        return (
          <StyledMentionSuggestion
            key={email}
            imageUrl={picture}
            name={name}
            email={email}
            isSelected={index === selectedMentionIndex}
            onMouseOver={() => setSelectedMentionIndex(index)}
            onMouseEnter={() => setSelectedMentionIndex(index)}
            onClick={() => {
              onSelect(mention)
            }}
          />
        )
      })}
    </Wrapper>
  )
}

MentionSuggestionsList.propTypes = {
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  onSelect: PropTypes.func,
}

MentionSuggestionsList.defaultProps = {
  mentions: [],
  onSelect: () => {},
}

export default MentionSuggestionsList
