import React from 'react'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'

export const anchorStyle = css`
  display: inline-flex;
  && {
    color: ${prop('theme.colors.secondary')};
  }

  &&:active {
    color: ${prop('theme.colors.secondary')};
  }

  &&:focus {
    color: ${prop('theme.colors.secondary700')};
  }

  &&:hover {
    color: ${prop('theme.colors.secondary700')};
  }
`

const StyledAnchor = styled.a`
  ${anchorStyle}
`

const Anchor = ({ ...props }) => <StyledAnchor {...props} />

export default Anchor
