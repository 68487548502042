import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { LG } from '@zendeskgarden/react-typography'
import { Button } from '@zendeskgarden/react-buttons'
import CheckIcon from 'mdi-react/CheckIcon'
import Flex from '../../atoms/Flex'
import Card from '../../atoms/Card'

const Wrapper = styled(Card)`
  padding: 16px;
`

const StyledCheckIcon = styled(CheckIcon)`
  fill: ${prop('theme.colors.green')};
  margin-right: 16px;
`

const Title = styled(LG)`
  margin-bottom: 16px;
`

const Explanation = styled(Flex)`
  padding: 4px;
  margin-bottom: 8px;

  > * {
    margin-bottom: 8px;
  }
`

const NewOrganizationExplainer = ({ onCreate, ...props }) => (
  <Wrapper {...props}>
    <Title>Team Workspace</Title>
    <Explanation>
      <Flex direction="row">
        <StyledCheckIcon />
        <Flex>Add additional people to the Workspace</Flex>
      </Flex>
      <Flex direction="row">
        <StyledCheckIcon />
        <Flex>Shared Projects</Flex>
      </Flex>
      <Flex direction="row">
        <StyledCheckIcon />
        <Flex>Comments and replies for all the team</Flex>
      </Flex>
    </Explanation>
    <Button isPrimary onClick={onCreate}>
      Create your Team Workspace
    </Button>
  </Wrapper>
)

NewOrganizationExplainer.propTypes = {
  onCreate: PropTypes.func,
}

NewOrganizationExplainer.defaultProps = {
  onCreate: () => {},
}

export default NewOrganizationExplainer
