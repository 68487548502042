import { useState, useCallback } from 'react'
import gql from 'graphql-tag'
import { getAppSyncClient } from '../../lib/appSyncClient'
import { updateSession as updateSessionGql } from '../../graphql/mutations'

function useUpdateSession(sessionId) {
  const apolloClient = getAppSyncClient()
  const [isSessionUpdating, setSessionUpdating] = useState(false)
  const [updateError, setUpdateError] = useState(null)

  const updateSession = useCallback(data => {
    setUpdateError(null)
    setSessionUpdating(true)

    apolloClient
      .mutate({
        mutation: gql(updateSessionGql),
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            sessionId,
            ...data,
          },
        },
      })
      .then(() => {
        setUpdateError(null)
        setSessionUpdating(false)
      })
      .catch(err => {
        console.error(err)
        setUpdateError(err)
        setSessionUpdating(false)
      })
  })

  return {
    isSessionUpdating,
    updateSession,
    updateError,
  }
}

export default useUpdateSession
