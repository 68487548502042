import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import isNil from 'lodash/isNil'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import LockIcon from 'mdi-react/LockIcon'
import EarthIcon from 'mdi-react/EarthIcon'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  flex-direction: row;
`

const Label = styled(Flex)`
  border-radius: 4px;
  padding: 0 8px;
  color: ${prop('theme.colors.grey800')};
  font-size: 12px;
  line-height: 15px;
  flex-direction: row;
`

const getShareTagBackgroundColor = ({
  isPrivate,
  isShared,
  isPublic,
  theme,
}) => {
  if (isPrivate) {
    return theme.colors.grey200
  }
  if (isShared) {
    return theme.colors.secondary200
  }
  if (isPublic) {
    return theme.colors.yellow200
  }

  return 'transparent'
}
const getShareTagIconColor = ({ isPrivate, isShared, isPublic, theme }) => {
  if (isPrivate) {
    return theme.colors.grey800
  }
  if (isShared) {
    return theme.colors.secondary800
  }
  if (isPublic) {
    return theme.colors.yellow800
  }

  return theme.colors.primary
}
const ShareTag = styled(Flex)`
  border-radius: 100px;
  background-color: ${getShareTagBackgroundColor};
  color: ${getShareTagIconColor};
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  font-size: 11px;
  font-weight: 400;
  flex-direction: row;
  line-height: 12px;
  min-height: 16px;

  svg {
    fill: ${getShareTagIconColor};
  }
`

const Counter = styled(Flex)`
  margin-left: 4px;
`

const DetailsLabel = ({
  counter,
  isPrivate,
  isPublic,
  isShared,
  sharedWithCounter,
  label,
  showTag,
  ...props
}) => (
  <Wrapper {...props}>
    {showTag && (
      <>
        <ShareTag isPrivate={isPrivate} isPublic={isPublic} isShared={isShared}>
          {isPrivate && <LockIcon size={12} />}
          {isPublic && <EarthIcon size={12} />}
          {isShared && <AccountMultipleIcon size={12} />}
          {isShared && <Counter>{sharedWithCounter}</Counter>}
        </ShareTag>
        {!isNil(counter) && (
          <Label data-test="label">
            {counter} {label}
          </Label>
        )}
      </>
    )}
    {!showTag && !isNil(counter) && (
      <Label style={{ paddingLeft: 0 }}>
        {counter} {label}
      </Label>
    )}
  </Wrapper>
)

DetailsLabel.propTypes = {
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isShared: PropTypes.bool,
  sharedWithCounter: PropTypes.number,
  counter: PropTypes.number,
  label: PropTypes.string,
  showTag: PropTypes.bool,
}

DetailsLabel.defaultProps = {
  isPrivate: false,
  isPublic: false,
  isShared: false,
  sharedWithCounter: 0,
  counter: 0,
  label: '',
  showTag: true,
}

export default DetailsLabel
