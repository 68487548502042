import isFunction from 'lodash/isFunction'
import mapValues from 'lodash/mapValues'
import { combineReducers } from 'redux'

export function buildReducerForActions(initialSate, reducersMap) {
  return (state = initialSate, action) => {
    const reducer = reducersMap[action.type]
    return reducer ? reducer(state, action) : state
  }
}

export function combineNestedReducers(map) {
  if (isFunction(map)) return map
  const combined = mapValues(map, r =>
    isFunction(r) ? r : combineNestedReducers(r)
  )
  return combineReducers(combined)
}
