import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import fromProjects from '../../../store/projects/projects.selectors'
import { setCurrentDevice } from '../../../desktop/store/actions'
import fromDevices from '../../../desktop/store/selectors'
import { createNewSessionRequest } from '../../../store/sessions/sessions.actions'

function useDeviceSelectorHook() {
  const devices = useSelector(fromDevices.getDevices)
  const currentDevice = useSelector(fromDevices.getCurrentDevice)

  const isCurrentDevicePresent = currentDevice
    ? !!devices.find(_device => _device.id === currentDevice)
    : false

  let initialDevice = {}
  if (isCurrentDevicePresent) {
    initialDevice = devices.find(_device => _device.id === currentDevice)
  } else if (devices.length > 0) {
    const [_initialDevice] = devices
    initialDevice = _initialDevice
  }

  const [selectedDevice, setSelectedDevice] = useState(initialDevice)
  const selectedDeviceId = get(selectedDevice, 'id', '')

  const dispatch = useDispatch()
  const currentProjectId = useSelector(fromProjects.getCurrentProjectId)
  const onSetCurrentDevice = useCallback(
    deviceId => dispatch(setCurrentDevice(deviceId)),
    [dispatch]
  )

  if (!isCurrentDevicePresent && initialDevice.id !== currentDevice) {
    onSetCurrentDevice(initialDevice.id)
    setSelectedDevice(initialDevice)
  }
  const onCreateNewSession = useCallback(
    () => dispatch(createNewSessionRequest()),
    [dispatch]
  )

  return {
    devices,
    selectedDevice,
    selectedDeviceId,
    setSelectedDevice,
    currentProjectId,
    onSetCurrentDevice,
    onCreateNewSession,
  }
}

export default useDeviceSelectorHook
