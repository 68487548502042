import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input, Message } from '@zendeskgarden/react-forms'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
} from '@zendeskgarden/react-modals'
import useNameSetting from './hooks/useNameSettings'

const NameSettingModal = ({ isVisible, onClose }) => {
  const {
    name,
    userName,
    identityId,
    isRequestLoading,
    isRequestComplete,
    isRequestRejected,
    onInput,
    updateUser,
  } = useNameSetting()

  useEffect(() => {
    if (isRequestComplete) {
      onClose()
    }
  }, [isRequestComplete])

  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])

  return (
    <>
      {isVisible && (
        <Modal isAnimated={false} data-test="name-settings-modal">
          <Header>Modify your name</Header>
          <Body>
            <Field>
              <Input
                ref={inputRef}
                placeholder="Your name"
                autoFocus
                focused
                onInput={onInput}
                defaultValue={userName}
              />
              {isRequestRejected && (
                <Message validation="Error">An error occurred</Message>
              )}
            </Field>
          </Body>
          <Footer>
            <FooterItem>
              <Button isBasic style={{ marginRight: '16px' }} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isPrimary
                disabled={isRequestLoading}
                data-test="save-button"
                onClick={() => {
                  updateUser({ identityId, name })
                }}
              >
                Save
              </Button>
            </FooterItem>
          </Footer>
        </Modal>
      )}
    </>
  )
}

NameSettingModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

NameSettingModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default NameSettingModal
