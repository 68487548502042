import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import get from 'lodash/get'
import { getAppSyncClient } from '../../../lib/appSyncClient'
import { getSessionIdByComment } from '../../../graphql/queries'
import NotFoundMobile from '../NotFoundMobile'
import NotAuthorized from '../NotAuthorized'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import SessionProxy from '../SessionProxy'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { useGoogleLogin } from '@react-oauth/google'
import {
  isGoogleLogin,
  isGoogleSignedIn,
  googleSignIn,
} from '../../../util/auth'

const apolloClient = getAppSyncClient()

const CommentProxy = ({ match }) => {
  const history = useHistory()
  const { isLogged } = useUserInfo()

  const commentId = get(match, 'params.commentId')
  const [isLoading, setLoading] = useState(true)
  const [isSessionFound, setSessionFound] = useState(false)
  const [isUserAuthorized, setUserAuthorized] = useState(false)
  const [sessionId, setSessionId] = useState(null)

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse =>
      googleSignIn(
        codeResponse,
        () => {
          fetchComment()
        },
        () => {
          window.location.href = `/login?redirect=comment:${commentId}`
        }
      ),
    flow: 'auth-code',
  })

  const fetchComment = () =>
    apolloClient
      .query({
        query: gql(getSessionIdByComment),
        fetchPolicy: 'network-only',
        variables: {
          commentId,
        },
      })
      .then(data => {
        const _sessionId = get(data, 'data.getSessionIdByComment')
        setSessionId(_sessionId.replace('session:', ''))
        setSessionFound(true)
        setUserAuthorized(true)
      })
      .catch(err => {
        console.error(err)
        const errorMessage = err.toString().toLowerCase()
        if (errorMessage.includes('not found')) {
          setSessionFound(false)
          setUserAuthorized(true)
        }
        if (errorMessage.includes('not authorized')) {
          setUserAuthorized(false)
          setSessionFound(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })

  useEffect(() => {
    if (isLogged) {
      if (isGoogleLogin()) {
        isGoogleSignedIn().then(isLoggedIn => {
          if (isLoggedIn) {
            fetchComment()
          }

          if (!isLoggedIn) {
            loginWithGoogle()
          }
        })
        return
      }

      fetchComment()
    }
  }, [isLogged])

  if (!isLogged) {
    history.push('/register')
    return null
  }

  if (!commentId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !isSessionFound) {
    return <NotFoundMobile />
  }

  if (!isLoading && !isUserAuthorized) {
    return <NotAuthorized />
  }

  if (!isLoading && sessionId) {
    return (
      <SessionProxy
        match={{ params: { sessionId } }}
        initialCommentId={commentId}
      />
    )
  }

  return <div />
}

CommentProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

export default CommentProxy
