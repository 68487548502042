import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import random from 'lodash/random'
import range from 'lodash/range'
import RefreshIcon from 'mdi-react/RefreshIcon'
import { PropTypes } from 'prop-types'
import prop from 'lodash/fp/prop'
import uniq from 'lodash/uniq'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton/IconButton'
import theme from '../../theme/old'
import WorkspaceLogoPicker from './WorkspaceLogoPickerContainer'
import WorkspaceLogo from '../../atoms/WorkspaceLogo/WorkspaceLogo'

const Wrapper = styled(Flex)`
  flex-direction: row;
  user-select: none;
  justify-content: center;
  align-items: center;
`

const StyledWorkspaceLogoPicker = styled(WorkspaceLogoPicker)`
  margin-right: 24px;
  user-select: none;

  && .circle {
    border-radius: 8px;
  }
`

const StyledWorkspaceLogo = styled(WorkspaceLogo)`
  margin-right: 16px;
`

const StyledIconButton = styled(IconButton)`
  align-self: center;
  cursor: pointer;
  user-select: none;
  border-radius: 10000px;

  && svg {
    fill: ${prop('theme.colors.grey600')};
  }
  &&:hover svg {
    fill: ${prop('theme.colors.grey800')};
  }
`

const numberOfColors = 3

const baseColorVariants = ['300', '400', '500', '600']

const backgroundColors = [
  ...baseColorVariants.map(suffix => theme.colors[`primary${suffix}`]),
  ...baseColorVariants.map(suffix => theme.colors[`secondary${suffix}`]),
  ...baseColorVariants.map(suffix => theme.colors[`green${suffix}`]),
  ...baseColorVariants.map(suffix => theme.colors[`yellow${suffix}`]),
  ...baseColorVariants.map(suffix => theme.colors[`warning${suffix}`]),
]

const getRandomColor = () =>
  backgroundColors[random(0, backgroundColors.length - 1)]

const _getRandomColors = () => range(numberOfColors).map(getRandomColor)

const getRandomColors = () => {
  let randomAvatars = uniq(_getRandomColors())
  while (randomAvatars.length !== numberOfColors) {
    randomAvatars = uniq(_getRandomColors())
  }
  return randomAvatars
}

const WorkspaceLogoSelector = ({
  name,
  onBackgroundColorSelect,
  onPictureChange,
  currentColor,
  initialBackgroundColor,
  initialPictureUrl,
  ...props
}) => {
  const [colors, setColors] = useState(getRandomColors())
  const onClick = useCallback(() => {
    setColors(getRandomColors())
  }, [currentColor])

  const [backgroundColor, setBackgroundColor] = useState(
    initialBackgroundColor || 'secondary'
  )
  const [imageUrl, setImageUrl] = useState(initialPictureUrl)
  const onColorSelect = useCallback(color => {
    setBackgroundColor(color)
    setImageUrl('')
    onBackgroundColorSelect({ backgroundColor: color })
  }, [])

  return (
    <Wrapper {...props}>
      <StyledWorkspaceLogoPicker
        size="small"
        name={name}
        imageUrl={imageUrl}
        backgroundColor={backgroundColor}
        onPictureChange={pictureUrl => {
          onPictureChange(pictureUrl)
          setImageUrl(pictureUrl)
        }}
      />
      {colors.map(color => {
        return (
          <StyledWorkspaceLogo
            key={Math.random()}
            name={name}
            size="medium"
            backgroundColor={color}
            onClick={() => onColorSelect(color)}
          />
        )
      })}
      <StyledIconButton icon={RefreshIcon} isBasic onClick={onClick} />
    </Wrapper>
  )
}

WorkspaceLogoSelector.propTypes = {
  name: PropTypes.string.isRequired,
  currentColor: PropTypes.string,
  initialBackgroundColor: PropTypes.string,
  initialPictureUrl: PropTypes.string,
  onBackgroundColorSelect: PropTypes.func,
  onPictureChange: PropTypes.func,
}

WorkspaceLogoSelector.defaultProps = {
  currentColor: null,
  initialBackgroundColor: '',
  initialPictureUrl: '',
  onBackgroundColorSelect: () => {},
  onPictureChange: () => {},
}

export default WorkspaceLogoSelector
