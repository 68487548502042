import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import prop from 'lodash/fp/prop'
import WorkspaceLogoSelector from '../../molecules/WorkspaceLogoSelector'
import Flex from '../../atoms/Flex'
import WorkspaceNameModal from './WorkspaceNameModal'
import { StyledErrorMessage } from '../../commonStyledComponents'
import WorkspaceEmailDomains from '../../pages/WorkspaceSettingsPage/WorkspaceEmailDomains'

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  min-width: 580px;
  width: 580px;
`
const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid ${prop('theme.colors.grey100')};

  :last-child {
    border: none;
  }
`

const DeleteRow = styled(SettingRow)`
  padding-top: 24px;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
`

const BaseLabel = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 500;
`

const DeleteRowLabel = styled(BaseLabel)`
  align-self: flex-start;
  margin-bottom: 12px;
`

const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const Name = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 300;
`

const Subtitle = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  letter-spacing: 0.05em;
  font-weight: 300;
  font-size: 12px;
  color: ${prop('theme.colors.grey400')};
  margin-top: 4px;
  margin-bottom: 8px;
`
const Edit = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  margin-left: 16px;
`

const Description = styled(Flex)`
  color: ${prop('theme.colors.grey400')};
  align-self: flex-start;
`

const ConfirmTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: flex;
`

const DeleteButton = styled(Button)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Settings = ({
  workspaceName,
  workspacePictureUrl,
  workspaceBackgroundColor,
  onPictureChange,
  onBackgroundColorSelect,
  onWorkspaceTitleUpdate,
  onUpdateWorkspaceEmailDomains,
  onWorkspaceDelete,
  onBack,
  isWorkspaceTitleRequestLoading,
  isWorkspaceTitleRequestRejected,
  availableWorkspaceEmailDomains,
  workspaceEmailDomains,
  ...props
}) => {
  const [isNameModalVisible, setNameModalVisible] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  return (
    <Wrapper {...props}>
      <SettingRow>
        <BaseLabel>Workspace picture</BaseLabel>
        <Edited>
          <WorkspaceLogoSelector
            name={workspaceName}
            onBackgroundColorSelect={onBackgroundColorSelect}
            onPictureChange={onPictureChange}
            currentColor={workspaceBackgroundColor}
            initialPictureUrl={workspacePictureUrl}
            initialBackgroundColor={workspaceBackgroundColor}
          />
        </Edited>
      </SettingRow>
      <SettingRow>
        <BaseLabel>Workspace name</BaseLabel>
        <Edited>
          <Name>{workspaceName}</Name>
          <Edit>
            <Button isBasic onClick={() => setNameModalVisible(true)}>
              Edit
            </Button>
          </Edit>
        </Edited>
      </SettingRow>
      <SettingRow>
        <Flex direction="column" flexGrow={1}>
          <BaseLabel style={{ alignSelf: 'flex-start' }}>
            Allowed email domains
          </BaseLabel>
          <Subtitle>
            Anyone with email addresses at these domains can automatically join
            the workspace
          </Subtitle>
          <WorkspaceEmailDomains
            emailDomainsList={availableWorkspaceEmailDomains}
            selectedEmailDomains={workspaceEmailDomains}
            onUpdateWorkspaceEmailDomains={onUpdateWorkspaceEmailDomains}
          />
        </Flex>
      </SettingRow>
      <DeleteRow>
        <DeleteRowLabel>Delete Workspace</DeleteRowLabel>
        <Description>
          <div>Workspace deletion cannot be undone.</div>
          <div>Please enter the name of the workspace to confirm.</div>
        </Description>
      </DeleteRow>
      <SettingRow>
        <ConfirmTextField>
          <Input
            type="text"
            name="name"
            onChange={e => setConfirmText(e.target.value)}
            /*
              onBlur={handleBlur}
              value={values.email}
              validation={errors.email ? 'warning' : undefined}
              */
            placeholder="Workspace name"
            data-test="delete-workspace-input"
          />
          <StyledErrorMessage name="name" />
        </ConfirmTextField>
        <DeleteButton
          isPrimary
          isDanger
          type="submit"
          /* disabled={isFormLoading} */
          disabled={confirmText !== workspaceName}
          data-test="delete-workspace-button"
          onClick={() => onWorkspaceDelete().then(() => onBack())}
        >
          {/* isFormLoading && <StyledLoader size="16px" /> */}
          {/* !isFormLoading && */ <>Delete</>}
        </DeleteButton>
      </SettingRow>
      {isNameModalVisible && (
        <WorkspaceNameModal
          isVisible={isNameModalVisible}
          onClose={() => setNameModalVisible(false)}
          workspaceTitle={workspaceName}
          onTitleUpdate={onWorkspaceTitleUpdate}
          isRequestLoading={isWorkspaceTitleRequestLoading}
          isRequestRejected={isWorkspaceTitleRequestRejected}
        />
      )}
    </Wrapper>
  )
}

Settings.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  workspacePictureUrl: PropTypes.string,
  workspaceBackgroundColor: PropTypes.string,
  onPictureChange: PropTypes.func.isRequired,
  onBackgroundColorSelect: PropTypes.func.isRequired,
  onWorkspaceTitleUpdate: PropTypes.func.isRequired,
  onUpdateWorkspaceEmailDomains: PropTypes.func.isRequired,
  onWorkspaceDelete: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isWorkspaceTitleRequestLoading: PropTypes.bool.isRequired,
  isWorkspaceTitleRequestRejected: PropTypes.bool.isRequired,
  availableWorkspaceEmailDomains: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  workspaceEmailDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
}

Settings.defaultProps = {
  workspacePictureUrl: '',
  workspaceBackgroundColor: '',
}

export default Settings
