const indexOfAll = (str, value) => {
  const positions = []
  let index = null
  let start = 0
  while (index !== -1) {
    index = str.indexOf(value, start)
    if (index === -1) {
      break
    }

    positions.push(index)
    start = index + 1
  }
  return positions
}

export default indexOfAll
