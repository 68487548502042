import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { useTooltip } from '@zendeskgarden/container-tooltip'
import Flex from '../../atoms/Flex'
import RichEditor from '../../atoms/RichEditor'
import MentionSuggestionsList from '../MentionSuggestionsList'
import { MentionSuggestionType } from '../MentionSuggestion/MentionSuggestion'
import usePopper from '../../hooks/usePopper'
import useRichEditor from './useRichEditor'

const Wrapper = styled(Flex)``

const MentionSuggestionsListContainer = styled(Flex)`
  width: 320px;
  overflow: hidden;
  z-index: 1000;
  ${prop('theme.boxShadows.elevation4')};
`

const RichEditorWithMentions = ({
  mentions,
  onAddedMention,
  onRemovedMention,
  suggestions,
  initialValue,
  placeholder,
  onInput,
  textRef,
  autoFocusToken,
  resetToken,
  ...props
}) => {
  const { isVisible, getTooltipProps, openTooltip, closeTooltip } = useTooltip({
    isVisible: false,
    delayMilliseconds: 0,
  })

  const getCustomTooltipProps = (...args) => {
    const returnValue = getTooltipProps(...args)
    return {
      ...returnValue,
      style: {
        ...returnValue.style,
        height: returnValue.style.opacity === 0 ? 0 : 'auto',
      },
    }
  }

  const onShowSuggestions = () => openTooltip(0)
  const onHideSuggestions = () => closeTooltip(0)

  const {
    currentSuggestions,
    isSuggestionsVisible,
    currentText,
    onMentionSelect,
    onMentionSuggestionsTrigger,
    setCurrentSuggestions,
    setCurrentText,
    setRichEditor,
    setSuggestionsVisible,
    triggerRef,
  } = useRichEditor({
    initialValue,
    mentions,
    onAddedMention,
    onHideSuggestions,
    onRemovedMention,
    onShowSuggestions,
    suggestions,
  })

  const mentionListContainerRef = useRef(null)

  const { style } = usePopper({
    referenceRef: triggerRef,
    popperRef: mentionListContainerRef,
    placement: 'auto',
    positionFixed: true,
  })

  const styles = {
    ...style,
    visibility: isVisible && isSuggestionsVisible ? 'visible' : 'hidden',
    opacity: isVisible && isSuggestionsVisible ? 1 : 0,
    pointerEvents: 'all',
  }

  const onRichEditorInput = useCallback(
    (text, quillEditor) => {
      onInput(quillEditor.getText(0))
      setCurrentText(quillEditor.getText(0))
    },
    [currentText, onInput, setCurrentText]
  )

  return (
    <Wrapper {...props}>
      <RichEditor
        editorConf={{}}
        textRef={textRef}
        placeholder={placeholder}
        initialValue={initialValue}
        resetToken={resetToken}
        autoFocusToken={autoFocusToken}
        mentions={mentions}
        onInput={onRichEditorInput}
        onMount={setRichEditor}
        onMentionSuggestionsTrigger={onMentionSuggestionsTrigger}
        onHideMentionSuggestions={() => {
          setSuggestionsVisible(false)
          setCurrentSuggestions([])
        }}
      />
      <MentionSuggestionsListContainer
        ref={mentionListContainerRef}
        {...getCustomTooltipProps({
          ref: mentionListContainerRef,
          style: styles,
        })}
      >
        {currentSuggestions.length > 0 && (
          <MentionSuggestionsList
            mentions={currentSuggestions}
            onSelect={onMentionSelect}
          />
        )}
      </MentionSuggestionsListContainer>
    </Wrapper>
  )
}

RichEditorWithMentions.propTypes = {
  suggestions: PropTypes.arrayOf(MentionSuggestionType),
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocusToken: PropTypes.string,
  resetToken: PropTypes.string,
  onAddedMention: PropTypes.func,
  onRemovedMention: PropTypes.func,
  onInput: PropTypes.func,
  textRef: PropTypes.shape({ current: PropTypes.element }),
}

RichEditorWithMentions.defaultProps = {
  mentions: [],
  suggestions: [],
  initialValue: '',
  placeholder: '',
  autoFocusToken: '',
  resetToken: '',
  onAddedMention: () => {},
  onRemovedMention: () => {},
  onInput: () => {},
  textRef: null,
}

export default RichEditorWithMentions
