const NAMESPACE = 'PAGE_EVENTS'

export const READ_PAGE_EVENTS = `${NAMESPACE}/READ`
export const READ_PAGE_EVENTS_REQUEST = `${READ_PAGE_EVENTS}/REQUEST`
export const READ_PAGE_EVENTS_REQUEST_SUCCESS = `${READ_PAGE_EVENTS_REQUEST}_SUCCESS`
export const READ_PAGE_EVENTS_REQUEST_FAILURE = `${READ_PAGE_EVENTS_REQUEST}_FAILURE`

export const readPageEventsRequest = sessionId => ({
  type: READ_PAGE_EVENTS_REQUEST,
  payload: { sessionId },
  meta: { thunk: READ_PAGE_EVENTS_REQUEST },
})

export const readPageEventsRequestSuccess = (pageEvents, thunk) => ({
  type: READ_PAGE_EVENTS_REQUEST_SUCCESS,
  payload: { pageEvents },
  meta: { thunk },
})

export const readPageEventsRequestFailure = (e, thunk) => ({
  type: READ_PAGE_EVENTS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_PAGE_EVENTS = `${NAMESPACE}/CREATE`
export const CREATE_PAGE_EVENTS_REQUEST = `${CREATE_PAGE_EVENTS}/REQUEST`
export const CREATE_PAGE_EVENTS_REQUEST_SUCCESS = `${CREATE_PAGE_EVENTS_REQUEST}_SUCCESS`
export const CREATE_PAGE_EVENTS_REQUEST_FAILURE = `${CREATE_PAGE_EVENTS_REQUEST}_FAILURE`

export const createPageEventsRequest = ({ sessionId, events }) => ({
  type: CREATE_PAGE_EVENTS_REQUEST,
  payload: { sessionId, events },
  meta: { thunk: CREATE_PAGE_EVENTS_REQUEST },
})

export const createPageEventsRequestSuccess = ({ result }, thunk) => ({
  type: CREATE_PAGE_EVENTS_REQUEST_SUCCESS,
  payload: { result },
  meta: { thunk },
})

export const createPageEventsRequestFailure = (e, thunk) => ({
  type: CREATE_PAGE_EVENTS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
