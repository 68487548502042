import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import { SHARE_TYPES } from '../../molecules/ProjectShareModal/ProjectShare.constants'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import fromUsers from '../../../store/users/users.selectors'
import Flex from '../../atoms/Flex'
import ProjectCard from '../../molecules/ProjectCard'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { toUserId } from '../../../util/utils'

const Wrapper = styled(Flex)`
  display: ${({ fullHeight }) => (fullHeight ? 'flex' : 'block')};
  flex-grow: 1;
`

const StyledProjectCard = styled(ProjectCard)`
  margin: 0 16px 16px 16px;
`

const ProjectsListMobile = ({
  onProjectClick,
  onCreateProject,
  onShare,
  onDelete,
  ...props
}) => {
  const { userIdentityId } = useUserInfo()
  const currentProjectId = useSelector(fromProjects.getCurrentProjectId)
  const enrichedProjects = useSelector(fromSessions.getProjectsMergedSessions)
  const allUsers = useSelector(fromUsers.getAllUsers)
  const currentOrganizationMembers = useSelector(
    fromOrganizations.getCurrentOrganizationMembers
  )

  const projects = useMemo(
    () =>
      enrichedProjects.map(project => {
        const { admin, edit, view, createdBy } = project

        const ownerUserEmail = allUsers.find(
          user => toUserId(user.pk) === toUserId(createdBy)
        )?.email

        const _admin = admin || []
        const _edit = edit || []
        const _view = view || []
        const sharedWith = uniq(
          compact(_admin.concat(_edit, _view, [ownerUserEmail]))
        )

        const isSharedWithTheWorkspace =
          project?.shareType === SHARE_TYPES.anyone
        const isShared = sharedWith.length > 0 || isSharedWithTheWorkspace
        const isPrivate = !isShared

        const sharedWithCounter = isSharedWithTheWorkspace
          ? currentOrganizationMembers.length
          : sharedWith.length

        return {
          ...project,
          isPrivate,
          isShared,
          sharedWithCounter,
          canDelete: project.createdBy === userIdentityId,
        }
      }),

    [enrichedProjects, currentOrganizationMembers, userIdentityId, allUsers]
  )

  return (
    <Wrapper fullHeight={projects.length === 0} {...props}>
      {projects.length > 0 && (
        <>
          {projects.map(project => (
            <StyledProjectCard
              key={project.pk}
              project={project.pk}
              title={project.title}
              data-test={`project-list-item-${project.title}`}
              counter={project?.uploadedSessions?.length || 0}
              isPrivate={project.isPrivate}
              isShared={project.isShared}
              isActive={project.pk === currentProjectId}
              sharedWithCounter={project.sharedWithCounter}
              onClick={() => onProjectClick(project.pk)}
              hasSettings
              onShare={() => onShare(project)}
              onDelete={() => onDelete(project)}
              showDelete={project.canDelete}
            />
          ))}
        </>
      )}
    </Wrapper>
  )
}

ProjectsListMobile.propTypes = {
  onProjectClick: PropTypes.func,
  onCreateProject: PropTypes.func,
  onShare: PropTypes.func,
  onDelete: PropTypes.func,
}

ProjectsListMobile.defaultProps = {
  onProjectClick: () => {},
  onCreateProject: () => {},
  onShare: () => {},
  onDelete: () => {},
}

ProjectsListMobile.whyDidYouRender = false

export default ProjectsListMobile
