import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import prop from 'lodash/fp/prop'
import CheckIcon from 'mdi-react/CheckIcon'
import Flex from '../../../atoms/Flex'
import TeamPlanSvg from '../../../../assets/onboarding_team_plan.svg'
import PersonalPlanSvg from '../../../../assets/onboarding_personal_plan.svg'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled.h3`
  margin-bottom: 16px;
`

const NextButton = styled(Button)`
  align-self: center;
  && {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 260px;
  }
`

const Tile = styled(Flex)`
  position: relative;
  width: 80%;
  align-self: center;
  border: 2px solid grey;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? `${theme.colors.primary} !important` : theme.colors.grey200};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  cursor: pointer;

  :hover {
    border-color: ${prop('theme.colors.grey300')};
  }
`

const TileIcon = styled(Flex)`
  width: 54.09px;
  height: 68px;

  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`

const TileContent = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 32px;
`

const TileTitle = styled(Flex)`
  align-self: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${prop('primary')};
  margin-bottom: 8px;
`

const TileDescription = styled(Flex)`
  align-self: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${prop('primary400')};
  text-align: left;
`

const Checkbox = styled(Flex)`
  width: 16px;
  height: 16px;
  border-radius: 24px;
  border: 2px solid ${prop('theme.colors.grey200')};
  position: absolute;
  top: 16px;
  right: 16px;
`

const SelectedCheckbox = styled(Checkbox)`
  border: 2px solid ${prop('theme.colors.primary')};
  background-color: ${prop('theme.colors.primary')};
`

const UserRoleMobile = ({ userName, onNext, ...props }) => {
  const [selectedValue, setSelectedValue] = useState('team')

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>How are you planning to use iterspace?</Title>
      </CenteredBox>
      <CenteredBox>
        <Tile
          isSelected={selectedValue === 'team'}
          onClick={() => setSelectedValue('team')}
        >
          {selectedValue !== 'team' && <Checkbox />}
          {selectedValue === 'team' && (
            <SelectedCheckbox>
              <CheckIcon size="16" color="white" />
            </SelectedCheckbox>
          )}
          <TileIcon>
            <img src={TeamPlanSvg} alt="team" />
          </TileIcon>
          <TileContent>
            <TileTitle>With my team</TileTitle>
            <TileDescription>
              Create a Team Workspace where you can invite specific people and
              manage permissions.
            </TileDescription>
          </TileContent>
        </Tile>
        <Tile
          isSelected={selectedValue === 'personal'}
          onClick={() => setSelectedValue('personal')}
        >
          {selectedValue !== 'personal' && <Checkbox />}
          {selectedValue === 'personal' && (
            <SelectedCheckbox>
              <CheckIcon size="16" color="white" />
            </SelectedCheckbox>
          )}
          <TileIcon>
            <img src={PersonalPlanSvg} alt="personal" />
          </TileIcon>
          <TileContent>
            <TileTitle>Personal use</TileTitle>
            <TileDescription>
              Start from a Personal Workspace where recordings can only be
              private or public.
            </TileDescription>
          </TileContent>
        </Tile>
      </CenteredBox>
      <CenteredBox style={{ minHeight: 'fit-content' }}>
        <NextButton
          isPrimary
          type="submit"
          disabled={!selectedValue}
          onClick={() => onNext(selectedValue)}
        >
          Continue
        </NextButton>
      </CenteredBox>
    </Wrapper>
  )
}

UserRoleMobile.propTypes = {
  userName: PropTypes.string,
  onNext: PropTypes.func,
}

UserRoleMobile.defaultProps = {
  userName: '',
  onNext: () => {},
}

export default UserRoleMobile
