import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { XL } from '@zendeskgarden/react-typography'

const StyledTitle = styled(XL)`
  padding: 16px 8px;
  letter-spacing: 0.03em;
  font-weight: 400;
  color: ${prop('theme.colors.grey600')};
`

const SectionTitleMobile = ({ children, ...props }) => (
  <StyledTitle {...props}>{children}</StyledTitle>
)

SectionTitleMobile.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionTitleMobile
