export const isShareApiAvailable = () => !!navigator.share

export const share = ({ title, text, url }) => {
  if (isShareApiAvailable()) {
    const shareConfig = {
      title,
      url,
    }

    if (text) {
      shareConfig.text = text
    }
    return navigator.share(shareConfig)
  }

  return Promise.reject(Error('share api not supported'))
}
