import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input, Label } from '@zendeskgarden/react-forms'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from '@zendeskgarden/react-modals'
import MobileModal from '../MobileModal'
import { isMobile } from '../../../lib/platform'
import ProjectShareTypeSelection from '../ProjectShareModal/ProjectShareTypeSelection'
import { SHARE_TYPES } from '../ProjectShareModal/ProjectShare.constants'
import { StyledErrorMessage } from '../../commonStyledComponents'

const StyledProjectShareTypeSelection = styled(ProjectShareTypeSelection)`
  padding: 16px 0;
`

const CreateProjectModal = ({
  isVisible,
  onClose,
  onTitleInput,
  onSubmit,
  isLoading,
  enrichedUsers,
  isInviteButtonDisabled,
  isPersonalWorkspace,
  onAddUser,
  onRemoveUser,
  onShareTypeChange,
  setEmailShareValue,
  showUserBox,
  totalContributors,
  totalWorkspaceMembers,
  workspaceName,
  isTitleErrorVisible,
}) => {
  const [currentShareType, setCurrentShareType] = useState(SHARE_TYPES.anyone)
  useEffect(() => {
    onShareTypeChange(currentShareType)
  }, [currentShareType])

  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])
  const ModalComponent = isMobile() ? MobileModal : Modal

  return (
    <div>
      {isVisible && (
        <ModalComponent onClose={onClose} isAnimated={false}>
          <Header>Create a project</Header>
          <Body>
            <Field>
              <Label>Title</Label>
              <Input
                ref={inputRef}
                placeholder="i.e. Website Review, New Feature QA, Inspiration..."
                onInput={onTitleInput}
                data-test="create-project-input"
              />
              {isTitleErrorVisible && (
                <StyledErrorMessage>This field is required</StyledErrorMessage>
              )}
            </Field>
            {!isPersonalWorkspace && (
              <StyledProjectShareTypeSelection
                currentShareType={currentShareType}
                enrichedUsers={enrichedUsers}
                isInviteButtonDisabled={isInviteButtonDisabled}
                onAddUser={onAddUser}
                onRemoveUser={onRemoveUser}
                setCurrentShareType={setCurrentShareType}
                setEmailShareValue={setEmailShareValue}
                showUserBox={showUserBox}
                totalContributors={totalContributors}
                totalWorkspaceMembers={totalWorkspaceMembers}
                workspaceName={workspaceName}
                isCreation
              />
            )}
          </Body>
          <Footer>
            <FooterItem>
              <Button onClick={onClose} isBasic>
                Cancel
              </Button>
            </FooterItem>
            <FooterItem>
              <Button
                isPrimary
                disabled={isLoading}
                onClick={onSubmit}
                data-test="new-project-modal-create-button"
              >
                Create
              </Button>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" onClick={onClose} />
        </ModalComponent>
      )}
    </div>
  )
}

CreateProjectModal.propTypes = {
  enrichedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  isInviteButtonDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPersonalWorkspace: PropTypes.bool,
  isTitleErrorVisible: PropTypes.bool,
  isVisible: PropTypes.bool,
  onAddUser: PropTypes.func,
  onClose: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onShareTypeChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onTitleInput: PropTypes.func,
  setEmailShareValue: PropTypes.func,
  showUserBox: PropTypes.bool,
  totalContributors: PropTypes.number,
  totalWorkspaceMembers: PropTypes.number,
  workspaceName: PropTypes.string,
}

CreateProjectModal.defaultProps = {
  enrichedUsers: [],
  isInviteButtonDisabled: true,
  isLoading: false,
  isPersonalWorkspace: true,
  isTitleErrorVisible: false,
  isVisible: false,
  onAddUser: () => {},
  onClose: () => {},
  onRemoveUser: () => {},
  onShareTypeChange: () => {},
  onSubmit: () => {},
  onTitleInput: () => {},
  setEmailShareValue: () => {},
  showUserBox: false,
  totalContributors: 0,
  totalWorkspaceMembers: 0,
  workspaceName: '',
}

export default CreateProjectModal
