/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = `mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    pk
    sk
  }
}
`;
export const updateOrganization = `mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    pk
    sk
  }
}
`;
export const updateOrganizationEmailDomains = `mutation UpdateOrganizationEmailDomains(
  $input: UpdateOrganizationEmailDomainsInput!
) {
  updateOrganizationEmailDomains(input: $input) {
    pk
    sk
  }
}
`;
export const deleteOrganization = `mutation DeleteOrganization($organizationId: String!) {
  deleteOrganization(organizationId: $organizationId) {
    pk
    sk
  }
}
`;
export const createRelation = `mutation CreateRelation($input: CreateRelationInput!) {
  createRelation(input: $input) {
    pk
    sk
  }
}
`;
export const createProject = `mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    pk
    sk
  }
}
`;
export const updateProject = `mutation UpdateProject($input: ProjectUpdateInput!) {
  updateProject(input: $input) {
    pk
    sk
  }
}
`;
export const deleteProject = `mutation DeleteProject($projectId: String!) {
  deleteProject(projectId: $projectId) {
    pk
    sk
  }
}
`;
export const createSession = `mutation CreateSession($input: CreateSessionInput!) {
  createSession(input: $input) {
    pk
    sk
  }
}
`;
export const updateSession = `mutation UpdateSession($input: SessionUpdateInput!) {
  updateSession(input: $input) {
    pk
    sk
  }
}
`;
export const deleteSession = `mutation DeleteSession($sessionId: String!) {
  deleteSession(sessionId: $sessionId) {
    pk
    sk
  }
}
`;
export const updateSessionSharing = `mutation UpdateSessionSharing(
  $sessionId: String!
  $isPublic: Boolean!
  $areCommentsAllowed: Boolean!
) {
  updateSessionSharing(
    sessionId: $sessionId
    isPublic: $isPublic
    areCommentsAllowed: $areCommentsAllowed
  ) {
    pk
    sk
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    pk
    sk
  }
}
`;
export const updateComment = `mutation UpdateComment($input: CommentUpdateInput!) {
  updateComment(input: $input) {
    pk
    sk
  }
}
`;
export const deleteComment = `mutation DeleteComment($commentId: String!) {
  deleteComment(commentId: $commentId) {
    pk
    sk
  }
}
`;
export const createCommentResolution = `mutation CreateCommentResolution($input: CommentResolution) {
  createCommentResolution(input: $input) {
    pk
    sessionId
    text
    createdAt
    createdBy
    updatedAt
    updatedBy
    videoPositionSecs
    videoPoint {
      percentage {
        x
        y
      }
      absolute {
        x
        y
      }
    }
    videoRectangle {
      topLeft {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
      bottomRight {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
    }
    owner {
      pk
      name
      picture
      email
      organizations {
        organizationId
        role
      }
    }
    replies {
      id
      text
      createdAt
      createdBy
      updatedAt
      updatedBy
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      mentions {
        pk
        name
        email
      }
    }
    mentions {
      pk
      name
      email
    }
    isResolved
    resolutions {
      createdAt
      createdBy
      status
    }
  }
}
`;
export const createReply = `mutation CreateReply($input: CreateReplyInput!) {
  createReply(input: $input) {
    pk
    sk
  }
}
`;
export const updateReply = `mutation UpdateReply($input: UpdateReplyInput!) {
  updateReply(input: $input) {
    pk
    sk
  }
}
`;
export const deleteReply = `mutation DeleteReply($input: DeleteReplyInput!) {
  deleteReply(input: $input) {
    pk
    sk
  }
}
`;
export const createUser = `mutation CreateUser(
  $email: String!
  $picture: String
  $name: String
  $role: String
  $plan: String
  $workspaceName: String
  $workspacePictureUrl: String
  $workspaceBackgroundColor: String
  $workspaceShareByEmailDomain: Boolean
  $workspaceMembers: [OnboardingWorkspaceInvitedMembers]
  $isGoogle: Boolean
  $userRegistrationData: UserRegistrationDataInput
) {
  createUser(
    email: $email
    picture: $picture
    name: $name
    role: $role
    plan: $plan
    workspaceName: $workspaceName
    workspacePictureUrl: $workspacePictureUrl
    workspaceBackgroundColor: $workspaceBackgroundColor
    workspaceShareByEmailDomain: $workspaceShareByEmailDomain
    workspaceMembers: $workspaceMembers
    isGoogle: $isGoogle
    userRegistrationData: $userRegistrationData
  ) {
    pk
    sk
  }
}
`;
export const updateUser = `mutation UpdateUser(
  $identityId: String!
  $picture: String
  $name: String
  $email: String
  $role: String
  $notifications: UserNotification
) {
  updateUser(
    identityId: $identityId
    picture: $picture
    name: $name
    email: $email
    role: $role
    notifications: $notifications
  ) {
    pk
    sk
  }
}
`;
export const afterLogin = `mutation AfterLogin($email: String!) {
  afterLogin(email: $email) {
    role
    name
    pk
    waitingForIdentityUpdate
    email
    picture
  }
}
`;
export const moveSession = `mutation MoveSession($sessionId: String!, $destinationProjectId: String!) {
  moveSession(
    sessionId: $sessionId
    destinationProjectId: $destinationProjectId
  ) {
    sessionId
    oldProjectId
    newProjectId
  }
}
`;
export const createRemoteTest = `mutation CreateRemoteTest(
  $projectId: String!
  $title: String!
  $sections: [RemoteTestSectionInput]
) {
  createRemoteTest(projectId: $projectId, title: $title, sections: $sections) {
    pk
    sk
  }
}
`;
export const updateRemoteTest = `mutation UpdateRemoteTest(
  $remoteTestId: String!
  $title: String!
  $sections: [RemoteTestSectionInput]
) {
  updateRemoteTest(
    remoteTestId: $remoteTestId
    title: $title
    sections: $sections
  ) {
    pk
    sk
  }
}
`;
export const deleteRemoteTest = `mutation DeleteRemoteTest($remoteTestId: String!) {
  deleteRemoteTest(remoteTestId: $remoteTestId) {
    pk
    sk
  }
}
`;
export const deleteRemoteTestSession = `mutation DeleteRemoteTestSession($sessionId: String!) {
  deleteRemoteTestSession(sessionId: $sessionId) {
    pk
    sk
  }
}
`;
export const createJiraIssue = `mutation CreateJiraIssue(
  $issueTypeId: String
  $projectId: String
  $description: String
  $summary: String
  $resourceId: String
  $resourceUrl: String
  $sessionId: String
  $customFields: [JiraCustomField]
) {
  createJiraIssue(
    issueTypeId: $issueTypeId
    projectId: $projectId
    description: $description
    summary: $summary
    resourceId: $resourceId
    resourceUrl: $resourceUrl
    sessionId: $sessionId
    customFields: $customFields
  ) {
    id
    key
    url
  }
}
`;
export const deleteJira = `mutation DeleteJira {
  deleteJira {
    pk
    sk
  }
}
`;
export const createTrelloIssue = `mutation CreateTrelloIssue(
  $boardId: String
  $listId: String
  $title: String
  $description: String
  $sourceUrl: String
  $sessionId: String
) {
  createTrelloIssue(
    boardId: $boardId
    listId: $listId
    title: $title
    description: $description
    sourceUrl: $sourceUrl
    sessionId: $sessionId
  ) {
    id
    url
  }
}
`;
export const deleteTrello = `mutation DeleteTrello {
  deleteTrello {
    pk
    sk
  }
}
`;
export const postSlackMessage = `mutation PostSlackMessage(
  $slackChannelId: String
  $slackUserId: String
  $sessionId: String
  $commentId: String
  $replyId: String
) {
  postSlackMessage(
    slackChannelId: $slackChannelId
    slackUserId: $slackUserId
    sessionId: $sessionId
    commentId: $commentId
    replyId: $replyId
  ) {
    result
  }
}
`;
export const deleteSlack = `mutation DeleteSlack {
  deleteSlack {
    pk
    sk
  }
}
`;
export const createPageEvent = `mutation CreatePageEvent($sessionId: String, $events: String) {
  createPageEvent(sessionId: $sessionId, events: $events) {
    pk
    sk
  }
}
`;
export const setNotificationsRead = `mutation SetNotificationsRead($notifications: [NotificationKey]) {
  setNotificationsRead(notifications: $notifications) {
    notificationIds
  }
}
`;
