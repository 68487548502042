import { useHistory } from 'react-router-dom'
import useUploads from '../../components/hooks/useUploads'

function eventListenerCallback(e) {
  e.preventDefault()
  e.returnValue = ''
}

function preventBack(block, isUploading) {
  return block(() => {
    if (isUploading) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      alert('You have pending uploads, please wait them to finish')
      return false
    }
    return true
  })
}

const useEventsListener = () => {
  const { resetUploads, isSomethingUploading } = useUploads()
  const { block } = useHistory()

  const addOnPopStateEventListener = () => {
    window.addEventListener(
      'popstate',
      preventBack(block, isSomethingUploading())
    )
  }

  const removeOnPopStateEventListener = () => {
    window.removeEventListener(
      'popstate',
      preventBack(block, isSomethingUploading())
    )
  }

  const addBeforeUnloadEvent = () => {
    window.addEventListener('beforeunload', eventListenerCallback)
  }

  const removeBeforeUnloadEvent = () => {
    window.removeEventListener('beforeunload', eventListenerCallback)
    resetUploads()
  }

  const addOnLoadEventListener = () => {
    window.addEventListener('load', () => {
      resetUploads()
      removeBeforeUnloadEvent()
    })
  }

  return {
    removeBeforeUnloadEvent,
    addBeforeUnloadEvent,
    addOnLoadEventListener,
    addOnPopStateEventListener,
    removeOnPopStateEventListener,
  }
}

export default useEventsListener
