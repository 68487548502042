/* eslint-disable import/prefer-default-export */
export const sortOrganizations = topOrganizationIds => organizations => {
  const topOrganizations = organizations.filter(org =>
    topOrganizationIds.includes(org.pk)
  )
  const othersOrganizations = organizations.filter(
    org => !topOrganizationIds.includes(org.pk)
  )

  othersOrganizations.sort((a, b) => {
    if (a?.title === b?.title) return 0
    if (a?.title?.toLowerCase?.() < b?.title?.toLowerCase?.()) return -1
    if (a?.title?.toLowerCase?.() > b?.title?.toLowerCase?.()) return 1
    return 0
  })

  return topOrganizations.concat(othersOrganizations)
}
