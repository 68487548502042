const NAMESPACE = 'INTEGRATIONS/SLACK'

export const GET_SLACK = `${NAMESPACE}/GET`
export const GET_SLACK_REQUEST = `${GET_SLACK}/REQUEST`
export const GET_SLACK_REQUEST_SUCCESS = `${GET_SLACK_REQUEST}_SUCCESS`
export const GET_SLACK_REQUEST_FAILURE = `${GET_SLACK_REQUEST}_FAILURE`

export const getSlackRequest = () => ({
  type: GET_SLACK_REQUEST,
  payload: {},
  meta: { thunk: GET_SLACK_REQUEST },
})

export const getSlackRequestSuccess = (data, thunk) => ({
  type: GET_SLACK_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const getSlackRequestFailure = (e, thunk) => ({
  type: GET_SLACK_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_SLACK = `${NAMESPACE}/UPDATE`
export const UPDATE_SLACK_REQUEST = `${UPDATE_SLACK}/REQUEST`
export const UPDATE_SLACK_REQUEST_SUCCESS = `${UPDATE_SLACK_REQUEST}_SUCCESS`
export const UPDATE_SLACK_REQUEST_FAILURE = `${UPDATE_SLACK_REQUEST}_FAILURE`

export const updateSlackRequest = () => ({
  type: UPDATE_SLACK_REQUEST,
  payload: {},
  meta: { thunk: UPDATE_SLACK_REQUEST },
})

export const updateSlackRequestSuccess = (data, thunk) => ({
  type: UPDATE_SLACK_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const updateSlackRequestFailure = (e, thunk) => ({
  type: UPDATE_SLACK_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const POST_SLACK_MESSAGE = `${NAMESPACE}/POST/MESSAGE`
export const POST_SLACK_MESSAGE_REQUEST = `${POST_SLACK_MESSAGE}/REQUEST`
export const POST_SLACK_MESSAGE_REQUEST_SUCCESS = `${POST_SLACK_MESSAGE_REQUEST}_SUCCESS`
export const POST_SLACK_MESSAGE_REQUEST_FAILURE = `${POST_SLACK_MESSAGE_REQUEST}_FAILURE`

export const postSlackMessageRequest = ({
  sessionId,
  commentId,
  replyId,
  channelId,
  userId,
}) => ({
  type: POST_SLACK_MESSAGE_REQUEST,
  payload: {
    sessionId,
    commentId,
    replyId,
    channelId,
    userId,
  },
  meta: { thunk: POST_SLACK_MESSAGE_REQUEST },
})

export const postSlackMessageRequestSuccess = (data, thunk) => ({
  type: POST_SLACK_MESSAGE_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const postSlackMessageRequestFailure = (e, thunk) => ({
  type: POST_SLACK_MESSAGE_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const REMOVE_SLACK = `${NAMESPACE}/REMOVE`
export const REMOVE_SLACK_REQUEST = `${REMOVE_SLACK}/REQUEST`
export const REMOVE_SLACK_REQUEST_SUCCESS = `${REMOVE_SLACK_REQUEST}_SUCCESS`
export const REMOVE_SLACK_REQUEST_FAILURE = `${REMOVE_SLACK_REQUEST}_FAILURE`

export const removeSlackRequest = () => ({
  type: REMOVE_SLACK_REQUEST,
  payload: {},
  meta: { thunk: REMOVE_SLACK_REQUEST },
})

export const removeSlackRequestSuccess = (data, thunk) => ({
  type: REMOVE_SLACK_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const removeSlackRequestFailure = (e, thunk) => ({
  type: REMOVE_SLACK_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
