import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../../atoms/Flex'
import AvatarPicker from '../../../atoms/AvatarPicker'
import AvatarSelector from '../../../molecules/AvatarSelector/AvatarSelector'
import { addSearchParameter } from '../../../../util/utils'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled.h3``

const StyledForm = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const StyledAvatarPicker = styled(AvatarPicker)`
  margin-bottom: 24px;
`

const NextButton = styled(Button)`
  && {
    margin-top: 24px;
    width: 352px;
  }
`

const defaultAvatarColor = 'yellow'
const AvatarMobile = ({ userName, pictureUrl, onNext, ...props }) => {
  const [avatarName] = useState(userName)
  const [avatarPictureUrl, setAvatarPictureUrl] = useState(pictureUrl)
  const [avatarBackgroundColor, setAvatarBackgroundColor] = useState(
    defaultAvatarColor
  )

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>
          Create a profile to personalize how you&apos;ll appear to
          collaborators
        </Title>
      </CenteredBox>
      <CenteredBox direction="row">
        <StyledForm>
          <StyledAvatarPicker
            data-test="avatar-picker"
            name={avatarName}
            imageUrl={avatarPictureUrl}
            backgroundColor={avatarBackgroundColor}
            onPictureChange={url => {
              setAvatarPictureUrl(addSearchParameter(url, 'r', Math.random()))
            }}
          />
          <AvatarSelector
            name={userName}
            currentColor={avatarBackgroundColor}
            onSelect={avatarSelection => {
              if (avatarSelection?.avatarUrl) {
                setAvatarPictureUrl(avatarSelection?.avatarUrl)
                return
              }
              if (avatarSelection?.avatarColor) {
                setAvatarPictureUrl('')
                setAvatarBackgroundColor(avatarSelection?.avatarColor)
              }
            }}
          />
          <NextButton
            isPrimary
            type="submit"
            data-test="avatar-selection-next-button"
            onClick={() => {
              onNext({ avatarPictureUrl, avatarBackgroundColor })
            }}
          >
            Continue
          </NextButton>
        </StyledForm>
      </CenteredBox>
    </Wrapper>
  )
}

AvatarMobile.propTypes = {
  userName: PropTypes.string,
  pictureUrl: PropTypes.string,
  onNext: PropTypes.func,
}

AvatarMobile.defaultProps = {
  userName: '',
  pictureUrl: '',
  onNext: () => {},
}

export default AvatarMobile
