import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fromSessions from '../../store/sessions/sessions.selectors'
import fromProjects from '../../store/projects/projects.selectors'
import fromOrganizations, {
  PERSONAL_ORGANIZATION_ID,
} from '../../store/organizations/organizations.selectors'
import { setCurrentOrganization } from '../../store/organizations/organizations.actions'

export const INITIAL_WORKSPACE_ID_LOCAL_STORAGE_KEY = 'initial-workspace-id'

const useFirstLanding = ({ localStorageKey = 'was-redirected' }) => {
  const dispatch = useDispatch()
  const projects = useSelector(fromSessions.getProjectsMergedSessions)
  const organizationsId = useSelector(fromOrganizations.getAllOrganizationsId)
  const currentProjectId = useSelector(fromProjects.getCurrentProjectId)
  const sessions = useSelector(
    fromSessions.getSessionsByProjectId(currentProjectId)
  )
  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )

  const wasRedirected = localStorage.getItem(localStorageKey)
  const initialWorkspaceId = localStorage.getItem(
    INITIAL_WORKSPACE_ID_LOCAL_STORAGE_KEY
  )
  useEffect(() => {
    if (initialWorkspaceId && organizationsId.includes(initialWorkspaceId)) {
      dispatch(setCurrentOrganization(initialWorkspaceId))
      localStorage.removeItem(INITIAL_WORKSPACE_ID_LOCAL_STORAGE_KEY)
      return
    }

    if (
      organizationsId.length === 1 &&
      sessions.length === 0 &&
      projects.length === 0 &&
      currentOrganizationId === PERSONAL_ORGANIZATION_ID &&
      !wasRedirected
    ) {
      localStorage.setItem(localStorageKey, true)
      dispatch(setCurrentOrganization(organizationsId[0]))
    }
  }, [organizationsId, projects, sessions, wasRedirected])
}

export default useFirstLanding
