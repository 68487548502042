import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Tiles } from '@zendeskgarden/react-forms'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import Flex from '../../../atoms/Flex'
import { USER_ROLES } from './UserRole'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled.h3`
  margin-bottom: 16px;
`

const NextButton = styled(Button)`
  align-self: center;
  && {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 260px;
  }
`

const StyledTile = styled(Tiles.Tile)`
  padding: 16px;

  @media only screen and (max-width: 580px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
  }
`

const StyledIcon = styled(Tiles.Icon)`
  img {
    width: 64px;
    height: 64px;
  }

  @media only screen and (max-width: 580px) {
    min-height: auto;
    order: 0;
    flex: 0 1 30%;
    align-self: auto;
  }
`

const StyledLabel = styled(Tiles.Label)`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 580px) {
    min-height: auto;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    margin-top: 2px;
  }
`

const StyledRow = styled(Row)`
  justify-content: center;
`

const StyledCol = styled(Col)`
  max-width: 260px;
  margin-bottom: 16px;
`

const UserRoleMobile = ({ userName, onNext, ...props }) => {
  const [selectedValue, setSelectedValue] = useState('')

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>Which best describes you, {userName}?</Title>
      </CenteredBox>
      <CenteredBox style={{ minHeight: 'fit-content' }}>
        <Tiles isCentered>
          <Grid>
            <StyledRow>
              {USER_ROLES.map(({ value, label, image }) => (
                <StyledCol key={value} xl={2} lg={2} md={12} xs={12}>
                  <StyledTile
                    value={value}
                    onChange={() => setSelectedValue(value)}
                  >
                    <StyledIcon>
                      <img src={image} alt={label} />
                    </StyledIcon>
                    <StyledLabel>{label}</StyledLabel>
                  </StyledTile>
                </StyledCol>
              ))}
            </StyledRow>
          </Grid>
        </Tiles>
        <NextButton
          isPrimary
          type="submit"
          disabled={!selectedValue}
          onClick={() => onNext(selectedValue)}
        >
          Continue
        </NextButton>
      </CenteredBox>
    </Wrapper>
  )
}

UserRoleMobile.propTypes = {
  userName: PropTypes.string,
  onNext: PropTypes.func,
}

UserRoleMobile.defaultProps = {
  userName: '',
  onNext: () => {},
}

export default UserRoleMobile
