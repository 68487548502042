import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import { createSelector } from 'reselect'
import { NOTIFICATIONS } from '../../components/organisms/Settings/NotificationConstants'
import { STATE_KEY } from './user.reducers'

const getState = state => getImmutable(state, STATE_KEY)

const isLogged = createSelector(
  getState,
  get('logged')
)

const getId = createSelector(
  getState,
  get('userId')
)

const getName = createSelector(
  getState,
  get('name')
)

const getEmail = createSelector(
  getState,
  get('email')
)

const getIdentityId = createSelector(
  getState,
  get('identityId')
)

const getPicture = createSelector(
  getState,
  get('picture')
)

const getOrganizations = createSelector(
  getState,
  getOr([], 'organizations')
)

const getRegistrationData = createSelector(
  getState,
  getOr([], 'registration')
)

const getNotificationsFrequency = createSelector(
  getState,
  getOr(NOTIFICATIONS.instant, 'notifications.frequency')
)

export default {
  isLogged,
  getId,
  getName,
  getEmail,
  getPicture,
  getIdentityId,
  getOrganizations,
  getNotificationsFrequency,
  getRegistrationData,
}
