import React from 'react'
import { connect } from 'react-redux'
import { registrationRequest } from '../../../../store/actions'
import { setUserData } from '../../../../store/user/user.actions'
import RegistrationForm from './RegistrationForm'

const mapDispatchToProps = dispatch => ({
  onSignUp: userData => dispatch(registrationRequest(userData)),
  setUserData: userData => dispatch(setUserData(userData)),
})

const RegistrationFormContainer = ({ ...props }) => (
  <RegistrationForm {...props} />
)

export default connect(
  null,
  mapDispatchToProps
)(RegistrationFormContainer)
