import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { isIOS } from '../../../lib/platform'
import Flex from '../Flex'
import Loader from '../Loader'
import VideoMarker from '../VideoMarker'
import AreaSelection from '../AreaSelection'
import CommentDialog from '../../molecules/CommentDialog'
import { ternary } from '../../../util/utils'
import useCanAddCommentsToSession from '../../hooks/useCanAddCommentsToSession'
import fromUser from '../../../store/user/user.selectors'
import useUrlParser from '../../hooks/useUrlParser'
import { calculateNewSize } from './phoneView.utils'

const boxShadowDefault =
  '0px 0px 1px rgba(7, 8, 34, 0.04), 0px 4px 8px rgba(7, 8, 34, 0.04), 0px 16px 24px rgba(7, 8, 34, 0.04), 0px 24px 32px rgba(7, 8, 34, 0.04)'
const boxShadowSelected =
  '0px 0px 1px rgba(7, 8, 34, 0.04), 0px 4px 8px rgba(7, 8, 34, 0.04), 0px 16px 24px rgba(7, 8, 34, 0.04), 0px 24px 32px rgba(7, 8, 34, 0.04)'

const Wrapper = styled(Flex)`
  box-shadow: ${({ focused }) =>
    focused ? boxShadowSelected : boxShadowDefault};
  width: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).width}px;
  height: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).height}px;
  margin: auto;
  position: relative;
  transition: box-shadow 0.15s ease-in;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const CenteredContainer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  z-index: 10;
  opacity: 0.75;
`

const commonCss = css`
  width: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).width}px;
  height: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).height}px;
  position: absolute;
  top: 0;
  left: 0;
`

const Image = styled.img`
  ${commonCss}
`

const Video = styled.video`
  ${commonCss}
  cursor: ${({ isFullscreen, canAddComments, isTemporaryRecording }) => {
    if (isFullscreen) {
      return 'pointer'
    }
    if (canAddComments || isTemporaryRecording) {
      return 'crosshair'
    }
    if (!canAddComments) {
      return 'not-allowed'
    }
    return 'default'
  }};
`

const StyledAreaSelection = styled(AreaSelection)`
  z-index: 40;
`

const StyledVideoMarker = styled(VideoMarker).attrs({
  size: 'large',
})`
  position: absolute;
  z-index: ${ternary('isBlurred')(10, 11)};
  top: ${prop('yPerc')}%;
  left: ${prop('xPerc')}%;
`

const Rectangle = styled.div.attrs({
  className: 'itrs-rectangle',
})`
  position: absolute;
  border: 2px solid ${prop('theme.colors.yellow')};
  transition: all 0.3s linear;
  box-sizing: border-box;
  overflow: auto;
  z-index: ${ternary('isBlurred')(12, 18)};

  top: ${prop('topLeft.percentage.y')}%;
  left: ${prop('topLeft.percentage.x')}%;
  width: ${({ topLeft, bottomRight }) =>
    bottomRight.percentage.x - topLeft.percentage.x}%;
  height: ${({ topLeft, bottomRight }) =>
    bottomRight.percentage.y - topLeft.percentage.y}%;

  border-style: ${ternary('isBlurred')('dashed', 'solid')};
  border-color: ${({ isBlurred, theme }) =>
    isBlurred ? prop('colors.grey200')(theme) : prop('colors.yellow')(theme)};
  background-color: transparent;
`

const SmallVideoMarkerForRectangle = styled(VideoMarker).attrs({
  size: 'small',
})`
  position: absolute;
  z-index: ${ternary('isBlurred')(14, 20)};
  top: calc(${prop('yPerc')}% - 2px);
  left: calc(${prop('xPerc')}% - 2px);
  border: 0;
  box-shadow: none !important;
`

const CommentDialogBox = styled(Flex).attrs({
  className: 'itrs-comment-dialog',
})`
  position: absolute;
  width: 420px;
  z-index: 40;
  top: ${({ yPerc = 0 }) => `calc(${yPerc}% - 24px)`};
  left: ${({ xPerc = 0 }) => `calc(${xPerc}% + 24px)`};
  display: ${ternary('isVisible')('flex', 'none')};
`

const getVideoEventPositions = (e, videoRef) => {
  const boundingRect = e.target.getBoundingClientRect()
  const resizedClickX = e.clientX - boundingRect.x
  const resizedClickY = e.clientY - boundingRect.y

  const resizeVideoWidth = videoRef.current.clientWidth
  const resizedVideoHeight = videoRef.current.clientHeight
  const xPerc = (resizedClickX * 100) / resizeVideoWidth
  const yPerc = (resizedClickY * 100) / resizedVideoHeight

  const realVideoWidth = videoRef.current.videoWidth
  const realVideoHeight = videoRef.current.videoHeight

  const xRatio = realVideoWidth / resizeVideoWidth
  const yRatio = realVideoHeight / resizedVideoHeight

  const originalClickX = resizedClickX * xRatio
  const originalClickY = resizedClickY * yRatio

  return {
    percentage: {
      x: xPerc,
      y: yPerc,
    },
    absolute: {
      x: originalClickX,
      y: originalClickY,
    },
  }
}

export const getVideoMarkerPositionFromPercentage = (
  { xPerc, yPerc },
  videoRef
) => {
  const realVideoWidth = videoRef.current.videoWidth
  const realVideoHeight = videoRef.current.videoHeight

  const absoluteX = (realVideoWidth / 100) * xPerc
  const absoluteY = (realVideoHeight / 100) * yPerc

  return {
    percentage: {
      x: xPerc,
      y: yPerc,
    },
    absolute: {
      x: absoluteX,
      y: absoluteY,
    },
  }
}

const PhoneView = ({
  autoPlay,
  currentTime,
  currentTimeToken,
  focused,
  height,
  isPlaying,
  maxWidth,
  maxHeight,
  onEnd,
  onLoaded,
  onPause,
  onPlay,
  onTimeUpdate,
  onNewVideoMarker,
  onReplyFocus,
  onMarkerClick,
  onShowRegistrationModal,
  url,
  videoPath,
  width,
  videoMarkers,
  externalRef,
  isTemporaryRecording,
  isAnonymous,
  posterNode,
  removeNewVideoMarkerTrigger,
  ...props
}) => {
  const canAddComments = useCanAddCommentsToSession()
  const isUserLogged = useSelector(fromUser.isLogged)
  const [isLoading, setLoading] = useState(true)
  const [isFullscreen, setFullscreen] = useState(false)
  const [
    newCommentDialogAutoFocusToken,
    setNewCommentDialogAutoFocusToken,
  ] = useState('')
  const [
    newCommentDialogAreaSelectAutoFocusToken,
    setNewCommentAreaSelectDialogAutoFocusToken,
  ] = useState('')
  const [newVideoMarker, setNewVideoMarker] = useState(null)
  const [commentDialogId, setCommentDialogId] = useState(null)

  const [showAreaSelection, setShowAreaSelection] = useState(false)
  const [areaSelection, setAreaSelection] = useState(null)

  const videoRef = useRef(null)
  const videoUrl =
    videoPath.startsWith('http') || videoPath.startsWith('blob')
      ? videoPath
      : `file://${videoPath}`

  const newCommentDialogBoxRef = useRef(null)
  const newCommentDialogBoxAreaSelectionRef = useRef(null)
  const newCommentDocumentClickHandler = e => {
    const { target } = e
    if (
      document.body.contains(target) &&
      !newCommentDialogBoxRef.current.contains(target) &&
      newVideoMarker
    ) {
      setNewVideoMarker(null)
    }

    if (newCommentDialogBoxAreaSelectionRef.current.contains(target)) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    if (
      document.body.contains(target) &&
      !newCommentDialogBoxAreaSelectionRef.current.contains(target) &&
      !target.classList.contains('itrs-rectangle') &&
      areaSelection
    ) {
      setAreaSelection(null)
      setShowAreaSelection(false)
    }
  }

  useEffect(() => {
    if (newCommentDialogBoxRef.current) {
      document.addEventListener('click', newCommentDocumentClickHandler)
    }
    return () => {
      if (newCommentDialogBoxRef.current) {
        document.removeEventListener('click', newCommentDocumentClickHandler)
      }
    }
  }, [
    newCommentDialogBoxRef.current,
    newVideoMarker,
    newCommentDialogBoxAreaSelectionRef.current,
    areaSelection,
  ])

  const commentDialogBoxRef = useRef(null)
  const commentDocumentClickHandler = e => {
    const { target } = e
    if (
      document.body.contains(target) &&
      !commentDialogBoxRef.current.contains(target) &&
      commentDialogId
    ) {
      setCommentDialogId(null)
    }
  }
  useEffect(() => {
    if (commentDialogBoxRef.current) {
      document.addEventListener('click', commentDocumentClickHandler)
    }
    return () => {
      if (commentDialogBoxRef.current) {
        document.removeEventListener('click', commentDocumentClickHandler)
      }
    }
  }, [commentDialogBoxRef.current, commentDialogId])

  const onLoad = () => {
    setLoading(false)
    onLoaded(videoRef.current)
    if (videoRef && videoRef.current) {
      // videoRef.current.removeEventListener('durationchange')
      // videoRef.current.removeEventListener('play')
      // videoRef.current.removeEventListener('pause')
      // videoRef.current.removeEventListener('timeupdate')

      videoRef.current.addEventListener('durationchange', () => {})
      videoRef.current.addEventListener('play', () => {
        setLoading(false)
        setNewVideoMarker(null)
        setAreaSelection(null)
        setShowAreaSelection(false)
        onNewVideoMarker(null)
        setCommentDialogId(null)
      })
      videoRef.current.addEventListener('playing', () => {
        setLoading(false)
        onPlay()
        setNewVideoMarker(null)
        setAreaSelection(null)
        setShowAreaSelection(false)
        setCommentDialogId(null)
      })
      videoRef.current.addEventListener('pause', () => {
        onPause()
      })
      videoRef.current.addEventListener('ended', () => {
        onEnd()
      })
      videoRef.current.addEventListener('timeupdate', () => {
        onTimeUpdate(get(videoRef, 'current.currentTime', 0))
        // onPlay()
      })
      videoRef.current.addEventListener('seeking', () => {
        debounce(() => setLoading(true), 20)
      })
      videoRef.current.addEventListener('seeked', () => {
        setLoading(false)
      })
      videoRef.current.addEventListener('waiting', () => {
        debounce(() => setLoading(true), 20)
      })
      videoRef.current.addEventListener('fullscreenchange', e => {
        setFullscreen(document.fullscreenElement === e.target)
      })
    }
  }

  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (isPlaying) {
        const playPromise = videoRef.current.play()
        if (playPromise.then) {
          playPromise.then(onPlay).catch(onPause)
        }
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = currentTime === 0 ? 0.01 : currentTime
    }
  }, [currentTime, currentTimeToken])

  const onVideoClick = useCallback(
    e => {
      if (
        isFullscreen ||
        (!canAddComments && !isTemporaryRecording) ||
        areaSelection
      ) {
        return
      }
      setCommentDialogId(null)
      const _currentTime = videoRef?.current?.currentTime
      if (_currentTime != null) {
        const clickPosition = getVideoEventPositions(e, videoRef)
        if (isUserLogged) {
          videoRef.current.pause()

          setNewVideoMarker({
            time: _currentTime,
            point: clickPosition,
          })
          setAreaSelection(null)
          setShowAreaSelection(false)
          setNewCommentDialogAutoFocusToken(Math.random().toString())
          videoRef.current.pause()
        }

        onNewVideoMarker({
          time: _currentTime,
          point: clickPosition,
        })
      }
    },
    [
      areaSelection,
      videoRef?.current,
      setCommentDialogId,
      isFullscreen,
      canAddComments,
      isUserLogged,
    ]
  )

  const serializedMarkers = useMemo(() =>
    videoMarkers
      .map(marker => `${marker.commentId}-${marker.isBlurred}`)
      .join(',')
  )
  const markers = useMemo(() => {
    return (
      videoMarkers.length > 0 &&
      videoMarkers.map(
        ({
          commentId,
          time,
          videoPoint,
          videoRectangle,
          number,
          isBlurred,
        }) => {
          const videoPointPercX = videoPoint?.percentage?.x
          const videoPointPercY = videoPoint?.percentage?.y
          return videoPoint ? (
            <StyledVideoMarker
              key={`${time}-${videoPointPercX}-${videoPointPercY}`}
              xPerc={videoPointPercX}
              yPerc={videoPointPercY}
              isBlurred={isBlurred}
              onClick={() => {
                setCommentDialogId(commentId)
                onMarkerClick(commentId)
              }}
            >
              {number}
            </StyledVideoMarker>
          ) : (
            <>
              <Rectangle
                key={`rect-${time}-${videoRectangle.topLeft.percentage.x}-${videoRectangle.topLeft.percentage.y}`}
                topLeft={videoRectangle.topLeft}
                bottomRight={videoRectangle.bottomRight}
                isBlurred={isBlurred}
              />
              <SmallVideoMarkerForRectangle
                key={`marker-${time}-${videoRectangle.topLeft.percentage.x}-${videoRectangle.topLeft.percentage.y}`}
                xPerc={videoRectangle.bottomRight.percentage.x}
                yPerc={videoRectangle.bottomRight.percentage.y}
                isBlurred={isBlurred}
                onClick={() => {
                  setCommentDialogId(commentId)
                  onMarkerClick(commentId)
                }}
              >
                {number}
              </SmallVideoMarkerForRectangle>
            </>
          )
        }
      )
    )
  }, [serializedMarkers])

  const { initialVideoTime, initialMarkerPosition } = useUrlParser()
  useEffect(() => {
    if (!isLoading && videoRef?.current) {
      let skipVideoMarker = false
      if (
        initialVideoTime &&
        videoRef.current.currentTime !== initialVideoTime
      ) {
        videoRef.current.pause()
        videoRef.current.currentTime = initialVideoTime
        skipVideoMarker = true
        setLoading(true)
      }

      if (initialMarkerPosition && !skipVideoMarker) {
        videoRef.current.pause()
        setTimeout(() => {
          videoRef.current.pause()
          const markerPosition = getVideoMarkerPositionFromPercentage(
            { xPerc: initialMarkerPosition.x, yPerc: initialMarkerPosition.y },
            videoRef
          )
          const videoMarker = {
            time: videoRef.current.currentTime,
            point: markerPosition,
          }
          setNewVideoMarker(videoMarker)
          setShowAreaSelection(false)
          onNewVideoMarker(videoMarker)
          setNewCommentDialogAutoFocusToken(Math.random().toString())
        }, 250)
      }
    }
  }, [isLoading, videoRef.current])

  useEffect(() => {
    if (removeNewVideoMarkerTrigger) {
      setNewVideoMarker(null)
      setAreaSelection(null)
      setShowAreaSelection(false)
    }
  }, [removeNewVideoMarkerTrigger])

  return (
    <Wrapper
      {...props}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      focused={focused}
      ref={externalRef}
    >
      {posterNode}
      {isLoading && (
        <CenteredContainer>
          <Loader />
        </CenteredContainer>
      )}
      {url && <Image src={url} />}
      <StyledAreaSelection
        containerRef={externalRef}
        onDrag={() => {}}
        mouseDownBlackList={[newCommentDialogBoxAreaSelectionRef.current]}
        onMouseDown={() => videoRef.current.pause()}
        onAreaSelection={_areaSelection => {
          if (isTemporaryRecording || isAnonymous) {
            setShowAreaSelection(false)
            setAreaSelection(null)
            setNewVideoMarker(null)
            onShowRegistrationModal()
            return
          }
          setAreaSelection(_areaSelection)
          setTimeout(() => {
            setNewCommentAreaSelectDialogAutoFocusToken(
              Math.random().toString()
            )
          }, 250)
        }}
        isVisible={showAreaSelection}
      />
      <CommentDialogBox
        ref={newCommentDialogBoxAreaSelectionRef}
        isVisible={!!areaSelection}
        xPerc={areaSelection?.bottomRight?.percentage?.x}
        yPerc={areaSelection?.bottomRight?.percentage?.y}
        data-test="new-area-selection-comment-dialog-box"
      >
        <CommentDialog
          videoCurrentTime={videoRef?.current?.currentTime}
          videoRectangle={areaSelection}
          onCommentCreate={() => {
            setNewVideoMarker(null)
            setAreaSelection(null)
            setShowAreaSelection(false)
          }}
          autoFocusToken={newCommentDialogAreaSelectAutoFocusToken}
          isCreateNewComment
        />
      </CommentDialogBox>
      {newVideoMarker && !areaSelection && (
        <StyledVideoMarker
          xPerc={newVideoMarker?.point?.percentage?.x}
          yPerc={newVideoMarker?.point?.percentage?.y}
        />
      )}
      <CommentDialogBox
        ref={newCommentDialogBoxRef}
        isVisible={!!newVideoMarker}
        xPerc={newVideoMarker?.point?.percentage?.x + 2}
        yPerc={newVideoMarker?.point?.percentage?.y}
        data-test="new-comment-dialog-box"
      >
        <CommentDialog
          videoCurrentTime={newVideoMarker?.time}
          videoPoint={newVideoMarker?.point}
          onCommentCreate={() => setNewVideoMarker(null)}
          autoFocusToken={newCommentDialogAutoFocusToken}
          isCreateNewComment
        />
      </CommentDialogBox>
      {!isPlaying && markers}
      {videoPath && (
        <Video
          src={videoUrl}
          ref={videoRef}
          onLoadedData={onLoad}
          autoPlay={autoPlay}
          controls={false}
          controlsList="nodownload"
          playsInline={isIOS()}
          preload="auto"
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          type="video/mp4"
          onClick={onVideoClick}
          onMouseDown={() => {
            setAreaSelection(null)
            setShowAreaSelection(true)
            setNewVideoMarker(null)
          }}
          isFullscreen={isFullscreen}
          canAddComments={canAddComments}
          isTemporaryRecording={isTemporaryRecording}
        />
      )}
    </Wrapper>
  )
}

PhoneView.propTypes = {
  autoPlay: PropTypes.bool,
  currentTime: PropTypes.number,
  currentTimeToken: PropTypes.string,
  focused: PropTypes.bool,
  height: PropTypes.number,
  isPlaying: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  onEnd: PropTypes.func,
  onLoaded: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onNewVideoMarker: PropTypes.func,
  onReplyFocus: PropTypes.func,
  onMarkerClick: PropTypes.func,
  onShowRegistrationModal: PropTypes.func,
  url: PropTypes.string,
  videoPath: PropTypes.string,
  width: PropTypes.number,
  videoMarkers: PropTypes.arrayOf(
    PropTypes.shape({
      commentId: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
      time: PropTypes.number,
      number: PropTypes.number,
    })
  ),
  externalRef: PropTypes.shape({}),
  isTemporaryRecording: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  posterNode: PropTypes.node,
  removeNewVideoMarkerTrigger: PropTypes.string,
}

PhoneView.defaultProps = {
  autoPlay: false,
  currentTime: 0,
  currentTimeToken: '',
  focused: false,
  height: 731,
  isPlaying: false,
  maxWidth: window.innerWidth * 0.69,
  maxHeight: 731,
  onEnd: () => {},
  onLoaded: () => {},
  onPause: () => {},
  onPlay: () => {},
  onTimeUpdate: () => {},
  onNewVideoMarker: () => {},
  onReplyFocus: () => {},
  onMarkerClick: () => {},
  onShowRegistrationModal: () => {},
  url: '',
  videoPath: '',
  width: 411,
  videoMarkers: [],
  externalRef: {},
  isTemporaryRecording: false,
  isAnonymous: true,
  posterNode: null,
  removeNewVideoMarkerTrigger: undefined,
}

PhoneView.whyDidYouRender = false
export default React.memo(PhoneView)
