import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { v1 as uuidv1 } from 'uuid'
import fromUser from '../../../store/user/user.selectors'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import SessionTopBar from './SessionTopBar'
import useSessionsHook from '../../../store/hooks/useSessionsHook'
import useShareModal from '../../molecules/ShareModal/useShareModal'
import useUpdateSession from '../../../store/hooks/useUpdateSession'
import useNotifications from '../../../store/hooks/useNotifications'
import useSessionVideoUpload from '../../../store/hooks/useSessionVideoUpload'
import { setRegistrationData } from '../../../store/user/user.actions'
import useCanAddCommentsToSession from '../../hooks/useCanAddCommentsToSession'
import useBrowserExtensionInstalled from '../../hooks/useBrowserExtensionInstalled'
import { isChromeLikeBrowser, isEdgeBrowser } from '../../../lib/platform'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import useJira from '../../hooks/useJira'
import useTrello from '../../hooks/useTrello'
import useSlack from '../../hooks/useSlack'
import { getFolderPathName } from '../../../util/paths'
import { getSessionUrl } from '../../../lib/urls'

const SessionTopBarContainer = ({
  isTemporaryRecording,
  onLogin,
  onRegister,
  recordingId,
  onSettings,
  onCreateJira,
  onCreateTrello,
  onSendSlackMessage,
  temporarySessionTitle,
  ...props
}) => {
  const history = useHistory()

  const isUserLogged = useSelector(fromUser.isLogged)
  const userName = useSelector(fromUser.getName)

  const storeProject = useSelector(fromProjects.getCurrentProject)

  const currentSession = useSelector(fromSessions.getCurrentSession)
  const currentSessionId = useSelector(fromSessions.getCurrentSessionId)
  const { sessions } = useSessionsHook()

  const currentEnhancedSession =
    useMemo(() => {
      return sessions.find(session => session.pk === currentSessionId)
    }, [currentSessionId, sessions]) || currentSession

  const currentProject =
    storeProject && currentSession?.projectId === storeProject?.pk
      ? storeProject
      : {}

  const onGoToHome = () => history.push('/app')

  const projectUrl = getFolderPathName(currentProject?.pk)
  const onGoToProject = e => {
    e.preventDefault()
    e.stopPropagation()
    history.push(projectUrl)
  }

  const userCanContribute = useCanAddCommentsToSession()

  const { isUserContributor, isCurrentUserOwner } = useShareModal({
    sessionId: currentSessionId,
  })

  const defaultSessionTitle = currentSession?.title || ''

  const { updateSession } = useUpdateSession(currentSessionId)
  const onSessionTitleUpdate = useCallback(
    title => updateSession({ title }),
    []
  )

  const { isUploadingVideo, videoUploadProgress } = useSessionVideoUpload(
    currentSessionId
  )

  useBeforeUnload(isUploadingVideo)

  const dispatch = useDispatch()
  const onRegisterHandler = () => {
    if (isTemporaryRecording) {
      dispatch(
        setRegistrationData({
          recordingId: `extension:${recordingId}`,
        })
      )
    }
    onRegister()
  }

  const isExtensionInstalled = useBrowserExtensionInstalled()
  const isChrome = isChromeLikeBrowser()
  const isEdge = isEdgeBrowser()

  const { hasJira } = useJira()
  const onJiraClick = sessionInfo => {
    if (!hasJira) {
      onSettings()
      return
    }
    onCreateJira(sessionInfo)
  }

  const { hasTrello } = useTrello()
  const onTrelloClick = sessionInfo => {
    if (!hasTrello) {
      onSettings()
      return
    }
    onCreateTrello(sessionInfo)
  }

  const { hasSlack } = useSlack()
  const onSlackClick = sessionInfo => {
    if (!hasSlack) {
      onSettings()
      return
    }
    onSendSlackMessage(sessionInfo)
  }

  const {
    unreadNotificationsCount,
    onMarkAllNotificationsAsRead,
  } = useNotifications()

  const onCopy = () => {
    copy(getSessionUrl(currentSessionId))
    dispatch(
      addNotification({
        notificationId: uuidv1(),
        text: 'Link copied to clipboard',
      })
    )
  }

  return (
    <SessionTopBar
      {...props}
      onSettings={onSettings}
      isUserLogged={isUserLogged}
      userName={userName}
      userCanContribute={userCanContribute}
      onGoToHome={onGoToHome}
      onGoToProject={onGoToProject}
      onCopy={onCopy}
      isPublicSession={
        currentEnhancedSession?.isPublic ||
        currentEnhancedSession?.sharing?.isPublic
      }
      isUserContributor={isUserContributor}
      isCurrentUserOwner={isCurrentUserOwner}
      isTemporaryRecording={isTemporaryRecording}
      numberOfContributors={currentEnhancedSession?.sharedWithCounter}
      defaultSessionTitle={defaultSessionTitle}
      sessionTitle={
        !isTemporaryRecording ? currentSession?.title : temporarySessionTitle
      }
      projectTitle={currentProject?.title}
      projectId={currentProject?.pk}
      projectUrl={projectUrl}
      sessionId={currentSessionId}
      onSessionTitleUpdate={onSessionTitleUpdate}
      onLogin={onLogin}
      onRegister={onRegisterHandler}
      onJiraClick={onJiraClick}
      onTrelloClick={onTrelloClick}
      onSlackClick={onSlackClick}
      isUploadingVideo={isUploadingVideo}
      videoUploadProgress={videoUploadProgress}
      isExtensionInstalled={isExtensionInstalled}
      isChromeBrowser={isChrome}
      isEdgeBrowser={isEdge}
      unreadNotificationsCount={unreadNotificationsCount}
      onMarkAllNotificationsAsRead={onMarkAllNotificationsAsRead}
      hasSlack={hasSlack}
    />
  )
}

SessionTopBarContainer.propTypes = {
  isTemporaryRecording: PropTypes.bool,
  onLogin: PropTypes.func,
  onRegister: PropTypes.func,
  onSettings: PropTypes.func,
  onCreateJira: PropTypes.func,
  onCreateTrello: PropTypes.func,
  onSendSlackMessage: PropTypes.func,
  recordingId: PropTypes.string,
  temporarySessionTitle: PropTypes.string,
}

SessionTopBarContainer.defaultProps = {
  isTemporaryRecording: false,
  onLogin: () => {},
  onRegister: () => {},
  onSettings: () => {},
  onCreateJira: () => {},
  onCreateTrello: () => {},
  onSendSlackMessage: () => {},
  recordingId: null,
  temporarySessionTitle: '',
}

export default SessionTopBarContainer
