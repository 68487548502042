import get from 'lodash/get'
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import gql from 'graphql-tag'
import {
  jiraIntegration as jiraIntegrationGql,
  updateJiraIntegration as updateJiraIntegrationGql,
} from '../../../graphql/queries'
import {
  createJiraIssue as createJiraIssueGql,
  deleteJira as removeJiraGql,
} from '../../../graphql/mutations'
import fromIntegrationsJira from './integrations.jira.selectors'
import {
  GET_JIRA_REQUEST,
  getJiraRequestSuccess,
  getJiraRequestFailure,
  UPDATE_JIRA_REQUEST,
  updateJiraRequestSuccess,
  updateJiraRequestFailure,
  CREATE_JIRA_REQUEST,
  createJiraRequestSuccess,
  createJiraRequestFailure,
  REMOVE_JIRA_REQUEST,
  removeJiraRequestSuccess,
  removeJiraRequestFailure,
} from './integrations.jira.actions'
import { LOGIN_REQUEST_SUCCESS } from '../../actions'
import { activityBasedAction } from '../../utils.sagas'
import analytics from '../../../lib/analytics'

export function* fetchJira({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(jiraIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.jiraIntegration')
    yield put(getJiraRequestSuccess(data))
  } catch (e) {
    yield put(getJiraRequestFailure(e))
    console.error(e)
  }
}

function* updateJira({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(updateJiraIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.updateJiraIntegration')
    yield put(updateJiraRequestSuccess(data))
  } catch (e) {
    yield put(updateJiraRequestFailure(e))
    console.error(e)
  }
}

function* startUpdateInterval({ apolloClient }) {
  while (true) {
    try {
      const hasJira = yield select(fromIntegrationsJira.getHasJira)
      if (hasJira) {
        yield call(updateJira, { apolloClient })
      }
    } catch (e) {
      console.error(e)
    }
    yield delay(3000 * 60)
  }
}

function* startJiraFork({ apolloClient }) {
  yield call(fetchJira, { apolloClient })

  const hasJira = yield select(fromIntegrationsJira.getHasJira)
  if (hasJira) {
    yield fork(activityBasedAction, fetchJira, 26000, { apolloClient })
    yield fork(startUpdateInterval, { apolloClient })
  }
}

function* createJira(
  { apolloClient },
  {
    payload: {
      summary,
      description,
      issueTypeId,
      projectId,
      resourceId,
      resourceUrl,
      sessionId,
      customFields,
    },
    meta: { thunk },
  }
) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(createJiraIssueGql),
      variables: {
        summary,
        description,
        issueTypeId,
        projectId,
        resourceId,
        resourceUrl,
        sessionId,
        customFields,
      },
    })

    const jiraIssue = get(result, 'data.createJiraIssue')
    yield put(createJiraRequestSuccess(jiraIssue, thunk))

    try {
      analytics.trackCreateJiraIssue()
    } catch (error) {
      console.error(error)
    }
  } catch (e) {
    yield put(createJiraRequestFailure(e, thunk))
    console.error(e)
  }
}

function* removeJira({ apolloClient }, { meta: { thunk } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(removeJiraGql),
    })

    yield put(removeJiraRequestSuccess({}, thunk))
    try {
      analytics.trackDisconnectJira()
    } catch (err) {
      console.error(err)
    }
  } catch (e) {
    yield put(removeJiraRequestFailure(e, thunk))
    console.error(e)
  }
}

export default function* jiraSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startJiraFork, { apolloClient }),
    yield takeLatest(GET_JIRA_REQUEST, fetchJira, { apolloClient }),
    yield takeLatest(UPDATE_JIRA_REQUEST, updateJira, { apolloClient }),
    yield takeLatest(CREATE_JIRA_REQUEST, createJira, { apolloClient }),
    yield takeLatest(REMOVE_JIRA_REQUEST, removeJira, { apolloClient }),
  ])
}
