/* eslint-disable import/prefer-default-export */

const getPrefixedId = prefix => id =>
  id.startsWith(prefix) ? id : `${prefix}${id}`

const getCleanId = prefixes => id =>
  prefixes.reduce(
    (acc, prefix) => (acc?.startsWith(prefix) ? acc.replace(prefix, '') : acc),
    id
  )

const PROJECT_PREFIX = 'project:'
const FOLDER_PREFIX = 'folder:'
const SESSION_PREFIX = 'session:'
const RECORDING_PREFIX = 'recording:'
const COMMENT_PREFIX = 'comment:'
const REPLY_PREFIX = 'comment:'
const REMOTE_TEST_PREFIX = 'remote-test:'
const USER_TEST_PREFIX = 'user-test:'
const ORGANIZATION_PREFIX = 'organization:'

export const getPrefixedProjectId = getPrefixedId(PROJECT_PREFIX)
export const getPrefixedFolderId = getPrefixedProjectId
export const getPrefixedSessionId = getPrefixedId(SESSION_PREFIX)
export const getPrefixedRecordingId = getPrefixedSessionId
export const getPrefixedCommentId = getPrefixedId(COMMENT_PREFIX)
export const getPrefixedReplyId = getPrefixedId(REPLY_PREFIX)
export const getPrefixedRemoteTestId = getPrefixedId(REMOTE_TEST_PREFIX)
export const getPrefixedOrganizationId = getPrefixedId(ORGANIZATION_PREFIX)

export const getCleanProjectId = getCleanId([PROJECT_PREFIX, FOLDER_PREFIX])
export const getCleanFolderId = getCleanProjectId
export const getCleanSessionId = getCleanId([SESSION_PREFIX, RECORDING_PREFIX])
export const getCleanRecordingId = getCleanSessionId
export const getCleanCommentId = getCleanId([COMMENT_PREFIX])
export const getCleanReplyId = getCleanId([REPLY_PREFIX])
export const getCleanRemoteTestId = getCleanId([
  REMOTE_TEST_PREFIX,
  USER_TEST_PREFIX,
])
export const getCleanOrganizationId = getCleanId([ORGANIZATION_PREFIX])
