import {
  GET_TRELLO_REQUEST_FAILURE,
  GET_TRELLO_REQUEST_SUCCESS,
  UPDATE_TRELLO_REQUEST_SUCCESS,
} from './integrations.trello.actions'
import { buildReducerForActions } from '../../../util/reduxTools'

export const STATE_KEY = 'integrations'
export const STATE_SUB_KEY = 'trello'

export const INITIAL_STATE = {
  hasTrello: false,
}

const reduceTrello = (state, { payload: { data } }) => {
  return {
    ...state,
    ...data,
  }
}

const reducer = {
  [GET_TRELLO_REQUEST_FAILURE]: () => {
    return INITIAL_STATE
  },
  [GET_TRELLO_REQUEST_SUCCESS]: reduceTrello,
  [UPDATE_TRELLO_REQUEST_SUCCESS]: reduceTrello,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
