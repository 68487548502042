import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Dropdown,
  Autocomplete,
  Menu,
  Item,
  Field,
  Label,
} from '@zendeskgarden/react-dropdowns'
import {
  Field as FormField,
  Label as FormLabel,
  Input,
  Textarea,
} from '@zendeskgarden/react-forms'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import debounce from 'lodash/debounce'
import { Button } from '@zendeskgarden/react-buttons'
import prop from 'lodash/fp/prop'
import first from 'lodash/first'
import { StyledErrorMessage } from '../../commonStyledComponents'
import Flex from '../../atoms/Flex'
import TrelloLogoSvg from '../../../assets/mark-gradient-blue-trello.svg'
import Loader from '../../atoms/Loader'

const StyledHeader = styled(Header)`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  padding: 12px 24px;
`

const StyledBody = styled(Body)`
  padding: 0 40px 0 40px;
  align-items: center;
  justify-content: center;
  height: auto;
`

const Row = styled(Flex)`
  margin-bottom: 24px;
`

const JiraLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

const StyledForm = styled(Form)`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`

const SubmitButton = styled(Button)`
  order: 0;
  align-self: flex-end;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledItem = styled(Item)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const TextAreaSubtitle = styled(Flex)`
  font-style: italic;
  color: ${prop('theme.colors.grey400')};
  font-size: 12px;
  margin-top: 2px;
  padding-left: 1px;
`

const StyledLoader = styled(Loader).attrs(props => ({
  color: prop('theme.colors.grey600')(props),
}))``

const useDropdown = (items, key, searchKey, initialKey) => {
  const [selectedItemKey, setSelectedItemKey] = useState(
    initialKey || items?.[0]?.[key]
  )

  const initialValue = first(items.filter(item => item[key] === initialKey))
  const [selectedItem, setSelectedItem] = useState(initialValue || items?.[0])
  const [value, setValue] = useState('')
  const [matchingItems, setMatchingItems] = useState(items)

  const filterMatchingRef = useRef(
    debounce(_value => {
      if (!_value?.trim) {
        setMatchingItems(items)
        return
      }
      const matchedProjects = items.filter(
        item =>
          item[searchKey]
            .trim()
            .toLowerCase()
            .indexOf(_value.trim().toLowerCase()) !== -1
      )

      setMatchingItems(matchedProjects)
    }, 300)
  )

  useEffect(() => {
    filterMatchingRef.current(value)
  }, [value])

  useEffect(() => {
    const obj = items.find(item => item[key] === selectedItemKey)
    if (obj) {
      setSelectedItem(obj)
    }
  }, [selectedItemKey, items])

  return {
    selectedItemKey,
    setSelectedItemKey,
    selectedItem,
    value,
    setValue,
    matchingItems,
    filterMatchingRef,
  }
}

const validateFormValues = values => {
  const errors = {}
  if (!values.title) {
    errors.title = 'The title is required'
  } else if (!values.description) {
    errors.description = 'The description is required'
  }
  return errors
}

const filterListsBasedOnBoard = selectedBoard => list => {
  if (list.idBoard === selectedBoard.id) {
    return true
  }
  if (list.idBoard !== selectedBoard.id) {
    return false
  }
  if (!list.idBoard) {
    return false
  }
  return true
}

const CreateTrelloCardModal = ({
  boards,
  lists,
  initialDescription,
  initialTrelloBoardId,
  onClose,
  onCreate,
  onBoardChange,
  sessionId,
}) => {
  const [isFormLoading, setFormLoading] = useState(false)

  const {
    selectedItemKey: selectedBoardKey,
    setSelectedItemKey: setSelectedBoardKey,
    selectedItem: selectedBoard,
    value: boardValue,
    setValue: setBoardValue,
    matchingItems: matchingBoards,
  } = useDropdown(boards, 'id', 'name', initialTrelloBoardId)

  const {
    selectedItemKey: selectedListKey,
    setSelectedItemKey: setSelectedListKey,
    selectedItem: selectedList,
    value: listValue,
    setValue: setListValue,
    matchingItems: matchingLists,
  } = useDropdown(lists, 'id', 'name')

  useEffect(() => {
    const filteredLists = lists.filter(filterListsBasedOnBoard(selectedBoard))
    setSelectedListKey(filteredLists?.[0]?.id)
    onBoardChange(selectedBoard)
  }, [selectedBoard?.id])

  const formInitialValues = {
    title: '',
    description: initialDescription,
  }

  return (
    <Modal isAnimated={false}>
      <StyledHeader>
        <JiraLogo src={TrelloLogoSvg} alt="jira" />
        Create Trello card
      </StyledHeader>
      <StyledBody>
        <Flex style={{ marginBottom: '24px' }}>
          <Formik
            initialValues={formInitialValues}
            validate={validateFormValues}
            onSubmit={values => {
              setFormLoading(true)
              const card = {
                title: values.title,
                description: values.description,
                boardId: selectedBoard.id,
                listId: selectedList.id,
                sessionId,
              }
              onCreate(card)
                .then(() => {
                  onClose()
                })
                .catch(() => {
                  setFormLoading(false)
                })
            }}
            render={({ values, errors, handleBlur, handleChange }) => (
              <StyledForm>
                <Row>
                  <FormField>
                    <FormLabel>Title</FormLabel>
                    <Input
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      validation={errors.title ? 'warning' : undefined}
                    />
                    <ErrorMessage component={StyledErrorMessage} name="title" />
                  </FormField>
                </Row>

                <Row>
                  <FormField>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={initialDescription}
                      value={values.description}
                      minRows={6}
                      validation={errors.description ? 'warning' : undefined}
                      isResizable
                    />
                    <TextAreaSubtitle>
                      Recording link will be attached automatically
                    </TextAreaSubtitle>
                  </FormField>
                </Row>

                <Row>
                  <Dropdown
                    inputValue={boardValue}
                    selectedItem={selectedBoardKey}
                    onStateChange={handleChange}
                    onSelect={item => setSelectedBoardKey(item)}
                    onInputValueChange={value => setBoardValue(value)}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                    validation={errors.board ? 'warning' : undefined}
                  >
                    <Field style={{ width: '100%' }}>
                      <Label>Board</Label>
                      <Autocomplete>
                        <span>{selectedBoard.name}</span>
                      </Autocomplete>
                    </Field>
                    <Menu>
                      {matchingBoards.length ? (
                        matchingBoards.map(option => (
                          <StyledItem key={option.id} value={option.id}>
                            <span>{option.name}</span>
                          </StyledItem>
                        ))
                      ) : (
                        <Item disabled>No matches found</Item>
                      )}
                    </Menu>
                  </Dropdown>
                  <ErrorMessage component={StyledErrorMessage} name="project" />
                </Row>

                <Row>
                  <Dropdown
                    inputValue={listValue}
                    selectedItem={selectedListKey}
                    onStateChange={handleChange}
                    onSelect={item => setSelectedListKey(item)}
                    onInputValueChange={value => setListValue(value)}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                    validation={errors.issueType ? 'warning' : undefined}
                  >
                    <Field style={{ width: '100%' }}>
                      <Label>List</Label>
                      <Autocomplete>
                        <span>{selectedList.name}</span>
                      </Autocomplete>
                    </Field>
                    <Menu>
                      {matchingLists.length ? (
                        matchingLists
                          .filter(filterListsBasedOnBoard(selectedBoard))
                          .map(option => (
                            <StyledItem key={option.id} value={option.id}>
                              <span>{option.name}</span>
                            </StyledItem>
                          ))
                      ) : (
                        <Item disabled>No matches found</Item>
                      )}
                    </Menu>
                  </Dropdown>
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="issueType"
                  />
                </Row>
                <Row style={{ marginBottom: '0' }}>
                  <SubmitButton
                    isPrimary
                    type="submit"
                    disabled={isFormLoading}
                  >
                    {isFormLoading && <StyledLoader size="16px" />}
                    {!isFormLoading && <>Create card</>}
                  </SubmitButton>
                </Row>
              </StyledForm>
            )}
          />
        </Flex>
      </StyledBody>
      <Close aria-label="Close modal" onClick={onClose} />
    </Modal>
  )
}

CreateTrelloCardModal.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onBoardChange: PropTypes.func,
  initialDescription: PropTypes.string,
  initialTrelloBoardId: PropTypes.string,
  sessionId: PropTypes.string,
  boards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      idBoard: PropTypes.string,
    })
  ),
}

CreateTrelloCardModal.defaultProps = {
  onClose: () => {},
  onCreate: () => {},
  onBoardChange: () => {},
  boards: [],
  lists: [],
  initialDescription: '',
  initialTrelloBoardId: null,
  sessionId: null,
}

export default CreateTrelloCardModal
