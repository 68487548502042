import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WorkspaceSettings from '../../organisms/WorkspaceSettings/WorkspaceSettings'
import Flex from '../../atoms/Flex'
import WorkspaceSettingsTopBar from './WorkspaceSettingsTopBar'
import Notifications from '../../organisms/Notifications'

const Wrapper = styled(Flex)`
  height: 100vh;
  overflow-y: scroll;
  flex: 1;
`

const StyledWorkspaceSettingsTopBar = styled(WorkspaceSettingsTopBar)`
  position: sticky;
  top: 0;
  left: 0;
`

const StyledWorkspaceSettings = styled(WorkspaceSettings)`
  padding-top: 88px;
  height: calc(100vh + 52px);
  justify-content: flex-start;
  padding-bottom: 32px;
`

const WorkspaceSettingsPage = ({
  workspaceName,
  workspacePictureUrl,
  workspaceBackgroundColor,
  onBack,
  userName,
  onSettings,
  onLogout,
  onWorkspaceUpdate,
  onUpdateWorkspaceEmailDomains,
  onWorkspaceTitleUpdate,
  onWorkspaceDelete,
  isWorkspaceTitleRequestLoading,
  isWorkspaceTitleRequestRejected,
  isCurrentUserOrganizationAdmin,
  availableWorkspaceEmailDomains,
  workspaceEmailDomains,
  ...props
}) => (
  <Wrapper {...props}>
    <StyledWorkspaceSettingsTopBar
      onBack={onBack}
      userName={userName}
      onSettings={onSettings}
      onLogout={onLogout}
    />
    <StyledWorkspaceSettings
      workspaceName={workspaceName}
      workspacePictureUrl={workspacePictureUrl}
      workspaceBackgroundColor={workspaceBackgroundColor}
      onWorkspaceUpdate={onWorkspaceUpdate}
      onUpdateWorkspaceEmailDomains={onUpdateWorkspaceEmailDomains}
      onWorkspaceTitleUpdate={onWorkspaceTitleUpdate}
      onWorkspaceDelete={onWorkspaceDelete}
      isWorkspaceTitleRequestLoading={isWorkspaceTitleRequestLoading}
      isWorkspaceTitleRequestRejected={isWorkspaceTitleRequestRejected}
      isCurrentUserOrganizationAdmin={isCurrentUserOrganizationAdmin}
      availableWorkspaceEmailDomains={availableWorkspaceEmailDomains}
      workspaceEmailDomains={workspaceEmailDomains}
      onBack={onBack}
    />
    <Notifications />
  </Wrapper>
)

WorkspaceSettingsPage.propTypes = {
  onBack: PropTypes.func,
  onLogout: PropTypes.func,
  onSettings: PropTypes.func,
  onWorkspaceUpdate: PropTypes.func,
  onUpdateWorkspaceEmailDomains: PropTypes.func.isRequired,
  userName: PropTypes.string,
  workspaceBackgroundColor: PropTypes.string,
  workspaceName: PropTypes.string.isRequired,
  workspacePictureUrl: PropTypes.string,
  onWorkspaceTitleUpdate: PropTypes.func.isRequired,
  onWorkspaceDelete: PropTypes.func.isRequired,
  isWorkspaceTitleRequestLoading: PropTypes.bool.isRequired,
  isWorkspaceTitleRequestRejected: PropTypes.bool.isRequired,
  isCurrentUserOrganizationAdmin: PropTypes.bool.isRequired,
  availableWorkspaceEmailDomains: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  workspaceEmailDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
}

WorkspaceSettingsPage.defaultProps = {
  onBack: () => {},
  onLogout: () => {},
  onSettings: () => {},
  onWorkspaceUpdate: () => {},
  userName: '',
  workspaceBackgroundColor: '',
  workspacePictureUrl: '',
}

export default WorkspaceSettingsPage
