import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@zendeskgarden/react-theming'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import {
  middleware as thunkMiddleware,
  reducer as thunkReducer,
} from 'redux-saga-thunk'
import persistState from 'redux-localstorage'
import { v1 as uuidv1 } from 'uuid'
import firebase from 'firebase/app'
import 'firebase/analytics'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'
import { combineNestedReducers } from './util/reduxTools'
import reducers, { STATE_KEY as ROOT_STATE_KEY } from './store/reducers'
import desktopReducers, {
  STATE_KEY as DESKTOP_STATE_KEY,
} from './desktop/store/reducers'
import { LOGOUT_REQUEST } from './store/actions'
import { addNotification } from './store/ui-notifications/ui-notifications.actions'
import theme from './components/theme/old'
import './index.css'
import App from './App'
import rootSaga from './store/sagas'
import { getAppSyncClient } from './lib/appSyncClient'
import appConfig from './config'
import * as serviceWorker from './serviceWorker'
import { version } from '../package.json'
import ErrorBoundary from './components/molecules/ErrorBoundary'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { setIsGoogleLoginAvailable } from './util/auth'

const sentryVersion =
  appConfig.Sentry.environment === 'production' ? version : `${version}-dev`

console.log(`iterspace webapp v${version}`)
console.log(`sentry - v${sentryVersion}`)

firebase.initializeApp(appConfig.Firebase)
firebase.analytics()

Sentry.init({
  ...appConfig.Sentry,
  release: sentryVersion,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
})

window.onerror = err => {
  Sentry.captureException(err)
}

const sagaMiddleware = createSagaMiddleware()
const rootReducer = (state, action) => {
  const req = require.context('..', true, /\.\/.+\/.+\.reducers\.js$/)

  const baseReducers = req.keys().reduce((acc, key) => {
    const stateKey = req(key).STATE_KEY
    const subKey = req(key).STATE_SUB_KEY
    const baseKey = `${stateKey}${subKey ? `.${subKey}` : ''}`
    return { ...acc, [baseKey]: req(key).default }
  }, {})

  if (action.type === LOGOUT_REQUEST) {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  const combinedReducers = combineNestedReducers({
    thunk: thunkReducer,
    [ROOT_STATE_KEY]: reducers,
    [DESKTOP_STATE_KEY]: desktopReducers,
    ...baseReducers,
  })
  return combinedReducers(state, action)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
    persistState([
      'thunk',
      'app',
      'devices',
      'localSessions',
      'comments',
      'organizations',
      'projects',
      'sessions',
      'remoteTests',
      'smartAssistant',
      'user',
      'users',
      'integrations',
      'integrations.jira',
      'integrations.trello',
      'integrations.slack',
      'notifications.activities',
      'library',
      'uploads',
    ]),
    Sentry.createReduxEnhancer()
  )
)

const apolloClient = getAppSyncClient()
sagaMiddleware.run(rootSaga, { apolloClient })

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={appConfig.Google.AppClientId}
          onScriptLoadSuccess={setIsGoogleLoginAvailable(true)}
          onScriptLoadError={setIsGoogleLoginAvailable(false)}
        >
          <App />
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

if (serviceWorker) {
  serviceWorker.register({
    onUpdate: serviceWorkerRegistration => {
      store.dispatch(
        addNotification({
          notificationId: uuidv1(),
          text:
            'New update available! Refresh the page to see the latest changes',
          isPersisted: true,
          callToAction: {
            text: 'Refresh',
            type: 'refresh',
            serviceWorkerRegistration,
          },
        })
      )
    },
  })
}
