import React from 'react'
import PropTypes from 'prop-types'
import SingleComment, { EVENT_TYPE } from './SingleComment'
import useCommentEvent from '../../../store/hooks/useCommentEvent'

const CommentEvent = ({
  commentId,
  resolutionCreatedAt,
  resolutionStatus,
  ...props
}) => {
  const { resolutionUser } = useCommentEvent(
    commentId,
    resolutionCreatedAt,
    resolutionStatus
  )
  return (
    <SingleComment
      userName={resolutionUser?.name}
      pictureUrl={resolutionUser?.picture}
      data-test="comment-resolution-event"
      createdAt={resolutionCreatedAt}
      eventType={resolutionStatus}
      hideResolve
      showSettings={false}
      {...props}
    />
  )
}

CommentEvent.propTypes = {
  commentId: PropTypes.string.isRequired,
  resolutionCreatedAt: PropTypes.number.isRequired,
  resolutionStatus: PropTypes.oneOf(Object.values(EVENT_TYPE)).isRequired,
}

export default CommentEvent
