import React from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: inline-flex;
  font-weight: bold;
  font-size: 14px;
  color: ${prop('theme.colors.grey700')};
`

const CommentUser = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

CommentUser.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CommentUser
