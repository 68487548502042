import React from 'react'
import styled from 'styled-components'
import Div100vh from '../../molecules/Div100vh'
import OnboardingMobile from './OnboardingMobile'

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
`

const OnboardingProxy = () => (
  <Wrapper>
    <OnboardingMobile />
  </Wrapper>
)

export default OnboardingProxy
