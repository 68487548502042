export const NAMESPACE = 'USER'

export const SET_USER_LOGGED = 'SET_USER_LOGGED'
export const setUserLogged = () => ({
  type: SET_USER_LOGGED,
  payload: {},
})

export const SET_USER_NOT_LOGGED = 'SET_USER_NOT_LOGGED'
export const setUserNotLogged = () => ({
  type: SET_USER_NOT_LOGGED,
  payload: {},
})

export const SET_USER_DATA = 'SET_USER_DATA'
export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data,
})

export const SET_USER_ID = 'SET_USER_ID'
export const setUserId = userId => ({
  type: SET_USER_ID,
  payload: { userId },
})

export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA'
export const setRegistrationData = data => ({
  type: SET_REGISTRATION_DATA,
  payload: data,
})

export const GET_USER = `${NAMESPACE}/GET`
export const GET_USER_REQUEST = `${NAMESPACE}/GET/REQUEST`
export const GET_USER_REQUEST_SUCCESS = `${NAMESPACE}/GET/REQUEST_SUCCESS`
export const GET_USER_REQUEST_FAILURE = `${NAMESPACE}/GET/REQUEST_FAILURE`

export const getUserRequest = () => ({
  type: GET_USER_REQUEST,
  payload: {},
  meta: { thunk: GET_USER },
})

export const getUserRequestSuccess = (userData, thunk) => ({
  type: GET_USER_REQUEST_SUCCESS,
  payload: userData,
  meta: { thunk },
})

export const getUserRequestFailure = (e, thunk) => ({
  type: GET_USER_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_USER = `${NAMESPACE}/CREATE`
export const CREATE_USER_REQUEST = `${CREATE_USER}/REQUEST`
export const CREATE_USER_REQUEST_SUCCESS = `${CREATE_USER_REQUEST}_SUCCESS`
export const CREATE_USER_REQUEST_FAILURE = `${CREATE_USER_REQUEST}_FAILURE`

export const createUserRequest = ({
  identityId,
  email,
  name,
  picture,
  avatarBackgroundColor,
  role,
  plan,
  workspaceName,
  workspacePictureUrl,
  workspaceBackgroundColor,
  workspaceShareByEmailDomain,
  workspaceMembers,
  isGoogle,
  userRegistrationData,
}) => ({
  type: CREATE_USER_REQUEST,
  payload: {
    identityId,
    email,
    name,
    picture,
    avatarBackgroundColor,
    role,
    plan,
    workspaceName,
    workspacePictureUrl,
    workspaceBackgroundColor,
    workspaceShareByEmailDomain,
    workspaceMembers,
    isGoogle,
    userRegistrationData,
  },
  meta: { thunk: CREATE_USER },
})

export const createUserRequestSuccess = (data, thunk) => ({
  type: CREATE_USER_REQUEST_SUCCESS,
  payload: data,
  meta: { thunk },
})

export const createUserRequestFailure = (e, thunk) => ({
  type: CREATE_USER_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_USER = `${NAMESPACE}/UPDATE`
export const UPDATE_USER_REQUEST = `${UPDATE_USER}/REQUEST`
export const UPDATE_USER_REQUEST_SUCCESS = `${UPDATE_USER_REQUEST}_SUCCESS`
export const UPDATE_USER_REQUEST_FAILURE = `${UPDATE_USER_REQUEST}_FAILURE`

export const updateUserRequest = ({
  identityId,
  email,
  name,
  picture,
  role,
  avatarBackgroundColor,
  notifications,
}) => ({
  type: UPDATE_USER_REQUEST,
  payload: {
    identityId,
    email,
    name,
    picture,
    role,
    avatarBackgroundColor,
    notifications,
  },
  meta: { thunk: UPDATE_USER },
})

export const updateUserRequestSuccess = (data, thunk) => ({
  type: UPDATE_USER_REQUEST_SUCCESS,
  payload: data,
  meta: { thunk },
})

export const updateUserRequestFailure = (e, thunk) => ({
  type: UPDATE_USER_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
