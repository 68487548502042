import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import xor from 'lodash/xor'
import padStart from 'lodash/padStart'
import prop from 'lodash/fp/prop'
import styled, { css } from 'styled-components'
import Tippy from '@tippyjs/react'
import PlayIcon from 'mdi-react/PlayIcon'
import PauseIcon from 'mdi-react/PauseIcon'
import VolumeHighIcon from 'mdi-react/VolumeHighIcon'
import VolumeOffIcon from 'mdi-react/VolumeOffIcon'
import FastForward10Icon from 'mdi-react/FastForward10Icon'
import Rewind10Icon from 'mdi-react/Rewind10Icon'
import CommentArrowLeftOutlineIcon from 'mdi-react/CommentArrowLeftOutlineIcon'
import CommentArrowRightOutlineIcon from 'mdi-react/CommentArrowRightOutlineIcon'
import { Field, Toggle, Label } from '@zendeskgarden/react-forms'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
import PlaybackBar from './PlaybackBar'
import useLocalStorage from '../../hooks/useLocalStorage'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const formatDuration = durationInSeconds => {
  const minutes = padStart(Math.floor(durationInSeconds / 60), 2, '0')
  const seconds = padStart(
    Math.floor(durationInSeconds - minutes * 60, 10),
    2,
    '0'
  )
  return `${minutes}:${seconds}`
}

const Wrapper = styled(Flex)`
  height: auto;
  width: 100%;
  line-height: 8px;
  flex-direction: column;
  align-items: center;
`

const ActionIconBox = styled(Flex)`
  flex: 0 1 24px;
  outline: none;
  border-right: ${({ showBorderRight, theme }) =>
    showBorderRight === false ? 'none' : `1px solid ${theme.colors.grey200}`};
  padding: 0 8px 0 8px;
  align-items: center;
  justify-content: center;
`

const PlaybackRateIconBox = styled(ActionIconBox)`
  border-right: none;
`

const Glue = styled(Flex)`
  flex-grow: 2;
`

const TimeBox = styled(Flex)`
  flex: 0 0 100px;
  font-size: 12px;
  line-height: 24px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid ${prop('theme.colors.grey200')};
`

const cssCommonIcon = css`
  padding: 4px;
`

const StyledIconButton = styled(IconButton).attrs({
  isBasic: true,
})`
  ${cssCommonIcon}
`

const StyledToggle = styled(Toggle)``

const StyledLabel = styled(Label)`
  padding-top: 5px;
  padding-right: 16px;
  border-right: 1px solid ${prop('theme.colors.grey200')};
  font-weight: normal;

  svg {
    top: 7px !important;
  }

  &&::before {
    top: 5px !important;
  }
`

export const PLAYBACK_STATUSES = {
  playing: 'playing',
  paused: 'paused',
}

export const toggleStatus = currentValue =>
  xor(Object.keys(PLAYBACK_STATUSES), [currentValue])[0]

export const hasAudio = video => {
  if (!video) {
    return false
  }
  const _hasAudio =
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks && video.audioTracks.length)

  return _hasAudio
}

export const toggleAudio = video => {
  if (!video) {
    return null
  }
  if (video.volume === 0) {
    return 1
  }
  return 0
}

const Row = styled(Flex)`
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
`

const BottomRow = styled(Row)`
  margin-top: 24px;
`

const PlaybackRateButton = styled(Button).attrs({
  isPrimary: true,
  isBasic: true,
  size: 'small',
})`
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  padding: 0 8px;
  cursor: pointer;
`

const Tooltip = styled(Flex)`
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  line-height: 20px;
`

const ShortcutKey = styled(Flex)`
  min-width: 20px;
  height: 20px;
  color: ${prop('theme.colors.grey800')};
  background: ${prop('theme.colors.grey300')};
  box-shadow: 0 2px 0 ${prop('theme.colors.grey400')};
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-style: normal;
  font-size: 16px;
  font-weight: 800;
  line-height: 8px;
  margin-left: 8px;
`

const MiniShortcutKey = styled(ShortcutKey)`
  font-size: 12px;
  font-weight: normal;
  line-height: 8px;
`

const ArrowShortcutKey = styled(ShortcutKey)`
  font-size: 12px;
  font-weight: 700;
  line-height: 8px;
`

export const getParentNodeButton = node => {
  try {
    if (node.tagName.toLowerCase() === 'button') {
      return node
    }

    let currentNode = node
    for (let i = 0; i < 10; i += 1) {
      currentNode = currentNode.parentNode
      if (currentNode.tagName.toLowerCase() === 'button') {
        break
      }
    }

    return currentNode
  } catch (e) {
    return { blur: () => {} }
  }
}

export const getNextPlaybackRate = playbackRate => {
  if (playbackRate === 1) {
    return 1.5
  }
  if (playbackRate === 1.5) {
    return 2
  }
  if (playbackRate === 2) {
    return 1
  }
  return 1
}

const Player = ({
  videoLengthSecs,
  currentPositionSecs,
  onPlay,
  onPause,
  onUpdatePosition,
  onTickersCrossed,
  onTickerClick,
  onPreviousComment,
  onNextComment,
  onRewind,
  onForward,
  videoStatus,
  sprites,
  tickers,
  videoRef,
  pageEvents,
  playbackRateTrigger,
  toggleAudioTrigger,
  onSkipComments,
  onPlaybackRate,
  ...props
}) => {
  const [savedPlaybackRate, savePlaybackRate] = useLocalStorage(
    'player-playback-rate',
    1
  )

  useEffect(() => {
    if (savedPlaybackRate) {
      onPlaybackRate(savedPlaybackRate)
    }
  }, [])

  useEffect(() => {
    if (videoRef) {
      // eslint-disable-next-line no-param-reassign
      videoRef.playbackRate = savedPlaybackRate
    }
  }, [videoRef])
  const [playbackRate, setPlaybackRate] = useState(savedPlaybackRate)
  const onPlaybackRateClick = () => {
    const newPlaybackRate = getNextPlaybackRate(playbackRate)
    setPlaybackRate(newPlaybackRate)
    // eslint-disable-next-line no-param-reassign
    videoRef.playbackRate = newPlaybackRate
    savePlaybackRate(newPlaybackRate)
    onPlaybackRate(newPlaybackRate)
  }

  useEffect(() => {
    if (playbackRateTrigger) {
      onPlaybackRateClick()
    }
  }, [playbackRateTrigger])

  const [savedSkipCommentEnabled, saveSkipCommentEnabled] = useLocalStorage(
    'player-skip-comments-enabled',
    true
  )
  useEffect(() => {
    onSkipComments(savedSkipCommentEnabled)
  }, [])
  const [isSkipCommentEnabled, setSkipCommentEnabled] = useState(
    savedSkipCommentEnabled
  )
  useEffect(() => {
    saveSkipCommentEnabled(isSkipCommentEnabled)
  }, [isSkipCommentEnabled])

  const [videoVolume, setVideoVolume] = useState(1)
  useEffect(() => {
    if (videoRef && hasAudio(videoRef)) {
      setVideoVolume(1)
      // eslint-disable-next-line no-param-reassign
      videoRef.volume = 1
    }
  }, [videoRef])

  const switchAudio = () => setVideoVolume(toggleAudio(videoRef))
  useEffect(() => {
    if (toggleAudioTrigger) {
      switchAudio()
    }
  }, [toggleAudioTrigger])

  const onPlayHandler = useCallback(() => {
    onPlay()
  }, [videoRef, onPlay])

  const onPauseHandler = () => {
    onPause()
  }

  useEffect(() => {
    if (currentPositionSecs >= videoLengthSecs) {
      onPauseHandler()
    }
  }, [currentPositionSecs])

  useEffect(() => {
    if (videoRef) {
      // eslint-disable-next-line no-param-reassign
      videoRef.volume = videoVolume
    }
  }, [videoRef, videoVolume])

  const areCommentButtonsVisible = tickers.length > 1
  const isSkipCommentsVisible = tickers.length > 0

  return (
    <Wrapper {...props} data-test="wrapper">
      <Row>
        <PlaybackBar
          videoLengthSecs={videoLengthSecs}
          onPlay={onPlay}
          onPause={onPause}
          onUpdatePosition={onUpdatePosition}
          onTickerClick={onTickerClick}
          sprites={sprites}
          tickers={tickers}
          pageEvents={pageEvents}
          videoRef={videoRef}
          onCommentsCrossed={onTickersCrossed}
        />
      </Row>
      <BottomRow>
        <ActionIconBox>
          {videoStatus === PLAYBACK_STATUSES.paused && (
            <Tippy
              content={
                <Tooltip>
                  Play <MiniShortcutKey>space</MiniShortcutKey>
                </Tooltip>
              }
              arrow={false}
              touch={false}
              placement="top"
            >
              <span>
                <StyledIconButton
                  icon={PlayIcon}
                  iconProps={{
                    size: 40,
                  }}
                  alt="play"
                  onClick={() => onPlayHandler()}
                />
              </span>
            </Tippy>
          )}
          {videoStatus === PLAYBACK_STATUSES.playing && (
            <Tippy
              content={
                <Tooltip>
                  Pause <MiniShortcutKey>space</MiniShortcutKey>
                </Tooltip>
              }
              arrow={false}
              touch={false}
              placement="top"
            >
              <span>
                <StyledIconButton
                  icon={PauseIcon}
                  iconProps={{
                    size: 40,
                  }}
                  alt="pause"
                  onClick={() => onPauseHandler()}
                />
              </span>
            </Tippy>
          )}
        </ActionIconBox>
        <ActionIconBox>
          <Tippy
            content={
              <Tooltip>
                Rewind <ShortcutKey>,</ShortcutKey>
              </Tooltip>
            }
            arrow={false}
            touch={false}
            placement="top"
          >
            <span>
              <StyledIconButton
                icon={Rewind10Icon}
                alt="rewind-10"
                onClick={e => {
                  onRewind()
                  const buttonNode = getParentNodeButton(e.target)
                  if (buttonNode.blur) buttonNode.blur()
                }}
              />
            </span>
          </Tippy>
        </ActionIconBox>
        <ActionIconBox showBorderRight={areCommentButtonsVisible}>
          <Tippy
            content={
              <Tooltip>
                Forward <ShortcutKey>.</ShortcutKey>
              </Tooltip>
            }
            arrow={false}
            touch={false}
            placement="top"
          >
            <span>
              <StyledIconButton
                icon={FastForward10Icon}
                alt="fast-forward-10"
                onClick={e => {
                  onForward()
                  const buttonNode = getParentNodeButton(e.target)
                  if (buttonNode.blur) buttonNode.blur()
                }}
              />
            </span>
          </Tippy>
        </ActionIconBox>
        {areCommentButtonsVisible && (
          <>
            <ActionIconBox>
              <Tippy
                content={
                  <Tooltip>
                    Previous comment <ArrowShortcutKey>←</ArrowShortcutKey>
                  </Tooltip>
                }
                arrow={false}
                touch={false}
                placement="top"
              >
                <span>
                  <StyledIconButton
                    icon={CommentArrowLeftOutlineIcon}
                    alt="previous-comment"
                    onClick={e => {
                      onPreviousComment()
                      const buttonNode = getParentNodeButton(e.target)
                      if (buttonNode.blur) buttonNode.blur()
                    }}
                  />
                </span>
              </Tippy>
            </ActionIconBox>
            <ActionIconBox style={{ borderRight: 'none' }}>
              <Tippy
                content={
                  <Tooltip>
                    Next comment <ArrowShortcutKey>→</ArrowShortcutKey>
                  </Tooltip>
                }
                arrow={false}
                touch={false}
                placement="top"
              >
                <span>
                  <StyledIconButton
                    icon={CommentArrowRightOutlineIcon}
                    alt="next-comment"
                    onClick={e => {
                      onNextComment()
                      const buttonNode = getParentNodeButton(e.target)
                      if (buttonNode.blur) buttonNode.blur()
                    }}
                  />
                </span>
              </Tippy>
            </ActionIconBox>
          </>
        )}
        <Glue />
        {isSkipCommentsVisible && (
          <Field>
            <StyledToggle
              checked={isSkipCommentEnabled}
              onChange={e => {
                setSkipCommentEnabled(!isSkipCommentEnabled)
                onSkipComments(!isSkipCommentEnabled)
                if (e.target?.blur) e.target.blur()
              }}
            >
              <StyledLabel>Skip comments</StyledLabel>
            </StyledToggle>
          </Field>
        )}
        <TimeBox>
          {formatDuration(currentPositionSecs)} /{' '}
          {formatDuration(videoLengthSecs)}
        </TimeBox>
        {hasAudio(videoRef) && (
          <ActionIconBox>
            <Tippy
              content={
                <Tooltip>
                  {videoVolume === 0 ? 'Unmute' : 'Mute'}{' '}
                  <MiniShortcutKey>m</MiniShortcutKey>
                </Tooltip>
              }
              arrow={false}
              touch={false}
              placement="top"
            >
              <span>
                <StyledIconButton
                  icon={videoVolume === 0 ? VolumeOffIcon : VolumeHighIcon}
                  onClick={e => {
                    setVideoVolume(toggleAudio(videoRef))
                    const buttonNode = getParentNodeButton(e.target)
                    if (buttonNode.blur) buttonNode.blur()
                  }}
                />
              </span>
            </Tippy>
          </ActionIconBox>
        )}
        <PlaybackRateIconBox>
          <Tippy
            content={
              <Tooltip>
                Playback speed
                <ArrowShortcutKey>↑</ArrowShortcutKey>
              </Tooltip>
            }
            arrow={false}
            touch={false}
            placement="top"
          >
            <span>
              <PlaybackRateButton
                onClick={e => {
                  onPlaybackRateClick()
                  const buttonNode = getParentNodeButton(e.target)
                  if (buttonNode.blur) buttonNode.blur()
                }}
              >
                {playbackRate}x
              </PlaybackRateButton>
            </span>
          </Tippy>
        </PlaybackRateIconBox>
      </BottomRow>
    </Wrapper>
  )
}
const SpriteShape = PropTypes.shape({
  order: PropTypes.string,
  url: PropTypes.string,
})

Player.propTypes = {
  videoStatus: PropTypes.string,
  videoLengthSecs: PropTypes.number,
  currentPositionSecs: PropTypes.number,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onUpdatePosition: PropTypes.func,
  onTickersCrossed: PropTypes.func,
  onTickerClick: PropTypes.func,
  onPreviousComment: PropTypes.func,
  onNextComment: PropTypes.func,
  onRewind: PropTypes.func,
  onForward: PropTypes.func,
  onSkipComments: PropTypes.func,
  onPlaybackRate: PropTypes.func,
  sprites: PropTypes.arrayOf(SpriteShape),
  tickers: PropTypes.arrayOf(PropTypes.shape({ startTime: PropTypes.number })),
  pageEvents: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, videoTime: PropTypes.number })
  ),
  videoRef: PropTypes.shape({
    playbackRate: PropTypes.number,
    volume: PropTypes.number,
  }),
  playbackRateTrigger: PropTypes.string,
  toggleAudioTrigger: PropTypes.string,
}

Player.defaultProps = {
  videoStatus: PLAYBACK_STATUSES.paused,
  videoLengthSecs: 0,
  currentPositionSecs: 0,
  onPlay: () => {},
  onPause: () => {},
  onUpdatePosition: () => {},
  onTickersCrossed: () => {},
  onTickerClick: () => {},
  onPreviousComment: () => {},
  onNextComment: () => {},
  onRewind: () => {},
  onForward: () => {},
  onSkipComments: () => {},
  onPlaybackRate: () => {},
  sprites: [],
  tickers: [],
  pageEvents: [],
  videoRef: null,
  playbackRateTrigger: undefined,
  toggleAudioTrigger: undefined,
}

Player.whyDidYouRender = true

export default Player
