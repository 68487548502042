import { useEffect } from 'react'

const beforeUnloadCallback = e => {
  e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = ''
}

const useBeforeUnload = isEnabled => {
  useEffect(() => {
    if (isEnabled) {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
      window.addEventListener('beforeunload', beforeUnloadCallback)
    }

    if (!isEnabled) {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [isEnabled])
}

export default useBeforeUnload
