import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { XXL } from '@zendeskgarden/react-typography'
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs'
import { Tooltip } from '@zendeskgarden/react-tooltips'
import OrganizationMembers from '../../molecules/OrganizationMembers'
import Flex from '../../atoms/Flex'
import WorkspaceLogo from '../../atoms/WorkspaceLogo'
import Settings from './Settings'

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
`

const WorkspaceName = styled(XXL)`
  margin-top: 8px;
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-top: 32px;
  overflow: visible;
`

const StyledTabList = styled(TabList)`
  text-align: center;
  width: 100%;
`

const StyledTabPanel = styled(TabPanel)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
`

const StyledOrganizationMembers = styled(OrganizationMembers)`
  width: 500px;
`

const WorkspaceSettings = ({
  workspaceName,
  workspacePictureUrl,
  workspaceBackgroundColor,
  onWorkspaceUpdate,
  onUpdateWorkspaceEmailDomains,
  onWorkspaceTitleUpdate,
  onWorkspaceDelete,
  onBack,
  isWorkspaceTitleRequestLoading,
  isWorkspaceTitleRequestRejected,
  isCurrentUserOrganizationAdmin,
  availableWorkspaceEmailDomains,
  workspaceEmailDomains,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState('tab-1')
  const [pictureUrl, setPictureUrl] = useState(workspacePictureUrl)
  const [backgroundColor, setBackgroundColor] = useState(
    workspaceBackgroundColor
  )

  return (
    <Wrapper {...props}>
      <WorkspaceLogo
        size="large"
        imgUrl={pictureUrl}
        name={workspaceName}
        backgroundColor={backgroundColor}
      />
      <WorkspaceName>{workspaceName}</WorkspaceName>

      <StyledTabs selectedItem={selectedTab} onChange={setSelectedTab}>
        <StyledTabList>
          <Tab item="tab-1">Members</Tab>
          <Tab item="tab-2" disabled={!isCurrentUserOrganizationAdmin}>
            {isCurrentUserOrganizationAdmin && 'Settings'}
            {!isCurrentUserOrganizationAdmin && (
              <Tooltip
                content="Admins only"
                zIndex="1000"
                appendToNode={document.querySelector('body')}
              >
                <span>Settings</span>
              </Tooltip>
            )}
          </Tab>
          {/* <Tab item="tab-3">Upgrade</Tab> */}
        </StyledTabList>
        <StyledTabPanel item="tab-1">
          <StyledOrganizationMembers />
        </StyledTabPanel>
        <StyledTabPanel item="tab-2">
          <Settings
            workspaceName={workspaceName}
            workspacePictureUrl={pictureUrl}
            workspaceBackgroundColor={backgroundColor}
            onWorkspaceTitleUpdate={onWorkspaceTitleUpdate}
            onUpdateWorkspaceEmailDomains={onUpdateWorkspaceEmailDomains}
            onWorkspaceDelete={onWorkspaceDelete}
            onBack={onBack}
            isWorkspaceTitleRequestLoading={isWorkspaceTitleRequestLoading}
            isWorkspaceTitleRequestRejected={isWorkspaceTitleRequestRejected}
            isCurrentUserOrganizationAdmin={isCurrentUserOrganizationAdmin}
            availableWorkspaceEmailDomains={availableWorkspaceEmailDomains}
            workspaceEmailDomains={workspaceEmailDomains}
            onPictureChange={_pictureUrl => {
              setPictureUrl(_pictureUrl)
              onWorkspaceUpdate({
                title: workspaceName,
                pictureUrl: _pictureUrl,
                backgroundColor: '',
              })
            }}
            onBackgroundColorSelect={({
              backgroundColor: _backgroundColor,
            }) => {
              setBackgroundColor(_backgroundColor)
              setPictureUrl('')
              onWorkspaceUpdate({
                title: workspaceName,
                pictureUrl: '',
                backgroundColor: _backgroundColor,
              })
            }}
          />
        </StyledTabPanel>
        {/* <StyledTabPanel item="tab-3"></StyledTabPanel> */}
      </StyledTabs>
    </Wrapper>
  )
}

WorkspaceSettings.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  workspacePictureUrl: PropTypes.string,
  workspaceBackgroundColor: PropTypes.string,
  onWorkspaceUpdate: PropTypes.func,
  onWorkspaceTitleUpdate: PropTypes.func.isRequired,
  onUpdateWorkspaceEmailDomains: PropTypes.func.isRequired,
  onWorkspaceDelete: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isWorkspaceTitleRequestLoading: PropTypes.bool.isRequired,
  isWorkspaceTitleRequestRejected: PropTypes.bool.isRequired,
  isCurrentUserOrganizationAdmin: PropTypes.bool.isRequired,
  availableWorkspaceEmailDomains: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  workspaceEmailDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
}

WorkspaceSettings.defaultProps = {
  workspacePictureUrl: '',
  workspaceBackgroundColor: '',
  onWorkspaceUpdate: () => {},
}

export default WorkspaceSettings
