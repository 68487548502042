/* eslint-disable import/prefer-default-export */
/**
 * Google Sign-In JavaScript client reference
 * https://developers.google.com/identity/sign-in/web/reference
 */
import Auth from '@aws-amplify/auth'
import Cache from '@aws-amplify/cache'
import jwt from 'jsonwebtoken'
import pick from 'lodash/pick'
import { getUnixTime } from 'date-fns'
import * as Sentry from '@sentry/react'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import appConfig from '../config'
import { isElectron } from '../lib/platform'
import { setUserTrackingInfo } from './tracking'

// Get/Set Local Storage
const STORAGE_GOOGLE_LOGIN = 'iterspace-is-google-login'
export const isGoogleLogin = () =>
  localStorage.getItem(STORAGE_GOOGLE_LOGIN) === 'true'

export const setGoogleLogin = () =>
  localStorage.setItem(STORAGE_GOOGLE_LOGIN, 'true')

export const setNotGoogleLogin = () =>
  localStorage.setItem(STORAGE_GOOGLE_LOGIN, 'false')

const STORAGE_IS_GOOGLE_LOGIN_AVAILABLE = 'iterspace-is-google-login-available'

export const setIsGoogleLoginAvailable = isAvailable =>
  localStorage.setItem(
    STORAGE_IS_GOOGLE_LOGIN_AVAILABLE,
    isAvailable.toString()
  )

export const getIsGoogleLoginAvailable = () =>
  localStorage.getItem(STORAGE_IS_GOOGLE_LOGIN_AVAILABLE) === 'true'

const getCognitoUser = email => {
  const poolData = {
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  }
  const userPool = new CognitoUserPool(poolData)
  const userData = {
    Username: email,
    Pool: userPool,
  }
  return new CognitoUser(userData)
}

export const getIdentityId = async () => {
  try {
    const currentCredentials = await Auth.currentCredentials()
    const credentials = Auth.essentialCredentials(currentCredentials)
    return credentials.identityId
  } catch (err) {
    console.error('getIdentityId error', err)
    return null
  }
}

const refreshCognitoSession = (cognitoUser, refreshToken) =>
  new Promise((resolve, reject) => {
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      if (err) {
        console.error('refreshSession error', err, session)
        reject(err)
        return
      }
      resolve(session)
    })
  })

const refreshJWT = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const currentSession = await Auth.currentSession()
  console.log('Refreshing JWT')
  return refreshCognitoSession(cognitoUser, currentSession.refreshToken)
}

export const getJWT = async () => {
  let _jwt

  if (isGoogleLogin()) {
    const federatedInfo = await Auth.currentAuthenticatedUser()

    if (federatedInfo) {
      const { token } = federatedInfo
      return token
    }
  }

  try {
    const session = await Auth.currentSession()
    let accessToken = session.getAccessToken()
    const nowUnixTimestamp = getUnixTime(new Date())
    if (accessToken.payload.exp <= nowUnixTimestamp) {
      const newSession = await refreshJWT()
      accessToken = newSession.getAccessToken()
    }
    _jwt = accessToken.getJwtToken()
    if (_jwt) {
      return _jwt
    }
    // const result = await Auth.currentCredentials()
    // _jwt = get(result, 'params.Logins')['accounts.google.com']
  } catch (err) {
    const federatedInfo = await Auth.currentAuthenticatedUser()

    if (federatedInfo) {
      const { token } = federatedInfo
      return token
    }

    console.warn('get JWT exception', err)
  }

  let federatedInfo = await Cache.getItem('federatedInfo')
  if (!federatedInfo) {
    federatedInfo = JSON.parse(
      localStorage.getItem('aws-amplify-federatedInfo')
    )
  }
  const { token } = federatedInfo

  return token
}

export const getUserAttributes = async () => {
  const jwtToken = await getJWT()
  const tokenContent = jwt.decode(jwtToken)

  const tokenKeys = ['picture', 'name', 'email']

  const identityId = await getIdentityId()
  setUserTrackingInfo(identityId)

  const _userData = pick(tokenContent, tokenKeys)

  return {
    ..._userData,
    identityId,
  }
}

export const confirmPassword = (email, verificationCode, newPassword) =>
  new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email)

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve()
      },
      onFailure(err) {
        reject(err)
      },
    })
  })

export const changePassword = (oldPassword, newPassword) =>
  Auth.currentAuthenticatedUser().then(user => {
    return Auth.changePassword(user, oldPassword, newPassword)
  })

export const forgotPassword = email =>
  new Promise((resolve, reject) => {
    // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then(data => {
        console.log(data)
        resolve(data)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })

    // cognitoUser.forgotPassword({
    //   onSuccess(data) {
    //     console.log('>>>> cognitoUser forgotPassword success', data)
    //     resolve(data)
    //   },
    //   onFailure(err) {
    //     console.log('>>>> cognitoUser forgotPassword failure', err)
    //     reject(err)
    //   },
    // })
  })

export const resendConfirmationEmail = email =>
  new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email)

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    })
  })

export const isGaPresent = () => {
  return !!window.gAuth
}

export const createGaScript = () => {
  return new Promise(resolve => {
    setIsGoogleLoginAvailable(true)
    resolve()
  })
}

export const isGoogleSignedIn = () =>
  new Promise(resolve => {
    createGaScript()
      .then(() => {
        Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then(() => resolve(true))
          .catch(() => resolve(false))
      })
      .catch(() => resolve(false))
  })

const getGoogleProfile = async token => {
  const base64Payload = token.split('.')[1]
  const payload = Buffer.from(base64Payload, 'base64')
  const userProfile = JSON.parse(payload)
  return userProfile
}

const getAWSCredentials = async (googleUser, cb) => {
  /* eslint-disable-next-line */
  const { token } = googleUser
  const profile = await getGoogleProfile(token)

  const { exp } = profile

  const user = {
    email: profile.email,
    name: `${profile.given_name} ${profile.family_name}`,
  }

  Auth.federatedSignIn('google', { token, expires_at: exp }, user).then(() => {
    cb({ isGoogle: true })
  })
}

export const googleSignIn = (tokenResponse, cb, errorCb) => {
  const { credential } = tokenResponse
  try {
    // ga.requestAccessToken()
    getAWSCredentials(
      {
        token: credential,
      },
      cb
    ).then(() => {
      setGoogleLogin()
    })
  } catch (e) {
    console.error(e)
    if (errorCb) {
      errorCb()
    }
    Sentry.captureMessage(e?.error ? e.error : e)
  }
}
