import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import PlusCircleIcon from 'mdi-react/PlusCircleIcon'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
import WorkspaceUpsellImage from '../../../assets/workspace_upsell_image.svg'

const Wrapper = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${prop('theme.colors.yellow')};
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  flex-direction: row;
`

const Left = styled(Flex)``
const Right = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Title = styled(Flex)`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
`
const Description = styled(Flex)`
  font-size: 14px;
  margin-bottom: 8px;
`
const CallToAction = styled(Flex)``

const Image = styled.img`
  width: 90px;
  margin-right: 16px;
`

const CreateWorkspaceCommunication = ({ onCreateClick, ...props }) => (
  <Wrapper {...props}>
    <Left>
      <Image src={WorkspaceUpsellImage} alt="image" />
    </Left>
    <Right>
      <Title>Want to share with specific people?</Title>
      <Description>
        Create a Team Workspace to invite and set restrictions. You’ll have the
        power to control who can view, edit, or collaborate.
      </Description>
      <CallToAction>
        <IconButton
          icon={PlusCircleIcon}
          isSecondary
          size="medium"
          onClick={onCreateClick}
        >
          Create Team Workspace
        </IconButton>
      </CallToAction>
    </Right>
  </Wrapper>
)

CreateWorkspaceCommunication.propTypes = {
  onCreateClick: PropTypes.func.isRequired,
}

export default CreateWorkspaceCommunication
