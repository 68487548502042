import get from 'lodash/get'
import chunk from 'lodash/chunk'
import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import gql from 'graphql-tag'
import {
  listSessions,
  getSession as getSessionGql,
} from '../../graphql/queries'
import {
  onCreateSession,
  onUpdateSession,
  onDeleteSession,
} from '../../graphql/subscriptions'
import {
  createSession as createSessionGql,
  deleteSession as deleteSessionGql,
  updateSession as updateSessionGql,
  updateSessionSharing as updateSessionSharingGql,
  moveSession as moveSessionGql,
} from '../../graphql/mutations'
import {
  CREATE_NEW_SESSION_REQUEST,
  createNewSessionRequestFailure,
  createNewSessionRequestSuccess,
  DELETE_SESSION_REQUEST,
  deleteSessionRequestFailure,
  deleteSessionRequestSuccess,
  GET_SESSION_REQUEST,
  getSessionRequest,
  getSessionRequestFailure,
  getSessionRequestSuccess,
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SHARING_REQUEST_SUCCESS,
  UPDATE_SESSION_SHARING_REQUEST,
  UPDATE_SESSIONS_REQUEST,
  updateSessionRequestFailure,
  updateSessionRequestSuccess,
  updateSessionSharingRequestFailure,
  updateSessionSharingRequestSuccess,
  updateSessionsRequest,
  updateSessionsRequestFailure,
  updateSessionsRequestSuccess,
  upsertSessions,
  MOVE_SESSION_REQUEST,
  MOVE_SESSION_REQUEST_SUCCESS,
  moveSessionRequestSuccess,
  moveSessionRequestFailure,
  CREATE_SESSION_RESOURCES_REQUEST,
  createSessionResourcesRequestSuccess,
  createSessionResourcesRequestFailure,
  updateSessionRequest,
} from './sessions.actions'
import { getSessionCommentsRequest } from '../comments/comments.actions'
import { callCreateComment } from '../comments/comments.sagas'
import { callCreatePageEvents } from '../pageEvents/pageEvents.sagas'
import { LOGIN_REQUEST_SUCCESS } from '../actions'
import fromProjects from '../projects/projects.selectors'
import fromSessions from './sessions.selectors'
import fromUser from '../user/user.selectors'
import { SET_CURRENT_PROJECT } from '../projects/projects.actions'
import { activityBasedAction } from '../utils.sagas'

export function* fetchSessions({ apolloClient, projectId }) {
  try {
    let _projectId = projectId
    if (!_projectId) {
      _projectId = yield select(fromProjects.getCurrentProjectId)
    }
    if (!_projectId) {
      yield put(upsertSessions([]))
      return
    }

    const sessions = yield call(apolloClient.query, {
      query: gql(listSessions),
      fetchPolicy: 'no-cache',
      variables: {
        projectId: _projectId,
      },
    })

    const items = get(sessions, 'data.listSessions.items')
    yield put(upsertSessions(items, _projectId))
    // TODO: update projects coming from shared sessions
  } catch (e) {
    yield put(upsertSessions([], projectId))
    console.error(e)
  }
}

function* dispatchUpdateSessionsRequest() {
  const isLogged = yield select(fromUser.isLogged)
  if (!isLogged) {
    return
  }
  yield put(updateSessionsRequest())
}

function* updateSessions({ apolloClient }, { meta: { thunk } }) {
  try {
    yield call(fetchSessions, { apolloClient })
    yield put(updateSessionsRequestSuccess({}, thunk))
  } catch (e) {
    console.error(e)
    yield put(updateSessionsRequestFailure({}, thunk))
  }
}

function* watchForSession({ apolloClient }) {
  try {
    yield call(fetchSessions, { apolloClient })

    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateSession),
        })
        .subscribe({
          next: () => {
            emitter('new-session-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateSession),
        })
        .subscribe({
          next: () => {
            emitter('update-session-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const deleteSubscription = apolloClient
        .subscribe({
          query: gql(onDeleteSession),
        })
        .subscribe({
          next: () => {
            emitter('delete-session-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        updateSubscription.unsubscribe()
        deleteSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      yield call(fetchSessions, { apolloClient })
    }
  } catch (e) {
    console.error(e)
  }
}

function* createSession({ apolloClient }, action) {
  const {
    meta: { thunk },
  } = action
  try {
    const currentProjectId = yield select(fromProjects.getCurrentProjectId)
    if (currentProjectId) {
      const data = yield call(apolloClient.mutate, {
        mutation: gql(createSessionGql),
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            projectId: currentProjectId,
          },
        },
      })
      if (data) {
        yield put(createNewSessionRequestSuccess(data, thunk))
        yield put(updateSessionsRequest())
      } else {
        yield put(
          createNewSessionRequestFailure(
            { error: 'Failed to create a new session' },
            thunk
          )
        )
      }
    } else {
      yield put(
        createNewSessionRequestFailure(
          { error: 'Failed to create a new session' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(createNewSessionRequestFailure(e, thunk))
  }
}

function* updateSessionSharing(
  { apolloClient },
  { payload: { sessionId, isPublic, areCommentsAllowed }, meta: { thunk } }
) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(updateSessionSharingGql),
      fetchPolicy: 'no-cache',
      variables: {
        sessionId,
        isPublic,
        areCommentsAllowed,
      },
    })
    yield put(
      updateSessionSharingRequestSuccess(
        {
          sessionId,
          isPublic,
          areCommentsAllowed,
        },
        thunk
      )
    )
  } catch (e) {
    console.error(e)
    yield put(updateSessionSharingRequestFailure(e, thunk))
  }
}

function* getSession(
  { apolloClient },
  { payload: { sessionId }, meta: { thunk } }
) {
  try {
    const sanitizedSessionId = sessionId.startsWith('session:')
      ? sessionId.replace('session:', '')
      : sessionId
    const data = yield call(apolloClient.query, {
      query: gql(getSessionGql),
      fetchPolicy: 'network-only',
      variables: {
        sessionId: sanitizedSessionId,
      },
    })
    yield put(getSessionRequestSuccess(data.data.getSession, thunk))
  } catch (e) {
    console.error(e)
    yield put(getSessionRequestFailure(e, thunk))
  }
}

function* updateSession({ apolloClient }, { payload, meta: { thunk } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(updateSessionGql),
      variables: {
        input: payload,
      },
    })

    yield put(updateSessionRequestSuccess({}, thunk))
  } catch (e) {
    yield put(updateSessionRequestFailure(e, thunk))
    console.error(e)
  }
}

function* deleteSession(
  { apolloClient },
  { payload: { sessionId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(deleteSessionGql),
      fetchPolicy: 'no-cache',
      variables: {
        sessionId,
      },
    })
    yield put(deleteSessionRequestSuccess({ ...data, sessionId }, thunk))
  } catch (e) {
    console.error(e)
    yield put(deleteSessionRequestFailure(e, thunk))
  }
}

function* updateCurrentSession() {
  const currentSessionId = yield select(fromSessions.getCurrentSessionId)
  if (currentSessionId) {
    yield put(getSessionRequest(currentSessionId))
  }
}

function* moveSessionRequest(
  { apolloClient },
  { payload: { sessionId, destinationProjectId }, meta: { thunk } }
) {
  try {
    const response = yield call(apolloClient.mutate, {
      mutation: gql(moveSessionGql),
      variables: {
        sessionId,
        destinationProjectId,
      },
    })
    const _sessionId = response?.data?.moveSession?.sessionId
    const oldProjectId = response?.data?.moveSession?.oldProjectId
    const newProjectId = response?.data?.moveSession?.newProjectId

    yield put(
      moveSessionRequestSuccess(
        { sessionId: _sessionId, oldProjectId, newProjectId },
        thunk
      )
    )
  } catch (e) {
    yield put(moveSessionRequestFailure(e, thunk))
    console.error(e)
  }
}

function* moveSessionRequestSuccessSaga(
  { apolloClient },
  { payload: { oldProjectId, newProjectId } }
) {
  yield call(fetchSessions, { apolloClient, projectId: oldProjectId })
  yield call(fetchSessions, { apolloClient, projectId: newProjectId })
}

function* startSessionsFork({ apolloClient }) {
  yield fork(watchForSession, { apolloClient })
  yield fork(activityBasedAction, fetchSessions, 26000, { apolloClient })
}

function* createSessionResourcesSaga(
  { apolloClient },
  {
    payload: { sessionId, comments, pageEvents, deviceMetaInfo },
    meta: { thunk },
  }
) {
  try {
    for (let i = 0; i < comments.length; i += 1) {
      const comment = comments[i]

      const { text, videoPoint, videoRectangle, videoPositionSecs } = comment
      yield call(
        callCreateComment,
        { sessionId, text, videoPoint, videoRectangle, videoPositionSecs },
        apolloClient
      )
    }

    yield put(getSessionCommentsRequest(sessionId))

    const pageEventsChunks = chunk(pageEvents.map(JSON.stringify), 50)

    for (let i = 0; i < pageEventsChunks.length; i += 1) {
      const pageEventsChunk = pageEventsChunks[i]
      yield call(
        callCreatePageEvents,
        { sessionId, events: pageEventsChunk },
        apolloClient
      )
    }

    yield put(updateSessionRequest({ sessionId, deviceMetaInfo }))

    yield put(createSessionResourcesRequestSuccess(thunk))
  } catch (e) {
    yield put(createSessionResourcesRequestFailure(e, thunk))
    console.error(e)
  }
}

export default function* sessionSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startSessionsFork, { apolloClient }),
    yield takeLatest(UPDATE_SESSIONS_REQUEST, updateSessions, { apolloClient }),
    yield takeLatest(GET_SESSION_REQUEST, getSession, { apolloClient }),
    yield takeLatest(DELETE_SESSION_REQUEST, deleteSession, { apolloClient }),
    yield takeLatest(UPDATE_SESSION_REQUEST, updateSession, { apolloClient }),
    yield takeLatest(CREATE_NEW_SESSION_REQUEST, createSession, {
      apolloClient,
    }),
    yield takeLatest(UPDATE_SESSION_SHARING_REQUEST, updateSessionSharing, {
      apolloClient,
    }),
    yield takeLatest(
      UPDATE_SESSION_SHARING_REQUEST_SUCCESS,
      updateCurrentSession
    ),
    yield takeLatest(SET_CURRENT_PROJECT, dispatchUpdateSessionsRequest),
    yield takeLatest(MOVE_SESSION_REQUEST, moveSessionRequest, {
      apolloClient,
    }),
    yield takeLatest(
      MOVE_SESSION_REQUEST_SUCCESS,
      moveSessionRequestSuccessSaga,
      {
        apolloClient,
      }
    ),
    yield takeLatest(
      CREATE_SESSION_RESOURCES_REQUEST,
      createSessionResourcesSaga,
      {
        apolloClient,
      }
    ),
  ])
}
