import { useSelector } from 'react-redux'
import fromSelectors from '../selectors'

const useSessionVideoUpload = sessionId => {
  const videoUploadProgress = useSelector(fromSelectors.getUploadProgress)
  const uploadSessionId = useSelector(fromSelectors.getUploadSessionId)

  return {
    isUploadingVideo:
      uploadSessionId === sessionId && videoUploadProgress < 100,
    videoUploadProgress,
  }
}

export default useSessionVideoUpload
