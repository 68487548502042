/* eslint-disable import/prefer-default-export */
import queryString from 'query-string'

export const getRecordingUrlWithVideoPosition = (
  recordingUrl,
  userRegistrationData
) => {
  const parsedUrl = queryString.parseUrl(recordingUrl)

  const videoPercentagePosition = userRegistrationData?.point?.percentage

  parsedUrl.query = {
    ...parsedUrl.query,
    t: userRegistrationData?.time,
    m: `${videoPercentagePosition?.x},${videoPercentagePosition?.y}`,
  }
  const recordingUrlWithMarkerPosition = queryString.stringifyUrl(parsedUrl)
  return recordingUrlWithMarkerPosition
}
