import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PlayIcon from 'mdi-react/PlayIcon'
import IconButton from '../../molecules/IconButton'
import Flex from '../../atoms/Flex'
import { isMobile } from '../../../lib/platform'

const Wrapper = styled(Flex)`
  background-color: rgba(255, 255, 255, 0.2);
  height: auto;
  display: inline-flex;
  padding: 16px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 200px;
`

const StyledIconButton = styled(IconButton)`
  padding: 4px;
  border-radius: 6px;
  min-width: 48px;
  min-height: 48px;

  && {
    width: 48px;
    height: 48px;
  }
`

const Text = styled(Flex)`
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-left: 16px;
`

const clickLabel = isMobile() ? 'Tap' : 'Click'

const VideoOverlay = ({ onPlay, showText, ...props }) => {
  return (
    <Wrapper {...props}>
      <StyledIconButton
        icon={PlayIcon}
        isSecondary
        iconProps={{
          size: 32,
        }}
        alt="play"
        onClick={onPlay}
      />
      {showText && <Text>{clickLabel} on a comment or press play</Text>}
    </Wrapper>
  )
}

VideoOverlay.propTypes = {
  onPlay: PropTypes.func.isRequired,
  showText: PropTypes.bool.isRequired,
}

export default VideoOverlay
