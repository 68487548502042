import get from 'lodash/get'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import gql from 'graphql-tag'
import {
  listProjects,
  getProject as getProjectGql,
  listSessions,
} from '../../graphql/queries'
import {
  createProject as createProjectGql,
  deleteProject as deleteProjectGql,
  updateProject as updateProjectGql,
} from '../../graphql/mutations'
import {
  onCreateProject,
  onUpdateProject,
  onDeleteProject,
} from '../../graphql/subscriptions'
import {
  GET_PROJECT_REQUEST,
  getProjectRequestSuccess,
  getProjectRequestFailure,
  UPDATE_PROJECTS_REQUEST,
  updateProjectsRequest,
  updateProjectsRequestSuccess,
  updateProjectsRequestFailure,
  CREATE_NEW_PROJECT_REQUEST,
  createNewProjectRequestSuccess,
  createNewProjectRequestFailure,
  UPDATE_PROJECT_REQUEST,
  updateProjectRequestSuccess,
  updateProjectRequestFailure,
  DELETE_PROJECT_REQUEST,
  deleteProjectRequestSuccess,
  deleteProjectRequestFailure,
  GET_PROJECTS_REQUEST,
  getProjectsRequestSuccess,
  getProjectsRequestFailure,
} from './projects.actions'
import {
  CREATE_NEW_SESSION_REQUEST_SUCCESS,
  DELETE_SESSION_REQUEST_SUCCESS,
} from '../sessions/sessions.actions'
import { LOGIN_REQUEST_SUCCESS } from '../actions'
import fromOrganizations from '../organizations/organizations.selectors'
import fromUser from '../user/user.selectors'
import { SET_CURRENT_ORGANIZATION } from '../organizations/organizations.actions'
import { trackProjectCreate } from '../analytics/analytics.actions'
import { activityBasedAction } from '../utils.sagas'

function* getProject(
  { apolloClient },
  { payload: { projectId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.query, {
      query: gql(getProjectGql),
      fetchPolicy: 'network-only',
      variables: {
        projectId,
      },
    })
    if (!data?.data?.getProject) {
      yield put(getProjectRequestFailure(Error('not found'), thunk))
      return
    }

    yield put(getProjectRequestSuccess(data.data.getProject, thunk))
  } catch (e) {
    console.error(e)
    yield put(getProjectRequestFailure(e, thunk))
  }
}

function* fetchProjects({ organizationId, apolloClient }) {
  let _organizationId = organizationId

  if (!_organizationId) {
    _organizationId = yield select(fromOrganizations.getCurrentOrganizationId)
  }

  const projects = yield call(apolloClient.query, {
    query: gql(listProjects),
    fetchPolicy: 'network-only',
    variables: {
      organizationId: _organizationId,
    },
  })
  for (const project of projects.data.listProjects.items) {
    const uploadedSessions = yield call(apolloClient.query, {
      query: gql(listSessions),
      fetchPolicy: 'network-only',
      variables: {
        projectId: project.pk,
      },
    })
    project.uploadedSessions = uploadedSessions?.data?.listSessions?.items
      ? uploadedSessions.data.listSessions.items.filter(session => {
          return session.videoConversionProgress === 100
        })
      : []
  }

  return get(projects, 'data.listProjects.items')
}

function* getProjects({ apolloClient }, { meta: { thunk } }) {
  try {
    const organizationId = yield select(
      fromOrganizations.getCurrentOrganizationId
    )
    const items = yield call(fetchProjects, { apolloClient })
    yield put(
      updateProjectsRequestSuccess(
        { organizationId, projects: items || [] },
        thunk
      )
    )
  } catch (err) {
    console.log('>>> error', err)
    yield put(updateProjectsRequestFailure(err, thunk))
  }
}

function* getProjectsRequest(
  { apolloClient },
  { payload: { organizationId }, meta: { thunk } }
) {
  try {
    const items = yield call(fetchProjects, { organizationId, apolloClient })
    yield put(
      getProjectsRequestSuccess(
        { organizationId, projects: items || [] },
        thunk
      )
    )
  } catch (err) {
    console.log('>>> error', err)
    yield put(getProjectsRequestFailure(err, thunk))
  }
}

function* dispatchUpdateProjects() {
  const isLogged = yield select(fromUser.isLogged)
  if (!isLogged) {
    return
  }
  yield put(updateProjectsRequest())
}

function* watchForProject({ apolloClient }) {
  try {
    yield dispatchUpdateProjects()

    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateProject),
        })
        .subscribe({
          next: () => {
            emitter('new-project-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateProject),
        })
        .subscribe({
          next: () => {
            emitter('update-project-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const deleteSubscription = apolloClient
        .subscribe({
          query: gql(onDeleteProject),
        })
        .subscribe({
          next: () => {
            emitter('delete-project-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        updateSubscription.unsubscribe()
        deleteSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      yield put(updateProjectsRequest())
    }
  } catch (e) {
    console.error(e)
  }
}

function* createProject({ apolloClient }, action) {
  const {
    payload: { title, shareType, membersEmails },
    meta: { thunk },
  } = action
  try {
    const currentOrganizationId = yield select(
      fromOrganizations.getCurrentOrganizationId
    )
    if (currentOrganizationId) {
      const data = yield call(apolloClient.mutate, {
        mutation: gql(createProjectGql),
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            title,
            organizationId: currentOrganizationId,
            shareType,
            members: membersEmails,
          },
        },
      })
      if (data) {
        yield put(trackProjectCreate({ organizationId: currentOrganizationId }))
        yield put(createNewProjectRequestSuccess(data, thunk))
      } else {
        yield put(
          createNewProjectRequestFailure(
            { error: 'Failed to create a new project' },
            thunk
          )
        )
      }
    } else {
      yield put(
        createNewProjectRequestFailure(
          { error: 'Failed to create a new project' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(createNewProjectRequestFailure(e, thunk))
  }
}

function* updateProject({ apolloClient }, { payload, meta: { thunk } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(updateProjectGql),
      variables: {
        input: payload,
      },
    })

    yield put(updateProjectRequestSuccess({}, thunk))
  } catch (e) {
    yield put(updateProjectRequestFailure(e, thunk))
    console.error(e)
  }
}

function* deleteProject(
  { apolloClient },
  { payload: { projectId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(deleteProjectGql),
      fetchPolicy: 'no-cache',
      variables: {
        projectId,
      },
    })
    yield put(deleteProjectRequestSuccess(data, thunk))
  } catch (e) {
    console.error(e)
    yield put(deleteProjectRequestFailure(e, thunk))
  }
}

function* startProjectsFork({ apolloClient }) {
  yield fork(watchForProject, { apolloClient })
  yield fork(activityBasedAction, dispatchUpdateProjects, 26000, {
    apolloClient,
  })
}

function* refreshProjects() {
  yield put(updateProjectsRequest())
}

export default function* projectsSagas({ apolloClient }) {
  yield all([
    yield takeLatest(LOGIN_REQUEST_SUCCESS, startProjectsFork, {
      apolloClient,
    }),
    yield takeLatest(GET_PROJECT_REQUEST, getProject, { apolloClient }),
    yield takeLatest(UPDATE_PROJECTS_REQUEST, getProjects, { apolloClient }),
    yield takeLatest(GET_PROJECTS_REQUEST, getProjectsRequest, {
      apolloClient,
    }),
    yield takeLatest(SET_CURRENT_ORGANIZATION, dispatchUpdateProjects, {
      apolloClient,
    }),
    yield takeLatest(DELETE_SESSION_REQUEST_SUCCESS, refreshProjects),
    yield takeLatest(DELETE_PROJECT_REQUEST, deleteProject, { apolloClient }),
    yield takeLatest(UPDATE_PROJECT_REQUEST, updateProject, { apolloClient }),
    yield takeLatest(CREATE_NEW_PROJECT_REQUEST, createProject, {
      apolloClient,
    }),
    yield takeLatest(CREATE_NEW_SESSION_REQUEST_SUCCESS, refreshProjects, {
      apolloClient,
    }),
  ])
}
