import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending } from 'redux-saga-thunk'
import fromUser from '../../../../store/user/user.selectors'
import useUserInfo from '../../../../store/hooks/useUserInfo'
import {
  UPDATE_USER,
  updateUserRequest,
} from '../../../../store/user/user.actions'

const isPending = state => pending(state, UPDATE_USER)

const usePictureSettings = () => {
  const { userPicture: userPictureUrl } = useUserInfo()
  const identityId = useSelector(fromUser.getIdentityId)
  const isPictureLoading = useSelector(isPending)

  const dispatch = useDispatch()
  const updatePictureUrl = useCallback(
    pictureUrl => {
      dispatch(updateUserRequest({ identityId, picture: pictureUrl }))
    },
    [dispatch, updateUserRequest, identityId]
  )

  return {
    userPictureUrl,
    updatePictureUrl,
    isPictureLoading,
  }
}

export default usePictureSettings
