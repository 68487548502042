import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import Settings from './Settings'

const StyledModal = styled(Modal)`
  max-width: 640px;
`

const SettingsModal = ({ isVisible, onClose }) => (
  <>
    {isVisible && (
      <StyledModal isAnimated={false}>
        <Header>Settings</Header>
        <Body>
          <Settings />
        </Body>
        <Close aria-label="Close" onClick={onClose} />
      </StyledModal>
    )}
  </>
)

SettingsModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

SettingsModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default SettingsModal
