import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import LockIcon from 'mdi-react/LockIcon'
import EmptyStateMobile from '../../molecules/EmptyStateMobile'
import Flex from '../../atoms/Flex'
import useUserInfo from '../../../store/hooks/useUserInfo'

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const NotAuthorized = ({ ...props }) => {
  const { isLogged, userEmail } = useUserInfo()
  const history = useHistory()

  if (!isLogged) {
    history.push('/register')
  }

  const button = {
    text: 'Back to Home',
    onClick: () => {
      history.push('/')
    },
  }

  return (
    <Wrapper {...props}>
      <EmptyStateMobile icon={<LockIcon size={48} />} button={button}>
        You need permission
        <br />
        <br />
        Want in? Ask for access, or switch to an account with permission.
        <br />
        <br />
        <small>You are signed in as {userEmail}</small>
      </EmptyStateMobile>
    </Wrapper>
  )
}

export default NotAuthorized
