import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { logoutRequest } from '../actions'

const useLoggedSession = () => {
  const dispatch = useDispatch()
  const onLogoutHandler = useCallback(() => dispatch(logoutRequest()), [
    dispatch,
  ])

  return {
    onLogoutHandler,
  }
}

export default useLoggedSession
