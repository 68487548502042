import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Flex from '../Flex'
import { getFromTheme } from '../../mixin'

const Wrapper = styled(Flex)`
  display: inline-flex;
  padding-bottom: 6px;
  position: relative;
  font-size: 14px;
  font-family: sans-serif;
`

const Content = styled(Flex)`
  background-color: ${getFromTheme('colors.grey800')};
  border-radius: 2px;
  width: auto;
  padding: 2px 4px;
  display: inline-flex;
  color: white;
  align-items: center;
  justify-content: center;
  min-height: 16px;
`

const PlayerTooltip = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

PlayerTooltip.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PlayerTooltip
