import React from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'

const Wrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const LeftBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`

const StyledUserAvatar = styled(UserAvatar)`
  align-self: flex-start;
`

const RightBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
`

const FirstRow = styled(Flex)``

const UserName = styled(Flex)`
  margin-bottom: 4px;
  font-weight: bold;
  align-items: flex-start;
  color: ${prop('theme.colors.grey800')};
`

const UserEmail = styled(Flex)`
  align-items: flex-start;
  color: ${prop('theme.colors.grey600')};
  font-size: 14px;
`

const MentionSuggestion = ({ imageUrl, name, email, ...props }) => (
  <Wrapper {...props}>
    <LeftBox>
      <StyledUserAvatar name={name} imgUrl={imageUrl} size="medium" />
    </LeftBox>
    <RightBox>
      <FirstRow>
        <UserName>{name}</UserName>
        <UserEmail>{email}</UserEmail>
      </FirstRow>
    </RightBox>
  </Wrapper>
)

export const MentionSuggestionType = PropTypes.shape({
  pk: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
})

MentionSuggestion.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
}

MentionSuggestion.defaultProps = {
  imageUrl: '',
  name: '',
  email: '',
}

export default MentionSuggestion
