import get from 'lodash/get'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import gql from 'graphql-tag'
import { getSessionComments as getSessionCommentsGql } from '../../graphql/queries'
import {
  createComment as createCommentGql,
  deleteComment as deleteCommentGql,
  updateComment as updateCommentGql,
  createCommentResolution as createCommentResolutionGql,
} from '../../graphql/mutations'
import {
  onCreateComment,
  onDeleteComment,
  onUpdateComment,
  onCreateCommentResolution,
} from '../../graphql/subscriptions'
import {
  GET_SESSION_COMMENTS_REQUEST,
  getSessionCommentsRequest,
  getSessionCommentsRequestSuccess,
  getSessionCommentsRequestFailure,
  CREATE_COMMENT_REQUEST,
  createCommentRequestSuccess,
  createCommentRequestFailure,
  DELETE_COMMENT_REQUEST,
  deleteCommentRequestSuccess,
  deleteCommentRequestFailure,
  UPDATE_COMMENT_REQUEST,
  updateCommentRequestSuccess,
  updateCommentRequestFailure,
  CREATE_COMMENT_RESOLUTION_REQUEST,
  createCommentResolutionRequestSuccess,
  createCommentResolutionRequestFailure,
} from './comments.actions'
import { LOGIN_REQUEST_SUCCESS } from '../actions'
import fromOrganizations from '../organizations/organizations.selectors'
import fromProjects from '../projects/projects.selectors'
import fromSessions from '../sessions/sessions.selectors'
import { trackCommentCreate } from '../analytics/analytics.actions'
import { filterMentions } from '../../components/molecules/MentionSuggestion/mention.utils'
import { removeLastReturn } from '../../util/utils'

function* getSessionComments(
  { apolloClient },
  { payload: { sessionId }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.query, {
      query: gql(getSessionCommentsGql),
      fetchPolicy: 'network-only',
      variables: {
        sessionId,
      },
    })

    const items = get(data, 'data.getSessionComments.items', []) || []
    yield put(
      getSessionCommentsRequestSuccess({ sessionId, comments: items }, thunk)
    )
  } catch (e) {
    yield put(getSessionCommentsRequestFailure(e, thunk))
    console.error(e)
  }
}

export function* callCreateComment(
  {
    sessionId,
    text,
    videoPositionSecs,
    videoPoint,
    videoRectangle,
    mentions = [],
  },
  apolloClient
) {
  const filteredMentions = filterMentions(mentions)
  yield call(apolloClient.mutate, {
    mutation: gql(createCommentGql),
    variables: {
      input: {
        sessionId,
        text,
        videoPositionSecs,
        videoPoint,
        videoRectangle,
        mentions: filteredMentions,
      },
    },
  })
}

function* createComment(
  { apolloClient },
  {
    payload: {
      sessionId,
      text,
      videoPositionSecs,
      videoPoint,
      videoRectangle,
      mentions,
    },
    meta: { thunk },
  }
) {
  try {
    const filteredMentions = filterMentions(mentions)
    yield callCreateComment(
      {
        sessionId,
        text,
        videoPositionSecs,
        videoPoint,
        videoRectangle,
        mentions,
      },
      apolloClient
    )

    yield put(createCommentRequestSuccess({}, thunk))

    try {
      const organizationId = yield select(
        fromOrganizations.getCurrentOrganizationId
      )
      const projectId = yield select(fromProjects.getCurrentProjectId)
      yield put(
        trackCommentCreate({
          organizationId,
          projectId,
          sessionId,
          withMentions: filteredMentions.length > 0,
        })
      )
    } catch (err) {
      console.error('Error on create comment tracking', err)
    }
  } catch (e) {
    yield put(createCommentRequestFailure(e, thunk))
    console.error(e)
  }
}

function* deleteComment(
  { apolloClient },
  { payload: { commentId }, meta: { thunk } }
) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(deleteCommentGql),
      variables: {
        commentId,
      },
    })

    yield put(deleteCommentRequestSuccess({}, thunk))
  } catch (e) {
    yield put(deleteCommentRequestFailure(e, thunk))
    console.error(e)
  }
}

function* updateComment(
  { apolloClient },
  {
    payload: {
      commentId,
      text,
      videoPositionSecs,
      mentions = null,
      isResolved,
    },
    meta: { thunk },
  }
) {
  try {
    const sanitizedText = removeLastReturn(text)
    const filteredMentions = mentions ? filterMentions(mentions) : mentions
    yield call(apolloClient.mutate, {
      mutation: gql(updateCommentGql),
      variables: {
        input: {
          commentId,
          text: sanitizedText,
          videoPositionSecs,
          mentions: filteredMentions,
          isResolved,
        },
      },
    })

    yield put(updateCommentRequestSuccess({}, thunk))
  } catch (e) {
    yield put(updateCommentRequestFailure(e, thunk))
    console.error(e)
  }
}

function* watchForComment({ apolloClient }) {
  try {
    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateComment),
        })
        .subscribe({
          next: () => {
            emitter('create-comment-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const deleteSubscription = apolloClient
        .subscribe({
          query: gql(onDeleteComment),
        })
        .subscribe({
          next: () => {
            emitter('delete-comment-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateComment),
        })
        .subscribe({
          next: () => {
            emitter('update-comment-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        deleteSubscription.unsubscribe()
        updateSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      const sessionId = yield select(fromSessions.getCurrentSessionId)
      yield put(getSessionCommentsRequest(sessionId))
    }
  } catch (e) {
    console.error(e)
  }
}

function* watchForCommentResolution({ apolloClient }) {
  try {
    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateCommentResolution),
        })
        .subscribe({
          next: payload => {
            emitter(payload?.data?.onCreateCommentResolution)
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      const sessionId = yield select(fromSessions.getCurrentSessionId)
      yield put(getSessionCommentsRequest(sessionId))
    }
  } catch (e) {
    console.error(e)
  }
}

function* startCommentsFork({ apolloClient }) {
  yield fork(watchForComment, { apolloClient })
  yield fork(watchForCommentResolution, { apolloClient })
}

function* createCommentResolution(
  { apolloClient },
  { payload: { commentId, resolutionState }, meta: { thunk } }
) {
  try {
    const data = yield call(apolloClient.mutate, {
      mutation: gql(createCommentResolutionGql),
      variables: {
        input: {
          commentId,
          resolutionState,
        },
      },
    })

    yield put(
      createCommentResolutionRequestSuccess(
        { comment: data.data.createCommentResolution },
        thunk
      )
    )
  } catch (e) {
    yield put(createCommentResolutionRequestFailure(e, thunk))
    console.error(e)
  }
}

export default function* commentsSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startCommentsFork, { apolloClient }),
    yield takeLatest(GET_SESSION_COMMENTS_REQUEST, getSessionComments, {
      apolloClient,
    }),
    yield takeLatest(CREATE_COMMENT_REQUEST, createComment, { apolloClient }),
    yield takeLatest(
      CREATE_COMMENT_RESOLUTION_REQUEST,
      createCommentResolution,
      { apolloClient }
    ),
    yield takeLatest(DELETE_COMMENT_REQUEST, deleteComment, { apolloClient }),
    yield takeLatest(UPDATE_COMMENT_REQUEST, updateComment, { apolloClient }),
  ])
}
