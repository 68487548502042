import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field, Toggle, Label, Hint } from '@zendeskgarden/react-forms'
import RecordIcon from 'mdi-react/RecordIcon'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton/IconButton'
import useRecordingPanel from './useRecordingPanel'
import useBrowserExtensionInstalled from '../../hooks/useBrowserExtensionInstalled'
import useBrowserExtensionRecording from '../../hooks/useBrowserExtensionRecording'

const Wrapper = styled(Flex)`
  padding: 8px;
`

const Row = styled(Flex)`
  flex-direction: row;
  margin-bottom: 16px;
`

const StyledField = styled(Field)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledLabel = styled(Label)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 0;
`

const StyledHint = styled(Hint)`
  font-weight: 400;
  font-style: italic;
`

const StyledToggle = styled(Toggle)`
  flex: 0 0 auto;
  display: flex;
  width: 40px;
  margin: 0;
  padding: 0;
`

const StyledIconButton = styled(IconButton)`
  width: 100%;
  && {
    display: flex;
  }
`

const RecordingPanel = ({ onStartRecordButtonClick, ...props }) => {
  const {
    areMicGrantsProvided,
    isMicPresent,
    isMicEnabled,
    onRequestMicrophonePermissions,
    toggleMicAudio,
    onStartRecording,
  } = useRecordingPanel()

  const isBrowserExtensionInstalled = useBrowserExtensionInstalled()
  const isBrowserExtensionRecording = useBrowserExtensionRecording()

  return (
    <Wrapper {...props}>
      {isBrowserExtensionInstalled && (
        <Row>
          <StyledField>
            <StyledLabel>
              <Flex>Microphone audio</Flex>
              {!areMicGrantsProvided && (
                <StyledHint>Grant permission using the extension</StyledHint>
              )}
              {areMicGrantsProvided && !isMicPresent && (
                <StyledHint>No audio devices connected</StyledHint>
              )}
            </StyledLabel>

            <StyledToggle
              checked={isMicEnabled}
              disabled={!areMicGrantsProvided || isBrowserExtensionRecording}
              onChange={() => {
                onRequestMicrophonePermissions()
                toggleMicAudio()
              }}
              data-test="mic-toggle"
            >
              <StyledLabel hidden style={{ width: '0' }}>
                Microphone audio
              </StyledLabel>
            </StyledToggle>
          </StyledField>
        </Row>
      )}
      <Row
        style={{
          marginBottom: '0',
        }}
      >
        <StyledIconButton
          onClick={() => {
            onStartRecording(isMicEnabled)
            onStartRecordButtonClick()
          }}
          icon={RecordIcon}
          isPrimary
          isStretched
          size="medium"
          isRightIcon
          iconProps={{
            style: {
              fill: '#EB5757',
            },
          }}
          data-test="start-recording-button"
          disabled={isBrowserExtensionRecording}
        >
          {isBrowserExtensionRecording
            ? 'Recording in progress...'
            : 'Start recording'}
        </StyledIconButton>
      </Row>
    </Wrapper>
  )
}

RecordingPanel.propTypes = {
  onStartRecordButtonClick: PropTypes.func.isRequired,
}

export default RecordingPanel
