import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import CameraIcon from 'mdi-react/CameraIcon'
import theme from '../../theme/old'
import Flex from '../Flex'

const sizes = {
  small: {
    wrapperWidth: 50,
    circleWidth: 50,
    circleFontSize: 20,
    pickerWidth: 32,
    pickerTop: -12,
  },
  large: {
    wrapperWidth: 102,
    circleWidth: 102,
    circleFontSize: 42,
    pickerWidth: 48,
    pickerTop: -12,
  },
}

const getSize = key => ({ size }) => sizes[size][key]

const Wrapper = styled(Flex)`
  width: ${getSize('wrapperWidth')}px;
  position: relative;
`

const Circle = styled(Flex)`
  border-radius: ${getSize('circleWidth')}px;
  width: ${getSize('circleWidth')}px;
  height: ${getSize('circleWidth')}px;
  background-color: #ffb648;
  overflow: hidden;
  z-index: 0;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: ${getSize('circleFontSize')}px;
  color: white;
`

const Picker = styled.button.attrs({
  type: 'file',
})`
  display: flex;
  border: none;
  outline: none;
  width: ${getSize('pickerWidth')}px;
  height: ${getSize('pickerWidth')}px;
  border-radius: ${getSize('pickerWidth')}px;
  background-color: white;
  position: absolute;
  top: ${getSize('pickerTop')}px;
  right: ${getSize('pickerTop')}px;
  align-items: center;
  justify-content: center;
  color: ${prop('theme.colors.grey800')};
  z-index: 1;
  cursor: pointer;

  :hover {
    color: ${prop('theme.colors.grey900')};
  }
`

const Image = styled(Flex)`
  width: 100%;
  height: 100%;
  display: block;
  ${({ url }) => (url ? `background-image: url("${url}");` : '')}
  background-size: cover;
`

const StyledCameraIcon = styled(CameraIcon)`
  &&:hover {
    fill: ${prop('theme.colors.grey600')};
  }
`

const handleFile = (e, onFile) => {
  if (e.target.files[0]) {
    onFile(e.target.files[0])
    e.target.value = ''
  }
}

const getNameInitials = name => {
  if (!name) {
    return 'IS'
  }
  let initials = ''
  const [first, second] = name.split(' ')
  if (first) {
    initials += first[0]
  }
  if (second) {
    initials += second[0]
  }

  return initials.toUpperCase()
}

const AvatarPicker = ({
  imageUrl,
  name,
  onFile,
  isPickerVisible,
  size,
  backgroundColor,
  ...props
}) => {
  const inputFileRef = useRef(null)
  const changeHandler = e => handleFile(e, onFile)

  useEffect(() => {
    if (inputFileRef.current) {
      inputFileRef.current.removeEventListener('change', changeHandler)
      inputFileRef.current.addEventListener('change', changeHandler)

      return () => {
        inputFileRef.current.removeEventListener('change', changeHandler)
      }
    }

    return () => {}
  }, [inputFileRef.current])
  return (
    <Wrapper {...props} size={size}>
      <Circle
        size={size}
        style={{
          backgroundColor: theme?.colors?.[backgroundColor] || 'yellow',
        }}
      >
        {imageUrl && <Image url={imageUrl} />}
        {!imageUrl && <Flex>{getNameInitials(name)}</Flex>}
      </Circle>
      {isPickerVisible && (
        <Picker
          size={size}
          onClick={() => {
            if (inputFileRef.current) {
              let event
              try {
                event = new MouseEvent('click')
              } catch (e) {
                event = document.createEvent('MouseEvent')
                event.initMouseEvent(
                  'click',
                  true,
                  true,
                  window,
                  0,
                  0,
                  0,
                  0,
                  0,
                  false,
                  false,
                  false,
                  false,
                  0,
                  null
                )
              }

              inputFileRef.current.dispatchEvent(event)
            }
          }}
        >
          <StyledCameraIcon size={size === 'small' ? 24 : 24} />
          <input
            type="file"
            ref={inputFileRef}
            accept=".png,.jpg,.jpeg"
            multiple={false}
            style={{ display: 'none' }}
          />
        </Picker>
      )}
    </Wrapper>
  )
}

AvatarPicker.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  onFile: PropTypes.func,
  isPickerVisible: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  backgroundColor: PropTypes.string,
}

AvatarPicker.defaultProps = {
  imageUrl: '',
  name: '',
  onFile: () => {},
  isPickerVisible: true,
  size: 'large',
  backgroundColor: 'yellow',
}

export default AvatarPicker
