import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import UserAvatar from '../../atoms/UserAvatar'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'

const Wrapper = styled(Flex)`
  flex-direction: row;
  background-color: ${({ isUnread }) =>
    isUnread ? 'hsl(266deg 83% 99%)' : 'transparent'};
  border-bottom: 1px solid
    ${({ isUnread, theme }) =>
      isUnread ? 'hsl(266deg 84% 95%)' : theme.colors.grey100};

  :last-child {
    border: none;
  }

  &&:first-of-type {
    border-top: 1px solid
      ${({ isUnread, theme }) =>
        isUnread ? 'hsl(266deg 84% 95%)' : theme.colors.grey100};
  }

  :hover {
    background-color: ${({ isUnread }) =>
      isUnread ? 'hsl(266deg 83% 98%)' : 'hsl(210deg 10% 99%)'};
  }
`

const LeftBox = styled(Flex)`
  width: 48px;
  min-width: 48px;
`

const IconBox = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${prop('theme.colors.grey200')};
  align-items: center;
  justify-content: center;

  svg {
    fill: ${prop('theme.colors.grey500')};
    width: 16px;
    height: 16px;
  }
`

const RightBox = styled(Flex)`
  flex-direction: column;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 8px 0 0;
`

const ActivityText = styled.div`
  padding-right: 16px;
  color: ${prop('theme.colors.grey800')};
`
const MessageText = styled(Flex)`
  margin-top: 8px;
  background-color: ${({ isUnread, theme }) =>
    theme.colors[isUnread ? 'secondary100' : 'grey100']};
  color: ${({ isUnread, theme }) =>
    theme.colors[isUnread ? 'secondary900' : 'grey800']};
  padding: 8px;
  border-radius: 2px;
`

const TimeAgo = styled(Flex)`
  margin-top: 8px;
  font-weight: ${ternary('isUnread')('600', '400')};
  color: ${({ isUnread, theme }) =>
    isUnread ? theme.colors.secondary : theme.colors.grey400};
`

const UnreadMarker = styled(Flex)`
  position: absolute;
  top: 10.5px;
  right: 12px;
  background-color: ${prop('theme.colors.secondary')};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`

const Activity = ({
  icon,
  userPictureUrl,
  userName,
  activityNode,
  messageText,
  timeAgo,
  isUnread,
  ...props
}) => (
  <Wrapper isUnread={isUnread} {...props}>
    <LeftBox>
      {icon && <IconBox>{icon}</IconBox>}
      {!icon && (
        <UserAvatar imgUrl={userPictureUrl} name={userName} size="small" />
      )}
    </LeftBox>
    <RightBox>
      {isUnread && <UnreadMarker />}
      <ActivityText isUnread={isUnread}>{activityNode}</ActivityText>
      {messageText && (
        <MessageText isUnread={isUnread}>{messageText}</MessageText>
      )}
      <TimeAgo isUnread={isUnread}>{timeAgo}</TimeAgo>
    </RightBox>
  </Wrapper>
)

Activity.propTypes = {
  icon: PropTypes.element,
  userPictureUrl: PropTypes.string,
  userName: PropTypes.string,
  activityNode: PropTypes.element,
  messageText: PropTypes.string,
  timeAgo: PropTypes.string,
  isUnread: PropTypes.bool,
}

Activity.defaultProps = {
  icon: null,
  userPictureUrl: '',
  userName: '',
  activityNode: null,
  messageText: '',
  timeAgo: '',
  isUnread: false,
}

export default Activity
