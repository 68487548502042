/* eslint-disable require-yield */
import Storage from '@aws-amplify/storage'
import axios from 'axios'
import last from 'lodash/last'
import { delay, takeLatest, all, call, select, put } from 'redux-saga/effects'
import { LOGIN_REQUEST_SUCCESS } from '../../../store/actions'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import {
  upsertLocalSession,
  deleteLocalSessionById,
} from './localSessions.actions'
import fromLocalSessions from './localSessions.selectors'
import video from '../../../lib/video'
import { DESKTOP_PULL_RECORDING_REQUEST_SUCCESS } from '../actions'
import api from '../../../api'

const fs = window.require('fs')

export function* uploadVideo({
  fileName,
  content,
  contentType,
  metadata,
  progressCallback,
}) {
  yield call(Storage.put, fileName, content, {
    level: 'private',
    contentType,
    metadata, // (map<String>) A map of metadata to store with the object in S3.
    progressCallback,
  })
}

let isUploading = false
function* uploadRecording({ path, sessionId = '', projectId = '' }) {
  try {
    isUploading = true
    const content = fs.readFileSync(path.replace('.mov', '.mp4'))

    yield uploadVideo({
      fileName: `${sessionId}.mp4`,
      content,
      contentType: 'video/mp4',
      metadata: { sessionId, projectId },
      progressCallback: progress => {
        console.log(`Video uploaded: ${progress.loaded}/${progress.total}`)
      },
    })
  } catch (err) {
    isUploading = false
    console.error(err)
  }
}

function* processLocalSession({
  payload: {
    videoInfo: { path, duration, durationUnit },
  },
}) {
  try {
    const sessionId = yield select(fromSessions.getCurrentSessionId)
    const projectId = yield select(fromProjects.getCurrentProjectId)
    yield put(
      upsertLocalSession({
        sessionId,
        videoPath: path,
        duration,
        durationUnit,
      })
    )

    const fileExtension = last(path.split('.'))
    if (fileExtension === 'mov') {
      yield call(video.convertMovToMp4, path)
      yield delay(1000)
    }

    if (!isUploading) {
      yield call(uploadRecording, { path, sessionId, projectId })
    }
  } catch (e) {
    console.error(e)
  }
}

function* processLocalSessions() {
  while (true) {
    try {
      const localSessions = (yield select(
        fromLocalSessions.getLocalSessions
      )).filter(session => !session.isUploaded)
      if (localSessions.length > 0) {
        const [localSession] = localSessions
        const { sessionId } = localSession
        const {
          data: { url },
        } = yield call(api.getRecording, sessionId)
        try {
          yield call(axios.get, url)
          const localVideoDuration = yield call(
            video.getVideoDuration,
            localSession.videoPath
          )
          const remoteVideoDuration = yield call(video.getVideoDuration, url)

          if (
            parseInt(localVideoDuration, 10) ===
            parseInt(remoteVideoDuration, 10)
          ) {
            yield put(
              upsertLocalSession({
                ...localSession,
                isUploaded: true,
              })
            )
          }
        } catch (err) {
          console.error(err)
          try {
            if (!isUploading) {
              yield call(uploadRecording, {
                path: localSession.videoPath,
                sessionId,
              })
            }
          } catch (_err) {
            if (_err.toString().includes('no such file')) {
              yield put(deleteLocalSessionById(sessionId))
            }
          }
        }
      }
    } catch (err) {
      console.error(err)
    }

    yield delay(60000)
  }
}

export default function* localSessionsSagas() {
  yield all([
    yield takeLatest(LOGIN_REQUEST_SUCCESS, processLocalSessions),
    yield takeLatest(
      DESKTOP_PULL_RECORDING_REQUEST_SUCCESS,
      processLocalSession
    ),
  ])
}
