import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { pending, done, rejected } from 'redux-saga-thunk'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import useProjectsHook from '../../../store/hooks/useProjectsHook'
import { updateSessionsRequest } from '../../../store/sessions/sessions.actions'
import fromDevices from '../../../desktop/store/selectors'
import fromOrganizations, {
  PERSONAL_ORGANIZATION_ID,
} from '../../../store/organizations/organizations.selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromUser from '../../../store/user/user.selectors'
import fromUsers from '../../../store/users/users.selectors'
import { GET_USER } from '../../../store/user/user.actions'
import { getRecordingPathName, getFolderPathName } from '../../../util/paths'
import useRemoteTestsHook from '../../../store/hooks/useRemoteTestsHook'
import { SHARE_TYPES } from '../../molecules/ProjectShareModal/ProjectShare.constants'
import useOrganization from '../../../store/hooks/useOrganization'
import useCanAddSessions from '../../../store/hooks/useCanAddSessions'
import { toUserId } from '../../../util/utils'

const isProjectShared = isPersonalWorkspace => project => {
  if (isPersonalWorkspace) {
    return false
  }
  return (
    project.isShared ||
    project.shareType === SHARE_TYPES.anyone ||
    (Array.isArray(project.view) && project.view.length > 0) ||
    (Array.isArray(project.edit) && project.edit.length > 0) ||
    (Array.isArray(project.admin) && project.admin.length > 0)
  )
}

const isProjectPrivate = isPersonalWorkspace => project => {
  if (isPersonalWorkspace) {
    return true
  }

  return (
    !project.isShared &&
    project.shareType !== SHARE_TYPES.anyone &&
    (!Array.isArray(project.view) || project.view.length === 0) &&
    (!Array.isArray(project.edit) || project.edit.length === 0) &&
    (!Array.isArray(project.admin) || project.admin.length === 0)
  )
}

const isGetUserPending = state => pending(state, GET_USER)
const isGetUserComplete = state => done(state, GET_USER)
const isGetUserRejected = state => rejected(state, GET_USER)

const getBadgeText = ({
  allUsers,
  currentProject,
  isCurrentOrganizationPersonal,
  isCurrentProjectPrivate,
  isProjectSharedWithTheWorkspace,
  currentOrganizationUsers,
}) => {
  if (isCurrentOrganizationPersonal) {
    return ''
  }

  const ownerUserEmail = allUsers.find(
    user => toUserId(user.pk) === toUserId(currentProject?.createdBy)
  )?.email

  if (isProjectSharedWithTheWorkspace) {
    return currentOrganizationUsers.length
  }

  if (!isCurrentOrganizationPersonal && !isCurrentProjectPrivate) {
    const currentProjectView = currentProject?.view || []
    const currentProjectEdit = currentProject?.edit || []
    const currentProjectAdmin = currentProject?.admin || []

    const sharedWith = uniq(
      compact(
        currentProjectAdmin.concat(currentProjectEdit, currentProjectView, [
          ownerUserEmail,
        ])
      )
    )

    return sharedWith.length
  }

  return ''
}

const useEntryView = () => {
  const history = useHistory()
  const devices = useSelector(fromDevices.getDevicesById)
  const organizationsId = useSelector(fromOrganizations.getAllOrganizationsId)

  const {
    currentOrganization,
    currentOrganizationId,
    isCurrentOrganizationPersonal,
    currentOrganizationUsers,
  } = useOrganization()

  const currentProjectId = useSelector(fromProjects.getCurrentProjectId)
  const currentProject = useSelector(fromSessions.getCurrentProject)
  const sessions = useSelector(
    fromSessions.getSessionsByProjectId(currentProjectId)
  )
  const { remoteTests } = useRemoteTestsHook()

  const userName = useSelector(fromUser.getName)
  const userId = useSelector(fromUser.getId)
  const identityId = useSelector(fromUser.getIdentityId)

  const isCurrentUserLoading = useSelector(isGetUserPending)
  const isCurrentUserCompleted = useSelector(isGetUserComplete)
  const isCurrentUserRejected = useSelector(isGetUserRejected)

  const { projects } = useProjectsHook()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateSessionsRequest())
    const config = {
      selector: '.headway-widget', // CSS selector where to inject the badge
      account: '7vYE6x',
      trigger: '.headway-widget span',
    }
    // eslint-disable-next-line no-undef
    if (window?.Headway) {
      // eslint-disable-next-line no-undef
      Headway.init(config)
    }
  }, [])
  const [isProjectModalVisible, setProjectModalVisible] = useState(false)
  const [isShareModalVisible, setShareModalVisible] = useState(false)
  const [dropdownProject, setDropdownProject] = useState(null)

  const onSetCurrentProject = useCallback(
    projectId => {
      history.push(getFolderPathName(projectId))
    },
    [dispatch]
  )

  const goToSession = ({ sessionId }) => {
    history.push(getRecordingPathName(sessionId))
  }

  const sharedProjects = projects.filter(
    isProjectShared(isCurrentOrganizationPersonal)
  )
  const privateProjects = projects.filter(
    isProjectPrivate(isCurrentOrganizationPersonal)
  )

  const isProjectSharedWithTheWorkspace =
    currentProject?.shareType === SHARE_TYPES.anyone

  const isCurrentProjectPrivate = currentProject
    ? isProjectPrivate(isCurrentOrganizationPersonal)(currentProject)
    : true

  const allUsers = useSelector(fromUsers.getAllUsers)

  const badgeText = getBadgeText({
    allUsers,
    currentProject,
    isCurrentOrganizationPersonal,
    isCurrentProjectPrivate,
    isProjectSharedWithTheWorkspace,
    currentOrganizationUsers,
  })

  useEffect(() => {
    if (!currentProjectId && projects.length > 0) {
      onSetCurrentProject(projects?.[0]?.pk)
    }
  }, [currentProjectId, projects, onSetCurrentProject])
  const currentUserCanAddSessions = useCanAddSessions()

  return {
    badgeText,
    organizationsId,
    currentOrganizationId,
    currentOrganization,
    currentProject,
    currentProjectId,
    devices,
    dropdownProject,
    goToSession,
    isCurrentProjectPrivate,
    isCurrentUserCompleted,
    isCurrentUserLoading,
    isCurrentUserRejected,
    isProjectModalVisible,
    isShareModalVisible,
    onSetCurrentProject,
    PERSONAL_ORGANIZATION_ID,
    privateProjects,
    projects,
    sessions,
    remoteTests,
    setDropdownProject,
    setProjectModalVisible,
    setShareModalVisible,
    sharedProjects,
    userName,
    userId,
    identityId,
    currentUserCanAddSessions,
  }
}

export default useEntryView
