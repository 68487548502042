import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import BellIcon from 'mdi-react/BellIcon'
import BellOffIcon from 'mdi-react/BellOffIcon'
import Flex from '../../atoms/Flex'
import AvatarPicker from '../../atoms/AvatarPicker'
import SheetList from '../../atoms/SheetList'
import MobileTopBar from '../../molecules/MobileTopBar'
import MobileModal from '../../molecules/MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'
import { isGoogleLogin } from '../../../util/auth'
import NameModal from './NameModal'
import PasswordModal from './PasswordModal'
import { addSearchParameter } from '../../../util/utils'

const Wrapper = styled(Flex)``

const Row = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  padding: 16px;
  border-bottom: 2px solid ${prop('theme.colors.grey100')};
`
const Label = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: ${prop('theme.colors.grey700')};
`
const Content = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin-top: 4px;
  color: ${prop('theme.colors.grey600')};
  letter-spacing: 0.02em;
`

const ContentCapitalize = styled(Content)`
  text-transform: capitalize;
`

const UserSettingsMobile = ({
  onBack,
  userName,
  email,
  picture,
  onPictureChange,
  onNotificationUpdate,
  onNameUpdate,
  notificationFrequency,
  ...props
}) => {
  const [isNotificationsSheetVisible, setNotificationsSheetVisible] = useState(
    false
  )

  const [notificationValue, setNotificationValue] = useState(
    notificationFrequency
  )
  const notificationsSheetItems = [
    {
      text: 'Instant',
      icon: <BellIcon />,
      onClick: () => {
        onNotificationUpdate('instant')
        setNotificationValue('instant')
        setNotificationsSheetVisible(false)
      },
    },
    {
      text: 'Never',
      icon: <BellOffIcon />,
      onClick: () => {
        onNotificationUpdate('never')
        setNotificationValue('never')
        setNotificationsSheetVisible(false)
      },
    },
  ]

  const [isPasswordModalVisible, setPasswordModalVisible] = useState(false)
  const [isNameModalVisible, setNameModalVisible] = useState(false)

  const header = useMemo(
    () => (
      <MobileTopBar icon={ArrowLeftIcon} onButtonClick={onBack}>
        Settings
      </MobileTopBar>
    ),
    []
  )

  const body = useMemo(
    () => (
      <>
        <Row>
          <Label>
            <AvatarPicker
              size="small"
              name={userName}
              imageUrl={picture}
              onPictureChange={newPictureUrl =>
                onPictureChange(
                  addSearchParameter(newPictureUrl, 'r', Math.random())
                )
              }
            />
          </Label>
        </Row>
        <Row onClick={() => setNameModalVisible(true)}>
          <Label>Name</Label>
          <Content>{userName}</Content>
        </Row>
        <Row>
          <Label>Email</Label>
          <Content>{email}</Content>
        </Row>
        {!isGoogleLogin() && (
          <Row onClick={() => setPasswordModalVisible(true)}>
            <Label>Change password</Label>
          </Row>
        )}
        <Row onClick={() => setNotificationsSheetVisible(true)}>
          <Label>Notifications</Label>
          <ContentCapitalize>{notificationValue}</ContentCapitalize>
        </Row>
        <SheetList
          onClose={() => setNotificationsSheetVisible(false)}
          isVisible={isNotificationsSheetVisible}
          items={notificationsSheetItems}
          title="Notifications"
          description="Configure the frequency in which comments and mentions are sent to your inbox."
        />
      </>
    ),
    [
      userName,
      picture,
      userName,
      email,
      onPictureChange,
      notificationValue,
      setNotificationsSheetVisible,
      isNotificationsSheetVisible,
      notificationsSheetItems,
      setPasswordModalVisible,
    ]
  )

  return (
    <>
      <Wrapper {...props}>
        <MobileModal>
          <MobileTemplate header={header} body={body} footer={null} />
        </MobileModal>
      </Wrapper>
      <PasswordModal
        isVisible={isPasswordModalVisible}
        onClose={() => setPasswordModalVisible(false)}
      />
      <NameModal
        name={userName}
        isVisible={isNameModalVisible}
        onClose={() => setNameModalVisible(false)}
        onNameUpdate={onNameUpdate}
      />
    </>
  )
}

UserSettingsMobile.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  picture: PropTypes.string,
  notificationFrequency: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onPictureChange: PropTypes.func.isRequired,
  onNotificationUpdate: PropTypes.func,
  onNameUpdate: PropTypes.func,
}

UserSettingsMobile.defaultProps = {
  picture: '',
  notificationFrequency: 'instant',
  onNotificationUpdate: () => {},
  onNameUpdate: () => {},
}

export default UserSettingsMobile
