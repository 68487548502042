import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Tippy from '@tippyjs/react'
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon'
import { Avatar } from '@zendeskgarden/react-avatars'
import {
  Dropdown,
  Menu,
  Trigger,
  HeaderItem,
} from '@zendeskgarden/react-dropdowns'
import BellOutlineIcon from 'mdi-react/BellOutlineIcon'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const Wrapper = styled(Flex)``

const StyledHeaderItem = styled(HeaderItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  min-height: 32px;

  &&:hover:not([disabled]) {
    background-color: transparent !important;
    color: ${prop('theme.colors.primary')} !important;
  }
`

const MarkAsReadIconButton = styled(IconButton)`
  border-radius: 50%;
`

const hasCount = badge => badge !== undefined

const StyledAvatar = styled(Avatar)`
  && {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    box-shadow: none;
  }

  &&:hover {
    background-color: ${prop('theme.colors.secondary100')};
  }

  &&::after {
    transition: all 0.3s ease-out;
    background-color: ${({ badge, theme }) =>
      hasCount(badge) ? theme.colors.warning600 : ''};
  }

  && span {
    transition: all 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  && svg {
    transition: all 0.3s ease-out;
    fill: ${prop('theme.colors.grey')};
  }
`

const ActivityIcon = ({
  count,
  notificationItems,
  onMarkAllAsReadClick,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isMarkAsReadTooltipVisible, setMarkAsReadTooltipVisible] = useState(
    false
  )
  const wrapperRef = useRef()
  const markAsReadRef = useRef()

  useEffect(() => {
    window.addEventListener('click', e => {
      if (wrapperRef?.current && !wrapperRef?.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }, [wrapperRef?.current])

  return (
    <Wrapper {...props} ref={wrapperRef}>
      <Dropdown isOpen={isOpen}>
        <Trigger>
          <span>
            <Tippy
              content="Notifications"
              placement="bottom"
              arrow={false}
              zIndex={10}
            >
              <StyledAvatar
                size="small"
                badge={count === 0 ? undefined : count}
                onClick={() => setOpen(!isOpen)}
              >
                <Avatar.Text>
                  <BellOutlineIcon size={20} />
                </Avatar.Text>
              </StyledAvatar>
            </Tippy>
          </span>
        </Trigger>
        <Menu
          hasArrow={false}
          placement="bottom-end"
          style={{
            transition: 'all 0.5s linear',
            display: isOpen ? 'inline-block' : 'none',
            opacity: isOpen ? '1' : '0',
            minWidth: '320px',
            maxWidth: '320px',
            zIndex: 2147483647,
          }}
          maxHeight={`${window.innerHeight * 0.8}px`}
        >
          <StyledHeaderItem>
            <Flex flexGrow={1}>Notifications</Flex>
            {count > 0 && (
              <Tippy
                content="Mark all as read"
                placement="bottom"
                arrow={false}
                zIndex={2147483647}
                visible={isMarkAsReadTooltipVisible}
                reference={markAsReadRef}
              >
                <span ref={markAsReadRef}>
                  <MarkAsReadIconButton
                    icon={PlaylistCheckIcon}
                    isSecondary
                    size="small"
                    onMouseEnter={() => setMarkAsReadTooltipVisible(true)}
                    onMouseLeave={() => setMarkAsReadTooltipVisible(false)}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onMarkAllAsReadClick()
                    }}
                  />
                </span>
              </Tippy>
            )}
          </StyledHeaderItem>
          {notificationItems}
        </Menu>
      </Dropdown>
    </Wrapper>
  )
}

ActivityIcon.propTypes = {
  count: PropTypes.number,
  notificationItems: PropTypes.element,
  onMarkAllAsReadClick: PropTypes.func,
}

ActivityIcon.defaultProps = {
  count: 0,
  notificationItems: <div />,
  onMarkAllAsReadClick: () => {},
}

export default ActivityIcon
