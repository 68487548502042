import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 24px;
  color: ${prop('theme.style.colors.grey500')};
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const ConfirmationModalMobile = ({
  title,
  text,
  submitButtonNode,
  onClose,
  onSubmit,
  isVisible,
}) => {
  const header = useMemo(
    () => (
      <StyledMobileTopBar rightIcon={CloseIcon} onRightButtonClick={onClose}>
        {title}
      </StyledMobileTopBar>
    ),
    [title]
  )

  const body = useMemo(() => <Body>{text}</Body>, [text])

  const footer = useMemo(
    () => (
      <Footer>
        <Button
          isPrimary
          onClick={() => {
            onSubmit()
          }}
        >
          {submitButtonNode}
        </Button>
      </Footer>
    ),
    [submitButtonNode]
  )

  return (
    <Flex>
      {isVisible && (
        <MobileModal>
          <MobileTemplate header={header} body={body} footer={footer} />
        </MobileModal>
      )}
    </Flex>
  )
}

ConfirmationModalMobile.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  submitButtonNode: PropTypes.node.isRequired,
}

export default ConfirmationModalMobile
