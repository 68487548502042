import { useDispatch, useSelector } from 'react-redux'
import fromIntegrationsSlack from '../../store/integrations/slack/integrations.slack.selectors'
import {
  postSlackMessageRequest,
  updateSlackRequest,
} from '../../store/integrations/slack/integrations.slack.actions'

const useTrello = () => {
  const dispatch = useDispatch()
  const hasSlack = useSelector(fromIntegrationsSlack.getHasSlack) || false
  const slackChannels =
    useSelector(fromIntegrationsSlack.getSlackChannels) || []
  const slackUsers = useSelector(fromIntegrationsSlack.getSlackUsers) || []

  const onPostSlackMessage = values => dispatch(postSlackMessageRequest(values))
  const fetchSlackResources = () => dispatch(updateSlackRequest())

  return {
    hasSlack,
    onPostSlackMessage,
    fetchSlackResources,
    slackChannels,
    slackUsers,
  }
}

export default useTrello
