import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import { Field, Input } from '@zendeskgarden/react-forms'
import Flex from '../../atoms/Flex/Flex'
import Badge from '../../molecules/Badge/Badge'
import useUpdateProject from '../../../store/hooks/useUpdateProject'
import ProjectShareModal from '../../molecules/ProjectShareModal'
import useEntryView from './useEntryView'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'

const TitleFlex = styled(Flex)`
  flex-direction: row;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  align-items: center;
`

const StyledField = styled(Field)`
  width: 100%;
`

const StyledBadge = styled(Badge)`
  margin-right: 24px;
`

const ProjectTitle = ({ onUpsell }) => {
  const {
    badgeText,
    isCurrentProjectPrivate,
    isShareModalVisible,
    setShareModalVisible,
    setDropdownProject,
    currentProjectId,
    currentProject,
    currentOrganizationId,
  } = useEntryView()

  const projectTitleRef = useRef(null)
  const { updateProject, setTitle: setProjectTitle } = useUpdateProject(
    currentProjectId
  )

  useEffect(() => {
    if (projectTitleRef?.current) {
      projectTitleRef.current.value = currentProject.title
    }
  }, [projectTitleRef?.current, currentProject?.pk])

  const { shouldShowCreation } = useLibraryWorkspace()

  return (
    <TitleFlex>
      {currentProject && (
        <>
          {shouldShowCreation && (
            <StyledBadge
              isPrivate={isCurrentProjectPrivate}
              text={badgeText.toString()}
              onClick={() => {
                if (currentOrganizationId === 'personal') {
                  onUpsell()
                  return
                }
                setDropdownProject(currentProject)
                setShareModalVisible(true)
              }}
              data-test="project-title-badge"
            />
          )}
          <StyledField>
            <Input
              ref={projectTitleRef}
              defaultValue={get(currentProject, 'title', '')}
              isBare
              style={{
                fontSize: '24px',
              }}
              readOnly={false}
              onBlur={() => {
                setProjectTitle({
                  projectId: currentProject?.pk,
                  title: projectTitleRef.current.value,
                })
                updateProject({ title: projectTitleRef.current.value })
              }}
              data-test="project-title-input"
            />
          </StyledField>
          {isShareModalVisible && (
            <ProjectShareModal
              isVisible={isShareModalVisible}
              projectId={currentProject ? currentProject.pk : null}
              onClose={() => {
                setTimeout(() => setShareModalVisible(false), 700)
              }}
            />
          )}
        </>
      )}
    </TitleFlex>
  )
}

ProjectTitle.propTypes = {
  onUpsell: PropTypes.func,
}

ProjectTitle.defaultProps = {
  onUpsell: () => {},
}

export default ProjectTitle
