import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import uniqBy from 'lodash/uniqBy'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { STATE_KEY } from './users.reducers'
import fromOrganizations from '../organizations/organizations.selectors'

const getState = state => getImmutable(state, STATE_KEY)

const getAllUsers = createSelector(
  getState,
  pipe(
    get(`byId`),
    values
  )
)

const getUserById = id =>
  createSelector(
    getState,
    get(`byId.${id}`)
  )

const getUsersByIds = ids =>
  createSelector(
    getState,
    state => ids.map(id => get(`byId.${id}`)(state))
  )

const getUserByEmail = email =>
  createSelector(
    getState,
    get(`byEmail.${email}`)
  )

const getUsersByEmail = emails =>
  createSelector(
    getState,
    state => emails.map(email => get(['byEmail', email])(state))
  )

const getCurrentOrganizationUsers = createSelector(
  getState,
  fromOrganizations.getCurrentOrganization,
  (users, organization) => {
    const organizationAdmins = get('admins')(organization) || []
    const organizationMembers = get('members')(organization) || []
    const organizationUsers = organizationAdmins.concat(organizationMembers)
    const _users = organizationUsers
      .map(organizationUserId => get(['byId', organizationUserId])(users))
      .filter(x => !!x)

    return uniqBy(_users, 'email')
  }
)

export default {
  getState,
  getAllUsers,
  getUserById,
  getUsersByIds,
  getUserByEmail,
  getUsersByEmail,
  getCurrentOrganizationUsers,
}
