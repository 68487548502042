import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import prop from 'lodash/fp/prop'
import AccountIcon from 'mdi-react/AccountIcon'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'
import MobileTopBar from '../MobileTopBar'
import EmptyStateMobile from '../EmptyStateMobile'
import CommentEditableTextMobile from '../CommentDialog/CommentEditableTextMobile'
import Loader from '../../atoms/Loader'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import { isIOS } from '../../../lib/platform'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 24px;
  box-sizing: border-box;
  flex-direction: row;
  justify-self: flex-end;
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const Row = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
`

const StyledCommentEditableTextMobile = styled(CommentEditableTextMobile)`
  width: 100%;
  margin-top: 8px;
  align-self: flex-end;
  flex-grow: 1;

  && {
    box-sizing: border-box;
  }
`

const StyledLoader = styled(Loader)`
  color: ${prop('theme.colors.grey600')};
`

const NewCommentModalMobile = ({
  isUserLogged,
  onClose,
  onSubmit,
  isSubmitting,
}) => {
  const [updatedText, setUpdateText] = useState('')
  const [currentMentions, setCurrentMentions] = useState([])
  const [autoFocusToken, setAutoFocusToken] = useState(null)

  useEffect(() => {
    if (!isIOS()) {
      setTimeout(() => setAutoFocusToken(Math.random().toString()), 100)
    }

    if (isIOS()) {
      setTimeout(() => setAutoFocusToken(Math.random().toString()), 500)
    }
  }, [])

  const header = (
    <StyledMobileTopBar rightIcon={CloseIcon} onRightButtonClick={onClose}>
      Leave a feedback
    </StyledMobileTopBar>
  )

  const textRef = useRef(null)
  useEffect(() => {
    if (textRef?.current) {
      textRef.current.style.height = '25vh'
    }
  }, [textRef?.current])

  const body = useMemo(
    () => (
      <Body>
        {isUserLogged ? (
          <StyledCommentEditableTextMobile
            placeholder="Type @ to mention..."
            autoFocusToken={autoFocusToken}
            onChange={setUpdateText}
            mentions={[]}
            textRef={textRef}
            onAddedMention={newMention =>
              setCurrentMentions([...currentMentions, newMention])
            }
            onRemovedMention={removedMention =>
              setCurrentMentions([
                ...currentMentions.filter(({ pk }) => pk !== removedMention.pk),
              ])
            }
          />
        ) : (
          <EmptyStateMobile
            icon={<AccountIcon />}
            button={{
              text: 'Sign in',
              onClick: () => {
                window.location.pathname = '/'
              },
            }}
          >
            Register or login to leave your comments
          </EmptyStateMobile>
        )}
      </Body>
    ),
    [isUserLogged, currentMentions.length, autoFocusToken]
  )

  const footer = useMemo(
    () =>
      isUserLogged && (
        <Footer>
          <Row>
            <Button
              isBasic
              isStretched
              onClick={onClose}
              style={{ marginRight: '8px' }}
            >
              Cancel
            </Button>
            <Button
              isPrimary
              isStretched
              disabled={!updatedText || isSubmitting}
              onClick={() => {
                if (updatedText) {
                  onSubmit(updatedText, currentMentions).then(() => onClose())
                }
              }}
            >
              {isSubmitting && <StyledLoader size="24px" />}
              {!isSubmitting && 'Add comment'}
            </Button>
          </Row>
        </Footer>
      ),
    [isUserLogged, isSubmitting, updatedText]
  )

  return (
    <MobileModal position="absolute">
      <MobileTemplate
        header={header}
        body={body}
        footer={footer}
        bodyStyle={{ justifyContent: isUserLogged ? 'flex-end' : 'center' }}
      />
    </MobileModal>
  )
}

NewCommentModalMobile.propTypes = {
  isUserLogged: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export default NewCommentModalMobile
