import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import LockIcon from 'mdi-react/LockIcon'
import EarthIcon from 'mdi-react/EarthIcon'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'

const stylesWithClick = css`
  background-color: ${({ isPublic, theme }) =>
    isPublic ? theme.colors.yellow300 : theme.colors.secondary200};
  cursor: pointer;
`

const Wrapper = styled(Flex).attrs({
  direction: 'row',
})`
  display: inline-flex;
  border-radius: 26px;
  padding: 4px 12px;
  height: 24px;
  background-color: ${({ isPublic, theme }) =>
    isPublic ? theme.colors.yellow200 : theme.colors.secondary100};
  color: ${({ isPublic, theme }) =>
    isPublic ? theme.colors.yellow800 : theme.colors.secondary600};

  :hover {
    ${({ onClick }) => (onClick ? stylesWithClick : '')}
  }
`

const InlineFlex = styled(Flex)`
  display: inline-flex;
  flex: 0 0 auto;
  font-size: 14px;
  height: 24px;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
`

const IconFlex = styled(InlineFlex)`
  margin-right: ${ternary('isPublic')('', '4px')};
`

const Badge = ({ text, isPrivate, isPublic, theme, ...props }) => {
  const iconColor = theme.colors.secondary600
  const publicIconColor = theme.colors.yellow800
  return (
    <Wrapper {...props} isPublic={isPublic}>
      <IconFlex isPublic={isPublic}>
        {isPublic && <EarthIcon size={16} color={publicIconColor} />}
        {!isPublic && isPrivate && <LockIcon size={16} color={iconColor} />}
        {!isPrivate && !isPublic && (
          <AccountMultipleIcon size={16} color={iconColor} />
        )}
      </IconFlex>
      {!isPublic && <InlineFlex>{isPrivate ? 'Private' : text}</InlineFlex>}
    </Wrapper>
  )
}

Badge.propTypes = {
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      secondary600: PropTypes.string,
      yellow800: PropTypes.string,
    }),
  }).isRequired,
}

Badge.defaultProps = {
  isPrivate: true,
  isPublic: false,
  text: '',
}

export default withTheme(Badge)
