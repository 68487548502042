const NAMESPACE = 'UI/NOTIFICATIONS'

export const NOTIFICATIONS_ADD = `${NAMESPACE}/ADD`
export const addNotification = ({
  notificationId,
  text,
  callToAction,
  isPersisted,
}) => ({
  type: NOTIFICATIONS_ADD,
  payload: { notificationId, text, callToAction, isPersisted },
})

export const NOTIFICATIONS_REMOVE = `${NAMESPACE}/REMOVE`
export const removeNotification = ({ notificationId }) => ({
  type: NOTIFICATIONS_REMOVE,
  payload: { notificationId },
})

export const NOTIFICATIONS_CLICK = `${NAMESPACE}/REMOVE`
export const clickNotification = ({ notificationId }) => ({
  type: NOTIFICATIONS_CLICK,
  payload: { notificationId },
})
