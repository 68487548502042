import React from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../../atoms/Flex'
import Loader from '../../../atoms/Loader/Loader'

const Wrapper = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
`

const Box = styled(Flex)`
  border-radius: 8px;
  box-shadow: ${prop('theme.boxShadows.topBarElevation')};
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  background-color: white;
`

const StyledLoader = styled(Loader)`
  margin-bottom: 24px;
`

const GoogleSignUpLoader = ({ ...props }) => (
  <Wrapper {...props}>
    <Box>
      <StyledLoader />
      <Flex>Hold tight, we&apos;re getting ready!</Flex>
    </Box>
  </Wrapper>
)

export default GoogleSignUpLoader
