import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fromOrganizations from '../organizations/organizations.selectors'
import fromUsers from '../users/users.selectors'
import {
  setCurrentOrganization,
  updateOrganizationEmailDomainsRequest,
} from '../organizations/organizations.actions'

const useOrganization = function useOrganization() {
  const currentOrganization = useSelector(
    fromOrganizations.getCurrentOrganization
  )

  const currentOrganizationAdmins = currentOrganization?.admins || []
  const currentOrganizationMembers = currentOrganization?.members || []
  const currentOrganizationUserIdss = currentOrganizationAdmins.concat(
    currentOrganizationMembers
  )

  const currentOrganizationUsers = useSelector(
    fromUsers.getUsersByIds(currentOrganizationUserIdss)
  )

  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )

  const isCurrentOrganizationPersonal = useSelector(
    fromOrganizations.isCurrentOrganizationPersonal
  )

  const organizations = useSelector(fromOrganizations.getOrganizations)

  const dispatch = useDispatch()
  const onSetCurrentOrganization = useCallback(
    organizationId => dispatch(setCurrentOrganization(organizationId)),
    [dispatch]
  )

  const onUpdateWorkspaceEmailDomains = useCallback(
    emailDomains =>
      dispatch(
        updateOrganizationEmailDomainsRequest({
          organizationId: currentOrganizationId,
          emailDomains,
        })
      ),
    [dispatch, currentOrganizationId]
  )

  return {
    currentOrganization,
    currentOrganizationId,
    isCurrentOrganizationPersonal,
    onSetCurrentOrganization,
    onUpdateWorkspaceEmailDomains,
    organizations,
    currentOrganizationUsers,
  }
}

export default useOrganization
