/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
const { promisify } = require('util')
const path = require('path')
const moment = require('moment')
// const Worker = require('./android-recording.worker.js')
const platform = require('./platform')

if (!window.require) {
  window.require = () => ({})
}

const ADB_EXE = 'adb'

const { ipcRenderer } = window.require('electron')

const getBasePath = () => window.require('electron').remote.app.getAppPath()
const getAppPath = () =>
  getBasePath().indexOf('app.asar') > 0
    ? getBasePath().replace('/app.asar', '')
    : getBasePath()

const getDependencies = () => {
  if (!platform.isElectron()) return { adbPath: '', execAsync: () => {} }

  const basePath = getBasePath()
  const adbPaths =
    basePath.indexOf('app.asar') > 0
      ? [basePath.replace('/app.asar', ''), 'bin', ADB_EXE]
      : [basePath, 'platform-tools', 'mac', ADB_EXE]

  const adbPath = path.join(...adbPaths)

  const childProcess = window.require('child_process')
  const fs = window.require('fs')
  const execAsync = promisify(childProcess.exec)
  const { spawn } = childProcess
  return { adbPath, execAsync, spawn, fs }
}

const getDeviceProp = async deviceId => {
  if (!platform.isElectron()) return null
  const { adbPath, execAsync } = getDependencies()
  return execAsync(`${adbPath} -s ${deviceId} shell getprop ro.product.model`)
}

const getDeviceIP = async deviceId => {
  if (!platform.isElectron()) return null
  const { adbPath, execAsync } = getDependencies()
  return execAsync(`${adbPath}-s ${deviceId} shell ip route | awk '{print $9}'`)
}

const getDeviceScreenSize = async deviceId => {
  if (!platform.isElectron()) return null
  try {
    const { adbPath, execAsync } = getDependencies()
    const sizeString = await execAsync(
      `${adbPath} -s ${deviceId} shell wm size | awk '{print $3}'`
    )
    const [width, height] = sizeString.split('x')
    return {
      width: parseInt(width, 10),
      height: parseInt(height, 10),
    }
  } catch (err) {
    console.error(err)
    return { width: 0, height: 0 }
  }
}

const listDevices = async () => {
  if (!platform.isElectron()) return null
  try {
    const { adbPath, execAsync } = getDependencies()
    const devices = await execAsync(`${adbPath} devices`)
    const lines = devices
      .toString()
      .split('\n')
      .filter(line => line.split('\t').length === 2)

    return Promise.map(lines, async device => {
      const [id] = device.split('\t')
      const model = await getDeviceProp(id)
      return { id, name: model, type: 'android' }
    })
  } catch (err) {
    console.error(err)
    return []
  }
}

const startRecording = deviceId => {
  if (!platform.isElectron()) return null
  try {
    ipcRenderer.send('startRecording', deviceId)
    // const worker = new Worker()
    // const basePath = getBasePath()
    // worker.postMessage({ deviceId, basePath })
    // return worker

    // const { adbPath, spawn } = getDependencies()
    // return spawn(
    //   adbPath,
    //   [`-s ${deviceId}`, 'shell', 'screenrecord', '/sdcard/ux.mp4'],
    //   { detached: true }
    // )
    return {}
  } catch (err) {
    console.error(err)
    return null
  }
}

const stopRecording = () => {
  try {
    ipcRenderer.send('stopRecording')
    // worker.terminate()
    // window.process.kill(-child.pid)
  } catch (err) {
    console.error(err)
  }
}

// const stopRecording = child => {
//   try {
//     // _worker.terminate()
//     window.process.kill(-child.pid)
//   } catch (err) {
//     console.error(err)
//   }
// }

const pullRecording = async (deviceId, targetFolder) => {
  try {
    const { adbPath, execAsync, fs } = getDependencies()
    if (!fs.existsSync(targetFolder)) {
      fs.mkdirSync(targetFolder)
    }
    const destination = `${targetFolder}/${moment().format(
      'YYYY-MM-DD-HH-mm-ss'
    )}.mp4`
    console.log('>>> moving file to ', destination)
    await execAsync(
      `${adbPath} -s ${deviceId} pull /sdcard/ux.mp4 ${destination}`
    )
    return destination
  } catch (err) {
    console.error(err)
    return ''
  }
}

export default {
  getBasePath,
  getAppPath,
  listDevices,
  getDeviceProp,
  getDeviceIP,
  getDeviceScreenSize,
  startRecording,
  stopRecording,
  pullRecording,
}
