const LAMBDAS_URL = 'https://ebvvuvihhj.execute-api.eu-central-1.amazonaws.com'

export const S3_BUCKET_NAME = 'iterspace-bucket-prod'
export const S3_BUCKET_URL = `https://${S3_BUCKET_NAME}.s3.eu-central-1.amazonaws.com`

const S3_PUBLIC_BUCKET_NAME = 'iterspace-bucket-public-prod'
export const S3_PUBLIC_BUCKET_URL = `https://${S3_PUBLIC_BUCKET_NAME}.s3.eu-central-1.amazonaws.com`

export const S3_PICTURE_URL = `${S3_PUBLIC_BUCKET_URL}/pictures`

export const JIRA_CLIENT_ID = 'p73xEj6xzCPTd66pxxDhIlxodDD3FS88'

export default {
  // All local ext ids
  chromeExtensionIds: [
    'ndebhkoacpejphbpmblobofohncnhbjg',
    'ecpihpkdobifbbfedbpjkpholahjgdod',
    'fekkcenohlnpiifkjdeiglfkbokopjap',
    'nhckkgdflngcjlnbfnimligmhjnpbpkg', // edge beta extension
    'dadlchfgjmiafgfjpclfnbicpbckdokb', // chrome beta extension
  ],
  chromeExtensionUrl:
    'https://chrome.google.com/webstore/detail/iterspace/ndebhkoacpejphbpmblobofohncnhbjg?hl=it&authuser=0',
  edgeExtensionUrl:
    'https://microsoftedge.microsoft.com/addons/detail/iterspace-screen-rec-/ecpihpkdobifbbfedbpjkpholahjgdod',
  websiteUrl: 'https://app.iterspace.com/',
  libraryWorkspaceId: 'organization:2c9e1e27-9c78-4772-9838-1a2075d90dcd',
  appUrls: {
    mac: `${S3_PUBLIC_BUCKET_URL}/desktopApps/iterspace-0.2.6-dev.dmg`,
  },
  recaptcha: {
    siteKey: '6LcX5LoZAAAAAIvPYSyf0e80rNJ1JmDTjIZ-zHT7',
  },
  Lambdas: {
    recording: `${LAMBDAS_URL}/recording`,
    sprites: `${LAMBDAS_URL}/sprites`,
    posters: `${LAMBDAS_URL}/sprites/poster`,
    share: `${LAMBDAS_URL}/share`,
    organizationMemberAdd: `${LAMBDAS_URL}/organization-member-add`,
    pictureUpload: `${LAMBDAS_URL}/pictureupload?email={email}&imageType={imageType}`,
    publicRemoteTest: `${LAMBDAS_URL}/remote-test/`,
    jiraRedirectUrl: `${LAMBDAS_URL}/integrations/jira`,
    jiraAvatarUrl: `${LAMBDAS_URL}/integrations/jira/avatar`,
    trelloRedirectUrl: `${LAMBDAS_URL}/integrations/trello`,
    slackRedirectUrl: `${LAMBDAS_URL}/integrations/slack`,
  },
  integrations: {
    slack: {
      authorizeUrl: 'https://slack.com/oauth/v2/authorize',
      clientId: '715507303138.1841018120215',
      scope:
        'chat:write.public,chat:write.customize,chat:write,channels:read,im:read,mpim:read,users:read',
      userScope:
        'channels:read,chat:write,groups:read,im:read,mpim:read,users:read',
    },
  },
  Google: {
    AppClientId:
      '936619655044-ljunbst241ev2o0mi15vl6lbst0ou2ks.apps.googleusercontent.com',
    OptimizeUrl: 'https://www.googleoptimize.com/optimize.js?id=OPT-N8JQQ9Q',
    AnalyticsPropertyForGoogleOptimize: 'UA-158158213-1',
  },
  region: 'eu-central-1',
  IdentityPoolId: 'eu-central-1:044c8f4b-85ef-4388-93c3-d665035a53aa',
  UserPoolId: 'eu-central-1_lNp3Phoxv',
  ClientId: '4b6bhvutb6g0qkuc3lv7h341lt',
  AWS: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-central-1:044c8f4b-85ef-4388-93c3-d665035a53aa',

      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'eu-central-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-central-1_lNp3Phoxv',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '4b6bhvutb6g0qkuc3lv7h341lt',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: '.yourdomain.com',
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: true,
      // },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',
      aws_appsync_graphqlEndpoint:
        'https://upgcz42emvayhcup3j3y5b3ds4.appsync-api.eu-central-1.amazonaws.com/graphql',
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'AWS_IAM',
      // aws_appsync_apiKey: 'da2-jrrjm6yzfbh7ffewria455hsx4',
    },
    Storage: {
      AWSS3: {
        bucket: S3_BUCKET_NAME, // REQUIRED -  Amazon S3 bucket
        region: 'eu-central-1', // OPTIONAL -  Amazon service region
      },
    },
  },
  Firebase: {
    apiKey: 'AIzaSyDlWxjv8wJ408-ZklVaAKfecg-tiFCPiu0',
    authDomain: 'iterspace-prod.firebaseapp.com',
    databaseURL: 'https://iterspace-prod.firebaseio.com',
    projectId: 'iterspace-prod',
    storageBucket: 'iterspace-prod.appspot.com',
    messagingSenderId: '986716426239',
    appId: '1:986716426239:web:6fe588ec96d05bce47a1ca',
    measurementId: 'G-K78HJS1NY0',
  },
  GTM: {
    gtmId: 'GTM-TN7PQQB',
  },
  Sentry: {
    dsn:
      'https://a66cb6798d184595b240cf8f09d629f5@o1087982.ingest.sentry.io/6102360',
    environment: 'production',
    tracesSampleRate: 0.5,
    normalizeDepth: 10,
  },
}
