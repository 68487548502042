import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import fromUser from '../../../store/user/user.selectors'
import UserAvatar from './UserAvatar'

const hasCount = badge => badge !== undefined

const StyledUserAvatar = styled(UserAvatar)`
  figure {
    box-shadow: ${({ badge, theme }) =>
      hasCount(badge) ? `0 0 0 2px ${theme.colors.secondary}` : ''};
  }

  figure::after {
    transition: all 0.3s ease-out;
    background-color: ${({ badge, theme }) =>
      hasCount(badge) ? theme.colors.secondary : ''};
  }
`

const CurrentUserAvatar = ({ ...props }) => {
  const picture = useSelector(fromUser.getPicture)
  const name = useSelector(fromUser.getName)

  return (
    <StyledUserAvatar
      {...props}
      imgUrl={picture}
      name={name}
      tooltipText="You"
    />
  )
}

export default CurrentUserAvatar
