import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'

const PageContainer = styled(Flex)`
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

const CenterTemplate = ({ children, ...props }) => (
  <PageContainer {...props}>{children}</PageContainer>
)

CenterTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CenterTemplate
