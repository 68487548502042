import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { LG } from '@zendeskgarden/react-typography'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Alert, Title } from '@zendeskgarden/react-notifications'
import { Formik, Form, ErrorMessage } from 'formik'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import CenterTemplate from '../../templates/CenterTemplate'
import Flex from '../../atoms/Flex'
import Brand from '../../atoms/Brand'
import Anchor from '../../atoms/Anchor'
import { StyledErrorMessage } from '../../commonStyledComponents'
import { confirmPassword } from '../../../util/auth'

const StyledCenterTemplate = styled(CenterTemplate)`
  padding: 24px;
  box-sizing: border-box;
`

const StyledBrand = styled(Brand)`
  width: 60%;
  max-width: 300px;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const StyledForm = styled(Form)`
  margin: 64px 64px 0 64px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const WarningAlert = styled(Alert)`
  margin-top: 16px;
  color: ${prop('theme.colors.yellow800')};
  background-color: ${prop('theme.colors.yellow100')};
  border-color: ${prop('theme.colors.yellow300')};
  text-align: left;
`

const SuccessAlert = styled(Alert)`
  margin-top: 16px;
  color: ${prop('theme.colors.green800')};
  background-color: ${prop('theme.colors.green100')};
  border-color: ${prop('theme.colors.green300')};
  text-align: left;
`

const WarningTitle = styled(Title)`
  color: ${prop('theme.colors.yellow900')} !important;
`

const formInitialValues = {
  password: '',
  repeatPassword: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.password) {
    errors.password = 'The password is required'
  } else if (values.password.length < 8) {
    errors.password = 'The password must be longer than 8 characters'
  } else if (values.repeatPassword !== values.password) {
    errors.repeatPassword = 'The two password must be the same'
  }
  return errors
}

const LoginPage = ({ ...props }) => {
  // eslint-disable-next-line no-restricted-globals
  const { email, code } = queryString.parse(location.search)
  const history = useHistory()

  useEffect(() => {
    if (!email || !code) {
      history.push('/login')
    }
  }, [email, code])

  const [isEmailNotConfirmed, setEmailNotConfirmed] = useState(false)
  const [isGoToForgotPassword, setGoToForgotPassword] = useState(false)
  const [isError, setError] = useState(false)
  const [isSuccess, setSuccess] = useState(false)

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true)
    confirmPassword(email, code, values.password)
      .then(() => {
        setSuccess(true)
      })
      .catch(error => {
        setSubmitting(false)
        if (error.code === 'UserNotConfirmedException') {
          setEmailNotConfirmed(true)
          return
        }
        if (error.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          setGoToForgotPassword(true)
        } else if (error.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          setError(true)
        }
        setError(true)
      })
  }

  return (
    <StyledCenterTemplate {...props}>
      <StyledBrand />
      <Formik
        initialValues={formInitialValues}
        validate={validateFormValues}
        onSubmit={onSubmitHandler}
        render={({
          values,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
        }) => (
          <React.Fragment>
            <StyledForm>
              <CenteredBox style={{ marginTop: '32px' }} direction="row">
                <LG>Create your new password</LG>
              </CenteredBox>
              <CenteredBox>
                <Field>
                  <Input
                    type="password"
                    name="password"
                    autocomplete="current-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    validation={errors.password ? 'warning' : undefined}
                    placeholder="Password"
                    style={{ marginTop: '16px' }}
                  />
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="password"
                  />
                </Field>
                <Field>
                  <Input
                    type="password"
                    name="repeatPassword"
                    autocomplete="current-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repeatPassword}
                    validation={errors.repeatPassword ? 'warning' : undefined}
                    placeholder="Repeat password"
                    style={{ marginTop: '16px' }}
                  />
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="repeatPassword"
                  />
                </Field>
              </CenteredBox>
              <CenteredBox>
                <Button
                  isStretched
                  isPrimary
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Reset password
                </Button>
                {isEmailNotConfirmed && (
                  <WarningAlert type="warning">
                    <WarningTitle>Verify your email</WarningTitle>
                    Lost the verification email?{' '}
                    <Anchor href="/forgot_password">
                      Reset your password
                    </Anchor>{' '}
                    to get a new one
                  </WarningAlert>
                )}
                {isGoToForgotPassword && (
                  <WarningAlert type="warning">
                    Reset your password is required{' '}
                    <Anchor href="/forgot_password">Go and reset</Anchor>
                  </WarningAlert>
                )}
                {isError && (
                  <WarningAlert type="warning">
                    Ops, an unexpected error occurred, please retry later
                  </WarningAlert>
                )}
                {isSuccess && (
                  <SuccessAlert type="success">
                    Password reset with success!{' '}
                    <Anchor href="/login">Log in</Anchor>
                  </SuccessAlert>
                )}
              </CenteredBox>
            </StyledForm>
          </React.Fragment>
        )}
      />
    </StyledCenterTemplate>
  )
}

export default LoginPage
