import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import prop from 'lodash/fp/prop'
import copy from 'copy-to-clipboard'
import { v1 as uuidv1 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { pending } from 'redux-saga-thunk'
import { Chrome, Sidebar, Main } from '@zendeskgarden/react-chrome'
import { MD } from '@zendeskgarden/react-typography'
import TagManager from 'react-gtm-module'
import RotatingWords from '../../molecules/Skeletons/RotatingWords'
import Flex from '../../atoms/Flex/Flex'
import { DESKTOP_DRAG_BAR_HEIGHT } from '../../atoms/DesktopDragBar/DesktopDragBar'
import DeviceSelector from '../../molecules/DeviceSelector'
import SessionsListMobile from '../SessionsListMobile'
import ProjectsSidebar from './ProjectsSidebar'
import ProjectTitle from './ProjectTitle'
import useEntryView from './useEntryView'
import useFirstLanding from '../../hooks/useFirstLanding'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'
import ProductSelector from '../../molecules/ProductSelector'
import ConfirmationModal from '../../molecules/ConfirmationModal/ConfirmationModal'
import LibraryModal from '../../molecules/LibraryModal'
import {
  deleteProjectRequest,
  UPDATE_PROJECTS_REQUEST,
} from '../../../store/projects/projects.actions'
import { deleteSessionRequest } from '../../../store/sessions/sessions.actions'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import {
  deleteRemoteTestRequest,
  deleteRemoteTestSessionRequest,
} from '../../../store/remoteTests/remoteTests.actions'
import ShareModal from '../../molecules/ShareModal'
import RecordingModal from '../../molecules/RecordingModal/RecordingModal'
import useRecordingUpload from '../../hooks/useRecordingUpload'
import useBrowserExtensionInstalled from '../../hooks/useBrowserExtensionInstalled'
import useTrello from '../../hooks/useTrello'
import useJira from '../../hooks/useJira'
import useSlack from '../../hooks/useSlack'
import fromSessions from '../../../store/sessions/sessions.selectors'
import CreateProjectModal from '../../molecules/CreateProjectModal'
import OrganizationEmptyState from '../../molecules/OrganizationEmptyState'
import RequestRecordingLinkModal from '../../molecules/RequestRecordingLinkModal'
import WorkspaceUpsellModal from '../../molecules/WorkspaceUpsellModal'
import ExtensionUpsell from '../../molecules/ExtensionUpsell'
import CreateOrganizationModal from '../../molecules/CreateOrganizationModal'
import CreateJiraIssueModal from '../../molecules/CreateJiraIssueModal'
import CreateTrelloCardModal from '../../molecules/CreateTrelloCardModal'
import SendSlackMessageModal from '../../molecules/SendSlackMessageModal'
import {
  isMobile,
  isChromeLikeBrowser,
  isEdgeBrowser,
} from '../../../lib/platform'
import { getRemoteTestUrl, getSessionUrl } from '../../../lib/urls'
import { getProjectPathName } from '../../../util/paths'
import { MAIN_VIEWS } from '../../pages/MainAppPage/MainAppPage.constants'
import analytics from '../../../lib/analytics'
import appConfig from '../../../config'
import UploadAccordionProxy from '../UploadAccordionProxy'
import useEventsListener from '../../../store/hooks/useEventsListener'

const Wrapper = styled.div``

const StyledChrome = styled(Chrome)`
  height: calc(100vh - 52px - ${DESKTOP_DRAG_BAR_HEIGHT}px);
  background-color: ${prop('theme.colors.grey100')};
`

const StyledSessionsListMobile = styled(SessionsListMobile)`
  box-sizing: border-box;
  width: auto;
  flex-grow: 1;
`

const StyledSidebar = styled(Sidebar)`
  padding: 0;
  background-color: white;
  overflow: visible;
  overflow-y: scroll;
`

const StyledMain = styled(Main)`
  padding: 24px;
  background-color: ${prop('theme.colors.grey100')};
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-start;

  > div {
    max-width: 1024px;
    @media only screen and (min-width: 1700px) {
      margin-left: -10%;
    }
  }
`

const ProjectTitleFlex = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  margin-bottom: 24px;
  padding: 0;
`

const StyledDeviceSelector = styled(DeviceSelector)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`

const ProjectsBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  max-width: 800px;
  margin-right: 32px;
`

const ActionsBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  min-width: 200px;
  max-width: 316px;
`

const SectionCaption = styled(MD)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  margin: 16px 0;
  font-weight: bold;
  color: ${prop('theme.colors.grey400')};
`

const StyledOrganizationEmptyState = styled(OrganizationEmptyState)`
  && {
    margin-left: 0px !important;
    width: 100%;

    max-width: 100%;
  }
`

const DangerText = styled.span`
  color: ${prop('theme.colors.warning')};
  font-weight: bold;
`

const isPending = state => pending(state, UPDATE_PROJECTS_REQUEST)

const EntryView = ({ setCurrentView, onSettings, ...props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    addOnLoadEventListener,
    addOnPopStateEventListener,
  } = useEventsListener()

  addOnLoadEventListener()
  addOnPopStateEventListener()

  useFirstLanding({ localStorageKey: 'main-was-redirected' })
  const {
    currentOrganization,
    currentOrganizationId,
    currentProjectId,
    goToSession,
    isProjectModalVisible,
    onSetCurrentProject,
    PERSONAL_ORGANIZATION_ID,
    sessions,
    remoteTests,
    setProjectModalVisible,
    currentUserCanAddSessions,
    userName,
    userId,
    identityId,
    currentProject,
  } = useEntryView()

  const isLoadingProjects = useSelector(isPending)
  const projects = useSelector(fromSessions.getProjectsMergedSessions)

  // useEffect() are called in the order they appear

  useEffect(() => {
    // Listen for the current data layer
    TagManager.initialize({
      gtmId: appConfig.GTM.gtmId,
      dataLayerName: 'UserDataLayer',
      events: {
        // If key and value doesn't match,
        // problem may arise and trigger data duplication
        project_switch: 'project_switch',
      },
    })
  }, [])

  useEffect(() => {
    // Send data only over project and organisation
    // We need to account for isLoadingProjects var otherwise
    // we trigger it multiple time due to re-rendering
    if (!isLoadingProjects && currentProject && currentOrganization) {
      // Send data
      TagManager.dataLayer({
        dataLayerName: 'UserDataLayer',
        dataLayer: {
          // We need our own event declared otherwise on every-page re-render
          // (excluding isLoadingProjects variable) we trigger it multiple time
          // (and we don't want that)
          event: 'project_switch',
          userId: identityId || userId,
          userName,
          currentProjectId,
          currentProject: currentProject.title,
          currentOrganizationId,
          currentOrganization: currentOrganization.title,
        },
      })
    }
  }, [currentProject, currentOrganization, isLoadingProjects])

  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true)
  const [lastSkeletonOrganizationId, setLastSkeletonOrganizationId] = useState(
    null
  )
  useEffect(() => {
    if (
      projects?.length === 0 &&
      isLoadingProjects &&
      currentOrganizationId !== lastSkeletonOrganizationId
    ) {
      setShouldShowSkeleton(true)
      setLastSkeletonOrganizationId(currentOrganizationId)
      return
    }
    setShouldShowSkeleton(false)
  }, [currentOrganizationId, projects?.length, isLoadingProjects])

  const [isDeleteSessionModalVisible, setDeleteSessionModalVisible] = useState(
    false
  )
  const [isDeleteProjectModalVisible, setDeleteProjectModalVisible] = useState(
    false
  )
  const [isShareModalVisible, setShareModalVisible] = useState(false)
  const [isRecordingModalVisible, setRecordingModalVisible] = useState(false)
  const [selectedSession, setSelectedSession] = useState('')
  const [selectedProject, setSelectedProject] = useState('')

  const [
    isRemoteTestShareModalVisible,
    setRemoteTestShareModalVisible,
  ] = useState(false)
  const [
    isDeleteRemoteTestModalVisible,
    setDeleteRemoteTestModalVisible,
  ] = useState(false)
  const [selectedRemoteTest, setSelectedRemoteTest] = useState('')

  const onRemoteTestDelete = useCallback(
    remoteTestId => {
      dispatch(deleteRemoteTestRequest({ remoteTestId }))
        .then(() => {
          setDeleteRemoteTestModalVisible(false)
        })
        .catch(err => {
          console.error('remote test delete an error occurred', err)
        })
    },
    [dispatch]
  )

  const [
    isDeleteRemoteTestSessionModalVisible,
    setDeleteRemoteTestSessionModalVisible,
  ] = useState(false)
  const [selectedRemoteTestSession, setSelectedRemoteTestSession] = useState('')

  const onRemoteTestSessionDelete = useCallback(
    sessionId => {
      dispatch(deleteRemoteTestSessionRequest({ sessionId }))
        .then(() => {
          setDeleteRemoteTestSessionModalVisible(false)
        })
        .catch(err => {
          console.error('remote test session delete an error occurred', err)
        })
    },
    [dispatch]
  )

  const onProjectDelete = useCallback(
    projectId => {
      dispatch(deleteProjectRequest(projectId))
        .then(() => {
          setDeleteProjectModalVisible(false)

          const nextProject = projects.find(
            project => project?.pk !== projectId
          )
          if (nextProject) {
            history.push(getProjectPathName(nextProject.pk))
            onSetCurrentProject(nextProject.pk)
            return
          }
          history.push('/app')
        })
        .catch(err => {
          console.error('session deleted an error occurred', err)
        })
    },
    [dispatch, projects]
  )

  const onSessionDelete = useCallback(
    sessionId => {
      dispatch(deleteSessionRequest(sessionId))
        .then(() => {
          setDeleteSessionModalVisible(false)
        })
        .catch(err => {
          console.error('session deleted an error occurred', err)
        })
    },
    [dispatch]
  )

  const { screenRecordingHandler } = useRecordingUpload()

  const onCreateProject = () => setProjectModalVisible(true)

  const [
    isRequestRecordingLinkModalVisible,
    setRequestRecordingLinkModalVisible,
  ] = useState(false)
  const [requestRecordingTitle, setRequestRecordingTitle] = useState('')
  const [requestRecordingUrl, setRequestRecordingUrl] = useState('')

  const [isUpsellModalVisible, setUpsellModalVisible] = useState(false)
  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)

  const isBrowserExtensionInstalled = useBrowserExtensionInstalled()
  const [
    isExtensionUpsellModalVisible,
    setExtensionUpsellModalVisible,
  ] = useState(false)

  const [libraryModalProduct, setLibraryModalProduct] = useState(null)
  const [isLibraryModalVisible, setLibraryModalVisible] = useState(false)
  const onSpecificProductClick = product => {
    setLibraryModalProduct(product)
    setLibraryModalVisible(true)
    analytics.trackProductSelectorClick({ product: product.title })
  }

  const onScreenRecordingClick = useCallback(() => {
    if (
      (isChromeLikeBrowser() || isEdgeBrowser()) &&
      !isBrowserExtensionInstalled
    ) {
      setLibraryModalVisible(false)
      setExtensionUpsellModalVisible(true)
      return false
    }
    if (
      (isChromeLikeBrowser() || isEdgeBrowser()) &&
      isBrowserExtensionInstalled
    ) {
      return true
    }
    setRecordingModalVisible(true)
    return false
  }, [
    isChromeLikeBrowser,
    isBrowserExtensionInstalled,
    setRecordingModalVisible,
  ])

  const { hasJira } = useJira()
  const { hasTrello } = useTrello()
  const { hasSlack } = useSlack()

  const [
    isJiraCreateIssueModalVisible,
    setJiraCreateIssueModalVisible,
  ] = useState(false)
  const [jiraIssueDescription, setJiraIssueDescription] = useState('')
  const [jiraIssueResourceId, setJiraIssueResourceId] = useState('')
  const [jiraIssueResourceUrl, setJiraIssueResourceUrl] = useState('')

  const [
    isTrelloCreateCardModalVisible,
    setTrelloCreateCardModalVisible,
  ] = useState(false)
  const [trelloCardSessionId, setTrelloCardSessionId] = useState(null)

  const [
    isSendSlackMessageModalVisible,
    setSendSlackMessageModalVisible,
  ] = useState(false)
  const [slackSessionId, setSlackSessionId] = useState(null)

  const { shouldShowCreation } = useLibraryWorkspace()

  return (
    <Wrapper {...props}>
      <StyledChrome>
        {projects.length > 0 && (
          <StyledSidebar>
            <ProjectsSidebar
              onProjectDelete={_projectId => {
                setSelectedProject(_projectId)
                setDeleteProjectModalVisible(true)
              }}
            />
          </StyledSidebar>
        )}
        <StyledMain>
          {shouldShowSkeleton && <RotatingWords />}
          {!shouldShowSkeleton && projects.length === 0 && (
            <StyledOrganizationEmptyState
              onClick={onCreateProject}
              organizationTitle={
                currentOrganizationId === PERSONAL_ORGANIZATION_ID
                  ? null
                  : currentOrganization?.title
              }
            />
          )}
          <Flex
            flexGrow={1}
            style={{ alignSelf: projects.length === 0 ? 'center' : 'auto' }}
          >
            {projects.length > 0 && (
              <>
                <ProjectTitleFlex>
                  <ProjectTitle
                    onUpsell={() => {
                      setUpsellModalVisible(true)
                    }}
                  />
                  {currentProjectId && (
                    <StyledDeviceSelector onSessionStart={goToSession} />
                  )}
                </ProjectTitleFlex>
                <Flex direction="row" flexGrow={1} style={{ width: '100%' }}>
                  <ProjectsBox>
                    {(sessions.length > 0 || remoteTests.length > 0) && (
                      <SectionCaption>Recordings</SectionCaption>
                    )}
                    <StyledSessionsListMobile
                      hasSettings
                      onSessionClick={goToSession}
                      onSessionDelete={_sessionId => {
                        setSelectedSession(_sessionId)
                        setDeleteSessionModalVisible(true)
                      }}
                      onSessionCopy={_sessionId => {
                        copy(getSessionUrl(_sessionId))
                        dispatch(
                          addNotification({
                            notificationId: uuidv1(),
                            text: 'Link copied to clipboard',
                          })
                        )
                      }}
                      onSessionShare={_sessionId => {
                        setSelectedSession(_sessionId)
                        setShareModalVisible(true)
                      }}
                      onRemoteTestLink={({ title, remoteTestId }) => {
                        setRequestRecordingTitle(title)
                        setRequestRecordingUrl(getRemoteTestUrl(remoteTestId))
                        setRequestRecordingLinkModalVisible(true)
                      }}
                      onRemoteTestEdit={remoteTestId => {
                        setCurrentView({
                          name: MAIN_VIEWS.createRemoteTest,
                          props: { remoteTestId },
                        })
                      }}
                      onRemoteTestDelete={_remoteTest => {
                        setSelectedRemoteTest(_remoteTest)
                        setDeleteRemoteTestModalVisible(true)
                      }}
                      onRemoteTestShare={_remoteTest => {
                        setSelectedRemoteTest(_remoteTest)
                        setRemoteTestShareModalVisible(true)
                      }}
                      onRemoteTestSessionDelete={_remoteTestSession => {
                        setSelectedRemoteTestSession(_remoteTestSession)
                        setDeleteRemoteTestSessionModalVisible(true)
                      }}
                      onJiraClick={({ sessionId }) => {
                        if (!hasJira) {
                          onSettings()
                          return
                        }
                        setJiraIssueDescription('')
                        setJiraIssueResourceId(sessionId)
                        setJiraIssueResourceUrl(getSessionUrl(sessionId))
                        setJiraCreateIssueModalVisible(true)
                      }}
                      onTrelloClick={({ sessionId }) => {
                        if (!hasTrello) {
                          onSettings()
                          return
                        }
                        setTrelloCreateCardModalVisible(true)
                        setTrelloCardSessionId(sessionId)
                      }}
                      onSlackClick={({ sessionId }) => {
                        if (!hasSlack) {
                          onSettings()
                          return
                        }
                        setSendSlackMessageModalVisible(true)
                        setSlackSessionId(sessionId)
                      }}
                    />
                  </ProjectsBox>
                  {shouldShowCreation && (
                    <ActionsBox>
                      <SectionCaption>Create new</SectionCaption>
                      <ProductSelector
                        onRemoteTestClick={() =>
                          setCurrentView({ name: MAIN_VIEWS.createRemoteTest })
                        }
                        onScreenRecordingClick={onScreenRecordingClick}
                        isDisabled={
                          !currentUserCanAddSessions &&
                          currentOrganizationId !== PERSONAL_ORGANIZATION_ID
                        }
                        onSpecificProductClick={product =>
                          onSpecificProductClick(product)
                        }
                      />
                    </ActionsBox>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </StyledMain>
      </StyledChrome>
      {isDeleteSessionModalVisible && (
        <ConfirmationModal
          isDanger
          title="Delete recording"
          text="Are you sure to delete the recording?"
          actionButtonText="Delete"
          onClose={() => {
            setDeleteSessionModalVisible(false)
          }}
          onSubmit={() => {
            onSessionDelete(selectedSession)
          }}
        />
      )}
      {isLibraryModalVisible && (
        <LibraryModal
          title="title"
          text="body"
          actionButtonText="Start recording"
          onClose={() => setLibraryModalVisible(false)}
          onSubmit={() => onScreenRecordingClick()}
          product={libraryModalProduct}
        />
      )}
      {isDeleteRemoteTestModalVisible && (
        <ConfirmationModal
          isDanger
          title={`Delete remote test ${selectedRemoteTest?.title}`}
          text={
            <>
              Are you sure to delete the test?
              <br />
              <DangerText>All the user recordings will be lost.</DangerText>
            </>
          }
          actionButtonText="Delete"
          onClose={() => {
            setDeleteRemoteTestModalVisible(false)
          }}
          onSubmit={() => {
            onRemoteTestDelete(selectedRemoteTest.pk)
          }}
        />
      )}
      {isDeleteRemoteTestSessionModalVisible && (
        <ConfirmationModal
          isDanger
          title={`Delete session ${selectedRemoteTestSession?.title}`}
          text="Are you sure to delete the session?"
          actionButtonText="Delete"
          onClose={() => {
            setDeleteRemoteTestSessionModalVisible(false)
          }}
          onSubmit={() => {
            onRemoteTestSessionDelete(selectedRemoteTestSession.pk)
          }}
        />
      )}
      {isDeleteProjectModalVisible && (
        <ConfirmationModal
          title="Delete project"
          isDanger
          text={
            <>
              <Flex>Are you sure you want to delete this project?</Flex>
              <Flex>
                All recordings and share links will be permanently destroyed.
              </Flex>
            </>
          }
          actionButtonText="Delete"
          onClose={() => {
            setDeleteProjectModalVisible(false)
          }}
          onSubmit={() => {
            onProjectDelete(selectedProject)
          }}
        />
      )}
      {isShareModalVisible && selectedSession && (
        <ShareModal
          isVisible={isShareModalVisible}
          sessionId={selectedSession}
          onClose={() => {
            setTimeout(() => setShareModalVisible(false), 100)
          }}
          onCreateWorkspace={() => {
            setShareModalVisible(false)
            setCreateOrganizationModalVisible(true)
          }}
        />
      )}
      {isRemoteTestShareModalVisible && selectedRemoteTest?.pk && (
        <ShareModal
          isVisible={isRemoteTestShareModalVisible}
          remoteTestId={selectedRemoteTest?.pk}
          onClose={() => {
            setTimeout(() => setRemoteTestShareModalVisible(false), 100)
          }}
          onCreateWorkspace={() => {
            setRemoteTestShareModalVisible(false)
            setCreateOrganizationModalVisible(true)
          }}
        />
      )}
      {isRecordingModalVisible && (
        <RecordingModal
          isVisible={isRecordingModalVisible}
          onSave={({ content, mimeType }) => {
            screenRecordingHandler({ content, mimeType })
            setRecordingModalVisible(false)
          }}
          onClose={() => setRecordingModalVisible(false)}
        />
      )}
      {isProjectModalVisible && (
        <CreateProjectModal
          isVisible={isProjectModalVisible}
          onClose={() => setProjectModalVisible(false)}
        />
      )}
      <RequestRecordingLinkModal
        title={requestRecordingTitle}
        url={requestRecordingUrl}
        isVisible={isRequestRecordingLinkModalVisible}
        onClose={() => setRequestRecordingLinkModalVisible(false)}
      />
      <WorkspaceUpsellModal
        isVisible={isUpsellModalVisible}
        onClose={() => setUpsellModalVisible(false)}
        onCreate={() => setCreateOrganizationModalVisible(true)}
      />
      <ExtensionUpsell
        isVisible={isExtensionUpsellModalVisible}
        onClose={() => setExtensionUpsellModalVisible(false)}
      />
      <UploadAccordionProxy />
      {!isMobile() && (
        <CreateOrganizationModal
          isVisible={isCreateOrganizationModalVisible}
          onClose={() => {
            setCreateOrganizationModalVisible(false)
          }}
        />
      )}
      {isJiraCreateIssueModalVisible && (
        <CreateJiraIssueModal
          isVisible
          initialDescription={jiraIssueDescription}
          resourceId={jiraIssueResourceId}
          resourceUrl={jiraIssueResourceUrl}
          sessionId={jiraIssueResourceId}
          onClose={() => setJiraCreateIssueModalVisible(false)}
        />
      )}
      {isTrelloCreateCardModalVisible && (
        <CreateTrelloCardModal
          sessionId={trelloCardSessionId}
          onClose={() => setTrelloCreateCardModalVisible(false)}
        />
      )}
      {isSendSlackMessageModalVisible && (
        <SendSlackMessageModal
          sessionId={slackSessionId}
          onClose={() => setSendSlackMessageModalVisible(false)}
        />
      )}
    </Wrapper>
  )
}

EntryView.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired,
}

EntryView.whyDidYouRender = false

export default withApollo(EntryView)
