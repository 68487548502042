import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import PlayIcon from 'mdi-react/PlayIcon'
import PauseIcon from 'mdi-react/PauseIcon'
import VolumeHighIcon from 'mdi-react/VolumeHighIcon'
import VolumeOffIcon from 'mdi-react/VolumeOffIcon'
import FastForward10Icon from 'mdi-react/FastForward10Icon'
import Rewind10Icon from 'mdi-react/Rewind10Icon'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import IconButton from '../../molecules/IconButton'

const Wrapper = styled(Flex)`
  width: 100vw;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  border-top: 1px solid ${prop('theme.colors.grey200')};
`

const ControlBox = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${prop('theme.colors.grey200')};

  :last-child {
    border-right: none;
  }
`

const StyledIconButton = styled(IconButton).attrs({
  isBasic: true,
})`
  padding: 4px;
`

const PlaybackRateButton = styled(Button).attrs({
  isPrimary: true,
  isBasic: true,
  size: 'small',
  isStretched: false,
})`
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  padding: 0 8px;
  cursor: pointer;
`

const PlayerMobile = ({
  isPlaying,
  isPlayHighlighted,
  hasAudio,
  videoVolume,
  playbackRate,
  onPlay,
  onPause,
  onRewind,
  onFastForward,
  onVolumeOff,
  onVolumeOn,
  onPlaybackRate,
  ...props
}) => (
  <Wrapper {...props}>
    <ControlBox>
      {!isPlaying && (
        <StyledIconButton
          icon={PlayIcon}
          isBasic={!isPlayHighlighted}
          isPrimary={isPlayHighlighted}
          iconProps={{
            size: 40,
          }}
          alt="play"
          onClick={onPlay}
        />
      )}
      {isPlaying && (
        <StyledIconButton
          icon={PauseIcon}
          iconProps={{
            size: 40,
          }}
          alt="pause"
          onClick={onPause}
        />
      )}
    </ControlBox>
    <ControlBox>
      <StyledIconButton
        icon={Rewind10Icon}
        iconProps={{
          size: 40,
        }}
        alt="rewind"
        onClick={onRewind}
      />
    </ControlBox>
    <ControlBox>
      <StyledIconButton
        icon={FastForward10Icon}
        iconProps={{
          size: 40,
        }}
        alt="fast forward"
        onClick={onFastForward}
      />
    </ControlBox>
    {hasAudio && (
      <ControlBox>
        <StyledIconButton
          icon={videoVolume === 0 ? VolumeOffIcon : VolumeHighIcon}
          onClick={() => (videoVolume === 0 ? onVolumeOn() : onVolumeOff())}
        />
      </ControlBox>
    )}
    <ControlBox>
      <PlaybackRateButton onClick={onPlaybackRate}>
        {playbackRate}x
      </PlaybackRateButton>
    </ControlBox>
  </Wrapper>
)

PlayerMobile.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  isPlayHighlighted: PropTypes.bool,
  hasAudio: PropTypes.bool.isRequired,
  videoVolume: PropTypes.number,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onRewind: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeOff: PropTypes.func,
  onVolumeOn: PropTypes.func,
  onPlaybackRate: PropTypes.func,
  playbackRate: PropTypes.number,
}

PlayerMobile.defaultProps = {
  isPlayHighlighted: false,
  videoVolume: 1,
  playbackRate: 1,
  onPlay: () => {},
  onPause: () => {},
  onRewind: () => {},
  onFastForward: () => {},
  onVolumeOff: () => {},
  onVolumeOn: () => {},
  onPlaybackRate: () => {},
}

export default PlayerMobile
