import { useState, useCallback } from 'react'
import gql from 'graphql-tag'
import { useDispatch } from 'react-redux'
import { setProjectTitle } from '../projects/projects.actions'
import { getAppSyncClient } from '../../lib/appSyncClient'
import { updateProject as updateProjectGql } from '../../graphql/mutations'

function useUpdateProject(projectId) {
  const apolloClient = getAppSyncClient()
  const [isProjectUpdating, setProjectUpdating] = useState(false)
  const [updateError, setUpdateError] = useState(null)

  const updateProject = useCallback(data => {
    setUpdateError(null)
    setProjectUpdating(true)

    apolloClient
      .mutate({
        mutation: gql(updateProjectGql),
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            projectId,
            ...data,
          },
        },
      })
      .then(() => {
        setUpdateError(null)
        setProjectUpdating(false)
      })
      .catch(err => {
        console.error(err)
        setUpdateError(err)
        setProjectUpdating(false)
      })
  })

  const dispatch = useDispatch()
  const setTitle = useCallback(({ projectId: _projectId, title }) =>
    dispatch(setProjectTitle({ projectId: _projectId, title }))
  )

  return {
    isProjectUpdating,
    updateProject,
    updateError,
    setTitle,
  }
}

export default useUpdateProject
