import React from 'react'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'
import LibraryModal from './LibraryModal'

const LibraryModalContainer = ({ ...props }) => {
  const { isLibraryWorkspacePresent } = useLibraryWorkspace()
  return (
    <LibraryModal
      {...props}
      isLibraryWorkspacePresent={isLibraryWorkspacePresent}
    />
  )
}

export default LibraryModalContainer
