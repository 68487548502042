import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { v1 as uuidv1 } from 'uuid'
import { LG } from '@zendeskgarden/react-typography'
import { Button } from '@zendeskgarden/react-buttons'
import { Label } from '@zendeskgarden/react-forms'
import { useDispatch, useSelector } from 'react-redux'
import Flex from '../../atoms/Flex'
import appConfig, { JIRA_CLIENT_ID } from '../../../config'
import { getJWT } from '../../../util/auth'
import JiraLogoSVG from '../../../assets/mark-gradient-blue-jira-software.svg'
import TrelloLogoSVG from '../../../assets/mark-gradient-blue-trello.svg'
import SlackLogoSVG from '../../../assets/Slack_Mark.svg'
import {
  getJiraRequest,
  removeJiraRequest,
  updateJiraRequest,
} from '../../../store/integrations/jira/integrations.jira.actions'
import {
  getTrelloRequest,
  removeTrelloRequest,
  updateTrelloRequest,
} from '../../../store/integrations/trello/integrations.trello.actions'
import {
  getSlackRequest,
  removeSlackRequest,
  updateSlackRequest,
} from '../../../store/integrations/slack/integrations.slack.actions'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import fromJiraIntegrations from '../../../store/integrations/jira/integrations.jira.selectors'
import fromTrelloIntegrations from '../../../store/integrations/trello/integrations.trello.selectors'
import fromSlackIntegrations from '../../../store/integrations/slack/integrations.slack.selectors'
import analytics from '../../../lib/analytics'

const Wrapper = styled(Flex)``

const StyledTitle = styled(LG)`
  padding: 16px;
`

const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`

const StyledLabel = styled(Label)`
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`

const Logo = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 8px;
`

const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`
const ButtonBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  font-weight: 300;
  letter-spacing: 0.06em;
`

const onJiraClick = e => {
  e.preventDefault()
  e.stopPropagation()
  getJWT().then(jwt => {
    const jiraUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${JIRA_CLIENT_ID}&scope=read:jira-work%20read:jira-user%20write:jira-work%20manage:jira-configuration%20offline_access&redirect_uri=${appConfig.Lambdas.jiraRedirectUrl}&state=${jwt}&response_type=code&prompt=consent`
    window.open(jiraUrl)
    analytics.trackConnectJira()
  })
}

const onTrelloClick = e => {
  e.preventDefault()
  e.stopPropagation()
  getJWT().then(jwt => {
    const trelloUrl = `${appConfig.Lambdas.trelloRedirectUrl}?jwt=${jwt}`
    window.open(trelloUrl)
    analytics.trackConnectTrello()
  })
}

const onSlackClick = e => {
  e.preventDefault()
  e.stopPropagation()
  getJWT().then(jwt => {
    const slackUrl = `${appConfig.integrations.slack.authorizeUrl}?client_id=${appConfig.integrations.slack.clientId}&scope=${appConfig.integrations.slack.scope}&user_scope=${appConfig.integrations.slack.userScope}&redirect_uri=${appConfig.Lambdas.slackRedirectUrl}&state=${jwt}`
    window.open(slackUrl)
    analytics.trackConnectSlack()
  })
}

const IntegrationsSetting = ({ ...props }) => {
  const dispatch = useDispatch()
  const hasJira = useSelector(fromJiraIntegrations.getHasJira)
  useEffect(() => {
    dispatch(updateJiraRequest())
  }, [])

  const hasTrello = useSelector(fromTrelloIntegrations.getHasTrello)
  useEffect(() => {
    dispatch(updateTrelloRequest())
  }, [])

  const hasSlack = useSelector(fromSlackIntegrations.getHasSlack) || false
  useEffect(() => {
    dispatch(updateSlackRequest())
  }, [])

  const onRemoveJiraClick = useCallback(() => {
    dispatch(removeJiraRequest())
      .then(() => dispatch(getJiraRequest()))
      .catch(() => {
        dispatch(
          addNotification({
            notificationId: uuidv1(),
            text: 'An error occurred',
          })
        )
      })
  }, [dispatch])

  const onRemoveTrelloClick = useCallback(() => {
    dispatch(removeTrelloRequest())
      .then(() => dispatch(getTrelloRequest()))
      .catch(() => {
        dispatch(
          addNotification({
            notificationId: uuidv1(),
            text: 'An error occurred',
          })
        )
      })
  }, [dispatch])

  const onRemoveSlackClick = useCallback(() => {
    dispatch(removeSlackRequest())
      .then(() => dispatch(getSlackRequest()))
      .catch(() => {
        dispatch(
          addNotification({
            notificationId: uuidv1(),
            text: 'An error occurred',
          })
        )
      })
  }, [dispatch])

  return (
    <Wrapper {...props}>
      <StyledTitle>Integrations</StyledTitle>
      <SettingRow>
        <StyledLabel>
          <Logo src={JiraLogoSVG} alt="Jira" />
          <span>Jira</span>
        </StyledLabel>
        <Edited>
          <ButtonBox>
            {hasJira ? (
              <Button isBasic size="small" onClick={onRemoveJiraClick}>
                Remove
              </Button>
            ) : (
              <Button isPrimary size="small" onClick={onJiraClick}>
                Connect
              </Button>
            )}
          </ButtonBox>
        </Edited>
      </SettingRow>
      <SettingRow>
        <StyledLabel>
          <Logo src={TrelloLogoSVG} alt="Trello" style={{ height: '26px' }} />
          <span>Trello</span>
        </StyledLabel>
        <Edited>
          <ButtonBox>
            {hasTrello ? (
              <Button isBasic size="small" onClick={onRemoveTrelloClick}>
                Remove
              </Button>
            ) : (
              <Button isPrimary size="small" onClick={onTrelloClick}>
                Connect
              </Button>
            )}
          </ButtonBox>
        </Edited>
      </SettingRow>
      <SettingRow>
        <StyledLabel>
          <Logo
            src={SlackLogoSVG}
            alt="Trello"
            style={{
              height: '22px',
              width: '22px',
              marginLeft: '4px',
              marginRight: '14px',
            }}
          />
          <span>Slack</span>
        </StyledLabel>
        <Edited>
          <ButtonBox>
            {hasSlack ? (
              <Button isBasic size="small" onClick={onRemoveSlackClick}>
                Remove
              </Button>
            ) : (
              <Button isPrimary size="small" onClick={onSlackClick}>
                Connect
              </Button>
            )}
          </ButtonBox>
        </Edited>
      </SettingRow>
    </Wrapper>
  )
}

IntegrationsSetting.propTypes = {}

IntegrationsSetting.defaultProps = {}

export default IntegrationsSetting
