import { get, set, merge } from 'dot-prop-immutable'
import moment from 'moment'
import { buildReducerForActions } from '../../util/reduxTools'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import {
  SET_CURRENT_ORGANIZATION,
  CREATE_NEW_ORGANIZATION_REQUEST_SUCCESS,
  UPDATE_ORGANIZATION_REQUEST_SUCCESS,
  GET_ORGANIZATIONS_REQUEST_SUCCESS,
  UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_SUCCESS,
} from './organizations.actions'

export const STATE_KEY = 'organizations'

const getHumanReadableDate = momentDate => momentDate.format('lll')

export const INITIAL_STATE = {
  current: 'personal',
  byId: {},
  allIds: [],
}

const reducer = {
  [SET_CURRENT_ORGANIZATION]: (state, { payload: { organizationId } }) =>
    set(state, 'current', organizationId),
  [GET_ORGANIZATIONS_REQUEST_SUCCESS]: (
    state,
    { payload: { organizations = [] } }
  ) =>
    organizations.reduce(
      (acc, organization) => {
        acc.byId[organization.pk] = organization
        acc.allIds.push(organization.pk)
        return acc
      },
      {
        ...state,
        byId: {},
        allIds: [],
      }
    ),

  [CREATE_NEW_ORGANIZATION_REQUEST_SUCCESS]: (
    state,
    { payload: { organizationId, createdBy, createdAt, ...organization } }
  ) => {
    const date = moment.unix(createdAt)
    const newState = set(state, `byId.${organizationId}`, {
      id: organizationId,
      createdBy,
      createdAt,
      createdAtHuman: getHumanReadableDate(date),
      ...organization,
    })
    return { ...newState, allIds: [...newState.allIds, organizationId] }
  },
  [UPDATE_ORGANIZATION_REQUEST_SUCCESS]: (
    state,
    { payload: { organizationId, ...organization } }
  ) => {
    const date = moment.unix()
    return merge(state, `byId.${organizationId}`, {
      ...organization,
      updatedAt: date,
      updatedAtHuman: getHumanReadableDate(date),
    })
  },
  [UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_SUCCESS]: (
    state,
    { payload: { organizationId, emailDomains } }
  ) => {
    const organization = get(state, `byId.${organizationId}`)
    return merge(state, `byId.${organizationId}`, {
      ...organization,
      emailDomains,
    })
  },
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
