import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'
import ExtensionUpsellModalSvg from '../../../assets/extension_upsell_modal.svg'
import GoogleChromeIcon from '../../../assets/google_chrome_icon.svg'
import EdgeIcon from '../../../assets/edge_icon_white.svg'
import { openExtensionPage } from '../../../lib/browserExtension'
import { isChromeLikeBrowser, getBrowserName } from '../../../lib/platform'

const StyledHeader = styled(Header)`
  && {
    border-bottom: none;
  }
`

const Title = styled(Flex)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${prop('theme.colors.grey800')};
`
const Description = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: ${prop('theme.colors.grey600')};
  padding: 16px;
  margin-bottom: 24px;
  line-height: 24px;
  align-self: center;
`

const Image = styled.img`
  margin-bottom: 32px;
`

const ExtensionUpsell = ({ isVisible, onClose }) => (
  <>
    {isVisible && (
      <Modal
        onClose={onClose}
        isAnimated={false}
        isLarge
        style={{ width: '400px' }}
      >
        <StyledHeader />
        <Body>
          <Flex direction="column">
            <Image src={ExtensionUpsellModalSvg} alt="image" />
            <Flex>
              <Title justifyContent="center" alignItems="center">
                You&apos;re almost ready!
              </Title>
              <Description>
                Install the {getBrowserName()} extension to add comments while
                recording
              </Description>
              <IconButton
                isPrimary
                size="large"
                icon={isChromeLikeBrowser() ? GoogleChromeIcon : EdgeIcon}
                onClick={() => {
                  openExtensionPage()
                  onClose()
                }}
              >
                Open {getBrowserName()} Store
              </IconButton>
            </Flex>
          </Flex>
        </Body>
        <Close aria-label="Close modal" onClick={onClose} />
      </Modal>
    )}
  </>
)

ExtensionUpsell.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

ExtensionUpsell.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default ExtensionUpsell
