import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'
import MobileTopBar from '../MobileTopBar'
import CommentDialog from '../CommentDialog'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import { isIOS } from '../../../lib/platform'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 24px;
  box-sizing: border-box;
  flex-direction: row;
  justify-self: flex-start;
`

const safariFlexFix = css`
  flex-shrink: 0;
  flex-basis: auto;
`

const StyledCommentDialog = styled(CommentDialog)`
  ${safariFlexFix}
  width: 100%;
  box-sizing: border-box;
`

const NewReplyModalMobile = ({
  comment,
  commentNumber,
  currentUserName,
  onCopyLink,
  onResolveCommentClick,
  onClose,
  isCommentInEditMode,
  replyIdInEditMode,
}) => {
  const [autoFocusToken, setAutoFocusToken] = useState(null)

  useEffect(() => {
    if (!isCommentInEditMode && !replyIdInEditMode) {
      if (!isIOS()) {
        setTimeout(() => setAutoFocusToken(Math.random().toString()), 100)
      }

      if (isIOS()) {
        setTimeout(() => setAutoFocusToken(Math.random().toString()), 500)
      }
    }
  }, [isCommentInEditMode, replyIdInEditMode])

  const header = (
    <StyledMobileTopBar rightIcon={CloseIcon} onRightButtonClick={onClose}>
      Thread
    </StyledMobileTopBar>
  )

  const textRef = useRef(null)
  useEffect(() => {
    if (textRef?.current) {
      textRef.current.style.height = '25vh'
    }
  }, [textRef?.current])

  const body = (
    <Body>
      <StyledCommentDialog
        autoFocusToken={autoFocusToken}
        isCommentInEditMode={isCommentInEditMode}
        replyIdInEditMode={replyIdInEditMode}
        comment={comment}
        isHighlighted
        isCommentCopyLinkEnabled
        tagText={commentNumber}
        isResolved={comment?.isResolved}
        currentUserName={currentUserName}
        showJira={false}
        showTrello={false}
        showSlack={false}
        onCopyLink={onCopyLink}
        onResolveCommentClick={({ commentId: _commentId, isResolved }) => {
          onResolveCommentClick({
            commentId: _commentId,
            isResolved,
          })
        }}
      />
    </Body>
  )

  return (
    <MobileModal position="absolute">
      <MobileTemplate
        header={header}
        body={body}
        footer={null}
        bodyStyle={{ justifyContent: 'flex-start' }}
      />
    </MobileModal>
  )
}

NewReplyModalMobile.propTypes = {
  onClose: PropTypes.func.isRequired,
  comment: PropTypes.shape({
    isResolved: PropTypes.bool,
  }).isRequired,
  commentNumber: PropTypes.number.isRequired,
  currentUserName: PropTypes.string.isRequired,
  onCopyLink: PropTypes.func.isRequired,
  onResolveCommentClick: PropTypes.func.isRequired,
  isCommentInEditMode: PropTypes.bool.isRequired,
  replyIdInEditMode: PropTypes.string.isRequired,
}

export default NewReplyModalMobile
