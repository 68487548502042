import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { Header, HeaderItemWrapper } from '@zendeskgarden/react-chrome'
import UserSettingsMenu from '../../molecules/UserSettingsMenu'
import { HEADER_HEIGHT } from '../MainAppPage/MainAppPage.constants'
import IconButton from '../../molecules/IconButton/IconButton'

const StyledHeader = styled(Header)`
  flex: 0 0 ${HEADER_HEIGHT}px;
  order: 0;
  z-index: 100;
  box-shadow: ${prop('theme.boxShadows.topBarElevation')};
`

const WorkspaceSettingsTopBar = ({
  userName,
  onSettings,
  onLogout,
  onBack,
  ...props
}) => {
  return (
    <StyledHeader {...props}>
      <HeaderItemWrapper maxX>
        <IconButton
          icon={ArrowLeftIcon}
          size="medium"
          isBasic
          onClick={onBack}
          data-test="back-to-workspace-button"
        >
          Back to workspace
        </IconButton>
      </HeaderItemWrapper>
      <UserSettingsMenu
        userName={userName}
        onSettings={onSettings}
        onLogout={onLogout}
      />
    </StyledHeader>
  )
}

WorkspaceSettingsTopBar.propTypes = {
  userName: PropTypes.string,
  onSettings: PropTypes.func,
  onLogout: PropTypes.func,
  onBack: PropTypes.func,
}

WorkspaceSettingsTopBar.defaultProps = {
  userName: '',
  onSettings: () => {},
  onLogout: () => {},
  onBack: () => {},
}

export default WorkspaceSettingsTopBar
