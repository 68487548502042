import React, { useState } from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'
import Flex from '../../atoms/Flex'
import RichEditorWithMentions from '../RichEditorWithMentions'
import useMentionSuggestions from '../../../store/hooks/useMentionSuggestions'
import { MentionSuggestionType } from '../MentionSuggestion/MentionSuggestion'

const Wrapper = styled(Flex)``

const CommentTextWrapper = styled(Flex)`
  position: relative;
`

const StyledRichEditorWithMentions = styled(RichEditorWithMentions)`
  margin: 0;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 6px;
  color: ${prop('theme.colors.grey800')};
  border-color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.secondary300 : theme.colors.grey100};
`

const CommentEditableText = ({
  autoFocusToken,
  placeholder,
  initialValue,
  resetToken,
  onChange,
  onAddedMention,
  onRemovedMention,
  mentions,
  onFocus,
  onBlur,
  textRef,
  ...props
}) => {
  const { suggestions } = useMentionSuggestions()
  const [isTextAreaFocused, setTextAreaFocused] = useState(false)

  const onInputHandler = text => onChange(text)

  return (
    <Wrapper {...props}>
      <CommentTextWrapper>
        <StyledRichEditorWithMentions
          mentions={mentions}
          suggestions={suggestions}
          initialValue={initialValue}
          textRef={textRef}
          isFocused={isTextAreaFocused}
          autoFocusToken={autoFocusToken}
          resetToken={resetToken}
          placeholder={placeholder}
          onInput={onInputHandler}
          onChange={onInputHandler}
          onFocus={() => {
            setTextAreaFocused(true)
            onFocus()
          }}
          onBlur={() => {
            setTextAreaFocused(false)
            onBlur()
          }}
          onAddedMention={onAddedMention}
          onRemovedMention={onRemovedMention}
        />
      </CommentTextWrapper>
    </Wrapper>
  )
}

CommentEditableText.propTypes = {
  autoFocusToken: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onAddedMention: PropTypes.func,
  onRemovedMention: PropTypes.func,
  resetToken: PropTypes.string,
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  textRef: PropTypes.shape({}),
}

CommentEditableText.defaultProps = {
  autoFocusToken: '',
  placeholder: '',
  initialValue: '',
  onChange: () => {},
  onAddedMention: () => {},
  onRemovedMention: () => {},
  resetToken: '',
  mentions: [],
  onFocus: () => {},
  onBlur: () => {},
  textRef: { current: null },
}

export default CommentEditableText
