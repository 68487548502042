import { useMemo, useState } from 'react'
import uniq from 'lodash/uniq'
import { useSelector } from 'react-redux'
import useProjectsHook from './useProjectsHook'
import fromRemoteTests from '../remoteTests/remoteTests.selectors'
import useDeepEffect from '../../components/hooks/useDeepEffect'

const useRemoteTestsHook = () => {
  const { projectSharedWith } = useProjectsHook()

  const storeRemoteTests = useSelector(
    fromRemoteTests.getRemoteTestsByCurrentProject
  )

  const computedRemoteTests = useMemo(
    () =>
      storeRemoteTests.map(remoteTests => {
        const { admin, edit, view } = remoteTests
        const _admin = admin || []
        const _edit = edit || []
        const _view = view || []
        const sharedWith = uniq(projectSharedWith.concat(_admin, _edit, _view))
        const isPrivate = sharedWith.length === 0
        const isShared = sharedWith.length > 0
        return {
          ...remoteTests,
          isPrivate,
          isShared,
          isPublic: !!remoteTests?.sharing?.isPublic,
          sharedWithCounter: sharedWith.length,
        }
      }),

    [storeRemoteTests, projectSharedWith]
  )

  const [remoteTests, setRemoteTests] = useState([])
  useDeepEffect(() => {
    setRemoteTests(computedRemoteTests)
  }, [computedRemoteTests])

  return {
    remoteTests,
  }
}

export default useRemoteTestsHook
