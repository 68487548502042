import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import EmoticonOutlineIcon from 'mdi-react/EmoticonOutlineIcon'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton'

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  opacity: 0.5;

  && {
    border: none;
  }
`

const EmojiButton = ({
  isEmojiPickerVisible,
  setEmojiPickerVisible,
  textareaCursorPosition,
  setTextareaCursorPosition,
  insertEmoji,
  textRef,
  placement,
  ...props
}) => {
  return (
    <Flex {...props}>
      <StyledIconButton
        icon={EmoticonOutlineIcon}
        className="emoji-mart"
        isBasic
        active={isEmojiPickerVisible}
        onClick={() => {
          setEmojiPickerVisible(!isEmojiPickerVisible)
        }}
      />
      {isEmojiPickerVisible && (
        <Picker
          onSelect={insertEmoji}
          showPreview={false}
          showSkinTones={false}
          style={{
            position: 'absolute',
            top: placement === 'bottom' ? '40px' : 'auto',
            bottom: placement === 'top' ? '40px' : 'auto',
            right: '4px',
            zIndex: 1000,
          }}
        />
      )}
    </Flex>
  )
}

EmojiButton.propTypes = {
  isEmojiPickerVisible: PropTypes.bool.isRequired,
  setEmojiPickerVisible: PropTypes.func.isRequired,
  textareaCursorPosition: PropTypes.number.isRequired,
  setTextareaCursorPosition: PropTypes.func.isRequired,
  insertEmoji: PropTypes.func.isRequired,
  textRef: PropTypes.shape({}).isRequired,
  placement: PropTypes.string,
}

EmojiButton.defaultProps = {
  placement: 'bottom',
}

export default EmojiButton
