import axios from 'axios'
import appConfig from '../config'
import { getJWT } from '../util/auth'
import video from '../lib/video'

const getRecording = async sessionId => {
  const jwt = await getJWT()
  return axios({
    method: 'get',
    url: appConfig.Lambdas.recording,
    params: {
      sessionId: sessionId.replace('session:', ''),
      supportedCodecs: video.getSupportedVideoCodecs(),
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const getExtensionRecording = async recordingId => {
  return axios({
    method: 'get',
    url: appConfig.Lambdas.recording,
    params: {
      recordingId,
      supportedCodecs: video.getSupportedVideoCodecs(),
    },
  })
}

const getPublicRecording = async sessionId =>
  axios({
    method: 'get',
    url: appConfig.Lambdas.recording,
    params: {
      sessionId: sessionId.replace('session:', ''),
      supportedCodecs: video.getSupportedVideoCodecs(),
    },
  })

const getSprites = async sessionId => {
  const jwt = await getJWT()
  return axios({
    method: 'get',
    url: appConfig.Lambdas.sprites,
    params: {
      sessionId: sessionId.replace('session:', ''),
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const getPosters = async sessionIds => {
  const jwt = await getJWT()
  return axios({
    method: 'post',
    url: appConfig.Lambdas.posters,
    data: {
      sessionIds,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const getPublicSprites = async sessionId =>
  axios({
    method: 'get',
    url: appConfig.Lambdas.sprites,
    params: {
      sessionId: sessionId.replace('session:', ''),
    },
  })

const share = async ({
  projectId,
  sessionId,
  remoteTestId,
  permission,
  userEmail,
  inviterIdentityId,
}) => {
  const jwt = await getJWT()
  return axios({
    method: 'post',
    url: appConfig.Lambdas.share,
    data: {
      body: {
        projectId,
        sessionId,
        remoteTestId,
        relation: permission,
        userEmail,
        inviterIdentityId,
      },
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const addOrganizationMember = async ({
  organizationId,
  userEmail,
  userRole,
  inviterIdentityId,
}) => {
  const jwt = await getJWT()
  return axios({
    method: 'post',
    url: appConfig.Lambdas.organizationMemberAdd,
    data: {
      body: {
        organizationId,
        userEmail,
        userRole,
        inviterIdentityId,
      },
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

const getRemoteTest = async remoteTestId =>
  axios({
    method: 'get',
    url: `${appConfig.Lambdas.publicRemoteTest}${remoteTestId.replace(
      'user-test:',
      ''
    )}`,
  })

const getJiraAvatar = async avatarUrl => {
  const jwt = await getJWT()
  return axios({
    method: 'get',
    url: `${appConfig.Lambdas.jiraAvatarUrl}`,
    params: {
      url: avatarUrl,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

export default {
  addOrganizationMember,
  getExtensionRecording,
  getJiraAvatar,
  getPublicRecording,
  getPublicSprites,
  getRecording,
  getRemoteTest,
  getSprites,
  getPosters,
  share,
}
