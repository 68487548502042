import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Field, Label, Toggle, Input } from '@zendeskgarden/react-forms'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import Flex from '../../atoms/Flex'
import { SHARE_TYPES } from './ProjectShare.constants'
import ProjectShareTypeSelection from './ProjectShareTypeSelection'
import ProjectOwner from './ProjectOwner'
import IconButton from '../IconButton'

const StyledBody = styled(Body)`
  padding: 24px 40px;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareableLinkTextField = EmailTextField

const CopyButton = styled(IconButton)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
`

const NormalLabel = styled(Flex)`
  font-weight: normal;
  margin-bottom: 6px;
  flex-direction: row;
`

const ShareModal = ({
  areCommentsAllowedState,
  copyButtonText,
  enrichedUsers,
  isCurrentUserOwner,
  isFormLoading,
  isLogged,
  isModalVisible,
  isPersonalWorkspace,
  isPublicState,
  isShareButtonEnabled,
  isUserContributor,
  onAddUser,
  onModalClose,
  onRemoveUser,
  remoteTestId,
  resourceName,
  session,
  setCommentsAllowed,
  setCopyButtonText,
  setIsPublic,
  setShareValue,
  shareableLink,
  title,
  totalContributors,
  totalWorkspaceMembers,
  updateSessionSharing,
  workspaceName,
  shareType,
  onUpdateProjectShareTypeHandler,
}) => {
  const [currentShareType, setCurrentShareType] = useState(shareType)
  useEffect(() => {
    if (currentShareType) {
      onUpdateProjectShareTypeHandler(currentShareType)
    }
  }, [currentShareType])

  const plural = totalContributors === 1 ? '' : 's'

  const projectOwnerUser = enrichedUsers.find(user => user.isOwner)

  const isProjectShareTypeSelectionVisible =
    isCurrentUserOwner && !isPersonalWorkspace

  return (
    <div>
      {isModalVisible && (
        <Modal isAnimated={false}>
          <Header style={{ textAlign: 'left', fontWeight: 'normal' }}>
            <div style={{ width: '80%' }}>
              Share {resourceName}
              <br />
              <b>{title}</b>
            </div>
          </Header>
          <StyledBody>
            <Flex>
              {!remoteTestId && (
                <Flex style={{ marginBottom: '16px' }}>
                  <Field>
                    <NormalLabel style={{ display: 'block' }}>
                      {currentShareType === SHARE_TYPES.anyone && (
                        <>
                          <b>Anyone at {workspaceName}</b>{' '}
                          <span>can open the project with this link</span>
                        </>
                      )}
                      {currentShareType === SHARE_TYPES.selectedMembers && (
                        <>
                          <b>
                            Only {totalContributors} member{plural} of{' '}
                            {workspaceName}
                          </b>{' '}
                          <span>can open with this link</span>
                        </>
                      )}
                    </NormalLabel>
                  </Field>
                  <Flex direction="row">
                    <ShareableLinkTextField>
                      <Input type="text" defaultValue={shareableLink} />
                    </ShareableLinkTextField>
                    <CopyButton
                      isPrimary
                      icon={ContentCopyIcon}
                      iconProps={{
                        size: 20,
                      }}
                      onClick={() => {
                        copy(shareableLink)
                        setCopyButtonText('Copied')
                        setTimeout(() => {
                          if (copyButtonText) {
                            setCopyButtonText('Copy')
                          }
                        }, 2000)
                      }}
                    >
                      {copyButtonText}
                    </CopyButton>
                  </Flex>
                </Flex>
              )}
              {isLogged && isUserContributor && session && (
                <Grid style={{ marginBottom: '16px' }}>
                  <Row style={{ padding: '8px 0' }}>
                    <Col>
                      <Field>
                        <Toggle
                          checked={isPublicState}
                          onChange={event => {
                            const value = event.target.checked
                            setIsPublic(value)
                            let commentsAllowed = areCommentsAllowedState
                            if (!value) {
                              commentsAllowed = false
                              setCommentsAllowed(commentsAllowed)
                            }
                            updateSessionSharing({
                              isPublic: value,
                              areCommentsAllowed: commentsAllowed,
                            })
                          }}
                        >
                          <Label regular>Public</Label>
                        </Toggle>
                      </Field>
                    </Col>
                  </Row>

                  <Row style={{ padding: '8px 0' }}>
                    <Col>
                      <Field>
                        <Toggle
                          disabled={!isPublicState}
                          checked={areCommentsAllowedState}
                          onChange={event => {
                            setCommentsAllowed(event.target.checked)
                            updateSessionSharing({
                              isPublic: isPublicState,
                              areCommentsAllowed: event.target.checked,
                            })
                          }}
                        >
                          <Label regular>Allow comments</Label>
                        </Toggle>
                      </Field>
                    </Col>
                  </Row>
                </Grid>
              )}
            </Flex>
            {isProjectShareTypeSelectionVisible && (
              <ProjectShareTypeSelection
                currentShareType={currentShareType}
                enrichedUsers={enrichedUsers}
                isInviteButtonDisabled={!isShareButtonEnabled || isFormLoading}
                onAddUser={onAddUser}
                onRemoveUser={onRemoveUser}
                setCurrentShareType={setCurrentShareType}
                setEmailShareValue={setShareValue}
                showUserBox={
                  isLogged &&
                  (isUserContributor || isCurrentUserOwner) &&
                  !isPersonalWorkspace
                }
                totalContributors={totalContributors}
                totalWorkspaceMembers={totalWorkspaceMembers}
                workspaceName={workspaceName}
                showMembersRecap={false}
              />
            )}
            {projectOwnerUser &&
              (!isProjectShareTypeSelectionVisible ||
                currentShareType === SHARE_TYPES.anyone) && (
                <>
                  <Field style={{ marginTop: '8px' }}>
                    <NormalLabel>
                      Only the owner can change sharing settings
                    </NormalLabel>
                  </Field>
                  <ProjectOwner
                    name={projectOwnerUser.name}
                    email={projectOwnerUser.email}
                    picture={projectOwnerUser.picture}
                    isOwner
                  />
                </>
              )}
          </StyledBody>
          <Close aria-label="Close modal" onClick={onModalClose} />
        </Modal>
      )}
    </div>
  )
}

ShareModal.propTypes = {
  remoteTestId: PropTypes.string,
  areCommentsAllowedState: PropTypes.bool.isRequired,
  copyButtonText: PropTypes.string.isRequired,
  enrichedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCurrentUserOwner: PropTypes.bool.isRequired,
  isFormLoading: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  isPersonalWorkspace: PropTypes.bool.isRequired,
  isPublicState: PropTypes.bool.isRequired,
  isShareButtonEnabled: PropTypes.bool.isRequired,
  isUserContributor: PropTypes.bool.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  session: PropTypes.shape({}).isRequired,
  setCommentsAllowed: PropTypes.func.isRequired,
  setCopyButtonText: PropTypes.func.isRequired,
  setIsPublic: PropTypes.func.isRequired,
  setShareValue: PropTypes.func.isRequired,
  shareableLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalContributors: PropTypes.number.isRequired,
  totalWorkspaceMembers: PropTypes.number.isRequired,
  updateSessionSharing: PropTypes.func.isRequired,
  workspaceName: PropTypes.string,
  shareType: PropTypes.oneOf(Object.values(SHARE_TYPES)),
  onUpdateProjectShareTypeHandler: PropTypes.func,
}

ShareModal.defaultProps = {
  remoteTestId: '',
  workspaceName: '',
  shareType: null,
  onUpdateProjectShareTypeHandler: () => {},
}

export default ShareModal
