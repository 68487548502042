import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import EditableText from '../../atoms/EditableText'

const Wrapper = styled(Flex)``

const Description = styled(Flex)``

const StyledEditableText = styled(EditableText)`
  border: 1px solid black;
  box-sizing: border-box;
  background-color: transparent;
  border-color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.grey300 : theme.colors.grey200};
`

const InfoBox = styled(Flex)`
  flex-direction: column;
  margin-top: 24px;
`

const Row = styled(Flex)`
  flex-direction: row;
  padding: 2px 0;
  font-size: 14px;
  line-height: 32px;
`

const Label = styled(Flex)`
  color: ${prop('theme.colors.grey')};
  width: 80px;
`

const Value = styled(Flex)`
  color: ${prop('theme.colors.primary')};
`

const SessionInfo = ({
  description,
  osName,
  osVersion,
  browserName,
  browserVersion,
  screenSize,
  windowSize,
  deviceType,
  deviceVendor,
  deviceEngineName,
  deviceEngineVersion,
  locale,
  timeZone,
  isReadOnly,
  onDescriptionChange,
  ...props
}) => {
  const [isTextAreaFocused, setTextAreaFocused] = useState(false)

  return (
    <Wrapper {...props}>
      <Description>
        <StyledEditableText
          readOnly={isReadOnly}
          isFocused={isTextAreaFocused}
          textAreaProps={{
            defaultValue: description,
            style: { backgroundColor: 'transparent' },
            placeholder: 'Description...',
            onChange: e => {
              console.log('>>>', e.target.value)
              onDescriptionChange(e.target.value)
            },
            onFocus: () => {
              setTextAreaFocused(true)
            },
            onBlur: () => {
              setTextAreaFocused(false)
            },
          }}
        />
      </Description>
      <InfoBox>
        {osName && (
          <Row>
            <Label>Platform</Label>
            <Value>
              {osName} {osVersion}
            </Value>
          </Row>
        )}
        {browserName && (
          <Row>
            <Label>Browser</Label>
            <Value>
              {browserName} {browserVersion}
            </Value>
          </Row>
        )}
        {deviceEngineName && (
          <Row>
            <Label>Engine</Label>
            <Value>
              {deviceEngineName} {deviceEngineVersion}
            </Value>
          </Row>
        )}
        {!!screenSize && (
          <Row>
            <Label>Screen</Label>
            <Value>
              {screenSize.width} x {screenSize.height}
            </Value>
          </Row>
        )}
        {!!windowSize && (
          <Row>
            <Label>Window</Label>
            <Value>
              {windowSize.width} x {windowSize.height}
            </Value>
          </Row>
        )}
        {deviceType && (
          <Row>
            <Label>Type</Label>
            <Value>{deviceType}</Value>
          </Row>
        )}
        {deviceVendor && (
          <Row>
            <Label>Vendor</Label>
            <Value>{deviceVendor}</Value>
          </Row>
        )}
        {locale && (
          <Row>
            <Label>Locale</Label>
            <Value>{locale}</Value>
          </Row>
        )}
        {timeZone && (
          <Row>
            <Label>Timezone</Label>
            <Value>{timeZone}</Value>
          </Row>
        )}
      </InfoBox>
    </Wrapper>
  )
}

const Size = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
})

SessionInfo.propTypes = {
  description: PropTypes.string,
  osName: PropTypes.string,
  osVersion: PropTypes.string,
  browserName: PropTypes.string,
  browserVersion: PropTypes.string,
  screenSize: Size,
  windowSize: Size,
  deviceType: PropTypes.string,
  deviceVendor: PropTypes.string,
  deviceEngineName: PropTypes.string,
  deviceEngineVersion: PropTypes.string,
  locale: PropTypes.string,
  timeZone: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onDescriptionChange: PropTypes.func,
}

SessionInfo.defaultProps = {
  description: '',
  osName: '',
  osVersion: '',
  browserName: '',
  browserVersion: '',
  screenSize: null,
  windowSize: null,
  deviceType: '',
  deviceVendor: '',
  deviceEngineName: '',
  deviceEngineVersion: '',
  locale: '',
  timeZone: '',
  isReadOnly: true,
  onDescriptionChange: () => {},
}

export default SessionInfo
