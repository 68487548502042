import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Alert, Title } from '@zendeskgarden/react-notifications'
import { LG } from '@zendeskgarden/react-typography'
import { Formik, Form, ErrorMessage } from 'formik'
import { trackLoginRequest } from '../../../store/analytics/analytics.actions'
import CenterTemplate from '../../templates/CenterTemplate'
import Flex from '../../atoms/Flex'
import Link from '../../atoms/Link'
import Brand from '../../atoms/Brand'
import { StyledErrorMessage } from '../../commonStyledComponents'
import GoogleButton from '../../molecules/GoogleButton'
import {
  setNotGoogleLogin,
  resendConfirmationEmail,
  getIsGoogleLoginAvailable,
} from '../../../util/auth'

const StyledCenterTemplate = styled(CenterTemplate)`
  padding: 24px;
  box-sizing: border-box;
`

const StyledBrand = styled(Brand)`
  width: 60%;
  max-width: 300px;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const StyledForm = styled(Form)`
  margin: 64px 64px 0 64px;
  border-bottom: 1px solid ${prop('theme.colors.grey200')};
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledLink = styled(Link)`
  display: inline-flex;
  margin-left: 8px;
`

const StyledWarningAlert = styled(Alert)`
  margin-top: 16px;
  color: ${prop('theme.colors.yellow800')};
  background-color: ${prop('theme.colors.yellow100')};
  border-color: ${prop('theme.colors.yellow300')};
  text-align: left;
`

const StyledEmailWarningAlert = styled(StyledWarningAlert)`
  margin-top: 0;
  margin-bottom: 16px;
`

const StyledWarningTitle = styled(Title)`
  color: ${prop('theme.colors.yellow900')} !important;
`

const StyledSuccessAlert = styled(Alert)`
  margin-bottom: 16px;
  color: ${prop('theme.colors.green700')};
  background-color: ${prop('theme.colors.green100')};
  border-color: ${prop('theme.colors.green200')};
  text-align: left;
`

const StyledSuccessTitle = styled(Title)`
  color: ${prop('theme.colors.green800')} !important;
`

const formInitialValues = {
  email: '',
  password: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'The email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  } else if (!values.password) {
    errors.password = 'The password is required'
  }
  return errors
}

const LoginPage = ({
  onLoginSubmit,
  onComplete,
  onGoogleSignInComplete,
  onShowRegister,
  emailVerification,
  isModal,
  ...props
}) => {
  const dispatch = useDispatch()
  const [incorrectCredentials, setIncorrectCredentials] = useState(false)
  const [isEmailNotConfirmed, setEmailNotConfirmed] = useState(false)
  const [email, setEmail] = useState('')
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    onLoginSubmit(values)
      .then(() => {
        setIncorrectCredentials(false)
        setEmailNotConfirmed(false)
        setNotGoogleLogin()
        dispatch(trackLoginRequest({ isGoogle: false }))
        onComplete()
      })
      .catch(({ error }) => {
        console.log('>>> err', error)
        setSubmitting(false)
        if (error.code === 'UserNotConfirmedException') {
          setEmailNotConfirmed(true)
          return
        }
        if (
          error.code === 'PasswordResetRequiredException' ||
          error.message.includes('FORCE_CHANGE_PASSWORD')
        ) {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          setIncorrectCredentials(true)
          setEmailNotConfirmed(false)
          window.location.href = '/forgot_password'
          return
        }
        if (error.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
        } else if (error.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
        }
        setEmailNotConfirmed(false)
        setIncorrectCredentials(true)
      })
  }

  const onEmailInput = e => setEmail(e?.target?.value)
  const onResendConfirmationEmail = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      resendConfirmationEmail(email)
    },
    [email]
  )

  const onGoogleSignInCompleteCallback = args => {
    dispatch(trackLoginRequest({ isGoogle: true }))
    onGoogleSignInComplete(args)
  }

  return (
    <StyledCenterTemplate {...props}>
      <StyledBrand />
      <Formik
        initialValues={formInitialValues}
        validate={validateFormValues}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
        }) => (
          <React.Fragment>
            <StyledForm>
              {emailVerification === 'success' && (
                <StyledSuccessAlert type="success">
                  <StyledSuccessTitle>
                    Your email is now verified!
                  </StyledSuccessTitle>
                  Sign in to start collaborating in context
                </StyledSuccessAlert>
              )}
              {emailVerification === 'failure' && (
                <StyledEmailWarningAlert type="warning">
                  <StyledWarningTitle>Something went wrong</StyledWarningTitle>
                  Your email address could not be verified
                </StyledEmailWarningAlert>
              )}
              <CenteredBox>
                <LG>Sign in</LG>
              </CenteredBox>
              <CenteredBox>
                <Field>
                  <Input
                    type="email"
                    name="email"
                    autocomplete="email"
                    onChange={handleChange}
                    onInput={onEmailInput}
                    onBlur={handleBlur}
                    value={values.email}
                    validation={errors.email ? 'warning' : undefined}
                    placeholder="Email"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                </Field>
                <Field>
                  <Input
                    type="password"
                    name="password"
                    autocomplete="current-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    validation={errors.password ? 'warning' : undefined}
                    placeholder="Password"
                    style={{ marginTop: '16px' }}
                  />
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="password"
                  />
                </Field>
                {incorrectCredentials && (
                  <StyledErrorMessage>
                    The email and password combination is incorrect
                  </StyledErrorMessage>
                )}
                {isEmailNotConfirmed && (
                  <StyledWarningAlert type="warning">
                    <StyledWarningTitle>Verify your email</StyledWarningTitle>
                    Check your inbox and verify your email to start using
                    iterspace.{' '}
                    <Link onClick={onResendConfirmationEmail}>
                      Resend confirmation email
                    </Link>
                  </StyledWarningAlert>
                )}
              </CenteredBox>
              <CenteredBox>
                <Button
                  isStretched
                  isPrimary
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  data-test="sign-in-button"
                >
                  Sign in
                </Button>
              </CenteredBox>
              <CenteredBox direction="row">
                <StyledLink
                  to="/forgot_password"
                  target={isModal ? '_blank' : '_self'}
                >
                  Forgot password?
                </StyledLink>
              </CenteredBox>
              <CenteredBox>or</CenteredBox>
              <CenteredBox>
                <GoogleButton
                  onComplete={onGoogleSignInCompleteCallback}
                  disabled={!getIsGoogleLoginAvailable()}
                >
                  Sign in with Google
                </GoogleButton>
              </CenteredBox>
              {!getIsGoogleLoginAvailable() && (
                <CenteredBox>
                  <StyledEmailWarningAlert type="warning">
                    <StyledWarningTitle>
                      Can&apos;t sign in with Google?
                    </StyledWarningTitle>
                    To sign in with Google you need to enable third-party
                    cookies or exit Incognito mode.
                  </StyledEmailWarningAlert>
                </CenteredBox>
              )}
            </StyledForm>
            <CenteredBox style={{ marginTop: '32px' }} direction="row">
              <span style={{ display: 'inline-flex' }}>First time?</span>
              <StyledLink to="/register" onClick={onShowRegister}>
                Create an account
              </StyledLink>
            </CenteredBox>
          </React.Fragment>
        )}
      />
    </StyledCenterTemplate>
  )
}

LoginPage.propTypes = {
  onLoginSubmit: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onGoogleSignInComplete: PropTypes.func.isRequired,
  emailVerification: PropTypes.oneOf(['success', 'failure']),
  onShowRegister: PropTypes.func,
  isModal: PropTypes.bool,
}

LoginPage.defaultProps = {
  emailVerification: undefined,
  onShowRegister: () => {},
  isModal: false,
}

export default LoginPage
