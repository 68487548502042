import React, { useCallback, useReducer, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { ApolloProvider } from 'react-apollo'
import queryString from 'query-string'
import { v1 as uuidv1 } from 'uuid'
import { Rehydrated } from 'aws-appsync-react'
import LoginPage from './components/pages/LoginPage'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import CreateNewPasswordPage from './components/pages/CreateNewPasswordPage'
import RegistrationPage from './components/pages/Onboarding/RegistrationPage'
import MainAppPage from './components/pages/MainAppPage'
import MainAppPageMobile from './components/pages/MainAppPage/MainAppPageMobile'
import DesktopDragBar from './components/atoms/DesktopDragBar/DesktopDragBar'
import ProjectProxy from './components/organisms/ProjectProxy'
import SessionProxy from './components/organisms/SessionProxy'
import ExtensionProxy from './components/organisms/ExtensionProxy'
import RequestRecordingProxy from './components/organisms/RequestRecordingProxy'
import CommentProxy from './components/organisms/CommentProxy'
import OnboardingProxy from './components/organisms/OnboardingProxy'
import SettingsProxy from './components/organisms/SettingsProxy'
import WorkspaceProxy from './components/organisms/WorkspaceProxy'
import WorkspaceSettingsProxy from './components/organisms/WorkspaceSettingsProxy'
import { loginRequestSuccess } from './store/actions'
import { addNotification } from './store/ui-notifications/ui-notifications.actions'
import './App.css'
import appConfig from './config'
import { getAppSyncClient } from './lib/appSyncClient'
import { isMobile } from './lib/platform'
import { isGoogleLogin, createGaScript, googleSignIn } from './util/auth'
import { useGoogleLogin } from '@react-oauth/google'
import { getUserRequest } from './store/user/user.actions'
import useTrello from './components/hooks/useTrello'
import useSlack from './components/hooks/useSlack'
import { version } from '../package.json'

if (process.env.NODE_ENV === 'development') {
  // console.log('enabling whyDidYouRender')
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  // const whyDidYouRender = require('@welldone-software/why-did-you-render')
  // whyDidYouRender(React)
}

const PrivateRoute = ({
  component: Component,
  isLoading,
  isAuthenticated,
  username,
  onLogout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
        !isLoading &&
        (isAuthenticated ? (
          <Component {...props} username={username} onLogout={onLogout} />
        ) : (
          <Redirect
            to={{
              pathname: '/register',
              /* eslint-disable-next-line */
              state: { from: props.location },
            }}
          />
        ))
      )
    }}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
}

PrivateRoute.defaultProps = {
  onLogout: () => {},
}

const PublicRoute = ({
  component: Component,
  isLoading,
  isAuthenticated,
  goToVerifyAccount,
  goToOnboarding,
  onComplete,
  onGoogleSignInComplete,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <React.Fragment>
        {goToOnboarding && (
          <Redirect
            to={{
              pathname: '/onboarding',
            }}
          />
        )}
        {!goToVerifyAccount &&
          !isLoading &&
          (!isAuthenticated ? (
            <Component
              {...props}
              onComplete={onComplete}
              onGoogleSignInComplete={onGoogleSignInComplete}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/app',
                /* eslint-disable-next-line */
                state: { from: props.location },
              }}
            />
          ))}
      </React.Fragment>
    )}
  />
)

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  onComplete: PropTypes.func,
  onGoogleSignInComplete: PropTypes.func,
  goToVerifyAccount: PropTypes.bool,
  goToOnboarding: PropTypes.bool,
}

PublicRoute.defaultProps = {
  component: <></>,
  isLoading: false,
  isAuthenticated: false,
  onComplete: () => {},
  onGoogleSignInComplete: () => {},
  goToVerifyAccount: false,
  goToOnboarding: false,
}

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  username: '',
  goToVerifyAccount: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_username':
      return { ...state, username: action.payload.username }
    case 'set_authenticated':
      return { ...state, isAuthenticated: true }
    case 'set_unauthenticated':
      return { ...state, isAuthenticated: false }
    case 'stop_loading':
      return { ...state, isLoading: false }
    /*     case 'go_to_verify_account':
      return { ...state, goToVerifyAccount: true } */
    case 'go_to_onboarding':
      return { ...state, goToOnboarding: true }
    case 'reset_go_to_verify_account':
      return { ...state, goToVerifyAccount: false }
    default:
      return state
  }
}

const verifyAuth = (dispatch, onLoginSuccess, storeDispatch) =>
  Auth.currentAuthenticatedUser({
    bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  })
    .then(async user => {
      const result = await Auth.currentCredentials()
      const credentials = Auth.essentialCredentials(result)
      const email = user.attributes ? user.attributes.email : user.email

      try {
        await storeDispatch(getUserRequest())
      } catch (err) {
        dispatch({ type: 'go_to_onboarding' })
        if (
          window.location.pathname !== '' &&
          window.location.pathname !== '/onboarding' &&
          !window.location.pathname.includes('/recording')
        ) {
          window.location.href = '/onboarding'
        }
        return false
      }

      const isCognitoUser = !!user.attributes
      if (email) {
        onLoginSuccess(email, credentials.sessionToken, isCognitoUser)
      }
      // eslint-disable-next-line no-undef
      if (window.FS) {
        // eslint-disable-next-line no-undef
        FS.identify(credentials.identityId, {
          email,
          appVersion: version,
        })
      }

      dispatch({
        type: 'set_username',
        payload: {
          username: email,
        },
      })
      dispatch({ type: 'set_authenticated' })
      dispatch({ type: 'stop_loading' })
      return true
    })
    .catch(err => {
      console.error(err)
      dispatch({ type: 'stop_loading' })
      return false
    })

const onLogout = dispatch => {
  dispatch({ type: 'set_unauthenticated' })
}

Auth.configure(appConfig.AWS.Auth)
const appSyncClient = getAppSyncClient()

const App = ({ onLoginSuccess }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const storeDispatch = useDispatch()

  useEffect(() => {
    const { jira } = queryString.parse(window.location.search)
    if (jira && jira === 'success') {
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'Jira successfully linked!',
          isPersisted: true,
        })
      )
    }
    if (jira && jira === 'error') {
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'An error occured while adding Jira, please retry later',
          isPersisted: true,
        })
      )
    }
  }, [])

  const { fetchTrelloResources } = useTrello()

  useEffect(() => {
    const { trello } = queryString.parse(window.location.search)
    if (trello && trello === 'success') {
      fetchTrelloResources()
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'Trello successfully linked!',
          isPersisted: true,
        })
      )
    }
    if (trello && trello === 'error') {
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'An error occured while adding Trello, please retry later',
          isPersisted: true,
        })
      )
    }
  }, [])

  const { fetchSlackResources } = useSlack()

  useEffect(() => {
    const { slack } = queryString.parse(window.location.search)
    if (slack && slack === 'success') {
      fetchSlackResources()
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'Slack successfully linked!',
          isPersisted: true,
        })
      )
    }
    if (slack && slack === 'error') {
      storeDispatch(
        addNotification({
          notificationId: uuidv1(),
          text: 'An error occured while adding Slack, please retry later',
          isPersisted: true,
        })
      )
    }
  }, [])

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse =>
      googleSignIn(codeResponse, () =>
        verifyAuth(dispatch, onLoginSuccess, storeDispatch, true)
      ),
    flow: 'auth-code',
  })

  useEffect(() => {
    createGaScript()
      .then(() => {
        verifyAuth(dispatch, onLoginSuccess, storeDispatch).then(result => {
          if (!result) {
            if (isGoogleLogin()) {
              loginWithGoogle()
            }
          }
        })
      })
      .catch(console.error)
  }, [onLoginSuccess])

  const loginHandler = useCallback(() => {
    storeDispatch(getUserRequest())
      .then(() => {
        verifyAuth(dispatch, onLoginSuccess, storeDispatch, true)
      })
      .catch(() => {
        dispatch({ type: 'go_to_onboarding' })
      })
  }, [storeDispatch, getUserRequest, verifyAuth, dispatch, onLoginSuccess])

  useEffect(() => {
    // init Google Optimize
    const script = document.createElement('script')
    script.src = appConfig.Google.OptimizeUrl
    script.async = 1
    document.getElementsByTagName('head')[0].appendChild(script)
  }, [])

  return (
    <ApolloProvider client={appSyncClient}>
      <Rehydrated>
        <Router>
          <DesktopDragBar />
          <PublicRoute
            exact
            path={['/', '/index.html']}
            component={LoginPage}
            onComplete={() => {
              /* loginHandler() */
            }}
            onGoogleSignInComplete={() => loginHandler()}
            {...state}
          />
          <PublicRoute
            exact
            path="/login"
            component={LoginPage}
            onComplete={() => {
              /* loginHandler() */
            }}
            onGoogleSignInComplete={() => loginHandler()}
            {...state}
          />
          <PublicRoute
            exact
            path="/register"
            component={RegistrationPage}
            onComplete={({ isGoogle = false }) => {
              console.log('>>> isGoogle', isGoogle)
              dispatch({ type: 'go_to_onboarding' })
            }}
            {...state}
          />
          <PublicRoute
            exact
            path="/forgot_password"
            component={ForgotPasswordPage}
            {...state}
          />
          <PublicRoute
            path="/reset"
            component={CreateNewPasswordPage}
            {...state}
          />
          <PublicRoute
            exact
            path={['/remote-test/:requestRecordingId']}
            component={RequestRecordingProxy}
          />
          <Route exact path="/onboarding" component={OnboardingProxy} />
          <Route
            exact
            path={['/project/:projectId', '/folder/:projectId']}
            component={ProjectProxy}
          />
          <Route
            exact
            path={['/session/:sessionId', '/recording/:sessionId']}
            component={SessionProxy}
          />
          <Route
            exact
            path={['/extension/:recordingId', '/temporary/:recordingId']}
            component={ExtensionProxy}
          />
          <Route
            exact
            path={['/comment/:commentId', '/comment/:commentId/reply/:replyId']}
            component={CommentProxy}
          />
          <Route exact path={['/settings']} component={SettingsProxy} />
          <PrivateRoute
            path="/app"
            exact={false}
            component={isMobile() ? MainAppPageMobile : MainAppPage}
            isAuthenticated={state.isAuthenticated}
            username={state.username}
            onLogout={() => {
              onLogout(dispatch)
            }}
            {...state}
          />
          <Route
            exact
            path={['/workspace/:organizationId']}
            component={WorkspaceProxy}
          />
          <Route
            exact
            path={['/workspace/edit/:organizationId']}
            component={WorkspaceSettingsProxy}
          />
        </Router>
      </Rehydrated>
    </ApolloProvider>
  )
}

const mapDispatchToProps = dispatch => ({
  onLoginSuccess: (email, accessToken, isCognitoUser) =>
    dispatch(
      loginRequestSuccess(
        { username: email, accessToken, isCognitoUser },
        'thunk'
      )
    ),
})

App.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
}

App.whyDidYouRender = false

export default connect(
  null,
  mapDispatchToProps
)(App)
