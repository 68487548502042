import Cache from '@aws-amplify/cache'

const setSprite = ({ sessionId, spriteIndex, imageDataBase64, size }) => {
  const key = `${sessionId}:${spriteIndex}`
  const value = {
    sessionId,
    spriteIndex,
    size,
    data: imageDataBase64,
  }
  Cache.setItem(key, value)
}

const getSprite = (sessionId, spriteIndex) => {
  const key = `${sessionId}:${spriteIndex}`
  return Cache.getItem(key)
}

export default { setSprite, getSprite }
