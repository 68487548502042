import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import NotFoundMobile from '../NotFoundMobile'
import AnonymousMainAppPage from '../../pages/AnonymousMainAppPage'
import useUserInfo from '../../../store/hooks/useUserInfo'
import messageTypes from '../../../browser-extension/messageTypes'
import browserExtension from '../../../lib/browserExtension'
import SessionDestinationSelector from '../SessionDestinationSelector'

const ExtensionProxy = ({ match }) => {
  const { isLogged: isUserLogged } = useUserInfo()

  const recordingId = get(match, 'params.recordingId')
  const [isLoading, setLoading] = useState(false) // TODO: change to true
  const [success, setSuccess] = useState(true) // TODO: change to false
  const [recording, setRecording] = useState(null) // TODO: change to false

  useEffect(() => {
    if (recordingId) {
      browserExtension
        .sendMessageToBackground({ type: messageTypes.REQUEST_VIDEO_BLOB })
        .then(response => {
          setLoading(false)
          if (response?.blobUrl) {
            setSuccess(true)
            setRecording(response)
            return
          }
          setLoading(false)
        })
    }
  }, [recordingId])

  if (!recordingId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !success) {
    return <NotFoundMobile />
  }

  if (!isLoading && success && !isUserLogged && recording) {
    return (
      <AnonymousMainAppPage recording={recording} recordingId={recordingId} />
    )
  }

  if (!isLoading && success && isUserLogged && recording) {
    return <SessionDestinationSelector recording={recording} />
  }

  return null
}

ExtensionProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

export default ExtensionProxy
