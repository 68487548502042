import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Dropdown,
  Autocomplete,
  Menu,
  Item,
  Field,
  Label,
} from '@zendeskgarden/react-dropdowns'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import debounce from 'lodash/debounce'
import { Button } from '@zendeskgarden/react-buttons'
import prop from 'lodash/fp/prop'
import get from 'lodash/get'
import first from 'lodash/first'
import { StyledErrorMessage } from '../../commonStyledComponents'
import Flex from '../../atoms/Flex'
import SlackLogoSvg from '../../../assets/Slack_Mark.svg'
import Loader from '../../atoms/Loader'
import useDeepEffect from '../../hooks/useDeepEffect'

const StyledHeader = styled(Header)`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  padding: 12px 24px;
`

const StyledBody = styled(Body)`
  padding: 0 40px 0 40px;
  align-items: center;
  justify-content: center;
  height: auto;
`

const Row = styled(Flex)`
  margin-bottom: 24px;
`

const SlackLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  transform: scale(0.6);
`

const StyledForm = styled(Form)`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`

const SubmitButton = styled(Button)`
  order: 0;
  align-self: flex-end;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledItem = styled(Item)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const ItemImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
  align-self: center;
`

const StyledLoader = styled(Loader).attrs(props => ({
  color: prop('theme.colors.grey600')(props),
}))``

const useDropdown = (items, key, searchKey, initialKey) => {
  const [selectedItemKey, setSelectedItemKey] = useState(
    initialKey || items?.[0]?.[key]
  )

  const initialValue = first(items.filter(item => item[key] === initialKey))
  const [selectedItem, setSelectedItem] = useState(initialValue || items?.[0])
  const [value, setValue] = useState('')
  const [matchingItems, setMatchingItems] = useState(items)

  const filterMatchingRef = _items =>
    debounce(_value => {
      if (!_value?.trim) {
        setMatchingItems(_items)
        return
      }
      const matchedDestinations = _items.filter(
        item =>
          item[searchKey]
            .trim()
            .toLowerCase()
            .indexOf(_value.trim().toLowerCase()) !== -1
      )

      setMatchingItems(matchedDestinations)
    }, 300)

  useDeepEffect(() => {
    filterMatchingRef(items)(value)
  }, [value, items])

  useEffect(() => {
    const obj = items.find(item => item[key] === selectedItemKey)
    if (obj) {
      setSelectedItem(obj)
    }
  }, [selectedItemKey, items])

  return {
    selectedItemKey,
    setSelectedItemKey,
    selectedItem,
    value,
    setValue,
    matchingItems,
    filterMatchingRef,
  }
}

const SendSlackMessageModal = ({
  channels,
  users,
  initialDestinationId,
  onClose,
  onSend,
  onSelect,
}) => {
  const [isFormLoading, setFormLoading] = useState(false)
  const destinations = channels.concat(users)
  const {
    selectedItemKey: selectedDestinationKey,
    setSelectedItemKey: setSelectedDestinationKey,
    selectedItem: selectedDestination,
    value: destinationValue,
    setValue: setDestinationValue,
    matchingItems: matchingDestinations,
  } = useDropdown(destinations, 'id', 'name', initialDestinationId)

  const formInitialValues = {
    destination: initialDestinationId || get(first(destinations), 'id'),
  }

  return (
    <Modal isAnimated={false} style={{ overflow: 'visible', top: '20%' }}>
      <StyledHeader>
        <SlackLogo src={SlackLogoSvg} alt="slack" />
        Send to Slack
      </StyledHeader>
      <StyledBody>
        <Flex style={{ marginBottom: '24px' }}>
          <Formik
            initialValues={formInitialValues}
            onSubmit={values => {
              setFormLoading(true)
              onSend({
                destination: values.destination,
                isChannel: !!selectedDestination?.isChannel,
                isUser: !selectedDestination?.isChannel,
              })
                .then(() => {
                  onClose()
                })
                .catch(() => {
                  setFormLoading(false)
                })
            }}
            render={({ values, errors, handleChange }) => (
              <StyledForm>
                <Row>
                  <Dropdown
                    inputValue={destinationValue}
                    selectedItem={selectedDestinationKey}
                    onStateChange={handleChange}
                    onSelect={item => {
                      setSelectedDestinationKey(item)
                      // eslint-disable-next-line no-param-reassign
                      values.destination = item
                      onSelect(item)
                    }}
                    onInputValueChange={value => {
                      setDestinationValue(value)
                    }}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                    validation={errors.destination ? 'warning' : undefined}
                  >
                    <Field style={{ width: '100%' }}>
                      <Label>Destination channel or user</Label>
                      <Autocomplete
                        start={
                          selectedDestination?.avatarUrl ? (
                            <ItemImage
                              src={selectedDestination?.avatarUrl}
                              alt="icon"
                            />
                          ) : null
                        }
                      >
                        <span>
                          {selectedDestination?.isChannel
                            ? `#${selectedDestination.name}`
                            : selectedDestination.realName}
                        </span>
                      </Autocomplete>
                    </Field>
                    <Menu placement="bottom" style={{ marginTop: '100px' }}>
                      {matchingDestinations.length ? (
                        matchingDestinations.map(destination => (
                          <StyledItem
                            key={destination.id}
                            value={destination.id}
                          >
                            {destination?.avatarUrl && (
                              <ItemImage
                                src={destination.avatarUrl}
                                alt="icon"
                              />
                            )}
                            <span>
                              {destination?.isChannel
                                ? `#${destination.name}`
                                : destination.realName}
                            </span>
                          </StyledItem>
                        ))
                      ) : (
                        <Item disabled>No matches found</Item>
                      )}
                    </Menu>
                  </Dropdown>
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="destination"
                  />
                </Row>

                <Row style={{ marginBottom: '0' }}>
                  <SubmitButton
                    isPrimary
                    type="submit"
                    disabled={isFormLoading}
                  >
                    {isFormLoading && <StyledLoader size="16px" />}
                    {!isFormLoading && <>Send to Slack</>}
                  </SubmitButton>
                </Row>
              </StyledForm>
            )}
          />
        </Flex>
      </StyledBody>
      <Close aria-label="Close modal" onClick={onClose} />
    </Modal>
  )
}

SendSlackMessageModal.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  initialDestinationId: PropTypes.string,
  onSend: PropTypes.func,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
}

SendSlackMessageModal.defaultProps = {
  channels: [],
  users: [],
  initialDestinationId: '',
  onSend: () => {},
  onClose: () => {},
  onSelect: () => {},
}

export default SendSlackMessageModal
