import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Flex from '../../atoms/Flex'
import ProjectCard from '../../molecules/ProjectCard'
import SectionTitleMobile from '../../molecules/SectionTitleMobile'
import OrganizationEmptyState from '../../molecules/OrganizationEmptyState'
import RotatingWords from '../../molecules/Skeletons/RotatingWords'

const Wrapper = styled(Flex)`
  display: ${({ fullHeight }) => (fullHeight ? 'flex' : 'block')};
  flex-grow: 1;
`

const TitleBox = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 8px;
`

const StyledProjectCard = styled(ProjectCard)`
  margin: 0 16px 16px 16px;
`

const StyledOrganizationEmptyState = styled(OrganizationEmptyState)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 8px;
  flex-grow: 1;
  box-sizing: border-box;

  && {
    box-sizing: border-box;
  }
`

const ProjectsListMobile = ({
  onProjectClick,
  onCreateProject,
  projects,
  showTitle,
  showLoader,
  ...props
}) => (
  <Wrapper fullHeight={projects.length === 0} {...props}>
    {showLoader && <RotatingWords />}
    {!showLoader && projects.length === 0 && (
      <StyledOrganizationEmptyState onClick={onCreateProject} />
    )}
    {!showLoader && projects.length > 0 && (
      <>
        {showTitle && (
          <TitleBox>
            <SectionTitleMobile>Projects</SectionTitleMobile>
          </TitleBox>
        )}
        {projects.map(project => (
          <StyledProjectCard
            key={project.pk}
            title={project.title}
            counter={project?.sessions?.length || 0}
            isPrivate={project.isPrivate}
            isShared={project.isShared}
            sharedWithCounter={project.sharedWithCounter}
            onClick={() => onProjectClick(project.pk)}
          />
        ))}
      </>
    )}
  </Wrapper>
)

ProjectsListMobile.propTypes = {
  onProjectClick: PropTypes.func,
  onCreateProject: PropTypes.func,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  showTitle: PropTypes.bool,
  showLoader: PropTypes.bool,
}

ProjectsListMobile.defaultProps = {
  onProjectClick: () => {},
  onCreateProject: () => {},
  projects: [],
  showTitle: true,
  showLoader: false,
}

ProjectsListMobile.whyDidYouRender = false

export default ProjectsListMobile
