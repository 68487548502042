import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'
import NotAuthorized from '../NotAuthorized'
import useUserInfo from '../../../store/hooks/useUserInfo'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import WorkspaceSettingsPage from '../../pages/WorkspaceSettingsPage'

const WorkspaceSettingsProxy = ({ match }) => {
  const { isLogged: isUserLogged } = useUserInfo()

  const organizationId = get(match, 'params.organizationId')
  const organization = useSelector(
    fromOrganizations.getOrganizationById(organizationId)
  )

  if (!organizationId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (!isUserLogged || !!organization) {
    return <NotAuthorized />
  }

  if (isUserLogged) {
    return <WorkspaceSettingsPage organizationId={organizationId} />
  }

  return null
}

WorkspaceSettingsProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

export default WorkspaceSettingsProxy
