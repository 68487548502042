import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Anchor } from '@zendeskgarden/react-buttons'
import { Modal, Header, Body, Footer, Close } from '@zendeskgarden/react-modals'
import { Alert, Title } from '@zendeskgarden/react-notifications'
import { getSessionUrl } from '../../../lib/urls'
import { AdditionalProductShape } from '../ProductSelector/ProductSelector'
import RecordingPanel from '../ProductSelector/RecordingPanel'
import { isScreenRecordingSupported } from '../../../lib/mediaRecorder'

const StyledBody = styled(Body)`
  .library-modal {
    color: hsl(210, 10%, 48%);
  }
  .library-modal h7 {
    font-size: 16px;
    font-weight: 600;
    color: hsl(210, 10%, 30%);
  }
  .library-modal-list {
    list-style-type: none;
    line-height: 1.7em;
  }

  .library-modal-list li {
    margin-bottom: 16px;
  }

  .library-modal-list li > b {
    font-size: 16px;
    color: hsl(210, 10%, 30%);
    margin-left: -28px;
  }
`

const StyledFooter = styled(Footer)`
  && {
    justify-content: stretch;
    flex-grow: 1;
    width: 100%;
    display: flex;
    min-width: 100%;
    box-sizing: border-box;
    padding: 8px 24px 16px;
  }
`

const StyledRecordingPanel = styled(RecordingPanel)`
  width: 100%;
  box-sizing: border-box;
`

const StyledTitle = styled(Title)`
  line-height: 20px;
`

const StyledAlert = styled(Alert)`
  background-color: ${prop('theme.colors.warning100')};
  width: 100%;

  ${StyledTitle} {
    color: ${prop('theme.colors.warning800')};
  }

  color: ${prop('theme.colors.warning600')};

  svg {
    fill: ${prop('theme.colors.warning800')} !important;
  }

  :hover {
    background-color: ${prop('theme.colors.warning100')};
  }
`

const LibraryModal = ({
  actionButtonText,
  onClose,
  onSubmit,
  product,
  isLibraryWorkspacePresent,
  ...props
}) => {
  return (
    <Modal isLarge={false} isAnimated={false} {...props}>
      <Header>{product.title}</Header>
      <StyledBody
        dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
      />
      {isLibraryWorkspacePresent && product.libraryRecordingId && (
        <Anchor
          isExternal
          href={getSessionUrl(product.libraryRecordingId)}
          target="_blank"
          style={{ alignSelf: 'center' }}
        >
          See it on the library
        </Anchor>
      )}
      <StyledFooter>
        {isScreenRecordingSupported() && (
          <StyledRecordingPanel onStartRecordButtonClick={() => onSubmit()} />
        )}
        {!isScreenRecordingSupported() && (
          <StyledAlert type="error">
            <StyledTitle>Screen recording</StyledTitle>
            Not supported on this browser yet.
            <br />
            Please use a recent version of{' '}
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
              title="Download Google Chrome browser"
            >
              Chrome
            </a>
            , or{' '}
            <a
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noopener noreferrer"
              title="Download Microsoft Edge browser"
            >
              Edge
            </a>
            .
          </StyledAlert>
        )}
      </StyledFooter>
      <Close aria-label="Close modal" onClick={onClose} />
    </Modal>
  )
}

LibraryModal.propTypes = {
  actionButtonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  product: AdditionalProductShape.isRequired,
  isLibraryWorkspacePresent: PropTypes.bool,
}

LibraryModal.defaultProps = {
  isLibraryWorkspacePresent: false,
}

export default LibraryModal
