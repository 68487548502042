import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Flex from '../../atoms/Flex'
import EditableText from '../../atoms/EditableText'
import MobileTopBar from '../../molecules/MobileTopBar'
import MobileModal from '../../molecules/MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'

const Wrapper = styled(Flex)``

const Body = styled(Flex)`
  padding: 24px;
`

const Footer = styled(Flex)`
  padding: 24px;
`

const StyledEditableText = styled(EditableText)`
  border: 1px solid black;
  box-sizing: border-box;
  border-color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.grey200 : theme.colors.grey100};
`

const NameModal = ({ name, isVisible, onClose, onNameUpdate, ...props }) => {
  const header = useMemo(
    () => (
      <MobileTopBar icon={ArrowLeftIcon} onButtonClick={onClose}>
        Edit name
      </MobileTopBar>
    ),
    []
  )

  const [currentName, setCurrentName] = useState(name)
  const [isTextAreaFocused, setTextAreaFocused] = useState(false)

  const body = useMemo(
    () => (
      <Body>
        <StyledEditableText
          isFocused={isTextAreaFocused}
          textAreaProps={{
            defaultValue: name,
            onChange: e => {
              console.log('>>>', e.target.value)
              setCurrentName(e.target.value)
            },
            onFocus: () => {
              setTextAreaFocused(true)
            },
            onBlur: () => {
              setTextAreaFocused(false)
            },
          }}
        />
      </Body>
    ),
    [name, setCurrentName, isTextAreaFocused]
  )

  const footer = useMemo(() => (
    <Footer>
      <Button
        isPrimary
        onClick={() => {
          onNameUpdate(currentName)
        }}
      >
        Save
      </Button>
    </Footer>
  ))

  return (
    <>
      {isVisible && (
        <Wrapper {...props}>
          <MobileModal onClose={onClose}>
            <MobileTemplate header={header} body={body} footer={footer} />
          </MobileModal>
        </Wrapper>
      )}
    </>
  )
}

NameModal.propTypes = {
  name: PropTypes.string.isRequired,
  onNameUpdate: PropTypes.func,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

NameModal.defaultProps = {
  isVisible: false,
  onNameUpdate: () => {},
  onClose: () => {},
}

export default NameModal
