import { useDispatch } from 'react-redux'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import uniq from 'lodash/uniq'
import {
  getOrganizationsRequest,
  setCurrentOrganization,
} from '../organizations/organizations.actions'
import { getProjectRequest } from '../projects/projects.actions'
import { getUsersRequest } from '../users/users.actions'
import useUserInfo from './useUserInfo'
import useDeepEffect from '../../components/hooks/useDeepEffect'

const useFetchPublicSessionUsers = ({
  isPublicSession,
  currentProject: sessionProject,
  currentProjectId: sessionProjectId,
}) => {
  const dispatch = useDispatch()
  const { userIdentityId } = useUserInfo()
  useDeepEffect(() => {
    async function getUsers() {
      if (isPublicSession) {
        const userOrganizationsResponse = await dispatch(
          getOrganizationsRequest()
        )
        let _currentProject = sessionProject
        if (!sessionProject) {
          let projectResponse = {}
          try {
            projectResponse = await dispatch(
              getProjectRequest(sessionProjectId.replace('project:', ''))
            )
          } catch (err) {
            console.error(err)
          }
          _currentProject = projectResponse?.project
        }
        if (
          userOrganizationsResponse &&
          userOrganizationsResponse?.organizations &&
          _currentProject
        ) {
          const { organizations } = userOrganizationsResponse
          const publicSessionOrganization = organizations.find(
            o => o.pk === _currentProject.organizationId
          )
          if (!publicSessionOrganization) {
            return
          }
          if (publicSessionOrganization) {
            const {
              pk: organizationId,
              admins = [],
              members = [],
            } = publicSessionOrganization
            dispatch(setCurrentOrganization(organizationId))
            const orgAdmins = admins || []
            const orgMembers = members || []
            const organizationUsers = uniq(
              compact(concat(orgAdmins, orgMembers, [userIdentityId]))
            )
            await dispatch(getUsersRequest(organizationUsers))
          }
        } else {
          await dispatch(getUsersRequest([userIdentityId]))
        }
      } else {
        await dispatch(getUsersRequest([userIdentityId]))
      }
    }
    getUsers()
  }, [isPublicSession, sessionProject, sessionProjectId, userIdentityId])
}

export default useFetchPublicSessionUsers
