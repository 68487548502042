import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import { createSelector } from 'reselect'
import { STATE_KEY, STATE_SUB_KEY } from './integrations.slack.reducers'

const getState = state => getImmutable(state, `${STATE_KEY}\\.${STATE_SUB_KEY}`)

const getHasSlack = createSelector(
  getState,
  get('hasSlack')
)

const getSlackChannels = createSelector(
  getState,
  get('channels')
)

const getSlackUsers = createSelector(
  getState,
  get('users')
)

export default {
  getHasSlack,
  getSlackChannels,
  getSlackUsers,
}
