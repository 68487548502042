import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import AccountArrowRightIcon from 'mdi-react/AccountArrowRightIcon'
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon'
import { Field, Label, Input } from '@zendeskgarden/react-forms'
import { Formik, Form, ErrorMessage } from 'formik'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import { StyledErrorMessage } from '../../commonStyledComponents'
import Flex from '../../atoms/Flex'
import SheetList from '../../atoms/SheetList'
import IconButton from '../IconButton'
import Member from '../Member'
import useOrganization from '../../../store/hooks/useOrganization'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import theme from '../../theme/old'

const formInitialValues = {
  email: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'The email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const ROLES = {
  admin: 'admin',
  member: 'member',
  remove: 'remove',
}

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const MembersBox = styled(Flex)`
  margin-top: 16px;
`

const ShareForm = styled(Form)`
  display: flex;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareButton = styled(IconButton)`
  order: 0;
  flex: 0 1 40px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
  width: 40px;
`

const StyledBody = styled(Flex)`
  padding: 8px;
`

const OrganizationModalMobile = ({
  currentUserEmail,
  fetchUsers,
  isCurrentUserOrganizationAdmin,
  isVisible,
  onAddUser,
  onChangeRole,
  onClose,
  organizationUsers,
}) => {
  const { currentOrganization } = useOrganization()
  const organizationTitle = get(currentOrganization, 'title')
  const invitedUsers = currentOrganization?.invitedUsers || []

  const [isModalVisible, setModalVisible] = useState(isVisible)
  const [isMemberSheetVisible, setMemberSheetVisible] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    fetchUsers(currentOrganization)
  }, [currentOrganization, fetchUsers])

  useEffect(() => {
    setModalVisible(isVisible)
  }, [isVisible])

  const onModalClose = () => {
    setModalVisible(false)
    onClose()
  }

  const [selectedMember, setSelectedMember] = useState(null)

  const memberSheetItems = useMemo(() => {
    if (!selectedMember) {
      return []
    }

    const moveToRole =
      selectedMember.role === ROLES.member ? ROLES.admin : ROLES.member

    const roleDescription =
      moveToRole === ROLES.member
        ? 'Can create projects and recordings within the workspace'
        : 'Can invite new members, create projects and recordings within the workspace'

    const items = []
    if (isCurrentUserOrganizationAdmin) {
      items.push({
        text: `Change role to ${capitalize(moveToRole)}`,
        subtext: roleDescription,
        icon: <AccountArrowRightIcon />,
        onClick: () => {
          onChangeRole({
            userEmail: selectedMember.email,
            userRole: moveToRole,
          })
          setMemberSheetVisible(false)
        },
      })
    }
    items.push({
      text: 'Remove',
      icon: <AccountRemoveIcon />,
      style: { color: theme.colors.warning },
      iconColor: theme.colors.warning,
      onClick: () => {
        onChangeRole({
          userEmail: selectedMember.email,
          userRole: 'remove',
        })
        setMemberSheetVisible(false)
      },
    })
    return items
  }, [selectedMember, isCurrentUserOrganizationAdmin])

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        rightIcon={CloseIcon}
        onRightButtonClick={onModalClose}
      >
        Workspace: {organizationTitle}
      </StyledMobileTopBar>
    ),
    [organizationTitle]
  )

  const body = useMemo(
    () => (
      <StyledBody style={{ minHeight: '420px' }}>
        {isCurrentUserOrganizationAdmin && (
          <React.Fragment>
            <Field>
              <Label>Invite new member</Label>
            </Field>
            <Formik
              initialValues={formInitialValues}
              validate={validateFormValues}
              onSubmit={(values, actions) => {
                if (values.email) {
                  setIsFormLoading(true)
                  onAddUser({
                    userEmail: values.email,
                    userRole: ROLES.member,
                  })
                    .then(() => {
                      setIsFormLoading(false)
                    })
                    .finally(() => {
                      actions.resetForm({ email: '' })
                    })
                }
              }}
              render={({ values, errors, handleBlur, handleChange }) => (
                <ShareForm>
                  <EmailTextField>
                    <Input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      validation={errors.email ? 'warning' : undefined}
                      placeholder="Teammate email..."
                    />
                    <ErrorMessage component={StyledErrorMessage} name="email" />
                  </EmailTextField>
                  <ShareButton
                    isPrimary
                    icon={AccountPlusIcon}
                    type="submit"
                    disabled={isFormLoading}
                  />
                </ShareForm>
              )}
            />
          </React.Fragment>
        )}
        <MembersBox>
          {organizationUsers.map(
            ({ picture, name, email, role, isPendingRegistration }) => (
              <Member
                key={email}
                pictureUrl={picture}
                name={name || email}
                email={email}
                role={role}
                isPending={isPendingRegistration}
                onSettingsClick={
                  isCurrentUserOrganizationAdmin || email === currentUserEmail
                    ? () => {
                        setSelectedMember({
                          email,
                          role,
                        })
                        setMemberSheetVisible(true)
                      }
                    : null
                }
              />
            )
          )}
        </MembersBox>
      </StyledBody>
    ),
    [organizationUsers, invitedUsers]
  )

  return (
    <>
      {isModalVisible && (
        <MobileModal>
          <MobileTemplate header={header} body={body} footer={null} />
        </MobileModal>
      )}
      <SheetList
        title={selectedMember?.email}
        onClose={() => setMemberSheetVisible(false)}
        isVisible={isMemberSheetVisible}
        items={memberSheetItems}
      />
    </>
  )
}

OrganizationModalMobile.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  fetchUsers: PropTypes.func,
  isCurrentUserOrganizationAdmin: PropTypes.bool,
  isVisible: PropTypes.bool,
  onAddUser: PropTypes.func,
  onChangeRole: PropTypes.func,
  onClose: PropTypes.func,
  organizationUsers: PropTypes.arrayOf(PropTypes.shape({})),
}

OrganizationModalMobile.defaultProps = {
  fetchUsers: () => {},
  isCurrentUserOrganizationAdmin: PropTypes.bool,
  isVisible: false,
  onAddUser: () => {},
  onChangeRole: () => {},
  onClose: () => {},
  organizationUsers: [],
}

export default OrganizationModalMobile
