const NAMESPACE = 'ORGANIZATION'

export const SET_CURRENT_ORGANIZATION = `${NAMESPACE}/SET_CURRENT`
export const setCurrentOrganization = organizationId => ({
  type: SET_CURRENT_ORGANIZATION,
  payload: { organizationId },
})

export const GET_ORGANIZATIONS = `${NAMESPACE}/GET`
export const GET_ORGANIZATIONS_REQUEST = `${GET_ORGANIZATIONS}/REQUEST`
export const GET_ORGANIZATIONS_REQUEST_SUCCESS = `${GET_ORGANIZATIONS_REQUEST}_SUCCESS`
export const GET_ORGANIZATIONS_REQUEST_FAILURE = `${GET_ORGANIZATIONS_REQUEST}_FAILURE`

export const getOrganizationsRequest = () => ({
  type: GET_ORGANIZATIONS_REQUEST,
  payload: {},
  meta: { thunk: GET_ORGANIZATIONS_REQUEST },
})

export const getOrganizationsRequestSuccess = ({ organizations }, thunk) => ({
  type: GET_ORGANIZATIONS_REQUEST_SUCCESS,
  payload: { organizations },
  meta: { thunk },
})

export const getOrganizationsRequestFailure = (e, thunk) => ({
  type: GET_ORGANIZATIONS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_NEW_ORGANIZATION = `${NAMESPACE}/CREATE_NEW`
export const CREATE_NEW_ORGANIZATION_REQUEST = `${NAMESPACE}/CREATE_NEW/REQUEST`
export const CREATE_NEW_ORGANIZATION_REQUEST_SUCCESS = `${NAMESPACE}/CREATE_NEW/REQUEST_SUCCESS`
export const CREATE_NEW_ORGANIZATION_REQUEST_FAILURE = `${NAMESPACE}/CREATE_NEW/REQUEST_FAILURE`

export const createNewOrganizationRequest = ({ ...organization }) => ({
  type: CREATE_NEW_ORGANIZATION_REQUEST,
  payload: { ...organization },
  meta: { thunk: CREATE_NEW_ORGANIZATION_REQUEST },
})

export const createNewOrganizationRequestSuccess = (
  { createdBy, organizationId, createdAt, title },
  thunk
) => ({
  type: CREATE_NEW_ORGANIZATION_REQUEST_SUCCESS,
  payload: { createdBy, organizationId, createdAt, title },
  meta: { thunk },
})

export const createNewOrganizationRequestFailure = (e, thunk) => ({
  type: CREATE_NEW_ORGANIZATION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_ORGANIZATION = `${NAMESPACE}/UPDATE`
export const UPDATE_ORGANIZATION_REQUEST = `${NAMESPACE}/UPDATE/REQUEST`
export const UPDATE_ORGANIZATION_REQUEST_SUCCESS = `${NAMESPACE}/UPDATE/REQUEST_SUCCESS`
export const UPDATE_ORGANIZATION_REQUEST_FAILURE = `${NAMESPACE}/UPDATE/REQUEST_FAILURE`

export const updateOrganizationRequest = ({ ...organization }) => ({
  type: UPDATE_ORGANIZATION_REQUEST,
  payload: { ...organization },
  meta: { thunk: UPDATE_ORGANIZATION_REQUEST },
})

export const updateOrganizationRequestSuccess = (
  { organizationId, ...organization },
  thunk
) => ({
  type: UPDATE_ORGANIZATION_REQUEST_SUCCESS,
  payload: { organizationId, ...organization },
  meta: { thunk },
})

export const updateOrganizationRequestFailure = (e, thunk) => ({
  type: UPDATE_ORGANIZATION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_ORGANIZATION_EMAIL_DOMAINS = `${NAMESPACE}/UPDATE_EMAIL_DOMAINS`
export const UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST = `${UPDATE_ORGANIZATION_EMAIL_DOMAINS}/REQUEST`
export const UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_SUCCESS = `${UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST}_SUCCESS`
export const UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_FAILURE = `${UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST}_FAILURE`

export const updateOrganizationEmailDomainsRequest = ({
  organizationId,
  emailDomains,
}) => ({
  type: UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST,
  payload: { organizationId, emailDomains },
  meta: { thunk: UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST },
})

export const updateOrganizationEmailDomainsRequestSuccess = (
  { organizationId, emailDomains },
  thunk
) => ({
  type: UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_SUCCESS,
  payload: { organizationId, emailDomains },
  meta: { thunk },
})

export const updateOrganizationEmailDomainsRequestFailure = (e, thunk) => ({
  type: UPDATE_ORGANIZATION_EMAIL_DOMAINS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_ORGANIZATION = `${NAMESPACE}/DELETE`
export const DELETE_ORGANIZATION_REQUEST = `${NAMESPACE}/DELETE/REQUEST`
export const DELETE_ORGANIZATION_REQUEST_SUCCESS = `${NAMESPACE}/DELETE/REQUEST_SUCCESS`
export const DELETE_ORGANIZATION_REQUEST_FAILURE = `${NAMESPACE}/DELETE/REQUEST_FAILURE`

export const deleteOrganizationRequest = organizationId => ({
  type: DELETE_ORGANIZATION_REQUEST,
  payload: { organizationId },
  meta: { thunk: DELETE_ORGANIZATION_REQUEST },
})

export const deleteOrganizationRequestSuccess = (payload, thunk) => ({
  type: DELETE_ORGANIZATION_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteOrganizationRequestFailure = (e, thunk) => ({
  type: DELETE_ORGANIZATION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
