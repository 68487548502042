import { DEFAULT_THEME } from '@zendeskgarden/react-theming'
import { css } from 'styled-components'
import { lighten, darken } from 'polished'

export const primaryColor = 'hsl(0, 0%, 7%)'
export const greenColor = 'hsl(144, 73%, 41%)'
const violetColor = 'hsl(266, 83%, 48%)'
export const secondaryColor = violetColor
export const yellowColor = 'hsl(40, 91%, 53%)'
export const warningColor = 'hsl(359, 66%, 51%)'

const secondary100 = 'hsl(266, 83%, 94%)'
const secondary200 = 'hsl(266, 83%, 90%)'
const secondary300 = 'hsl(266, 83%, 80%)'
const secondary400 = 'hsl(266, 83%, 70%)'
const secondary500 = 'hsl(266, 83%, 60%)'
const secondary600 = violetColor
const secondary700 = 'hsl(266, 83%, 35%)'
const secondary800 = 'hsl(266, 83%, 25%)'
const secondary900 = 'hsl(266, 83%, 18%)'

const greyColor = 'hsl(210, 10%, 40%)'

const colors = {
  grey: greyColor,
  grey100: 'hsl(210, 10%, 96%)',
  grey200: 'hsl(210, 10%, 86%)',
  grey300: 'hsl(210, 10%, 72%)',
  grey400: 'hsl(210, 10%, 56%)',
  grey500: 'hsl(210, 10%, 48%)',
  grey600: greyColor,
  grey700: 'hsl(210, 10%, 30%)',
  grey800: 'hsl(210, 10%, 20%)',
  grey900: 'hsl(210, 10%, 10%)',
  secondary: violetColor,
  secondary100,
  secondary200,
  secondary300,
  secondary400,
  secondary500,
  secondary600,
  secondary700,
  secondary800,
  secondary900,
  green: greenColor,
  green100: 'hsl(144, 73%, 91%)',
  green200: 'hsl(144, 73%, 81%)',
  green300: 'hsl(144, 73%, 71%)',
  green400: 'hsl(144, 73%, 61%)',
  green500: 'hsl(144, 73%, 51%)',
  green600: greenColor,
  green700: 'hsl(144, 73%, 31%)',
  green800: 'hsl(144, 73%, 21%)',
  green900: 'hsl(144, 73%, 11%)',
  yellow: yellowColor,
  yellow100: 'hsl(40, 91%, 95%)',
  yellow200: 'hsl(40, 91%, 90%)',
  yellow300: 'hsl(40, 91%, 83%)',
  yellow400: 'hsl(40, 91%, 73%)',
  yellow500: 'hsl(40, 91%, 64%)',
  yellow600: yellowColor,
  yellow700: 'hsl(36, 91%, 43%)',
  yellow800: 'hsl(32, 91%, 33%)',
  yellow900: 'hsl(30, 91%, 23%)',
  primary: primaryColor,
  primary100: lighten(0.8, primaryColor),
  primary200: lighten(0.6, primaryColor),
  primary300: lighten(0.4, primaryColor),
  primary400: lighten(0.25, primaryColor),
  primary500: lighten(0.1, primaryColor),
  primary600: primaryColor,
  primary700: darken(0.1, primaryColor),
  primary800: darken(0.2, primaryColor),
  primary900: darken(0.3, primaryColor),
  warning: warningColor,
  warning100: 'hsl(361, 66%, 93%)',
  warning200: 'hsl(361, 66%, 86%)',
  warning300: 'hsl(361, 66%, 76%)',
  warning400: 'hsl(361, 66%, 66%)',
  warning500: 'hsl(359, 66%, 58%)',
  warning600: warningColor,
  warning700: 'hsl(357, 66%, 40%)',
  warning800: 'hsl(353, 66%, 28%)',
  warning900: 'hsl(353, 66%, 16%)',
  smartAssistantPrimary: '#FB9D11',
  smartAssistantSecondary: '#FFD699',
}

const borders = {
  color: colors.grey100,
}

const boxShadows = {
  topBarElevation:
    '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);',
  elevation1:
    'box-shadow: 0 1px 3px hsla(0, 0%, 0%, .1), 0 1px 2px hsla(0, 0%, 0%, .12)',
  elevation2:
    'box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);',
  elevation3:
    'box-shadow: 0 10px 20px hsla(0, 0%, 0%, .08), 0 3px 6px hsla(0, 0%, 0%, .08)',
  elevation4:
    'box-shadow: 0 16px 32px hsla(0, 0%, 0%, .1), 0 4px 8px hsla(0, 0%, 0%, .06)',
  elevation5: 'box-shadow: 0 20px 40px hsla(0, 0%, 0%, .2)',
  altElevation1:
    'box-shadow: 0 1px 3px hsla(0, 0%, 0%, .12), 0 1px 2px hsla(0, 0%, 0%, .24)',
  altElevation2:
    'box-shadow: 0 3px 6px hsla(0, 0%, 0%, .15), 0 2px 4px hsla(0, 0%, 0%, .12)',
  altElevation3:
    'box-shadow: 0 10px 20px hsla(0, 0%, 0%, .15), 0 3px 6px hsla(0, 0%, 0%, .10)',
  altElevation4:
    'box-shadow: 0 15px 25px hsla(0, 0%, 0%, .15), 0 5px 10px hsla(0, 0%, 0%, .5)',
}

const buttons = css`
  border: none;
  box-shadow: 0;
  transition: background-color .15s linear;

  &&[disabled]{
    cursor: not-allowed;
    ${props => (props.isPrimary ? `color: ${colors.grey400} !important;` : '')}
    ${props =>
      props.isPrimary ? `background-color: ${colors.grey100} !important;` : ''}

    ${props =>
      props.isSecondary ? `color: ${colors.secondary400} !important;` : ''}
    ${props =>
      props.isSecondary
        ? `background-color: ${colors.secondary100} !important;`
        : ''}

    ${props => (props.isBasic ? `color: ${colors.grey400} !important;` : '')}
    ${props =>
      props.isBasic ? `background-color: transparent !important;` : ''}


    svg {
      ${props => (props.isPrimary ? `fill: ${colors.grey400};` : '')}
      ${props => (props.isSecondary ? `fill: ${colors.secondary400};` : '')}
      ${props => (props.isBasic ? `fill: ${colors.grey400};` : '')}
    }
  }
  && {
    ${props => (props.white ? 'background-color: white;' : '')}

    ${props => (props.isPrimary ? `color: white;` : '')}
    ${props => (props.isPrimary ? `background-color: ${primaryColor};` : '')}

    ${props => (props.isSecondary ? `color: white;` : '')}
    ${props =>
      props.isSecondary ? `background-color: ${secondaryColor};` : ''}
    ${props => (props.isSecondary ? `border-color: ${secondaryColor};` : '')}

    ${props => (props.isBasic ? `color: ${secondaryColor};` : '')}

    ${props =>
      props.isBasic && props.isPrimary ? `color: ${primaryColor};` : ''}

    ${props => (props.isBasic ? `background-color: transparent;` : '')}

    ${props => (props.isDanger ? `color: white;` : '')}
    ${props =>
      props.isDanger ? `background-color: ${colors.warning600};` : ''}

    ${props =>
      props.isBasic && props.isPrimary
        ? `background-color: transparent; color: ${colors.grey400};`
        : ''}

    svg {
      ${props => (props.isBasic ? `fill: ${colors.grey400} !important;` : '')}
    }
  }

  &&:hover {
    ${props =>
      props.isPrimary ? `background-color: ${colors.primary500};` : ''}

    ${props =>
      props.isSecondary ? `background-color: ${colors.secondary700};` : ''}

    ${props => (props.isBasic ? `color: ${colors.secondary600};` : '')}
    ${props =>
      props.isBasic ? `background-color: ${colors.secondary100};` : ''}
    
    ${props =>
      props.isBasic && props.isPrimary
        ? `background-color: ${colors.grey100}; color: ${colors.grey800} !important;`
        : ''}

    ${props => (props.white ? `background-color: #D0D2D3;` : '')}
    ${props => (props.white ? `color: #505050;` : '')}

    svg {
      ${props => (props.isBasic ? `fill: ${colors.grey800} !important;` : '')}
    }
  }

  &&:focus {
    box-shadow: none;
    ${props =>
      props.isPrimary ? `background-color: ${colors.primary300};` : ''}
    ${props =>
      props.isSecondary ? `background-color: ${colors.secondary400};` : ''}
    ${props => (props.isBasic ? `color: ${colors.secondary400};` : '')}

    ${props =>
      props.isBasic && props.isPrimary
        ? `background-color: ${colors.grey100}; color: ${colors.grey400}`
        : ''}
  }

  &&:active {
    ${props =>
      props.isPrimary ? `background-color: ${colors.primary800};` : ''}
    ${props =>
      props.isSecondary ? `background-color: ${colors.secondary500};` : ''}
    ${props =>
      props.isBasic ? `background-color: ${colors.secondary200};` : ''}
    ${props => (props.isBasic ? `color: ${colors.secondary800};` : '')}

    ${props =>
      props.isBasic && props.isPrimary
        ? `background-color: ${colors.grey200}; color: ${colors.grey600}`
        : ''}
  }
`

const inputBoxShadow = '0 0 0 3px'

const formsInput = css`
  :hover {
    border-color: ${colors.secondary400};
  }

  :focus {
    border-color: ${colors.secondary400};
    box-shadow: ${inputBoxShadow} ${colors.secondary100};
  }

  [data-variant='green']:hover {
    border-color: ${colors.green600};
  }

  [data-variant='green']:focus {
    border-color: ${colors.green600};
    box-shadow: ${inputBoxShadow} ${colors.green100};
  }
`

const dropdownsFauxInput = css`
  display: flex;
  justify-content: center;
  align-items: center;

  && svg {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &&:focus,
  &&:hover {
    border-color: ${colors.secondary400} !important;
  }

  &&:focus {
    box-shadow: ${inputBoxShadow} ${colors.secondary100} !important;
  }
`

const dropdownsSelect = css`
  &&:hover:not([disabled]) {
    border-color: ${colors.secondary400} !important;
    box-shadow: none !important;
  }

  &&:focus:not([disabled]) {
    border-color: ${colors.secondary400} !important;
    box-shadow: none !important;
  }

  &&[open] {
    border: 1px solid ${colors.secondary300} !important;
    box-shadow: none !important;
  }

  input,
  input:focus,
  input:hover {
    border: none !important;
    box-shadow: none !important;
  }
`

const dropdownsMenu = css`
  && {
    ${boxShadows.elevation4}
  }
`

const dropdownsItem = css`
  &&:hover:not([disabled]) {
    color: ${colors.secondary};
    background-color: ${colors.secondary200};
  }

  &&:active:not([disabled]) {
    color: ${colors.secondary};
    background-color: ${colors.secondary200};
  }

  &&:focus:not([disabled]) {
    color: ${colors.secondary};
    background-color: ${colors.secondary100};
  }

  &&[aria-selected='true'] {
    background-color: ${colors.secondary100};
  }

  && svg {
    fill: ${colors.grey400};
  }
  &&:hover:not([disabled]) svg {
    fill: ${colors.secondary400};
  }
`

const dropdownsItemMeta = css`
  && {
    color: ${colors.grey500};
  }
  &&:hover:not([disabled]) {
    color: ${colors.secondary400};
  }

  &&:active:not([disabled]) {
    color: ${colors.secondary500};
  }

  &&:focus:not([disabled]) {
    color: ${colors.secondary400};
  }
`

const tablesRow = css`
  &&:hover:not([disabled]) {
    color: ${colors.secondary};
    background-color: ${colors.secondary100};
    border-color: ${colors.secondary200};
  }
  &&:active {
    border-color: ${colors.secondary};
  }
`
const tablesCell = css`
  && {
    box-shadow: none !important;
  }

  &&:active:not([disabled]) {
    box-shadow: inset 3px 0 0 0 ${colors.secondary} !important;
  }
`

const avatars = css`
  && {
    background-color: transparent;
  }
`

const notificationsNotification = css`
  && {
    box-shadow: 0 20px 28px 0 ${colors.grey200};
  }
`

const tabs = css`
  &&[aria-selected='true'] {
    color: ${secondaryColor};
  }
`

const tooltip = css`
  &&[type='dark'] {
    color: white;
    background-color: ${colors.primary};
    box-shadow: 0 6px 12px 0 ${colors.grey200} !important;
  }
  &&[type='light'] {
    color: ${colors.primary};
    box-shadow: 0 20px 28px 0 ${colors.primary100} !important;
  }
`

const tooltipModal = css`
  && {
    box-shadow: 0 6px 12px 0 ${colors.grey200} !important;
  }
`

const palette = {
  blue: {
    '100': colors.secondary100,
    '200': colors.secondary200,
    '300': colors.secondary300,
    '400': colors.secondary400,
    '500': colors.secondary500,
    '600': colors.secondary600,
    '700': colors.secondary700,
    '800': colors.secondary800,
  },
  grey: {
    '100': colors.grey100,
    '200': colors.grey200,
    '300': colors.grey300,
    '400': colors.grey400,
    '500': colors.grey500,
    '600': colors.grey600,
    '700': colors.grey700,
    '800': colors.grey800,
  },
  azure: {
    '400': colors.secondary400,
    '600': colors.secondary600,
    M400: colors.secondary800,
    M600: colors.secondary900,
  },
  kale: {
    '100': colors.secondary100,
    '200': colors.secondary200,
    '300': colors.secondary300,
    '400': colors.secondary400,
    '500': colors.secondary500,
    '600': colors.secondary600,
    '700': colors.secondary700,
    '800': colors.secondary800,
  },
}

const theme = {
  ...DEFAULT_THEME,
  borders: {
    ...DEFAULT_THEME.borders,
    ...borders,
  },
  colors: {
    ...DEFAULT_THEME.colors,
    ...colors,
    base: 'light',
    background: 'white',
    foreground: colors.primary,
    primaryHue: colors.primary,
    dangerHue: colors.warning,
    warningHue: colors.yellow,
    successHue: colors.green,
    neutralHue: colors.grey300,
    chromeHue: 'kale',
  },

  palette: {
    ...DEFAULT_THEME.palette,
    ...palette,
  },
  boxShadows,
  boxShadowSelected: boxShadows.elevation4,
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  components: {
    'avatars.avatar': avatars,
    'buttons.button': buttons,
    'forms.input': formsInput,
    'forms.text_input': formsInput,
    'dropdowns.faux_input': dropdownsFauxInput,
    'dropdowns.select': dropdownsSelect,
    'dropdowns.menu': dropdownsMenu,
    'dropdowns.item': dropdownsItem,
    'dropdowns.item_meta': dropdownsItemMeta,
    'menus.item': dropdownsItem,
    'tables.row': tablesRow,
    'tables.cell': tablesCell,
    'notifications.notification': notificationsNotification,
    'tabs.tab': tabs,
    'tooltip.tooltip': tooltip,
    'modals.tooltip_modal': tooltipModal,
  },
}

export default theme
