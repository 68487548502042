import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import { getAppSyncClient } from '../../lib/appSyncClient'
import {
  createPageEventsRequest,
  CREATE_PAGE_EVENTS_REQUEST,
  readPageEventsRequest,
  READ_PAGE_EVENTS_REQUEST,
} from '../pageEvents/pageEvents.actions'

const apolloClient = getAppSyncClient()

const createPageEventsPending = state =>
  pending(state, CREATE_PAGE_EVENTS_REQUEST)
const createPageEventsDone = state => done(state, CREATE_PAGE_EVENTS_REQUEST)
const createPageEventsFulfilled = state =>
  fulfilled(state, CREATE_PAGE_EVENTS_REQUEST)
const createPageEventsRejected = state =>
  rejected(state, CREATE_PAGE_EVENTS_REQUEST)

const readPageEventsPending = state => pending(state, READ_PAGE_EVENTS_REQUEST)
const readPageEventsDone = state => done(state, READ_PAGE_EVENTS_REQUEST)
const readPageEventsFulfilled = state =>
  fulfilled(state, READ_PAGE_EVENTS_REQUEST)
const readPageEventsRejected = state =>
  rejected(state, READ_PAGE_EVENTS_REQUEST)

const useCRUDPageEvents = () => {
  const dispatch = useDispatch()

  const onCreatePageEventsHandler = useCallback(
    (sessionId, events) =>
      dispatch(
        createPageEventsRequest({
          sessionId,
          events,
        })
      ),
    [apolloClient]
  )

  const isCreatePageEventsPending = useSelector(createPageEventsPending)
  const isCreatePageEventsDone = useSelector(createPageEventsDone)
  const isCreatePageEventsFulfilled = useSelector(createPageEventsFulfilled)
  const isCreatePageEventsRejected = useSelector(createPageEventsRejected)

  const onReadPageEventsHandler = useCallback(
    sessionId => dispatch(readPageEventsRequest(sessionId)),
    [apolloClient]
  )

  const isReadPageEventsPending = useSelector(readPageEventsPending)
  const isReadPageEventsDone = useSelector(readPageEventsDone)
  const isReadPageEventsFulfilled = useSelector(readPageEventsFulfilled)
  const isReadPageEventsRejected = useSelector(readPageEventsRejected)

  return {
    onCreatePageEventsHandler,
    isCreatePageEventsPending,
    isCreatePageEventsDone,
    isCreatePageEventsFulfilled,
    isCreatePageEventsRejected,
    onReadPageEventsHandler,
    isReadPageEventsPending,
    isReadPageEventsDone,
    isReadPageEventsFulfilled,
    isReadPageEventsRejected,
  }
}

export default useCRUDPageEvents
