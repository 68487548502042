import React from 'react'
import styled from 'styled-components'
import unionBy from 'lodash/unionBy'
import sortBy from 'lodash/sortBy'
import prop from 'lodash/fp/prop'
import indexOfAll from '../../../util/indexOfAll'
import { convertURLsToAnchors } from '../../componentUtils'

const Mention = styled.span`
  color: ${prop('theme.colors.secondary')};
`

export const MENTION_CHAR = '@'

export const splitText = (text, mentions) => {
  const splitPositions = []
  const uniqSortedNames = unionBy(mentions, 'name').sort(
    (a, b) => b.name.length - a.name.length
  )

  const indexes = []
  uniqSortedNames.forEach(({ name }) => {
    const matchText = `${MENTION_CHAR}${name}`
    indexOfAll(text, matchText).forEach(index => {
      if (!indexes.includes(index)) {
        splitPositions.push({ index, length: matchText.length })
        indexes.push(index)
      }
    })
  })

  if (splitPositions.length === 0) {
    return [text]
  }

  return sortBy(splitPositions, ['index']).reduce(
    (acc, splitPosition, index, inputArray) => {
      const { index: splitIndex, length } = splitPosition

      const isLast = index === inputArray.length - 1

      if (index === 0) {
        const textBefore = text.substring(0, splitIndex)
        const mentionText = text.substring(splitIndex, splitIndex + length)
        if (isLast) {
          const finalText = text.substring(splitIndex + length)

          return [textBefore, mentionText, finalText]
        }
        return [textBefore, mentionText]
      }

      const previousSplit = inputArray[index - 1]

      const textBeforeIndex = previousSplit.index + previousSplit.length
      const textBeforeEndIndex = splitIndex

      const textBefore = text.substring(textBeforeIndex, textBeforeEndIndex)
      const mentionText = text.substring(splitIndex, splitIndex + length)
      const skipTextBefore = textBefore.startsWith(MENTION_CHAR)
      if (isLast) {
        const finalText = text.substring(splitIndex + length)

        if (skipTextBefore) {
          return finalText
            ? [...acc, mentionText, finalText]
            : [...acc, mentionText]
        }
        return finalText
          ? [...acc, textBefore, mentionText, finalText]
          : [...acc, textBefore, mentionText]
      }
      if (skipTextBefore) {
        return [...acc, mentionText]
      }
      return [...acc, textBefore, mentionText]
    },
    []
  )
}

const formatMentionSuggestions = (text, mentions = []) => {
  if (!mentions || mentions.length === 0) {
    return convertURLsToAnchors(text)
  }
  const splits = splitText(text, mentions)

  const mentionNames = mentions.map(({ name }) => `${MENTION_CHAR}${name}`)
  return splits.map(split => {
    if (!split) {
      return ''
    }
    if (mentionNames.includes(split)) {
      return <Mention key={split}>{split}</Mention>
    }

    return convertURLsToAnchors(split)
  })
}

export default formatMentionSuggestions
