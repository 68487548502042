import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../Flex'

const Wrapper = styled(Flex)`
  border-radius: 8px;
  padding: 8px;
  background-color: white;
  flex-shrink: 0;
  transition: height 0.3s ease-in-out, box-shadow 0.15s ease-out,
    margin 0.3 ease-in-out;
  ${({ elevation, ...props }) =>
    prop(`theme.boxShadows.elevation${elevation}`)(props)};
`

const Card = ({ children, elevation, ...props }) => (
  <Wrapper elevation={elevation} {...props}>
    {children}
  </Wrapper>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  elevation: PropTypes.number,
}

Card.defaultProps = {
  elevation: 2,
}

export default Card
