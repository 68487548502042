import React from 'react'
import { isScreenRecordingSupported } from '../../../lib/mediaRecorder'
import ProductSelector from './ProductSelector'

const additionalProducts = [
  {
    iconName: 'BugOutlineIcon',
    title: 'Bug report',
    descriptionHtml:
      '<div class="library-modal">   <h7>How to create a bug report that will make developers happy</h7>   <br>   <br>   <ul class="library-modal-list">     <li>       <b>🪐 &nbsp; Give context</b><br>       Assume that your developer has no idea about the bug you found     </li>     <li>       <b>📹 &nbsp; Record the steps to reproduce the bug</b><br>       Keep it simple. The goal is to allow developers to reproduce the bug on their side.     </li>     <li>       <b>🐞 &nbsp; Show expected result vs actual result</b><br>       Explain what you expected to happen and what actually happened.     </li>     <li>       <b>💻 &nbsp; Include environment info</b><br>       We got you covered! Technical data is collected by default with iterspace.     </li>     <li>       <b>❤️ &nbsp; Share accurate report</b><br>       We promise, reporting a bug never felt this good!     </li>   </ul> </div>',
    libraryRecordingId: '',
  },
  {
    descriptionHtml:
      '<div class="library-modal">   <h7>A successful design process is collaborative.</h7>   <div>Ready to provide helpful design feedback?</div>   <br>   <ul class="library-modal-list">     <li>       <b>🪐 &nbsp; Give context</b><br>       Set the stage with problems and goals.     </li>     <li>       <b>❓ &nbsp; Ask questions</b><br>       Encourage discussion and ensure that no assumptions are being made.     </li>     <li>       <b>🐞 &nbsp; Communicate problems, not solutions</b><br>       Empower designers with knowledge to explore different solutions.     </li>     <li>       <b>🎯 &nbsp; Focus on strategic goals</b><br>       Does the design successfully address your audience needs?     </li>     <li>       <b>👏 &nbsp; Share the good</b><br>       Don’t forget that everyone likes to receive high-fives.     </li>   </ul> </div>',
    iconName: 'PencilOutlineIcon',
    title: 'Design review',
    libraryRecordingId: '',
  },
  {
    libraryRecordingId: '',
    iconName: 'LightbulbOnOutlineIcon',
    title: 'Inspiration',
    descriptionHtml:
      '<div class="library-modal">   <h7>How to easily collect inspiration for your project</h7>   <br>   <br>   <ul class="library-modal-list">     <li>       <b>🎯 &nbsp; Define your scope</b><br>       Pick the area you want to look at (e.g. a checkout flow or the landing experience).     </li>     <li>       <b>👤 &nbsp; Test the site acting as a user </b><br>       This way you’ll know which parts are working better than others.     </li>     <li>       <b>🌟 &nbsp; Highlight interesting things </b><br>       Leave a comment each time you find something that stands out.     </li>     <li>       <b>🚫 &nbsp; Mark approaches to avoid</b><br>       Don’t focus on too many of them, but it’s worth to leave a comment on the worst offenders.     </li>     <li>       <b>🏷 &nbsp; Give a proper title</b><br>       A clear title makes the inspiration session easy to find.     </li>   </ul> </div>',
  },
]

const ProductSelectorContainer = ({ ...props }) => (
  <ProductSelector
    {...props}
    additionalProducts={additionalProducts}
    isScreenRecordingSupported={isScreenRecordingSupported()}
  />
)

export default ProductSelectorContainer
