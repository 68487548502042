import appConfig from '../config'
import { isChromeLikeBrowser, isEdgeBrowser } from './platform'

const sendMessageToBackground = message => {
  return new Promise(resolve => {
    appConfig.chromeExtensionIds.forEach(chromeExtensionId => {
      // eslint-disable-next-line no-undef
      if (chrome?.runtime) {
        // eslint-disable-next-line no-undef
        chrome.runtime.sendMessage(chromeExtensionId, message, response => {
          if (response) {
            resolve(response)
          }
        })
      }
    })
  })
}

export const openExtensionPage = () => {
  if (isChromeLikeBrowser()) {
    window.open(appConfig.chromeExtensionUrl)
    return
  }

  if (isEdgeBrowser()) {
    window.open(appConfig.edgeExtensionUrl)
  }
}

export default {
  sendMessageToBackground,
}
