import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { MD } from '@zendeskgarden/react-typography'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'
import prop from 'lodash/fp/prop'
import IconButton from '../../molecules/IconButton/IconButton'
import CreateProjectModal from '../../molecules/CreateProjectModal'
import ProjectShareModal from '../../molecules/ProjectShareModal'
import Flex from '../../atoms/Flex'
import useEntryView from './useEntryView'
import ProjectsList from '../ProjectsList'
import WorkspaceUpsellModal from '../../molecules/WorkspaceUpsellModal/WorkspaceUpsellModal'
import { isMobile } from '../../../lib/platform'
import CreateOrganizationModal from '../../molecules/CreateOrganizationModal/CreateOrganizationModal'
import fromSessions from '../../../store/sessions/sessions.selectors'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'

const Wrapper = styled(Flex)`
  display: ${({ fullHeight }) => (fullHeight ? 'flex' : 'block')};
  flex-grow: 1;
  height: 100%;
`

const TitleFlex = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`

const ProjectSectionCaption = styled(MD)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  margin: 8px;
  font-weight: bold;
`

const NewFolderIconButton = styled(IconButton)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  color: ${prop('theme.colors.primary')};
`

const ProjectsSidebar = ({ onNewProject, onProjectDelete, ...props }) => {
  const [isUpsellModalVisible, setUpsellModalVisible] = useState(false)
  const projects = useSelector(fromSessions.getProjectsMergedSessions)
  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)
  const {
    PERSONAL_ORGANIZATION_ID,
    currentOrganizationId,
    dropdownProject,
    isProjectModalVisible,
    isShareModalVisible,
    onSetCurrentProject,
    setDropdownProject,
    setProjectModalVisible,
    setShareModalVisible,
  } = useEntryView()

  const onShareHandler = useCallback(
    project => {
      if (currentOrganizationId === PERSONAL_ORGANIZATION_ID) {
        setUpsellModalVisible(true)
        return
      }
      setDropdownProject(project)
      setShareModalVisible(true)
    },
    [
      setDropdownProject,
      setShareModalVisible,
      setUpsellModalVisible,
      currentOrganizationId,
    ]
  )

  const { shouldShowCreation } = useLibraryWorkspace()

  return (
    <Wrapper fullHeight={projects.length === 0} {...props}>
      {projects.length > 0 && (
        <TitleFlex>
          <ProjectSectionCaption>Projects</ProjectSectionCaption>

          {shouldShowCreation && (
            <NewFolderIconButton
              icon={FolderPlusIcon}
              isBasic
              isPrimary
              onClick={() => setProjectModalVisible(true)}
              data-test="projects-sidebar-new-project-button"
            >
              New project
            </NewFolderIconButton>
          )}
        </TitleFlex>
      )}

      <ProjectsList
        onProjectClick={projectId => onSetCurrentProject(projectId)}
        onCreateProject={() => setProjectModalVisible(true)}
        onDelete={project => onProjectDelete(project.pk)}
        onShare={onShareHandler}
      />
      {isProjectModalVisible && (
        <CreateProjectModal
          isVisible={isProjectModalVisible}
          onClose={() => {
            setTimeout(() => setProjectModalVisible(false), 700)
          }}
        />
      )}
      {isShareModalVisible && (
        <ProjectShareModal
          isVisible={isShareModalVisible}
          projectId={dropdownProject ? dropdownProject.pk : null}
          onClose={() => {
            setTimeout(() => setShareModalVisible(false), 700)
          }}
        />
      )}
      <WorkspaceUpsellModal
        isVisible={isUpsellModalVisible}
        onClose={() => setUpsellModalVisible(false)}
        onCreate={() => setCreateOrganizationModalVisible(true)}
      />
      {!isMobile() && (
        <CreateOrganizationModal
          isVisible={isCreateOrganizationModalVisible}
          onClose={() => {
            setCreateOrganizationModalVisible(false)
          }}
        />
      )}
    </Wrapper>
  )
}

ProjectsSidebar.propTypes = {
  onNewProject: PropTypes.func,
  onProjectDelete: PropTypes.func,
}

ProjectsSidebar.defaultProps = {
  onNewProject: () => {},
  onProjectDelete: () => {},
}

export default ProjectsSidebar
