import { buildReducerForActions } from '../../util/reduxTools'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import {
  GET_SESSION_COMMENTS_REQUEST_SUCCESS,
  CREATE_COMMENT_RESOLUTION_REQUEST_SUCCESS,
} from './comments.actions'

export const STATE_KEY = 'comments'

export const INITIAL_STATE = {
  bySessionId: {},
}

const updateCommentOnStore = (state, { payload: { comment } }) => {
  const { sessionId } = comment
  const stateBySessionId = state.bySessionId

  const newStateBySessionId = {
    ...stateBySessionId,
    [sessionId]: {
      ...stateBySessionId[sessionId],
      byId: {
        ...stateBySessionId[sessionId].byId,
        [comment.pk]: comment,
      },
    },
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [comment.pk]: comment,
    },
    bySessionId: newStateBySessionId,
  }
}

const reducer = {
  [GET_SESSION_COMMENTS_REQUEST_SUCCESS]: (
    state,
    { payload: { sessionId, comments } }
  ) => {
    const newState = comments.reduce(
      (acc, comment) => {
        acc.byId[comment.pk] = comment
        acc.allIds.push(comment.pk)
        return acc
      },
      {
        byId: {},
        allIds: [],
      }
    )
    return {
      ...state,
      bySessionId: { ...state.bySessionId, [sessionId]: { ...newState } },
    }
  },
  [CREATE_COMMENT_RESOLUTION_REQUEST_SUCCESS]: updateCommentOnStore,
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
