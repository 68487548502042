import { useSelector } from 'react-redux'
import get from 'lodash/get'
import fromComments from '../comments/comments.selectors'
import fromUsers from '../users/users.selectors'

const useReply = (commentId, resolutionCreatedAt, resolutionStatus) => {
  const comment = useSelector(fromComments.getComment(commentId))

  const resolution = get(comment, 'resolutions', []).find(
    ({ createdAt, status }) =>
      createdAt === resolutionCreatedAt && status === resolutionStatus
  )

  const resolutionUserId = get(resolution, 'createdBy', null)
  const resolutionUser = useSelector(fromUsers.getUserById(resolutionUserId))

  return {
    comment,
    resolution,
    resolutionUser,
  }
}

export default useReply
