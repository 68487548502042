import React from 'react'
import DeviceSelector from './DeviceSelector'
import { isElectron } from '../../../lib/platform'
import useDeviceSelectorHook from './useDeviceSelectorHook'

const DeviceSelectorContainer = ({ ...props }) => {
  const storeData = useDeviceSelectorHook()

  return isElectron() ? <DeviceSelector {...storeData} {...props} /> : null
}

export default DeviceSelectorContainer
