import React, { useState, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import UserSettingsPageMobile from '../../pages/UserSettingsPageMobile'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { useGoogleLogin } from '@react-oauth/google'
import {
  isGoogleLogin,
  isGoogleSignedIn,
  googleSignIn,
} from '../../../util/auth'

const SettingsProxy = () => {
  const history = useHistory()
  const { isLogged } = useUserInfo()
  const [isSessionActive, setSessionActive] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse =>
      googleSignIn(
        codeResponse,
        () => {
          setSessionActive(true)
          setLoading(false)
        },
        () => {
          window.location.href = '/login'
        }
      ),
    flow: 'auth-code',
  })

  useLayoutEffect(() => {
    if (isLogged) {
      setLoading(true)

      if (isGoogleLogin()) {
        isGoogleSignedIn().then(isLoggedIn => {
          if (isLoggedIn) {
            setSessionActive(true)
            setLoading(false)
            return
          }

          if (!isLoggedIn) {
            loginWithGoogle()
          }
        })
        return
      }
      setSessionActive(true)
      setLoading(false)
      return
    }
    setSessionActive(false)
    setLoading(false)
  }, [isLogged])

  if (!isLogged) {
    history.push('/register')
    return null
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !isSessionActive) {
    history.push('/login')
    return null
  }

  if (!isLoading && isSessionActive) {
    return <UserSettingsPageMobile />
  }

  return null
}

export default SettingsProxy
