import React from 'react'
import PropTypes from 'prop-types'
import useCRUDComment from '../../../store/hooks/useCRUDComment'
import NewCommentModalMobile from './NewCommentModalMobile'

const NewCommentModalMobileContainer = ({
  videoCurrentTime,
  videoPoint,
  ...props
}) => {
  const { onCreateCommentHandler, isCreateCommentPending } = useCRUDComment()

  const onSubmit = (newReactionText, newReactionMentions) =>
    onCreateCommentHandler(
      newReactionText,
      videoCurrentTime,
      videoPoint,
      null,
      newReactionMentions
    )

  return (
    <NewCommentModalMobile
      {...props}
      onSubmit={onSubmit}
      isSubmitting={isCreateCommentPending}
      videoCurrentTime={videoCurrentTime}
      videoPoint={videoPoint}
    />
  )
}

NewCommentModalMobileContainer.propTypes = {
  videoCurrentTime: PropTypes.number.isRequired,
  videoPoint: PropTypes.shape({}).isRequired,
}

export default NewCommentModalMobileContainer
