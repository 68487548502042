import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 8px;
  flex-grow: 1;
`

const ImageFlex = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  justify-content: center;
  align-items: center;

  > svg {
    fill: ${prop('theme.colors.grey500')};
  }
`

const TextFlex = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  font-size: 20px;
  text-align: center;
`

const ButtonFlex = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

const EmptyStateMobile = ({
  icon,
  children,
  button,
  secondaryButton,
  ...props
}) => (
  <Wrapper {...props}>
    {icon && <ImageFlex>{icon}</ImageFlex>}
    {children && <TextFlex>{children}</TextFlex>}
    {button && (
      <ButtonFlex>
        <Button isPrimary onClick={e => button.onClick(e)}>
          {button.text}
        </Button>
      </ButtonFlex>
    )}
    {secondaryButton && (
      <ButtonFlex>
        <Button secondary onClick={e => secondaryButton.onClick(e)}>
          {secondaryButton.text}
        </Button>
      </ButtonFlex>
    )}
  </Wrapper>
)

EmptyStateMobile.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  secondaryButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
}

EmptyStateMobile.defaultProps = {
  icon: null,
  children: null,
  button: null,
  secondaryButton: null,
}

export default EmptyStateMobile
