import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Flex from '../../atoms/Flex'
import MobileModal from '../../molecules/MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'
import MobileTopBar from '../../molecules/MobileTopBar'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-self: flex-end;

  && div:first-child {
    border-top: none;
  }
`

const ActivitiesPageMobile = ({
  onClose,
  notificationItems,
  onMarkAllAsReadClick,
  unreadNotificationsCount,
}) => {
  const header = (
    <StyledMobileTopBar
      icon={ArrowLeftIcon}
      onButtonClick={onClose}
      rightIcon={unreadNotificationsCount > 0 ? PlaylistCheckIcon : null}
      onRightButtonClick={onMarkAllAsReadClick}
    >
      Notifications
    </StyledMobileTopBar>
  )

  const body = <Body>{notificationItems}</Body>

  return (
    <MobileModal>
      <MobileTemplate header={header} body={body} footer={null} />
    </MobileModal>
  )
}

ActivitiesPageMobile.propTypes = {
  onClose: PropTypes.func,
  notificationItems: PropTypes.arrayOf(PropTypes.element),
  onMarkAllAsReadClick: PropTypes.func,
  unreadNotificationsCount: PropTypes.number,
}

ActivitiesPageMobile.defaultProps = {
  onClose: () => {},
  notificationItems: [],
  onMarkAllAsReadClick: () => {},
  unreadNotificationsCount: 0,
}

export default ActivitiesPageMobile
