import { useEffect, useState } from 'react'
import messageTypes from '../../browser-extension/messageTypes'
import browserExtension from '../../lib/browserExtension'

const useBrowserExtensionInstalled = () => {
  const [isBrowserExtensionInstalled, setExtensionInstalled] = useState(false)

  const update = () =>
    browserExtension
      .sendMessageToBackground({ type: messageTypes.PING })
      .then(response => {
        if (response?.type === messageTypes.PONG) {
          setExtensionInstalled(true)
          return
        }
        setExtensionInstalled(false)
      })
      .catch(() => setExtensionInstalled(false))

  useEffect(() => {
    setInterval(() => update(), 5000)
    update()
  }, [])

  return isBrowserExtensionInstalled
}
export default useBrowserExtensionInstalled
