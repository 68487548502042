import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Field, Label, Toggle, Input } from '@zendeskgarden/react-forms'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import Flex from '../../atoms/Flex'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import useShareModal from './useShareModal'
import Sharing from '../Sharing/Sharing'
import IconButton from '../IconButton'
import { getLinkTitle } from './ShareModal'
import ProjectOwner from '../ProjectShareModal/ProjectOwner'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Grid = styled(Flex)``

const Row = styled(Flex)`
  flex-direction: row;
`

const Col = styled(Flex)`
  flex-direction: column;
`

const Body = styled(Flex)`
  padding: 16px;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareableLinkTextField = EmailTextField

const CopyButton = styled(IconButton)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
`

const NormalText = styled.span`
  font-weight: normal;
`

const NormalLabel = styled(Flex)`
  display: inline-block;
  font-weight: normal;
  margin-bottom: 6px;
  flex-direction: row;

  b {
    margin-right: 4px;
  }
`

const Section = styled(Flex)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const ShareModalMobile = ({ sessionId, projectId, isVisible, onClose }) => {
  const {
    areCommentsAllowedState,
    copyButtonText,
    enrichedUsers,
    isCurrentUserOwner,
    isFormLoading,
    isLogged,
    isModalVisible,
    isPublicState,
    isUserContributor,
    onModalClose,
    onShareButtonClick,
    onShareSubmit,
    session,
    setCommentsAllowed,
    setCopyButtonText,
    setIsPublic,
    setShareValue,
    shareableLink,
    shareCallback,
    title,
    updateSessionSharing,
    isShareButtonEnabled,
    onAddUser,
    onRemoveUser,
    totalWorkspaceMembers,
    totalContributors,
    workspaceName,
    sessionOwnerUser,
    remoteTestOwnerUser,
    isSessionProjectSharedWithAnyone,
    resourceName,
    isPersonalWorkspace,
    remoteTestId,
  } = useShareModal({
    sessionId,
    projectId,
    isVisible,
    onClose,
  })

  const shareLinkLabel = getLinkTitle({
    isSessionProjectSharedWithAnyone,
    workspaceName,
    resourceName,
    totalContributors,
    isPublic: isPublicState,
  })

  const isListOfContributorsVisible =
    isLogged &&
    (isUserContributor || isCurrentUserOwner) &&
    !isPersonalWorkspace &&
    !isSessionProjectSharedWithAnyone &&
    !isPublicState

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        rightIcon={CloseIcon}
        onRightButtonClick={onModalClose}
      >
        <div>
          Share {resourceName}&nbsp;<NormalText>{title}</NormalText>
        </div>
      </StyledMobileTopBar>
    ),
    [projectId, title, onModalClose]
  )

  const body = useMemo(
    () => (
      <Body>
        <Section>
          <Field>
            <NormalLabel>{shareLinkLabel}</NormalLabel>
          </Field>
          <Flex direction="row">
            <ShareableLinkTextField>
              <Input type="text" defaultValue={shareableLink} />
            </ShareableLinkTextField>
            <CopyButton
              isPrimary
              icon={ContentCopyIcon}
              iconProps={{
                size: 20,
              }}
              onClick={() => {
                copy(shareableLink)
                setCopyButtonText('Copied')
                setTimeout(() => {
                  if (copyButtonText) {
                    setCopyButtonText('Copy')
                  }
                }, 2000)
              }}
            >
              {copyButtonText}
            </CopyButton>
          </Flex>
          {isLogged && isUserContributor && session && (
            <Grid>
              <Row style={{ padding: '8px 0' }}>
                <Col>
                  <Field>
                    <Toggle
                      checked={isPublicState}
                      onChange={event => {
                        const value = event.target.checked
                        setIsPublic(value)
                        let commentsAllowed = areCommentsAllowedState
                        if (!value) {
                          commentsAllowed = false
                          setCommentsAllowed(commentsAllowed)
                        }
                        updateSessionSharing({
                          isPublic: value,
                          areCommentsAllowed: commentsAllowed,
                        })
                      }}
                    >
                      <Label regular>Public</Label>
                    </Toggle>
                  </Field>
                </Col>
              </Row>

              <Row style={{ padding: '8px 0' }}>
                <Col>
                  <Field>
                    <Toggle
                      disabled={!isPublicState}
                      checked={areCommentsAllowedState}
                      onChange={event => {
                        setCommentsAllowed(event.target.checked)
                        updateSessionSharing({
                          isPublic: isPublicState,
                          areCommentsAllowed: event.target.checked,
                        })
                      }}
                    >
                      <Label regular>Allow comments</Label>
                    </Toggle>
                  </Field>
                </Col>
              </Row>
            </Grid>
          )}
        </Section>
        {isListOfContributorsVisible && (
          <Section>
            <Sharing
              users={enrichedUsers}
              isInviteButtonDisabled={!isShareButtonEnabled || isFormLoading}
              onInput={e => setShareValue(e.target.value)}
              onAdd={userEmail => onAddUser(userEmail)}
              onRemove={userEmail => onRemoveUser(userEmail)}
              totalWorkspaceMembers={totalWorkspaceMembers}
              totalContributors={totalContributors}
            />
          </Section>
        )}
        {sessionOwnerUser && session && (
          <Section>
            <Field style={{ marginTop: '8px', marginBottom: '4px' }}>
              <Label>
                <NormalText>
                  Only the owner can change sharing settings
                </NormalText>
              </Label>
            </Field>
            <ProjectOwner
              name={sessionOwnerUser.name}
              email={sessionOwnerUser.email}
              picture={sessionOwnerUser.picture}
              isOwner
            />
          </Section>
        )}
        {remoteTestOwnerUser && remoteTestId && (
          <Section>
            <Field style={{ marginTop: '8px', marginBottom: '4px' }}>
              <Label>
                <NormalText>
                  Only the owner can change sharing settings
                </NormalText>
              </Label>
            </Field>
            <ProjectOwner
              name={remoteTestOwnerUser.name}
              email={remoteTestOwnerUser.email}
              picture={remoteTestOwnerUser.picture}
              isOwner
            />
          </Section>
        )}
      </Body>
    ),
    [
      areCommentsAllowedState,
      copyButtonText,
      enrichedUsers,
      isCurrentUserOwner,
      isFormLoading,
      isLogged,
      isModalVisible,
      isPublicState,
      isUserContributor,
      onModalClose,
      onShareButtonClick,
      onShareSubmit,
      session,
      setCommentsAllowed,
      setCopyButtonText,
      setIsPublic,
      setShareValue,
      shareableLink,
      shareCallback,
      title,
      updateSessionSharing,
    ]
  )

  return (
    isModalVisible && (
      <MobileModal>
        <MobileTemplate header={header} body={body} footer={null} />
      </MobileModal>
    )
  )
}

ShareModalMobile.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  sessionId: PropTypes.string,
  projectId: PropTypes.string,
}

ShareModalMobile.defaultProps = {
  isVisible: false,
  onClose: () => {},
  sessionId: '',
  projectId: '',
}

export default ShareModalMobile
