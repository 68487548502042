import React from 'react'
import { withRouter } from 'react-router-dom'
import SessionViewMobile from '../../organisms/SessionView/SessionViewMobileNewContainer'

const AnonymousMainAppPageMobile = ({ ...props }) => (
  <SessionViewMobile
    isRecording={false}
    isEditMode
    readOnly
    isAnonymous
    {...props}
  />
)

export default withRouter(AnonymousMainAppPageMobile)
