import {
  GET_JIRA_REQUEST_FAILURE,
  GET_JIRA_REQUEST_SUCCESS,
  UPDATE_JIRA_REQUEST_SUCCESS,
} from './integrations.jira.actions'
import { buildReducerForActions } from '../../../util/reduxTools'

export const STATE_KEY = 'integrations'
export const STATE_SUB_KEY = 'jira'

export const INITIAL_STATE = {
  hasJira: false,
}

const reduceJira = (state, { payload: { data } }) => {
  return {
    ...state,
    ...data,
  }
}

const reducer = {
  [GET_JIRA_REQUEST_FAILURE]: () => {
    return INITIAL_STATE
  },
  [GET_JIRA_REQUEST_SUCCESS]: reduceJira,
  [UPDATE_JIRA_REQUEST_SUCCESS]: reduceJira,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
