import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setCurrentProject } from '../../../store/projects/projects.actions'
import { getProjectPathName } from '../../../util/paths'
import useCRUDProject from '../../../store/hooks/useCRUDProject'
import CreateProjectModal from './CreateProjectModal'
import useShareModal from '../ShareModal/useShareModal'
import useOrganization from '../../../store/hooks/useOrganization'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { toUserId } from '../../../util/utils'

const CreateProjectModalContainer = ({ isVisible, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { userIdentityId } = useUserInfo()
  const { isCurrentOrganizationPersonal } = useOrganization()
  const [title, setTitle] = useState('')
  const [isTitleErrorVisible, setTitleErrorVisible] = useState(false)

  const { onCreateProjectHandler, isCreateProjectPending } = useCRUDProject()

  const onTitleInput = e => {
    setTitle(e.target.value)
    setTitleErrorVisible(!e.target.value)
  }

  const {
    enrichedUsers,
    isCurrentUserOwner,
    workspaceName,
    totalWorkspaceMembers,
  } = useShareModal({})

  const [enrichedUsersState, setEnrichedUsersState] = useState(enrichedUsers)
  const [shareType, setShareType] = useState('')

  const [invitedUsersEmails, setInvitedUsersEmails] = useState([])

  useEffect(() => {
    const newUsers = enrichedUsers
      .filter(user => toUserId(user.pk) !== toUserId(userIdentityId))
      .map(user => {
        return {
          ...user,
          isInvited: invitedUsersEmails.includes(user.email),
        }
      })
    setEnrichedUsersState(newUsers)
  }, [enrichedUsers?.length, invitedUsersEmails?.length, userIdentityId])

  const onAddUser = userEmail => {
    setInvitedUsersEmails([...invitedUsersEmails, userEmail])
    return Promise.resolve()
  }

  const onRemoveUser = userEmail => {
    setInvitedUsersEmails(
      invitedUsersEmails.filter(email => email !== userEmail)
    )
    return Promise.resolve()
  }

  const onSubmit = useCallback(() => {
    if (!title) {
      setTitleErrorVisible(true)
      return
    }
    onCreateProjectHandler(title, shareType, invitedUsersEmails)
      .then(data => {
        if (data) {
          setTimeout(() => {
            history.push(getProjectPathName(data.data.createProject.pk))
            dispatch(setCurrentProject(data.data.createProject.pk))
          }, 600)
        }
        onClose(null, data)
      })
      .catch(error => {
        onClose(error)
      })
  }, [title, invitedUsersEmails, shareType])

  return (
    <CreateProjectModal
      isVisible={isVisible}
      onClose={onClose}
      onTitleInput={onTitleInput}
      onSubmit={onSubmit}
      onShareTypeChange={setShareType}
      isLoading={isCreateProjectPending}
      enrichedUsers={enrichedUsersState}
      isInviteButtonDisabled={isCurrentUserOwner}
      onAddUser={onAddUser}
      onRemoveUser={onRemoveUser}
      showUserBox
      totalContributors={invitedUsersEmails.length}
      totalWorkspaceMembers={totalWorkspaceMembers.length}
      workspaceName={workspaceName}
      isPersonalWorkspace={isCurrentOrganizationPersonal}
      isTitleErrorVisible={isTitleErrorVisible}
    />
  )
}

CreateProjectModalContainer.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

CreateProjectModalContainer.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

CreateProjectModalContainer.whyDidYouRender = true

export default CreateProjectModalContainer
