const NAMESPACE = 'INTEGRATIONS/TRELLO'

export const GET_TRELLO = `${NAMESPACE}/GET`
export const GET_TRELLO_REQUEST = `${GET_TRELLO}/REQUEST`
export const GET_TRELLO_REQUEST_SUCCESS = `${GET_TRELLO_REQUEST}_SUCCESS`
export const GET_TRELLO_REQUEST_FAILURE = `${GET_TRELLO_REQUEST}_FAILURE`

export const getTrelloRequest = () => ({
  type: GET_TRELLO_REQUEST,
  payload: {},
  meta: { thunk: GET_TRELLO_REQUEST },
})

export const getTrelloRequestSuccess = (data, thunk) => ({
  type: GET_TRELLO_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const getTrelloRequestFailure = (e, thunk) => ({
  type: GET_TRELLO_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_TRELLO = `${NAMESPACE}/UPDATE`
export const UPDATE_TRELLO_REQUEST = `${UPDATE_TRELLO}/REQUEST`
export const UPDATE_TRELLO_REQUEST_SUCCESS = `${UPDATE_TRELLO_REQUEST}_SUCCESS`
export const UPDATE_TRELLO_REQUEST_FAILURE = `${UPDATE_TRELLO_REQUEST}_FAILURE`

export const updateTrelloRequest = () => ({
  type: UPDATE_TRELLO_REQUEST,
  payload: {},
  meta: { thunk: UPDATE_TRELLO_REQUEST },
})

export const updateTrelloRequestSuccess = (data, thunk) => ({
  type: UPDATE_TRELLO_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const updateTrelloRequestFailure = (e, thunk) => ({
  type: UPDATE_TRELLO_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_TRELLO = `${NAMESPACE}/CREATE`
export const CREATE_TRELLO_REQUEST = `${CREATE_TRELLO}/REQUEST`
export const CREATE_TRELLO_REQUEST_SUCCESS = `${CREATE_TRELLO_REQUEST}_SUCCESS`
export const CREATE_TRELLO_REQUEST_FAILURE = `${CREATE_TRELLO_REQUEST}_FAILURE`

export const createTrelloRequest = ({
  title,
  description,
  listId,
  boardId,
  sourceUrl,
  sessionId,
}) => ({
  type: CREATE_TRELLO_REQUEST,
  payload: {
    title,
    description,
    listId,
    boardId,
    sourceUrl,
    sessionId,
  },
  meta: { thunk: CREATE_TRELLO_REQUEST },
})

export const createTrelloRequestSuccess = (data, thunk) => ({
  type: CREATE_TRELLO_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const createTrelloRequestFailure = (e, thunk) => ({
  type: CREATE_TRELLO_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const REMOVE_TRELLO = `${NAMESPACE}/REMOVE`
export const REMOVE_TRELLO_REQUEST = `${REMOVE_TRELLO}/REQUEST`
export const REMOVE_TRELLO_REQUEST_SUCCESS = `${REMOVE_TRELLO_REQUEST}_SUCCESS`
export const REMOVE_TRELLO_REQUEST_FAILURE = `${REMOVE_TRELLO_REQUEST}_FAILURE`

export const removeTrelloRequest = () => ({
  type: REMOVE_TRELLO_REQUEST,
  payload: {},
  meta: { thunk: REMOVE_TRELLO_REQUEST },
})

export const removeTrelloRequestSuccess = (data, thunk) => ({
  type: REMOVE_TRELLO_REQUEST_SUCCESS,
  payload: { data },
  meta: { thunk },
})

export const removeTrelloRequestFailure = (e, thunk) => ({
  type: REMOVE_TRELLO_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
