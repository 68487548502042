import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Avatar } from '@zendeskgarden/react-avatars'
import theme from '../../theme/old'

const Wrapper = styled.div`
  width: auto;
`

const commonCss = css`
  &:before {
    color: white !important;
    box-shadow: none;
    ${({ badge, status }) =>
      badge || status
        ? 'box-shadow: inset 0 0 0 2px white;'
        : 'box-shadow:  none;'}
  }
  &:after {
    color: white;
  }

  img {
    border-radius: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }
`

const getNameShort = name => {
  if (name.includes('@')) {
    const [first, second] = name.split('@')[0].split('.')
    return first && second
      ? `${first[0].toUpperCase()}${second[0].toUpperCase()}`
      : first[0].toUpperCase()
  }
  if (name.includes(' ')) {
    const [first, second] = name.split(' ')
    return first && second
      ? `${first[0].toUpperCase()}${second[0].toUpperCase()}`
      : first[0].toUpperCase()
  }
  return name[0].toUpperCase()
}

const getAvatarContent = (imgUrl, letter, name) => {
  if (!imgUrl && letter) {
    return <Avatar.Text>{letter.toUpperCase()}</Avatar.Text>
  }
  if (!imgUrl && !letter && name) {
    return <Avatar.Text>{getNameShort(name)}</Avatar.Text>
  }
  if (imgUrl) {
    return <img alt="" src={imgUrl} />
  }

  return <></>
}

const getBackgroundColor = (backgroundColor, imgUrl) => {
  if (imgUrl) {
    return 'transparent'
  }

  if (backgroundColor) {
    return backgroundColor
  }

  return theme.colors.secondary
}

const StyledAvatar = styled(Avatar)`
  ${commonCss}
  ${({ backgroundColor, imgUrl }) =>
    `background-color: ${getBackgroundColor(
      backgroundColor,
      imgUrl
    )} !important;`}

    ${({ badgeBgColor }) =>
      badgeBgColor ? `box-shadow: 0 0 0 2px ${badgeBgColor} !important;` : ''}

  &&::after {
    ${({ badgeColor }) =>
      badgeColor ? `-webkit-text-fill-color: ${badgeColor} !important;` : ''}
    ${({ badgeBgColor }) =>
      badgeBgColor ? `background-color: ${badgeBgColor};` : ''}
  }
`

const UserAvatar = ({
  imgUrl,
  letter,
  name,
  size,
  status,
  badge,
  badgeColor,
  badgeBgColor,
  backgroundColor,
  ...props
}) => (
  <Wrapper {...props}>
    <StyledAvatar
      size={size}
      status={status}
      badge={badge}
      badgeColor={badgeColor}
      badgeBgColor={badgeBgColor}
      foregroundColor="white"
      backgroundColor={backgroundColor}
      imgUrl={imgUrl}
    >
      {getAvatarContent(imgUrl, letter, name)}
    </StyledAvatar>
  </Wrapper>
)

UserAvatar.propTypes = {
  imgUrl: PropTypes.string,
  letter: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([
    'extraextrasmall',
    'extrasmall',
    'small',
    'medium',
    'large',
  ]),
  status: PropTypes.oneOf(['away', 'available', undefined]),
  badge: PropTypes.number,
  badgeColor: PropTypes.string,
  badgeBgColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

UserAvatar.defaultProps = {
  imgUrl: '',
  letter: '',
  name: 'user',
  size: 'small',
  status: undefined,
  badge: undefined,
  badgeColor: undefined,
  badgeBgColor: undefined,
  backgroundColor: undefined,
}

export default UserAvatar
