import { set } from 'dot-prop-immutable'
import get from 'lodash/get'
import omit from 'lodash/omit'
import pipe from 'lodash/fp/pipe'
import concat from 'lodash/fp/concat'
import getOr from 'lodash/fp/getOr'
import { buildReducerForActions } from '../../../util/reduxTools'
import {
  UPSERT_LOCAL_SESSION,
  DELETE_LOCAL_SESSION,
} from './localSessions.actions'

export const STATE_KEY = 'localSessions'

export const INITIAL_STATE = {
  byId: {},
  allIds: [],
}

const upsertSession = (state, session) => {
  const { sessionId } = session
  const isSessionPresent = !!get(state, `byId.${sessionId}`)
  const newState = set(state, `byId.${sessionId}`, session)
  if (!isSessionPresent) {
    const newIds = pipe(
      getOr({}, 'allIds'),
      concat(sessionId)
    )(state)
    return set(newState, 'allIds', newIds)
  }
  return newState
}

const reducer = {
  [UPSERT_LOCAL_SESSION]: (state, { payload: { session } }) =>
    upsertSession(state, session),
  [DELETE_LOCAL_SESSION]: (state, { payload: { sessionId } }) => ({
    ...state,
    byId: omit(state.byId, sessionId),
    allIds: state.allIds.filter(id => id !== sessionId),
  }),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
