import React from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'
import quoteBackgroundTop from '../../../assets/quote_background_top.svg'
import quoteBackgroundBottom from '../../../assets/quote_background_bottom.svg'

const Wrapper = styled(Flex)`
  height: 100vh;
  width: 440px;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${prop('theme.colors.secondary')};
`

const Content = styled(Flex)`
  width: 296px;
  z-index: 1000;
`

const ImageTop = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`
const ImageBottom = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const QuoteText = styled(Flex)`
  border: 1px solid #cbb9ff;
  box-sizing: border-box;
  border-radius: 32px;
  border-bottom-left-radius: 0;
  padding: 16px 24px;
  color: white;
  font-weight: normal;
`

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 16px;
`

const QuoteUserCard = styled(Flex)`
  margin-top: 16px;
  flex-direction: row;
`

const QuoteContent = styled(Flex)``

const QuotedName = styled(Flex)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #f3f4fa;
  margin-top: 2px;
  margin-bottom: 2px;
`
const QuotedDescription = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #cbb9ff;
`

const Quote = ({ ...props }) => (
  <Wrapper {...props}>
    <Content>
      <QuoteText>
        Man, iterspace is one of the best products I&apos;ve stumbled upon in a
        minute. Such a delight to use.
      </QuoteText>
      <QuoteUserCard>
        <StyledUserAvatar size="medium" imgUrl="/avatars/avatar_1.png" />
        <QuoteContent>
          <QuotedName>Daniele Bogo</QuotedName>
          <QuotedDescription>Mobile Wrangler @ Automattic</QuotedDescription>
        </QuoteContent>
      </QuoteUserCard>
    </Content>
    <ImageTop src={quoteBackgroundTop} alt="top" />
    <ImageBottom src={quoteBackgroundBottom} alt="bottom" />
  </Wrapper>
)

export default Quote
