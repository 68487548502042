import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Div100vh from '../../molecules/Div100vh'
import { ternary } from '../../../util/utils'
import Flex from '../Flex'

const Wrapper = styled(Div100vh)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2400;
  will-change: opacity;
  transition: all 0.1s linear;
  opacity: ${ternary('isVisible')('1', '0')};
  visibility: ${ternary('isVisible')('visible', 'hidden')};
`

const SheetBox = styled(Flex)`
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  z-index: 2500;
  will-change: translateY;
  transition: all 0.3s ease-in-out;
  transform: translateY(${ternary('isVisible')('0', '100%')});
  ${prop('theme.boxShadows.elevation1')};
`

const Item = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  color: ${({ color, theme }) => color || theme.colors.grey800};
  font-weight: 400;
`

const ItemIcon = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  > * {
    fill: ${({ iconColor, theme }) => iconColor || theme.colors.grey300};
  }
`

const ItemContent = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 16px;
`
const ItemText = styled(Flex)`
  color: ${prop('theme.colors.grey800')};
`

const ItemSubText = styled(Flex)`
  color: ${prop('theme.colors.grey600')};
  margin-top: 8px;
  font-weight: 400;
  line-height: 1.5em;
`

const Title = styled(Item)`
  font-weight: bold;
  justify-content: flex-start;
  align-items: flex-start;
  letter-spacing: 0.04em;
  color: ${prop('theme.colors.grey600')};
`

const Description = styled(Item)`
  font-weight: normal;
  font-size: 14px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0 16px;
  color: ${prop('theme.colors.grey600')};
  line-height: 1.5em;
`

const SheetList = ({
  isVisible,
  title,
  description,
  items,
  onClose,
  ...props
}) => (
  <Wrapper {...props} isVisible={isVisible} onClick={onClose}>
    <SheetBox
      isVisible={isVisible}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {items.map(
        ({
          text = '',
          subtext = '',
          icon = '',
          onClick = null,
          style = {},
          iconColor = null,
        }) => (
          <Item
            key={text}
            style={style}
            onClick={e => {
              onClick(e)
              onClose()
            }}
          >
            {icon && <ItemIcon iconColor={iconColor}>{icon}</ItemIcon>}
            <ItemContent>
              {text && <ItemText>{text}</ItemText>}
              {subtext && <ItemSubText>{subtext}</ItemSubText>}
            </ItemContent>
          </Item>
        )
      )}
    </SheetBox>
  </Wrapper>
)

SheetList.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
}

SheetList.defaultProps = {
  onClose: () => {},
  title: '',
  description: '',
  items: [],
  isVisible: false,
}

export default SheetList
