/* eslint-disable import/prefer-default-export */
import React from 'react'
import Anchor from './atoms/Anchor'
import { getURLs } from '../util/utils'

export const convertURLsToAnchors = text => {
  const urls = getURLs(text)
  if (!urls) {
    return text
  }
  const splits = []
  let currentText = text
  urls.forEach((url, index) => {
    const [initial, later] = currentText.split(url)
    splits.push(initial)
    splits.push(url)
    currentText = later
    if (index === urls.length - 1) {
      splits.push(later)
    }
  })
  return splits.map((split, index) => {
    if (index % 2 === 1) {
      return (
        <Anchor href={split} target="_blank" rel="noopener noreferrer">
          {split}
        </Anchor>
      )
    }
    return split
  })
}
