import { useMemo, useState } from 'react'
import uniq from 'lodash/uniq'
import { useSelector } from 'react-redux'
import { pending } from 'redux-saga-thunk'
import { SHARE_TYPES } from '../../components/molecules/ProjectShareModal/ProjectShare.constants'
import useProjectsHook from './useProjectsHook'
import fromComments from '../comments/comments.selectors'
import fromProjects from '../projects/projects.selectors'
import { UPDATE_SESSIONS } from '../sessions/sessions.actions'
import fromOrganizations from '../organizations/organizations.selectors'
import useDeepEffect from '../../components/hooks/useDeepEffect'

const isFetchingSessionsPending = state => pending(state, UPDATE_SESSIONS)

const useSessionsHook = () => {
  const { projectSharedWith } = useProjectsHook()
  const currentProjectId = useSelector(fromProjects.getCurrentProjectId)
  const allProjects = useSelector(fromProjects.getProjects)
  const currentOrganizationMembers = useSelector(
    fromOrganizations.getCurrentOrganizationMembers
  )

  const enrichedSessions = useSelector(
    fromComments.getEnrichedSessionsByCurrentProject
  )

  const computedSessions = useMemo(
    () =>
      enrichedSessions.map(session => {
        const { admin, edit, view, projectId } = session
        const project = allProjects.find(p => p.pk === projectId)
        const _admin = admin || []
        const _edit = edit || []
        const _view = view || []
        const sharedWith = uniq(projectSharedWith.concat(_admin, _edit, _view))
        const isPrivate = sharedWith.length === 0
        const isShared = sharedWith.length > 0

        const isSharedWithTheWorkspace =
          project?.shareType === SHARE_TYPES.anyone

        const sharedWithCounter = isSharedWithTheWorkspace
          ? currentOrganizationMembers.length
          : sharedWith.length

        return {
          ...session,
          isPrivate,
          isShared,
          isPublic: !!session?.sharing?.isPublic,
          sharedWithCounter,
        }
      }),

    [
      enrichedSessions,
      projectSharedWith,
      currentOrganizationMembers,
      allProjects,
    ]
  )

  const [sessions, setSessions] = useState([])
  useDeepEffect(() => {
    setSessions(computedSessions)
  }, [computedSessions])

  const isFetchingSessions = useSelector(isFetchingSessionsPending)

  return {
    sessions,
    isFetchingSessions,
    currentProjectId,
  }
}

export default useSessionsHook
