import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Dropdown,
  Autocomplete,
  Menu,
  Item,
  Field,
  Label,
} from '@zendeskgarden/react-dropdowns'
import {
  Field as FormField,
  Label as FormLabel,
  Input,
  Textarea,
} from '@zendeskgarden/react-forms'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import debounce from 'lodash/debounce'
import { Button } from '@zendeskgarden/react-buttons'
import { Alert } from '@zendeskgarden/react-notifications'
import prop from 'lodash/fp/prop'
import first from 'lodash/first'
import { StyledErrorMessage } from '../../commonStyledComponents'
import Flex from '../../atoms/Flex'
import JiraLogoSvg from '../../../assets/mark-gradient-blue-jira-software.svg'
import Loader from '../../atoms/Loader'
import useDeepEffect from '../../hooks/useDeepEffect'

const StyledHeader = styled(Header)`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  padding: 12px 24px;
`

const StyledBody = styled(Body)`
  padding: 0 40px 0 40px;
  align-items: center;
  justify-content: center;
  height: auto;
`

const Row = styled(Flex)`
  margin-bottom: 24px;
`

const JiraLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`

const StyledForm = styled(Form)`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`

const SubmitButton = styled(Button)`
  order: 0;
  align-self: flex-end;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledItem = styled(Item)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

const ItemImage = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 8px;
  align-self: center;
`

const TextAreaSubtitle = styled(Flex)`
  font-style: italic;
  color: ${prop('theme.colors.grey400')};
  font-size: 12px;
  margin-top: 2px;
  padding-left: 1px;
`

const StyledLoader = styled(Loader).attrs(props => ({
  color: prop('theme.colors.grey600')(props),
}))``

const StyledWarningAlert = styled(Alert)`
  margin-top: 0;
  color: ${prop('theme.colors.yellow800')};
  background-color: ${prop('theme.colors.yellow100')};
  border-color: ${prop('theme.colors.yellow300')};
  text-align: left;
`

const useDropdown = (items, key, searchKey, initialKey) => {
  const [selectedItemKey, setSelectedItemKey] = useState(
    initialKey || items?.[0]?.[key]
  )

  const initialValue = first(items.filter(item => item[key] === initialKey))
  const [selectedItem, setSelectedItem] = useState(initialValue || items?.[0])
  const [value, setValue] = useState('')
  const [matchingItems, setMatchingItems] = useState(items)

  const filterMatchingRef = _items =>
    debounce(_value => {
      if (!_value?.trim) {
        setMatchingItems(_items)
        return
      }
      const matchedProjects = _items.filter(
        item =>
          item[searchKey]
            .trim()
            .toLowerCase()
            .indexOf(_value.trim().toLowerCase()) !== -1
      )

      setMatchingItems(matchedProjects)
    }, 300)

  useDeepEffect(() => {
    filterMatchingRef(items)(value)
  }, [value, items])

  useEffect(() => {
    const obj = items.find(item => item[key] === selectedItemKey)
    if (obj) {
      setSelectedItem(obj)
    }
  }, [selectedItemKey, items])

  return {
    selectedItemKey,
    setSelectedItemKey,
    selectedItem,
    value,
    setValue,
    matchingItems,
    filterMatchingRef,
  }
}

const validateFormValues = mandatoryFields => values => {
  const errors = {}
  if (!values.title) {
    errors.title = 'The title is required'
  } else if (!values.description) {
    errors.description = 'The description is required'
  }

  mandatoryFields.forEach(field => {
    if (!values[field.key]) {
      errors[field.key] = `${field.name} is required`
    }
  })
  return errors
}

const filterIssueTypesBasedOnProject = selectedProject => issueType => {
  if (selectedProject.isNextGen && issueType.projectId === selectedProject.id) {
    return true
  }
  if (selectedProject.isNextGen && issueType.projectId !== selectedProject.id) {
    return false
  }
  if (!selectedProject.isNextGen && issueType.projectId) {
    return false
  }
  return true
}

const SUPPORTED_CUSTOM_FIELD_TYPES = [
  'string',
  'float',
  'textarea',
  'textfield',
  'url',
]

const CreateJiraIssueModal = ({
  projects,
  issueTypes,
  requiredFields,
  initialDescription,
  initialJiraProjectId,
  resourceId,
  resourceUrl,
  sessionId,
  isVisible,
  onClose,
  onCreate,
  onProjectChange,
}) => {
  const [isModalVisible, setModalVisible] = useState(isVisible)
  const [isFormLoading, setFormLoading] = useState(false)

  useEffect(() => {
    setModalVisible(isVisible)
  }, [isVisible])

  const onModalClose = () => {
    setModalVisible(false)
    onClose()
  }

  const {
    selectedItemKey: selectedProjectKey,
    setSelectedItemKey: setSelectedProjectKey,
    selectedItem: selectedProject,
    value: projectValue,
    setValue: setProjectValue,
    matchingItems: matchingProjects,
  } = useDropdown(projects, 'id', 'name', initialJiraProjectId)

  const {
    selectedItemKey: selectedIssueTypeKey,
    setSelectedItemKey: setSelectedIssueTypeKey,
    selectedItem: selectedIssueType,
    value: issueTypeValue,
    setValue: setIssueTypeValue,
    matchingItems: matchingIssueTypes,
  } = useDropdown(issueTypes, 'id', 'name')

  useEffect(() => {
    const filteredIssueTypes = issueTypes.filter(
      filterIssueTypesBasedOnProject(selectedProject)
    )
    setSelectedIssueTypeKey(filteredIssueTypes[0].id)
    onProjectChange(selectedProject)
  }, [selectedProject?.id])

  const [mandatoryFields, setMandatoryFields] = useState([])
  const [
    arePresentUnsupportedCustomFields,
    setUnsupportedCustomFields,
  ] = useState(false)
  useEffect(() => {
    const _mandatoryFields = requiredFields.filter(
      field =>
        field.projectId === selectedProject?.id &&
        field.issueTypeId === selectedIssueType?.id
    )
    setMandatoryFields(_mandatoryFields)
    setUnsupportedCustomFields(
      !!_mandatoryFields.find(
        field => !SUPPORTED_CUSTOM_FIELD_TYPES.includes(field.type)
      )
    )
  }, [selectedProject?.id, selectedIssueType?.id])

  const formInitialValues = {
    title: '',
    description: initialDescription,
  }

  return (
    <div>
      {isModalVisible && (
        <Modal isAnimated={false}>
          <StyledHeader>
            <JiraLogo src={JiraLogoSvg} alt="jira" />
            Create Jira issue
          </StyledHeader>
          <StyledBody>
            <Flex style={{ marginBottom: '24px' }}>
              <Formik
                initialValues={formInitialValues}
                validate={validateFormValues(mandatoryFields)}
                onSubmit={values => {
                  setFormLoading(true)

                  const customFields = []
                  if (mandatoryFields.length) {
                    mandatoryFields.forEach(field => {
                      customFields.push({
                        key: field.key,
                        value: values[field.key],
                      })
                    })
                  }

                  const issue = {
                    summary: values.title,
                    description: values.description,
                    projectId: selectedProject.id,
                    issueTypeId: selectedIssueType.id,
                    resourceId,
                    resourceUrl,
                    sessionId,
                    customFields,
                  }

                  onCreate(issue)
                    .then(() => {
                      onClose()
                    })
                    .catch(() => {
                      setFormLoading(false)
                    })
                }}
                render={({ values, errors, handleBlur, handleChange }) => (
                  <StyledForm>
                    {arePresentUnsupportedCustomFields && (
                      <Row>
                        <StyledWarningAlert type="warning">
                          This Issue Type includes custom fields that are not
                          supported yet. Please create the issue on Jira.
                        </StyledWarningAlert>
                      </Row>
                    )}

                    <Row>
                      <Dropdown
                        inputValue={projectValue}
                        selectedItem={selectedProjectKey}
                        onStateChange={handleChange}
                        onSelect={item => setSelectedProjectKey(item)}
                        onInputValueChange={value => setProjectValue(value)}
                        downshiftProps={{ defaultHighlightedIndex: 0 }}
                        validation={errors.project ? 'warning' : undefined}
                      >
                        <Field style={{ width: '100%' }}>
                          <Label>Jira Project</Label>
                          <Autocomplete
                            start={
                              <ItemImage
                                src={
                                  selectedProject?.avatarBase64
                                    ? `data:${selectedProject?.avatarContentType};base64,${selectedProject.avatarBase64}`
                                    : selectedProject?.avatarUrl
                                }
                                alt="icon"
                              />
                            }
                          >
                            <span>
                              {selectedProject.name} ({selectedProject.key})
                            </span>
                          </Autocomplete>
                        </Field>
                        <Menu>
                          {matchingProjects.length ? (
                            matchingProjects.map(option => (
                              <StyledItem key={option.id} value={option.id}>
                                <ItemImage
                                  src={
                                    option?.avatarBase64
                                      ? `data:${option?.avatarContentType};base64,${option.avatarBase64}`
                                      : option?.avatarUrl
                                  }
                                  alt="icon"
                                />
                                <span>{option.key}</span>
                              </StyledItem>
                            ))
                          ) : (
                            <Item disabled>No matches found</Item>
                          )}
                        </Menu>
                      </Dropdown>
                      <ErrorMessage
                        component={StyledErrorMessage}
                        name="project"
                      />
                    </Row>

                    <Row>
                      <FormField>
                        <FormLabel>Title</FormLabel>
                        <Input
                          type="text"
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          validation={errors.title ? 'warning' : undefined}
                        />
                        <ErrorMessage
                          component={StyledErrorMessage}
                          name="title"
                        />
                      </FormField>
                    </Row>

                    <Row>
                      <FormField>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={initialDescription}
                          value={values.description}
                          minRows={6}
                          validation={
                            errors.description ? 'warning' : undefined
                          }
                          isResizable
                        />
                        <TextAreaSubtitle>
                          Recording link will be attached automatically
                        </TextAreaSubtitle>
                      </FormField>
                    </Row>

                    <Row>
                      <Dropdown
                        inputValue={issueTypeValue}
                        selectedItem={selectedIssueTypeKey}
                        onStateChange={handleChange}
                        onSelect={item => setSelectedIssueTypeKey(item)}
                        onInputValueChange={value => setIssueTypeValue(value)}
                        downshiftProps={{ defaultHighlightedIndex: 0 }}
                        validation={errors.issueType ? 'warning' : undefined}
                      >
                        <Field style={{ width: '100%' }}>
                          <Label>Issue Type</Label>
                          <Autocomplete
                            start={
                              <ItemImage
                                src={selectedIssueType?.iconUrl}
                                alt="icon"
                              />
                            }
                          >
                            <span>{selectedIssueType.name}</span>
                          </Autocomplete>
                        </Field>
                        <Menu>
                          {matchingIssueTypes.length ? (
                            matchingIssueTypes
                              .filter(
                                filterIssueTypesBasedOnProject(selectedProject)
                              )
                              .map(option => (
                                <StyledItem key={option.id} value={option.id}>
                                  <ItemImage src={option.iconUrl} alt="icon" />
                                  <span>{option.name}</span>
                                </StyledItem>
                              ))
                          ) : (
                            <Item disabled>No matches found</Item>
                          )}
                        </Menu>
                      </Dropdown>
                      <ErrorMessage
                        component={StyledErrorMessage}
                        name="issueType"
                      />
                    </Row>

                    {mandatoryFields.map(field => {
                      // https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-fields/#api-rest-api-3-field-post
                      if (SUPPORTED_CUSTOM_FIELD_TYPES.includes(field.type)) {
                        return (
                          <Row key={field.key}>
                            <FormField>
                              <FormLabel>{field.name}</FormLabel>
                              <Input
                                type="text"
                                name={field.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[field.key]}
                                validation={
                                  errors[field.key] ? 'warning' : undefined
                                }
                              />
                              <ErrorMessage
                                component={StyledErrorMessage}
                                name={field.key}
                              />
                            </FormField>
                          </Row>
                        )
                      }
                      return null
                    })}

                    <Row style={{ marginBottom: '0' }}>
                      <SubmitButton
                        isPrimary
                        type="submit"
                        disabled={
                          isFormLoading || arePresentUnsupportedCustomFields
                        }
                      >
                        {isFormLoading && <StyledLoader size="16px" />}
                        {!isFormLoading && <>Create issue</>}
                      </SubmitButton>
                    </Row>
                  </StyledForm>
                )}
              />
            </Flex>
          </StyledBody>
          <Close aria-label="Close modal" onClick={onModalClose} />
        </Modal>
      )}
    </div>
  )
}

CreateJiraIssueModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onProjectChange: PropTypes.func,
  initialDescription: PropTypes.string,
  initialJiraProjectId: PropTypes.string,
  resourceId: PropTypes.string,
  resourceUrl: PropTypes.string,
  sessionId: PropTypes.string,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
      avatarUrl: PropTypes.string,
    })
  ),
  issueTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      iconUrl: PropTypes.string,
    })
  ),
  requiredFields: PropTypes.arrayOf(
    PropTypes.shape({
      isRequired: PropTypes.bool,
      projectId: PropTypes.string,
      issueTypeId: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      key: PropTypes.string,
    })
  ),
}

CreateJiraIssueModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onCreate: () => {},
  onProjectChange: () => {},
  projects: [],
  issueTypes: [],
  requiredFields: [],
  initialDescription: '',
  resourceId: '',
  resourceUrl: '',
  sessionId: '',
  initialJiraProjectId: null,
}

export default CreateJiraIssueModal
