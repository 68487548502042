/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types'

export const UserShape = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  picture: PropTypes.string,
})

export const CommentShape = PropTypes.shape({
  text: PropTypes.string,
  createdBy: PropTypes.string,
  createdAt: PropTypes.number,
  owner: UserShape,
})
