import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'
import { easeOutQuad } from '../../easings'

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: white;
  background-color: ${prop('theme.colors.primary')};
  border-radius: 4px;
  left: 8px;
  padding: 16px;
  ${ternary('isVisible')('', 'transform: translateY(-200%)')};
  transition: all 300ms ${easeOutQuad};
  z-index: 100000;
  cursor: pointer;
  user-select: none;
  box-shadow: ${prop('theme.boxShadows.topBarElevation')};
`

const Text = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  text-align: left;
  font-size: 14px;
`
const Cta = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  text-transform: uppercase;
  margin-left: 16px;
  color: ${prop('theme.colors.secondary400')};
  :hover {
    color: ${prop('theme.colors.secondary300')};
  }
`

const Snackbar = ({ text, onClick, callToAction, isVisible, ...props }) => (
  <Wrapper isVisible={isVisible} onClick={onClick} {...props}>
    <Text>{text}</Text>
    {callToAction && (
      <Cta
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          callToAction.onClick()
        }}
      >
        {callToAction.text}
      </Cta>
    )}
  </Wrapper>
)

Snackbar.propTypes = {
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  callToAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  onClick: PropTypes.func,
}

Snackbar.defaultProps = {
  isVisible: false,
  onClick: () => {},
  callToAction: null,
}

export default Snackbar
