import React, { useEffect, useCallback, useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import first from 'lodash/first'
import { XL } from '@zendeskgarden/react-typography'
import { Label } from '@zendeskgarden/react-forms'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import Logo from '../../atoms/Logo'
import Card from '../../atoms/Card'
import ProgressBar from '../../atoms/ProgressBar'
import OrganizationsSelect from '../OrganizationsSelect'
import Loader from '../../atoms/Loader'
import ProjectCheckbox from './ProjectCheckbox'
import { PERSONAL_ORGANIZATION_ID } from '../../../store/organizations/organizations.selectors'
import { ternary } from '../../../util/utils'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  text-align: center;
  margin: auto;
  display: flex;
`

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 24px;
  align-self: center;

  && {
    border-radius: 6px;
    overflow: hidden;
  }
`

export const StyledErrorMessage = styled.div`
  font-style: italic;
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
`

const LogoContainer = styled(Flex)`
  width: 36px;
  height: 36px;
  align-self: center;
`

const StyledLogo = styled(Logo)`
  width: 36px;
  height: 36px;
  margin-top: 16px;
  align-self: center;
  display: flex;
`

const StyledCard = styled(Card)`
  padding: 16px;
  margin-bottom: 8px;
  text-align: left;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
`

const Container = styled(Flex)`
  max-width: 320px;
  align-self: center;
  justify-self: center;
  margin: auto;
`

const Title = styled(XL)`
  font-weight: 600;
  margin: 24px 0;
  align-self: center;
  text-align: center;
`

const DropdownLabel = styled(Label)`
  margin-bottom: 4px;
`

const ProjectsBox = styled(Flex)`
  margin: 8px 0;
`

const StyledButton = styled(Button)`
  margin: 4px 0;
`

const LoaderBox = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  visibility: ${ternary('isVisible')('visible', 'hidden')};
`

const SessionDestinationSelector = ({
  projects,
  onChangeOrganization,
  onDelete,
  onSave,
  initialProjectId,
  currentOrganizationId,
  isLoading,
  hasOrganizations,
  ...props
}) => {
  const [organizationId, setOrganizationId] = useState(PERSONAL_ORGANIZATION_ID)
  const [projectId, setProjectId] = useState(initialProjectId)
  const [projectTitle, setProjectTitle] = useState('')
  const [showError, setShowError] = useState(false)
  const [showNewProjectTitleError, setShowNewProjectTitleError] = useState(
    false
  )

  const [isSaving, setSaving] = useState(false)

  useEffect(() => {
    const projectdIds = (projects || []).map(project => project.pk)
    if (projectdIds.includes(initialProjectId)) {
      setProjectId(initialProjectId)
      return
    }
    const firstProjectId = get(first(projects), 'pk')
    setProjectId(firstProjectId)
  }, [currentOrganizationId])

  const onSaveHandler = useCallback(() => {
    if (!projectId) {
      setShowError(true)
      return
    }
    if (projectId === 'new-project' && !projectTitle) {
      setShowNewProjectTitleError(true)
      return
    }
    if (organizationId && projectId === 'new-project') {
      setSaving(true)
      onSave({ organizationId, projectId, projectTitle })
      return
    }
    if (organizationId && projectId) {
      setSaving(true)
      onSave({ organizationId, projectId })
    }
  }, [organizationId, projectId, projectTitle])

  return (
    <Wrapper {...props}>
      <Container>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        <Title>Recording completed!</Title>
        <StyledProgressBar width={50} />
        <StyledCard>
          {!hasOrganizations && (
            <DropdownLabel>Select the destination project</DropdownLabel>
          )}
          {hasOrganizations && (
            <>
              <DropdownLabel>Select a destination</DropdownLabel>
              <OrganizationsSelect
                showOrganizationButtonSharingVisible={false}
                showNewOrganizationButton={false}
                shouldNavigateToWorkspace={false}
                checkLibraryWorkspaceAdmin
                onChangeOrganizationInSession={_organizationId => {
                  onChangeOrganization(_organizationId)
                  setOrganizationId(_organizationId)
                }}
              />
            </>
          )}
          <ProjectsBox>
            <LoaderBox isVisible={isLoading}>
              <Loader size="24px" />
            </LoaderBox>
            {projects.map(project => (
              <ProjectCheckbox
                key={project.pk}
                projectId={project.pk}
                projectTitle={project.title}
                isChecked={projectId === project.pk}
                onClick={_projectId => {
                  setProjectId(_projectId)
                  setShowError(false)
                  setShowNewProjectTitleError(false)
                }}
              />
            ))}
            <ProjectCheckbox
              isInput
              isChecked={projectId === 'new-project'}
              onClick={() => {
                setProjectId('new-project')
                setShowError(false)
                setShowNewProjectTitleError(false)
              }}
              onInput={value => {
                setProjectTitle(value)
              }}
            />
          </ProjectsBox>
          {showError && (
            <StyledErrorMessage>Select a project</StyledErrorMessage>
          )}
          {showNewProjectTitleError && (
            <StyledErrorMessage>Type the new project title</StyledErrorMessage>
          )}
          <StyledButton
            isPrimary
            disabled={isSaving}
            onClick={() => onSaveHandler()}
          >
            {isSaving ? <Loader size="16px" /> : 'Save'}
          </StyledButton>
        </StyledCard>
        <StyledButton isBasic onClick={onDelete}>
          Delete
        </StyledButton>
      </Container>
    </Wrapper>
  )
}

SessionDestinationSelector.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onChangeOrganization: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onProjectSelected: PropTypes.func,
  initialProjectId: PropTypes.string,
  currentOrganizationId: PropTypes.string,
  isLoading: PropTypes.bool,
  hasOrganizations: PropTypes.bool,
}

SessionDestinationSelector.defaultProps = {
  onChangeOrganization: () => {},
  onDelete: () => {},
  onSave: () => {},
  onProjectSelected: () => {},
  initialProjectId: null,
  currentOrganizationId: null,
  isLoading: false,
  hasOrganizations: false,
}

export default SessionDestinationSelector
