import React from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Link as LinkBase } from 'react-router-dom'

const StyledLinkBase = styled(LinkBase)`
  && {
    color: ${prop('theme.colors.secondary')};
  }

  &&:active {
    color: ${prop('theme.colors.secondary')};
  }

  &&:focus {
    color: ${prop('theme.colors.secondary800')};
  }

  &&:hover {
    color: ${prop('theme.colors.secondary800')};
  }
`

const Link = ({ ...props }) => <StyledLinkBase {...props} />

export default Link
