const NAMESPACE = 'DESKTOP'

export const DESKTOP_INIT = `${NAMESPACE}/INIT`
export const initDesktop = () => ({
  type: DESKTOP_INIT,
  payload: {},
})

export const DESKTOP_UPDATE_DEVICES = `${NAMESPACE}/UPDATE_DEVICES`
export const updateDevices = devices => ({
  type: DESKTOP_UPDATE_DEVICES,
  payload: { devices },
})

export const DESKTOP_SET_CURRENT_DEVICE = `${NAMESPACE}/SET_CURRENT_DEVICE`
export const setCurrentDevice = deviceId => ({
  type: DESKTOP_SET_CURRENT_DEVICE,
  payload: { currentDevice: deviceId },
})

export const DESKTOP_START_RECORDING_REQUEST = `${NAMESPACE}/START_RECORDING_REQUEST`
export const DESKTOP_START_RECORDING_REQUEST_SUCCESS = `${NAMESPACE}/START_RECORDING_REQUEST_SUCCESS`
export const DESKTOP_START_RECORDING_REQUEST_FAILURE = `${NAMESPACE}/START_RECORDING_REQUEST_FAILURE`

export const startRecordingRequest = () => ({
  type: DESKTOP_START_RECORDING_REQUEST,
  payload: {},
  meta: { thunk: DESKTOP_START_RECORDING_REQUEST },
})

export const startRecordingRequestSuccess = (payload, thunk) => ({
  type: DESKTOP_START_RECORDING_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const startRecordingRequestFailure = (e, thunk) => ({
  type: DESKTOP_START_RECORDING_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DESKTOP_STOP_RECORDING = `${NAMESPACE}/STOP_RECORDING`
export const stopRecording = () => ({
  type: DESKTOP_STOP_RECORDING,
  payload: {},
})

export const DESKTOP_PULL_RECORDING_REQUEST = `${NAMESPACE}/PULL_RECORDING_REQUEST`
export const DESKTOP_PULL_RECORDING_REQUEST_SUCCESS = `${NAMESPACE}/PULL_RECORDING_REQUEST_SUCCESS`
export const DESKTOP_PULL_RECORDING_REQUEST_FAILURE = `${NAMESPACE}/PULL_RECORDING_REQUEST_FAILURE`

export const pullRecordingRequest = payload => ({
  type: DESKTOP_PULL_RECORDING_REQUEST,
  payload,
  meta: { thunk: DESKTOP_PULL_RECORDING_REQUEST },
})

export const pullRecordingRequestSuccess = (videoInfo, thunk) => ({
  type: DESKTOP_PULL_RECORDING_REQUEST_SUCCESS,
  payload: { videoInfo },
  meta: { thunk },
})

export const pullRecordingRequestFailure = (e, thunk) => ({
  type: DESKTOP_PULL_RECORDING_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
