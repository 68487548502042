import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import DeleteClockIcon from 'mdi-react/DeleteClockIcon'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  flex-direction: row;
  height: 32px;
`

const LeftBox = styled(Flex)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid ${prop('theme.colors.yellow600')};
  border-right: 0;
  background-color: ${prop('theme.colors.yellow200')};
  color: ${prop('theme.colors.primary')};
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 12px;
  flex-direction: row;
`

const StyledButton = styled(Button)`
  font-weight: 600;
  height: 32px;

  && {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const SecondaryButtonText = styled.span`
  color: ${prop('theme.colors.grey300')};
  font-weight: 400;
`

const StyledDeleteClockIcon = styled(DeleteClockIcon)`
  width: 16px;
  height: 30px;
  display: flex;
  line-height: 30px;
  margin-right: 4px;
  && {
    fill: ${prop('theme.colors.yellow600')};
  }
`

const TemporaryRecordingCallToAction = ({ onClick, ...props }) => (
  <Wrapper {...props}>
    <LeftBox>
      <Flex>
        <StyledDeleteClockIcon size={16} />
      </Flex>
      <Flex>This recording will expire soon</Flex>
    </LeftBox>
    <StyledButton size="small" isPrimary isStretched={false} onClick={onClick}>
      Sign in to save{' '}
      <SecondaryButtonText>— It&apos;s free</SecondaryButtonText>
    </StyledButton>
  </Wrapper>
)

TemporaryRecordingCallToAction.propTypes = {
  onClick: PropTypes.func,
}

TemporaryRecordingCallToAction.defaultProps = {
  onClick: () => {},
}

export default TemporaryRecordingCallToAction
