import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, rejected, fulfilled, done } from 'redux-saga-thunk'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import { logoutRequest } from '../../../store/actions'
import { setUserId } from '../../../store/user/user.actions'
import fromUser from '../../../store/user/user.selectors'
import fromSessions from '../../../store/sessions/sessions.selectors'
import fromProjects from '../../../store/projects/projects.selectors'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import {
  setCurrentProject,
  UPDATE_PROJECTS_REQUEST,
} from '../../../store/projects/projects.actions'
import { initDesktop } from '../../../desktop/store/actions'
import { setCurrentOrganization } from '../../../store/organizations/organizations.actions'
import {
  getOrganizationPathName,
  getOrganizationSettingsPathName,
} from '../../../util/paths'

const isPending = state => pending(state, UPDATE_PROJECTS_REQUEST)
const isFulfilled = state => fulfilled(state, UPDATE_PROJECTS_REQUEST)
const isDone = state => done(state, UPDATE_PROJECTS_REQUEST)
const isRejected = state => rejected(state, UPDATE_PROJECTS_REQUEST)

const useMainAppPage = function useMainAppPage(initialView) {
  const history = useHistory()
  const isUserLogged = useSelector(fromUser.isLogged)
  const userId = useSelector(fromUser.getId)
  const userName = useSelector(fromUser.getName)
  const userEmail = useSelector(fromUser.getEmail)
  const userPicture = useSelector(fromUser.getPicture)
  const userIdentityId = useSelector(fromUser.getIdentityId)
  const projects = useSelector(fromSessions.getProjectsMergedSessions)
  const projectsPending = useSelector(isPending)
  const projectsRejected = useSelector(isRejected)
  const projectsFullfilled = useSelector(isFulfilled)
  const projectsDone = useSelector(isDone)
  const currentProject = useSelector(fromProjects.getCurrentProject)
  const currentOrganizationProjects = useSelector(fromProjects.getProjects)
  const currentOrganization = useSelector(
    fromOrganizations.getCurrentOrganization
  )
  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )
  const organizations = useSelector(fromOrganizations.getOrganizations)

  const dispatch = useDispatch()
  const onLogoutHandler = useCallback(() => dispatch(logoutRequest()), [
    dispatch,
  ])
  const onSetUserId = useCallback(_userId => dispatch(setUserId(_userId)), [
    dispatch,
  ])
  const onInitDesktop = useCallback(() => dispatch(initDesktop()), [dispatch])

  const goToOrganizationSettings = useCallback(() => {
    history.push(getOrganizationSettingsPathName(currentOrganizationId))
  }, [history, currentOrganizationId])

  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)

  const onSetCurrentOrganization = useCallback(
    organizationId => dispatch(setCurrentOrganization(organizationId)),
    [dispatch]
  )

  const onGoToOrganization = useCallback(
    organizationId => history.push(getOrganizationPathName(organizationId)),
    [dispatch]
  )

  const onSetCurrentProject = useCallback(
    projectId => dispatch(setCurrentProject(projectId)),
    [dispatch]
  )

  const [_currentOrganizationId, _setCurrentOrganizationId] = useState(
    currentOrganization
  )
  useEffect(() => {
    if (
      currentProject &&
      currentOrganizationId &&
      currentOrganizationId !== _currentOrganizationId
    ) {
      _setCurrentOrganizationId(currentOrganizationId)
      if (currentOrganization.pk !== currentProject.organizationId) {
        onSetCurrentProject(get(currentOrganizationProjects, '[0].pk'))
      }
    }
  }, [currentOrganizationId])

  const [currentView, setCurrentView] = useState(initialView)

  return {
    currentView,
    setCurrentView,
    isUserLogged,
    userId,
    userName,
    userEmail,
    userPicture,
    userIdentityId,
    projects,
    projectsPending,
    projectsRejected,
    projectsFullfilled,
    projectsDone,
    currentProject,
    currentOrganization,
    organizations,
    onLogoutHandler,
    onSetUserId,
    onInitDesktop,
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
    onSetCurrentOrganization,
    onGoToOrganization,
    goToOrganizationSettings,
  }
}

export default useMainAppPage
