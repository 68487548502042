const NAMESPACE = 'PENDING_UPLOADS'

export const CREATE_PENDING_UPLOADS = `${NAMESPACE}/CREATE`
export const createPendingUploads = (sessionId, obj) => ({
  type: CREATE_PENDING_UPLOADS,
  payload: { sessionId, obj },
})

export const DELETE_PENDING_UPLOADS = `${NAMESPACE}/DELETE`
export const deletePendingUploads = sessionId => ({
  type: DELETE_PENDING_UPLOADS,
  payload: { sessionId },
})

export const SET_PENDING_UPLOADS = `${NAMESPACE}/SET`
export const setPendingUploads = (sessionId, obj) => ({
  type: SET_PENDING_UPLOADS,
  payload: { sessionId, obj },
})

export const RESET_PENDING_UPLOADS = `${NAMESPACE}/RESET`
export const resetPendingUploads = () => ({
  type: RESET_PENDING_UPLOADS,
  payload: {},
})

export const GET_PENDING_UPLOADS = `${NAMESPACE}/GET`
export const getPendingUploads = () => ({
  type: GET_PENDING_UPLOADS,
  payload: {},
})
