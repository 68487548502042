import { useDispatch, useSelector } from 'react-redux'
import fromIntegrationsJira from '../../store/integrations/jira/integrations.jira.selectors'
import { createJiraRequest } from '../../store/integrations/jira/integrations.jira.actions'

const useJira = () => {
  const dispatch = useDispatch()
  const hasJira = useSelector(fromIntegrationsJira.getHasJira) || false

  const onCreateJiraIssue = values => dispatch(createJiraRequest(values))

  return {
    hasJira,
    onCreateJiraIssue,
  }
}

export default useJira
