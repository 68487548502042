import React, { useState, useLayoutEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { MAIN_VIEWS } from '../../pages/MainAppPage/MainAppPage.constants'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import NotFoundMobile from '../NotFoundMobile'
import NotAuthorized from '../NotAuthorized'
import MainAppPage from '../../pages/MainAppPage'
import AnonymousMainAppPage from '../../pages/AnonymousMainAppPage'
import EntryViewMobile from '../EntryView/EntryViewMobile'
import { setCurrentOrganization } from '../../../store/organizations/organizations.actions'
import { trackProjectView } from '../../../store/analytics/analytics.actions'
import {
  setCurrentProject,
  getProjectRequest,
} from '../../../store/projects/projects.actions'
import fromProjects from '../../../store/projects/projects.selectors'
import { isMobile } from '../../../lib/platform'
import { getPrefixedProjectId } from '../../../util/ids'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { useGoogleLogin } from '@react-oauth/google'
import {
  isGoogleLogin,
  isGoogleSignedIn,
  googleSignIn,
} from '../../../util/auth'

const ProjectProxy = ({ match }) => {
  const history = useHistory()
  const { isLogged } = useUserInfo()

  const projectId = get(match, 'params.projectId')
  const [isLoading, setLoading] = useState(false)
  const [isProjectFound, setProjectFound] = useState(false)
  const [isUserAuthorized, setUserAuthorized] = useState(false)
  const [project, setProject] = useState(null)
  const dispatch = useDispatch()

  const storeProject = useSelector(
    fromProjects.getProjectById(getPrefixedProjectId(projectId))
  )
  const storeProjectId = storeProject?.pk

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse =>
      googleSignIn(
        codeResponse,
        () => {
          fetchProject()
        },
        () => {
          window.location.href = `/login?redirect=${projectId}`
        }
      ),
    flow: 'auth-code',
  })

  const fetchProject = () =>
    dispatch(getProjectRequest(projectId))
      .then(({ project: _project }) => {
        setProject(_project)
        dispatch(setCurrentOrganization(_project.organizationId))
        dispatch(setCurrentProject(_project.pk))
        setUserAuthorized(true)
        setProjectFound(true)
      })
      .catch(err => {
        console.error(err)
        const errorMessage = err.toString().toLowerCase()
        if (errorMessage.includes('not found')) {
          setProjectFound(false)
        }
        if (errorMessage.includes('not authorized')) {
          setUserAuthorized(false)
          setProjectFound(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })

  useLayoutEffect(() => {
    if (!isLoading && project && isLogged) {
      dispatch(
        trackProjectView({
          projectId: project?.pk,
          organizationId: project?.organizationId,
        })
      )
    }
  }, [isLoading, project, isLogged])

  useLayoutEffect(() => {
    if (isLogged && !storeProjectId) {
      setLoading(true)

      if (isGoogleLogin()) {
        isGoogleSignedIn().then(isLoggedIn => {
          if (isLoggedIn) {
            fetchProject()
          }

          if (!isLoggedIn) {
            loginWithGoogle()
          }
        })
        return
      }

      fetchProject()
    }

    if (storeProjectId) {
      setProject(storeProject)
      dispatch(setCurrentOrganization(storeProject.organizationId))
      dispatch(setCurrentProject(storeProject.pk))
      setProjectFound(true)
      setUserAuthorized(true)
      setLoading(false)
    }
  }, [isLoading, isLogged, storeProjectId])

  if (!isLogged) {
    history.push('/register')
    return null
  }

  if (!projectId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !isUserAuthorized) {
    return <NotAuthorized />
  }

  if (!isLoading && !isProjectFound) {
    return <NotFoundMobile />
  }

  if (!isLoading && project && !isLogged) {
    return <AnonymousMainAppPage />
  }

  if (!isLoading && project && isLogged) {
    return isMobile() ? (
      <EntryViewMobile isVisible showProject />
    ) : (
      <MainAppPage
        initialView={{
          name: MAIN_VIEWS.entry,
        }}
      />
    )
  }

  return null
}

ProjectProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
}

export default ProjectProxy
