import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../Flex'
import { ternary } from '../../../util/utils'

const blink = keyframes`
  from {
    box-shadow: 0 0 0 0px rgba(244, 183, 53, 0);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(244, 183, 53, 0.5);
  }
  to {
    box-shadow: 0 0 0 0px rgba(244, 183, 53, 0);
  }
`

const smallCss = css`
  width: 34px;
  height: 34px;
  margin-top: -17px;
  margin-left: -17px;
  line-height: 34px;
  font-size: 16px;
`

const largeCss = css`
  width: 46px;
  height: 46px;
  margin-top: -23px;
  margin-left: -23px;
  line-height: 46px;
  font-size: 22px;
`

const Wrapper = styled(Flex)`
  padding: 0;
  text-align: center;

  color: ${prop('theme.colors.yellow900')};
  ${({ isBlurred, theme }) =>
    isBlurred ? `color: ${theme.colors.grey800} !important;` : ''}

  background-color: ${prop('theme.colors.yellow500')};
  ${({ isBlurred, theme }) =>
    isBlurred ? `background-color: ${theme.colors.grey200} !important;` : ''}

  border: 4px solid white;
  border-radius: 100px;
  cursor: ${ternary('isBlurred')('pointer', 'default')};
  opacity: 1;
  transition: all 0.3s;

${({ isBlurred }) =>
  isBlurred
    ? ''
    : css`
        animation: ${blink} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      `}
  

  &&:hover {
    background: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    animation: none;
  ${({ isBlurred }) =>
    isBlurred ? '' : 'box-shadow: 0 0 0 8px rgba(244, 183, 53, 0.5);'}
  }

  ${({ size }) => {
    if (size === 'small') {
      return smallCss
    }
    if (size === 'large') {
      return largeCss
    }
    return ''
  }}
`

const VideoMarker = ({ children, size, isBlurred, ...props }) => (
  <Wrapper size={size} isBlurred={isBlurred} {...props}>
    {children}
  </Wrapper>
)

VideoMarker.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'large']),
  isBlurred: PropTypes.bool,
}

VideoMarker.defaultProps = {
  children: null,
  size: 'small',
  isBlurred: false,
}

export default VideoMarker
