import { get as getImmutable } from 'dot-prop-immutable'
import getFp from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import filterFp from 'lodash/fp/filter'
import { createSelector } from 'reselect'
import { STATE_KEY } from './notifications.reducers'

const getState = state => getImmutable(state, STATE_KEY.replace('.', '\\.'))

const getAllNotifications = createSelector(
  getState,
  getFp('notifications')
)

const getAllUnreadNotifications = createSelector(
  getState,
  pipe(
    getFp('notifications'),
    filterFp(notification => !!notification?.unread)
  )
)

const getUnreadNotificationsCount = createSelector(
  getState,
  getFp('unreadCount')
)

const getNotificationsByIds = notificationIds =>
  createSelector(
    getState,
    state => {
      return state.notifications.filter(notification =>
        notificationIds.includes(notification.notificationId)
      )
    }
  )

export default {
  getAllNotifications,
  getAllUnreadNotifications,
  getUnreadNotificationsCount,
  getNotificationsByIds,
}
