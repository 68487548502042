import firebase from 'firebase/app'
import moment from 'moment'
import 'firebase/analytics'

const getAccessEventPayload = conf => {
  const payload = {}
  if (conf?.isGoogle !== undefined) {
    payload.method = conf.isGoogle ? 'google' : 'email'
  }
  if (conf?.userIdentityId) {
    payload.user_identity_id = conf.userIdentityId
  }
  return payload
}

const trackSignUp = conf => {
  const eventPayload = getAccessEventPayload(conf)
  firebase.analytics().logEvent('sign_up', eventPayload)
}

const trackLogin = conf => {
  const eventPayload = getAccessEventPayload(conf)
  firebase.analytics().logEvent('login', eventPayload)
}

const trackConnectJira = () => {
  firebase.analytics().logEvent('connect_jira', {})
}

const trackDisconnectJira = () => {
  firebase.analytics().logEvent('disconnect_jira', {})
}

const trackCreateJiraIssue = () => {
  firebase.analytics().logEvent('create_jira_issue', {})
}

const trackConnectTrello = () => {
  firebase.analytics().logEvent('connect_trello', {})
}

const trackDisconnectTrello = () => {
  firebase.analytics().logEvent('disconnect_trello', {})
}

const trackCreateTrelloCard = () => {
  firebase.analytics().logEvent('create_trello_card', {})
}

const trackConnectSlack = () => {
  firebase.analytics().logEvent('connect_slack', {})
}

const trackDisconnectSlack = () => {
  firebase.analytics().logEvent('disconnect_slack', {})
}

const trackPostSlackMessage = () => {
  firebase.analytics().logEvent('post_slack_message', {})
}

const EventsCache = {
  events: {},
  isEventValid(serializedEvent) {
    if (!this.events[serializedEvent]) {
      this.events[serializedEvent] = moment()
      return true
    }

    const now = moment()
    if (now.diff(this.events[serializedEvent], 'seconds') < 10) {
      return false
    }

    this.events[serializedEvent] = moment()
    return true
  },
}

const trackSessionView = trackingEvent => {
  const eventName = 'session_view'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const {
      sessionId,
      projectId,
      organizationId,
      userIdentityId,
    } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      session_id: sessionId,
      project_id: projectId,
      organization_id: organizationId,
      user_identity_id: userIdentityId,
    })
  }
}

const trackProjectView = trackingEvent => {
  const eventName = 'project_view'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { projectId, organizationId, userIdentityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      project_id: projectId,
      organization_id: organizationId,
      user_identity_id: userIdentityId,
    })
  }
}

const trackProjectCreate = trackingEvent => {
  const eventName = 'project_create'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { organizationId, userIdentityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      organization_id: organizationId,
      user_identity_id: userIdentityId,
    })
  }
}

export const SESSION_CREATE_ORIGIN = {
  screenRecording: 'screen_recording',
  uploadRecording: 'upload_recording',
}

const trackSessionCreate = trackingEvent => {
  const eventName = 'session_create'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { organizationId, projectId, origin, userIdentityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      organization_id: organizationId,
      project_id: projectId,
      user_identity_id: userIdentityId,
      origin,
    })
  }
}

const trackCommentCreate = trackingEvent => {
  const eventName = 'comment_create'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const {
      organizationId,
      projectId,
      sessionId,
      withMentions,
      userIdentityId,
    } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      organization_id: organizationId,
      project_id: projectId,
      session_id: sessionId,
      with_mentions: withMentions,
      user_identity_id: userIdentityId,
    })
  }
}

const trackOnboardingComplete = trackingEvent => {
  const eventName = 'onboarding_complete'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { identityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      user_identity_id: identityId,
    })
  }
}

const trackProductSelectorClick = trackingEvent => {
  const eventName = 'product_click'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { identityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      user_identity_id: identityId,
    })
  }
}

const trackOrganizationInviteUser = trackingEvent => {
  const eventName = 'organization_invite_user'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { organizationId, userIdentityId, userRole } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      organization_id: organizationId,
      user_identity_id: userIdentityId,
      user_role: userRole,
    })
  }
}

const trackOrganizationRemoveUser = trackingEvent => {
  const eventName = 'organization_remove_user'
  const serializedEvent = JSON.stringify({
    eventName,
    ...trackingEvent,
  })
  const isEventValid = EventsCache.isEventValid(serializedEvent)
  if (isEventValid) {
    const { organizationId, userIdentityId } = trackingEvent
    firebase.analytics().logEvent(eventName, {
      organization_id: organizationId,
      user_identity_id: userIdentityId,
    })
  }
}

export default {
  trackCommentCreate,
  trackConnectJira,
  trackConnectSlack,
  trackConnectTrello,
  trackCreateJiraIssue,
  trackCreateTrelloCard,
  trackDisconnectJira,
  trackDisconnectSlack,
  trackDisconnectTrello,
  trackPostSlackMessage,
  trackLogin,
  trackOnboardingComplete,
  trackOrganizationInviteUser,
  trackOrganizationRemoveUser,
  trackProjectCreate,
  trackProjectView,
  trackSessionCreate,
  trackSessionView,
  trackSignUp,
  trackProductSelectorClick,
}
