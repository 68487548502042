import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MD, XL } from '@zendeskgarden/react-typography'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import EmptyPersonalSVG from '../../../assets/empty_personal.svg'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const SVGFlex = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 240px;
  height: 160px;
`

const StyledXL = styled(XL)`
  color: ${prop('theme.colors.grey900')};
  margin-top: 24px;
  text-align: center;
`

const StyledMD = styled(MD)`
  color: ${prop('theme.colors.grey600')};
  margin-top: 8px;
`

const ButtonFlex = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

const OrganizationEmptyState = ({ onClick, organizationTitle, ...props }) => (
  <Wrapper {...props}>
    <SVGFlex>
      <Image src={EmptyPersonalSVG} alt="empty-project" />
      <StyledXL>
        Welcome to{' '}
        {organizationTitle
          ? `the ${organizationTitle} workspace`
          : 'your Personal workspace'}
      </StyledXL>
      <StyledMD>
        Get started by creating {organizationTitle ? 'the' : 'your'} first
        project
      </StyledMD>
      <ButtonFlex>
        <Button
          isPrimary
          onClick={onClick}
          data-test="workspace-empty-state-new-project-button"
        >
          New Project
        </Button>
      </ButtonFlex>
    </SVGFlex>
  </Wrapper>
)

OrganizationEmptyState.propTypes = {
  onClick: PropTypes.func.isRequired,
  organizationTitle: PropTypes.string,
}

OrganizationEmptyState.defaultProps = {
  organizationTitle: '',
}

export default OrganizationEmptyState
