import React, { useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import {
  Dropdown,
  Menu,
  Item,
  PreviousItem,
  NextItem,
  Trigger,
  Separator,
} from '@zendeskgarden/react-dropdowns'
import { Button } from '@zendeskgarden/react-buttons'
import Tippy from '@tippyjs/react'
import LinkVariantIcon from 'mdi-react/LinkVariantIcon'
import TrashIcon from 'mdi-react/TrashIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import FolderMoveOutlineIcon from 'mdi-react/FolderMoveOutlineIcon'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import { v1 as uuidv1 } from 'uuid'
import Loader from '../../atoms/Loader'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import { getProjectsRequest } from '../../../store/projects/projects.actions'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import { moveSessionRequest } from '../../../store/sessions/sessions.actions'
import JiraIconSvg from '../../../assets/mark-gradient-neutral-jira-software.svg'
import TrelloIconSvg from '../../../assets/mark-gradient-neutral-trello.svg'
import SlackIconSvg from '../../../assets/Slack_Mark_Monochrome_Grey.svg'
import useJira from '../../hooks/useJira'
import useTrello from '../../hooks/useTrello'
import useSlack from '../../hooks/useSlack'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const JiraLogo = styled.img`
  width: 20px;
  height: 20px;
  transform: scale(1.1);
  margin-right: 16px;
`

const TrelloLogo = styled.img`
  width: 20px;
  height: 20px;
  transform: scale(0.9);
  margin-right: 16px;
`

const SlackLogo = styled.img`
  width: 20px;
  height: 20px;
  transform: scale(0.7);
  margin-right: 16px;
`

const baseWidth = 32
const ActionsContainer = styled(Button).attrs({
  isBasic: true,
})`
  flex: 0 0 24px;
  width: ${baseWidth}px;
  height: ${baseWidth}px;
  cursor: pointer;
  padding: 8px;
  border-radius: ${baseWidth}px;
  align-items: center;
  visibility: ${ternary('isVisible')('visible', 'hidden')};
  position: absolute;
  top: calc(50% - ${baseWidth / 2}px);
  right: 8px;
  outline: none;

  && {
    outline: none;
    padding: 0;
    width: ${baseWidth}px;
    height: ${baseWidth}px;
    border-radius: ${baseWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && svg {
    fill: ${prop('theme.colors.grey400')};
  }
`

const IconBox = styled(Flex)`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`

const menuItemCss = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 24px 8px 16px;

  && svg {
    margin-right: 16px;
  }
`
const StyledMenuItem = styled(Item)`
  ${menuItemCss}
`
const StyledNextItem = styled(NextItem)`
  ${menuItemCss}
`

const StyledLoader = styled(Loader).attrs({
  size: '24px',
})`
  padding: 24px 48px;
  align-self: center;
`

const EmptyItem = styled(Flex)`
  padding: 8px 36px 12px 36px;
  color: ${prop('theme.colors.grey600')};
  font-style: italic;
`

const stopEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const SessionSettings = ({
  sessionId,
  isVisible,
  onCopy,
  onShare,
  onDelete,
  onJiraClick,
  onTrelloClick,
  onSlackClick,
  showCopy,
  showShare,
  showDelete,
  showMove,
  showJira,
  showTrello,
  showSlack,
  ...props
}) => {
  const organizations = useSelector(fromOrganizations.getOrganizations)
  const { hasJira } = useJira()
  const { hasTrello } = useTrello()
  const { hasSlack } = useSlack()
  const [menuTree, setMenuTree] = useState(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [isLoadingProjects, setLoadingProjects] = useState(false)
  const [projects, setProjects] = useState(false)
  const [selectedMoveToProject, setSelectedMoveToProject] = useState(null)

  const dispatch = useDispatch()
  const getOrganizationProjects = useCallback(
    organizationId => {
      setLoadingProjects(true)
      dispatch(getProjectsRequest(organizationId)).then(data => {
        const _projects = data?.projects || []
        setProjects(_projects)
        setLoadingProjects(false)
      })
    },
    [dispatch]
  )

  const renderItems = ({
    showCopy: _showCopy,
    showShare: _showShare,
    showDelete: _showDelete,
    showMove: _showMove,
    showJira: _showJira,
    showTrello: _showTrello,
    showSlack: _showSlack,
  }) => {
    if (menuTree === 'move') {
      return (
        <>
          <PreviousItem value="root">Workspaces</PreviousItem>
          <Separator />
          {organizations.map(organization => (
            <NextItem
              key={organization.pk}
              value="workspace"
              onClick={() => {
                getOrganizationProjects(organization.pk)
              }}
            >
              {organization.title}
            </NextItem>
          ))}
        </>
      )
    }

    if (menuTree === 'workspace') {
      return (
        <>
          <PreviousItem value="move">Projects</PreviousItem>
          <Separator />
          {isLoadingProjects && <StyledLoader />}
          {!isLoadingProjects && projects.length === 0 && (
            <EmptyItem>Empty workspace</EmptyItem>
          )}
          {!isLoadingProjects &&
            projects.length > 0 &&
            projects.map(project => {
              return (
                <Item
                  key={project.pk}
                  value="move-to-project"
                  onClick={() => setSelectedMoveToProject(project)}
                >
                  {project.title}
                </Item>
              )
            })}
        </>
      )
    }

    return (
      <>
        {_showShare && (
          <StyledMenuItem value="share" onClick={stopEvent}>
            <AccountMultipleIcon size={20} />
            Share
          </StyledMenuItem>
        )}
        {_showCopy && (
          <StyledMenuItem value="copy" onClick={stopEvent}>
            <LinkVariantIcon size={18} />
            Copy link
          </StyledMenuItem>
        )}
        {_showMove && (
          <StyledNextItem value="move" onClick={stopEvent}>
            <FolderMoveOutlineIcon size={20} />
            Move to
          </StyledNextItem>
        )}
        {_showJira && (
          <StyledNextItem value="jira" onClick={stopEvent}>
            <JiraLogo src={JiraIconSvg} alt="jira" />
            {hasJira ? 'Create Jira Issue' : 'Connect Jira'}
          </StyledNextItem>
        )}
        {_showTrello && (
          <StyledNextItem value="trello" onClick={stopEvent}>
            <TrelloLogo src={TrelloIconSvg} alt="trello" />
            {hasTrello ? 'Create Trello Issue' : 'Connect Trello'}
          </StyledNextItem>
        )}
        {_showSlack && (
          <StyledNextItem value="slack" onClick={stopEvent}>
            <SlackLogo src={SlackIconSvg} alt="slack" />
            {hasSlack ? 'Send to Slack' : 'Connect Slack'}
          </StyledNextItem>
        )}
        {_showDelete && (
          <StyledMenuItem value="delete" onClick={stopEvent}>
            <TrashIcon size={20} />
            Delete
          </StyledMenuItem>
        )}
      </>
    )
  }

  const [isTooltipVisible, setTooltipVisible] = useState(false)
  const tooltipTargetRef = useRef()

  return (
    <>
      <Tippy
        content="Actions"
        placement="bottom"
        arrow={false}
        zIndex={10}
        visible={isTooltipVisible}
        reference={tooltipTargetRef.current}
      />
      <Dropdown
        isOpen={isDropdownOpen}
        onClick={stopEvent}
        highlightedIndex={null}
        onStateChange={(changes, stateAndHelpers) => {
          if (Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
            const shouldBeOpen =
              changes.selectedItem === 'root' ||
              changes.selectedItem === 'move' ||
              changes.selectedItem === 'workspace' ||
              changes.isOpen
            setDropdownOpen(shouldBeOpen)

            if (!shouldBeOpen) {
              stateAndHelpers.clearSelection()
              stateAndHelpers.clearItems()
            }
          }

          if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
            setMenuTree(changes.selectedItem)
          }
        }}
        onSelect={item => {
          if (item === 'copy') {
            onCopy()
          }
          if (item === 'share') {
            onShare()
          }
          if (item === 'delete') {
            onDelete()
          }
          if (item === 'jira') {
            onJiraClick()
          }
          if (item === 'trello') {
            onTrelloClick()
          }
          if (item === 'slack') {
            onSlackClick()
          }
          if (item === 'move-to-project') {
            setTimeout(() => {
              dispatch(
                moveSessionRequest({
                  sessionId,
                  destinationProjectId: selectedMoveToProject.pk,
                })
              )
                .then(() => {
                  dispatch(
                    addNotification({
                      notificationId: uuidv1(),
                      text: `Recording successfully moved to project ${selectedMoveToProject.title}`,
                    })
                  )
                })
                .catch(err => {
                  console.error(err)
                  dispatch(
                    addNotification({
                      notificationId: uuidv1(),
                      text: `Something went wrong`,
                    })
                  )
                })
            }, 100)
          }
        }}
      >
        <Trigger>
          <ActionsContainer
            isVisible={isVisible}
            onClick={stopEvent}
            {...props}
          >
            <IconBox
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
              ref={tooltipTargetRef}
            >
              <DotsVerticalIcon size={24} />
            </IconBox>
          </ActionsContainer>
        </Trigger>
        <Menu
          placement="end-top"
          hasArrow
          popperModifiers={{
            preventOverflow: { boundariesElement: 'viewport' },
          }}
          onClick={stopEvent}
        >
          {renderItems({
            showCopy,
            showShare,
            showDelete,
            showMove,
            showJira,
            showTrello,
            showSlack,
          })}
        </Menu>
      </Dropdown>
    </>
  )
}

SessionSettings.propTypes = {
  sessionId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onCopy: PropTypes.func,
  onShare: PropTypes.func,
  onDelete: PropTypes.func,
  onJiraClick: PropTypes.func,
  onTrelloClick: PropTypes.func,
  onSlackClick: PropTypes.func,
  showCopy: PropTypes.bool,
  showShare: PropTypes.bool,
  showDelete: PropTypes.bool,
  showMove: PropTypes.bool,
  showJira: PropTypes.bool,
  showTrello: PropTypes.bool,
  showSlack: PropTypes.bool,
}

SessionSettings.defaultProps = {
  isVisible: false,
  onCopy: () => {},
  onShare: () => {},
  onDelete: () => {},
  onJiraClick: () => {},
  onTrelloClick: () => {},
  onSlackClick: () => {},
  showCopy: true,
  showShare: true,
  showDelete: true,
  showMove: true,
  showJira: true,
  showTrello: true,
  showSlack: true,
}

export default SessionSettings
