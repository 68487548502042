import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import prop from 'lodash/fp/prop'
import { v1 as uuidv1 } from 'uuid'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import ArrowLeft from '../../atoms/Icons/arrow_left.svg'
import Flex from '../../atoms/Flex'
import Loader from '../../atoms/Loader'
import MobileModal from '../../molecules/MobileModal'
import MobileTopBar from '../../molecules/MobileTopBar'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import MobileTemplate from '../../templates/MobileTemplate'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import { getProjectsRequest } from '../../../store/projects/projects.actions'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import { moveSessionRequest } from '../../../store/sessions/sessions.actions'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Item = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  color: ${({ color, theme }) => color || theme.colors.grey800};
  font-weight: 400;
`

const ItemIcon = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;

  > * {
    fill: ${({ iconColor, theme }) => iconColor || theme.colors.grey300};
  }
`

const ItemContent = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  padding-left: 16px;
`
const ItemText = styled(Flex)`
  color: ${prop('theme.colors.grey800')};
`

const StyledLoader = styled(Loader).attrs({
  size: '48px',
})`
  padding: 48px;
  align-self: center;
`

const MoveRecordingTreePageMobile = ({ sessionId, onBack, isVisible }) => {
  const dispatch = useDispatch()
  const organizations = useSelector(fromOrganizations.getOrganizations)
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const [isLoadingProjects, setLoadingProjects] = useState(false)
  const [projects, setProjects] = useState(false)
  const [selectedProjectId, setSelectedProjectId] = useState(false)

  const getOrganizationProjects = useCallback(
    organizationId => {
      setLoadingProjects(true)
      dispatch(getProjectsRequest(organizationId)).then(data => {
        const _projects = data?.projects || []
        setProjects(_projects)
        setLoadingProjects(false)
      })
    },
    [dispatch]
  )

  const headerWorkspaces = useMemo(
    () => (
      <StyledMobileTopBar icon={ArrowLeft} onButtonClick={onBack}>
        Workspaces
      </StyledMobileTopBar>
    ),
    []
  )
  const headerProjects = useMemo(
    () => (
      <StyledMobileTopBar
        icon={ArrowLeft}
        onButtonClick={() => setSelectedWorkspace(null)}
      >
        {selectedWorkspace?.title} projects
      </StyledMobileTopBar>
    ),
    [selectedWorkspace]
  )

  const bodyWorkspaces = useMemo(
    () =>
      organizations.map(organization => (
        <Item
          key={organization.pk}
          onClick={() => {
            setSelectedWorkspace(organization)
            getOrganizationProjects(organization.pk)
          }}
        >
          <ItemContent>
            <ItemText>{organization.title}</ItemText>
          </ItemContent>
          <ItemIcon>
            <ArrowRightIcon />
          </ItemIcon>
        </Item>
      )),
    [organizations]
  )

  const bodyProjects = useMemo(
    () => (
      <>
        {isLoadingProjects && <StyledLoader />}
        {!isLoadingProjects && projects.length === 0 && (
          <Item>
            <ItemContent>
              <ItemText>
                <i>Empty workspace</i>
              </ItemText>
            </ItemContent>
          </Item>
        )}
        {!isLoadingProjects &&
          projects.length > 0 &&
          projects.map(project => (
            <Item
              key={project.pk}
              onClick={() => {
                setSelectedProjectId(project.pk)
                dispatch(
                  moveSessionRequest({
                    sessionId,
                    destinationProjectId: project.pk,
                  })
                )
                  .then(() => {
                    dispatch(
                      addNotification({
                        notificationId: uuidv1(),
                        text: `Recording successfully moved to project ${project.title}`,
                      })
                    )
                  })
                  .catch(err => {
                    console.error(err)
                    dispatch(
                      addNotification({
                        notificationId: uuidv1(),
                        text: `Something went wrong`,
                      })
                    )
                  })
                  .finally(() => {
                    setSelectedProjectId(null)
                    onBack()
                  })
              }}
            >
              <ItemContent>
                <ItemText>{project.title}</ItemText>
              </ItemContent>
              <ItemIcon>
                {selectedProjectId === project.pk && <Loader size="20px" />}
              </ItemIcon>
            </Item>
          ))}
      </>
    ),
    [isLoadingProjects, projects, selectedProjectId]
  )

  return (
    <Flex>
      {isVisible && (
        <MobileModal>
          <MobileTemplate
            header={!selectedWorkspace ? headerWorkspaces : headerProjects}
            body={!selectedWorkspace ? bodyWorkspaces : bodyProjects}
            footer={null}
          />
        </MobileModal>
      )}
    </Flex>
  )
}

MoveRecordingTreePageMobile.propTypes = {
  isVisible: PropTypes.bool,
  onBack: PropTypes.func,
  sessionId: PropTypes.string.isRequired,
}

MoveRecordingTreePageMobile.defaultProps = {
  isVisible: false,
  onBack: () => {},
}

export default MoveRecordingTreePageMobile
