import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'
import AvatarPicker from '../../atoms/AvatarPicker'
import { addSearchParameter } from '../../../util/utils'

const Wrapper = styled(Flex)``

const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`
const Label = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 500;
`
const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const PictureSetting = ({ picture, userName, onPictureChange, ...props }) => {
  return (
    <Wrapper {...props}>
      <SettingRow>
        <Label>Picture</Label>
        <Edited>
          <AvatarPicker
            size="small"
            imageUrl={picture}
            name={userName}
            onPictureChange={newPictureUrl =>
              onPictureChange(
                addSearchParameter(newPictureUrl, 'r', Math.random())
              )
            }
          />
        </Edited>
      </SettingRow>
    </Wrapper>
  )
}

PictureSetting.propTypes = {
  picture: PropTypes.string,
  userName: PropTypes.string,
  onPictureChange: PropTypes.func,
}

PictureSetting.defaultProps = {
  picture: null,
  userName: null,
  onPictureChange: () => {},
}

PictureSetting.defaultProps = {}

export default PictureSetting
