import { all, call, put, takeEvery } from 'redux-saga/effects'
import gql from 'graphql-tag'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import { listUsers as listUsersGql } from '../../graphql/queries'
import {
  GET_USERS_REQUEST,
  getUsersRequestSuccess,
  getUsersRequestFailure,
} from './users.actions'

function* getUsers({ apolloClient }, { payload: { ids }, meta: { thunk } }) {
  try {
    const uniqueIds = uniq(ids)
    const data = yield call(apolloClient.query, {
      query: gql(listUsersGql),
      fetchPolicy: 'network-only',
      variables: {
        ids: uniqueIds,
      },
    })
    const users = get(data, 'data.listUsers.items')

    if (users) {
      yield put(getUsersRequestSuccess(compact(users), thunk))
    } else {
      yield put(getUsersRequestFailure({ error: 'no users' }, thunk))
    }
  } catch (err) {
    yield put(getUsersRequestFailure(err, thunk))
  }
}

export default function* usersSagas({ apolloClient }) {
  yield all([yield takeEvery(GET_USERS_REQUEST, getUsers, { apolloClient })])
}
