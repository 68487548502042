import {
  GET_SLACK_REQUEST_FAILURE,
  GET_SLACK_REQUEST_SUCCESS,
  UPDATE_SLACK_REQUEST_SUCCESS,
} from './integrations.slack.actions'
import { buildReducerForActions } from '../../../util/reduxTools'

export const STATE_KEY = 'integrations'
export const STATE_SUB_KEY = 'slack'

export const INITIAL_STATE = {
  hasSlack: false,
}

const reduceSlack = (state, { payload: { data } }) => {
  return {
    ...state,
    ...data,
  }
}

const reducer = {
  [GET_SLACK_REQUEST_FAILURE]: () => {
    return INITIAL_STATE
  },
  [GET_SLACK_REQUEST_SUCCESS]: reduceSlack,
  [UPDATE_SLACK_REQUEST_SUCCESS]: reduceSlack,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
