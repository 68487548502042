import { merge, set } from 'dot-prop-immutable'
import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import pipe from 'lodash/fp/pipe'
import concat from 'lodash/fp/concat'
import getOr from 'lodash/fp/getOr'
import omit from 'lodash/omit'
import remove from 'lodash/remove'
import moment from 'moment'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import { buildReducerForActions } from '../../util/reduxTools'
import {
  SET_CURRENT_SESSION,
  UPDATE_SESSION_REQUEST_SUCCESS,
  GET_SESSION_REQUEST_SUCCESS,
  UPSERT_SESSION,
  UPSERT_SESSIONS,
  UPDATE_SESSION_SHARING_REQUEST_SUCCESS,
  DELETE_SESSION_REQUEST_SUCCESS,
} from './sessions.actions'

export const STATE_KEY = 'sessions'

const getHumanReadableDate = momentDate => momentDate.format('lll')

export const INITIAL_STATE = {
  current: '',
  byId: {},
  allIds: [],
}

const upsertSession = (state, session) => {
  const { pk } = session
  const isSessionPresent = !!get(state, `byId.${pk}`)
  const newState = set(state, `byId.${pk}`, session)
  if (!isSessionPresent) {
    const newIds = pipe(
      getOr({}, 'allIds'),
      concat(pk)
    )(state)
    return set(newState, 'allIds', newIds)
  }
  return newState
}

const upsertSessions = (state, sessions = [], projectId) => {
  const byId = filter(state.byId, x => x.projectId !== projectId).reduce(
    (acc, session) => {
      acc[session.pk] = session
      return acc
    },
    {}
  )
  const allIds = map(byId, x => x.pk)

  const previousState = {
    ...state,
    byId,
    allIds,
  }
  return sessions.reduce((acc, session) => {
    acc.byId[session.pk] = session
    if (!acc.allIds.includes(session.pk)) {
      acc.allIds.push(session.pk)
    }
    return acc
  }, previousState)
}

const reducer = {
  [SET_CURRENT_SESSION]: (state, { payload: { sessionId } }) =>
    set(state, 'current', sessionId),
  [GET_SESSION_REQUEST_SUCCESS]: (state, { payload: { session } }) =>
    upsertSession(state, session),
  [UPDATE_SESSION_REQUEST_SUCCESS]: (state, { payload }) => {
    if (!payload) {
      return state
    }

    const { sessionId, updatedAt, ...session } = payload
    const date = moment.unix(updatedAt)
    return merge(state, `byId.${sessionId}`, {
      ...session,
      updatedAt,
      updatedAtHuman: getHumanReadableDate(date),
    })
  },
  [UPSERT_SESSION]: (state, { payload: { session } }) =>
    upsertSession(state, session),
  [UPSERT_SESSIONS]: (state, { payload: { sessions = [], projectId } }) => ({
    ...state,
    ...upsertSessions(state, sessions, projectId),
  }),
  [UPDATE_SESSION_SHARING_REQUEST_SUCCESS]: (
    state,
    { payload: { sessionId, isPublic, areCommentsAllowed } }
  ) => {
    const session = get(state, `byId.${sessionId}`)
    return merge(state, `byId.${sessionId}`, {
      ...session,
      isPublic,
      areCommentsAllowed,
    })
  },
  [DELETE_SESSION_REQUEST_SUCCESS]: (state, { payload: { sessionId } }) => {
    const newState = {
      ...state,
      byId: omit(state.byId, sessionId),
      allIds: remove(state.allIds, x => x === sessionId),
    }
    return newState
  },
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
