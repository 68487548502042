/* eslint-disable import/prefer-default-export */
import { delay, take, call, race } from 'redux-saga/effects'
import { ACTIVE_USER, INACTIVE_USER } from './actions'

export function* activityBasedAction(
  fn,
  baseTimeout = 15000,
  { apolloClient }
) {
  const BASE_TIMEOUT = baseTimeout
  let minimumTimeout = BASE_TIMEOUT
  let lastInactive = Date.now()
  while (true) {
    const [timeout, active, inactive] = yield race([
      delay(minimumTimeout),
      take(ACTIVE_USER),
      take(INACTIVE_USER),
    ])

    if (inactive) {
      lastInactive = Date.now()
      minimumTimeout = 100000000000000000000
    }

    if (timeout) {
      yield call(fn, { apolloClient })
    }

    if (active) {
      minimumTimeout = BASE_TIMEOUT
      if (Date.now() - lastInactive >= minimumTimeout) {
        yield call(fn, { apolloClient })
      }
    }
  }
}
