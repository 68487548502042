import { all, takeLatest, delay, put } from 'redux-saga/effects'
import {
  NOTIFICATIONS_ADD,
  removeNotification,
} from './ui-notifications.actions'

// eslint-disable-next-line require-yield
function* notificationsAddSaga({ payload: { notificationId, isPersisted } }) {
  if (!isPersisted) {
    yield delay(3000)
    yield put(removeNotification({ notificationId }))
  }
}

export default function* notificationsSagas(/* { apolloClient } */) {
  yield all([yield takeLatest(NOTIFICATIONS_ADD, notificationsAddSaga)])
}
