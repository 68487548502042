/* eslint-disable import/prefer-default-export */
import React from 'react'
import PencilIcon from 'mdi-react/PencilIcon'
import LinkVariantIcon from 'mdi-react/LinkVariantIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShareVariantIcon from 'mdi-react/ShareVariantIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import FolderMoveOutlineIcon from 'mdi-react/FolderMoveOutlineIcon'
import copy from 'copy-to-clipboard'
import { getSessionUrl } from '../../../lib/urls'
import { share, isShareApiAvailable } from '../../../lib/share'

export const PAGES = {
  newComment: 'newComment',
  comments: 'comments',
  thread: 'thread',
  share: 'share',
}

export const getSessionMenuItems = ({
  sessionTitle,
  sessionId,
  isUserOwnSession,
  isAnonymous,
  setRenameSessionModalVisible,
  setDeleteSessionModalVisible,
  setMoveRecordingTreeVisible,
  setSecondaryPage,
  showCopySnackbar,
}) => {
  const sessionSheetItems = []
  if (isUserOwnSession) {
    sessionSheetItems.push({
      text: 'Rename recording',
      icon: <PencilIcon />,
      onClick: () => setRenameSessionModalVisible(true),
    })
  }

  sessionSheetItems.push({
    text: 'Copy recording link',
    icon: <LinkVariantIcon />,
    onClick: () => {
      copy(getSessionUrl(sessionId))
      showCopySnackbar()
    },
  })

  if (!isAnonymous) {
    sessionSheetItems.push({
      text: 'Share settings',
      icon: <AccountMultipleIcon />,
      onClick: () => {
        setSecondaryPage(PAGES.share)
      },
    })
  }

  if (isShareApiAvailable()) {
    sessionSheetItems.push({
      text: 'Share recording',
      icon: <ShareVariantIcon />,
      onClick: () => {
        share({ title: sessionTitle, url: getSessionUrl(sessionId) })
      },
    })
  }

  if (!isAnonymous) {
    sessionSheetItems.push({
      text: 'Move recording',
      icon: <FolderMoveOutlineIcon />,
      onClick: () => setMoveRecordingTreeVisible(true),
    })
  }

  if (isUserOwnSession) {
    sessionSheetItems.push({
      text: 'Delete recording',
      icon: <DeleteIcon />,
      onClick: () => setDeleteSessionModalVisible(true),
    })
  }

  return sessionSheetItems
}
