import React from 'react'
import MobileTopBar from './MobileTopBar'
import useNotifications from '../../../store/hooks/useNotifications'

const MobileTopBarContainer = ({ ...props }) => {
  const { unreadNotificationsCount } = useNotifications()

  return (
    <MobileTopBar
      {...props}
      unreadNotificationsCount={unreadNotificationsCount}
    />
  )
}

export default MobileTopBarContainer
