import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Dropdown, Menu, Item, Trigger } from '@zendeskgarden/react-dropdowns'
import { Button } from '@zendeskgarden/react-buttons'
import TrashIcon from 'mdi-react/TrashIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import { ternary } from '../../../util/utils'

const baseWidth = 32
const ActionsContainer = styled(Button).attrs({
  isBasic: true,
})`
  flex: 0 0 24px;
  width: ${baseWidth}px;
  height: ${baseWidth}px;
  cursor: pointer;
  padding: 8px;
  border-radius: ${baseWidth}px;
  align-items: center;
  visibility: ${ternary('isVisible')('visible', 'hidden')};
  position: absolute;
  top: calc(50% - ${baseWidth / 2}px);
  right: 8px;
  outline: none;

  && {
    outline: none;
    padding: 0;
    width: ${baseWidth}px;
    height: ${baseWidth}px;
    border-radius: ${baseWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && svg {
    fill: ${prop('theme.colors.grey400')};
  }
`

const menuItemCss = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 24px 8px 16px;

  && svg {
    margin-right: 16px;
  }
`
const StyledMenuItem = styled(Item)`
  ${menuItemCss}
`

const stopEvent = e => {
  e.preventDefault()
  e.stopPropagation()
}

const ProjectSettings = ({
  isVisible,
  onShare,
  showDelete,
  onDelete,
  ...props
}) => (
  <Dropdown
    onClick={stopEvent}
    onSelect={item => {
      if (item === 'share') {
        onShare()
      }
      if (item === 'delete') {
        onDelete()
      }
    }}
  >
    <Trigger>
      <ActionsContainer isVisible={isVisible} onClick={stopEvent} {...props}>
        <DotsVerticalIcon size={24} />
      </ActionsContainer>
    </Trigger>
    <Menu
      placement="start-top"
      hasArrow
      popperModifiers={{
        preventOverflow: { boundariesElement: 'viewport' },
      }}
      onClick={stopEvent}
    >
      <StyledMenuItem
        value="share"
        onClick={stopEvent}
        data-test="project-card-settings-share"
      >
        <AccountMultipleIcon size={20} />
        Share
      </StyledMenuItem>
      {showDelete && (
        <StyledMenuItem
          value="delete"
          onClick={stopEvent}
          data-test="project-card-settings-delete"
        >
          <TrashIcon size={20} />
          Delete
        </StyledMenuItem>
      )}
    </Menu>
  </Dropdown>
)

ProjectSettings.propTypes = {
  isVisible: PropTypes.bool,
  onShare: PropTypes.func,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
}

ProjectSettings.defaultProps = {
  isVisible: false,
  onShare: () => {},
  onDelete: () => {},
  showDelete: false,
}

export default ProjectSettings
