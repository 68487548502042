import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Div100vh from '../../molecules/Div100vh'
import { ternary } from '../../../util/utils'
import Flex from '../Flex'
import useNotch from '../../hooks/useNotch'

const Wrapper = styled(Div100vh)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${ternary('showBackdrop')(
    'rgba(0, 0, 0, 0.5)',
    'transparent'
  )};
  z-index: 2400;
  will-change: opacity;
  transition: all 0.1s linear;
  opacity: ${ternary('isVisible')('1', '0')};
  visibility: ${ternary('isVisible')('visible', 'hidden')};
`

const SheetBox = styled(Flex)`
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  z-index: 2500;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  will-change: translateY;
  transition: all 0.3s ease-in-out;
  transform: translateY(${ternary('isVisible')('0', '100%')});
  ${prop('theme.boxShadows.elevation1')};
  padding-bottom: ${prop('paddingBottom')}px;
`

const Sheet = ({ isVisible, children, showBackdrop, onClose, ...props }) => {
  const { bottomNotch } = useNotch
  return (
    <Wrapper
      {...props}
      showBackdrop={showBackdrop}
      isVisible={isVisible}
      onClick={onClose}
    >
      <SheetBox
        isVisible={isVisible}
        paddingBottom={bottomNotch}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {children}
      </SheetBox>
    </Wrapper>
  )
}

Sheet.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  showBackdrop: PropTypes.bool,
}

Sheet.defaultProps = {
  onClose: () => {},
  isVisible: false,
  children: null,
  showBackdrop: true,
}

export default Sheet
