import Quill from 'quill'
import {
  getMentionCharIndex,
  hasValidChars,
  hasValidMentionCharIndex,
} from './utils'

class Mention {
  constructor(quill, options) {
    this.isOpen = false
    this.itemIndex = 0
    this.mentionCharPos = null
    this.cursorPos = null
    this.values = []

    this.quill = quill

    this.options = {
      source: null,
      renderItem(item) {
        return `${item.value}`
      },
      onSelect(item, insertItem) {
        insertItem(item)
      },
      mentionDenotationChars: ['@'],
      showDenotationChar: true,
      allowedChars: /^[a-zA-Z0-9_]*$/,
      minChars: 0,
      maxChars: 31,
      offsetTop: 2,
      offsetLeft: 0,
      isolateCharacter: false,
      fixMentionsToQuill: false,
      defaultMenuOrientation: 'bottom',
      dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target'],
      linkTarget: '_blank',
      onOpen() {
        return true
      },
      onClose() {
        return true
      },
      spaceAfterInsert: true,
      onTrigger() {},
      onDenotationCharRemoved() {},
    }

    Object.assign(this.options, options, {
      dataAttributes: Array.isArray(options.dataAttributes)
        ? this.options.dataAttributes.concat(options.dataAttributes)
        : this.options.dataAttributes,
    })

    quill.on('text-change', this.onTextChange.bind(this))
  }

  getTextBeforeCursor() {
    const startPos = Math.max(0, this.cursorPos - this.options.maxChars)
    const textBeforeCursorPos = this.quill.getText(
      startPos,
      this.cursorPos - startPos
    )
    return textBeforeCursorPos
  }

  onSomethingChange(delta) {
    const range = this.quill.getSelection()
    if (range == null) return

    if (
      this.isOpen &&
      delta?.ops?.[1]?.insert &&
      delta.ops[1].insert === '\n'
    ) {
      const keepCharacters = delta.ops[0].retain
      this.quill.deleteText(keepCharacters, 1, 'api')
      return
    }

    this.cursorPos = range.index
    const textBeforeCursor = this.getTextBeforeCursor()
    const { mentionChar, mentionCharIndex } = getMentionCharIndex(
      textBeforeCursor,
      this.options.mentionDenotationChars
    )

    if (
      hasValidMentionCharIndex(
        mentionCharIndex,
        textBeforeCursor,
        this.options.isolateCharacter
      )
    ) {
      const mentionCharPos =
        this.cursorPos - (textBeforeCursor.length - mentionCharIndex)
      this.mentionCharPos = mentionCharPos
      const textAfter = textBeforeCursor.substring(
        mentionCharIndex + mentionChar.length
      )
      if (
        textAfter.length >= this.options.minChars &&
        hasValidChars(textAfter, this.options.allowedChars)
      ) {
        this.isOpen = true
        this.quill.formatText(
          this.mentionCharPos,
          mentionChar.length + textAfter.length,
          'user-suggestion',
          true,
          'api'
        )

        this.options.onTrigger({
          textAfter,
          mentionChar,
          mentionCharPosition: this.mentionCharPos,
          triggerNode: this.quill.container.querySelector(
            '.ql-user-suggestion'
          ),
        })

        return
      }

      this.onMissinMentions(mentionChar, textAfter)
    }
    this.onMissinMentions(mentionChar, '')
  }

  onMissinMentions(mentionChar, textAfter) {
    if (this.isOpen) {
      this.options.onDenotationCharRemoved()
      this.isOpen = false
      if (mentionChar) {
        this.quill.formatText(
          this.mentionCharPos,
          mentionChar.length + textAfter.length,
          'user-suggestion',
          false,
          'silent'
        )
      }
      this.quill.format('user-suggestion', false, 'silent')
      this.quill.format('user-mention', false, 'silent')
      this.quill.setSelection(this.cursorPos, 0, 'silent')
    }
  }

  onTextChange(delta, oldDelta, source) {
    if (source === 'user') {
      this.onSomethingChange(delta)
    }
  }
}

Quill.register('modules/mention', Mention)

export default Mention
