import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/values'
import sortBy from 'lodash/fp/sortBy'
import { createSelector } from 'reselect'
import { STATE_KEY } from './ui-notifications.reducers'

const getState = state => getImmutable(state, STATE_KEY)

const getAllNotifications = createSelector(
  getState,
  pipe(
    get('byId'),
    sortBy('createdAt'),
    values
  )
)

export default {
  getAllNotifications,
}
