import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import LinkIcon from 'mdi-react/LinkIcon'
import Flex from '../../atoms/Flex'
import Card from '../../atoms/Card'
import Loader from '../../atoms/Loader'
import DetailsLabel from '../DetailsLabel'
import RemoteTestSettings from './RemoteTestSettings'
import SessionCard from '../SessionCard'
import { SESSION_CARD_VARIANTS } from '../SessionCard/SessionCard'

const Wrapper = styled(Flex)``

const StyledRemoteTestSettings = styled(RemoteTestSettings)`
  display: none;
  visibility: hidden;
  top: 20px;
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;
  padding: 8px 16px;
  cursor: default;
  transition: background-color 0.3s;
  position: relative;

  &&:hover {
    background-color: ${prop('theme.colors.grey100')};
  }

  &&:hover ${StyledRemoteTestSettings} {
    display: flex;
    visibility: visible;
  }
`

const LeftBox = styled(Flex)`
  align-self: center;
  order: 0;
  flex: 0 1 auto;
`

const CentralBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  padding: 8px;
`

const RightBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  align-self: center;
`

const Title = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${prop('theme.colors.grey800')};
`

const Subtitle = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  flex-direction: row;
  margin-top: 8px;
`

const CounterBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  flex-direction: row;
`

const StyledLoader = styled(Loader)`
  color: ${prop('theme.colors.secondary')};
  font-size: 24px;
`

const StyledLinkIcon = styled(LinkIcon)`
  * {
    fill: ${prop('theme.colors.grey400')};
  }
`

const RemoteTestBox = styled(Flex)`
  flex-direction: row;
`

const SessionsBox = styled(Flex)`
  margin: 8px 8px 0 8px;
`

const StyledSessionCard = styled(SessionCard)`
  margin-bottom: 16px;
`

const RemoteTestCard = ({
  authorName,
  counter,
  hasSettings,
  imageData,
  imageUrl,
  isLoading,
  isPrivate,
  isPublic,
  isShared,
  onImageLoad,
  sharedWithCounter,
  spriteSize,
  title,
  onDelete,
  onShare,
  onPreview,
  onLink,
  onEdit,
  remoteTestId,
  sessions,
  onSessionClick,
  onSessionDelete,
  onSessionShare,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <StyledCard>
        <RemoteTestBox>
          <LeftBox>
            <StyledLinkIcon size={20} />
          </LeftBox>
          <CentralBox>
            <Title>{title}</Title>
            {!isLoading && (
              <Subtitle>
                <DetailsLabel
                  isPrivate={isPrivate && !isPublic}
                  isShared={isShared && !isPublic}
                  isPublic={isPublic}
                  sharedWithCounter={sharedWithCounter}
                  counter={null}
                  label={null}
                />
              </Subtitle>
            )}
          </CentralBox>
          {isLoading && (
            <RightBox>
              <CounterBox>
                <StyledLoader />
              </CounterBox>
            </RightBox>
          )}
          {!isLoading && hasSettings && (
            <StyledRemoteTestSettings
              remoteTestId={remoteTestId}
              onShare={onShare}
              onPreview={onPreview}
              onLink={onLink}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          )}
        </RemoteTestBox>
        {sessions.length > 0 && (
          <SessionsBox>
            {sessions.map(session => (
              <StyledSessionCard
                key={session.pk}
                data-test={`session-card-${session.title}`}
                sessionId={session.pk}
                title={session.title}
                counter={session.commentsCounter || 0}
                hasSettings
                settingsShowMove={false}
                showDetailsTag={false}
                variant={SESSION_CARD_VARIANTS.flat}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSessionClick({
                    sessionId: session.pk,
                    projectId: session.projectId,
                  })
                }}
                onDelete={() => onSessionDelete(session)}
                onShare={() => onSessionShare(session.pk)}
              />
            ))}
          </SessionsBox>
        )}
      </StyledCard>
    </Wrapper>
  )
}

RemoteTestCard.propTypes = {
  remoteTestId: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  counter: PropTypes.number,
  hasSettings: PropTypes.bool,
  imageData: PropTypes.string,
  imageUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isShared: PropTypes.bool,
  onDelete: PropTypes.func,
  onImageLoad: PropTypes.func,
  onShare: PropTypes.func,
  onPreview: PropTypes.func,
  onLink: PropTypes.func,
  onEdit: PropTypes.func,
  sharedWithCounter: PropTypes.number,
  title: PropTypes.string,
  spriteSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  sessions: PropTypes.arrayOf(PropTypes.shape({})),
  onSessionClick: PropTypes.func,
  onSessionDelete: PropTypes.func,
  onSessionShare: PropTypes.func,
}

RemoteTestCard.defaultProps = {
  authorName: '',
  counter: 0,
  hasSettings: false,
  imageData: '',
  imageUrl: '',
  isLoading: false,
  isPrivate: false,
  isPublic: false,
  isShared: false,
  onDelete: () => {},
  onImageLoad: () => {},
  onShare: () => {},
  onPreview: () => {},
  onLink: () => {},
  onEdit: () => {},
  sharedWithCounter: 0,
  spriteSize: null,
  title: '',
  sessions: [],
  onSessionClick: () => {},
  onSessionDelete: () => {},
  onSessionShare: () => {},
}

export default RemoteTestCard
