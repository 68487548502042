import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import gql from 'graphql-tag'
import {
  deleteReply as deleteReplyGql,
  updateReply as updateReplyGql,
  createReply as createReplyGql,
} from '../../graphql/mutations'
import {
  onCreateReply,
  onDeleteReply,
  onUpdateReply,
} from '../../graphql/subscriptions'
import {
  CREATE_REPLY_REQUEST,
  createReplyRequestSuccess,
  createReplyRequestFailure,
  DELETE_REPLY_REQUEST,
  deleteReplyRequestSuccess,
  deleteReplyRequestFailure,
  UPDATE_REPLY_REQUEST,
  updateReplyRequestSuccess,
  updateReplyRequestFailure,
} from './replies.actions'
import { LOGIN_REQUEST_SUCCESS } from '../actions'
import fromSessions from '../sessions/sessions.selectors'
import { getSessionCommentsRequest } from '../comments/comments.actions'
import { filterMentions } from '../../components/molecules/MentionSuggestion/mention.utils'
import { removeLastReturn } from '../../util/utils'

function* createReply(
  { apolloClient },
  { payload: { commentId, text, mentions }, meta: { thunk } }
) {
  try {
    const filteredMentions = filterMentions(mentions)
    yield call(apolloClient.mutate, {
      mutation: gql(createReplyGql),
      variables: {
        input: {
          commentId,
          text,
          mentions: filteredMentions,
        },
      },
    })

    yield put(createReplyRequestSuccess({}, thunk))
  } catch (e) {
    yield put(createReplyRequestFailure(e, thunk))
    console.error(e)
  }
}

function* deleteReply(
  { apolloClient },
  { payload: { commentId, replyId }, meta: { thunk } }
) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(deleteReplyGql),
      variables: {
        input: {
          commentId,
          replyId,
        },
      },
    })

    yield put(deleteReplyRequestSuccess({}, thunk))
  } catch (e) {
    yield put(deleteReplyRequestFailure(e, thunk))
    console.error(e)
  }
}

function* updateReply(
  { apolloClient },
  { payload: { commentId, replyId, text, mentions }, meta: { thunk } }
) {
  try {
    const sanitizedText = removeLastReturn(text)
    const filteredMentions = filterMentions(mentions)
    yield call(apolloClient.mutate, {
      mutation: gql(updateReplyGql),
      variables: {
        input: {
          commentId,
          replyId,
          text: sanitizedText,
          mentions: filteredMentions,
        },
      },
    })

    yield put(updateReplyRequestSuccess({}, thunk))
  } catch (e) {
    yield put(updateReplyRequestFailure(e, thunk))
    console.error(e)
  }
}

function* watchForReply({ apolloClient }) {
  try {
    const channel = eventChannel(emitter => {
      const createSubscription = apolloClient
        .subscribe({
          query: gql(onCreateReply),
        })
        .subscribe({
          next: () => {
            emitter('create-reply-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const deleteSubscription = apolloClient
        .subscribe({
          query: gql(onDeleteReply),
        })
        .subscribe({
          next: () => {
            emitter('delete-reply-event')
          },
          error: () => {
            emitter(END)
          },
        })

      const updateSubscription = apolloClient
        .subscribe({
          query: gql(onUpdateReply),
        })
        .subscribe({
          next: () => {
            emitter('update-reply-event')
          },
          error: () => {
            emitter(END)
          },
        })

      return () => {
        createSubscription.unsubscribe()
        deleteSubscription.unsubscribe()
        updateSubscription.unsubscribe()
      }
    })

    while (true) {
      yield take(channel)
      const sessionId = yield select(fromSessions.getCurrentSessionId)
      yield put(getSessionCommentsRequest(sessionId))
    }
  } catch (e) {
    console.error(e)
  }
}

function* startRepliesFork({ apolloClient }) {
  yield fork(watchForReply, { apolloClient })
}

export default function* repliesSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startRepliesFork, { apolloClient }),
    yield takeLatest(CREATE_REPLY_REQUEST, createReply, { apolloClient }),
    yield takeLatest(DELETE_REPLY_REQUEST, deleteReply, { apolloClient }),
    yield takeLatest(UPDATE_REPLY_REQUEST, updateReply, { apolloClient }),
  ])
}
