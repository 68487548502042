import React from 'react'
import PropTypes from 'prop-types'
import ProjectCard from './ProjectCard'
import useLibraryWorkspace from '../../../store/hooks/useLibraryWorkspace'

const ProjectCardContainer = ({ ...props }) => {
  const { shouldShowCreation } = useLibraryWorkspace()
  return <ProjectCard {...props} showDetailsTag={shouldShowCreation} />
}

ProjectCardContainer.propTypes = {
  project: PropTypes.number.isRequired,
}
export default ProjectCardContainer
