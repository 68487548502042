import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from '@zendeskgarden/react-modals'
import MobileModal from '../MobileModal'
import { isMobile } from '../../../lib/platform'

const ModalComponent = isMobile() ? MobileModal : Modal

const CancelButton = styled(Button)`
  && {
    color: ${prop('theme.colors.grey')};
  }
`

const ConfirmationModal = ({
  title,
  text,
  actionButtonText,
  onClose,
  onSubmit,
  isDanger = false,
  ...props
}) => (
  <ModalComponent
    onClose={onClose}
    isLarge={false}
    isAnimated={false}
    {...props}
  >
    <Header>{title}</Header>
    <Body>{text}</Body>
    <Footer>
      <FooterItem>
        <CancelButton onClick={onClose} isBasic>
          Cancel
        </CancelButton>
      </FooterItem>
      <FooterItem>
        <Button onClick={onSubmit} isPrimary isDanger={isDanger}>
          {actionButtonText}
        </Button>
      </FooterItem>
    </Footer>
    {!isMobile() && <Close aria-label="Close modal" onClick={onClose} />}
  </ModalComponent>
)

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actionButtonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDanger: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  isDanger: false,
}

export default ConfirmationModal
