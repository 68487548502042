const NAMESPACE = 'NOTIFICATIONS'

export const GET_NOTIFICATIONS = `${NAMESPACE}/GET`
export const GET_NOTIFICATIONS_REQUEST = `${GET_NOTIFICATIONS}/REQUEST`
export const GET_NOTIFICATIONS_REQUEST_SUCCESS = `${GET_NOTIFICATIONS_REQUEST}_SUCCESS`
export const GET_NOTIFICATIONS_REQUEST_FAILURE = `${GET_NOTIFICATIONS_REQUEST}_FAILURE`

export const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload: {},
  meta: { thunk: GET_NOTIFICATIONS_REQUEST },
})

export const getNotificationsRequestSuccess = ({ notifications }, thunk) => ({
  type: GET_NOTIFICATIONS_REQUEST_SUCCESS,
  payload: { notifications },
  meta: { thunk },
})

export const getNotificationsRequestFailure = (e, thunk) => ({
  type: GET_NOTIFICATIONS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const GET_UNREAD_NOTIFICATIONS = `${NAMESPACE}/GET_UNREAD`
export const GET_UNREAD_NOTIFICATIONS_REQUEST = `${GET_UNREAD_NOTIFICATIONS}/REQUEST`
export const GET_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS = `${GET_UNREAD_NOTIFICATIONS_REQUEST}_SUCCESS`
export const GET_UNREAD_NOTIFICATIONS_REQUEST_FAILURE = `${GET_UNREAD_NOTIFICATIONS_REQUEST}_FAILURE`

export const getUnreadNotificationsRequest = () => ({
  type: GET_UNREAD_NOTIFICATIONS_REQUEST,
  payload: {},
  meta: { thunk: GET_UNREAD_NOTIFICATIONS_REQUEST },
})

export const getUnreadNotificationsRequestSuccess = ({ count }, thunk) => ({
  type: GET_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
  payload: { count },
  meta: { thunk },
})

export const getUnreadNotificationsRequestFailure = (e, thunk) => ({
  type: GET_UNREAD_NOTIFICATIONS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const SET_NOTIFICATIONS_READ = `${NAMESPACE}/SET_READ`
export const SET_NOTIFICATIONS_READ_REQUEST = `${SET_NOTIFICATIONS_READ}/REQUEST`
export const SET_NOTIFICATIONS_READ_REQUEST_SUCCESS = `${SET_NOTIFICATIONS_READ_REQUEST}_SUCCESS`
export const SET_NOTIFICATIONS_READ_REQUEST_FAILURE = `${SET_NOTIFICATIONS_READ_REQUEST}_FAILURE`

export const setNotificationsReadRequest = ({ notificationIds }) => ({
  type: SET_NOTIFICATIONS_READ_REQUEST,
  payload: { notificationIds },
  meta: { thunk: SET_NOTIFICATIONS_READ_REQUEST },
})

export const setNotificationsReadRequestSuccess = (
  { notificationIds },
  thunk
) => ({
  type: SET_NOTIFICATIONS_READ_REQUEST_SUCCESS,
  payload: { notificationIds },
  meta: { thunk },
})

export const setNotificationsReadRequestFailure = (e, thunk) => ({
  type: SET_NOTIFICATIONS_READ_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
