import { all, put, race, take, takeEvery } from 'redux-saga/effects'
import {
  TRACK_SIGN_UP_REQUEST,
  trackSignUpRequestSuccess,
  trackSignUpRequestFailure,
  TRACK_LOGIN_REQUEST,
  trackLoginRequestSuccess,
  trackLoginRequestFailure,
  TRACK_SESSION_VIEW,
  TRACK_PROJECT_VIEW,
  TRACK_PROJECT_CREATE,
  TRACK_SESSION_CREATE,
  TRACK_COMMENT_CREATE,
} from './analytics.actions'
import {
  getUserRequest,
  GET_USER_REQUEST_SUCCESS,
  GET_USER_REQUEST_FAILURE,
} from '../user/user.actions'
import analytics from '../../lib/analytics'

function* trackLogin({ payload: { isGoogle }, meta: { thunk } }) {
  try {
    yield put(getUserRequest())
    const { success, failure } = yield race({
      success: take(GET_USER_REQUEST_SUCCESS),
      failure: take(GET_USER_REQUEST_FAILURE),
    })
    if (success?.payload?.identityId) {
      analytics.trackLogin({
        isGoogle,
        userIdentityId: success.payload.identityId,
      })
      yield put(trackLoginRequestSuccess({}, thunk))
    }
    if (!success?.payload?.identityId || failure) {
      analytics.trackSignUp({ isGoogle })
      yield put(trackLoginRequestFailure({}, thunk))
    }
  } catch (err) {
    analytics.trackSignUp({ isGoogle })
    yield put(trackLoginRequestFailure({}, thunk))
  }
}

function* trackSignUp({ payload: { isGoogle }, meta: { thunk } }) {
  try {
    yield put(getUserRequest())
    const { success, failure } = yield race({
      success: take(GET_USER_REQUEST_SUCCESS),
      failure: take(GET_USER_REQUEST_FAILURE),
    })
    if (success?.payload?.identityId) {
      analytics.trackLogin({
        isGoogle,
        userIdentityId: success.payload.identityId,
      })
      yield put(trackSignUpRequestFailure({}, thunk))
    }
    if (!success?.payload?.identityId || failure) {
      analytics.trackSignUp({ isGoogle })
      yield put(trackSignUpRequestSuccess({}, thunk))
    }
  } catch (err) {
    analytics.trackSignUp({ isGoogle })
    yield put(trackSignUpRequestSuccess({}, thunk))
  }
}

function* trackSessionViewHandler({ payload }) {
  yield put(getUserRequest())
  const { success } = yield race({
    success: take(GET_USER_REQUEST_SUCCESS),
    failure: take(GET_USER_REQUEST_FAILURE),
  })

  const userIdentityId = success?.payload?.identityId || ''
  analytics.trackSessionView({
    ...payload,
    userIdentityId,
  })
}

function* trackProjectViewHandler({ payload }) {
  yield put(getUserRequest())
  const { success } = yield race({
    success: take(GET_USER_REQUEST_SUCCESS),
    failure: take(GET_USER_REQUEST_FAILURE),
  })

  const userIdentityId = success?.payload?.identityId || ''
  analytics.trackProjectView({ ...payload, userIdentityId })
}

function* trackProjectCreateHandler({ payload }) {
  yield put(getUserRequest())
  const { success } = yield race({
    success: take(GET_USER_REQUEST_SUCCESS),
    failure: take(GET_USER_REQUEST_FAILURE),
  })

  const userIdentityId = success?.payload?.identityId || ''
  analytics.trackProjectCreate({ ...payload, userIdentityId })
}

function* trackSessionCreateHandler({ payload }) {
  yield put(getUserRequest())
  const { success } = yield race({
    success: take(GET_USER_REQUEST_SUCCESS),
    failure: take(GET_USER_REQUEST_FAILURE),
  })

  const userIdentityId = success?.payload?.identityId || ''
  analytics.trackSessionCreate({ ...payload, userIdentityId })
}

function* trackCommentCreateHandler({ payload }) {
  yield put(getUserRequest())
  const { success } = yield race({
    success: take(GET_USER_REQUEST_SUCCESS),
    failure: take(GET_USER_REQUEST_FAILURE),
  })

  const userIdentityId = success?.payload?.identityId || ''
  analytics.trackCommentCreate({ ...payload, userIdentityId })
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(TRACK_LOGIN_REQUEST, trackLogin),
    yield takeEvery(TRACK_SIGN_UP_REQUEST, trackSignUp),
    yield takeEvery(TRACK_SESSION_VIEW, trackSessionViewHandler),
    yield takeEvery(TRACK_PROJECT_VIEW, trackProjectViewHandler),
    yield takeEvery(TRACK_PROJECT_CREATE, trackProjectCreateHandler),
    yield takeEvery(TRACK_SESSION_CREATE, trackSessionCreateHandler),
    yield takeEvery(TRACK_COMMENT_CREATE, trackCommentCreateHandler),
  ])
}
