import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { loginRequest } from '../../../store/actions'
import LoginPage from './LoginPage'
import { setUserLogged } from '../../../store/user/user.actions'

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoginSubmit: userData => dispatch(loginRequest(userData)),
  onGoogleSignInComplete: () => {
    dispatch(setUserLogged())
    if (ownProps?.onGoogleSignInComplete) {
      ownProps.onGoogleSignInComplete()
    }
  },
})

const LoginPageContainer = ({ ...props }) => {
  const { email_verification: emailVerification } = queryString.parse(
    window.location.search
  )

  return <LoginPage emailVerification={emailVerification} {...props} />
}

export default connect(
  null,
  mapDispatchToProps
)(LoginPageContainer)
