import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStopwatch } from './useStopwatch'
import TimePart from './TimePart'
import { timeUnits } from './utils'

const [hours, minutes, seconds, milliseconds] = timeUnits
const StopWatch = ({
  hideHours,
  hideMinutes,
  hideSeconds,
  hideMilliseconds,
  separators,
  isStopped,
  ...props
}) => {
  const { startHandler, pauseHandler, time } = useStopwatch()
  useEffect(() => {
    if (isStopped) {
      pauseHandler()
    } else {
      startHandler()
    }
  }, [isStopped])

  return (
    <div {...props}>
      <div>
        <TimePart
          show={!hideHours}
          separator={separators && separators[0]}
          type={hours}
          value={time.hours}
        />
        <TimePart
          show={!hideMinutes}
          separator={hideSeconds ? '' : separators && separators[1]}
          type={minutes}
          value={time.minutes}
        />
        <TimePart
          show={!hideSeconds}
          separator={hideMilliseconds ? '' : separators && separators[2]}
          type={seconds}
          value={time.seconds}
        />
        <TimePart
          show={!hideMilliseconds}
          separator={null}
          type={milliseconds}
          value={time.milliseconds}
        />
      </div>
    </div>
  )
}

StopWatch.propTypes = {
  isStopped: PropTypes.bool,
  hideHours: PropTypes.bool, // hide hours
  hideMinutes: PropTypes.bool, // hide minutes
  hideSeconds: PropTypes.bool, // hide seconds
  hideMilliseconds: PropTypes.bool, // hide milliseconds
  separators: PropTypes.arrayOf(PropTypes.string), // separators to use between hours/minutes/seconds/milliseconds
  // separator use example: [":"/*separatorBetweenHoursAndMinutes*/, ":"/*separatorBetweenMinutesAndSeconds*/, "."/*separatorBetweenSecondsAndMilliseconds*/]
}

StopWatch.defaultProps = {
  isStopped: false,
  hideHours: true,
  hideMinutes: false,
  hideSeconds: false,
  hideMilliseconds: true,
  separators: [':', ':'],
}

export default StopWatch
