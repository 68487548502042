import { set, merge } from 'dot-prop-immutable'
import get from 'lodash/get'
import filter from 'lodash/filter'
import map from 'lodash/map'
import pipe from 'lodash/fp/pipe'
import concat from 'lodash/fp/concat'
import getOr from 'lodash/fp/getOr'
import moment from 'moment'
import { buildReducerForActions } from '../../util/reduxTools'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import {
  SET_CURRENT_PROJECT,
  GET_PROJECT_REQUEST_SUCCESS,
  UPDATE_PROJECT_REQUEST_SUCCESS,
  UPSERT_PROJECT,
  UPDATE_PROJECTS_REQUEST_SUCCESS,
  GET_PROJECTS_REQUEST_SUCCESS,
  SET_PROJECT_TITLE,
} from './projects.actions'

export const STATE_KEY = 'projects'

const getHumanReadableDate = momentDate => momentDate.format('lll')

export const INITIAL_STATE = {
  current: '',
  byId: {},
  allIds: [],
}

const upsertProject = (state, project) => {
  const { pk } = project
  const isProjectPresent = !!get(state, `byId.${pk}`)
  const newState = set(state, `byId.${pk}`, project)
  if (!isProjectPresent) {
    const newIds = pipe(
      getOr({}, 'allIds'),
      concat(pk)
    )(state)
    return set(newState, 'allIds', newIds)
  }
  return newState
}

const updateProjects = (
  state,
  { payload: { organizationId, projects = [] } }
) => {
  const byId = filter(
    state.byId,
    x => x.organizationId !== organizationId
  ).reduce((acc, project) => {
    acc[project.pk] = project
    return acc
  }, {})
  const allIds = map(byId, x => x.pk)
  const newState = projects.reduce(
    (acc, project) => {
      const creationDate = moment.unix(project.createdAt)
      const updationDate = moment.unix(project.updatedAt)
      return {
        byId: {
          ...acc.byId,
          [project.pk]: {
            ...project,
            createdAtHuman: getHumanReadableDate(creationDate),
            updatedAtHuman: getHumanReadableDate(updationDate),
          },
        },
        allIds: [...acc.allIds, project.pk],
      }
    },
    { byId, allIds }
  )
  return { ...state, byId: newState.byId, allIds: newState.allIds }
}

const reducer = {
  [SET_CURRENT_PROJECT]: (state, { payload: { projectId } }) =>
    set(state, 'current', projectId),
  [GET_PROJECT_REQUEST_SUCCESS]: (state, { payload: { project } }) =>
    upsertProject(state, project),
  [UPDATE_PROJECT_REQUEST_SUCCESS]: (
    state,
    { payload: { projectId, updatedAt, ...project } }
  ) => {
    const date = moment.unix(updatedAt)
    return merge(state, `byId.${projectId}`, {
      ...project,
      updatedAt,
      updatedAtHuman: getHumanReadableDate(date),
    })
  },
  [UPSERT_PROJECT]: (state, { payload: { project } }) =>
    upsertProject(state, project),
  [UPDATE_PROJECTS_REQUEST_SUCCESS]: updateProjects,
  [GET_PROJECTS_REQUEST_SUCCESS]: updateProjects,
  [SET_PROJECT_TITLE]: (state, { payload: { projectId, title } }) => {
    const newState = set(state, `byId.${projectId}.title`, title)
    return newState
  },
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
