import React from 'react'
import PropTypes from 'prop-types'
import ProjectShareModal from './ProjectShareModal'
import ProjectShareModalMobile from './ProjectShareModalMobile'
import useShareModal from '../ShareModal/useShareModal'
import { isMobile } from '../../../lib/platform'

const getResourceName = (sessionId, projectId, remoteTestId) => {
  if (sessionId) {
    return 'recording'
  }

  if (projectId) {
    return 'project'
  }

  if (remoteTestId) {
    return 'remote test'
  }

  return ''
}

const ShareModalContainer = ({
  sessionId,
  projectId,
  remoteTestId,
  isVisible,
  onClose,
  onCreateWorkspace,
}) => {
  const {
    areCommentsAllowedState,
    copyButtonText,
    enrichedUsers,
    isCurrentUserOwner,
    isFormLoading,
    isLogged,
    isModalVisible,
    isPublicState,
    isUserContributor,
    onModalClose,
    onAddUser,
    onRemoveUser,
    session,
    setCommentsAllowed,
    setCopyButtonText,
    setIsPublic,
    shareableLink,
    title,
    updateSessionSharing,
    isShareButtonEnabled,
    isPersonalWorkspace,
    setShareValue,
    totalWorkspaceMembers,
    totalContributors,
    workspaceName,
    shareType,
    onUpdateProjectShareTypeHandler,
    projectOwnerUser,
  } = useShareModal({
    sessionId,
    projectId,
    remoteTestId,
    isVisible,
    onClose,
  })

  const resourceName = getResourceName(sessionId, projectId, remoteTestId)

  const props = {
    areCommentsAllowedState,
    copyButtonText,
    enrichedUsers,
    isCurrentUserOwner,
    isFormLoading,
    isLogged,
    isModalVisible,
    isPersonalWorkspace,
    isPublicState,
    isShareButtonEnabled,
    isUserContributor,
    onAddUser,
    onClose,
    onCreateWorkspace,
    onModalClose,
    onRemoveUser,
    projectId,
    remoteTestId,
    resourceName,
    session,
    sessionId,
    setCommentsAllowed,
    setCopyButtonText,
    setIsPublic,
    setShareValue,
    shareableLink,
    title,
    totalContributors,
    totalWorkspaceMembers,
    updateSessionSharing,
    workspaceName,
    shareType,
    onUpdateProjectShareTypeHandler,
    projectOwnerUser,
  }

  return isMobile() ? (
    <ProjectShareModalMobile {...props} />
  ) : (
    <ProjectShareModal {...props} />
  )
}

ShareModalContainer.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateWorkspace: PropTypes.func,
  sessionId: PropTypes.string,
  projectId: PropTypes.string,
  remoteTestId: PropTypes.string,
}

ShareModalContainer.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onCreateWorkspace: () => {},
  sessionId: '',
  projectId: '',
  remoteTestId: '',
}

export default ShareModalContainer
