import { useEffect, useState } from 'react'
import api from '../../api'

const usePosters = sessionIds => {
  const [posters, setPosters] = useState([])

  let unmount = false
  useEffect(() => {
    const getPosters = () => {
      if (sessionIds.length > 0) {
        api.getPosters(sessionIds).then(({ data }) => {
          if (!unmount) {
            setPosters(data.posters)
          }
        })
      }
    }
    getPosters()
    const postersInterval = setInterval(() => getPosters(), 3 * 60 * 1000)

    return () => {
      clearInterval(postersInterval)
      unmount = true
    }
  }, [sessionIds.length])

  return {
    posters,
  }
}

export default usePosters
