import { useEffect, useState } from 'react'

export const getTopNotch = () => {
  const div = document.createElement('div')
  div.style.paddingTop = 'env(safe-area-inset-top)'
  document.body.appendChild(div)
  const topNotch = div.clientHeight
  document.body.removeChild(div)
  return topNotch
}

export const getBottomNotch = () => {
  const div = document.createElement('div')
  div.style.paddingTop = 'env(safe-area-inset-bottom)'
  document.body.appendChild(div)
  const bottomNotch = div.clientHeight
  document.body.removeChild(div)
  return bottomNotch
}

const useNotch = () => {
  const [topNotch, setTopNotch] = useState(0)
  const [bottomNotch, setBottomNotch] = useState(0)

  useEffect(() => {
    setTopNotch(getTopNotch())
    setBottomNotch(getBottomNotch())
  }, [])

  return {
    topNotch,
    bottomNotch,
  }
}

export default useNotch
