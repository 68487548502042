import React from 'react'
import styled from 'styled-components'
import ReloadIcon from 'mdi-react/ReloadIcon'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import EmptyStateMobile from '../EmptyStateMobile'
import { unregister } from '../../../serviceWorker'

const StyledEmptyState = styled(EmptyStateMobile)`
  width: 100vw;
  height: 100vh;
  align-self: center;
`

const FallbackErrorView = () => (
  <StyledEmptyState
    icon={<ReloadIcon size={32} />}
    button={{
      text: 'Reload',
      onClick: () => {
        window.location.reload()
      },
    }}
    secondaryButton={{
      text: 'Logout',
      onClick: () => {
        try {
          localStorage.clear()
          sessionStorage.clear()
          unregister()
        } catch (err) {
          console.error('Error while logging out from the error boundary', err)
        }
        setTimeout(() => {
          window.location.pathname = '/'
        }, 10)
      },
    }}
  >
    Ops, an error occurred.
  </StyledEmptyState>
)

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={FallbackErrorView}>
    {children}
  </Sentry.ErrorBoundary>
)

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
