import get from 'lodash/get'
import {
  all,
  call,
  delay,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import gql from 'graphql-tag'
import {
  trelloIntegration as trelloIntegrationGql,
  updateTrelloIntegration as updateTrelloIntegrationGql,
} from '../../../graphql/queries'
import {
  createTrelloIssue as createTrelloIssueGql,
  deleteTrello as removeTrelloGql,
} from '../../../graphql/mutations'
import {
  GET_TRELLO_REQUEST,
  getTrelloRequestSuccess,
  getTrelloRequestFailure,
  UPDATE_TRELLO_REQUEST,
  updateTrelloRequestSuccess,
  updateTrelloRequestFailure,
  CREATE_TRELLO_REQUEST,
  createTrelloRequestSuccess,
  createTrelloRequestFailure,
  REMOVE_TRELLO_REQUEST,
  removeTrelloRequestSuccess,
  removeTrelloRequestFailure,
} from './integrations.trello.actions'
import { LOGIN_REQUEST_SUCCESS } from '../../actions'
import { activityBasedAction } from '../../utils.sagas'
import analytics from '../../../lib/analytics'

export function* fetchTrello({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(trelloIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.trelloIntegration')
    yield put(getTrelloRequestSuccess(data))
  } catch (e) {
    yield put(getTrelloRequestFailure(e))
    console.error(e)
  }
}

function* updateTrello({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(updateTrelloIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.updateTrelloIntegration')
    yield put(updateTrelloRequestSuccess(data))
  } catch (e) {
    yield put(updateTrelloRequestFailure(e))
    console.error(e)
  }
}

function* startUpdateInterval({ apolloClient }) {
  while (true) {
    try {
      yield call(updateTrello, { apolloClient })
    } catch (e) {
      console.error(e)
    }
    yield delay(3000 * 60)
  }
}

function* startTrelloFork({ apolloClient }) {
  yield call(fetchTrello, { apolloClient })

  yield fork(activityBasedAction, fetchTrello, 26000, { apolloClient })
  yield fork(startUpdateInterval, { apolloClient })
}

function* createTrello(
  { apolloClient },
  {
    payload: { title, description, listId, boardId, sourceUrl, sessionId },
    meta: { thunk },
  }
) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(createTrelloIssueGql),
      variables: {
        title,
        description,
        listId,
        boardId,
        sourceUrl,
        sessionId,
      },
    })

    const trelloIssue = get(result, 'data.createTrelloIssue')
    yield put(createTrelloRequestSuccess(trelloIssue, thunk))
    try {
      analytics.trackCreateTrelloCard()
    } catch (error) {
      console.error(error)
    }
  } catch (e) {
    yield put(createTrelloRequestFailure(e, thunk))
    console.error(e)
  }
}

function* removeTrello({ apolloClient }, { meta: { thunk } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(removeTrelloGql),
    })

    yield put(removeTrelloRequestSuccess({}, thunk))
    try {
      analytics.trackDisconnectTrello()
    } catch (error) {
      console.error(error)
    }
  } catch (e) {
    yield put(removeTrelloRequestFailure(e, thunk))
    console.error(e)
  }
}

export default function* trelloSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startTrelloFork, { apolloClient }),
    yield takeLatest(GET_TRELLO_REQUEST, fetchTrello, { apolloClient }),
    yield takeLatest(UPDATE_TRELLO_REQUEST, updateTrello, { apolloClient }),
    yield takeLatest(CREATE_TRELLO_REQUEST, createTrello, { apolloClient }),
    yield takeLatest(REMOVE_TRELLO_REQUEST, removeTrello, { apolloClient }),
  ])
}
