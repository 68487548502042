import axios from 'axios'
import slice from 'lodash/slice'
import last from 'lodash/last'

export const formatAuthorName = name => {
  const [firstName, lastName] = name.split(' ')
  if (!lastName) {
    return firstName
  }

  return `${firstName} ${lastName[0]}.`
}

export const formatAuthorsNames = authorNames => {
  const start = slice(authorNames, 0, 2)
  const end = slice(authorNames, 2)
  const names = start.map(name => formatAuthorName(name))
  return {
    authorNames: names,
    additionalAuthorsNumber: end.length,
  }
}

export const checkIfVideoExists = url => axios.head(url)

export const filterEmptyComments = comments =>
  comments.filter(comment => {
    return comment || false
  })

export const addAnonymousUserToComments = comments =>
  comments.map(comment => ({
    ...comment,
    owner: { ...(comment?.owner || {}), name: 'Anonymous' },
  }))

export const sortCommentsByPositionFn = (a, b) =>
  a.videoPositionSecs - b.videoPositionSecs

export const sortCommentsByPosition = comments =>
  comments.sort(sortCommentsByPositionFn)

export const addContinueButtonBreakPointsToSortedComments = timeIntervalSeconds => comments => {
  let lastCommentSecs = null
  const finalComments = []
  comments.forEach((comment, index) => {
    if (index === 0) {
      lastCommentSecs = comment.videoPositionSecs
      if (comments.length === 1) {
        finalComments.push({ ...comment, continueButtonBreakPoint: true })
        return
      }
      finalComments.push(comment)
      return
    }

    if (comment.videoPositionSecs >= lastCommentSecs + timeIntervalSeconds) {
      lastCommentSecs = comment.videoPositionSecs
      const lastComment = last(finalComments)
      finalComments[finalComments.length - 1] = {
        ...lastComment,
        continueButtonBreakPoint: true,
      }
    }

    lastCommentSecs = comment.videoPositionSecs

    if (index === comments.length - 1) {
      lastCommentSecs = comment.videoPositionSecs
      finalComments.push({
        ...comment,
        continueButtonBreakPoint: true,
      })
      return
    }
    finalComments.push(comment)
  })

  return finalComments
}
