import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'
import PageEvent from './PageEvent'
import { PAGE_EVENT_TYPES } from './pageEvents.constants'
import { FILTER_OPTIONS } from '../../molecules/PageEventsFilter/PageEventsFilter'

const Wrapper = styled(Flex)`
  width: 100%;
  overflow: auto;
`

const StyledPageEvent = styled(PageEvent)`
  margin: 2px 0;
  cursor: pointer;
`

const shouldShowPageEvent = (filters, eventType) => {
  if (
    (eventType === PAGE_EVENT_TYPES.consoleLog ||
      eventType === PAGE_EVENT_TYPES.consoleDebug ||
      eventType === PAGE_EVENT_TYPES.consoleInfo) &&
    filters.includes(FILTER_OPTIONS.consoleLog)
  ) {
    return true
  }

  if (
    (eventType === PAGE_EVENT_TYPES.consoleError ||
      eventType === PAGE_EVENT_TYPES.error) &&
    filters.includes(FILTER_OPTIONS.consoleError)
  ) {
    return true
  }

  if (
    eventType === PAGE_EVENT_TYPES.consoleWarn &&
    filters.includes(FILTER_OPTIONS.consoleWarn)
  ) {
    return true
  }

  if (
    eventType === PAGE_EVENT_TYPES.navigation &&
    filters.includes(FILTER_OPTIONS.navigation)
  ) {
    return true
  }

  if (
    eventType === PAGE_EVENT_TYPES.click &&
    filters.includes(FILTER_OPTIONS.click)
  ) {
    return true
  }

  if (
    eventType === PAGE_EVENT_TYPES.webRequest &&
    filters.includes(FILTER_OPTIONS.webRequest)
  ) {
    return true
  }

  if (
    eventType === PAGE_EVENT_TYPES.webErrorRequest &&
    filters.includes(FILTER_OPTIONS.webErrorRequest)
  ) {
    return true
  }

  return false
}

const keyDownHandler = wrapperRef => e => {
  if (
    e.code === 'Space' &&
    (wrapperRef.current.contains(document.activeElement) ||
      e.target === document.body)
  ) {
    e.preventDefault()
  }
}

const PageEvents = ({ events, onClick, filters, ...props }) => {
  const wrapperRef = useRef(null)
  useEffect(() => {
    const handler = keyDownHandler(wrapperRef)
    window.addEventListener('keydown', handler)

    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [])

  return (
    <Wrapper {...props} ref={wrapperRef}>
      {events.map(
        event =>
          shouldShowPageEvent(filters, event.type) && (
            <StyledPageEvent
              key={event.videoTime}
              eventType={event.type}
              textNode={event.textNode}
              isCurrent={event.isCurrent}
              onClick={() => onClick(event.videoTime)}
            />
          )
      )}
    </Wrapper>
  )
}

PageEvents.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
}

PageEvents.defaultProps = {
  filters: Object.values(FILTER_OPTIONS),
  onClick: () => {},
}

export default PageEvents
