import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../Flex'

const Wrapper = styled(Flex)`
  color: ${prop('theme.colors.primary')};
  flex-direction: row;
`

const Left = styled(Flex)`
  width: auto;
  justify-content: flex-start;
  margin-right: 16px;
`

const STEP_WIDTH = 80
const Right = styled(Flex)`
  width: ${STEP_WIDTH}px;
  justify-content: flex-end;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`

const SHUFFLE_STEP_WIDTH = 24
const Step = styled(Flex)`
  height: 8px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${prop('theme.colors.grey200')};
  position: relative;
  width: ${STEP_WIDTH}px;
  margin-right: 8px;

  :last-child {
    margin-right: 0;
  }
`

const shuffleKeyframes = keyframes`
  0% {
    left: 0;
    width: ${SHUFFLE_STEP_WIDTH}px;
  }

  50% {
  width: ${SHUFFLE_STEP_WIDTH + 8}px;
  }

  100% {
    left: ${STEP_WIDTH - SHUFFLE_STEP_WIDTH}px;
    width: ${SHUFFLE_STEP_WIDTH}px;
  }
`

const StepProgress = styled(Flex)`
  height: 8px;
  border-radius: none;
  background-color: ${prop('theme.colors.green')};
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ percentage }) => (STEP_WIDTH / 100) * percentage}px;
  ${({ shuffle }) =>
    shuffle
      ? css`
          width: 64px;
          animation: 1.4s ${shuffleKeyframes} ease-in-out infinite alternate;
        `
      : ''}
`

const UploadProgress = ({ percentage, ...props }) => (
  <Wrapper {...props}>
    <Left>Uploading</Left>
    <Right>
      <Step>
        <StepProgress percentage={percentage} />
      </Step>
    </Right>
  </Wrapper>
)

UploadProgress.propTypes = {
  percentage: PropTypes.number,
}

UploadProgress.defaultProps = {
  percentage: 0,
}

export default UploadProgress
