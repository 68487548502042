import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import useNotch from '../../hooks/useNotch'

export const BAR_HEIGHT = 52

const Wrapper = styled(Flex)`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
`

const Header = styled(Flex)`
  order: 0;
  flex: 0 1 ${BAR_HEIGHT}px;
  align-self: auto;
  height: ${BAR_HEIGHT}px;
  justify-items: center;
  padding-top: ${prop('paddingTop')}px;
`

const Body = styled(Flex).attrs({
  flexGrow: 1,
})`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  overflow-y: scroll;
  padding-top: ${prop('paddingTop')}px;
  padding-bottom: ${prop('paddingBottom')}px;
`

const Footer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: flex-end;
  padding-bottom: ${prop('paddingBottom')}px;
`

const MobileTemplate = ({
  header,
  body,
  footer,
  bodyRef,
  bodyStyle,
  ...props
}) => {
  const { topNotch, bottomNotch } = useNotch()
  return (
    <Wrapper {...props}>
      {!!header && <Header paddingTop={topNotch}>{header}</Header>}
      {!!body && (
        <Body
          ref={bodyRef}
          paddingTop={header ? topNotch : 0}
          paddingBottom={footer ? bottomNotch : 0}
          style={bodyStyle}
        >
          {body}
        </Body>
      )}
      {!!footer && <Footer paddingBottom={bottomNotch}>{footer}</Footer>}
    </Wrapper>
  )
}

MobileTemplate.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  bodyRef: PropTypes.shape({}),
  bodyStyle: PropTypes.shape({}),
}

MobileTemplate.defaultProps = {
  header: null,
  body: null,
  footer: null,
  bodyRef: null,
  bodyStyle: {},
}

export default MobileTemplate
