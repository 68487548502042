import React, { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Tippy from '@tippyjs/react'
import {
  Header,
  HeaderItem,
  HeaderItemWrapper,
} from '@zendeskgarden/react-chrome'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Tooltip } from '@zendeskgarden/react-tooltips'
import { Tag } from '@zendeskgarden/react-tags'
import InformationIcon from 'mdi-react/InformationIcon'
import Logo from '../../atoms/Logo'
import Anchor from '../../atoms/Anchor'
import ActivityIcon from '../../molecules/ActivityIcon'
import UserSettingsMenu from '../../molecules/UserSettingsMenu'
import SessionSettings from '../../molecules/SessionCard/SessionSettings'
import Badge from '../../molecules/Badge'
import { HEADER_HEIGHT } from '../../pages/MainAppPage/MainAppPage.constants'
import UploadProgress from '../../atoms/UploadProgress'
import TemporaryRecordingCallToAction from '../../molecules/TemporaryRecordingCallToAction'
import GoogleChromeIcon from '../../../assets/chrome_icon_coloured.svg'
import EdgeIcon from '../../../assets/edge_icon_coloured.svg'
import SlackIcon from '../../../assets/Slack_Mark_Monochrome_Grey.svg'
import IconButton from '../../molecules/IconButton'
import ActivitiesList from '../ActivitiesList'
import Flex from '../../atoms/Flex'
import { openExtensionPage } from '../../../lib/browserExtension'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const StyledHeader = styled(Header)`
  flex: 0 0 ${HEADER_HEIGHT}px;
  order: 0;
  z-index: 100;
  box-shadow: ${prop('theme.boxShadows.topBarElevation')};
`

const StyledLogo = styled(Logo)`
  width: 32px;
  margin-right: 24px;
  cursor: pointer;
`

const ResourceTitle = styled(HeaderItem)`
  color: ${prop('theme.colors.grey600')};
  margin: 0;
  cursor: text;
  user-select: text;
  flex-grow: 1;

  &&:hover,
  &&:focus {
    color: ${prop('theme.colors.grey600')};
  }
`

const ActivityHeaderItem = styled(HeaderItem)`
  margin: 0 0 0 16px;
  padding-left: 16px;
  border-left: 1px solid ${prop('theme.colors.grey200')};
  border-radius: 0;
`

const StyledUserSettingsMenu = styled(UserSettingsMenu)`
  padding: 0;
  margin: 0 8px;
`

const StyledSessionSettings = styled(SessionSettings)`
  display: inline-flex;
  order: 1;

  && {
    position: static;
    width: 32px;
    height: 32px;
    flex-basis: 32px;
  }

  svg {
    transform: rotate(90deg);
  }
`

const StyledBadge = styled(Badge)`
  cursor: pointer;
`

const ProjectAnchor = styled(Anchor)`
  margin-right: 6px;

  && {
    color: ${prop('theme.colors.grey900')};
    text-decoration: none;
  }

  &&:hover {
    color: ${prop('theme.colors.grey900')};
    text-decoration: underline;
  }
`

const StyledSessionTitle = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  line-height: 28px;
  height: 28px;
  max-height: 28px;
  justify-content: center;
  align-items: flex-start;
  margin-left: 6px;
`

const StyledInput = styled(Input)`
  font-size: 14px;
  line-height: 28px;
  display: flex;
`

const ExtensionIconButton = styled(IconButton)`
  border: 1px solid ${prop('theme.colors.secondary')} !important;
`

const SlackIconButton = styled(IconButton)`
  border-radius: 50%;
`

const SignInButton = styled(Button)`
  height: 32px;
`

const UploadBox = styled(Flex)`
  flex-direction: row;
  border: 1px solid ${prop('theme.colors.grey300')};
  padding: 0 16px;
  border-radius: 4px;
`

const DontCloseMessage = styled(Flex)`
  flex-direction: row;
  margin-left: 16px;
  align-items: center;
  align-self: center;
  justify-content: center;
  font-style: italic;
  color: ${prop('theme.colors.grey600')};

  svg {
    fill: ${prop('theme.colors.grey300')};
    cursor: pointer;
  }

  [role='tooltip'] {
    font-style: normal;
  }
`

const SessionTopBar = ({
  isUserLogged,
  isPublicSession,
  isUserContributor,
  isCurrentUserOwner,
  isTemporaryRecording,
  numberOfContributors,
  onDelete,
  onGoToHome,
  onGoToProject,
  onLogin,
  onLogout,
  onMoveTo,
  onRegister,
  onSettings,
  onShare,
  onCopy,
  onSessionTitleUpdate,
  onJiraClick,
  onTrelloClick,
  onSlackClick,
  projectTitle,
  projectUrl,
  sessionTitle,
  defaultSessionTitle,
  userName,
  userCanContribute,
  sessionId,
  projectId,
  isUploadingVideo,
  videoUploadProgress,
  isExtensionInstalled,
  isChromeBrowser,
  isEdgeBrowser,
  unreadNotificationsCount,
  onMarkAllNotificationsAsRead,
  hasSlack,
  ...props
}) => {
  const sessionTitleRef = useRef(null)

  return (
    <StyledHeader {...props}>
      <HeaderItemWrapper maxX>
        <StyledLogo onClick={onGoToHome} />
        <ResourceTitle>
          {!isTemporaryRecording && projectTitle && projectId && (
            <>
              <ProjectAnchor href={projectUrl} onClick={onGoToProject}>
                {projectTitle}
              </ProjectAnchor>{' '}
              /{' '}
            </>
          )}
          <StyledSessionTitle>
            <StyledInput
              ref={sessionTitleRef}
              data-test="recording-session-title"
              placeholder="Recording title"
              defaultValue={sessionTitle || defaultSessionTitle}
              isBare
              readOnly={!userCanContribute || isTemporaryRecording}
              onBlur={() => {
                const title = sessionTitleRef.current.value
                onSessionTitleUpdate(title)
              }}
            />
          </StyledSessionTitle>
        </ResourceTitle>
      </HeaderItemWrapper>
      {!isExtensionInstalled && (isChromeBrowser || isEdgeBrowser) && (
        <HeaderItem style={{ marginRight: '8px' }}>
          <ExtensionIconButton
            isSecondary
            size="small"
            iconProps={{
              style: {
                width: '16px',
                height: '16px',
              },
            }}
            icon={isChromeBrowser ? GoogleChromeIcon : EdgeIcon}
            onClick={() => openExtensionPage()}
          >
            Create your sharable recordings
          </ExtensionIconButton>
        </HeaderItem>
      )}
      {isUploadingVideo && !isTemporaryRecording && (
        <HeaderItem style={{ marginRight: '4px' }}>
          <UploadBox>
            <UploadProgress percentage={videoUploadProgress} />
            <DontCloseMessage>
              <Flex style={{ marginRight: '4px' }}>
                Don&#39;t close the window
              </Flex>
              <Tooltip
                type="light"
                placement="bottom"
                content="Your teammates won&#39;t be able to see your recording until the upload is complete"
                zIndex={1000}
              >
                <Flex>
                  <InformationIcon size="16" />
                </Flex>
              </Tooltip>
            </DontCloseMessage>
          </UploadBox>
        </HeaderItem>
      )}
      {isPublicSession && !userCanContribute && (
        <HeaderItem style={{ margin: '0 8px 0 8px' }}>
          <Tag>view only</Tag>
        </HeaderItem>
      )}
      {isUserLogged && hasSlack && !isTemporaryRecording && (
        <HeaderItem style={{ margin: '0', padding: '0' }}>
          <Tippy
            content="Send to Slack"
            placement="bottom"
            arrow={false}
            zIndex={10}
          >
            <span style={{ height: '32px' }}>
              <SlackIconButton
                isSecondary
                size="small"
                iconProps={{
                  style: {
                    width: '16px',
                    height: '16px',
                  },
                }}
                icon={SlackIcon}
                onClick={() => onSlackClick({ sessionId })}
              />
            </span>
          </Tippy>
        </HeaderItem>
      )}
      {isUserLogged && userCanContribute && !isTemporaryRecording && (
        <HeaderItem style={{ margin: '0 8px' }}>
          <Tippy content="Sharing" placement="bottom" arrow={false} zIndex={10}>
            <span style={{ height: '32px' }}>
              <StyledBadge
                text={numberOfContributors}
                isPrivate={!numberOfContributors}
                isPublic={isPublicSession}
                onClick={onShare}
              />
            </span>
          </Tippy>
        </HeaderItem>
      )}
      {isUserLogged && userCanContribute && !isTemporaryRecording && (
        <StyledSessionSettings
          isVisible
          sessionId={sessionId}
          showDelete={false}
          showMove={isUserContributor || isCurrentUserOwner}
          onShare={onShare}
          onCopy={onCopy}
          onJiraClick={() => onJiraClick({ sessionId })}
          onTrelloClick={() => onTrelloClick({ sessionId })}
          onSlackClick={() => onSlackClick({ sessionId })}
        />
      )}
      {isUserLogged && (
        <ActivityHeaderItem>
          <ActivityIcon
            count={unreadNotificationsCount}
            notificationItems={<ActivitiesList />}
            onMarkAllAsReadClick={onMarkAllNotificationsAsRead}
          />
        </ActivityHeaderItem>
      )}
      {isUserLogged && (
        <StyledUserSettingsMenu
          userName={userName}
          onSettings={onSettings}
          onLogout={onLogout}
        />
      )}
      {!isUserLogged && isTemporaryRecording && (
        <HeaderItem>
          <TemporaryRecordingCallToAction onClick={onRegister} />
        </HeaderItem>
      )}
      {!isUserLogged && !isTemporaryRecording && (
        <React.Fragment>
          {/* 
          <HeaderItem onClick={onLogin} style={{ cursor: 'pointer' }}>
            <HeaderItemText>Login</HeaderItemText>
          </HeaderItem> 
          */}
          <HeaderItem onClick={onRegister} style={{ cursor: 'pointer' }}>
            <SignInButton isPrimary size="small">
              Sign in
            </SignInButton>
          </HeaderItem>
        </React.Fragment>
      )}
    </StyledHeader>
  )
}

SessionTopBar.propTypes = {
  isUserLogged: PropTypes.bool,
  isPublicSession: PropTypes.bool,
  isUserContributor: PropTypes.bool,
  isCurrentUserOwner: PropTypes.bool,
  isTemporaryRecording: PropTypes.bool,
  userName: PropTypes.string,
  userCanContribute: PropTypes.bool,
  numberOfContributors: PropTypes.number,
  onDelete: PropTypes.func,
  onGoToHome: PropTypes.func,
  onGoToProject: PropTypes.func,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  onMoveTo: PropTypes.func,
  onRegister: PropTypes.func,
  onSettings: PropTypes.func,
  onShare: PropTypes.func,
  onCopy: PropTypes.func,
  onSessionTitleUpdate: PropTypes.func,
  onJiraClick: PropTypes.func,
  onTrelloClick: PropTypes.func,
  onSlackClick: PropTypes.func,
  projectTitle: PropTypes.string,
  projectUrl: PropTypes.string,
  sessionTitle: PropTypes.string,
  defaultSessionTitle: PropTypes.string,
  sessionId: PropTypes.string,
  projectId: PropTypes.string,
  isUploadingVideo: PropTypes.bool,
  videoUploadProgress: PropTypes.number,
  isExtensionInstalled: PropTypes.bool,
  isChromeBrowser: PropTypes.bool,
  isEdgeBrowser: PropTypes.bool,
  hasSlack: PropTypes.bool,
  unreadNotificationsCount: PropTypes.number,
  onMarkAllNotificationsAsRead: PropTypes.func,
}

SessionTopBar.defaultProps = {
  isUserLogged: false,
  isPublicSession: false,
  isUserContributor: false,
  isCurrentUserOwner: false,
  isTemporaryRecording: false,
  userName: '',
  userCanContribute: false,
  numberOfContributors: null,
  onDelete: () => {},
  onGoToHome: () => {},
  onGoToProject: () => {},
  onLogin: () => {},
  onLogout: () => {},
  onMoveTo: () => {},
  onRegister: () => {},
  onSettings: () => {},
  onShare: () => {},
  onCopy: () => {},
  onSessionTitleUpdate: () => {},
  onJiraClick: () => {},
  onTrelloClick: () => {},
  onSlackClick: () => {},
  projectTitle: null,
  sessionTitle: null,
  defaultSessionTitle: '',
  sessionId: null,
  projectId: null,
  projectUrl: null,
  isUploadingVideo: false,
  videoUploadProgress: null,
  isExtensionInstalled: true,
  isChromeBrowser: false,
  isEdgeBrowser: false,
  hasSlack: false,
  unreadNotificationsCount: 0,
  onMarkAllNotificationsAsRead: () => {},
}

export default SessionTopBar
