const NAMESPACE = 'COMMENT'

export const GET_SESSION_COMMENTS = `${NAMESPACE}/GET_SESSION_COMMENTS`
export const GET_SESSION_COMMENTS_REQUEST = `${GET_SESSION_COMMENTS}/REQUEST`
export const GET_SESSION_COMMENTS_REQUEST_SUCCESS = `${GET_SESSION_COMMENTS_REQUEST}/REQUEST_SUCCESS`
export const GET_SESSION_COMMENTS_REQUEST_FAILURE = `${GET_SESSION_COMMENTS_REQUEST}/REQUEST_FAILURE`

export const getSessionCommentsRequest = sessionId => ({
  type: GET_SESSION_COMMENTS_REQUEST,
  payload: { sessionId },
  meta: { thunk: GET_SESSION_COMMENTS_REQUEST },
})

export const getSessionCommentsRequestSuccess = (payload, thunk) => ({
  type: GET_SESSION_COMMENTS_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const getSessionCommentsRequestFailure = (e, thunk) => ({
  type: GET_SESSION_COMMENTS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_COMMENT = `${NAMESPACE}/CREATE`
export const CREATE_COMMENT_REQUEST = `${CREATE_COMMENT}/REQUEST`
export const CREATE_COMMENT_REQUEST_SUCCESS = `${CREATE_COMMENT_REQUEST}/REQUEST_SUCCESS`
export const CREATE_COMMENT_REQUEST_FAILURE = `${CREATE_COMMENT_REQUEST}/REQUEST_FAILURE`

export const createCommentRequest = ({
  sessionId,
  text,
  videoPositionSecs,
  videoPoint,
  videoRectangle,
  mentions,
}) => ({
  type: CREATE_COMMENT_REQUEST,
  payload: {
    sessionId,
    text,
    videoPositionSecs,
    videoPoint,
    videoRectangle,
    mentions,
  },
  meta: { thunk: CREATE_COMMENT_REQUEST },
})

export const createCommentRequestSuccess = (
  { sessionId, text, videoPositionSecs, videoPoint, mentions },
  thunk
) => ({
  type: CREATE_COMMENT_REQUEST_SUCCESS,
  payload: { sessionId, text, videoPositionSecs, videoPoint, mentions },
  meta: { thunk },
})

export const createCommentRequestFailure = (e, thunk) => ({
  type: CREATE_COMMENT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_COMMENT = `${NAMESPACE}/DELETE`
export const DELETE_COMMENT_REQUEST = `${DELETE_COMMENT}/REQUEST`
export const DELETE_COMMENT_REQUEST_SUCCESS = `${DELETE_COMMENT_REQUEST}/REQUEST_SUCCESS`
export const DELETE_COMMENT_REQUEST_FAILURE = `${DELETE_COMMENT_REQUEST}/REQUEST_FAILURE`

export const deleteCommentRequest = commentId => ({
  type: DELETE_COMMENT_REQUEST,
  payload: { commentId },
  meta: { thunk: DELETE_COMMENT_REQUEST },
})

export const deleteCommentRequestSuccess = (payload, thunk) => ({
  type: DELETE_COMMENT_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteCommentRequestFailure = (e, thunk) => ({
  type: DELETE_COMMENT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_COMMENT = `${NAMESPACE}/UPDATE`
export const UPDATE_COMMENT_REQUEST = `${NAMESPACE}/UPDATE/REQUEST`
export const UPDATE_COMMENT_REQUEST_SUCCESS = `${UPDATE_COMMENT_REQUEST}/REQUEST_SUCCESS`
export const UPDATE_COMMENT_REQUEST_FAILURE = `${UPDATE_COMMENT_REQUEST}/REQUEST_FAILURE`

export const updateCommentRequest = comment => ({
  type: UPDATE_COMMENT_REQUEST,
  payload: comment,
  meta: { thunk: UPDATE_COMMENT_REQUEST },
})

export const updateCommentRequestSuccess = (payload, thunk) => ({
  type: UPDATE_COMMENT_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const updateCommentRequestFailure = (e, thunk) => ({
  type: UPDATE_COMMENT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const CREATE_COMMENT_RESOLUTION = `${NAMESPACE}/CREATE_RESOLUTION`
export const CREATE_COMMENT_RESOLUTION_REQUEST = `${CREATE_COMMENT_RESOLUTION}/REQUEST`
export const CREATE_COMMENT_RESOLUTION_REQUEST_SUCCESS = `${CREATE_COMMENT_RESOLUTION_REQUEST}/REQUEST_SUCCESS`
export const CREATE_COMMENT_RESOLUTION_REQUEST_FAILURE = `${CREATE_COMMENT_RESOLUTION_REQUEST}/REQUEST_FAILURE`

export const createCommentResolutionRequest = ({
  commentId,
  resolutionState,
}) => ({
  type: CREATE_COMMENT_RESOLUTION_REQUEST,
  payload: {
    commentId,
    resolutionState,
  },
  meta: { thunk: CREATE_COMMENT_RESOLUTION_REQUEST },
})

export const createCommentResolutionRequestSuccess = (payload, thunk) => ({
  type: CREATE_COMMENT_RESOLUTION_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const createCommentResolutionRequestFailure = (e, thunk) => ({
  type: CREATE_COMMENT_RESOLUTION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
