import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import Flex from '../../atoms/Flex'
import useUserInfo from '../../../store/hooks/useUserInfo'
import NameSettingModal from './NameSettingModal'

const Wrapper = styled(Flex)``

const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`
const Label = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 500;
`
const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`
const Name = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 300;
`
const Edit = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  margin-left: 16px;
`

const NameSetting = ({ ...props }) => {
  const { userName } = useUserInfo()
  const [isModalVisible, setModalVisible] = useState(false)
  return (
    <>
      <Wrapper {...props}>
        <SettingRow>
          <Label>Name</Label>
          <Edited>
            <Name>{userName}</Name>
            <Edit>
              <Button isBasic onClick={() => setModalVisible(true)}>
                Edit
              </Button>
            </Edit>
          </Edited>
        </SettingRow>
      </Wrapper>
      <NameSettingModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  )
}

NameSetting.propTypes = {
  name: PropTypes.string,
}

NameSetting.defaultProps = {
  name: '',
}

export default NameSetting
