import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import InformationIcon from 'mdi-react/InformationIcon'
import { Field, Label, Radio } from '@zendeskgarden/react-forms'
import { Tooltip } from '@zendeskgarden/react-tooltips'
import Flex from '../../atoms/Flex'
import Sharing from '../Sharing/Sharing'
import { SHARE_TYPES } from './ProjectShare.constants'

const Wrapper = styled(Flex)``

const NormalLabel = styled(Flex)`
  font-weight: normal;
  margin-bottom: 6px;
  flex-direction: row;
`

const SharingLabel = styled(NormalLabel)`
  margin-top: 8px;
`

const ShareOption = styled(Flex)`
  padding: 10px 12px;
  border: 1px solid ${prop('theme.colors.grey200')};
  border-radius: 4px;
  margin-bottom: 8px;
`

const StyledSharing = styled(Sharing)`
  margin-top: 16px;
`

const StyledInformationIcon = styled(InformationIcon)`
  fill: ${prop('theme.colors.grey300')};
`

const LaterMessage = styled(Flex)`
  font-style: italic;
  color: ${prop('theme.colors.grey400')};
`

const ProjectShareTypeSelection = ({
  currentShareType,
  enrichedUsers,
  isInviteButtonDisabled,
  onAddUser,
  onRemoveUser,
  setCurrentShareType,
  setEmailShareValue,
  showUserBox,
  totalContributors,
  totalWorkspaceMembers,
  workspaceName,
  showMembersRecap,
  isCreation,
  ...props
}) => {
  const plural = totalContributors === 1 ? '' : 's'

  const selectedMembersRadioLabel = useMemo(() => {
    if (currentShareType === SHARE_TYPES.anyone) {
      return `Only selected members of ${workspaceName}`
    }
    return `Only ${totalContributors} member${plural} of ${workspaceName}`
  }, [currentShareType, totalContributors, plural, workspaceName])

  return (
    <Wrapper {...props}>
      <SharingLabel>Select who can collaborate</SharingLabel>
      <ShareOption>
        <Field>
          <Radio
            name="share-type"
            value={SHARE_TYPES.anyone}
            checked={currentShareType === SHARE_TYPES.anyone}
            onChange={event => setCurrentShareType(event.target.value)}
            data-test="shared-with-anyone"
          >
            <Label style={{ width: '95%' }}>
              <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex>Anyone at {workspaceName}</Flex>
                {!isCreation && (
                  <Tooltip
                    placement="start"
                    type="light"
                    zIndex="10000"
                    content="This will affect the visibility of new and existing recordings inside the project"
                  >
                    <Flex>
                      <StyledInformationIcon size="16" />
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
            </Label>
          </Radio>
        </Field>
      </ShareOption>
      <ShareOption>
        <Field>
          <Radio
            name="share-type"
            value={SHARE_TYPES.selectedMembers}
            checked={currentShareType === SHARE_TYPES.selectedMembers}
            onChange={event => setCurrentShareType(event.target.value)}
            data-test="shared-with-selected-members"
          >
            <Label>{selectedMembersRadioLabel}</Label>
          </Radio>
        </Field>
        {showUserBox && currentShareType === SHARE_TYPES.selectedMembers && (
          <StyledSharing
            users={enrichedUsers}
            isInviteButtonDisabled={isInviteButtonDisabled}
            onInput={e => setEmailShareValue(e.target.value)}
            onAdd={userEmail => onAddUser(userEmail)}
            onRemove={userEmail => onRemoveUser(userEmail)}
            totalWorkspaceMembers={totalWorkspaceMembers}
            totalContributors={totalContributors}
            showMembersRecap={showMembersRecap}
          />
        )}
      </ShareOption>
      {isCreation && <LaterMessage>You can edit this later</LaterMessage>}
    </Wrapper>
  )
}

ProjectShareTypeSelection.propTypes = {
  currentShareType: PropTypes.oneOf(Object.values(SHARE_TYPES)),
  enrichedUsers: PropTypes.arrayOf(PropTypes.shape({})),
  isInviteButtonDisabled: PropTypes.bool,
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  setCurrentShareType: PropTypes.func,
  setEmailShareValue: PropTypes.func,
  showUserBox: PropTypes.bool,
  totalContributors: PropTypes.number,
  totalWorkspaceMembers: PropTypes.number,
  workspaceName: PropTypes.string,
  showMembersRecap: PropTypes.bool,
  isCreation: PropTypes.bool,
}

ProjectShareTypeSelection.defaultProps = {
  currentShareType: SHARE_TYPES.anyone,
  enrichedUsers: [],
  isInviteButtonDisabled: true,
  onAddUser: () => {},
  onRemoveUser: () => {},
  setCurrentShareType: () => {},
  setEmailShareValue: () => {},
  showUserBox: false,
  totalContributors: 0,
  totalWorkspaceMembers: 0,
  workspaceName: '',
  showMembersRecap: true,
  isCreation: false,
}

export default ProjectShareTypeSelection
