import isEmpty from 'lodash/isEmpty'

function replaceRvhWithPx(propertyStringValue, windowHeight) {
  // regexp is global to make #replace work multiple times
  const rvhRegex = /(\d+(\.\d*)?)rvh(?!\w)/g
  return propertyStringValue.replace(
    rvhRegex,
    (_, rvh) => `${(windowHeight * parseFloat(rvh)) / 100}px`
  )
}

function throwOnBadArgs(givenStyle, windowHeight) {
  if (
    (typeof givenStyle !== 'object' && givenStyle !== undefined) ||
    Array.isArray(givenStyle)
  )
    throw Error(`style (the first argument) must be an object or undefined`)
  if (typeof windowHeight !== 'number' || windowHeight < 0)
    throw Error('Second argument (windowHeight) must be a non-negative number')
}

function convertStyle(givenStyle, windowHeight) {
  throwOnBadArgs(givenStyle, windowHeight)

  // If style is not passed, implicit {height: '100rvh'} style is used.
  const defaultStyle = { height: '100rvh' }
  const usedStyle = isEmpty(givenStyle) ? defaultStyle : givenStyle

  const convertedStyle = {}
  Object.keys(usedStyle).forEach(key => {
    // if a value contains no rvh unit, it's used as is, otherwise converted
    // to px; 1rvh = (window.innerHeight / 100)px
    convertedStyle[key] =
      typeof usedStyle[key] === 'string'
        ? replaceRvhWithPx(usedStyle[key], windowHeight)
        : usedStyle[key]
  })
  return convertedStyle
}

export default convertStyle
