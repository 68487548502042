import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import pipe from 'lodash/fp/pipe'
import mapFp from 'lodash/fp/map'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/fp/sortBy'
import Quill from 'quill'
import 'quill/dist/quill.bubble.css'
import { toColorString, parseToRgb } from 'polished'
import { formatAllLinks } from './quill.module.autoLink'
import './quill.mentions/quill.module.mention'
import './quill.mentions/blots/mention'
import Flex from '../Flex'
import theme from '../../theme/old'
import indexOfAll from '../../../util/indexOfAll'

Quill.debug('error')

const isValidUrl = value => {
  const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)

  return value.match(regex)
}

const Link = Quill.import('formats/link')
Link.sanitize = url => {
  if (!url.startsWith('http')) {
    return `https://${url}`
  }
  return url
}

const anchorStyle = {
  color: toColorString(parseToRgb(theme.colors.secondary)),
}

const Wrapper = styled(Flex)`
  min-height: 40px;
  padding: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;

  && .ql-container.ql-bubble:not(.ql-disabled) a {
    white-space: normal !important;
    word-break: break-word !important;
  }

  && .ql-container.ql-bubble:not(.ql-disabled) a::before {
    background-color: ${prop('theme.colors.primary')} !important;
    white-space: nowrap !important;
  }
`

const TextArea = styled(Flex)`
  && .ql-editor {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    border: none;
    resize: none;
    outline: 0;
    color: ${prop('theme.colors.grey800')};
    caret-color: ${prop('theme.colors.grey800')};
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  && .ql-editor * {
    line-height: 20px;
    font-size: 14px;
  }

  && .ql-editor h1 {
    line-height: 40px;
    font-size: 32px;
  }

  && .ql-editor h2 {
    line-height: 28px;
    font-size: 24px;
  }

  && .ql-editor.ql-blank::before {
    left: 0;
    right: 0;
    font-style: normal;
    color: ${prop('theme.colors.grey400')};
  }

  && *::selection {
    color: ${prop('theme.colors.secondary100')};
    background: ${prop('theme.colors.secondary')};
  }

  && a {
    color: ${prop('theme.colors.secondary')};

    :hover,
    :active {
      color: ${prop('theme.colors.secondary800')};
    }
  }

  .ql-user-suggestion {
    color: ${prop('theme.colors.secondary')};
    display: inline-block;
    font-style: italic;
    font-weight: normal;
    caret-color: ${prop('theme.colors.secondary400')};
  }

  .ql-user-mention {
    color: ${prop('theme.colors.secondary')};
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    caret-color: ${prop('theme.colors.secondary400')};
  }
`

const appendUrlValidator = quill => {
  if (quill?.theme?.tooltip?.save) {
    const tooltipSave = quill.theme.tooltip.save

    // eslint-disable-next-line no-param-reassign
    quill.theme.tooltip.save = () => {
      const { tooltip } = quill.theme
      const url = tooltip.textbox.value

      tooltip.textbox.classList.remove('ql-error')
      if (isValidUrl(url)) {
        tooltipSave.call(tooltip)
      } else {
        tooltip.textbox.classList.add('ql-error')
      }
    }
  }
}

const DEFAULT_FORMATS = [
  'link',
  'color',
  'background',
  'user-mention',
  'user-suggestion',
]

const RichEditor = ({
  initialValue,
  readOnly,
  placeholder,
  onInput,
  onFocus,
  onBlur,
  textRef,
  mentions,
  autoFocusToken,
  resetToken,
  onMentionSuggestionsTrigger,
  onHideMentionSuggestions,
  onMount,
  editorConf,
  ...props
}) => {
  const {
    toolbar = false,
    autoLink = true,
    formats = DEFAULT_FORMATS,
    mention = {
      onTrigger: onMentionSuggestionsTrigger,
      onDenotationCharRemoved: onHideMentionSuggestions,
    },
  } = editorConf

  const textAreaRef = useRef(textRef)

  const [richEditor, setRichEditor] = useState()

  useEffect(() => {
    if (richEditor && initialValue) {
      richEditor.insertText(0, initialValue, 'user')
    }
  }, [!!richEditor, initialValue])

  useEffect(() => {
    if (richEditor && mentions && mentions.length > 0) {
      const text = richEditor.getText(0)
      const mentionLabels = pipe(
        mapFp(prop('name')),
        mapFp(name => `@${name}`),
        uniq,
        sortBy(x => x.length)
      )(mentions)

      mentionLabels.forEach(mentionLabel => {
        const indexes = indexOfAll(text, mentionLabel)
        indexes.forEach(index => {
          richEditor.formatText(
            index,
            mentionLabel.length,
            'user-mention',
            true,
            'api'
          )
        })
      })
    }
  }, [mentions, richEditor])

  let previousAutoFocusToken = null
  useEffect(() => {
    if (
      richEditor &&
      autoFocusToken &&
      previousAutoFocusToken !== autoFocusToken
    ) {
      richEditor.focus()
    }
    previousAutoFocusToken = autoFocusToken
  }, [autoFocusToken, richEditor])

  let previousToken = null
  useEffect(() => {
    if (richEditor && resetToken && previousToken !== resetToken) {
      const textLength = richEditor.getLength()
      richEditor.deleteText(0, textLength, 'silent')
    }
    previousToken = resetToken
  }, [resetToken, richEditor])

  useEffect(() => {
    if (richEditor && initialValue) {
      formatAllLinks(richEditor, anchorStyle)
    }
  }, [richEditor, initialValue])

  useEffect(() => {
    if (textAreaRef?.current) {
      // eslint-disable-next-line no-new
      const _richEditor = new Quill(textAreaRef.current, {
        debug: 'error',
        modules: {
          toolbar,
          autoLink,
          mention,
        },
        placeholder,
        readOnly,
        formats,
        theme: 'bubble',
      })

      setRichEditor(_richEditor)
      onMount(_richEditor, textAreaRef.current)

      _richEditor.on('text-change', () => {
        const text = _richEditor.getText(0)
        onInput(text, _richEditor)
      })

      appendUrlValidator(_richEditor)

      if (textRef) {
        // eslint-disable-next-line no-param-reassign
        textRef.current = textAreaRef.current
      }

      textAreaRef.current.firstChild.onfocus = () => onFocus(_richEditor)
      textAreaRef.current.firstChild.onblur = () => onBlur(_richEditor)
    }
  }, [textAreaRef?.current, placeholder])

  return (
    <Wrapper {...props}>
      <TextArea ref={textAreaRef} />
    </Wrapper>
  )
}

const mentionShape = PropTypes.shape({
  pk: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

const editorConfShape = PropTypes.shape({
  toolbar: PropTypes.bool,
  autoLink: PropTypes.bool,
})

RichEditor.propTypes = {
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  autoFocusToken: PropTypes.string,
  resetToken: PropTypes.string,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMentionSuggestionsTrigger: PropTypes.func,
  onHideMentionSuggestions: PropTypes.func,
  onMount: PropTypes.func,
  textRef: PropTypes.shape({
    current: PropTypes.element,
  }),
  mentions: PropTypes.arrayOf(mentionShape),
  editorConf: PropTypes.shape(editorConfShape),
}

RichEditor.defaultProps = {
  readOnly: false,
  placeholder: '',
  initialValue: '',
  autoFocusToken: '',
  resetToken: '',
  onInput: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onMentionSuggestionsTrigger: () => {},
  onHideMentionSuggestions: () => {},
  onMount: () => {},
  textRef: null,
  mentions: [],
  editorConf: {},
}

export default RichEditor
