import pick from 'lodash/pick'
import uuidv4 from 'uuid/v4'
import { set } from 'dot-prop-immutable'
import {
  GET_USER_REQUEST_SUCCESS,
  SET_USER_DATA,
  SET_USER_LOGGED,
  SET_USER_NOT_LOGGED,
  SET_USER_ID,
  SET_REGISTRATION_DATA,
  UPDATE_USER_REQUEST_SUCCESS,
} from './user.actions'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'
import { buildReducerForActions } from '../../util/reduxTools'

export const STATE_KEY = 'user'

const INITIAL_STATE = {
  logged: false,
  registration: {},
}

const getUrlWithoutCache = url => {
  if (url.includes('?')) {
    const [baseUrl, queryString] = url.split('?')
    const searchParams = new URLSearchParams(queryString)
    searchParams.set('d', uuidv4())
    return `${baseUrl}?${searchParams.toString()}`
  }

  return `${url}?d=${uuidv4()}`
}

const reducer = {
  [SET_USER_LOGGED]: state => ({ ...state, logged: true }),
  [SET_USER_NOT_LOGGED]: state => ({ ...state, logged: false }),
  [SET_USER_DATA]: (state, { payload: { ...data } }) => {
    const filteredData = pick(
      data,
      Object.keys(data).filter(k => data[k] !== undefined)
    )
    return { ...state, ...filteredData }
  },
  [SET_USER_ID]: (state, { payload: { userId } }) => {
    return { ...state, userId }
  },
  [GET_USER_REQUEST_SUCCESS]: (state, { payload }) => {
    return { ...state, ...payload }
  },
  [UPDATE_USER_REQUEST_SUCCESS]: (state, { payload }) => {
    if (payload.picture) {
      const safeUrl = getUrlWithoutCache(payload.picture)
      return {
        ...state,
        ...payload,
        picture: safeUrl,
      }
    }
    return { ...state, ...payload }
  },
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
  [SET_REGISTRATION_DATA]: (state, { payload }) =>
    set(state, 'registration', payload),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
