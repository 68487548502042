import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LG, XL } from '@zendeskgarden/react-typography'
import prop from 'lodash/fp/prop'
import CommentsEmptyStateSVG from '../../../assets/empty_comments.svg'
import Flex from '../../atoms/Flex'
import Anchor from '../../atoms/Anchor'
import { ternary } from '../../../util/utils'
import { isMobile } from '../../../lib/platform'

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const SVGFlex = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: ${ternary('isSmall')('200px', '240px')};
  height: ${ternary('isSmall')('120px', '160px')};
`

const StyledXL = styled(XL)`
  color: ${prop('theme.colors.grey900')};
  margin-top: 24px;
  text-align: center;
  max-width: 270px;

  @media only screen and (max-width: 1024px) {
    font-size: 18px;
  }
`

const StyledLG = styled(LG)`
  color: ${prop('theme.colors.grey600')};
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
`

const CommentsEmptyState = ({
  onInviteClick,
  isCaption,
  isSmall,
  showImage,
  ...props
}) => {
  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    onInviteClick()
  }
  return (
    <Wrapper {...props}>
      <SVGFlex>
        {showImage && (
          <Image
            src={CommentsEmptyStateSVG}
            alt="empty-project"
            isSmall={isSmall}
          />
        )}
        <StyledXL>
          {isMobile() ? 'Tap' : 'Click'} anywhere on the video to leave a
          comment
        </StyledXL>
        {isCaption && (
          <StyledLG>
            <strong>@mention</strong> or{' '}
            <Anchor href="#" onClick={onClick}>
              invite people
            </Anchor>{' '}
            to start a conversation
          </StyledLG>
        )}
      </SVGFlex>
    </Wrapper>
  )
}

CommentsEmptyState.propTypes = {
  onInviteClick: PropTypes.func.isRequired,
  isCaption: PropTypes.bool,
  isSmall: PropTypes.bool,
  showImage: PropTypes.bool,
}

CommentsEmptyState.defaultProps = {
  isCaption: false,
  isSmall: false,
  showImage: true,
}

export default CommentsEmptyState
