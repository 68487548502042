/* eslint-disable import/prefer-default-export */
import get from 'lodash/get'
import curry from 'lodash/curry'
import moment from 'moment'

export const ternary = propName => (truthy, falsy) => props =>
  get(props, propName) ? truthy : falsy

export const getRecordingTitleFromUrl = url => {
  const titlePrefix = 'Feedback on '
  const inputUrl = new URL(url)
  const { host } = inputUrl
  if (host.startsWith('www.')) {
    return titlePrefix + host.replace('www.', '')
  }
  return titlePrefix + host
}

export const getDefaultSessionTitle = project =>
  `${get(project, 'title', '')} session ${moment().format('ll')}`

export const getDefaultRemoteTestTitle = project =>
  `${get(project, 'title', '')} remote test ${moment().format('ll')}`

export const cleanUserIdentityId = identityId =>
  identityId && identityId.startsWith('user:')
    ? identityId.replace('user:', '')
    : identityId

export const limitNumber = curry((lowerBound, upperBound, number) => {
  if (lowerBound > upperBound) {
    console.error('lowerBound greater then upperBound', {
      lowerBound,
      upperBound,
    })
  }
  if (number < lowerBound) {
    return lowerBound
  }

  if (number > upperBound) {
    return upperBound
  }

  return number
})

export const getURLs = text => {
  const regExpStr =
    '(?:(?:https?|ftp|file)://|www.|ftp.)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])'
  const reg = new RegExp(regExpStr, 'igm')
  return text.match(reg)
}

export const filteredGmailUrl = () =>
  'https://mail.google.com/mail/u/0/#search/from%3A+iterspace'

export const toUserId = identityId =>
  identityId && identityId.startsWith('user:')
    ? identityId
    : `user:${identityId}`

export const addSearchParameter = (url, key, value) =>
  url.includes('?') ? `${url}&${key}=${value}` : `${url}?${key}=${value}`

export const removeLastReturn = text =>
  text.endsWith('\n') ? text.slice(0, -1) : text
