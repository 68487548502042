import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import random from 'lodash/random'
import range from 'lodash/range'
import RefreshIcon from 'mdi-react/RefreshIcon'
import { PropTypes } from 'prop-types'
import prop from 'lodash/fp/prop'
import uniq from 'lodash/uniq'
import UserAvatar from '../../atoms/UserAvatar'
import Flex from '../../atoms/Flex'
import IconButton from '../IconButton/IconButton'
import theme from '../../theme/old'

const Wrapper = styled(Flex)`
  flex-direction: row;
  user-select: none;
`

const StyledUserAvatar = styled(UserAvatar).attrs({
  size: 'large',
})`
  margin-right: 16px;
  user-select: none;
  cursor: pointer;
`

const StyledIconButton = styled(IconButton)`
  align-self: center;
  cursor: pointer;
  user-select: none;
  border-radius: 10000px;

  && svg {
    fill: ${prop('theme.colors.grey600')};
  }
  &&:hover svg {
    fill: ${prop('theme.colors.grey800')};
  }
`

const numberOfAvatars = 4
export const getRandomAvatarNumber = () => random(1, 20)

const _getRandomAvatars = () =>
  range(numberOfAvatars).map(getRandomAvatarNumber)

export const getRandomAvatars = () => {
  let randomAvatars = uniq(_getRandomAvatars())
  while (randomAvatars.length !== numberOfAvatars) {
    randomAvatars = uniq(_getRandomAvatars())
  }
  return randomAvatars
}

export const getAvatarUrl = avatarNumber =>
  `/avatars/avatar_${avatarNumber}.png`

const backgroundColors = ['primary', 'secondary', 'green', 'yellow', 'warning']

const getRandomColor = () =>
  backgroundColors[random(0, backgroundColors.length - 1)]

let previousColor = null
const getRandomBackgroundColor = currentColor => {
  let color = getRandomColor()

  while (color === previousColor || color === currentColor) {
    color = getRandomColor()
  }
  previousColor = color
  return color
}

const AvatarSelector = ({ name, onSelect, currentColor, ...props }) => {
  const [backgroundColor, setBackgroundColor] = useState('green')
  const [avatars, setAvatars] = useState(getRandomAvatars())

  const onClick = useCallback(() => {
    setAvatars(getRandomAvatars())
    const color = getRandomBackgroundColor(currentColor)
    setBackgroundColor(color)
  }, [currentColor])

  return (
    <Wrapper {...props}>
      <StyledUserAvatar
        name={name}
        backgroundColor={theme.colors[backgroundColor]}
        onClick={() => onSelect({ avatarColor: backgroundColor })}
      />
      {avatars.map(avatarNumber => {
        const avatarUrl = getAvatarUrl(avatarNumber)
        return (
          <StyledUserAvatar
            key={Math.random()}
            imgUrl={avatarUrl}
            onClick={() => onSelect({ avatarUrl })}
          />
        )
      })}
      <StyledIconButton icon={RefreshIcon} isBasic onClick={onClick} />
    </Wrapper>
  )
}

AvatarSelector.propTypes = {
  name: PropTypes.string.isRequired,
  currentColor: PropTypes.string,
  onSelect: PropTypes.func,
}

AvatarSelector.defaultProps = {
  currentColor: null,
  onSelect: () => {},
}

export default AvatarSelector
