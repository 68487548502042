import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Tiles } from '@zendeskgarden/react-forms'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import ProductManagerSVG from '../../../../assets/icons/product_manager.svg'
import DesignerSVG from '../../../../assets/icons/designer.svg'
import MarketingSVG from '../../../../assets/icons/marketing.svg'
import EngineeringSVG from '../../../../assets/icons/engineering.svg'
import OthersSVG from '../../../../assets/icons/others.svg'
import Flex from '../../../atoms/Flex'
import AvatarPicker from '../../../atoms/AvatarPicker'

const Wrapper = styled(Flex)``

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 600px;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled.h3`
  text-align: left;
`

const LeftBox = styled(Flex)`
  flex: 1 1 auto;
  padding-top: 16px;
  align-items: center;
`

const RightBox = styled(Flex)`
  flex: 1 1 auto;
  padding-left: 24px;
`

const StyledUsername = styled(Flex)`
  font-size: 24px;
  margin-top: 8px;
`

const NextButton = styled(Button)`
  && {
    width: 200px;
    margin-top: 16px;
  }
`

export const USER_ROLES = [
  {
    image: ProductManagerSVG,
    value: 'product-manager',
    label: 'Product manager',
  },
  {
    image: DesignerSVG,
    value: 'designer',
    label: 'Designer',
  },
  {
    image: MarketingSVG,
    value: 'marketing-and-sales',
    label: 'Marketing & Sales',
  },
  {
    image: EngineeringSVG,
    value: 'engineering',
    label: 'Engineering',
  },
  {
    image: OthersSVG,
    value: 'other',
    label: 'Other',
  },
]

const UserRole = ({
  downloadProgress,
  userName,
  pictureUrl,
  onNext,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState('')

  return (
    <Wrapper {...props}>
      <CenteredBox direction="row">
        <LeftBox>
          <AvatarPicker
            name={userName}
            imageUrl={pictureUrl}
            isPickerVisible={false}
          />
          <StyledUsername>{userName}</StyledUsername>
        </LeftBox>
        <RightBox>
          <Title>Which best describes you, {userName}?</Title>
          <Tiles
            name="basic-example"
            aria-label="Example radio group selection"
          >
            <Grid>
              <Row>
                {USER_ROLES.map(({ value, label, image }) => (
                  <Col sm={5}>
                    <Tiles.Tile
                      value={value}
                      onChange={() => setSelectedValue(value)}
                    >
                      <Tiles.Icon>
                        <img src={image} alt={label} />
                      </Tiles.Icon>
                      <Tiles.Label>{label}</Tiles.Label>
                    </Tiles.Tile>
                  </Col>
                ))}
              </Row>
            </Grid>
          </Tiles>
          <NextButton
            isPrimary
            disabled={!selectedValue}
            onClick={() => onNext(selectedValue)}
          >
            Continue
          </NextButton>
        </RightBox>
      </CenteredBox>
    </Wrapper>
  )
}

UserRole.propTypes = {
  downloadProgress: PropTypes.number,
  userName: PropTypes.string,
  pictureUrl: PropTypes.string,
  onNext: PropTypes.func,
}

UserRole.defaultProps = {
  downloadProgress: 0,
  userName: '',
  pictureUrl: '',
  onNext: () => {},
}

export default UserRole
