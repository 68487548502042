/* eslint-disable import/prefer-default-export */
import {
  getCleanProjectId,
  getCleanFolderId,
  getCleanSessionId,
  getCleanRecordingId,
  getCleanCommentId,
  getCleanReplyId,
  getCleanRemoteTestId,
  getCleanOrganizationId,
} from './ids'

export const getProjectPathName = projectId =>
  `/project/${getCleanProjectId(projectId)}`

export const getFolderPathName = projectId =>
  `/project/${getCleanFolderId(projectId)}`

export const getSessionPathName = sessionId =>
  `/recording/${getCleanSessionId(sessionId)}`

export const getRecordingPathName = sessionId =>
  `/recording/${getCleanRecordingId(sessionId)}`

export const getCommentPathName = commentId =>
  `/comment/${getCleanCommentId(commentId)}`

export const getReplyPathName = replyId => `/reply/${getCleanReplyId(replyId)}`

export const getRemoteTestPathName = remoteTestId =>
  `/remote-test/${getCleanRemoteTestId(remoteTestId)}`

export const getOrganizationPathName = organizationId =>
  `/workspace/${getCleanOrganizationId(organizationId)}`

export const getOrganizationSettingsPathName = organizationId =>
  `/workspace/edit/${getCleanOrganizationId(organizationId)}`
