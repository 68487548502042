/* eslint-disable import/prefer-default-export */
import Bowser from 'bowser'
import mobile from 'is-mobile'
import UAParser from 'ua-parser-js'
import get from 'lodash/get'

const uaParser = new UAParser().setUA(window.navigator.userAgent)
const deviceInfo = uaParser.getResult()
const device = uaParser.getDevice()
const browser = Bowser.parse(window.navigator.userAgent)

export const PLATFORMS = {
  mobile: 'mobile',
  desktop: 'desktop',
  tablet: 'tablet',
}

export const isElectron = () => !!get(window.process || {}, 'versions.electron')

export const isMobile = () => mobile() || device.type === PLATFORMS.tablet

export const isInStandaloneMode = () =>
  !!(
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone
  )

export const isDesktop = () =>
  get(browser, 'platform.type') === PLATFORMS.desktop

export const isIOS = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
  return iOSSafari
}

export const isIOSStandalone = () => isIOS() && isInStandaloneMode()

export const isMac = () => deviceInfo.os.name.toLowerCase().includes('mac')
export const isLinux = () => deviceInfo.os.name.toLowerCase().includes('linux')
export const isWindows = () =>
  deviceInfo.os.name.toLowerCase().includes('windows')

export const getDeviceType = () => uaParser.getDevice().type

export const getDeviceVendor = () => uaParser.getDevice().vendor

export const getDeviceEngineName = () => uaParser.getEngine().name

export const getDeviceEngineVersion = () => uaParser.getEngine().version

export const getOsName = () => uaParser.getOS()?.name || ''
export const getOsVersion = () => uaParser.getOS()?.version || ''

export const getBrowserName = () => uaParser.getBrowser()?.name || ''
export const getBrowserVersion = () => uaParser.getBrowser()?.version || ''

export const isChromeLikeBrowser = () =>
  ['Chrome', 'Chromium', 'Brave'].includes(uaParser.getBrowser()?.name)

export const isEdgeBrowser = () => uaParser.getBrowser()?.name === 'Edge'

export const getScreenSize = () => ({
  width: window.screen.width,
  height: window.screen.height,
})

export const getWindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

export const getLocale = () => {
  try {
    return navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language
  } catch (err) {
    return null
  }
}

export const getTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (err) {
    console.log(err)
    return null
  }
}
