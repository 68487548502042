import { useSelector } from 'react-redux'
import { pending, rejected, fulfilled, done } from 'redux-saga-thunk'
import uniq from 'lodash/uniq'
import fromSessions from '../sessions/sessions.selectors'
import { UPDATE_PROJECTS_REQUEST } from '../projects/projects.actions'

function useProjectsHook() {
  const projects = useSelector(fromSessions.getProjectsMergedSessions)
  const projectsPending = useSelector(state =>
    pending(state, UPDATE_PROJECTS_REQUEST)
  )
  const projectsRejected = useSelector(state =>
    rejected(state, UPDATE_PROJECTS_REQUEST)
  )
  const projectsFullfilled = useSelector(state =>
    fulfilled(state, UPDATE_PROJECTS_REQUEST)
  )
  const projectsDone = useSelector(state =>
    done(state, UPDATE_PROJECTS_REQUEST)
  )
  const currentProject = useSelector(fromSessions.getCurrentProject)

  const projectAdmins = currentProject?.admin || []
  const projectEdit = currentProject?.edit || []
  const projectView = currentProject?.view || []
  const projectSharedWith = uniq(projectAdmins.concat(projectEdit, projectView))
  const projectSharedWithCounter = projectSharedWith.length

  return {
    projects,
    projectsPending,
    projectsRejected,
    projectsFullfilled,
    projectsDone,
    currentProject,
    projectSharedWith,
    projectSharedWithCounter,
  }
}

export default useProjectsHook
