import { set } from 'dot-prop-immutable'
import omit from 'lodash/omit'
import {
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_REMOVE,
} from './ui-notifications.actions'
import { buildReducerForActions } from '../../util/reduxTools'

export const STATE_KEY = 'notifications'

export const INITIAL_STATE = {
  byId: {},
}

const reducer = {
  [NOTIFICATIONS_ADD]: (
    state,
    { payload: { notificationId, text, callToAction } }
  ) => {
    const createdAt = new Date().getTime()
    return set(state, `byId.${notificationId}`, {
      notificationId,
      text,
      createdAt,
      callToAction,
    })
  },
  [NOTIFICATIONS_REMOVE]: (state, { payload: { notificationId } }) =>
    omit(state, [`byId.${notificationId}`]),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
