import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import { getAppSyncClient } from '../../lib/appSyncClient'
import { createSession as createSessionGql } from '../../graphql/mutations'
import {
  updateSessionRequest,
  deleteSessionRequest,
  CREATE_NEW_SESSION_REQUEST,
  UPDATE_SESSION_REQUEST,
  DELETE_SESSION_REQUEST,
} from '../sessions/sessions.actions'
import fromSessions from '../sessions/sessions.selectors'
import fromUsers from '../users/users.selectors'

const apolloClient = getAppSyncClient()

const createSessionPending = state => pending(state, CREATE_NEW_SESSION_REQUEST)
const createSessionDone = state => done(state, CREATE_NEW_SESSION_REQUEST)
const createSessionFulfilled = state =>
  fulfilled(state, CREATE_NEW_SESSION_REQUEST)
const createSessionRejected = state =>
  rejected(state, CREATE_NEW_SESSION_REQUEST)

const updateSessionPending = state => pending(state, UPDATE_SESSION_REQUEST)
const updateSessionDone = state => done(state, UPDATE_SESSION_REQUEST)
const updateSessionFulfilled = state => fulfilled(state, UPDATE_SESSION_REQUEST)
const updateSessionRejected = state => rejected(state, UPDATE_SESSION_REQUEST)

const deleteSessionPending = state => pending(state, DELETE_SESSION_REQUEST)
const deleteSessionDone = state => done(state, DELETE_SESSION_REQUEST)
const deleteSessionFulfilled = state => fulfilled(state, DELETE_SESSION_REQUEST)
const deleteSessionRejected = state => rejected(state, DELETE_SESSION_REQUEST)

const useCRUDSession = sessionId => {
  const session = useSelector(fromSessions.getSessionById(sessionId))
  const createdBy = get(session, 'createdBy')
  const sessionUser = useSelector(fromUsers.getUserById(createdBy))

  const dispatch = useDispatch()

  const onCreateSessionHandler = useCallback(
    projectId =>
      apolloClient.mutate({
        mutation: gql(createSessionGql),
        variables: {
          input: {
            projectId,
          },
        },
      }),
    [apolloClient]
  )

  const isCreateSessionPending = useSelector(createSessionPending)
  const isCreateSessionDone = useSelector(createSessionDone)
  const isCreateSessionFulfilled = useSelector(createSessionFulfilled)
  const isCreateSessionRejected = useSelector(createSessionRejected)

  const onUpdateSessionHandler = useCallback(
    title =>
      dispatch(
        updateSessionRequest({
          sessionId,
          title,
        })
      ),
    [dispatch]
  )
  const isUpdateSessionPending = useSelector(updateSessionPending)
  const isUpdateSessionDone = useSelector(updateSessionDone)
  const isUpdateSessionFulfilled = useSelector(updateSessionFulfilled)
  const isUpdateSessionRejected = useSelector(updateSessionRejected)

  const onDeleteSessionHandler = useCallback(
    _sessionId => dispatch(deleteSessionRequest(_sessionId)),
    [dispatch]
  )

  const isDeleteSessionPending = useSelector(deleteSessionPending)
  const isDeleteSessionDone = useSelector(deleteSessionDone)
  const isDeleteSessionFulfilled = useSelector(deleteSessionFulfilled)
  const isDeleteSessionRejected = useSelector(deleteSessionRejected)

  return {
    session,
    sessionUser,
    onCreateSessionHandler,
    onUpdateSessionHandler,
    onDeleteSessionHandler,
    isUpdateSessionPending,
    isUpdateSessionDone,
    isUpdateSessionFulfilled,
    isUpdateSessionRejected,
    isDeleteSessionPending,
    isDeleteSessionDone,
    isDeleteSessionFulfilled,
    isDeleteSessionRejected,
    isCreateSessionPending,
    isCreateSessionDone,
    isCreateSessionFulfilled,
    isCreateSessionRejected,
  }
}

export default useCRUDSession
