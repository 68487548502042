import Quill from 'quill'
import { useState, useEffect } from 'react'

const getCaretCharacterOffset = domNode => {
  const doc = domNode.ownerDocument || domNode.document
  const win = doc.defaultView || doc.parentWindow
  let sel
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection()
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(domNode)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      return preCaretRange.toString().length
    }
    return 0
  }

  sel = doc.selection
  if (sel && sel.type !== 'Control') {
    const textRange = sel.createRange()
    const preCaretTextRange = doc.body.createTextRange()
    preCaretTextRange.moveToElementText(domNode)
    preCaretTextRange.setEndPoint('EndToEnd', textRange)
    return preCaretTextRange.text.length
  }
  return 0
}

const useEmojiPicker = textRef => {
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false)
  const [textareaCursorPosition, setTextareaCursorPosition] = useState(0)

  const onTextKeyDown = () => {
    const caretPosition = getCaretCharacterOffset(textRef.current)
    setTextareaCursorPosition(caretPosition)
  }

  const clickListener = e => {
    if (
      e.path &&
      e.path.find &&
      !e.path.find(
        el =>
          el.className &&
          el.className.includes &&
          el.className.includes('emoji-mart')
      )
    ) {
      setEmojiPickerVisible(false)
    }
  }
  useEffect(() => {
    window.document.addEventListener('click', clickListener)
    return () => {
      window.document.removeEventListener('click', clickListener)
    }
  }, [])
  const insertEmoji = data => {
    const quill = Quill.find(textRef.current)

    const emoji = `${data.native} `
    console.log('>>> emoji', emoji)
    if (quill) {
      quill.insertText(textareaCursorPosition, emoji, 'api')
    } else {
      const text = textRef.current.textContent
      // eslint-disable-next-line no-param-reassign
      textRef.current.textContent =
        text.substring(0, textareaCursorPosition) +
        emoji +
        text.substring(textareaCursorPosition, text.length)
    }

    const event = new Event('input', { bubbles: true })
    textRef.current.dispatchEvent(event)

    setTextareaCursorPosition(textareaCursorPosition + emoji.length)
  }

  return {
    isEmojiPickerVisible,
    setEmojiPickerVisible,
    textareaCursorPosition,
    setTextareaCursorPosition,
    onTextKeyDown,
    insertEmoji,
  }
}

export default useEmojiPicker
