import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import gql from 'graphql-tag'
import {
  getNotifications as getNotificationsGql,
  getUnreadNotificationsCount as getUnreadNotificationsCountGql,
} from '../../graphql/queries'
import { setNotificationsRead as setNotificationsReadGql } from '../../graphql/mutations'
import { LOGIN_REQUEST_SUCCESS, ACTIVE_USER } from '../actions'
import {
  GET_NOTIFICATIONS_REQUEST,
  getNotificationsRequestSuccess,
  getNotificationsRequestFailure,
  getNotificationsRequest,
  GET_UNREAD_NOTIFICATIONS_REQUEST,
  getUnreadNotificationsRequestSuccess,
  getUnreadNotificationsRequestFailure,
  getUnreadNotificationsRequest,
  SET_NOTIFICATIONS_READ_REQUEST,
  SET_NOTIFICATIONS_READ_REQUEST_SUCCESS,
  setNotificationsReadRequestSuccess,
  setNotificationsReadRequestFailure,
} from './notifications.actions'
import fromNotifications from './notifications.selectors'
import { updatePageTitle, updatePageFavicon } from './notifications.utils'

export function* fetchNotifications({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(getNotificationsGql),
      fetchPolicy: 'no-cache',
    })

    const notifications = result?.data?.getNotifications?.items || []
    yield put(getNotificationsRequestSuccess({ notifications }))
  } catch (e) {
    yield put(getNotificationsRequestFailure(e))
    console.error(e)
  }
}

export function* fetchUnreadNotifications({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(getUnreadNotificationsCountGql),
      fetchPolicy: 'no-cache',
    })

    const count = result?.data?.getUnreadNotificationsCount?.count || 0

    const currentUnreadCount = yield select(
      fromNotifications.getUnreadNotificationsCount
    )
    if (currentUnreadCount !== count) {
      yield put(getNotificationsRequest())
    }

    yield put(getUnreadNotificationsRequestSuccess({ count }))
    updatePageTitle(count)
    updatePageFavicon(count)
  } catch (e) {
    yield put(getUnreadNotificationsRequestFailure(e))
    console.error(e)
  }
}

function* setNotificationsRead(
  { apolloClient },
  { payload: { notificationIds }, meta: { thunk } }
) {
  try {
    const notifications = yield select(
      fromNotifications.getNotificationsByIds(notificationIds)
    )

    const notificationsKeys = notifications.map(
      ({ notificationId, createdAt }) => ({ notificationId, createdAt })
    )

    const data = yield call(apolloClient.mutate, {
      mutation: gql(setNotificationsReadGql),
      fetchPolicy: 'no-cache',
      variables: {
        notifications: notificationsKeys,
      },
    })
    if (data) {
      yield put(setNotificationsReadRequestSuccess(data, thunk))
    } else {
      yield put(
        setNotificationsReadRequestFailure(
          { error: 'Failed to set notification read' },
          thunk
        )
      )
    }
  } catch (e) {
    console.error(e)
    yield put(setNotificationsReadRequestFailure(e, thunk))
  }
}

function* scheduleGetUnreadNotification() {
  while (true) {
    try {
      yield put(getUnreadNotificationsRequest())
    } catch (e) {
      console.error(e)
    }
    yield race([
      delay(30000),
      take(ACTIVE_USER),
      take(SET_NOTIFICATIONS_READ_REQUEST_SUCCESS),
    ])
  }
}

function* startNotificationsFork() {
  yield fork(scheduleGetUnreadNotification)
}

export default function* notificationsSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startNotificationsFork, {
      apolloClient,
    }),
    yield takeEvery(LOGIN_REQUEST_SUCCESS, fetchNotifications, {
      apolloClient,
    }),
    yield takeLatest(GET_NOTIFICATIONS_REQUEST, fetchNotifications, {
      apolloClient,
    }),
    yield takeLatest(
      GET_UNREAD_NOTIFICATIONS_REQUEST,
      fetchUnreadNotifications,
      {
        apolloClient,
      }
    ),
    yield takeLatest(SET_NOTIFICATIONS_READ_REQUEST, setNotificationsRead, {
      apolloClient,
    }),
  ])
}
