import { useDispatch, useSelector } from 'react-redux'
import fromNotifications from '../notifications/notifications.selectors'
import { setNotificationsReadRequest } from '../notifications/notifications.actions'

const useNotifications = () => {
  const dispatch = useDispatch()
  const unreadNotificationsCount = useSelector(
    fromNotifications.getUnreadNotificationsCount
  )

  const unreadNotifications = useSelector(
    fromNotifications.getAllUnreadNotifications
  )

  const unreadNotificationsIds = unreadNotifications.map(
    notification => notification.notificationId
  )

  const onMarkAllNotificationsAsRead = () =>
    dispatch(
      setNotificationsReadRequest({ notificationIds: unreadNotificationsIds })
    )

  return {
    unreadNotificationsCount,
    onMarkAllNotificationsAsRead,
  }
}

export default useNotifications
