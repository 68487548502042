import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentSession } from '../../store/sessions/sessions.actions'
import { getRecordingPathName } from '../../util/paths'

const useNavigation = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const onSetCurrentSession = useCallback(
    sessionId => dispatch(setCurrentSession(sessionId)),
    [dispatch]
  )

  const goToSession = ({ sessionId }) => {
    onSetCurrentSession(sessionId)
    history.push(getRecordingPathName(sessionId))
  }

  return {
    goToSession,
  }
}

export default useNavigation
