import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import capitalize from 'lodash/capitalize'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Field, Label, Input } from '@zendeskgarden/react-forms'
import { Button, ChevronButton } from '@zendeskgarden/react-buttons'
import {
  Dropdown,
  Trigger,
  Menu,
  ItemMeta,
  MediaBody,
  MediaItem,
} from '@zendeskgarden/react-dropdowns'
import { Formik, Form, ErrorMessage } from 'formik'
import { StyledErrorMessage } from '../../commonStyledComponents'
import Flex from '../../atoms/Flex'
import Loader from '../../atoms/Loader'
import UserAvatar from '../../atoms/UserAvatar'
import useOrganization from '../../../store/hooks/useOrganization'
import { ternary } from '../../../util/utils'
import { ROLES } from './useOrganizationMembers'

const formInitialValues = {
  email: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'The email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const RolesGrid = styled(Grid)`
  margin-top: 16px;
`

const RolesRow = styled(Row)`
  padding: 8px 0;
  line-height: 32px;
`

const RoleCol = styled(Col)`
  text-align: right;
`

export const RoleDropdown = ({ currentRole, userEmail, onChangeRole }) => {
  return (
    <Dropdown
      selectedItem={currentRole}
      onSelect={value => onChangeRole(userEmail, value)}
    >
      <Trigger>
        <ChevronButton
          aria-label="Role"
          isBasic
          size="small"
          isPill={false}
          muted
          style={{ marginLeft: '16px' }}
        />
      </Trigger>
      <Menu placement="bottom-end" style={{ width: '350px' }} zIndex={10000}>
        <MediaItem value={ROLES.admin}>
          <MediaBody>
            Admin
            <ItemMeta>
              Can invite new members, plus all the member capabilities
            </ItemMeta>
          </MediaBody>
        </MediaItem>
        <MediaItem value={ROLES.member} checked={currentRole === ROLES.member}>
          <MediaBody>
            Member
            <ItemMeta>
              Can create projects and recordings within the workspace
            </ItemMeta>
          </MediaBody>
        </MediaItem>
        <MediaItem value={ROLES.remove}>
          <MediaBody>Remove</MediaBody>
        </MediaItem>
      </Menu>
    </Dropdown>
  )
}

RoleDropdown.propTypes = {
  currentRole: PropTypes.oneOf(Object.values(ROLES)).isRequired,
  userEmail: PropTypes.string.isRequired,
  onChangeRole: PropTypes.func.isRequired,
}

const ShareForm = styled(Form)`
  display: flex;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareButton = styled(Button)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledBody = styled(Flex)`
  padding: 8px 8px 16px 8px;
`

const Email = styled(Flex)`
  line-height: ${ternary('isPending')('16px', '32px')};
`

const Pending = styled(Flex)`
  line-height: 16px;
  color: ${prop('theme.colors.secondary')};
  font-style: italic;
  margin-top: 4px;
`

const StyledLoader = styled(Loader).attrs(props => ({
  color: prop('theme.colors.grey600')(props),
}))``

const OrganizationMembers = ({
  onAddUser,
  onChangeRole,
  isCurrentUserOrganizationAdmin,
  fetchUsers,
  organizationUsers,
  ...props
}) => {
  const { currentOrganization } = useOrganization()

  const organizationId = currentOrganization?.pk

  const [isFormLoading, setIsFormLoading] = useState(false)

  const onFetchUsers = useCallback(() => {
    fetchUsers(currentOrganization)
  }, [currentOrganization, organizationId, fetchUsers])

  useEffect(() => onFetchUsers(), [])

  return (
    <StyledBody {...props} data-test="workspace-members">
      {isCurrentUserOrganizationAdmin && (
        <React.Fragment>
          <Field>
            <Label>Invite new member</Label>
          </Field>
          <Formik
            initialValues={formInitialValues}
            validate={validateFormValues}
            onSubmit={(values, actions) => {
              if (values.email) {
                setIsFormLoading(true)
                onAddUser({
                  userEmail: values.email,
                  userRole: ROLES.member,
                })
                  .then(() => {
                    setIsFormLoading(false)
                  })
                  .finally(() => {
                    actions.resetForm({ email: '' })
                  })
              }
            }}
            render={({ values, errors, handleBlur, handleChange }) => (
              <ShareForm>
                <EmailTextField>
                  <Input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    validation={errors.email ? 'warning' : undefined}
                    placeholder="Email"
                    data-test="add-member-to-workspace-input"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                </EmailTextField>
                <ShareButton
                  isPrimary
                  type="submit"
                  disabled={isFormLoading}
                  data-test="add-member-to-workspace-button"
                >
                  {isFormLoading && <StyledLoader size="16px" />}
                  {!isFormLoading && <>Add</>}
                </ShareButton>
              </ShareForm>
            )}
          />
        </React.Fragment>
      )}
      <RolesGrid>
        {organizationUsers.map(
          ({ picture, name, email, role, isPendingRegistration }) => {
            return (
              <RolesRow
                key={email}
                style={{ padding: '5px 0' }}
                data-test={`member-${email}`}
              >
                <Col size={2} xs={1} style={{ textAlign: 'right' }}>
                  <UserAvatar
                    imgUrl={picture}
                    name={name || email}
                    status={null}
                  />
                </Col>
                <Col size={6} xs={7}>
                  <Email isPending={isPendingRegistration}>{email}</Email>
                  {isPendingRegistration && <Pending>pending</Pending>}
                </Col>
                <RoleCol size={4} xs={4}>
                  <React.Fragment>
                    <span data-test="role-label">{capitalize(role)}</span>
                    {isCurrentUserOrganizationAdmin && (
                      <RoleDropdown
                        userEmail={email}
                        currentRole={role}
                        onChangeRole={(assignerUserEmail, newRole) =>
                          onChangeRole({
                            userEmail: assignerUserEmail,
                            userRole: newRole,
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                </RoleCol>
              </RolesRow>
            )
          }
        )}
      </RolesGrid>
    </StyledBody>
  )
}

OrganizationMembers.propTypes = {
  fetchUsers: PropTypes.func,
  isCurrentUserOrganizationAdmin: PropTypes.bool,
  onAddUser: PropTypes.func,
  onChangeRole: PropTypes.func,
  organizationUsers: PropTypes.arrayOf(PropTypes.shape({})),
}

OrganizationMembers.defaultProps = {
  fetchUsers: () => {},
  isCurrentUserOrganizationAdmin: false,
  onAddUser: () => {},
  onChangeRole: () => {},
  organizationUsers: [],
}

export default OrganizationMembers
