import { useSelector } from 'react-redux'
import fromUser from '../user/user.selectors'

function useUserInfo() {
  const isLogged = useSelector(fromUser.isLogged)
  const userId = useSelector(fromUser.getId)
  const userName = useSelector(fromUser.getName)
  const userEmail = useSelector(fromUser.getEmail)
  const userPicture = useSelector(fromUser.getPicture)
  const userIdentityId = useSelector(fromUser.getIdentityId)
  const userNotificationsFrequency = useSelector(
    fromUser.getNotificationsFrequency
  )

  return {
    isLogged,
    userId,
    userName,
    userEmail,
    userPicture,
    userIdentityId,
    userNotificationsFrequency,
  }
}

export default useUserInfo
