import React, { useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { Button } from '@zendeskgarden/react-buttons'
import { Input } from '@zendeskgarden/react-forms'
import { Modal, Header, Body } from '@zendeskgarden/react-modals'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import { isMobile } from '../../../lib/platform'

const LinkModal = ({ url, isVisible, onClose }) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy')

  const ModalComponent = isMobile() ? MobileModal : Modal

  return (
    <div>
      {isVisible && (
        <ModalComponent onClose={onClose} isAnimated={false} isLarge>
          <Header>Comment link</Header>
          <Body>
            <Flex direction="row">
              <Flex flexGrow={1} style={{ paddingRight: '16px' }}>
                <Input placeholder="" defaultValue={url} small />
              </Flex>
              <Flex>
                <Button
                  size="small"
                  isPrimary
                  onClick={() => {
                    copy(url)
                    setCopyButtonText('Copied')
                    setTimeout(() => {
                      if (copyButtonText) {
                        setCopyButtonText('Copy')
                      }
                    }, 2000)
                  }}
                >
                  {copyButtonText}
                </Button>
              </Flex>
            </Flex>
          </Body>
        </ModalComponent>
      )}
    </div>
  )
}

LinkModal.propTypes = {
  url: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

LinkModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default LinkModal
