import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { handleZerosPadding, timeUnits } from './utils'

export const defaultSeparator = ':'

const StyledSpan = styled.span`
  font-size: 20px;
  letter-spacing: 2px;
  color: #49545c;
`

const TimePart = ({ show, type, value, separator = defaultSeparator }) =>
  show ? (
    <React.Fragment>
      <StyledSpan>{handleZerosPadding(type, value)}</StyledSpan>
      <StyledSpan>{separator}</StyledSpan>
    </React.Fragment>
  ) : (
    <React.Fragment />
  )

TimePart.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf(timeUnits),
  separator: PropTypes.string,
  value: PropTypes.number,
}

TimePart.defaultProps = {
  show: false,
  type: 'seconds',
  separator: defaultSeparator,
  value: '',
}

export default React.memo(TimePart)
