/* eslint-disable import/prefer-default-export */
import queryString from 'query-string'
import pick from 'lodash/pick'
import {
  getFolderPathName,
  getRecordingPathName,
  getCommentPathName,
  getReplyPathName,
  getRemoteTestPathName,
  getOrganizationPathName,
} from '../util/paths'

export const getOrganizationUrl = organizationId =>
  `${window.location.origin}${getOrganizationPathName(organizationId)}`

export const getProjectUrl = projectId =>
  `${window.location.origin}${getFolderPathName(projectId)}`

export const getSessionUrl = sessionId =>
  `${window.location.origin}${getRecordingPathName(sessionId)}`

export const getCommentUrl = commentId =>
  `${window.location.origin}${getCommentPathName(commentId)}`

export const getReplyUrl = (commentId, replyId) =>
  `${window.location.origin}${getCommentPathName(commentId)}${getReplyPathName(
    replyId
  )}`

export const getRemoteTestUrl = remoteTestId =>
  `${window.location.origin}${getRemoteTestPathName(remoteTestId)}`

export const getRedirectUrl = url => {
  const uri = queryString.parseUrl(url, { decode: true })
  const baseUrl = uri.url
    .split('/')
    .slice(0, -1)
    .join('/')
  const separator = baseUrl.endsWith('/') ? '' : '/'
  if (uri?.query?.redirect && uri.query.redirect.startsWith('session:')) {
    const [, sessionId] = uri.query.redirect.split(':')
    const redirectQueryString = pick(uri.query, ['t', 'm'])
    return queryString.stringifyUrl({
      url: `${baseUrl}${separator}session/${sessionId}`,
      query: redirectQueryString,
    })
  }

  if (uri?.query?.redirect && uri.query.redirect.startsWith('comment:')) {
    const [, commnentId] = uri.query.redirect.split(':')
    return queryString.stringifyUrl({
      url: `${baseUrl}${separator}comment/${commnentId}`,
    })
  }

  if (uri?.query?.redirect && uri.query.redirect.startsWith('project:')) {
    const [, projectId] = uri.query.redirect.split(':')
    return queryString.stringifyUrl({
      url: `${baseUrl}${separator}project/${projectId}`,
    })
  }

  if (uri?.query?.redirect && uri.query.redirect.startsWith('extension:')) {
    const [, recordingId] = uri.query.redirect.split(':')
    return queryString.stringifyUrl({
      url: `${baseUrl}${separator}extension/${recordingId}`,
    })
  }

  return null
}
