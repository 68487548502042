import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import fromSessions from '../../../store/sessions/sessions.selectors'
import Flex from '../../atoms/Flex'
import ProjetEmptyState from '../../molecules/ProjectEmptyState'
import SessionCard from '../../molecules/SessionCard'
import useSessionsHook from '../../../store/hooks/useSessionsHook'
import useRemoteTestsHook from '../../../store/hooks/useRemoteTestsHook'
import { isMobile } from '../../../lib/platform'
import RemoteTestCard from '../../molecules/RemoteTestCard/RemoteTestCard'
import { getRemoteTestUrl } from '../../../lib/urls'
import usePosters from '../../hooks/usePosters'
import SessionSkeleton from '../../atoms/SessionSkeleton'

const Wrapper = styled(Flex)`
  display: ${({ fullHeight }) => (fullHeight ? 'flex' : 'block')};
  flex-grow: 1;
`

const cardCss = css`
  margin: 0 0 16px 0;
`

const StyledSessionCard = styled(SessionCard)`
  ${cardCss}
`

const StyledRemoteTestCard = styled(RemoteTestCard)`
  ${cardCss}
`

const SessionsListMobile = ({
  onSessionClick,
  onLoadingSessionClick,
  onSessionDelete,
  onSessionCopy,
  onSessionShare,
  hasSettings,
  onRemoteTestClick,
  onRemoteTestDelete,
  onRemoteTestEdit,
  onRemoteTestShare,
  onRemoteTestPreview,
  onRemoteTestLink,
  onRemoteTestSessionDelete,
  onJiraClick,
  onTrelloClick,
  onSlackClick,
  ...props
}) => {
  const { sessions, isFetchingSessions, currentProjectId } = useSessionsHook()
  const [isFirstRendering, setFirstRendering] = useState(true)
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(true)
  const [previousProjectId, setPreviousProjectId] = useState(null)

  useEffect(() => {
    if (currentProjectId !== previousProjectId) {
      setFirstRendering(true)
      setPreviousProjectId(currentProjectId)
    }
    if (isFetchingSessions && isFirstRendering) {
      setShowLoadingSkeleton(true)
      return
    }
    if (!isFetchingSessions) {
      setShowLoadingSkeleton(false)
    }
    if (!isFetchingSessions && isFirstRendering) {
      setFirstRendering(false)
    }
  }, [
    currentProjectId,
    previousProjectId,
    isFetchingSessions,
    isFirstRendering,
  ])

  const { remoteTests } = useRemoteTestsHook()
  const resources = sessions
    .filter(session => !session.remoteTestId)
    .map(s => ({ ...s, type: 'session' }))
    .concat(remoteTests.map(r => ({ ...r, type: 'remoteTest' })))
    .sort((a, b) => b.updatedAt - a.updatedAt)

  const sessionsByRemoteTest = useSelector(
    fromSessions.getSessionsGroupedByRemoteTest
  )

  const { posters } = usePosters(sessions.map(session => session.pk))
  return (
    <Wrapper fullHeight={resources.length === 0} {...props}>
      {showLoadingSkeleton && <SessionSkeleton />}
      {!showLoadingSkeleton && resources.length === 0 && (
        <ProjetEmptyState style={{ marginTop: '40px' }} />
      )}
      {!showLoadingSkeleton && resources.length > 0 && (
        <>
          {resources.map(resource => {
            if (resource.type === 'session') {
              const session = resource
              return (
                <StyledSessionCard
                  key={session.pk}
                  sessionId={session.pk}
                  title={session.title}
                  posterUrl={
                    posters?.find(poster => poster.sessionId === session.pk)
                      ?.posterUrl
                  }
                  data-test={`session-card-${session.title}`}
                  authorName={session.createdByUserName}
                  counter={session.commentsCounter || 0}
                  isPublic={session.isPublic}
                  isShared={session.isShared}
                  isPrivate={session.isPrivate}
                  sharedWithCounter={session.sharedWithCounter}
                  hasSettings={hasSettings}
                  onLoadingClick={() => {
                    if (isMobile()) {
                      onLoadingSessionClick()
                    }
                  }}
                  onClick={() => {
                    onSessionClick({
                      sessionId: session.pk,
                      projectId: session.projectId,
                    })
                  }}
                  onDelete={() => onSessionDelete(session.pk)}
                  onCopy={() => onSessionCopy(session.pk)}
                  onShare={() => onSessionShare(session.pk)}
                  onJiraClick={() => onJiraClick({ sessionId: session.pk })}
                  onTrelloClick={() => onTrelloClick({ sessionId: session.pk })}
                  onSlackClick={() => onSlackClick({ sessionId: session.pk })}
                />
              )
            }
            if (resource.type === 'remoteTest') {
              const remoteTest = resource
              return (
                <StyledRemoteTestCard
                  key={remoteTest.pk}
                  remoteTestId={remoteTest.pk}
                  title={remoteTest.title}
                  posterUrl={
                    posters?.find(poster => poster.sessionId === remoteTest.pk)
                      ?.posterUrl
                  }
                  data-test={`remote-test-card-${remoteTest.title}`}
                  authorName={remoteTest.createdByUserName}
                  counter={remoteTest.commentsCounter || 0}
                  isPublic={remoteTest.isPublic}
                  isShared={remoteTest.isShared}
                  isPrivate={remoteTest.isPrivate}
                  sharedWithCounter={remoteTest.sharedWithCounter}
                  hasSettings={hasSettings}
                  sessions={sessionsByRemoteTest[remoteTest.pk] || []}
                  onClick={() => {
                    onRemoteTestClick({
                      remoteTestId: remoteTest.pk,
                      projectId: remoteTest.projectId,
                    })
                  }}
                  onDelete={() => onRemoteTestDelete(remoteTest)}
                  onEdit={() => onRemoteTestEdit(remoteTest.pk)}
                  onShare={() => onRemoteTestShare(remoteTest)}
                  onLink={() =>
                    onRemoteTestLink({
                      title: remoteTest.title,
                      remoteTestId: remoteTest.pk,
                    })
                  }
                  onPreview={() =>
                    window.open(getRemoteTestUrl(remoteTest.pk), '_blank')
                  }
                  onSessionClick={({ sessionId, projectId }) => {
                    onSessionClick({
                      sessionId,
                      projectId,
                    })
                  }}
                  onSessionShare={sessionId => onSessionShare(sessionId)}
                  onSessionDelete={sessionId =>
                    onRemoteTestSessionDelete(sessionId)
                  }
                />
              )
            }
            return null
          })}
        </>
      )}
    </Wrapper>
  )
}

SessionsListMobile.propTypes = {
  hasSettings: PropTypes.bool,
  onLoadingSessionClick: PropTypes.func,
  onSessionClick: PropTypes.func,
  onSessionDelete: PropTypes.func,
  onSessionCopy: PropTypes.func,
  onSessionShare: PropTypes.func,
  onRemoteTestClick: PropTypes.func,
  onRemoteTestDelete: PropTypes.func,
  onRemoteTestEdit: PropTypes.func,
  onRemoteTestShare: PropTypes.func,
  onRemoteTestPreview: PropTypes.func,
  onRemoteTestLink: PropTypes.func,
  onRemoteTestSessionDelete: PropTypes.func,
  onJiraClick: PropTypes.func,
  onTrelloClick: PropTypes.func,
  onSlackClick: PropTypes.func,
}

SessionsListMobile.defaultProps = {
  hasSettings: false,
  onLoadingSessionClick: () => {},
  onSessionClick: () => {},
  onSessionDelete: () => {},
  onSessionCopy: () => {},
  onSessionShare: () => {},
  onRemoteTestClick: () => {},
  onRemoteTestDelete: () => {},
  onRemoteTestEdit: () => {},
  onRemoteTestShare: () => {},
  onRemoteTestPreview: () => {},
  onRemoteTestLink: () => {},
  onRemoteTestSessionDelete: () => {},
  onJiraClick: () => {},
  onTrelloClick: () => {},
  onSlackClick: () => {},
}

SessionsListMobile.whyDidYouRender = false

export default SessionsListMobile
