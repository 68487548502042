import { useDispatch, useSelector } from 'react-redux'
import fromUploads from '../../store/uploads/upload.selectors'
import {
  createPendingUploads,
  deletePendingUploads,
  resetPendingUploads,
  setPendingUploads,
} from '../../store/uploads/upload.actions'
import {
  UPLOAD_STATE_ERROR,
  UPLOAD_STATE_UPLOADED,
  UPLOAD_STATE_UPLOADING,
  UPLOAD_STATE_WAITING,
} from '../../store/uploads/upload.constants'

const useUploads = () => {
  const dispatch = useDispatch()
  const pending = useSelector(fromUploads.getPendingUploads)

  const getUpload = sessionId => pending[sessionId]
  const isUploading = sessionId => {
    return (
      pending[sessionId] &&
      [
        UPLOAD_STATE_UPLOADING,
        UPLOAD_STATE_WAITING,
        UPLOAD_STATE_ERROR,
      ].includes(pending[sessionId].status)
    )
  }
  const isSomethingUploading = () => {
    return Object.values(pending).some(el =>
      [
        UPLOAD_STATE_UPLOADING,
        UPLOAD_STATE_WAITING,
        UPLOAD_STATE_ERROR,
      ].includes(el.status)
    )
  }
  const getUploads = () => pending
  const getUploadsAsArray = () => {
    return Object.keys(pending).map(id => ({ id, ...pending[id] }))
  }
  const getCurrentUpload = () =>
    getUploadsAsArray().find(u => u.status === UPLOAD_STATE_UPLOADING)
  const createUpload = (sessionId, data) => {
    return dispatch(createPendingUploads(sessionId, data))
  }
  const deleteUpload = sessionId => dispatch(deletePendingUploads(sessionId))
  const resetUploads = () => dispatch(resetPendingUploads())
  const setUpload = (sessionId, obj) => {
    return dispatch(setPendingUploads(sessionId, obj))
  }
  const getRemainingUploadsAsArray = () => {
    return getUploadsAsArray().filter(
      u => ![UPLOAD_STATE_UPLOADED, UPLOAD_STATE_ERROR].includes(u.status)
    )
  }

  const getErroneousUploadsAsArray = () => {
    return getUploadsAsArray().filter(u => u.status === UPLOAD_STATE_ERROR)
  }

  return {
    getUpload,
    getCurrentUpload,
    getUploads,
    getRemainingUploadsAsArray,
    getErroneousUploadsAsArray,
    getUploadsAsArray,
    createUpload,
    deleteUpload,
    resetUploads,
    setUpload,
    isUploading,
    isSomethingUploading,
  }
}

export default useUploads
