import Quill from 'quill'
import indexOfAll from '../../../util/indexOfAll'

const DEFAULT_OPTIONS = {
  paste: true,
  type: true,
}

const urlPatterns = [/https?:\/\/[^\s]+/, /www\.[^\s]+/]

const createUrlKeyboardHandler = (urlRegexp, quill) => (range, context) => {
  const prefixMatch = context.prefix.match(urlRegexp)
  if (prefixMatch === null) return true
  const prefixLength = prefixMatch[0].length
  const prefixStart = range.index - prefixLength
  const url = quill.getText(prefixStart, prefixLength)
  quill.formatText(prefixStart, prefixLength, { link: url }, 'user')
  return true
}

const registerTypeListener = quill => {
  urlPatterns.forEach(baseUrlPattern => {
    const urlPattern = new RegExp(`${baseUrlPattern.source}$`)
    quill.keyboard.addBinding({
      collapsed: true,
      key: ' ',
      prefix: urlPattern,
      handler: createUrlKeyboardHandler(urlPattern, quill),
    })
  })
}

const registerPasteListener = quill => {
  quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
    if (typeof node.data !== 'string') {
      return delta
    }

    const combinedPattern = urlPatterns
      .map(pattern => `(${pattern.source})`)
      .join('|')

    const combinedRegexp = new RegExp(combinedPattern, 'g')

    const insertedText = delta.ops[0].insert
    const isUrl = combinedRegexp.test(insertedText)
    if (isUrl) {
      // eslint-disable-next-line no-param-reassign
      delta.ops[0].attributes = {
        link: insertedText,
      }
      return delta
    }

    return delta
  })
}

export const formatAllLinks = (quill, format) => {
  const text = quill.getText(0)

  const combinedPattern = urlPatterns
    .map(pattern => `(${pattern.source})`)
    .join('|')

  const combinedRegexp = new RegExp(combinedPattern, 'g')
  const urls = text.match(combinedRegexp) || []
  urls.forEach(url => {
    indexOfAll(text, url).forEach(index => {
      quill.formatText(index, url.length, format, 'api')
    })
  })
}

class AutoLink {
  constructor(quill, options = {}) {
    const opts = { ...DEFAULT_OPTIONS, ...options }

    if (opts.type) {
      registerTypeListener(quill)
    }
    if (opts.paste) {
      registerPasteListener(quill)
    }
  }
}

Quill.register('modules/autoLink', AutoLink)

export default AutoLink
