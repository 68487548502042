import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import {
  Dropdown,
  Multiselect,
  Field,
  Menu,
  Item,
} from '@zendeskgarden/react-dropdowns'
import { Tag } from '@zendeskgarden/react-tags'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)``

const StyledTag = styled(Tag)`
  background-color: ${prop('theme.colors.secondary100')};
  color: ${prop('theme.colors.secondary')};

  :hover {
    background-color: ${prop('theme.colors.secondary200')};
    color: ${prop('theme.colors.secondary')};
  }
`

const StyledTagClose = styled(Tag.Close)`
  svg {
    color: ${prop('theme.colors.secondary')};
  }

  :hover svg {
    color: ${prop('theme.colors.secondary700')};
  }
`

const WorkspaceEmailDomains = ({
  emailDomainsList,
  selectedEmailDomains,
  onUpdateWorkspaceEmailDomains,
  ...props
}) => {
  const [selectedItems, setSelectedItems] = useState(selectedEmailDomains)
  const [inputValue, setInputValue] = useState('')
  const [matchingOptions, setMatchingOptions] = useState(emailDomainsList)

  const setMatchingOptionsHandler = useCallback(
    debounce(value => {
      if (!emailDomainsList) {
        if (value) {
          setMatchingOptions([...selectedItems, value.replace('@', '')])
        }
        return
      }
      const matchedOptions = emailDomainsList.filter(option => {
        return (
          option
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1
        )
      })

      setMatchingOptions(matchedOptions)
    }, 300),
    [emailDomainsList?.length]
  )

  useEffect(() => {
    setMatchingOptionsHandler(inputValue)
  }, [inputValue, setMatchingOptionsHandler])

  const renderOptions = () => {
    if (
      (!matchingOptions || matchingOptions?.length === 0) &&
      !emailDomainsList
    ) {
      return <Item disabled>Write a domain to add</Item>
    }
    if (matchingOptions?.length === 0) {
      return (
        <Item disabled>Type an email domain of a member of this workspace</Item>
      )
    }

    return matchingOptions.map(option => (
      <Item key={option} value={option}>
        <span>{option}</span>
      </Item>
    ))
  }

  return (
    <Wrapper {...props}>
      <Dropdown
        inputValue={inputValue}
        selectedItems={selectedItems}
        onSelect={items => {
          setSelectedItems(items)
          onUpdateWorkspaceEmailDomains(items)
        }}
        downshiftProps={{ defaultHighlightedIndex: 0 }}
        onInputValueChange={value => setInputValue(value)}
      >
        <Field>
          <Multiselect
            placeholder="Email domains..."
            renderItem={({ value, removeValue }) => (
              <StyledTag>
                <span>{value}</span>
                <StyledTagClose onClick={() => removeValue()} />
              </StyledTag>
            )}
          />
        </Field>
        <Menu>{renderOptions()}</Menu>
      </Dropdown>
    </Wrapper>
  )
}

WorkspaceEmailDomains.propTypes = {
  emailDomainsList: PropTypes.arrayOf(PropTypes.string),
  selectedEmailDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdateWorkspaceEmailDomains: PropTypes.func,
}

WorkspaceEmailDomains.defaultProps = {
  emailDomainsList: null,
  onUpdateWorkspaceEmailDomains: () => {},
}

export default WorkspaceEmailDomains
