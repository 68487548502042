import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import NotFoundMobile from '../NotFoundMobile'
import NotAuthorized from '../NotAuthorized'
import MainAppPage from '../../pages/MainAppPage'
import MainAppPageMobile from '../../pages/MainAppPage/MainAppPageMobile'
import AnonymousMainAppPage from '../../pages/AnonymousMainAppPage'
import AnonymousMainAppPageMobile from '../../pages/AnonymousMainAppPage/AnonymousMainAppPageMobile'
import {
  setCurrentSession,
  getSessionRequest,
} from '../../../store/sessions/sessions.actions'
import { setCurrentProject } from '../../../store/projects/projects.actions'
import { setCurrentOrganization } from '../../../store/organizations/organizations.actions'
import { isMobile } from '../../../lib/platform'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { MAIN_VIEWS } from '../../pages/MainAppPage/MainAppPage.constants'
import { useGoogleLogin } from '@react-oauth/google'
import {
  isGoogleLogin,
  isGoogleSignedIn,
  googleSignIn,
} from '../../../util/auth'

const SessionProxy = ({ match, initialCommentId }) => {
  const { isLogged: isUserLogged } = useUserInfo()

  const sessionId = get(match, 'params.sessionId')
  const [isLoading, setLoading] = useState(true)
  const [isSessionFound, setSessionFound] = useState(false)
  const [isUserAuthorized, setUserAuthorized] = useState(false)
  const [session, setSession] = useState(null)

  const dispatch = useDispatch()

  const loginWithGoogle = useGoogleLogin({
    onSuccess: codeResponse =>
      googleSignIn(
        codeResponse,
        () => {
          fetchSession()
        },
        () => {
          window.location.href = `/login?redirect=session:${sessionId}`
        }
      ),
    flow: 'auth-code',
  })

  const fetchSession = () =>
    dispatch(getSessionRequest(sessionId))
      .then(({ session: _session }) => {
        setSession(_session)
        const isPublic = _session?.sharing?.isPublic
        if (!isPublic && _session?.project?.organizationId) {
          dispatch(setCurrentOrganization(_session.project.organizationId))
        }
        if (!isPublic && _session?.projectId) {
          dispatch(setCurrentProject(_session.projectId))
        }
        dispatch(setCurrentSession(_session.pk))
        setUserAuthorized(true)
        setSessionFound(true)
      })
      .catch(err => {
        console.error(err)
        const errorMessage = err.toString().toLowerCase()
        if (errorMessage.includes('not found')) {
          setSessionFound(false)
        }
        if (errorMessage.includes('not authorized')) {
          setUserAuthorized(false)
          setSessionFound(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })

  useEffect(() => {
    if (isUserLogged) {
      if (isGoogleLogin()) {
        isGoogleSignedIn().then(isLoggedIn => {
          if (isLoggedIn) {
            fetchSession()
          }

          if (!isLoggedIn) {
            loginWithGoogle()
          }
        })
        return
      }
    }
    fetchSession()
  }, [])

  if (!sessionId) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  if (isLoading) {
    return <FullScreenLoader />
  }

  if (!isLoading && !isSessionFound) {
    return <NotFoundMobile />
  }

  if (!isLoading && !isUserAuthorized) {
    return <NotAuthorized />
  }

  if (!isLoading && session && !isUserLogged) {
    return isMobile() ? (
      <AnonymousMainAppPageMobile isStandalone />
    ) : (
      <AnonymousMainAppPage />
    )
  }

  if (!isLoading && session && isUserLogged) {
    const Component = isMobile() ? MainAppPageMobile : MainAppPage
    return (
      <Component
        initialView={{
          name: MAIN_VIEWS.session,
          props: { isEditMode: true, isRecording: false, initialCommentId },
        }}
      />
    )
  }

  return null
}

SessionProxy.propTypes = {
  match: PropTypes.shape({}).isRequired,
  initialCommentId: PropTypes.string,
}

SessionProxy.defaultProps = {
  initialCommentId: null,
}

export default SessionProxy
