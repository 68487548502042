import React from 'react'
import UploadAccordion from '../UploadAccordion'
import useUploads from '../../hooks/useUploads'
import {
  UPLOAD_STATE_ERROR,
  UPLOAD_STATE_UPLOADED,
} from '../../../store/uploads/upload.constants'

const MIN_MEGABIT_SPEED = 1

const checkSpeedCondition = speed => {
  let isSlow = true
  // Do not evaluate speed zero
  // because it means no upload
  // has started
  if (speed === 0) {
    isSlow = false
  } else if (speed !== 0 && speed >= MIN_MEGABIT_SPEED) {
    isSlow = false
  }
  return isSlow
}

const convertToAccordionItem = (onCancel, onRetry) => i => ({
  title: i.name,
  percentageProgress: i.progress,
  downloaded: i.uploaded,
  total: i.size,
  uploaded: i.status === UPLOAD_STATE_UPLOADED,
  hasError: i.status === UPLOAD_STATE_ERROR,
  onCancel: () => onCancel(i.id),
  onRetry: () => onRetry(i.id),
})

const UploadAccordionProxy = () => {
  const {
    getUploadsAsArray,
    resetUploads,
    getRemainingUploadsAsArray,
    deleteUpload,
    getCurrentUpload,
    getErroneousUploadsAsArray,
  } = useUploads()

  // This may be undefined
  const upload = getCurrentUpload()
  const errors = getErroneousUploadsAsArray()
  const uploads = getUploadsAsArray()
  const remaining = getRemainingUploadsAsArray()
  const items = uploads.map(convertToAccordionItem(deleteUpload, () => {}))

  return uploads.length ? (
    <UploadAccordion
      poorConnection={checkSpeedCondition(upload?.speed || 0)}
      remaining={remaining.length}
      total={uploads.length}
      completed={!remaining.length}
      onExit={() => resetUploads()}
      items={items}
      errorsNumber={errors.length}
    />
  ) : null
}

export default UploadAccordionProxy
