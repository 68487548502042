import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Header } from '@zendeskgarden/react-chrome'
import { Dropdown, Menu, Item, Trigger } from '@zendeskgarden/react-dropdowns'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import IconButton from '../IconButton'
import Flex from '../../atoms/Flex'
import CurrentUserAvatar from '../../atoms/UserAvatar/CurrentUserAvatar'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { getTopNotch } from '../../hooks/useNotch'
import theme from '../../theme/old'

const variants = {
  white: {
    backgroundColor: 'white',
    color: theme.colors.primary,
    boxShadow: theme.boxShadows.topBarElevation,
  },
  black: {
    backgroundColor: theme.colors.primary,
    color: 'white',
    boxShadow: theme.boxShadows.topBarElevation,
  },
  transparent: {
    backgroundColor: 'white',
    color: theme.colors.primary,
    boxShadow: 'none',
  },
}

const topNotch = getTopNotch()
const barHeight = BAR_HEIGHT + topNotch

const Wrapper = styled(Flex)``

const StyledHeader = styled(Header)`
  flex: 0 0 ${barHeight}px;
  height: ${barHeight}px;
  order: 0;
  z-index: 100;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ variant }) => variants[variant].boxShadow};
  && {
    background-color: ${({ variant }) => variants[variant].backgroundColor};
    color: ${({ variant }) => variants[variant].color};
    border-bottom: none;

    svg {
      fill: ${({ variant }) => variants[variant].color};
    }
  }
`

const LeftContent = styled(Flex)`
  flex: 0 1 ${barHeight}px;
  min-width: ${barHeight}px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

const CentralContent = styled(Flex)`
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  && {
    background-color: ${({ variant }) => variants[variant].backgroundColor};
    color: ${({ variant }) => variants[variant].color};
  }
`

const RightContent = styled(Flex)`
  flex: 0 1 ${barHeight}px;
  min-width: ${barHeight}px;
  align-items: flex-end;
`

export const MENU_BUTTON_CLASS = 'menu-button'

const MobileTopBar = ({
  onButtonClick,
  onRightButtonClick,
  icon,
  rightIcon,
  children,
  showRightMenu,
  showUserAvatar,
  variant,
  onUserAvatarClick,
  unreadNotificationsCount,
  ...props
}) => {
  const { isLogged } = useUserInfo()
  return (
    <Wrapper {...props}>
      <StyledHeader variant={variant}>
        <LeftContent variant={variant}>
          {icon && (
            <IconButton
              className={MENU_BUTTON_CLASS}
              icon={icon}
              onClick={onButtonClick}
              isPrimary={false}
              isBasic
            />
          )}
          {isLogged && showUserAvatar && (
            <CurrentUserAvatar
              size="small"
              style={{ marginLeft: 8 }}
              onClick={onUserAvatarClick}
              badge={
                unreadNotificationsCount === 0
                  ? undefined
                  : unreadNotificationsCount
              }
            />
          )}
        </LeftContent>
        <CentralContent variant={variant}>{children}</CentralContent>
        <RightContent>
          {rightIcon && (
            <IconButton
              icon={rightIcon}
              onClick={onRightButtonClick}
              isPrimary={false}
              isBasic
            />
          )}
          {showRightMenu && (
            <Dropdown onSelect={item => console.log(item)}>
              <Trigger>
                <IconButton icon={DotsVerticalIcon} />
              </Trigger>
              <Menu placement="bottom">
                <Item value="delete-session">Delete</Item>
              </Menu>
            </Dropdown>
          )}
        </RightContent>
      </StyledHeader>
    </Wrapper>
  )
}

MobileTopBar.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  rightIcon: PropTypes.node,
  unreadNotificationsCount: PropTypes.number,
  onButtonClick: PropTypes.func,
  onRightButtonClick: PropTypes.func,
  onUserAvatarClick: PropTypes.func,
  showUserAvatar: PropTypes.bool,
  showRightMenu: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(variants)),
}

MobileTopBar.defaultProps = {
  children: null,
  icon: null,
  rightIcon: null,
  unreadNotificationsCount: undefined,
  onButtonClick: () => {},
  onRightButtonClick: () => {},
  onUserAvatarClick: () => {},
  showUserAvatar: false,
  showRightMenu: false,
  variant: Object.keys(variants)[0],
}

MobileTopBar.whyDidYouRender = false

export default MobileTopBar
