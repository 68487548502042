import { all, call, put, take, takeEvery } from 'redux-saga/effects'
import gql from 'graphql-tag'
import get from 'lodash/get'
import omit from 'lodash/omit'
import omitBy from 'lodash/fp/omitBy'
import pipe from 'lodash/fp/pipe'
import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'
import {
  updateUser as updateUserGql,
  createUser as createUserGql,
} from '../../graphql/mutations'
import analytics from '../../lib/analytics'
import { getCurrentUser as getCurrentUserGql } from '../../graphql/queries'
import { LOGIN_REQUEST_SUCCESS, LOGOUT_REQUEST_SUCCESS } from '../actions'
import {
  GET_USER_REQUEST,
  getUserRequest,
  getUserRequestSuccess,
  getUserRequestFailure,
  CREATE_USER_REQUEST,
  createUserRequestSuccess,
  createUserRequestFailure,
  UPDATE_USER_REQUEST,
  updateUserRequestSuccess,
  updateUserRequestFailure,
  setUserLogged,
  setUserNotLogged,
} from './user.actions'

function* getUser({ apolloClient }, { meta: { thunk } }) {
  try {
    const data = yield call(apolloClient.query, {
      query: gql(getCurrentUserGql),
      fetchPolicy: 'network-only',
    })

    const _userData = get(data, 'data.getCurrentUser')

    if (_userData) {
      yield put(setUserLogged())
    } else {
      yield put(setUserNotLogged())
    }

    if (_userData) {
      const userData = omit(
        {
          ..._userData,
          identityId: _userData.pk.replace('user:', ''),
        },
        'pk'
      )
      yield put(getUserRequestSuccess(userData, thunk))
    } else {
      yield put(getUserRequestFailure({ error: 'Not authenticated' }, thunk))
    }
  } catch (err) {
    yield put(getUserRequestFailure(err, thunk))
  }
}

function* getUserWrapper() {
  yield take('AFTER_LOGIN')
  yield put(getUserRequest())
  yield put(setUserLogged())
}

function* createUser(
  { apolloClient },
  {
    payload: {
      identityId,
      email,
      name,
      picture,
      role,
      plan,
      workspaceName,
      workspacePictureUrl,
      workspaceBackgroundColor,
      workspaceShareByEmailDomain,
      workspaceMembers,
      avatarBackgroundColor,
      isGoogle,
      userRegistrationData,
    },
    meta: { thunk },
  }
) {
  try {
    const userData = pipe(
      omitBy(isUndefined),
      omitBy(isNil)
    )({
      identityId,
      email,
      name,
      picture,
      role,
      plan,
      workspaceName,
      workspacePictureUrl,
      workspaceBackgroundColor,
      workspaceShareByEmailDomain,
      workspaceMembers,
      avatarBackgroundColor,
      isGoogle,
      userRegistrationData,
    })

    yield call(apolloClient.mutate, {
      mutation: gql(createUserGql),
      fetchPolicy: 'no-cache',
      variables: userData,
    })

    try {
      analytics.trackOnboardingComplete({ identityId })
    } catch (err) {
      console.error('Error while tracking onboarding complete', err)
    }

    yield put(createUserRequestSuccess(userData, thunk))
  } catch (err) {
    yield put(createUserRequestFailure(err, thunk))
  }
}

function* updateUser(
  { apolloClient },
  {
    payload: {
      identityId,
      email,
      name,
      picture,
      role,
      avatarBackgroundColor,
      notifications,
    },
    meta: { thunk },
  }
) {
  try {
    const userData = pipe(
      omitBy(isUndefined),
      omitBy(isNil)
    )({
      identityId,
      email,
      name,
      picture,
      role,
      avatarBackgroundColor,
      notifications,
    })
    yield call(apolloClient.mutate, {
      mutation: gql(updateUserGql),
      fetchPolicy: 'no-cache',
      variables: userData,
    })
    yield put(updateUserRequestSuccess(userData, thunk))
  } catch (err) {
    yield put(updateUserRequestFailure(err, thunk))
  }
}

function* handleLogout() {
  yield put(setUserNotLogged())
}

export default function* userSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, getUserWrapper, { apolloClient }),
    yield takeEvery(LOGOUT_REQUEST_SUCCESS, handleLogout, { apolloClient }),
    yield takeEvery(GET_USER_REQUEST, getUser, { apolloClient }),
    yield takeEvery(CREATE_USER_REQUEST, createUser, { apolloClient }),
    yield takeEvery(UPDATE_USER_REQUEST, updateUser, { apolloClient }),
  ])
}
