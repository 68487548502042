import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import { getAppSyncClient } from '../../lib/appSyncClient'
import { createOrganization as createOrganizationGql } from '../../graphql/mutations'
import {
  updateOrganizationRequest,
  deleteOrganizationRequest,
  CREATE_NEW_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_REQUEST,
  setCurrentOrganization,
} from '../organizations/organizations.actions'
import fromOrganizations, {
  PERSONAL_ORGANIZATION_ID,
} from '../organizations/organizations.selectors'
import fromUsers from '../users/users.selectors'

const apolloClient = getAppSyncClient()

const createOrganizationPending = state =>
  pending(state, CREATE_NEW_ORGANIZATION_REQUEST)
const createOrganizationDone = state =>
  done(state, CREATE_NEW_ORGANIZATION_REQUEST)
const createOrganizationFulfilled = state =>
  fulfilled(state, CREATE_NEW_ORGANIZATION_REQUEST)
const createOrganizationRejected = state =>
  rejected(state, CREATE_NEW_ORGANIZATION_REQUEST)

const updateOrganizationPending = state =>
  pending(state, UPDATE_ORGANIZATION_REQUEST)
const updateOrganizationDone = state => done(state, UPDATE_ORGANIZATION_REQUEST)
const updateOrganizationFulfilled = state =>
  fulfilled(state, UPDATE_ORGANIZATION_REQUEST)
const updateOrganizationRejected = state =>
  rejected(state, UPDATE_ORGANIZATION_REQUEST)

const deleteOrganizationPending = state =>
  pending(state, DELETE_ORGANIZATION_REQUEST)
const deleteOrganizationDone = state => done(state, DELETE_ORGANIZATION_REQUEST)
const deleteOrganizationFulfilled = state =>
  fulfilled(state, DELETE_ORGANIZATION_REQUEST)
const deleteOrganizationRejected = state =>
  rejected(state, DELETE_ORGANIZATION_REQUEST)

const useCRUDOrganization = organizationId => {
  const organization = useSelector(
    fromOrganizations.getOrganizationById(organizationId)
  )
  const createdBy = get(organization, 'createdBy')
  const organizationUser = useSelector(fromUsers.getUserById(createdBy))

  const dispatch = useDispatch()

  const onCreateOrganizationHandler = useCallback(
    title =>
      apolloClient.mutate({
        mutation: gql(createOrganizationGql),
        variables: {
          input: {
            title,
          },
        },
      }),
    [apolloClient]
  )

  const isCreateOrganizationPending = useSelector(createOrganizationPending)
  const isCreateOrganizationDone = useSelector(createOrganizationDone)
  const isCreateOrganizationFulfilled = useSelector(createOrganizationFulfilled)
  const isCreateOrganizationRejected = useSelector(createOrganizationRejected)

  const onUpdateOrganizationHandler = useCallback(
    ({ title, pictureUrl, backgroundColor }) =>
      dispatch(
        updateOrganizationRequest({
          organizationId,
          title,
          pictureUrl,
          backgroundColor,
        })
      ),
    [dispatch]
  )
  const isUpdateOrganizationPending = useSelector(updateOrganizationPending)
  const isUpdateOrganizationDone = useSelector(updateOrganizationDone)
  const isUpdateOrganizationFulfilled = useSelector(updateOrganizationFulfilled)
  const isUpdateOrganizationRejected = useSelector(updateOrganizationRejected)

  const onDeleteOrganizationHandler = useCallback(
    () =>
      dispatch(deleteOrganizationRequest(organizationId)).then(() => {
        setCurrentOrganization(PERSONAL_ORGANIZATION_ID)
        return true
      }),
    [dispatch]
  )
  const isDeleteOrganizationPending = useSelector(deleteOrganizationPending)
  const isDeleteOrganizationDone = useSelector(deleteOrganizationDone)
  const isDeleteOrganizationFulfilled = useSelector(deleteOrganizationFulfilled)
  const isDeleteOrganizationRejected = useSelector(deleteOrganizationRejected)

  return {
    organization,
    organizationUser,
    onCreateOrganizationHandler,
    onUpdateOrganizationHandler,
    isUpdateOrganizationPending,
    isUpdateOrganizationDone,
    isUpdateOrganizationFulfilled,
    isUpdateOrganizationRejected,
    isCreateOrganizationPending,
    isCreateOrganizationDone,
    isCreateOrganizationFulfilled,
    isCreateOrganizationRejected,
    onDeleteOrganizationHandler,
    isDeleteOrganizationPending,
    isDeleteOrganizationDone,
    isDeleteOrganizationFulfilled,
    isDeleteOrganizationRejected,
  }
}

export default useCRUDOrganization
