import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'
import EmojiButton from '../EmojiButton'
import Flex from '../../atoms/Flex'
import RichEditorWithMentions from '../RichEditorWithMentions'
import useMentionSuggestions from '../../../store/hooks/useMentionSuggestions'
import useEmojiPicker from '../EmojiButton/useEmojiPicker'
import { MentionSuggestionType } from '../MentionSuggestion/MentionSuggestion'
import { isMobile } from '../../../lib/platform'

const Wrapper = styled(Flex)``

const CommentTextWrapper = styled(Flex)`
  position: relative;
`

const StyledRichEditorWithMentions = styled(RichEditorWithMentions)`
  margin: 0;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 6px;
  color: ${prop('theme.colors.grey800')};
  border-color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.secondary300 : theme.colors.grey100};
  ${({ isClicked }) => (isClicked ? 'padding-bottom: 24px;' : '')}
`

const StyledEmojiButton = styled(EmojiButton)`
  && {
    position: absolute;
    bottom: 6px;
    right: 8px;
  }

  && button {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`

const CommentEditableText = ({
  autoFocusToken,
  placeholder,
  initialValue,
  resetToken,
  onChange,
  onAddedMention,
  onRemovedMention,
  mentions,
  onFocus,
  onBlur,
  ...props
}) => {
  const { suggestions } = useMentionSuggestions()
  const [isTextAreaClicked, setTextAreaClicked] = useState(false)
  const [isTextAreaFocused, setTextAreaFocused] = useState(false)

  const newCommentRef = useRef(null)

  const {
    isEmojiPickerVisible,
    setEmojiPickerVisible,
    textareaCursorPosition,
    setTextareaCursorPosition,
    onTextKeyDown,
    insertEmoji,
  } = useEmojiPicker(newCommentRef)

  const onInputHandler = text => onChange(text)

  return (
    <Wrapper {...props}>
      <CommentTextWrapper>
        <StyledRichEditorWithMentions
          mentions={mentions}
          suggestions={suggestions}
          initialValue={initialValue}
          textRef={newCommentRef}
          isClicked={isTextAreaClicked}
          isFocused={isTextAreaFocused}
          autoFocusToken={autoFocusToken}
          resetToken={resetToken}
          placeholder={placeholder}
          onClick={() => setTextAreaClicked(true)}
          onInput={onInputHandler}
          onChange={onInputHandler}
          onFocus={() => {
            setTextAreaFocused(true)
            onFocus()
          }}
          onBlur={() => {
            setTextAreaFocused(false)
            onBlur()
          }}
          onKeyUp={onTextKeyDown}
          onMouseUp={onTextKeyDown}
          onAddedMention={onAddedMention}
          onRemovedMention={onRemovedMention}
        />
        {!isMobile() && (isTextAreaClicked || isTextAreaFocused) && (
          <StyledEmojiButton
            textRef={newCommentRef}
            isEmojiPickerVisible={isEmojiPickerVisible}
            setEmojiPickerVisible={setEmojiPickerVisible}
            textareaCursorPosition={textareaCursorPosition}
            setTextareaCursorPosition={setTextareaCursorPosition}
            insertEmoji={insertEmoji}
          />
        )}
      </CommentTextWrapper>
    </Wrapper>
  )
}

CommentEditableText.propTypes = {
  autoFocusToken: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onAddedMention: PropTypes.func,
  onRemovedMention: PropTypes.func,
  resetToken: PropTypes.string,
  mentions: PropTypes.arrayOf(MentionSuggestionType),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

CommentEditableText.defaultProps = {
  autoFocusToken: '',
  placeholder: '',
  initialValue: '',
  onChange: () => {},
  onAddedMention: () => {},
  onRemovedMention: () => {},
  resetToken: '',
  mentions: [],
  onFocus: () => {},
  onBlur: () => {},
}

export default CommentEditableText
