import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RefreshIcon from 'mdi-react/RefreshIcon'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'
import IconButton from '../IconButton'

const AnimatedImage = (
  <svg width="122" height="106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="dot-1" cx="61" cy="96" r="2" fill="#1CB358" />
    <circle id="dot-2" cx="61" cy="88" r="2" fill="#1CB358" />
    <circle id="dot-3" cx="61" cy="80" r="2" fill="#1CB358" />
    <g filter="url(#filter0_d)">
      <path
        d="M80 49.29a8.091 8.091 0 00.536-2.897c0-4.437-3.6-8.036-8.036-8.036-1.649 0-4.19 1.502-5.462 2.356C64.72 36.695 60.392 34 55.428 34c-7.399 0-13.392 5.993-13.392 13.393 0 .226.008.452.017.678C37.364 49.72 34 54.19 34 59.446 34 66.101 39.399 71.5 46.054 71.5h30.803c5.918 0 10.714-4.796 10.714-10.714 0-5.182-2.683-10.51-7.571-11.497z"
        fill="#fff"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.889 53.143A1.42 1.42 0 0054.3 54.57c.78 0 1.412-.64 1.412-1.428a1.42 1.42 0 00-1.412-1.429c-.78 0-1.411.64-1.411 1.429zm1.411-4.857c-2.65 0-4.8 2.174-4.8 4.857 0 2.682 2.15 4.857 4.8 4.857 2.652 0 4.8-2.175 4.8-4.857 0-2.683-2.148-4.857-4.8-4.857z"
      fill="#070822"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.975 51.429c-2.652 0-4.8 2.174-4.8 4.857V58h-3.39v-1.714c0-4.576 3.667-8.286 8.19-8.286h3.53v3.429h-3.53z"
      fill="#F4AA18"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.315 60.571c2.651 0 4.8-2.174 4.8-4.857V54h3.39v1.714c0 4.576-3.667 8.286-8.19 8.286h-3.53v-3.429h3.53z"
      fill="#6E15E0"
    />
    <path d="M49.5 60.571h9.883V64H49.5v-3.429z" fill="#1DB954" />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="121.571"
        height="105.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur id="blur1" stdDeviation="17" />
        <feColorMatrix values="0 0 0 0 0.709167 0 0 0 0 0.925 0 0 0 0 0.7696 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
    <animate
      xlinkHref="#blur1"
      attributeName="stdDeviation"
      values="10;30;10"
      dur="il pall1s"
      begin=".3s"
      repeatCount="indefinite"
    />
    <animate
      xlinkHref="#dot-1"
      attributeName="opacity"
      from="1"
      to="0"
      dur="1s"
      begin="0s"
      repeatCount="indefinite"
    />
    <animate
      xlinkHref="#dot-2"
      attributeName="opacity"
      from="1"
      to="0"
      dur="1s"
      begin=".1s"
      repeatCount="indefinite"
    />
    <animate
      xlinkHref="#dot-3"
      attributeName="opacity"
      from="1"
      to="0"
      dur="1s"
      begin=".2s"
      repeatCount="indefinite"
    />
  </svg>
)

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
`

const Image = styled(Flex)``

const Title = styled(Flex)`
  font-weight: 700;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 4px;
`
const Subtitle = styled(Flex)`
  margin-bottom: 24px;
  text-align: center;
`

const VideoUploading = ({ pictureUrl, userName, ...props }) => (
  <Wrapper {...props}>
    <Image>{AnimatedImage}</Image>
    <UserAvatar imgUrl={pictureUrl} name={userName} />
    <Title>{userName} is still uploading</Title>
    <Subtitle>Refresh the page in a few minutes</Subtitle>
    <IconButton
      icon={RefreshIcon}
      isBasic
      onClick={() => window.location.reload()}
    >
      Refresh
    </IconButton>
  </Wrapper>
)

VideoUploading.propTypes = {
  pictureUrl: PropTypes.string,
  userName: PropTypes.string,
}

VideoUploading.defaultProps = {
  pictureUrl: '',
  userName: '',
}

export default VideoUploading
