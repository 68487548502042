import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import {
  createNewProjectRequest,
  updateProjectRequest,
  deleteProjectRequest,
  CREATE_NEW_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
} from '../projects/projects.actions'
import fromProjects from '../projects/projects.selectors'
import fromOrganizations from '../organizations/organizations.selectors'
import fromUsers from '../users/users.selectors'

const createProjectPending = state => pending(state, CREATE_NEW_PROJECT_REQUEST)
const createProjectDone = state => done(state, CREATE_NEW_PROJECT_REQUEST)
const createProjectFulfilled = state =>
  fulfilled(state, CREATE_NEW_PROJECT_REQUEST)
const createProjectRejected = state =>
  rejected(state, CREATE_NEW_PROJECT_REQUEST)

const updateProjectPending = state => pending(state, UPDATE_PROJECT_REQUEST)
const updateProjectDone = state => done(state, UPDATE_PROJECT_REQUEST)
const updateProjectFulfilled = state => fulfilled(state, UPDATE_PROJECT_REQUEST)
const updateProjectRejected = state => rejected(state, UPDATE_PROJECT_REQUEST)

const deleteProjectPending = state => pending(state, DELETE_PROJECT_REQUEST)
const deleteProjectDone = state => done(state, DELETE_PROJECT_REQUEST)
const deleteProjectFulfilled = state => fulfilled(state, DELETE_PROJECT_REQUEST)
const deleteProjectRejected = state => rejected(state, DELETE_PROJECT_REQUEST)

const useCRUDProject = projectId => {
  const organizationId = useSelector(fromOrganizations.getCurrentOrganizationId)
  const project = useSelector(fromProjects.getProjectById(projectId))
  const createdBy = get(project, 'createdBy')
  const projectUser = useSelector(fromUsers.getUserById(createdBy))

  const dispatch = useDispatch()

  const onCreateProjectHandler = useCallback(
    (title, shareType, membersEmails) =>
      dispatch(
        createNewProjectRequest({
          organizationId,
          title,
          shareType,
          membersEmails,
        })
      ),
    [dispatch]
  )

  const isCreateProjectPending = useSelector(createProjectPending)
  const isCreateProjectDone = useSelector(createProjectDone)
  const isCreateProjectFulfilled = useSelector(createProjectFulfilled)
  const isCreateProjectRejected = useSelector(createProjectRejected)

  const onUpdateProjectHandler = useCallback(
    title =>
      dispatch(
        updateProjectRequest({
          projectId,
          title,
        })
      ),
    [dispatch]
  )

  const onUpdateProjectShareTypeHandler = useCallback(
    shareType =>
      dispatch(
        updateProjectRequest({
          projectId,
          shareType,
        })
      ),
    [dispatch]
  )

  const isUpdateProjectPending = useSelector(updateProjectPending)
  const isUpdateProjectDone = useSelector(updateProjectDone)
  const isUpdateProjectFulfilled = useSelector(updateProjectFulfilled)
  const isUpdateProjectRejected = useSelector(updateProjectRejected)

  const onDeleteProjectHandler = useCallback(
    _projectId => dispatch(deleteProjectRequest(_projectId)),
    [dispatch]
  )

  const isDeleteProjectPending = useSelector(deleteProjectPending)
  const isDeleteProjectDone = useSelector(deleteProjectDone)
  const isDeleteProjectFulfilled = useSelector(deleteProjectFulfilled)
  const isDeleteProjectRejected = useSelector(deleteProjectRejected)

  return {
    project,
    projectUser,
    onCreateProjectHandler,
    onUpdateProjectHandler,
    onUpdateProjectShareTypeHandler,
    onDeleteProjectHandler,
    isUpdateProjectPending,
    isUpdateProjectDone,
    isUpdateProjectFulfilled,
    isUpdateProjectRejected,
    isDeleteProjectPending,
    isDeleteProjectDone,
    isDeleteProjectFulfilled,
    isDeleteProjectRejected,
    isCreateProjectPending,
    isCreateProjectDone,
    isCreateProjectFulfilled,
    isCreateProjectRejected,
  }
}

export default useCRUDProject
