import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import wdtLoading from 'wdt-loading'
import Flex from '../../atoms/Flex'
import 'wdt-loading/wdtLoading.css'

const checkmarkKeyframes = keyframes`
  0% {
    height: 0;
    width: 7px;
    opacity: 1;
  }
  20% {
    height: 2px;
    width: 7px;
    opacity: 1;
  }
  40% {
    height: 5px;
    width: 7px;
    opacity: 1;
  }
  100% {
    height: 11px;
    width: 7px;
    opacity: 1;
  }
`

const Wrapper = styled(Flex)`
  display: block;

  .wdt-loading-screen {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
  }

  .wdt-loading-phrases {
    color: rgba(109, 21, 224, 1);
    width: 300px;
    margin: 0 auto;
    height: 106px;
    overflow: hidden;
    position: relative;
    padding: 10px 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  .wdt-loading-phrases:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(255, 255, 255, 1);

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  }

  .wdt-loading-phrase {
    padding-left: 30px;
    position: relative;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    transform: translateY(30px);
    line-height: 30px;
    height: 30px;
  }

  .wdt-loading-phrase:before {
    content: ' ';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 6px;
    left: 5px;
    background: rgba(109, 21, 224, 0.1);
    border: 1px solid rgba(109, 21, 224, 0.5);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white;
  }

  .wdt-loading-phrase:after {
    opacity: 0;
    height: 11px;
    width: 7px;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    border-right: 2px solid #6e14e0;
    border-top: 2px solid #6e14e0;
    border-radius: 0 !important;
    content: '';
    left: 7px;
    top: 15px;
    position: absolute;
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -o-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
  }

  .wdt-loading-phrase.wdt-checked:after {
    animation-delay: 100ms;
    animation-duration: 200ms;
    animation-timing-function: ease;
    animation-name: ${checkmarkKeyframes};
    transform: scaleX(-1) rotate(135deg);
    animation-fill-mode: forwards;
  }
`

const RotatingWords = () => {
  useEffect(() => {
    wdtLoading.start({
      category: 'default',
      speed: 1000,
    })

    return () => {
      wdtLoading.done()
    }
  }, [])
  return (
    <Wrapper>
      <div className="wdt-loading-screen">
        <div className="wdt-loading-phrases">
          <div className="wdt-loading-phrase-category" data-category="default">
            <div className="wdt-loading-phrase">Collecting projects...</div>
            <div className="wdt-loading-phrase">Checking permissions...</div>
            <div className="wdt-loading-phrase">Feeding the unicorns...</div>
            <div className="wdt-loading-phrase">Optimising resources...</div>
            <div className="wdt-loading-phrase">Granting access...</div>
            <div className="wdt-loading-phrase">Pushing pixels...</div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default RotatingWords
