import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import prop from 'lodash/fp/prop'
import {
  Dropdown,
  Multiselect,
  Field,
  Menu,
  Item,
} from '@zendeskgarden/react-dropdowns'
import { Tag } from '@zendeskgarden/react-tags'
import Flex from '../../atoms/Flex'
import PageEventIcon from '../../organisms/PageEvents/PageEventIcon'
import { PAGE_EVENT_TYPES } from '../../organisms/PageEvents/pageEvents.constants'

export const FILTER_OPTIONS = {
  navigation: 'Navigation',
  click: 'Click',
  consoleLog: 'Logs',
  consoleWarn: 'Warnings',
  consoleError: 'Errors',
  webRequest: 'Network Requests',
  webErrorRequest: 'Network Errors',
}

const getEventType = option => {
  if (option === FILTER_OPTIONS.consoleLog) {
    return PAGE_EVENT_TYPES.consoleLog
  }
  if (option === FILTER_OPTIONS.consoleWarn) {
    return PAGE_EVENT_TYPES.consoleWarn
  }
  if (option === FILTER_OPTIONS.consoleError) {
    return PAGE_EVENT_TYPES.consoleError
  }
  if (option === FILTER_OPTIONS.navigation) {
    return PAGE_EVENT_TYPES.navigation
  }
  if (option === FILTER_OPTIONS.click) {
    return PAGE_EVENT_TYPES.click
  }
  if (option === FILTER_OPTIONS.webRequest) {
    return PAGE_EVENT_TYPES.webRequest
  }
  if (option === FILTER_OPTIONS.webErrorRequest) {
    return PAGE_EVENT_TYPES.webErrorRequest
  }
  return ''
}

const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${prop('theme.colors.grey200')};

  [data-garden-id='forms.faux_input'] {
    border: none;
    border-radius: 0;
    padding: 16px;

    :focus {
      box-shadow: none !important;
    }
  }

  [data-garden-id='dropdowns.faux_input'][aria-expanded='true'] {
    box-shadow: none !important;
  }
`

const StyledPageEventIcon = styled(PageEventIcon)`
  margin-right: 8px;
`

const StyledTag = styled(Tag)`
  background-color: ${prop('theme.colors.secondary100')};
  border: 1px solid ${prop('theme.colors.secondary300')};
  color: ${prop('theme.colors.secondary')};

  :hover {
    border: 1px solid ${prop('theme.colors.secondary400')};
    color: ${prop('theme.colors.secondary800')};

    svg,
    svg path {
      fill: ${prop('theme.colors.secondary800')};
      stroke: ${prop('theme.colors.secondary800')};
    }
  }

  svg,
  svg path {
    fill: ${prop('theme.colors.secondary')};
    stroke: ${prop('theme.colors.secondary')};
  }
`

const PageEventsFilter = ({ selectedItems, onFilterUpdate, ...props }) => {
  const options = Object.values(FILTER_OPTIONS)
  const [_selectedItems, setSelectedItems] = useState(selectedItems)
  useEffect(() => {
    onFilterUpdate(selectedItems)
  }, [])
  const [inputValue, setInputValue] = useState('')
  const [matchingOptions, setMatchingOptions] = useState(options)

  const filterMatchingOptionsRef = useRef(
    debounce(value => {
      const matchedOptions = options.filter(option => {
        return (
          option
            .trim()
            .toLowerCase()
            .indexOf(value.trim().toLowerCase()) !== -1
        )
      })

      setMatchingOptions(matchedOptions)
    }, 300)
  )

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue)
  }, [inputValue])

  const renderOptions = () => {
    if (matchingOptions.length === 0) {
      return <Item disabled>No matches found</Item>
    }

    return matchingOptions.map(option => (
      <Item key={option} value={option}>
        <Flex direction="row" alignItems="center">
          <StyledPageEventIcon eventType={getEventType(option)} size={16} />
          <span>{option}</span>
        </Flex>
      </Item>
    ))
  }
  return (
    <Wrapper {...props}>
      <Dropdown
        inputValue={inputValue}
        selectedItems={_selectedItems}
        onSelect={items => {
          setSelectedItems(items)
          onFilterUpdate(items)
        }}
        downshiftProps={{ defaultHighlightedIndex: 0 }}
        onInputValueChange={value => setInputValue(value)}
      >
        <Field>
          <Multiselect
            isCompact
            maxItems={100}
            placeholder="Apply filters..."
            renderItem={({ value, removeValue }) => (
              <StyledTag>
                <Flex direction="row" alignItems="center">
                  <StyledPageEventIcon
                    eventType={getEventType(value)}
                    size={16}
                  />
                  {value}
                </Flex>
                <Tag.Close onClick={() => removeValue()} />
              </StyledTag>
            )}
          />
        </Field>
        <Menu>{renderOptions()}</Menu>
      </Dropdown>
    </Wrapper>
  )
}

PageEventsFilter.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onFilterUpdate: PropTypes.func,
}

PageEventsFilter.defaultProps = {
  selectedItems: Object.values(FILTER_OPTIONS),
  onFilterUpdate: () => {},
}

export default PageEventsFilter
