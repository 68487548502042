import get from 'lodash/fp/get'
import pick from 'lodash/fp/pick'
import { createSelector } from 'reselect'
import reduce from 'lodash/fp/reduce'
import { STATE_KEY } from './reducers'

const getDevices = get(`${STATE_KEY}.list`)

const getDevicesById = createSelector(
  getDevices,
  reduce((acc, el) => ({ ...acc, [el.id]: el }), {})
)

const getCurrentDevice = get(`${STATE_KEY}.currentDevice`)

const getCurrentDeviceInfo = createSelector(
  getDevicesById,
  getCurrentDevice,
  (devices, deviceId) => get(deviceId)(devices)
)

const getIsRecording = get(`${STATE_KEY}.isRecording`)

const getVideoInfo = get(`${STATE_KEY}.videoInfo`)

const getVideoPath = createSelector(
  getVideoInfo,
  get('path')
)

const getVideoDuration = createSelector(
  getVideoInfo,
  pick(['duration', 'durationUnit'])
)

export default {
  getDevices,
  getDevicesById,
  getCurrentDevice,
  getCurrentDeviceInfo,
  getIsRecording,
  getVideoDuration,
  getVideoInfo,
  getVideoPath,
}
