import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import SettingsIcon from 'mdi-react/SettingsIcon'
import UserAvatar from '../../atoms/UserAvatar'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  margin-bottom: 16px;
`

const LeftBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  width: 56px;
  justify-content: center;
  padding-left: 16px;
`
const CenterBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: stretch;
  justify-content: center;
`
const RightBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: stretch;
  width: 56px;
  justify-content: center;
  align-items: center;
`

const FirstRow = styled(Flex)`
  padding-bottom: 4px;
`

const SecondRow = styled(Flex)`
  flex-direction: row;
  color: ${prop('theme.colos.grey500')};
`

const Role = styled(Flex)`
  font-weight: bold;
  text-transform: capitalize;
`

const Pending = styled(Flex)`
  color: ${prop('theme.colors.secondary')};
  margin-left: 8px;
`

const StyledSettingsIcon = styled(SettingsIcon)`
  fill: ${prop('theme.colors.grey500')};
`

const Member = ({
  pictureUrl,
  name,
  email,
  role,
  isPending,
  onSettingsClick,
  ...props
}) => (
  <Wrapper {...props}>
    <LeftBox>
      <UserAvatar imgUrl={pictureUrl} name={name} />
    </LeftBox>
    <CenterBox>
      <FirstRow>{email}</FirstRow>
      <SecondRow>
        <Role>{role}</Role>
        {isPending && <Pending>Pending</Pending>}
      </SecondRow>
    </CenterBox>
    <RightBox>
      {onSettingsClick && <StyledSettingsIcon onClick={onSettingsClick} />}
    </RightBox>
  </Wrapper>
)

Member.propTypes = {
  pictureUrl: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  isPending: PropTypes.bool,
  onSettingsClick: PropTypes.func,
}

Member.defaultProps = {
  pictureUrl: '',
  name: '',
  email: '',
  role: '',
  isPending: false,
  onSettingsClick: () => {},
}

export default Member
