export const NAMESPACE = 'USERS'

export const GET_USERS = `${NAMESPACE}/GET`
export const GET_USERS_REQUEST = `${GET_USERS}/REQUEST`
export const GET_USERS_REQUEST_SUCCESS = `${GET_USERS_REQUEST}_SUCCESS`
export const GET_USERS_REQUEST_FAILURE = `${GET_USERS_REQUEST}_FAILURE`

export const getUsersRequest = ids => ({
  type: GET_USERS_REQUEST,
  payload: { ids },
  meta: { thunk: GET_USERS },
})

export const getUsersRequestSuccess = (users, thunk) => ({
  type: GET_USERS_REQUEST_SUCCESS,
  payload: { users },
  meta: { thunk },
})

export const getUsersRequestFailure = (e, thunk) => ({
  type: GET_USERS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
