import { all, call, put, takeEvery } from 'redux-saga/effects'
import gql from 'graphql-tag'
import {
  CREATE_PAGE_EVENTS_REQUEST,
  createPageEventsRequestSuccess,
  createPageEventsRequestFailure,
  READ_PAGE_EVENTS_REQUEST,
  readPageEventsRequestSuccess,
  readPageEventsRequestFailure,
} from './pageEvents.actions'
import { createPageEvent as createPageEventGql } from '../../graphql/mutations'
import { readPageEvents as readPageEventsGql } from '../../graphql/queries'

export function* callCreatePageEvents({ sessionId, events }, apolloClient) {
  return yield call(apolloClient.mutate, {
    mutation: gql(createPageEventGql),
    variables: {
      sessionId,
      events,
    },
  })
}

function* createPageEvents(
  { apolloClient },
  { payload: { sessionId, events }, meta: { thunk } }
) {
  try {
    const result = yield call(
      callCreatePageEvents,
      {
        sessionId,
        events,
      },
      apolloClient
    )

    if (result) {
      yield put(createPageEventsRequestSuccess(result, thunk))
      return
    }
    yield put(createPageEventsRequestFailure('error', thunk))
  } catch (err) {
    console.error(err)
    yield put(createPageEventsRequestFailure(err, thunk))
  }
}

function* readPageEvents(
  { apolloClient },
  { payload: { sessionId }, meta: { thunk } }
) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(readPageEventsGql),
      variables: {
        sessionId,
      },
    })

    if (
      result?.data?.readPageEvents?.items &&
      result?.data?.readPageEvents?.items.length > 0
    ) {
      const items = result?.data?.readPageEvents?.items
      let pageEvents = []
      items.forEach(item => {
        try {
          const _events = JSON.parse(item?.events || '[]')
          pageEvents = pageEvents.concat(_events)
        } catch (e) {
          console.error(
            `Error: not possible to parse the page event: ${item?.events}`,
            e
          )
        }
      })
      const sortedPageEvents = pageEvents.sort(
        (a, b) => a.videoTime - b.videoTime
      )
      yield put(readPageEventsRequestSuccess(sortedPageEvents, thunk))
      return
    }
    yield put(readPageEventsRequestFailure('error', thunk))
  } catch (err) {
    console.error(err)
    yield put(readPageEventsRequestFailure(err, thunk))
  }
}

// TODO: (gb) Invalid number of arguments, expected 2 but got 3.
// Signature mismatch on createPageEvents and readPageEvents,
// the 2nd argument is not the expected type:
// (action: ActionMatchingPattern<string>) => any)
export default function* pageEventsSagas({ apolloClient }) {
  yield all([
    yield takeEvery(CREATE_PAGE_EVENTS_REQUEST, createPageEvents, {
      apolloClient,
    }),
    yield takeEvery(READ_PAGE_EVENTS_REQUEST, readPageEvents, {
      apolloClient,
    }),
  ])
}
