import React, { useEffect, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import Flex from '../../atoms/Flex'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import useCRUDProject from '../../../store/hooks/useCRUDProject'
import useReduxSagaThunkButton from '../../../store/hooks/useReduxSagaThunkButton'
import { CREATE_NEW_PROJECT_REQUEST } from '../../../store/projects/projects.actions'
import { SHARE_TYPES } from '../ProjectShareModal/ProjectShare.constants'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const Body = styled(Flex)`
  padding: 16px;
`

const CreateProjectModalMobile = ({ isVisible, onClose }) => {
  const { onCreateProjectHandler } = useCRUDProject()

  const { buttonNode } = useReduxSagaThunkButton(CREATE_NEW_PROJECT_REQUEST, {
    initialNode: 'Create',
    pendingNode: 'Creating...',
    fulfilledNode: 'Created!',
    rejectedNode: 'An error occurred',
  })

  const [title, setTitle] = useState('')

  const onTitleInput = e => {
    setTitle(e.target.value)
  }

  const inputRef = useRef(null)
  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])

  const header = useMemo(
    () => (
      <StyledMobileTopBar rightIcon={CloseIcon} onRightButtonClick={onClose}>
        Create project
      </StyledMobileTopBar>
    ),
    []
  )

  const body = useMemo(
    () => (
      <Body>
        <Field>
          <Input
            ref={inputRef}
            placeholder="i.e. Website Review, New Feature QA, Inspiration..."
            autoFocus
            focused
            onInput={onTitleInput}
          />
        </Field>
      </Body>
    ),
    [inputRef]
  )

  const footer = useMemo(
    () => (
      <Footer>
        <Button
          isPrimary
          onClick={() => {
            onCreateProjectHandler(title, SHARE_TYPES.anyone, []).finally(
              () => {
                setTimeout(() => onClose(), 2000)
              }
            )
          }}
        >
          {buttonNode}
        </Button>
      </Footer>
    ),
    [title, buttonNode]
  )

  return (
    <Flex>
      {isVisible && (
        <MobileModal>
          <MobileTemplate header={header} body={body} footer={footer} />
        </MobileModal>
      )}
    </Flex>
  )
}

CreateProjectModalMobile.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

CreateProjectModalMobile.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default CreateProjectModalMobile
