import { useSelector } from 'react-redux'
import useOrganizationMembers from '../../components/molecules/OrganizationMembers/useOrganizationMembers'
import fromOrganizations from '../organizations/organizations.selectors'
import appConfig from '../../config'
import useUserInfo from './useUserInfo'

const useLibraryWorkspace = () => {
  const allOrganizationIds = useSelector(
    fromOrganizations.getAllOrganizationsId
  )

  const isLibraryWorkspacePresent = !!allOrganizationIds?.find(
    organizationId => organizationId === appConfig.libraryWorkspaceId
  )

  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )

  const { userIdentityId } = useUserInfo()

  const { isCurrentUserOrganizationAdmin } = useOrganizationMembers(
    currentOrganizationId
  )

  const isLibraryWorkspace =
    currentOrganizationId === appConfig.libraryWorkspaceId

  const isLibraryWorkspaceAndUserIsMember =
    isLibraryWorkspace && !isCurrentUserOrganizationAdmin

  const isLibraryWorkspaceAndUserIsAdmin =
    isLibraryWorkspace && isCurrentUserOrganizationAdmin

  const shouldShowCreation = !(
    isLibraryWorkspace && isLibraryWorkspaceAndUserIsMember
  )

  const libraryWorkspace = useSelector(
    fromOrganizations.getOrganizationById(appConfig.libraryWorkspaceId)
  )

  const libraryWorkspaceAdmins = libraryWorkspace?.admins || []

  const isCurrentUserLibraryWorkspaceAdmin = libraryWorkspaceAdmins.includes(
    userIdentityId
  )

  return {
    isLibraryWorkspace,
    isLibraryWorkspacePresent,
    isCurrentUserOrganizationAdmin,
    isLibraryWorkspaceAndUserIsMember,
    isLibraryWorkspaceAndUserIsAdmin,
    shouldShowCreation,
    isCurrentUserLibraryWorkspaceAdmin,
  }
}

export default useLibraryWorkspace
