/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrganization = `subscription OnCreateOrganization {
  onCreateOrganization {
    pk
    sk
  }
}
`;
export const onUpdateOrganization = `subscription OnUpdateOrganization {
  onUpdateOrganization {
    pk
    sk
  }
}
`;
export const onDeleteOrganization = `subscription OnDeleteOrganization {
  onDeleteOrganization {
    pk
    sk
  }
}
`;
export const onCreateRelation = `subscription OnCreateRelation {
  onCreateRelation {
    pk
    sk
  }
}
`;
export const onCreateProject = `subscription OnCreateProject {
  onCreateProject {
    pk
    sk
  }
}
`;
export const onUpdateProject = `subscription OnUpdateProject {
  onUpdateProject {
    pk
    sk
  }
}
`;
export const onDeleteProject = `subscription OnDeleteProject {
  onDeleteProject {
    pk
    sk
  }
}
`;
export const onCreateSession = `subscription OnCreateSession {
  onCreateSession {
    pk
    sk
  }
}
`;
export const onUpdateSession = `subscription OnUpdateSession {
  onUpdateSession {
    pk
    sk
  }
}
`;
export const onDeleteSession = `subscription OnDeleteSession {
  onDeleteSession {
    pk
    sk
  }
}
`;
export const onCreateComment = `subscription OnCreateComment {
  onCreateComment {
    pk
    sk
  }
}
`;
export const onCreateCommentResolution = `subscription OnCreateCommentResolution {
  onCreateCommentResolution {
    pk
    sessionId
    text
    createdAt
    createdBy
    updatedAt
    updatedBy
    videoPositionSecs
    videoPoint {
      percentage {
        x
        y
      }
      absolute {
        x
        y
      }
    }
    videoRectangle {
      topLeft {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
      bottomRight {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
    }
    owner {
      pk
      name
      picture
      email
      organizations {
        organizationId
        role
      }
    }
    replies {
      id
      text
      createdAt
      createdBy
      updatedAt
      updatedBy
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      mentions {
        pk
        name
        email
      }
    }
    mentions {
      pk
      name
      email
    }
    isResolved
    resolutions {
      createdAt
      createdBy
      status
    }
  }
}
`;
export const onUpdateComment = `subscription OnUpdateComment {
  onUpdateComment {
    pk
    sk
  }
}
`;
export const onDeleteComment = `subscription OnDeleteComment {
  onDeleteComment {
    pk
    sk
  }
}
`;
export const onCreateReply = `subscription OnCreateReply {
  onCreateReply {
    pk
    sk
  }
}
`;
export const onUpdateReply = `subscription OnUpdateReply {
  onUpdateReply {
    pk
    sk
  }
}
`;
export const onDeleteReply = `subscription OnDeleteReply {
  onDeleteReply {
    pk
    sk
  }
}
`;
export const onCreateRemoteTest = `subscription OnCreateRemoteTest {
  onCreateRemoteTest {
    pk
    sk
  }
}
`;
export const onUpdateRemoteTest = `subscription OnUpdateRemoteTest {
  onUpdateRemoteTest {
    pk
    sk
  }
}
`;
export const onDeleteRemoteTest = `subscription OnDeleteRemoteTest {
  onDeleteRemoteTest {
    pk
    sk
  }
}
`;
