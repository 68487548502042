import { get as getImmutable } from 'dot-prop-immutable'
import get from 'lodash/get'
import getOr from 'lodash/fp/getOr'
import getFp from 'lodash/fp/get'
import values from 'lodash/values'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/fp/sortBy'
import mapFp from 'lodash/fp/map'
import reduceFp from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import { createSelector } from 'reselect'
import { STATE_KEY } from './comments.reducers'
import fromSessions from '../sessions/sessions.selectors'
import fromUsers from '../users/users.selectors'

const getState = state => getImmutable(state, STATE_KEY)

const getSessionComments = sessionId =>
  createSelector(
    getState,
    flow(
      getOr({}, `bySessionId.${sessionId}.byId`),
      values,
      sortBy(['videoPositionSecs', 'createdAt'])
    )
  )

const getCommentUsers = users => (acc, userId) => {
  const user = users?.byId?.[userId]
  if (user) {
    return [...acc, user]
  }
  return acc
}

const getSessionCommentUsers = sessionId =>
  createSelector(
    getSessionComments(sessionId),
    fromUsers.getState,
    (comments, users) =>
      flow(
        mapFp(getFp('createdBy')),
        uniq,
        reduceFp(getCommentUsers(users), [])
      )(comments)
  )

const getComment = commentId =>
  createSelector(
    getState,
    fromSessions.getCurrentSessionId,
    (state, sessionId) =>
      getOr({}, `bySessionId.${sessionId}.byId.${commentId}`)(state)
  )

const getEnrichedSessionsByCurrentProject = createSelector(
  fromSessions.getSessionsByCurrentProject,
  getState,
  fromUsers.getState,
  (sessions, comments, users) =>
    sessions.map(session => {
      const createdBy = get(session, 'createdBy')
      const createdByUserName = get(users, `byId.${createdBy}.name`)
      const commentsIds =
        get(comments, `bySessionId.${session.pk}.allIds`, []) || []
      const numberOfComments = commentsIds.length
      const numberOfReplies = commentsIds.reduce((acc, commentId) => {
        const _comment =
          get(comments, `bySessionId.${session.pk}.byId.${commentId}`, {}) || {}
        const repliesLength = _comment?.replies?.length || 0
        return acc + repliesLength
      }, 0)
      return {
        ...session,
        createdByUserName,
        numberOfComments,
        numberOfReplies,
      }
    })
)

export default {
  getSessionComments,
  getSessionCommentUsers,
  getComment,
  getEnrichedSessionsByCurrentProject,
}
