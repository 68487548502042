import React, { useCallback, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import Card from '../../atoms/Card'
import Loader from '../../atoms/Loader'
import { getSpriteSize } from './SessionCardUtils'
import DetailsLabel from '../DetailsLabel'
import SessionSettings from './SessionSettings'

export const SESSION_CARD_VARIANTS = {
  default: 'default',
  flat: 'flat',
}

const Wrapper = styled(Flex)``

const StyledSessionSettings = styled(SessionSettings)`
  display: none;
  visibility: hidden;
`

const flatVariantCss = css`
  box-shadow: none;
  border: 1px solid ${prop('theme.colors.grey200')};
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;

  &&:hover {
    background-color: ${prop('theme.colors.grey100')};
  }

  &&:hover ${StyledSessionSettings} {
    display: flex;
    visibility: visible;
  }

  ${({ variant }) => (variant === 'flat' ? flatVariantCss : '')}
`

const LeftBox = styled(Flex)`
  align-self: center;
  order: 0;
  flex: 0 1 auto;
`

const CentralBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  margin-left: 16px;
  align-items: center;
  justify-content: center;
  padding: 8px;
`

const RightBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  align-self: center;
`

const skeleton = keyframes`
  0%{ background-position: 0% 50% }
  50%{ background-position: 100% 50% }
  100%{ background-position: 0% 50% }
`

const ImageBox = styled(Flex)`
  height: ${prop('height')}px;
  width: ${prop('width')}px;
  border-radius: 2px;
  overflow: hidden;
  background: linear-gradient(
    270deg,
    ${prop('theme.colors.grey200')},
    ${prop('theme.colors.grey300')}
  );
  background-size: 400% 400%;
  animation: ${skeleton} 1s ease infinite;
`

const StyledImage = styled.img.attrs({
  crossOrigin: 'anonymous',
})`
  max-height: ${prop('height')}px;
  height: ${prop('height')}px;
  width: ${prop('width')}px;
`

const Title = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${prop('theme.colors.grey800')};
`

const Subtitle = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  flex-direction: row;
  margin-top: 8px;
`

const CounterBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  flex-direction: row;
`

const StyledLoader = styled(Loader)`
  color: ${prop('theme.colors.secondary')};
  font-size: 24px;
`

const SessionCard = ({
  variant,
  authorName,
  counter,
  hasSettings,
  imageData,
  imageUrl,
  isLoading,
  isPrivate,
  isPublic,
  isShared,
  onImageLoad,
  sharedWithCounter,
  spriteSize,
  title,
  onDelete,
  onCopy,
  onShare,
  onJiraClick,
  onTrelloClick,
  onSlackClick,
  onLoadingClick,
  onClick,
  sessionId,
  showDetailsTag,
  settingsShowCopy,
  settingsShowShare,
  settingsShowDelete,
  settingsShowMove,
  settingsShowJira,
  settingsShowTrello,
  settingsShowSlack,
  ...props
}) => {
  const [spriteWidth, setSpriteWidth] = useState(spriteSize?.width || 20)
  const [spriteHeight, setSpriteHeight] = useState(spriteSize?.height || 40)

  const setSpriteSize = useCallback(
    e => {
      if (!spriteSize) {
        const size = getSpriteSize(e)
        setSpriteWidth(size.width)
        setSpriteHeight(size.height)
        onImageLoad(e, size)
      }
    },
    [spriteSize, getSpriteSize, setSpriteWidth, setSpriteHeight, onImageLoad]
  )

  const [isImageVisible, setImageVisible] = useState(false)

  return (
    <Wrapper
      {...props}
      onClick={() => {
        if (isLoading && onLoadingClick) {
          onLoadingClick()
          return
        }

        if (onClick) {
          onClick()
        }
      }}
    >
      <StyledCard variant={variant}>
        <LeftBox>
          <ImageBox width={spriteWidth} height={spriteHeight}>
            {(imageData || imageUrl) && (
              <StyledImage
                src={imageData || imageUrl}
                style={{ opacity: isImageVisible ? '1' : '0' }}
                onLoad={e => {
                  setSpriteSize(e)
                  setImageVisible(true)
                }}
                width={spriteWidth}
                height={spriteHeight}
              />
            )}
          </ImageBox>
        </LeftBox>
        <CentralBox>
          <Title>{title}</Title>
          {!isLoading && (
            <Subtitle>
              <DetailsLabel
                isPrivate={isPrivate && !isPublic}
                isShared={isShared && !isPublic}
                isPublic={isPublic}
                sharedWithCounter={sharedWithCounter}
                counter={counter}
                label={counter === 1 ? 'comment' : 'comments'}
                showTag={showDetailsTag}
              />
            </Subtitle>
          )}
        </CentralBox>
        {isLoading && (
          <RightBox>
            <CounterBox>
              <StyledLoader />
            </CounterBox>
          </RightBox>
        )}
        {!isLoading && hasSettings && (
          <StyledSessionSettings
            sessionId={sessionId}
            onCopy={onCopy}
            onShare={onShare}
            onDelete={onDelete}
            onJiraClick={onJiraClick}
            onTrelloClick={onTrelloClick}
            onSlackClick={onSlackClick}
            showCopy={settingsShowCopy}
            showShare={settingsShowShare}
            showDelete={settingsShowDelete}
            showMove={settingsShowMove}
            showJira={settingsShowJira}
            showTrello={settingsShowTrello}
            showSlack={settingsShowTrello}
          />
        )}
      </StyledCard>
    </Wrapper>
  )
}

SessionCard.propTypes = {
  sessionId: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  counter: PropTypes.number,
  hasSettings: PropTypes.bool,
  imageData: PropTypes.string,
  imageUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isShared: PropTypes.bool,
  onDelete: PropTypes.func,
  onImageLoad: PropTypes.func,
  onCopy: PropTypes.func,
  onShare: PropTypes.func,
  onJiraClick: PropTypes.func,
  onTrelloClick: PropTypes.func,
  onSlackClick: PropTypes.func,
  onLoadingClick: PropTypes.func,
  onClick: PropTypes.func,
  sharedWithCounter: PropTypes.number,
  title: PropTypes.string,
  spriteSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  showDetailsTag: PropTypes.bool,
  variant: PropTypes.oneOf([
    SESSION_CARD_VARIANTS.default,
    SESSION_CARD_VARIANTS.flat,
  ]),
  settingsShowCopy: PropTypes.bool,
  settingsShowShare: PropTypes.bool,
  settingsShowDelete: PropTypes.bool,
  settingsShowMove: PropTypes.bool,
  settingsShowJira: PropTypes.bool,
  settingsShowTrello: PropTypes.bool,
  settingsShowSlack: PropTypes.bool,
}

SessionCard.defaultProps = {
  authorName: '',
  counter: 0,
  hasSettings: false,
  imageData: '',
  imageUrl: '',
  isLoading: false,
  isPrivate: false,
  isPublic: false,
  isShared: false,
  onDelete: null,
  onImageLoad: () => {},
  onLoadingClick: () => {},
  onClick: () => {},
  onCopy: null,
  onShare: null,
  onJiraClick: null,
  onTrelloClick: null,
  onSlackClick: null,
  sharedWithCounter: 0,
  spriteSize: null,
  title: '',
  showDetailsTag: true,
  variant: 'default',
  settingsShowCopy: true,
  settingsShowShare: true,
  settingsShowDelete: true,
  settingsShowMove: true,
  settingsShowJira: true,
  settingsShowTrello: true,
  settingsShowSlack: true,
}

export default SessionCard
