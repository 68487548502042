import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import get from 'lodash/get'
import padStart from 'lodash/padStart'
import Flex from '../../atoms/Flex'
import PlayerTooltip from '../../atoms/PlayerTooltip'
import UserAvatar from '../../atoms/UserAvatar'
import PageEventIcon from '../../organisms/PageEvents/PageEventIcon'
import { PAGE_EVENT_TYPES } from '../../organisms/PageEvents/pageEvents.constants'
import useDeepEffect from '../../hooks/useDeepEffect'

const PlaybackBarWrapper = styled(Flex)`
  flex: 1 1 auto;
  padding: 4px 0;
  margin: 0 4px;
  position: relative;
  width: 100%;
`

const TICKER_WIDTH = '8px'

const Ticker = styled.div`
  width: ${TICKER_WIDTH};
  height: 8px;
  position: absolute;
  background-color: ${prop('theme.colors.yellow')};
  transition: all 0.1s ease-out;
  border-right: 2px solid white;
  border-left: 2px solid white;

  &&:hover {
    background-color: ${prop('theme.colors.secondary')};
  }
`

const StyledUserAvatar = styled(UserAvatar)`
  position: absolute;
  top: -18px;
  z-index: 16;
`

const PageEventTicker = styled.div`
  width: 2px;
  height: 8px;
  position: absolute;
  background-color: white;
`

const StyledPlayerTooltip = styled(PlayerTooltip)`
  position: absolute;
  top: -40px;
  left: -22px;
  display: inline-flex;
  margin-left: ${({ tooltipMargin }) => tooltipMargin}px;
  z-index: 24;
  display: none;
`

const StyledPlayerSprite = styled(PlayerTooltip)`
  position: absolute;
  bottom: 56px;
  left: -40px;
  display: inline-flex;
  margin-left: ${({ tooltipMargin }) => tooltipMargin}px;
  display: none;
  z-index: 2000;
  img {
    width: 80px;
    height: auto;
  }
`

const StyledPageEventIcon = styled(PageEventIcon)`
  position: absolute;
  width: 16px;
  top: ${({ eventType }) =>
    eventType === PAGE_EVENT_TYPES.navigation ? '-1px' : '18px'};
  z-index: 32;
`

const PlaybackBarBackground = styled(Flex)`
  background-color: ${prop('theme.colors.grey200')};
  flex: 1 1 auto;
  border-radius: 2px;
  cursor: pointer;

  :hover ${StyledPlayerTooltip} {
    display: flex;
  }

  :hover ${StyledPlayerSprite} {
    display: flex;
  }
`

const PlaybackBarFilled = styled(Flex)`
  background-color: ${prop('theme.colors.grey600')};
  width: 0;
  height: 8px;
  border-radius: 2px;
  transition: width 0.3s linear;
`

const formatDuration = durationInSeconds => {
  const minutes = padStart(Math.floor(durationInSeconds / 60), 2, '0')
  const seconds = padStart(
    Math.floor(durationInSeconds - minutes * 60, 10),
    2,
    '0'
  )
  return `${minutes}:${seconds}`
}

const getBarWidth = playbackBarRef => {
  if (playbackBarRef?.current) {
    return playbackBarRef.current.getBoundingClientRect().width
  }
  return 800
}

const PlaybackBar = ({
  onPause,
  onTickerClick,
  onUpdatePosition,
  onCommentsCrossed,
  pageEvents,
  sprites,
  tickers,
  videoLengthSecs,
  videoRef,
  ...props
}) => {
  const playbackBarFilledRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const timeUpdateHandler = () => {
    const _currentTimeSecs = videoRef?.currentTime || 0
    setCurrentTime(_currentTimeSecs)
    const percentage = (100 / videoLengthSecs) * _currentTimeSecs
    playbackBarFilledRef.current.style.width = `${percentage}%`
  }
  useEffect(() => {
    if (videoRef) {
      videoRef.addEventListener('timeupdate', timeUpdateHandler)
    }

    return () => {
      if (videoRef) {
        videoRef.removeEventListener('timeupdate', timeUpdateHandler)
      }
    }
  }, [videoLengthSecs, videoRef, playbackBarFilledRef?.current])

  const [tooltipLabel, setTooltipLabel] = useState('00:00')
  const [tooltipMargin, setTooltipMargin] = useState(0)
  const [compiledSprites, setCompiledSprites] = useState([])
  const [spriteUrl, setSpriteUrl] = useState('')
  const playbackBarRef = useRef(null)

  const getVideoPositionFromEvent = e => {
    const bounds = playbackBarRef.current.getBoundingClientRect()
    const x = e.clientX - bounds.left
    const videoPositionSecs =
      (x / playbackBarRef.current.clientWidth) * videoLengthSecs
    return videoPositionSecs
  }

  useDeepEffect(() => {
    const pixelSeconds = videoLengthSecs / getBarWidth(playbackBarRef)
    const frameLength = pixelSeconds * 4
    const getFrameNumber = time => Math.floor(time / frameLength)
    const currentFrameNumber = getFrameNumber(currentTime)
    const getFrame = ({ videoPositionSecs }) =>
      getFrameNumber(videoPositionSecs) === currentFrameNumber
    const allTickersWithSameTime = tickers.filter(getFrame)
    onCommentsCrossed({
      tickers: allTickersWithSameTime,
    })
  }, [currentTime, tickers, playbackBarRef?.current])

  const onPauseHandler = () => {
    onPause()
  }

  const onUpdatePositionHandler = e => {
    onUpdatePosition(getVideoPositionFromEvent(e))
    onPauseHandler()
  }

  const onMouseEvent = e => {
    const eventType = e.type
    if (eventType === 'mouseenter') {
      // setTooltipVisible(true)
    }
    if (eventType === 'mouseleave') {
      // setTooltipVisible(false)
    }

    const bounds = playbackBarRef.current.getBoundingClientRect()
    const x = e.clientX - bounds.left
    setTooltipMargin(x)
    const mouseVideoPositionSecs = getVideoPositionFromEvent(e)
    setTooltipLabel(formatDuration(mouseVideoPositionSecs))

    const currentSpriteIndex = parseInt(mouseVideoPositionSecs, 10) + 1
    const currentSpriteUrl = get(
      compiledSprites.find(sprite => sprite.order === currentSpriteIndex),
      'url',
      ''
    )
    setSpriteUrl(currentSpriteUrl)
  }

  const [compiledTickers, setCompiledTickers] = useState({})
  useEffect(() => {
    if (videoLengthSecs) {
      const _tickers = tickers.reduce((acc, ticker) => {
        const left = (ticker.videoPositionSecs * 100) / videoLengthSecs
        const hasVideoPoint = !!ticker.videoPoint
        if (acc[left]) {
          return {
            ...acc,
            [left]: {
              ...acc[left],
              hasVideoPoint: acc[left].hasVideoPoint || hasVideoPoint,
            },
          }
        }

        return {
          ...acc,
          [left]: {
            pk: ticker.pk,
            left,
            hasVideoPoint,
            owner: ticker?.owner,
          },
        }
      }, {})
      setCompiledTickers(_tickers)
    }
  }, [tickers, videoLengthSecs])

  const [compiledPageEvents, setCompiledPageEvents] = useState({})
  useEffect(() => {
    if (videoLengthSecs) {
      const _pageEvents = pageEvents.reduce((acc, pageEvent) => {
        const left = (pageEvent.videoTime * 100) / videoLengthSecs
        if (acc[left]) {
          return {
            ...acc,
            [left]: {
              ...acc[left],
            },
          }
        }

        return {
          ...acc,
          [left]: {
            id: pageEvent.id,
            left,
            type: pageEvent.type,
            videoTime: pageEvent.videoTime,
          },
        }
      }, {})
      setCompiledPageEvents(_pageEvents)
    }
  }, [pageEvents, videoLengthSecs])

  useEffect(() => {
    setCompiledSprites(
      sprites.map(sprite => ({ ...sprite, order: parseInt(sprite.order, 10) }))
    )
  }, [sprites.length])

  return (
    <PlaybackBarWrapper {...props} onClick={onUpdatePositionHandler}>
      <PlaybackBarBackground
        onMouseEnter={onMouseEvent}
        onMouseMove={onMouseEvent}
        onMouseLeave={onMouseEvent}
        ref={playbackBarRef}
      >
        <StyledPlayerTooltip tooltipMargin={tooltipMargin}>
          {tooltipLabel}
        </StyledPlayerTooltip>
        {spriteUrl && (
          <StyledPlayerSprite tooltipMargin={tooltipMargin}>
            <img src={spriteUrl} alt="sprite" />
          </StyledPlayerSprite>
        )}
        {Object.values(compiledTickers).map(ticker => {
          const variant = ticker.hasVideoPoint ? 'yellow' : 'violet'
          return (
            <Ticker
              key={`ticker-${ticker.pk}`}
              variant={variant}
              style={{
                left: `calc(${ticker.left}% - 1px)`,
                visibility: videoLengthSecs === 0 ? 'hidden' : 'visible',
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onTickerClick(ticker)
              }}
            />
          )
        })}
        {Object.values(compiledTickers).map(ticker => (
          <StyledUserAvatar
            key={`avatar-${ticker.pk}`}
            size="extraextrasmall"
            imgUrl={ticker?.owner?.picture}
            style={{
              left: `calc(${ticker.left}% - 2px)`,
              visibility: videoLengthSecs === 0 ? 'hidden' : 'visible',
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onTickerClick(ticker)
            }}
          />
        ))}
        {Object.values(compiledPageEvents).map(pageEvent => (
          <StyledPageEventIcon
            key={`event-${pageEvent.id}`}
            isAlternative
            eventType={pageEvent.type}
            size={16}
            style={{
              left: `calc(${pageEvent.left}% - 3px)`,
              visibility: videoLengthSecs === 0 ? 'hidden' : 'visible',
            }}
          />
        ))}
        {Object.values(compiledPageEvents).map(pageEvent => (
          <PageEventTicker
            key={`event-ticker-${pageEvent.id}`}
            style={{
              left: `calc(${pageEvent.left}% - 1px)`,
              visibility: videoLengthSecs === 0 ? 'hidden' : 'visible',
            }}
          />
        ))}
        <PlaybackBarFilled ref={playbackBarFilledRef} />
      </PlaybackBarBackground>
    </PlaybackBarWrapper>
  )
}

const SpriteShape = PropTypes.shape({
  order: PropTypes.string,
  url: PropTypes.string,
})

PlaybackBar.propTypes = {
  videoLengthSecs: PropTypes.number,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onUpdatePosition: PropTypes.func,
  onCommentsCrossed: PropTypes.func,
  onTickerClick: PropTypes.func,
  sprites: PropTypes.arrayOf(SpriteShape),
  tickers: PropTypes.arrayOf(PropTypes.shape({ startTime: PropTypes.number })),
  pageEvents: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, videoTime: PropTypes.number })
  ),
  videoRef: PropTypes.shape({
    currentTime: PropTypes.number,
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
}

PlaybackBar.defaultProps = {
  videoLengthSecs: 0,
  onPlay: () => {},
  onPause: () => {},
  onUpdatePosition: () => {},
  onCommentsCrossed: () => {},
  onTickerClick: () => {},
  sprites: [],
  tickers: [],
  pageEvents: [],
  videoRef: { current: null },
}

PlaybackBar.whyDidYouRender = false

export default PlaybackBar
