export const AFTER_LOGIN = 'AFTER_LOGIN'

export const REGISTRATION_REQUEST = 'REGISTRATION/REQUEST'
export const REGISTRATION_REQUEST_SUCCESS = 'REGISTRATION/REQUEST_SUCCESS'
export const REGISTRATION_REQUEST_FAILURE = 'REGISTRATION/REQUEST_FAILURE'

export const registrationRequest = userData => ({
  type: REGISTRATION_REQUEST,
  payload: userData,
  meta: { thunk: REGISTRATION_REQUEST },
})

export const registrationRequestSuccess = thunk => ({
  type: REGISTRATION_REQUEST_SUCCESS,
  payload: {},
  meta: { thunk },
})

export const registrationRequestFailure = (e, thunk) => ({
  type: REGISTRATION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const VERIFICATION_REQUEST = 'VERIFICATION/REQUEST'
export const VERIFICATION_REQUEST_SUCCESS = 'VERIFICATION/REQUEST_SUCCESS'
export const VERIFICATION_REQUEST_FAILURE = 'VERIFICATION/REQUEST_FAILURE'

export const verificationRequest = ({ email, code }) => ({
  type: VERIFICATION_REQUEST,
  payload: { email, code },
})

export const verificationRequestSuccess = username => ({
  type: VERIFICATION_REQUEST_SUCCESS,
  payload: { username },
})

export const verificationRequestFailure = e => ({
  type: VERIFICATION_REQUEST_FAILURE,
  payload: e,
  error: true,
})

export const LOGIN_REQUEST = 'LOGIN/REQUEST'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN/REQUEST_SUCCESS'
export const LOGIN_REQUEST_FAILURE = 'LOGIN/REQUEST_FAILURE'

export const loginRequest = ({ email, password }) => ({
  type: LOGIN_REQUEST,
  payload: { email, password },
  meta: { thunk: LOGIN_REQUEST },
})

export const loginRequestSuccess = (payload, thunk) => ({
  type: LOGIN_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const loginRequestFailure = (e, thunk) => ({
  type: LOGIN_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const LOGOUT_REQUEST = 'LOGOUT/REQUEST'
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT/REQUEST_SUCCESS'
export const LOGOUT_REQUEST_FAILURE = 'LOGOUT/REQUEST_FAILURE'

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
  payload: {},
  meta: { thunk: LOGOUT_REQUEST },
})

export const logoutRequestSuccess = (payload, thunk) => ({
  type: LOGOUT_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const logoutRequestFailure = (e, thunk) => ({
  type: LOGOUT_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const startLoading = () => ({
  type: START_LOADING,
  payload: {},
})

export const stopLoading = () => ({
  type: STOP_LOADING,
  payload: {},
})

export const ACTIVE_USER = 'ACTIVE_USER'
export const activeUser = () => ({
  type: ACTIVE_USER,
})

export const INACTIVE_USER = 'INACTIVE_USER'
export const inactiveUser = () => ({
  type: INACTIVE_USER,
})

export const START_APP_DOWNLOAD = 'START_APP_DOWNLOAD'
export const startAppDownload = os => ({
  type: START_APP_DOWNLOAD,
  payload: { os },
})

export const UPDATE_DOWNLOAD_PROGRESS = 'UPDATE_DOWNLOAD_PROGRESS'
export const updateDownloadProgress = progress => ({
  type: UPDATE_DOWNLOAD_PROGRESS,
  payload: progress,
})

export const DOWNLOAD_APP = 'DOWNLOAD_APP'
export const downloadApp = () => ({
  type: DOWNLOAD_APP,
})

export const UPLOAD_VIDEO_FILES = `UPLOAD_VIDEO_FILES`
export const uploadVideoFiles = files => ({
  type: UPLOAD_VIDEO_FILES,
  payload: { files },
})

export const UPLOAD_SCREEN_RECORDING = `UPLOAD_SCREEN_RECORDING`
export const uploadScreenRecording = ({ content, mimeType }) => ({
  type: UPLOAD_SCREEN_RECORDING,
  payload: { content, mimeType },
})

export const UPLOAD_EXTENSION_RECORDING = `UPLOAD_EXTENSION_RECORDING`
export const uploadExtensionRecording = ({ blobUrl }) => ({
  type: UPLOAD_EXTENSION_RECORDING,
  payload: { blobUrl },
})

export const RESET_UPLOAD_PROGRESS = `RESET_UPLOAD_PROGRESS`
export const resetUploadProgress = () => ({
  type: RESET_UPLOAD_PROGRESS,
  payload: {},
})

export const UPDATE_UPLOAD_PROGRESS = `UPDATE_UPLOAD_PROGRESS`
export const updateUploadProgress = (
  progress,
  sessionId,
  conversionProgress = 0,
  spritesProgress = 0
) => ({
  type: UPDATE_UPLOAD_PROGRESS,
  payload: { progress, sessionId, conversionProgress, spritesProgress },
})
