import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { HeaderItem, HeaderItemIcon } from '@zendeskgarden/react-chrome'
import {
  Dropdown,
  Menu,
  Item,
  Separator,
  Trigger,
} from '@zendeskgarden/react-dropdowns'
import CurrentUserAvatar from '../../atoms/UserAvatar/CurrentUserAvatar'

const AvatarHeaderItem = styled(HeaderItem)`
  cursor: pointer;
  outline: none;
  box-shadow: none !important;
  color: white !important;
  & > * {
    color: white !important;
  }
`

const AvatarHeaderItemIcon = styled(HeaderItemIcon)`
  cursor: pointer;
  color: white !important;
  & > figure {
    background-color: ${prop('theme.colors.secondary')};
    color: white !important;
  }
`

const AvatarMenuHeader = styled(HeaderItem)`
  font-weight: bold;
  text-align: right;
`

const UserSettingsMenu = ({ userName, onSettings, onLogout, ...props }) => (
  <>
    <Dropdown
      onSelect={item => {
        if (item === 'option-settings') {
          onSettings()
        }

        if (item === 'option-logout') {
          onLogout()
        }
      }}
    >
      <Trigger>
        <AvatarHeaderItem {...props}>
          <AvatarHeaderItemIcon>
            <CurrentUserAvatar data-test="current-user-avatar" size="small" />
          </AvatarHeaderItemIcon>
        </AvatarHeaderItem>
      </Trigger>
      <Menu placement="top-end">
        <AvatarMenuHeader>{userName}</AvatarMenuHeader>
        <Separator />
        <Item
          value="option-settings"
          data-test="user-settings-menu-item-settings"
        >
          Settings
        </Item>
        <Item value="option-logout" data-test="user-settings-menu-item-logout">
          Logout
        </Item>
      </Menu>
    </Dropdown>
  </>
)

UserSettingsMenu.propTypes = {
  userName: PropTypes.string.isRequired,
  onSettings: PropTypes.func,
  onLogout: PropTypes.func,
}

UserSettingsMenu.defaultProps = {
  onSettings: () => {},
  onLogout: () => {},
}

export default UserSettingsMenu
