import { useSelector } from 'react-redux'
import { SHARE_TYPES } from '../../components/molecules/ProjectShareModal/ProjectShare.constants'
import useUserInfo from './useUserInfo'
import fromSessions from '../sessions/sessions.selectors'
import fromProjects from '../projects/projects.selectors'
import fromUsers from '../users/users.selectors'

const useCanDeleteSession = sessionId => {
  const { userIdentityId, userEmail } = useUserInfo()
  const session = useSelector(fromSessions.getSessionById(sessionId))
  const project = useSelector(fromProjects.getProjectById(session?.projectId))

  const sessionOwnerUser = useSelector(
    fromUsers.getUserById(session?.createdBy)
  )
  const projectOwnerUser = useSelector(
    fromUsers.getUserById(project?.createdBy)
  )

  const isSessionOwner = userEmail === sessionOwnerUser?.email
  const isProjectOwner = userEmail === projectOwnerUser?.email
  const isOwner = isSessionOwner || isProjectOwner

  if (
    project?.shareType === SHARE_TYPES.anyone &&
    session?.createdBy === userIdentityId
  ) {
    return true
  }

  if (isOwner) {
    return true
  }

  return false
}

export default useCanDeleteSession
