import videoUtils from './video'
import { isChromeLikeBrowser, isEdgeBrowser } from './platform'

/* eslint-disable import/prefer-default-export */
let mediaChunks = []

const getSupportedContraints = () =>
  navigator.mediaDevices.getSupportedConstraints()

const checkConstraints = mediaType => {
  const supportedMediaConstraints = getSupportedContraints()
  const unsupportedConstraints = Object.keys(mediaType).filter(
    constraint => !supportedMediaConstraints[constraint]
  )

  if (unsupportedConstraints.length > 0) {
    console.error(
      `The constraints ${unsupportedConstraints.join(
        ','
      )} are not supported on this browser.`
    )
  }
}

export const getSupportedVideoMimeType = () => {
  const contentTypes = [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp8',
    'video/mp4;codecs=avc1',
    'video/mp4;codecs=h264',
    'video/mp4;',
    'video/webm',
  ]
  for (let index = 0; index < contentTypes.length; index += 1) {
    const contentType = contentTypes[index]
    if (MediaRecorder.isTypeSupported(contentType)) {
      return contentType
    }
  }

  console.error('Unsupported video streams')
  return ''
}

export const getSupportedAudioMimeType = () => {
  const contentTypes = [
    'audio/mp3',
    'audio/aac',
    'audio/wav',
    'audio/ogg',
    'audio/webm',
  ]
  for (let index = 0; index < contentTypes.length; index += 1) {
    const contentType = contentTypes[index]
    if (MediaRecorder.isTypeSupported(contentType)) {
      return contentType
    }
  }

  console.error('Unsupported audio streams')
  return ''
}

const getMediaStream = async ({
  audio,
  video,
  screen,
  mediaRecorderOptions = {},
}) => {
  if (typeof audio === 'object') {
    checkConstraints(audio)
  }
  if (typeof video === 'object') {
    checkConstraints(video)
  }
  const requiredMedia = {
    audio,
    video: video || screen,
  }

  if (screen) {
    const stream = await window.navigator.mediaDevices.getDisplayMedia({
      ...mediaRecorderOptions,
      video: video || true,
    })
    if (audio) {
      try {
        const audioStream = await window.navigator.mediaDevices.getUserMedia({
          ...mediaRecorderOptions,
          audio,
        })

        audioStream
          .getAudioTracks()
          .forEach(audioTrack => stream.addTrack(audioTrack))
      } catch (err) {
        console.error('Audio track error', err)
      }
    }
    return stream
  }
  const stream = await window.navigator.mediaDevices.getUserMedia(requiredMedia)
  return stream
}

const onRecordingActive = ({ data }) => {
  mediaChunks.push(data)
}

const onRecordingStop = (blobPropertyBag, isVideo, callback) => {
  const videoMimeType = getSupportedVideoMimeType()
  console.log('videoMimeType', videoMimeType)
  const audioMimeType = getSupportedAudioMimeType()
  console.log('audioMimeType', audioMimeType)

  const blobProperty =
    blobPropertyBag || isVideo
      ? { type: videoMimeType }
      : { type: audioMimeType }

  videoUtils
    .makeRecordedVideoSeekableFromBlob(new Blob(mediaChunks, blobProperty))
    .then(refinedBlob => {
      const mediaBlobUrl = URL.createObjectURL(refinedBlob)

      if (callback && typeof callback === 'function') {
        callback({
          mediaBlob: refinedBlob,
          mediaBlobUrl,
          videoMimeType,
          audioMimeType,
        })
      }
      mediaChunks = []
    })
}

/*
@param mediaRecorderOptions
An optional options object that will be passed to MediaRecorder. Please note that if you specify the MIME type via either audio or video prop and through this mediaRecorderOptions, the mediaRecorderOptions have higher precedence.

type: object
default: {}

@param blobPropertyBag
An optional BlobPropertyBag dictionary which may specify the following two attributes (for the mediaBlob):

type, that represents the MIME type of the content of the array that will be put in the blob.
endings, with a default value of "transparent", that specifies how strings containing the line ending character \n are to be written out. It is one of the two values: "native", meaning that line ending characters are changed to match host OS filesystem convention, or "transparent", meaning that endings are stored in the blob without change

type: object
default:
if video is enabled,
  {
    type: "video/mp4"
  }
if there's only audio is enabled,
  {
    type: "audio/wav"
  }
*/
export const startRecording = async ({
  audio,
  video,
  screen,
  mediaRecorderOptions = {},
  blobPropertyBag,
  onStop = () => {},
}) => {
  const mediaStream = await getMediaStream({
    audio,
    video,
    screen,
    mediaRecorderOptions,
  })
  if (mediaStream) {
    const mediaRecorder = new MediaRecorder(mediaStream)
    const isVideo = video || screen
    mediaRecorder.ondataavailable = onRecordingActive
    mediaRecorder.onstop = () =>
      onRecordingStop(blobPropertyBag, isVideo, onStop)
    mediaRecorder.onerror = () => {
      console.error('Media Recorder', 'NO_RECORDER')
    }
    mediaRecorder.start()
    return { mediaStream, mediaRecorder }
  }
  return null
}

export const muteAudio = mediaStream => {
  if (mediaStream) {
    try {
      mediaStream
        .getAudioTracks()
        // eslint-disable-next-line no-param-reassign, no-return-assign
        .forEach(audioTrack => (audioTrack.enabled = false))
    } catch (err) {
      console.error('Mute audio error', err)
    }
  }
}

export const unmuteAudio = mediaStream => {
  if (mediaStream) {
    try {
      mediaStream
        .getAudioTracks()
        // eslint-disable-next-line no-param-reassign, no-return-assign
        .forEach(audioTrack => (audioTrack.enabled = true))
    } catch (err) {
      console.error('Unmute audio error', err)
    }
  }
}

export const pauseRecording = mediaRecorder => {
  if (mediaRecorder && mediaRecorder.state === 'recording') {
    mediaRecorder.pause()
  }
}

export const resumeRecording = mediaRecorder => {
  if (mediaRecorder && mediaRecorder.state === 'paused') {
    mediaRecorder.resume()
  }
}

export const stopRecording = (mediaRecorder, mediaStream) =>
  new Promise(resolve => {
    if (mediaRecorder) {
      mediaRecorder.stop()
    }
    if (mediaStream) {
      const tracks = mediaStream.getTracks()
      tracks.forEach(track => track.stop())
    }
    resolve()
  })

export const isMediaRecorderSupported = () => !!window.MediaRecorder
export const isScreenRecordingSupported = () =>
  !!window.navigator.mediaDevices.getDisplayMedia &&
  isMediaRecorderSupported() &&
  getSupportedVideoMimeType() !== '' &&
  (isChromeLikeBrowser() || isEdgeBrowser())
