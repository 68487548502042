import React from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  display: block;
  text-align: left;
  word-break: break-word;
  font-size: 14px;
  color: ${prop('theme.colors.grey800')};
  white-space: pre-wrap;
  line-height: 1.4em;
`

const CommentText = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

CommentText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CommentText
