import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import get from 'lodash/get'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import deepEqual from 'fast-deep-equal/es6/react'
import { MD } from '@zendeskgarden/react-typography'
import CommentsEmptyState from '../../molecules/CommentsEmptyState'
import PlaybackBar from '../../molecules/Player/PlaybackBar'
import SheetList from '../../atoms/SheetList'
import Loader from '../../atoms/Loader'
import Div100vh from '../../molecules/Div100vh'
import PlayerMobile from './PlayerMobile'
import {
  getNextPlaybackRate,
  getParentNodeButton,
  hasAudio,
} from '../../molecules/Player/Player'
import VideoOverlay from './VideoOverlay'
import MobileTopBar from '../../molecules/MobileTopBar'
import { getSessionMenuItems, PAGES } from './SessionViewMobile.utils'
import Flex from '../../atoms/Flex'
import VideoMarker from '../../atoms/VideoMarker'
import EditTextMobile from '../../molecules/EditTextMobile'
import CommentDialog from '../../molecules/CommentDialog'
import { ternary } from '../../../util/utils'
import ShareModalMobile from '../../molecules/ShareModal/ShareModalMobile'
import ConfirmationModalMobile from '../../molecules/ConfirmationModalMobile'
import MoveRecordingTreePageMobile from '../../pages/MoveRecordingTreePageMobile'
import RegistrationModalMobile from '../../pages/Onboarding/RegistrationPage/RegistrationModalMobile'
import useUrlParser from '../../hooks/useUrlParser'
import { getVideoMarkerPositionFromPercentage } from '../../atoms/PhoneView/PhoneView'
import useDeepEffect from '../../hooks/useDeepEffect'
import { calculateNewSize } from '../../atoms/PhoneView/phoneView.utils'
import NewCommentMobile from '../../molecules/CommentDialog/NewCommentMobile'
import NewCommentModalMobile from '../../molecules/NewCommentModalMobile'
import NewReplyModalMobile from '../../molecules/NewReplyModalMobile'
import VideoUploading from '../../molecules/VideoUploading'
import useLocalStorage from '../../hooks/useLocalStorage'
import { HEADER_HEIGHT } from '../../pages/MainAppPage/MainAppPage.constants'

const Wrapper = styled(Div100vh).attrs({
  id: 'wrapper',
})`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100vw;
  background-color: ${prop('theme.colors.grey600')};
  box-shadow: 0 0 2000px ${prop('theme.colors.grey900')} inset;
  overflow: hidden;
  position: absolute;
  bottom: 0;
`

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
`

const PlaybackBarBox = styled(Flex)`
  padding: 20px 16px;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 10px 20px rgba(0, 0, 0, 0.04);
  z-index: 10;
`

const CommentsBox = styled(Flex)`
  flex-grow: 1;
  background-color: white;
  padding: 16px 8px;
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
`

const StyledPlaybackBar = styled(PlaybackBar)`
  height: 8px;
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;
`

const StyledPlayerMobile = styled(PlayerMobile)``

const VideoWrapper = styled(Flex)`
  height: 40%;
  min-height: 40%;
  width: 100vw;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  position: relative;
  align-self: center;
  align-items: center;
`

const InitialOverlay = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(19, 18, 28, 0.8);
  z-index: 10;
  align-items: center;
  justify-content: center;
  color: white;
`

const VideoBox = styled(Flex)`
  position: relative;
  width: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).width}px;
  height: ${({ maxWidth, maxHeight, height, width }) =>
    calculateNewSize({ maxWidth, maxHeight, height, width }).height}px;
`

const StyledVideoMarker = styled(VideoMarker)`
  position: absolute;
  top: ${prop('yPerc')}%;
  left: ${prop('xPerc')}%;
  z-index: ${ternary('isBlurred')(10, 11)};
`

const Rectangle = styled.div.attrs({
  className: 'itrs-rectangle',
})`
  position: absolute;
  border: 2px solid ${prop('theme.colors.yellow')};
  transition: all 0.3s linear;
  box-sizing: border-box;
  overflow: auto;
  z-index: ${ternary('isBlurred')(12, 18)};

  top: ${prop('topLeft.percentage.y')}%;
  left: ${prop('topLeft.percentage.x')}%;
  width: ${({ topLeft, bottomRight }) =>
    bottomRight.percentage.x - topLeft.percentage.x}%;
  height: ${({ topLeft, bottomRight }) =>
    bottomRight.percentage.y - topLeft.percentage.y}%;

  border-style: ${ternary('isBlurred')('dashed', 'solid')};
  border-color: ${({ isBlurred, theme }) =>
    isBlurred ? prop('colors.grey200')(theme) : prop('colors.yellow')(theme)};
  background-color: transparent;
`

const SmallVideoMarkerForRectangle = styled(VideoMarker).attrs({
  size: 'small',
})`
  position: absolute;
  z-index: ${ternary('isBlurred')(14, 20)};
  top: calc(${prop('yPerc')}% - 2px);
  left: calc(${prop('xPerc')}% - 2px);
  border: 0;
  box-shadow: none !important;
`

const safariFlexFix = css`
  flex-shrink: 0;
  flex-basis: auto;
`

const StyledCommentDialog = styled(CommentDialog)`
  ${safariFlexFix}
`

const StyledNewCommentMobile = styled(NewCommentMobile)`
  margin-bottom: 16px;
  ${prop('theme.boxShadowSelected')};
`

const CenteredContainer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  z-index: 501;
  opacity: 0.75;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  transition: transform 0.3s ease-out;
`

const EncodingMessage = styled(MD)`
  margin-top: 16px;
  position: absolute;
  top: 48px;
  width: 200px;
  color: white;
  text-align: center;
`

const VideoUploadingWrapper = styled(Flex)`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: 100vw;
  background-color: white;
  align-items: center;
  justify-content: center;
`

const COMMENTS_TOLERANCE_SECS = 0.25

const SessionViewMobileNew = ({
  areCommentsAllowed,
  comments,
  deleteSessionButtonNode,
  dispatchCreateReply,
  dispatchDeleteComment,
  getCommentsDispatch,
  initialCommentId,
  isAnonymous,
  isEditMode,
  isStandalone,
  isUserOwnSession,
  isVideoEncodingOnTheCloud,
  isVideoMissing,
  onAnonymourReplyFocus,
  onAnonymousVideoClick,
  onBack,
  onDeleteSessionHandler,
  onExit,
  onResolveCommentClick,
  onUpdateSessionHandler,
  pageEvents,
  readOnly,
  renameSessionButtonNode,
  session,
  sessionId,
  sessionTitle,
  showCopySnackbar,
  sprites,
  userCanAddCommentsToSession,
  userCanContribute,
  userIdentityId,
  userName,
  videoDuration,
  videoHeight,
  videoUrl,
  videoWidth,
  sessionCreatorUserName,
  sessionCreatorPictureUrl,
  ...props
}) => {
  const commentsBoxRef = useRef(null)
  const [currentActiveCommentId, setCurrentActiveCommentId] = useState(
    `comment:${initialCommentId}`
  )
  const [isSessionSheetVisible, setSessionSheetVisible] = useState(false)

  const [isRenameSessionModalVisible, setRenameSessionModalVisible] = useState(
    false
  )
  const [isDeleteSessionModalVisible, setDeleteSessionModalVisible] = useState(
    false
  )
  const [isMoveRecordingTreeVisible, setMoveRecordingTreeVisible] = useState(
    false
  )
  const [isRegistrationModalVisible, setRegistrationModalVisible] = useState(
    false
  )
  const [isNewCommentModalVisible, setNewCommentModalVisible] = useState(false)
  const [isNewReplyModalVisible, setNewReplyModalVisible] = useState(false)
  const [newReplyCommentId, setNewReplyCommentId] = useState(null)
  const [newReplyCommentNumber, setNewReplyCommentNumber] = useState(null)
  const [isNewReplyCommentInEditMode, setNewReplyCommentInEditMode] = useState(
    false
  )
  const [newReplyReplyIdInEditMode, setNewReplyReplyIdInEditMode] = useState(
    null
  )
  const [newVideoMarker, setNewVideoMarker] = useState(null)
  const [secondaryPage, setSecondaryPage] = useState(null)
  const [isVideoLoaded, setVideoLoaded] = useState(false)
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    if (isVideoMissing) {
      setLoading(false)
    }
  }, [isVideoMissing])
  const [isPlaying, setPlaying] = useState(false)
  const [readOnlyCurrentTime, setReadOnlyCurrentTime] = useState(0)
  const [videoCurrentTime, setVideoCurrentTime] = useState(null)
  const [isInitialOverlayVisible, setInitialOverlayVisible] = useState(
    !initialCommentId
  )

  const onMarkerClick = commentId => {
    setCurrentActiveCommentId(commentId)
  }

  useEffect(() => {
    if (videoDuration && isVideoLoaded && initialCommentId) {
      const _commentId = `comment:${initialCommentId}`
      const initialComment = comments.find(x => x.pk === _commentId)
      setVideoCurrentTime(initialComment.videoPositionSecs)
      setReadOnlyCurrentTime(initialComment.videoPositionSecs)
      setPlaying(false)
      setCurrentActiveCommentId(_commentId)
    }

    if (videoDuration && isVideoLoaded && !initialCommentId) {
      /* NOTE: autoplay is deprecated */
      // setPlaying(true)
    }
  }, [isVideoLoaded, videoDuration])

  const sessionMenuItems = getSessionMenuItems({
    sessionTitle,
    sessionId,
    isUserOwnSession,
    isAnonymous,
    setRenameSessionModalVisible,
    setDeleteSessionModalVisible,
    setMoveRecordingTreeVisible,
    setSecondaryPage,
    showCopySnackbar,
  })

  const videoRef = useRef(null)
  const videoWrapperRef = useRef(null)

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.currentTime = videoCurrentTime
    }
  }, [videoRef?.current, videoCurrentTime])

  const [videoStartAtSeconds, setVideoStartAtSeconds] = useState(null)
  const [isExecuted, setExecuted] = useState(false)
  useDeepEffect(() => {
    if (!isExecuted && videoRef.current && videoStartAtSeconds !== null) {
      videoRef.current.pause()
      setVideoCurrentTime(videoStartAtSeconds)
    }
    if (!isExecuted && videoRef.current && videoStartAtSeconds !== null) {
      setExecuted(true)
    }
  }, [videoStartAtSeconds, videoRef.current, isExecuted, setVideoCurrentTime])

  const switchToAddCommentView = (clickPosition, videoTime) => {
    setNewVideoMarker({
      point: clickPosition,
    })
    videoRef.current.pause()
    setVideoCurrentTime(videoTime)
    commentsBoxRef.current.scrollTop = 0
  }

  const { initialVideoTime, initialMarkerPosition } = useUrlParser()
  useEffect(() => {
    if (!isLoading && videoRef?.current) {
      let skipVideoMarker = false
      if (
        initialVideoTime &&
        videoRef.current.currentTime !== initialVideoTime
      ) {
        setVideoStartAtSeconds(initialVideoTime)
        skipVideoMarker = true
      }

      if (initialMarkerPosition && !skipVideoMarker) {
        setTimeout(() => {
          const markerPosition = getVideoMarkerPositionFromPercentage(
            {
              xPerc: initialMarkerPosition.x,
              yPerc: initialMarkerPosition.y,
            },
            videoRef
          )
          switchToAddCommentView(markerPosition, initialVideoTime)
        }, 250)
      }
    }
  }, [isLoading, videoRef.current])

  const [lastCrossedCommentId, setLastCrossedCommentId] = useState(null)

  useEffect(() => {
    const crossedComment = comments.find(
      comment =>
        readOnlyCurrentTime >= comment.videoPositionSecs - 0.1 &&
        readOnlyCurrentTime <=
          comment.videoPositionSecs + COMMENTS_TOLERANCE_SECS
    )

    if (crossedComment?.pk && crossedComment.pk !== lastCrossedCommentId) {
      /* pause on crossed comment is deprecated */
      /*
      setPlaying(false)
      setTimeout(() => {
        setVideoCurrentTime(crossedComment.videoPositionSecs)
        videoRef.current.pause()
        setPlaying(false)
      }, 200)
      setTimeout(() => {
        setVideoCurrentTime(crossedComment.videoPositionSecs)
        videoRef.current.pause()
        setPlaying(false)
      }, 500)
      */
      setLastCrossedCommentId(crossedComment.pk)
    }
  }, [
    videoRef?.current,
    comments.length,
    lastCrossedCommentId,
    setLastCrossedCommentId,
    setVideoCurrentTime,
    readOnlyCurrentTime,
  ])

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.addEventListener('durationchange', () => {})
      videoRef.current.addEventListener('canplay', () => {
        setLoading(false)
      })
      videoRef.current.addEventListener('loadeddata', () => {
        // The first frame of the media has finished loading.
        setVideoLoaded(true)
        setLoading(false)
      })
      videoRef.current.addEventListener('loadedmetadata', () => {
        setVideoLoaded(true)
        setLoading(false)
      })
      videoRef.current.addEventListener('play', () => {
        setLoading(false)
        setPlaying(true)
      })
      videoRef.current.addEventListener('playing', () => {
        setLoading(false)
        setPlaying(true)
      })
      videoRef.current.addEventListener('pause', () => {
        setPlaying(false)
      })
      videoRef.current.addEventListener('ended', () => {
        setPlaying(false)
      })
      videoRef.current.addEventListener('timeupdate', () => {
        const currentTime = videoRef?.current?.currentTime
        if (currentTime !== undefined) {
          setReadOnlyCurrentTime(currentTime)
        }
      })
      videoRef.current.addEventListener('seeking', () => {
        setLoading(true)
        setPlaying(false)
      })
      videoRef.current.addEventListener('seeked', () => {
        setLoading(false)
        setTimeout(() => setLoading(false), 50)
      })
      videoRef.current.addEventListener('waiting', () => {
        setLoading(true)
        setPlaying(false)
      })
    }
  }, [videoRef?.current])

  const renameSessionModal = useMemo(
    () => (
      <EditTextMobile
        isVisible={isRenameSessionModalVisible}
        title="Rename recording"
        text={sessionTitle}
        buttonNode={renameSessionButtonNode}
        textAreaPlaceholder="Write the title..."
        onSubmit={text => {
          onUpdateSessionHandler(text).finally(() =>
            setTimeout(() => setRenameSessionModalVisible(false), 1000)
          )
        }}
        onClose={() => setRenameSessionModalVisible(false)}
      />
    ),
    [isRenameSessionModalVisible, sessionTitle, renameSessionButtonNode]
  )

  const onPlay = () => {
    setInitialOverlayVisible(false)
    if (videoRef?.current) {
      setNewVideoMarker(null)
      const playPromise = videoRef.current.play()
      if (playPromise.then) {
        playPromise.then(() => setPlaying(true)).catch(() => setPlaying(false))
      }
    }
  }

  const onPause = () => {
    if (videoRef?.current) {
      videoRef.current.pause()
      setPlaying(false)
    }
  }

  const [videoMaxWidth, setVideoMaxWidth] = useState(0)
  const [videoMaxHeight, setVideoMaxHeight] = useState(0)

  useEffect(() => {
    const videoNode = document.getElementById('recordingVideoId')
    if (videoNode) {
      const videoSize = calculateNewSize({
        maxWidth: videoMaxWidth,
        maxHeight: videoMaxHeight,
        height: videoHeight,
        width: videoWidth,
      })
      videoNode.style.width = `${videoSize.width}px`
      videoNode.style.height = `${videoSize.height}px`
    }
  }, [videoUrl, videoMaxWidth, videoMaxHeight])

  const onSetVideoMaxSize = () => {
    if (videoWrapperRef?.current) {
      const maxWidth = window.innerWidth
      const maxHeight = videoWrapperRef.current.getBoundingClientRect().height
      setVideoMaxWidth(maxWidth)
      setVideoMaxHeight(maxHeight)
    }
  }

  useEffect(() => {
    if (videoWidth && videoHeight) {
      /*
      const maxWidth = window.innerWidth
      const maxHeight = videoWrapperRef.current.getBoundingClientRect().height

      const newSize = calculateNewSize({
        width: videoWidth,
        height: videoHeight,
        maxWidth,
        maxHeight,
      })
      */
      /*
      videoWrapperRef.current.style.width = `${newSize.width}px`
      videoWrapperRef.current.style.height = `${newSize.height}px`
      videoWrapperRef.current.style.minHeight = `${newSize.height}px`
      */
    }
  }, [videoWidth, videoHeight])

  useEffect(() => {
    onSetVideoMaxSize()
  }, [])

  const onUpdatePosition = time => {
    setNewVideoMarker(null)
    setVideoCurrentTime(time)
    videoRef.current.pause()
    setPlaying(false)
    setInitialOverlayVisible(false)
    // trick, not nice I know
    setTimeout(() => {
      setLastCrossedCommentId(null)
    }, 1000)
  }

  const onCommentClick = ({ videoPositionSecs, commentId }) => {
    setPlaying(false)
    setInitialOverlayVisible(false)
    videoRef.current.pause()
    setVideoCurrentTime(videoPositionSecs)
    setNewVideoMarker(null)
    // setPlayerDrivenTime(videoPositionSecs)
    // setPlayerDrivenTimeToken(Math.random().toString())
    setCurrentActiveCommentId(commentId)
  }

  const onTickerClick = ticker => {
    const comment = comments.find(_comment => _comment.pk === ticker.pk)
    if (comment) {
      onCommentClick({
        videoPositionSecs: comment?.videoPositionSecs,
        commentId: comment?.pk,
      })
    }
  }

  const [savedPlaybackRate, savePlaybackRate] = useLocalStorage(
    'player-playback-rate',
    1
  )
  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.playbackRate = savedPlaybackRate
    }
  }, [videoRef?.current])
  const [playbackRate, setPlaybackRate] = useState(savedPlaybackRate)
  const onPlaybackRateClick = () => {
    const newPlaybackRate = getNextPlaybackRate(playbackRate)
    setPlaybackRate(newPlaybackRate)
    // eslint-disable-next-line no-param-reassign
    videoRef.current.playbackRate = newPlaybackRate
    savePlaybackRate(newPlaybackRate)
  }

  const [crossedComments, setCrossedComments] = useState([])
  const onCommentsCrossed = ({ tickers }) => {
    if (!deepEqual(tickers, crossedComments)) {
      setCrossedComments(tickers)
      if (!currentActiveCommentId) {
        setCurrentActiveCommentId(get(tickers, '[0].pk', null))
      }
    }

    if (tickers.length === 0) {
      setCurrentActiveCommentId(null)
    }
  }

  const [commentVideoMarkers, setCommentVideoMarkers] = useState([])
  useLayoutEffect(() => {
    const commentsWithVideoPoint = crossedComments.filter(
      comment => comment?.videoPoint || comment?.videoRectangle
    )
    if (commentsWithVideoPoint.length > 0) {
      const videoMarkers = commentsWithVideoPoint.reduce(
        (acc, comment) => [
          ...acc,
          {
            videoPoint: comment.videoPoint,
            videoRectangle: comment.videoRectangle,
            time: comment.videoPositionSecs,
            commentId: comment.pk,
            number: comment?.number,
            isBlurred: comment.pk !== currentActiveCommentId,
          },
        ],
        []
      )

      if (!deepEqual(commentVideoMarkers, videoMarkers)) {
        setCommentVideoMarkers(videoMarkers)
      }
      return
    }
    if (!deepEqual(commentVideoMarkers, [])) {
      setCommentVideoMarkers([])
    }
  }, [
    crossedComments,
    readOnlyCurrentTime,
    commentVideoMarkers,
    currentActiveCommentId,
  ])

  const onVideoTap = e => {
    const currentTime = videoRef?.current?.currentTime
    if (currentTime !== null && currentTime !== undefined) {
      const boundingRect = e.target.getBoundingClientRect()
      const resizedClickX = e.clientX - boundingRect.x
      const resizedClickY = e.clientY - boundingRect.y

      const resizeVideoWidth = videoRef.current.clientWidth
      const resizedVideoHeight = videoRef.current.clientHeight
      const xPerc = (resizedClickX * 100) / resizeVideoWidth
      const yPerc = (resizedClickY * 100) / resizedVideoHeight

      const realVideoWidth = videoRef.current.videoWidth
      const realVideoHeight = videoRef.current.videoHeight

      const xRatio = realVideoWidth / resizeVideoWidth
      const yRatio = realVideoHeight / resizedVideoHeight

      const originalClickX = resizedClickX * xRatio
      const originalClickY = resizedClickY * yRatio

      const clickPosition = {
        percentage: {
          x: xPerc,
          y: yPerc,
        },
        absolute: {
          x: originalClickX,
          y: originalClickY,
        },
      }

      if (isAnonymous) {
        videoRef.current.pause()
        setRegistrationModalVisible(true)
        onAnonymousVideoClick({
          time: videoRef.current.currentTime,
          point: clickPosition,
        })
      } else {
        switchToAddCommentView(clickPosition, videoRef.current.currentTime)
      }
    }
  }

  const onRewind = e => {
    onUpdatePosition(Math.max(0.01, readOnlyCurrentTime - 10))
    const buttonNode = getParentNodeButton(e.target)
    if (buttonNode.blur) buttonNode.blur()
  }

  const onFastForward = e => {
    onUpdatePosition(readOnlyCurrentTime + 10)
    const buttonNode = getParentNodeButton(e.target)
    if (buttonNode.blur) buttonNode.blur()
  }

  const [videoVolume, setVideoVolume] = useState(1)
  useEffect(() => {
    if (videoRef?.current && hasAudio(videoRef?.current)) {
      setVideoVolume(1)
      // eslint-disable-next-line no-param-reassign
      videoRef.current.volume = 1
    }
  }, [videoRef?.current])

  useEffect(() => {
    if (videoRef?.current) {
      // eslint-disable-next-line no-param-reassign
      videoRef.current.volume = videoVolume
    }
  }, [videoVolume, videoRef?.current])

  const isOverlayVisible =
    !isVideoEncodingOnTheCloud &&
    !isVideoMissing &&
    !!videoWidth &&
    !!videoHeight &&
    isInitialOverlayVisible

  return (
    <Wrapper {...props}>
      <StyledMobileTopBar
        icon={ArrowLeftIcon}
        onButtonClick={onBack}
        rightIcon={DotsVerticalIcon}
        onRightButtonClick={() => setSessionSheetVisible(true)}
      >
        {sessionTitle}
      </StyledMobileTopBar>
      {isVideoMissing && !videoUrl && (
        <VideoUploadingWrapper>
          <VideoUploading
            userName={sessionCreatorUserName}
            pictureUrl={sessionCreatorPictureUrl}
          />
        </VideoUploadingWrapper>
      )}
      <VideoWrapper
        ref={videoWrapperRef}
        style={{ display: isVideoMissing ? 'none' : 'flex' }}
      >
        {isOverlayVisible && (
          <InitialOverlay onClick={onPlay}>
            <VideoOverlay onPlay={onPlay} showText={comments?.length > 0} />
          </InitialOverlay>
        )}
        <VideoBox
          width={videoWidth}
          height={videoHeight}
          maxWidth={videoMaxWidth}
          maxHeight={videoMaxHeight}
        >
          {(isVideoEncodingOnTheCloud || (!isOverlayVisible && isLoading)) && (
            <CenteredContainer>
              <Loader color="white" />
              {isVideoEncodingOnTheCloud && (
                <EncodingMessage>
                  Bear with us, it could take a few minutes
                </EncodingMessage>
              )}
            </CenteredContainer>
          )}
          {newVideoMarker && (
            <StyledVideoMarker
              xPerc={newVideoMarker?.point?.percentage?.x}
              yPerc={newVideoMarker?.point?.percentage?.y}
            />
          )}
          {commentVideoMarkers.length > 0 &&
            !isPlaying &&
            commentVideoMarkers.map(
              ({ videoPoint, videoRectangle, time, number, commentId }) => {
                const videoPointPercX = videoPoint?.percentage?.x
                const videoPointPercY = videoPoint?.percentage?.y

                const isBlurred =
                  !isPlaying &&
                  !!crossedComments.find(({ pk: _pk }) => _pk === commentId) &&
                  currentActiveCommentId !== commentId

                return videoPoint ? (
                  <StyledVideoMarker
                    key={`${time}-${videoPointPercX}-${videoPointPercY}`}
                    xPerc={videoPointPercX}
                    yPerc={videoPointPercY}
                    isBlurred={isBlurred}
                    onClick={() => {
                      onMarkerClick(commentId)
                    }}
                  >
                    {number}
                  </StyledVideoMarker>
                ) : (
                  <>
                    <Rectangle
                      key={`rect-${time}-${videoRectangle.topLeft.percentage.x}-${videoRectangle.topLeft.percentage.y}`}
                      topLeft={videoRectangle.topLeft}
                      bottomRight={videoRectangle.bottomRight}
                      isBlurred={isBlurred}
                    />
                    <SmallVideoMarkerForRectangle
                      key={`marker-${time}-${videoRectangle.topLeft.percentage.x}-${videoRectangle.topLeft.percentage.y}`}
                      xPerc={videoRectangle.bottomRight.percentage.x}
                      yPerc={videoRectangle.bottomRight.percentage.y}
                      isBlurred={isBlurred}
                      onClick={() => {
                        onMarkerClick(commentId)
                      }}
                    >
                      {number}
                    </SmallVideoMarkerForRectangle>
                  </>
                )
              }
            )}
          {videoUrl && videoWidth && videoHeight && (
            <video
              id="recordingVideoId"
              src={videoUrl}
              ref={videoRef}
              disableRemotePlayback
              poster={sprites?.[0]?.url}
              autoPlay={false}
              controls={false}
              playsInline
              preload="metadata"
              disablepictureinpicture
              type="video/mp4"
              width={videoWidth}
              height={videoHeight}
              onClick={onVideoTap}
            />
          )}
        </VideoBox>
      </VideoWrapper>
      <PlaybackBarBox style={{ display: isVideoMissing ? 'none' : 'flex' }}>
        <StyledPlaybackBar
          videoLengthSecs={videoDuration}
          onUpdatePosition={onUpdatePosition}
          onTickerClick={onTickerClick}
          sprites={[]}
          tickers={comments}
          pageEvents={pageEvents}
          videoRef={videoRef?.current}
          onCommentsCrossed={onCommentsCrossed}
        />
      </PlaybackBarBox>
      <CommentsBox
        ref={commentsBoxRef}
        style={{ display: isVideoMissing ? 'none' : 'flex' }}
      >
        {newVideoMarker && (
          <StyledNewCommentMobile
            onCancel={() => setNewVideoMarker(null)}
            onComment={() => setNewCommentModalVisible(true)}
          />
        )}
        {comments.length === 0 && (
          <CommentsEmptyState isCaption={false} isSmall showImage={false} />
        )}
        {comments.map(comment => {
          const { pk: commentId, number } = comment

          const isHighlighted =
            !isPlaying && currentActiveCommentId === commentId

          const isBlurred =
            !isPlaying &&
            !!crossedComments.find(({ pk: _pk }) => _pk === commentId) &&
            currentActiveCommentId !== commentId

          return (
            <StyledCommentDialog
              key={commentId}
              comment={comment}
              isInlineEdit={false}
              isHighlighted={isHighlighted}
              isBlurred={isBlurred}
              isCommentCopyLinkEnabled
              tagText={number}
              isResolved={comment?.isResolved}
              currentUserName={userName}
              showJira={false}
              showTrello={false}
              showSlack={false}
              onCommentEdit={_commentId => {
                setNewReplyCommentId(_commentId)
                setNewReplyCommentNumber(number)
                setNewReplyCommentInEditMode(true)
                setNewReplyReplyIdInEditMode(null)
                setNewReplyModalVisible(true)
              }}
              onCommentClick={() => {
                onCommentClick({
                  videoPositionSecs: comment?.videoPositionSecs,
                  commentId,
                })
              }}
              onCopyLink={() => showCopySnackbar()}
              onReplyEdit={(_commentId, _replyId) => {
                setNewReplyCommentId(_commentId)
                setNewReplyCommentNumber(number)
                setNewReplyCommentInEditMode(false)
                setNewReplyReplyIdInEditMode(_replyId)
                setNewReplyModalVisible(true)
              }}
              onReplyFocus={() => {
                if (isAnonymous) {
                  setRegistrationModalVisible(true)
                  onAnonymourReplyFocus({ commentId })
                  return
                }
                setNewReplyCommentId(commentId)
                setNewReplyCommentNumber(number)
                setNewReplyCommentInEditMode(false)
                setNewReplyReplyIdInEditMode(null)
                setNewReplyModalVisible(true)
              }}
              onResolveCommentClick={({
                commentId: _commentId,
                isResolved,
              }) => {
                if (isAnonymous) {
                  setRegistrationModalVisible(true)
                  return
                }
                onResolveCommentClick({
                  commentId: _commentId,
                  isResolved,
                })
              }}
            />
          )
        })}
      </CommentsBox>
      <StyledPlayerMobile
        style={{ display: isVideoMissing ? 'none' : 'flex' }}
        isPlaying={isPlaying}
        isPlayHighlighted={
          !isPlaying &&
          readOnlyCurrentTime > 0 &&
          readOnlyCurrentTime !== videoDuration
        }
        onPlay={onPlay}
        onPause={onPause}
        onRewind={onRewind}
        onFastForward={onFastForward}
        playbackRate={playbackRate}
        onPlaybackRate={onPlaybackRateClick}
        hasAudio={hasAudio(videoRef?.current)}
        videoVolume={videoVolume}
        onVolumeOn={() => setVideoVolume(1)}
        onVolumeOff={() => setVideoVolume(0)}
      />
      <SheetList
        onClose={() => setSessionSheetVisible(false)}
        isVisible={isSessionSheetVisible}
        items={sessionMenuItems}
      />
      {secondaryPage === PAGES.share && (
        <ShareModalMobile
          isVisible={secondaryPage === PAGES.share}
          sessionId={sessionId}
          onClose={() => {
            setTimeout(() => setSecondaryPage(null), 700)
          }}
        />
      )}
      {isNewCommentModalVisible && (
        <NewCommentModalMobile
          isUserLogged={!isAnonymous}
          onClose={() => {
            setNewVideoMarker(null)
            setNewCommentModalVisible(false)
          }}
          videoCurrentTime={readOnlyCurrentTime}
          videoPoint={newVideoMarker?.point}
        />
      )}
      {isNewReplyModalVisible && (
        <NewReplyModalMobile
          onClose={() => {
            setNewReplyModalVisible(false)
          }}
          commentId={newReplyCommentId}
          commentNumber={newReplyCommentNumber}
          isCommentInEditMode={isNewReplyCommentInEditMode}
          replyIdInEditMode={newReplyReplyIdInEditMode}
          currentUserName={userName}
          onCopyLink={() => showCopySnackbar()}
          onResolveCommentClick={({ commentId: _commentId, isResolved }) => {
            onResolveCommentClick({
              commentId: _commentId,
              isResolved,
            })
          }}
        />
      )}
      {isDeleteSessionModalVisible && (
        <ConfirmationModalMobile
          title="Delete recording"
          text="Do you really want to delete the recording?"
          submitButtonNode={deleteSessionButtonNode}
          isVisible={isDeleteSessionModalVisible}
          onClose={() => {
            setDeleteSessionModalVisible(false)
          }}
          onSubmit={() =>
            onDeleteSessionHandler(sessionId).finally(() => {
              setDeleteSessionModalVisible(false)
              onBack()
            })
          }
        />
      )}
      {renameSessionModal}
      {isMoveRecordingTreeVisible && (
        <MoveRecordingTreePageMobile
          sessionId={sessionId}
          isVisible={isMoveRecordingTreeVisible}
          onBack={() => setMoveRecordingTreeVisible(false)}
        />
      )}
      {isRegistrationModalVisible && (
        <RegistrationModalMobile
          onClose={() => setRegistrationModalVisible(false)}
        />
      )}
    </Wrapper>
  )
}

SessionViewMobileNew.propTypes = {
  areCommentsAllowed: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  deleteSessionButtonNode: PropTypes.node,
  dispatchCreateReply: PropTypes.func,
  dispatchDeleteComment: PropTypes.func,
  getCommentsDispatch: PropTypes.func,
  initialCommentId: PropTypes.string,
  isAnonymous: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isStandalone: PropTypes.bool,
  isUserOwnSession: PropTypes.bool,
  isVideoEncodingOnTheCloud: PropTypes.bool,
  isVideoMissing: PropTypes.bool,
  onAnonymourReplyFocus: PropTypes.func,
  onAnonymousVideoClick: PropTypes.func,
  onBack: PropTypes.func,
  onDeleteSessionHandler: PropTypes.func,
  onExit: PropTypes.func,
  onResolveCommentClick: PropTypes.func,
  onUpdateSessionHandler: PropTypes.func,
  pageEvents: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  renameSessionButtonNode: PropTypes.node,
  session: PropTypes.shape({}),
  sessionId: PropTypes.string,
  sessionTitle: PropTypes.string,
  showCopySnackbar: PropTypes.func,
  sprites: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  userCanAddCommentsToSession: PropTypes.bool,
  userCanContribute: PropTypes.bool,
  userIdentityId: PropTypes.string,
  userName: PropTypes.string,
  videoDuration: PropTypes.number,
  videoHeight: PropTypes.number,
  videoUrl: PropTypes.string,
  videoWidth: PropTypes.number,
  sessionCreatorUserName: PropTypes.string,
  sessionCreatorPictureUrl: PropTypes.string,
}

SessionViewMobileNew.defaultProps = {
  areCommentsAllowed: false,
  comments: [],
  deleteSessionButtonNode: null,
  dispatchCreateReply: () => {},
  dispatchDeleteComment: () => {},
  getCommentsDispatch: () => {},
  initialCommentId: '',
  isAnonymous: true,
  isEditMode: false,
  isStandalone: false,
  isUserOwnSession: false,
  isVideoEncodingOnTheCloud: false,
  isVideoMissing: false,
  onAnonymourReplyFocus: () => {},
  onAnonymousVideoClick: () => {},
  onBack: () => {},
  onDeleteSessionHandler: () => {},
  onExit: () => {},
  onResolveCommentClick: () => {},
  onUpdateSessionHandler: () => {},
  pageEvents: [],
  readOnly: true,
  renameSessionButtonNode: null,
  session: {},
  sessionId: '',
  sessionTitle: '',
  showCopySnackbar: () => {},
  sprites: [],
  userCanAddCommentsToSession: false,
  userCanContribute: false,
  userIdentityId: '',
  userName: '',
  videoDuration: 0,
  videoHeight: 0,
  videoUrl: '',
  videoWidth: 0,
  sessionCreatorUserName: '',
  sessionCreatorPictureUrl: '',
}

export default SessionViewMobileNew
