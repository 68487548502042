/* eslint-disable import/prefer-default-export */
import memoize from 'lodash/memoize'

export const calculateNewSize = memoize(
  ({ maxWidth, maxHeight, height, width }) => {
    const newWidth = height <= maxHeight ? width : (maxHeight / height) * width
    if (newWidth > maxWidth) {
      const correctWidth = maxWidth
      const correctHeight = (correctWidth / width) * height
      return {
        width: correctWidth,
        height: correctHeight,
      }
    }

    const newHeight = Math.min(height, maxHeight)
    return {
      width: newWidth,
      height: newHeight,
    }
  }
)
