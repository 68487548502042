import { get as getImmutable } from 'dot-prop-immutable'
import { createSelector } from 'reselect'
import { STATE_KEY } from './upload.reducers'

const getState = state => getImmutable(state, STATE_KEY)

const getPendingUploads = createSelector(
  getState,
  state => ({ ...state })
)

export default {
  getPendingUploads,
}
