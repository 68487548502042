import React from 'react'
import styled from 'styled-components'
import Loader from '../../atoms/Loader'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const CenteredContainer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`

const FullScreenLoader = ({ ...props }) => (
  <Wrapper {...props}>
    <CenteredContainer>
      <Loader />
    </CenteredContainer>
  </Wrapper>
)

export default FullScreenLoader
