//
// With credits to https://github.com/eugeneware/ffmpeg-static
//
import { Decoder, Reader, tools } from 'ts-ebml'

const { promisify } = require('util')
const path = require('path')
const platform = require('./platform')

const getBasePath = () => window.require('electron').remote.app.getAppPath()
const getAppPath = () =>
  getBasePath().indexOf('app.asar') > 0
    ? getBasePath().replace('/app.asar', '')
    : getBasePath()

const getDependencies = () => {
  if (!platform.isElectron()) return { ffprobePath: '', execAsync: () => {} }

  const os = window.require('os')

  const osPlatform = os.platform()
  if (
    osPlatform !== 'darwin' &&
    osPlatform !== 'linux' &&
    osPlatform !== 'win32'
  ) {
    console.error('Unsupported platform.')
    return null
  }

  const arch = os.arch()
  if (osPlatform === 'darwin' && arch !== 'x64') {
    console.error('Unsupported architecture.')
    return null
  }

  const platformPath = osPlatform === 'darwin' ? 'mac' : osPlatform

  const ffprobe = `ffprobe${osPlatform === 'win32' ? '.exe' : ''}`
  const ffmpeg = `ffmpeg${osPlatform === 'win32' ? '.exe' : ''}`

  const basePath = getBasePath()
  const executablesPath =
    basePath.indexOf('app.asar') > 0
      ? [basePath.replace('/app.asar', ''), 'bin', arch]
      : [basePath, 'platform-tools', platformPath, arch]

  const ffprobePath = path.join(...executablesPath, ffprobe)
  const ffmpegPath = path.join(...executablesPath, ffmpeg)

  const childProcess = window.require('child_process')
  const fs = window.require('fs')
  const execAsync = promisify(childProcess.exec)
  const { spawn } = childProcess
  return { ffmpegPath, ffprobePath, execAsync, spawn, fs }
}

const getVideoInfo = async (videoPath, info) => {
  if (!platform.isElectron()) return null
  const { ffprobePath, execAsync } = getDependencies()
  const stdout = await execAsync(
    `${ffprobePath} -v error -show_format -show_streams "${videoPath}"`
  )
  let matched = []
  switch (info) {
    case 'duration':
      matched = stdout.match(/duration="?(\d*\.\d*)"?/)
      break
    case 'width':
      matched = stdout.match(/width="?(\d*)"?/)
      break
    case 'height':
      matched = stdout.match(/height="?(\d*)"?/)
      break
    default:
      matched = ['0']
  }
  if (matched && matched[1]) {
    return parseFloat(matched[1])
  }
  console.error(`No ${info} found!`)
  return null
}
const getVideoDuration = async videoPath => getVideoInfo(videoPath, 'duration')
const getVideoWidth = async videoPath => getVideoInfo(videoPath, 'width')
const getVideoHeight = async videoPath => getVideoInfo(videoPath, 'height')

const convertMovToMp4 = async videoPath => {
  try {
    const { ffmpegPath, execAsync } = getDependencies()
    const targetPath = videoPath.replace('.mov', '.mp4')
    const command = `${ffmpegPath} -i ${videoPath} -vcodec copy -acodec copy ${targetPath}`
    await execAsync(command)
    return targetPath
  } catch (err) {
    console.error(err)
    return ''
  }
}

const generateSprites = async ({ imgPrefix, destinationFolder, videoPath }) => {
  if (!platform.isElectron()) return null

  const { ffmpegPath, execAsync, fs } = getDependencies()

  const mkDirAsync = promisify(fs.mkdir)
  const readDirAsync = promisify(fs.readdir)

  const basePath = getAppPath()
  const destination = path.join(basePath, 'recordings', destinationFolder)

  await mkDirAsync(destination)

  const destinationPath = path.join(destination, `${imgPrefix}_%03d.jpg`)

  const command = `${ffmpegPath} -i ${videoPath} -vf fps=1/2 ${destinationPath}`
  await execAsync(command)

  const files = await readDirAsync(destination)
  return files.map(file => path.join(destination, file))
}

const _readAsArrayBuffer = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.onerror = ev => {
      reject(ev)
    }
  })

const makeRecordedVideoSeekableFromBlob = async blob => {
  const decoder = new Decoder()
  const reader = new Reader()

  const buf = await _readAsArrayBuffer(blob)
  const elms = decoder.decode(buf)
  elms.forEach(elm => {
    reader.read(elm)
  })

  const refinedMetadataBuf = tools.makeMetadataSeekable(
    reader.metadatas,
    reader.duration,
    reader.cues
  )
  const buffer = await _readAsArrayBuffer(blob)
  const body = buffer.slice(reader.metadataSize)
  const refinedBlob = new Blob([refinedMetadataBuf, body], {
    type: blob.type,
  })

  return refinedBlob
}

const makeRecordedVideoSeekableFromChunks = async chunks => {
  const webmBlob = new Blob(chunks, { type: 'video/webm' })
  return makeRecordedVideoSeekableFromBlob(webmBlob)
}

const canPlayType = mimeType => {
  const video = document.createElement('video')
  return video.canPlayType(mimeType) === 'probably'
}

// https://github.com/Modernizr/Modernizr/blob/d31f4a000d4cf9cc393e018ab13d29c00775f0e0/feature-detects/video.js
const getSupportedVideoCodecs = () => [
  ...(canPlayType('video/mp4; codecs="avc1.42E01E"') ? ['h264'] : []),
  ...(canPlayType('video/mp4; codecs="hev1"') ? ['h265'] : []),
  ...(canPlayType('video/webm; codecs="h264"') ||
  MediaRecorder.isTypeSupported('video/webm; codecs="h264"')
    ? ['vp8']
    : []), // workaround, I discovered that Chrome records the screen using webm/h264 and not vp8 even if specified
  ...(canPlayType('video/mp4; codecs="av01"') ? ['av1'] : []),
  ...(canPlayType('application/x-mpegURL; codecs="avc1.42E01E"')
    ? ['hls']
    : []),
]

export default {
  getVideoDuration,
  getVideoWidth,
  getVideoHeight,
  generateSprites,
  convertMovToMp4,
  makeRecordedVideoSeekableFromChunks,
  makeRecordedVideoSeekableFromBlob,
  getSupportedVideoCodecs,
}
