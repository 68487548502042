import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, fulfilled, rejected, done } from 'redux-saga-thunk'
import {
  CREATE_REPLY_REQUEST,
  createReplyRequest,
  updateReplyRequest,
  UPDATE_REPLY_REQUEST,
  deleteReplyRequest,
  DELETE_REPLY_REQUEST,
} from '../replies/replies.actions'
import fromComments from '../comments/comments.selectors'

const createReplyPending = state => pending(state, CREATE_REPLY_REQUEST)
const createReplyComplete = state => done(state, CREATE_REPLY_REQUEST)
const createReplyRejected = state => rejected(state, CREATE_REPLY_REQUEST)
const createReplyFulfilled = state => fulfilled(state, CREATE_REPLY_REQUEST)

const updateReplyPending = state => pending(state, UPDATE_REPLY_REQUEST)
const updateReplyFulfilled = state => fulfilled(state, UPDATE_REPLY_REQUEST)
const updateReplyRejected = state => rejected(state, UPDATE_REPLY_REQUEST)
const updateReplyDone = state => done(state, UPDATE_REPLY_REQUEST)

const deleteReplyPending = state => pending(state, DELETE_REPLY_REQUEST)
const deleteReplyFulfilled = state => fulfilled(state, DELETE_REPLY_REQUEST)
const deleteReplyRejected = state => rejected(state, DELETE_REPLY_REQUEST)
const deleteReplyDone = state => done(state, DELETE_REPLY_REQUEST)

const useCRUDReply = (commentId, replyId) => {
  const dispatch = useDispatch()

  const comment = useSelector(fromComments.getComment(commentId))
  const commentReplies = comment?.replies || []

  const reply = commentReplies.find(_reply => _reply.id === replyId) || {}

  const isCreateReplyLoading = useSelector(createReplyPending)
  const isCreateReplyFulfilled = useSelector(createReplyFulfilled)
  const isCreateReplyRejected = useSelector(createReplyRejected)
  const isCreateReplyCompleted = useSelector(createReplyComplete)

  const isUpdateReplyLoading = useSelector(updateReplyPending)
  const isUpdateReplyFulfilled = useSelector(updateReplyFulfilled)
  const isUpdateReplyRejected = useSelector(updateReplyRejected)
  const isUpdateReplyDone = useSelector(updateReplyDone)

  const isDeleteReplyLoading = useSelector(deleteReplyPending)
  const isDeleteReplyFulfilled = useSelector(deleteReplyFulfilled)
  const isDeleteReplyRejected = useSelector(deleteReplyRejected)
  const isDeleteReplyDone = useSelector(deleteReplyDone)

  const onCreateReplyHandler = useCallback(
    (text, mentions) => dispatch(createReplyRequest(commentId, text, mentions)),
    [commentId]
  )

  const onUpdateReplyHandler = useCallback(
    (text, mentions) =>
      dispatch(updateReplyRequest({ text, replyId, commentId, mentions })),
    [replyId, commentId]
  )

  const onDeleteReplyHandler = useCallback(
    () => dispatch(deleteReplyRequest({ replyId, commentId })),
    [replyId, commentId]
  )

  return {
    reply,
    onCreateReplyHandler,
    onUpdateReplyHandler,
    onDeleteReplyHandler,
    isCreateReplyLoading,
    isCreateReplyFulfilled,
    isCreateReplyCompleted,
    isCreateReplyRejected,
    isUpdateReplyLoading,
    isUpdateReplyFulfilled,
    isUpdateReplyRejected,
    isUpdateReplyDone,
    isDeleteReplyLoading,
    isDeleteReplyFulfilled,
    isDeleteReplyRejected,
    isDeleteReplyDone,
  }
}

export default useCRUDReply
