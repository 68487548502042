import get from 'lodash/get'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { listSmartAssistantSuggestions as listSmartAssistantSuggestionsGql } from '../../graphql/queries'
import {
  GET_SUGGESTIONS_REQUEST,
  getSuggestionsRequest,
  getSuggestionsRequestSuccess,
  getSuggestionsRequestFailure,
} from './smartAssistant.actions'
import { LOGIN_REQUEST_SUCCESS } from '../actions'

function* fetchSmartAssistant({ apolloClient }, { meta: { thunk } }) {
  try {
    const data = yield call(apolloClient.query, {
      query: gql(listSmartAssistantSuggestionsGql),
      fetchPolicy: 'network-only',
    })
    const suggestions = get(data, 'data.listSmartAssistantSuggestions', [])
    yield put(getSuggestionsRequestSuccess(suggestions, thunk))
  } catch (e) {
    console.error(e)
    yield put(getSuggestionsRequestFailure(e, thunk))
  }
}

function* triggerFetchSmartAssistant() {
  yield put(getSuggestionsRequest())
}

export default function* smartAssistantSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, triggerFetchSmartAssistant),
    yield takeLatest(GET_SUGGESTIONS_REQUEST, fetchSmartAssistant, {
      apolloClient,
    }),
  ])
}
