import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import InboxArrowDownIcon from 'mdi-react/InboxArrowDownIcon'
import Flex from '../../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  align-self: center;
  align-items: center;
`

const Title = styled.h2``

const IconBox = styled(Flex)`
  position: relative;
`

const IconDot = styled(Flex)`
  width: 12px;
  height: 12px;
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: ${prop('theme.colors.yellow')};
  border: 4px solid white;
  border-radius: 24px;
`

const Icon = styled(InboxArrowDownIcon).attrs({
  size: 56,
})`
  fill: ${prop('theme.colors.secondary')};
`

const StyledButton = styled(Button)`
  && {
    margin-top: 24px;
    font-weight: bold;
  }
`

const StyledText = styled(Flex)`
  width: 260px;
  line-height: 20px;
  color: ${prop('theme.colors.grey600')};
`

const OnboardingCompleteMobile = ({ email, ...props }) => {
  return (
    <Wrapper {...props}>
      <CenteredBox>
        <IconBox>
          <IconDot />
          <Icon />
        </IconBox>
        <Title>Almost done!</Title>
        <StyledText>A verification email has been sent to {email}</StyledText>
        {email.endsWith('@gmail.com') && (
          <Flex>
            <StyledButton
              isBasic
              onClick={() => {
                window.open(
                  'https://mail.google.com/mail/u/0/#search/subject%3Aiterspace+in%3AAnywhere'
                )
                window.location = '/login'
              }}
            >
              Open Email App
            </StyledButton>
          </Flex>
        )}
      </CenteredBox>
    </Wrapper>
  )
}

OnboardingCompleteMobile.propTypes = {
  email: PropTypes.string,
}

OnboardingCompleteMobile.defaultProps = {
  email: '',
}

export default OnboardingCompleteMobile
