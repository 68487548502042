import set from 'lodash/set'
import {
  REGISTRATION_REQUEST_SUCCESS,
  LOGIN_REQUEST_SUCCESS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_DOWNLOAD_PROGRESS,
  RESET_UPLOAD_PROGRESS,
  UPDATE_UPLOAD_PROGRESS,
  LOGOUT_REQUEST_SUCCESS,
} from './actions'
import { buildReducerForActions } from '../util/reduxTools'

export const STATE_KEY = 'app'

const INITIAL_STATE = {
  username: '',
  downloadProgress: 0,
  uploadProgress: {},
  ui: {
    loading: true,
  },
}

const reducer = {
  [REGISTRATION_REQUEST_SUCCESS]: (state, { payload: { username } }) => {
    return { ...state, username }
  },
  [LOGIN_REQUEST_SUCCESS]: (state, { payload: { username } }) => {
    return { ...state, username }
  },
  [START_LOADING]: state => {
    return { ...set(state, 'ui.loading', true) }
  },
  [STOP_LOADING]: state => {
    return { ...set(state, 'ui.loading', false) }
  },
  [UPDATE_DOWNLOAD_PROGRESS]: (state, { payload }) => {
    return { ...set(state, 'downloadProgress', payload) }
  },
  [RESET_UPLOAD_PROGRESS]: state => {
    return {
      ...set(state, 'uploadProgress', {
        progress: 0,
        sessionId: '',
        conversionProgress: 0,
        spritesProgress: 0,
      }),
    }
  },
  [UPDATE_UPLOAD_PROGRESS]: (
    state,
    { payload: { progress, sessionId, conversionProgress, spritesProgress } }
  ) => {
    return {
      ...set(state, 'uploadProgress', {
        progress,
        sessionId,
        conversionProgress,
        spritesProgress,
      }),
    }
  },
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
