import React, { useState } from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import Div100vh from '../../molecules/Div100vh/Div100vh'
import RequestRecording from '../../organisms/RequestRecording'
import fromRemoteTests from '../../../store/remoteTests/remoteTests.selectors'

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${prop('theme.colors.grey100')};
  align-items: center;
  overflow: auto;
  box-sizing: border-box;
  padding-top: 48px;

  @media only screen and (max-width: 768px) {
    padding-top: 16px;
  }
`

const RequestRecordingPage = ({ remoteTestId, ...props }) => {
  const uploadProgress = useSelector(
    fromRemoteTests.getRemoteTestUploadProgress(remoteTestId)
  )

  const [animatedProps, setAnimatedProps] = useState({ scroll: 0 })
  return (
    <Wrapper scrollTop={animatedProps.scroll}>
      <RequestRecording
        uploadProgress={uploadProgress}
        onScroll={setAnimatedProps}
        {...props}
      />
    </Wrapper>
  )
}

RequestRecordingPage.propTypes = {
  remoteTestId: PropTypes.string.isRequired,
}

export default RequestRecordingPage
