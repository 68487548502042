import {
  SET_NOTIFICATIONS_READ_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
} from './notifications.actions'
import { buildReducerForActions } from '../../util/reduxTools'

export const STATE_KEY = 'notifications.activities'

export const INITIAL_STATE = {
  notifications: [],
  unreadCount: 0,
}

const reducer = {
  [SET_NOTIFICATIONS_READ_REQUEST]: (
    state,
    { payload: { notificationIds } }
  ) => {
    const updatedNotifications = state.notifications.map(notification => {
      if (notificationIds.includes(notification.notificationId)) {
        return {
          ...notification,
          unread: false,
        }
      }
      return notification
    })

    const unreadCount = updatedNotifications.reduce((acc, notification) => {
      if (notification.unread) {
        return acc + 1
      }
      return acc
    }, 0)

    return {
      ...state,
      notifications: updatedNotifications,
      unreadCount,
    }
  },
  [GET_NOTIFICATIONS_REQUEST_SUCCESS]: (
    state,
    { payload: { notifications } }
  ) => {
    const unreadCount = notifications.reduce((acc, notification) => {
      if (notification.unread) {
        return acc + 1
      }
      return acc
    }, 0)

    return {
      ...state,
      notifications,
      unreadCount,
    }
  },
  [GET_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS]: (
    state,
    { payload: { count } }
  ) => {
    return {
      ...state,
      unreadCount: count,
    }
  },
}

export default buildReducerForActions(INITIAL_STATE, reducer)
