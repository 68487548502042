import { useDispatch, useSelector } from 'react-redux'
import fromIntegrationsTrello from '../../store/integrations/trello/integrations.trello.selectors'
import {
  createTrelloRequest,
  updateTrelloRequest,
} from '../../store/integrations/trello/integrations.trello.actions'

const useTrello = () => {
  const dispatch = useDispatch()
  const hasTrello = useSelector(fromIntegrationsTrello.getHasTrello) || false

  const onCreateTrelloCard = values => dispatch(createTrelloRequest(values))
  const fetchTrelloResources = () => dispatch(updateTrelloRequest())

  return {
    hasTrello,
    onCreateTrelloCard,
    fetchTrelloResources,
  }
}

export default useTrello
