import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import { getAppSyncClient } from '../../lib/appSyncClient'
import { onCreateComment } from '../../graphql/subscriptions'
import {
  getSessionCommentsRequest,
  updateCommentRequest,
  deleteCommentRequest,
  CREATE_COMMENT_REQUEST,
  UPDATE_COMMENT_REQUEST,
  DELETE_COMMENT_REQUEST,
  createCommentRequest,
} from '../comments/comments.actions'
import fromComments from '../comments/comments.selectors'
import fromSessions from '../sessions/sessions.selectors'
import fromUsers from '../users/users.selectors'

const apolloClient = getAppSyncClient()

const createCommentPending = state => pending(state, CREATE_COMMENT_REQUEST)
const createCommentDone = state => done(state, CREATE_COMMENT_REQUEST)
const createCommentFulfilled = state => fulfilled(state, CREATE_COMMENT_REQUEST)
const createCommentRejected = state => rejected(state, CREATE_COMMENT_REQUEST)

const updateCommentPending = state => pending(state, UPDATE_COMMENT_REQUEST)
const updateCommentDone = state => done(state, UPDATE_COMMENT_REQUEST)
const updateCommentFulfilled = state => fulfilled(state, UPDATE_COMMENT_REQUEST)
const updateCommentRejected = state => rejected(state, UPDATE_COMMENT_REQUEST)

const deleteCommentPending = state => pending(state, DELETE_COMMENT_REQUEST)
const deleteCommentDone = state => done(state, DELETE_COMMENT_REQUEST)
const deleteCommentFulfilled = state => fulfilled(state, DELETE_COMMENT_REQUEST)
const deleteCommentRejected = state => rejected(state, DELETE_COMMENT_REQUEST)

const useCRUDComment = commentId => {
  const sessionId = useSelector(fromSessions.getCurrentSessionId)
  const comment = useSelector(fromComments.getComment(commentId))
  const createdBy = get(comment, 'createdBy')
  const commentUser = useSelector(fromUsers.getUserById(createdBy))

  const dispatch = useDispatch()

  const getCommentsDispatch = useCallback(
    _sessionId => dispatch(getSessionCommentsRequest(_sessionId)),
    [dispatch]
  )

  useEffect(() => {
    const newCommentSubscription = apolloClient
      .subscribe({
        query: gql(onCreateComment),
      })
      .subscribe({
        next: () => getCommentsDispatch(sessionId),
      })

    return () => {
      newCommentSubscription.unsubscribe()
    }
  }, [])

  const onCreateCommentHandler = useCallback(
    (text, videoCurrentTime, videoPoint, videoRectangle, mentions) =>
      dispatch(
        createCommentRequest({
          sessionId,
          text,
          videoPositionSecs: videoCurrentTime,
          videoPoint,
          videoRectangle,
          mentions,
        })
      ),
    [(apolloClient, sessionId)]
  )

  const isCreateCommentPending = useSelector(createCommentPending)
  const isCreateCommentDone = useSelector(createCommentDone)
  const isCreateCommentFulfilled = useSelector(createCommentFulfilled)
  const isCreateCommentRejected = useSelector(createCommentRejected)

  const onUpdateCommentHandler = useCallback(
    (_commentId, _text, _videoPositionSecs, mentions) =>
      dispatch(
        updateCommentRequest({
          commentId: _commentId,
          text: _text,
          videoPositionSecs: _videoPositionSecs,
          mentions,
        })
      ),
    [dispatch]
  )
  const isUpdateCommentPending = useSelector(updateCommentPending)
  const isUpdateCommentDone = useSelector(updateCommentDone)
  const isUpdateCommentFulfilled = useSelector(updateCommentFulfilled)
  const isUpdateCommentRejected = useSelector(updateCommentRejected)

  const onDeleteCommentHandler = useCallback(
    _commentId => dispatch(deleteCommentRequest(_commentId)),
    [dispatch]
  )

  const isDeleteCommentPending = useSelector(deleteCommentPending)
  const isDeleteCommentDone = useSelector(deleteCommentDone)
  const isDeleteCommentFulfilled = useSelector(deleteCommentFulfilled)
  const isDeleteCommentRejected = useSelector(deleteCommentRejected)

  return {
    comment,
    commentUser,
    onCreateCommentHandler,
    onUpdateCommentHandler,
    onDeleteCommentHandler,
    isCreateCommentPending,
    isCreateCommentDone,
    isCreateCommentFulfilled,
    isCreateCommentRejected,
    isUpdateCommentPending,
    isUpdateCommentDone,
    isUpdateCommentFulfilled,
    isUpdateCommentRejected,
    isDeleteCommentPending,
    isDeleteCommentDone,
    isDeleteCommentFulfilled,
    isDeleteCommentRejected,
  }
}

export default useCRUDComment
