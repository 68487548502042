import { v1 as uuidv1 } from 'uuid'
import { useDispatch } from 'react-redux'
import { addNotification } from '../ui-notifications/ui-notifications.actions'

const useUiNotifications = () => {
  const dispatch = useDispatch()
  const onShowNotification = text =>
    dispatch(
      addNotification({
        notificationId: uuidv1(),
        text,
      })
    )

  const onShowLinkCopyNotification = () =>
    onShowNotification('Link copied to clipboard')

  return {
    onShowNotification,
    onShowLinkCopyNotification,
  }
}

export default useUiNotifications
