const NAMESPACE = 'REPLY'

export const CREATE_REPLY = `${NAMESPACE}/CREATE`
export const CREATE_REPLY_REQUEST = `${NAMESPACE}/CREATE/REQUEST`
export const CREATE_REPLY_REQUEST_SUCCESS = `${CREATE_REPLY_REQUEST}/REQUEST_SUCCESS`
export const CREATE_REPLY_REQUEST_FAILURE = `${CREATE_REPLY_REQUEST}/REQUEST_FAILURE`

export const createReplyRequest = (commentId, text, mentions) => ({
  type: CREATE_REPLY_REQUEST,
  payload: { commentId, text, mentions },
  meta: { thunk: CREATE_REPLY_REQUEST },
})

export const createReplyRequestSuccess = (payload, thunk) => ({
  type: CREATE_REPLY_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const createReplyRequestFailure = (e, thunk) => ({
  type: CREATE_REPLY_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_REPLY = `${NAMESPACE}/DELETE`
export const DELETE_REPLY_REQUEST = `${NAMESPACE}/DELETE/REQUEST`
export const DELETE_REPLY_REQUEST_SUCCESS = `${DELETE_REPLY_REQUEST}/REQUEST_SUCCESS`
export const DELETE_REPLY_REQUEST_FAILURE = `${DELETE_REPLY_REQUEST}/REQUEST_FAILURE`

export const deleteReplyRequest = ({ replyId, commentId }) => ({
  type: DELETE_REPLY_REQUEST,
  payload: { replyId, commentId },
  meta: { thunk: DELETE_REPLY_REQUEST },
})

export const deleteReplyRequestSuccess = (payload, thunk) => ({
  type: DELETE_REPLY_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteReplyRequestFailure = (e, thunk) => ({
  type: DELETE_REPLY_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_REPLY = `${NAMESPACE}/UPDATE`
export const UPDATE_REPLY_REQUEST = `${NAMESPACE}/UPDATE/REQUEST`
export const UPDATE_REPLY_REQUEST_SUCCESS = `${UPDATE_REPLY_REQUEST}/REQUEST_SUCCESS`
export const UPDATE_REPLY_REQUEST_FAILURE = `${UPDATE_REPLY_REQUEST}/REQUEST_FAILURE`

export const updateReplyRequest = ({ text, replyId, commentId, mentions }) => ({
  type: UPDATE_REPLY_REQUEST,
  payload: { text, replyId, commentId, mentions },
  meta: { thunk: UPDATE_REPLY_REQUEST },
})

export const updateReplyRequestSuccess = (payload, thunk) => ({
  type: UPDATE_REPLY_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const updateReplyRequestFailure = (e, thunk) => ({
  type: UPDATE_REPLY_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
