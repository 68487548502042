// eslint-disable-next-line import/prefer-default-export
export const PAGE_EVENT_TYPES = {
  navigation: 'navigation',
  click: 'click',
  consoleLog: 'consoleLog',
  consoleDebug: 'consoleDebug',
  consoleInfo: 'consoleInfo',
  consoleWarn: 'consoleWarn',
  consoleError: 'consoleError',
  error: 'error',
  webRequest: 'webRequest',
  webErrorRequest: 'webErrorRequest',
}
