const getOriginalImageSize = e => ({
  width: e.target.naturalWidth,
  height: e.target.naturalHeight,
})

export const PORTRAIT_SIZE = {
  width: 20,
  height: 40,
}

export const LANDSCAPE_SIZE = {
  width: 80,
  height: 40,
}

export const getSpriteSize = e => {
  const naturalSize = getOriginalImageSize(e)
  if (naturalSize.height > naturalSize.width) {
    return PORTRAIT_SIZE
  }
  return LANDSCAPE_SIZE
}
