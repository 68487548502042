import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import Flex from '../../atoms/Flex'
import MobileTopBar from '../../molecules/MobileTopBar'
import MobileModal from '../../molecules/MobileModal'
import MobileTemplate from '../../templates/MobileTemplate'
import useChangePassword from '../../organisms/Settings/hooks/useChangePassword'
import { StyledErrorMessage } from '../../commonStyledComponents'

const Wrapper = styled(Flex)``

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const ErrorMessage = styled(StyledErrorMessage)`
  justify-content: flex-start;
  align-items: flex-start;
`

const Body = styled(Flex)`
  padding: 24px;
`

const Footer = styled(Flex)`
  padding: 24px;
`

const PasswordModal = ({ isVisible, onClose, ...props }) => {
  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const repeatNewPasswordRef = useRef(null)

  const {
    isChangingPassword,
    isChangePasswordFailed,
    isChangePasswordDone,
    changePasswordHandler,
    onChange,
    formErrors,
    isFormValid,
    submitError,
  } = useChangePassword({
    oldPasswordRef: currentPasswordRef,
    newPasswordRef,
    repeatPasswordRef: repeatNewPasswordRef,
  })

  useEffect(() => {
    if (currentPasswordRef?.current) {
      setTimeout(() => currentPasswordRef.current.focus(), 0)
    }
  }, [isVisible, currentPasswordRef?.current])

  useEffect(() => {
    if (isChangePasswordDone) {
      setTimeout(() => {
        onClose()
      }, 2000)
    }
  }, [isChangePasswordDone])

  const header = useMemo(
    () => (
      <MobileTopBar icon={ArrowLeftIcon} onButtonClick={onClose}>
        Change password
      </MobileTopBar>
    ),
    []
  )

  const body = useMemo(
    () => (
      <Body>
        <CenteredBox>
          <Field>
            <Input
              ref={currentPasswordRef}
              type="password"
              placeholder="Type your current password"
              autoFocus
              focused
              onInput={onChange}
            />
            {formErrors.oldPassword && (
              <ErrorMessage>{formErrors.oldPassword}</ErrorMessage>
            )}
          </Field>
        </CenteredBox>
        <CenteredBox>
          <Field>
            <Input
              ref={newPasswordRef}
              type="password"
              placeholder="Type your new password"
              onInput={onChange}
            />
            {formErrors.newPassword && (
              <ErrorMessage>{formErrors.newPassword}</ErrorMessage>
            )}
          </Field>
        </CenteredBox>
        <CenteredBox>
          <Field>
            <Input
              ref={repeatNewPasswordRef}
              type="password"
              placeholder="Type again your new password"
              onInput={onChange}
            />
            {formErrors.repeatPassword && (
              <ErrorMessage>{formErrors.repeatPassword}</ErrorMessage>
            )}
          </Field>
        </CenteredBox>
      </Body>
    ),
    [
      onChange,
      formErrors,
      repeatNewPasswordRef,
      newPasswordRef,
      currentPasswordRef,
    ]
  )

  const footer = useMemo(
    () => (
      <Footer>
        {isChangePasswordFailed && (
          <ErrorMessage style={{ alignSelf: 'flex-end' }}>
            {submitError}
          </ErrorMessage>
        )}
        <Button
          isPrimary
          disabled={!isFormValid || isChangingPassword}
          onClick={() => {
            changePasswordHandler(
              currentPasswordRef.current.value,
              newPasswordRef.current.value
            )
          }}
        >
          Save
        </Button>
      </Footer>
    ),
    [
      isFormValid,
      isChangingPassword,
      changePasswordHandler,
      currentPasswordRef?.current,
      newPasswordRef?.current,
    ]
  )

  return (
    <>
      {isVisible && (
        <Wrapper {...props}>
          <MobileModal onClose={onClose}>
            <MobileTemplate header={header} body={body} footer={footer} />
          </MobileModal>
        </Wrapper>
      )}
    </>
  )
}

PasswordModal.propTypes = {
  name: PropTypes.string.isRequired,
  onNameUpdate: PropTypes.func,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

PasswordModal.defaultProps = {
  isVisible: false,
  onNameUpdate: () => {},
  onClose: () => {},
}

export default PasswordModal
