import React from 'react'
import styled from 'styled-components'
import UserAvatar from '../UserAvatar'

const getBorderRadius = ({ size }) => {
  if (size === 'extrasmall') {
    return 4
  }

  return 8
}

const StyledUserAvatar = styled(UserAvatar)`
  user-select: none;
  cursor: pointer;

  && figure,
  img {
    border-radius: ${getBorderRadius}px !important;
  }
`

const WorkspaceLogo = ({ ...props }) => <StyledUserAvatar {...props} />

export default WorkspaceLogo
