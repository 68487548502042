import { useCallback, useState } from 'react'
import { changePassword } from '../../../../util/auth'

const getChangePasswordErrorMesage = err => {
  if (err.code === 'InvalidParameterException') {
    if (err.message.includes('failed to satisfy constraint')) {
      return 'The passwords must have length greater than 6'
    }
  }
  if (err.code === 'NotAuthorizedException') {
    return 'Wrong password'
  }
  return ''
}

const useChangePassword = ({
  oldPasswordRef,
  newPasswordRef,
  repeatPasswordRef,
}) => {
  const [isChangingPassword, setChangingPassword] = useState(false)
  const [isChangePasswordFailed, setChangePasswordFailed] = useState(false)
  const [isChangePasswordDone, setChangePasswordDone] = useState(false)

  const [submitError, setSubmitError] = useState('')

  const changePasswordHandler = useCallback((oldPassword, newPassword) => {
    setChangingPassword(true)
    changePassword(oldPassword, newPassword)
      .then(() => setChangePasswordDone(true))
      .catch(err => {
        console.error(err)
        setSubmitError(getChangePasswordErrorMesage(err))
        setChangePasswordFailed(true)
        return err
      })
      .finally(() => setChangingPassword(false))
  }, [])

  const [isFormValid, setFormValid] = useState(false)
  const [formErrors, setFormErrors] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  })
  const [touchedInputs, setTouchedInputs] = useState({
    oldPassword: false,
    newPassword: false,
    repeatPassword: false,
  })
  const onChange = useCallback(
    e => {
      if (
        oldPasswordRef?.current &&
        newPasswordRef?.current &&
        repeatPasswordRef?.current
      ) {
        if (e.target === oldPasswordRef?.current) {
          setTouchedInputs({
            ...touchedInputs,
            oldPassword: true,
          })
        }
        if (e.target === newPasswordRef?.current) {
          setTouchedInputs({
            ...touchedInputs,
            newPassword: true,
          })
        }
        if (e.target === repeatPasswordRef?.current) {
          setTouchedInputs({
            ...touchedInputs,
            repeatPassword: true,
          })
        }

        if (
          touchedInputs.oldPassword &&
          oldPasswordRef.current.value.length === 0
        ) {
          setFormValid(false)
          setFormErrors({
            ...formErrors,
            oldPassword: 'The current password can not be empty',
          })
          return
        }

        if (
          touchedInputs.newPassword &&
          newPasswordRef.current.value.length === 0
        ) {
          setFormValid(false)
          setFormErrors({
            ...formErrors,
            newPassword: 'The new password can not be empty',
          })
          return
        }
        if (
          touchedInputs.repeatPassword &&
          repeatPasswordRef.current.value.length === 0
        ) {
          setFormValid(false)
          setFormErrors({
            ...formErrors,
            repeatPassword: 'The two passwords must be equals',
          })
          return
        }
        if (
          touchedInputs.repeatPassword &&
          newPasswordRef.current.value !== repeatPasswordRef.current.value
        ) {
          setFormValid(false)
          setFormErrors({
            ...formErrors,
            repeatPassword: 'The two passwords must be equals',
          })
          return
        }

        setFormValid(true)
        setFormErrors({
          oldPassword: '',
          newPassword: '',
          repeatPassword: '',
        })
      }
    },
    [
      oldPasswordRef?.current,
      newPasswordRef?.current,
      repeatPasswordRef?.current,
      touchedInputs,
    ]
  )

  return {
    isChangingPassword,
    isChangePasswordFailed,
    isChangePasswordDone,
    changePasswordHandler,
    onChange,
    isFormValid,
    formErrors,
    submitError,
  }
}

export default useChangePassword
