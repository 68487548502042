import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { useDispatch } from 'react-redux'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input, Label } from '@zendeskgarden/react-forms'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
  Close,
} from '@zendeskgarden/react-modals'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import MobileModal from '../MobileModal'
import { isMobile } from '../../../lib/platform'
import { createOrganization as createOrganizationGql } from '../../../graphql/mutations'
import {
  setCurrentOrganization,
  updateOrganizationEmailDomainsRequest,
} from '../../../store/organizations/organizations.actions'
import Flex from '../../atoms/Flex'
import WorkspaceEmailDomains from '../../pages/WorkspaceSettingsPage/WorkspaceEmailDomains'

const EmailDomainsLabel = styled(Label)`
  margin-bottom: 8px;
`
const LightGrey = styled(Flex)`
  color: ${prop('theme.colors.grey400')};
  display: inline-flex;
  font-weight: 400;
`

const Subtitle = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: flex-start;
  letter-spacing: 0.05em;
  font-weight: 300;
  font-size: 12px;
  color: ${prop('theme.colors.grey400')};
  margin-top: 4px;
`

const CreateOrganizationModal = ({ isVisible, onClose }) => {
  const inputRef = useRef(null)
  const [title, setTitle] = useState('')
  const [emailDomains, setEmailDomains] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  const onTitleInput = e => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])

  const ModalComponent = isMobile() ? MobileModal : Modal

  return (
    <div>
      {isVisible && (
        <ModalComponent isAnimated={false}>
          <Header>Create Team Workspace</Header>
          <Body>
            <Field>
              <Label>Name</Label>
              <Input
                ref={inputRef}
                placeholder="Acme Corporation"
                autoFocus
                focused
                onInput={onTitleInput}
              />
            </Field>
            <Field
              style={{ display: title ? 'flex' : 'none', marginTop: '16px' }}
            >
              <Flex direction="column">
                <EmailDomainsLabel>
                  Allowed email domains <LightGrey>(Optional)</LightGrey>
                </EmailDomainsLabel>
                <WorkspaceEmailDomains
                  emailDomainsList={null}
                  selectedEmailDomains={[]}
                  onUpdateWorkspaceEmailDomains={_emailDomains =>
                    setEmailDomains(_emailDomains)
                  }
                />
                <Subtitle>
                  Anyone with email addresses at these domains can automatically
                  join the workspace
                </Subtitle>
              </Flex>
            </Field>
          </Body>
          <Footer>
            <FooterItem>
              <Button
                onClick={onClose}
                isBasic
                data-test="cancel-workspace-modal-button"
              >
                Cancel
              </Button>
            </FooterItem>
            <FooterItem>
              <Mutation mutation={gql(createOrganizationGql)}>
                {(createOrganization, { loading, called, data, error }) => {
                  if (called && (data || error)) {
                    onClose(error, data)
                  }
                  return (
                    <Button
                      isPrimary
                      disabled={loading}
                      onClick={() => {
                        if (!title) {
                          return
                        }
                        createOrganization({
                          variables: {
                            input: {
                              title,
                            },
                          },
                        }).then(organization => {
                          const organizationId =
                            organization?.data?.createOrganization?.pk
                          if (organizationId) {
                            dispatch(
                              updateOrganizationEmailDomainsRequest({
                                organizationId,
                                emailDomains,
                              })
                            ).then(() => {
                              dispatch(setCurrentOrganization(organizationId))
                              history.push('/app')
                            })
                            return
                          }
                          console.error(
                            Error(
                              `Excpected organizationId, received ${organization}`
                            )
                          )
                        })
                      }}
                      data-test="create-workspace-button"
                    >
                      Create
                    </Button>
                  )
                }}
              </Mutation>
            </FooterItem>
          </Footer>
          <Close aria-label="Close modal" onClick={onClose} />
        </ModalComponent>
      )}
    </div>
  )
}

CreateOrganizationModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

CreateOrganizationModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default CreateOrganizationModal
