import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Field, Label, Input } from '@zendeskgarden/react-forms'
import { Button } from '@zendeskgarden/react-buttons'
import Loader from '../../atoms/Loader'
import Flex from '../../atoms/Flex'
import InvitedUserRow from './InvitedUserRow'

const Wrapper = styled(Flex)`
  flex-direction: column;
  font-size: 14px;
`

const Row = styled(Flex)`
  flex-direction: row;
  margin-bottom: 16px;
`

const Col = styled(Flex)``

const StyledInput = styled(Input)`
  width: auto;
  flex-grow: 1;
`

const InviteButton = styled(Button)`
  margin-left: 16px;
  width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInvitedUserRow = styled(InvitedUserRow)`
  margin-bottom: 8px;
`

const StyledLoader = styled(Loader).attrs(props => ({
  color: prop('theme.colors.grey600')(props),
}))``

const Sharing = ({
  users,
  onRemove,
  onAdd,
  totalWorkspaceMembers,
  totalContributors,
  isInviteButtonDisabled,
  onInput,
  showInviteEmailField,
  showMembersRecap,
  ...props
}) => {
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <Wrapper {...props}>
      {showInviteEmailField && (
        <>
          <Field>
            <Label>Share with users</Label>
          </Field>
          <Row>
            <StyledInput
              ref={inputRef}
              onInput={onInput}
              placeholder="Email"
              data-test="sharing-invite-input"
            />
            <InviteButton
              isPrimary
              disabled={isInviteButtonDisabled}
              onClick={() => {
                setIsLoading(true)
                onAdd(inputRef.current.value).finally(() => setIsLoading(false))
                inputRef.current.value = ''
              }}
              data-test="sharing-invite-button"
            >
              {isLoading && <StyledLoader size="16px" />}
              {!isLoading && <>Invite</>}
            </InviteButton>
          </Row>
        </>
      )}
      <Col>
        {showMembersRecap && !!totalWorkspaceMembers && (
          <Row>
            {totalContributors} of {totalWorkspaceMembers} members can
            contribute
          </Row>
        )}
        {users.map(user => (
          <StyledInvitedUserRow
            key={user.email}
            email={user.email}
            picture={user.picture}
            isOwner={user.isOwner}
            isProjectContributor={user.isProjectContributor}
            isInvited={user.isInvited}
            isPending={user.isPending}
            onRemoveUser={() => onRemove(user.email)}
            onAddUser={() => onAdd(user.email)}
            data-test={`sharing-user-${user.email}`}
          />
        ))}
      </Col>
    </Wrapper>
  )
}

Sharing.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  onInput: PropTypes.func,
  totalWorkspaceMembers: PropTypes.number,
  totalContributors: PropTypes.number,
  isInviteButtonDisabled: PropTypes.bool,
  showInviteEmailField: PropTypes.bool,
  showMembersRecap: PropTypes.bool,
}

Sharing.defaultProps = {
  users: [],
  onRemove: () => {},
  onAdd: () => {},
  onInput: () => {},
  totalWorkspaceMembers: 0,
  totalContributors: 0,
  isInviteButtonDisabled: true,
  showInviteEmailField: false, // input field is deprecated
  showMembersRecap: true,
}

export default Sharing
