import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MD } from '@zendeskgarden/react-typography'
import Flex from '../../atoms/Flex'
import Activity from '../../molecules/Activity'

const Wrapper = styled(Flex)``

const StyledActivity = styled(Activity)`
  padding: 16px;
  cursor: pointer;
`

const EmptyState = styled(MD)`
  text-align: center;
  padding: 16px 24px 24px 24px;
`

const ActivitiesList = ({ notifications, ...props }) => (
  <Wrapper {...props}>
    {notifications.length === 0 && (
      <EmptyState>No notifications yet</EmptyState>
    )}
    {notifications.map(
      ({
        notificationId,
        icon,
        userPictureUrl,
        userName,
        activityNode,
        messageText,
        timeAgo,
        isUnread,
        onClick,
      }) => (
        <StyledActivity
          key={notificationId}
          icon={icon}
          userPictureUrl={userPictureUrl}
          userName={userName}
          activityNode={activityNode}
          messageText={messageText}
          timeAgo={timeAgo}
          isUnread={isUnread}
          onClick={onClick}
        />
      )
    )}
  </Wrapper>
)

const NotificationShape = PropTypes.shape({
  notificationId: PropTypes.string.isRequired,
  icon: PropTypes.string,
  userPictureUrl: PropTypes.string,
  userName: PropTypes.string,
  activityNode: PropTypes.element,
  messageText: PropTypes.string,
  timeAgo: PropTypes.string,
  isUnread: PropTypes.bool,
})

ActivitiesList.propTypes = {
  notifications: PropTypes.arrayOf(NotificationShape),
}

ActivitiesList.defaultProps = {
  notifications: [],
}

export default ActivitiesList
