import { useSelector } from 'react-redux'
import flow from 'lodash/fp/flow'
import concatFp from 'lodash/fp/concat'
import uniqByFp from 'lodash/fp/uniqBy'
import filterFp from 'lodash/fp/filter'
import compact from 'lodash/compact'
import fromUsers from '../users/users.selectors'
import fromUser from '../user/user.selectors'
import fromComments from '../comments/comments.selectors'
import fromSessions from '../sessions/sessions.selectors'

const useMentionSuggestions = () => {
  const userIdentityId = useSelector(fromUser.getIdentityId)
  const currentUserIdentityId = `user:${userIdentityId}`
  const currentOrganizationUsers =
    useSelector(fromUsers.getCurrentOrganizationUsers) || []

  const currentSession = useSelector(fromSessions.getCurrentSession)
  const sessionCommentUsers = useSelector(
    fromComments.getSessionCommentUsers(currentSession?.pk)
  )
  const sessionCreatorUser = useSelector(
    fromUsers.getUserById(currentSession?.createdBy)
  )
  const suggestions = flow(
    concatFp(sessionCommentUsers),
    concatFp([sessionCreatorUser]),
    compact,
    filterFp(({ pk }) => pk !== currentUserIdentityId),
    uniqByFp('pk')
  )(currentOrganizationUsers)

  return {
    suggestions,
  }
}

export default useMentionSuggestions
