import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Flex from '../Flex'

const Wrapper = styled(Flex)`
  min-height: 40px;
  padding: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  border: none;
  resize: none;
  outline: 0;
  color: ${prop('theme.colors.grey600')};
`

const EditableText = ({
  autoFocusToken,
  innerRef,
  readOnly,
  textAreaProps,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false)
  useEffect(() => {
    if (autoFocusToken && innerRef?.current) {
      innerRef.current.focus()
    }
  }, [autoFocusToken, innerRef?.current])
  return (
    <Wrapper isFocused={isFocused} readOnly={readOnly} {...props}>
      <StyledTextareaAutosize
        readOnly={readOnly}
        inputRef={innerRef}
        {...textAreaProps}
        onBlur={() => {
          setFocused(false)
          if (textAreaProps.onBlur) {
            textAreaProps.onBlur()
          }
        }}
        onFocus={() => {
          setFocused(true)
          if (textAreaProps.onFocus) {
            textAreaProps.onFocus()
          }
        }}
      />
    </Wrapper>
  )
}

EditableText.propTypes = {
  autoFocusToken: PropTypes.string,
  innerRef: PropTypes.shape({
    current: PropTypes.shape({
      focus: PropTypes.func,
    }),
  }),
  textAreaProps: PropTypes.shape({
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  readOnly: PropTypes.bool,
}

EditableText.defaultProps = {
  autoFocusToken: '',
  innerRef: { current: { value: '' } },
  textAreaProps: {},
  readOnly: false,
}

export default EditableText
