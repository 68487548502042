import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, rejected, done, fulfilled } from 'redux-saga-thunk'
import { UPDATE_USER_REQUEST, updateUserRequest } from '../user/user.actions'
import fromUser from '../user/user.selectors'

const updateUserPending = state => pending(state, UPDATE_USER_REQUEST)
const updateUserDone = state => done(state, UPDATE_USER_REQUEST)
const updateUserFulfilled = state => fulfilled(state, UPDATE_USER_REQUEST)
const updateUserRejected = state => rejected(state, UPDATE_USER_REQUEST)

const useCRUDUser = () => {
  const identityId = useSelector(fromUser.getIdentityId)
  const dispatch = useDispatch()

  const onUpdateUserHandler = useCallback(
    userData => dispatch(updateUserRequest({ ...userData, identityId })),
    [dispatch, identityId]
  )
  const isUpdateSessionPending = useSelector(updateUserPending)
  const isUpdateUserDone = useSelector(updateUserDone)
  const isUpdateUserFulfilled = useSelector(updateUserFulfilled)
  const isUpdateUserRejected = useSelector(updateUserRejected)

  return {
    onUpdateUserHandler,
    isUpdateSessionPending,
    isUpdateUserDone,
    isUpdateUserFulfilled,
    isUpdateUserRejected,
  }
}

export default useCRUDUser
