import React from 'react'
import styled from 'styled-components'
import { MD, XL } from '@zendeskgarden/react-typography'
import prop from 'lodash/fp/prop'
import EmptyProjectSVG from '../../../assets/empty_project.svg'
import Flex from '../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const SVGFlex = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 240px;
  height: 160px;
`

const StyledXL = styled(XL)`
  color: ${prop('theme.colors.grey900')};
  margin-top: 24px;
`

const StyledMD = styled(MD)`
  color: ${prop('theme.colors.grey600')};
  margin-top: 8px;
  text-align: center;
`

const ProjectEmptyState = ({ ...props }) => (
  <Wrapper {...props}>
    <SVGFlex>
      <Image src={EmptyProjectSVG} alt="empty-project" />
      <StyledXL>This project is empty</StyledXL>
      <StyledMD>Create a new recording or upload it from your device</StyledMD>
    </SVGFlex>
  </Wrapper>
)

export default ProjectEmptyState
