import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import prop from 'lodash/fp/prop'
import { withRouter, useHistory } from 'react-router-dom'
import {
  Chrome,
  Body,
  Header,
  HeaderItem,
  HeaderItemWrapper,
  HeaderItemText,
  Main,
} from '@zendeskgarden/react-chrome'
import BullhornOutlineIcon from 'mdi-react/BullhornOutlineIcon'
import Tippy from '@tippyjs/react'
import ActivityIcon from '../../molecules/ActivityIcon'
import UserSettingsMenu from '../../molecules/UserSettingsMenu'
import ShareModal from '../../molecules/ShareModal'
import Notifications from '../../organisms/Notifications'
import EntryView from '../../organisms/EntryView'
import SessionView from '../../organisms/SessionView'
import Logo from '../../atoms/Logo'
import OrganizationsSelect from '../../organisms/OrganizationsSelect'
import { isElectron } from '../../../lib/platform'
import { getSessionUrl } from '../../../lib/urls'
import { DESKTOP_DRAG_BAR_HEIGHT } from '../../atoms/DesktopDragBar/DesktopDragBar'
import RequestRecordingLinkModal from '../../molecules/RequestRecordingLinkModal'
import SettingsModal from '../../organisms/Settings/SettingsModal'
import SessionTopBar from '../../organisms/SessionTopBar'
import useMainAppPage from './useMainAppPage'
import RequestRecordingCreatePage from '../RequestRecordingCreatePage'
import { MAIN_VIEWS, HEADER_HEIGHT } from './MainAppPage.constants'
import fromSessions from '../../../store/sessions/sessions.selectors'
import useNotifications from '../../../store/hooks/useNotifications'
import CreateOrganizationModal from '../../molecules/CreateOrganizationModal'
import CreateJiraIssueModal from '../../molecules/CreateJiraIssueModal'
import CreateTrelloCardModal from '../../molecules/CreateTrelloCardModal'
import SendSlackMessageModal from '../../molecules/SendSlackMessageModal'
import ActivitiesList from '../../organisms/ActivitiesList'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const StyledChrome = styled(Chrome)`
  height: calc(100vh - ${DESKTOP_DRAG_BAR_HEIGHT}px);
`

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: column;
`

const StyledHeader = styled(Header)`
  flex: 0 0 ${HEADER_HEIGHT}px;
  order: 0;
  z-index: 100;
  box-shadow: ${prop('theme.boxShadows.topBarElevation')};
`

const StyledMain = styled(Main)`
  flex: 1 1;
  order: 1;
`

const SmallHeaderItem = styled(HeaderItem)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  justify-items: center;
  display: flex;
`

const ChangelogHeaderItem = styled(SmallHeaderItem)`
  border-radius: 50%;
  transition: all 0.3s ease-out;
  margin-right: 8px;

  svg {
    margin-left: 4px;
  }

  &&:hover {
    background-color: ${prop('theme.colors.secondary100')};

    svg {
      transition: all 0.3s ease-out;
      fill: ${prop('theme.colors.grey800')};
    }
  }
`

const StyledLogo = styled(Logo)`
  width: 32px;
  margin-right: 24px;
`

const MainAppPage = ({ initialView, onLogout, username, ...props }) => {
  const {
    onInitDesktop,
    isUserLogged,
    onLogoutHandler,
    onSetUserId,
    userName,
    currentView,
    setCurrentView,
  } = useMainAppPage(initialView)

  const {
    unreadNotificationsCount,
    onMarkAllNotificationsAsRead,
  } = useNotifications()

  const history = useHistory()

  const onLogoutClickHandler = () => {
    onLogoutHandler()
      .then(() => {
        onLogout()
      })
      .catch(console.error)
  }

  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false)
  const onSettingsClickHandler = () => {
    setSettingsModalVisible(true)
  }

  const [
    isRequestRecordingLinkModalVisible,
    setRequestRecordingLinkModalVisible,
  ] = useState(false)

  const [requestRecordingTitle, setRequestRecordingTitle] = useState('')
  const [requestRecordingUrl, setRequestRecordingUrl] = useState('')

  useEffect(() => {
    onSetUserId(username)

    if (isElectron()) {
      onInitDesktop()
    }

    return () => {}
  }, [])

  const [isShareModalVisible, setShareModalVisible] = useState(false)
  const currentSession = useSelector(fromSessions.getCurrentSession)

  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)

  const [
    isJiraCreateIssueModalVisible,
    setJiraCreateIssueModalVisible,
  ] = useState(false)
  const [jiraIssueDescription, setJiraIssueDescription] = useState('')
  const [jiraIssueResourceId, setJiraIssueResourceId] = useState('')
  const [jiraIssueResourceUrl, setJiraIssueResourceUrl] = useState('')

  const [
    isTrelloCreateCardModalVisible,
    setTrelloCreateCardModalVisible,
  ] = useState(false)
  const [trelloCardSessionId, setTrelloCardSessionId] = useState(null)
  const [trelloCardCommentId, setTrelloCardCommentId] = useState(null)

  const [
    isSendSlackMessageModalVisible,
    setSendSlackMessageModalVisible,
  ] = useState(false)
  const [slackSessionId, setSlackSessionId] = useState(null)

  return (
    <StyledChrome {...props}>
      <StyledBody>
        {[MAIN_VIEWS.createRemoteTest, MAIN_VIEWS.entry].includes(
          currentView.name
        ) && (
          <StyledHeader>
            <HeaderItemWrapper maxX>
              <StyledLogo />
              {currentView.name !== MAIN_VIEWS.session && (
                <OrganizationsSelect
                  onChangeOrganizationInSession={() => {
                    if (currentView.name === MAIN_VIEWS.session) {
                      history.push('/app')
                    }
                  }}
                />
              )}
            </HeaderItemWrapper>
            <Tippy
              content="Latest changes"
              placement="bottom"
              arrow={false}
              zIndex={10}
            >
              <ChangelogHeaderItem>
                <div className="headway-widget">
                  <BullhornOutlineIcon size={24} />
                </div>
              </ChangelogHeaderItem>
            </Tippy>
            <SmallHeaderItem style={{ marginLeft: '4px' }}>
              <ActivityIcon
                count={unreadNotificationsCount}
                notificationItems={<ActivitiesList />}
                onMarkAllAsReadClick={onMarkAllNotificationsAsRead}
              />
            </SmallHeaderItem>
            {isUserLogged && (
              <UserSettingsMenu
                userName={userName}
                onSettings={onSettingsClickHandler}
                onLogout={onLogoutClickHandler}
              />
            )}
            {!isUserLogged && (
              <React.Fragment>
                <HeaderItem
                  onClick={() => {
                    // eslint-disable-next-line react/prop-types
                    props.history.push('/login')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <HeaderItemText>Login</HeaderItemText>
                </HeaderItem>
                <HeaderItem
                  onClick={() => {
                    // eslint-disable-next-line react/prop-types
                    props.history.push('/register')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <HeaderItemText>Sign up</HeaderItemText>
                </HeaderItem>
              </React.Fragment>
            )}
          </StyledHeader>
        )}
        {currentView.name === MAIN_VIEWS.session && (
          <SessionTopBar
            onSettings={onSettingsClickHandler}
            onLogout={onLogoutClickHandler}
            onShare={() => setShareModalVisible(true)}
            onCreateJira={({ sessionId }) => {
              setJiraIssueDescription('')
              setJiraIssueResourceId(sessionId)
              setJiraIssueResourceUrl(getSessionUrl(sessionId))
              setJiraCreateIssueModalVisible(true)
            }}
            onCreateTrello={({ sessionId = null, commentId = null }) => {
              setTrelloCreateCardModalVisible(true)
              setTrelloCardSessionId(sessionId)
              setTrelloCardCommentId(commentId)
            }}
            onSendSlackMessage={({ sessionId }) => {
              setSendSlackMessageModalVisible(true)
              setSlackSessionId(sessionId)
            }}
            isTemporaryRecording={!!currentView.props.recordingId}
          />
        )}
        <StyledMain>
          {currentView.name === MAIN_VIEWS.entry && (
            <EntryView
              setCurrentView={setCurrentView}
              onSettings={onSettingsClickHandler}
            />
          )}
          {currentView.name === MAIN_VIEWS.session && (
            <SessionView
              {...currentView.props}
              readOnly={false}
              onShowShareModal={() => setShareModalVisible(true)}
              onShowUserSettings={() => onSettingsClickHandler()}
            />
          )}
          {currentView.name === MAIN_VIEWS.createRemoteTest && (
            <RequestRecordingCreatePage
              setCurrentView={setCurrentView}
              setModalTitle={setRequestRecordingTitle}
              setModalUrl={setRequestRecordingUrl}
              setModalVisible={setRequestRecordingLinkModalVisible}
              remoteTestId={currentView?.props?.remoteTestId}
            />
          )}
        </StyledMain>
      </StyledBody>
      <SettingsModal
        isVisible={isSettingsModalVisible}
        onClose={() => setSettingsModalVisible(false)}
      />
      <RequestRecordingLinkModal
        title={requestRecordingTitle}
        url={requestRecordingUrl}
        isVisible={isRequestRecordingLinkModalVisible}
        onClose={() => setRequestRecordingLinkModalVisible(false)}
      />
      {isShareModalVisible && (
        <ShareModal
          title={currentSession?.title}
          isVisible={isShareModalVisible}
          sessionId={currentSession?.pk}
          onClose={() => {
            setShareModalVisible(false)
          }}
          onCreateWorkspace={() => {
            setShareModalVisible(false)
            setCreateOrganizationModalVisible(true)
          }}
        />
      )}
      <CreateOrganizationModal
        isVisible={isCreateOrganizationModalVisible}
        onClose={() => {
          setCreateOrganizationModalVisible(false)
        }}
      />
      {isJiraCreateIssueModalVisible && (
        <CreateJiraIssueModal
          isVisible
          initialDescription={jiraIssueDescription}
          resourceId={jiraIssueResourceId}
          resourceUrl={jiraIssueResourceUrl}
          sessionId={jiraIssueResourceId}
          onClose={() => setJiraCreateIssueModalVisible(false)}
        />
      )}
      {isTrelloCreateCardModalVisible && (
        <CreateTrelloCardModal
          sessionId={trelloCardSessionId}
          commentId={trelloCardCommentId}
          onClose={() => setTrelloCreateCardModalVisible(false)}
        />
      )}
      {isSendSlackMessageModalVisible && (
        <SendSlackMessageModal
          sessionId={slackSessionId}
          onClose={() => setSendSlackMessageModalVisible(false)}
        />
      )}
      <Notifications />
    </StyledChrome>
  )
}

MainAppPage.propTypes = {
  initialView: PropTypes.shape({
    name: PropTypes.string.isRequired,
    props: PropTypes.shape({}),
  }),
  onLogout: PropTypes.func,
  username: PropTypes.string,
}

MainAppPage.defaultProps = {
  initialView: { name: MAIN_VIEWS.entry, props: { isEditMode: false } },
  onLogout: () => {},
  username: '',
}

export default withRouter(MainAppPage)
