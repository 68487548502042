import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@zendeskgarden/react-loaders'
import { secondaryColor } from '../../theme/old'

const Loader = ({ color, size, ...props }) => (
  <Spinner size={size} color={color} duration={1250} delayMS={0} {...props} />
)

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

Loader.defaultProps = {
  color: secondaryColor,
  size: '54px',
}

export default Loader
