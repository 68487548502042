import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import UserAvatar from '../../atoms/UserAvatar'

const Wrapper = styled(Flex)`
  flex-direction: row;
  font-size: 14px;
`

const StyledUserAvatar = styled(UserAvatar)`
  margin-right: 16px;
  width: 32px;
`

const Name = styled(Flex)`
  flex-grow: 1;
  line-height: 32px;
  font-weight: bold;
`

const Right = styled(Flex)`
  flex-grow: 0;
  line-height: 32px;
  color: ${prop('theme.colors.grey600')};
`

const ProjectOwner = ({ email, name, picture, ...props }) => (
  <Wrapper {...props}>
    <StyledUserAvatar imgUrl={picture} name={email} status={null} />
    <Name>{name}</Name>
    {email && <Right>{email}</Right>}
  </Wrapper>
)

ProjectOwner.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  picture: PropTypes.string,
}

ProjectOwner.defaultProps = {
  picture: null,
}

export default ProjectOwner
