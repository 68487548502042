import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import WorkspaceUpsellImage from '../../../assets/workspace_upsell_image.svg'

const StyledHeader = styled(Header)`
  && {
    border-bottom: none;
  }
`

const Title = styled(Flex)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${prop('theme.colors.grey800')};
`
const Description = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: ${prop('theme.colors.grey600')};
  padding: 16px;
  margin-bottom: 24px;
  line-height: 24px;
  align-self: center;
`

const Image = styled.img`
  margin-bottom: 32px;
`

const WorkspaceUpsellModal = ({ isVisible, onCreate, onClose }) => {
  return (
    <>
      {isVisible && (
        <Modal
          onClose={onClose}
          isAnimated={false}
          isLarge
          style={{ width: '400px' }}
        >
          <StyledHeader />
          <Body>
            <Flex direction="column">
              <Image src={WorkspaceUpsellImage} alt="image" />
              <Flex>
                <Title justifyContent="center" alignItems="center">
                  Do more with sharable projects
                </Title>
                <Description>
                  Create a team workspace to share projects with your teammates
                </Description>
                <Button
                  isPrimary
                  onClick={() => {
                    onCreate()
                    onClose()
                  }}
                >
                  Create Team Workspace
                </Button>
              </Flex>
            </Flex>
          </Body>
          <Close aria-label="Close modal" onClick={onClose} />
        </Modal>
      )}
    </>
  )
}

WorkspaceUpsellModal.propTypes = {
  isVisible: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
}

WorkspaceUpsellModal.defaultProps = {
  isVisible: false,
  onCreate: () => {},
  onClose: () => {},
}

export default WorkspaceUpsellModal
