import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import pick from 'lodash/fp/pick'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Alert, Title } from '@zendeskgarden/react-notifications'
import { LG } from '@zendeskgarden/react-typography'
import { Formik, Form, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import { trackSignUpRequest } from '../../../../store/analytics/analytics.actions'
import GoogleButton from '../../../molecules/GoogleButton'
import Flex from '../../../atoms/Flex'
import Link from '../../../atoms/Link'
import Anchor from '../../../atoms/Anchor'
import { StyledErrorMessage } from '../../../commonStyledComponents'
import { getIsGoogleLoginAvailable } from '../../../../util/auth'

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const StyledForm = styled(Form)`
  margin: 64px 64px 0 64px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${prop('theme.colors.grey200')};
  width: 80%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledToS = styled(CenteredBox)`
  display: block;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 0;
  color: ${prop('theme.colors.grey600')};
  white-space: break-spaces;
`

const StyledAnchor = styled(Anchor)`
  display: inline-block;
`

const StyledLink = styled(Link)`
  display: inline-flex;
  margin-left: 8px;
`

const StyledWarningAlert = styled(Alert)`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${prop('theme.colors.yellow800')};
  background-color: ${prop('theme.colors.yellow100')};
  border-color: ${prop('theme.colors.yellow300')};
  text-align: left;
`

const StyledEmailWarningAlert = styled(StyledWarningAlert)`
  margin-top: 0;
  margin-bottom: 16px;
`

const StyledWarningTitle = styled(Title)`
  color: ${prop('theme.colors.yellow900')} !important;
`

const formInitialValues = {
  name: '',
  surname: '',
  email: '',
  password: '',
}

const validateFormValues = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'The email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  } else if (!values.password) {
    errors.password = 'The password is required'
  } else if (values.password.length < 8) {
    errors.password = 'The password must be longer than 8 characters'
  }

  return errors
}

const RegistrationForm = ({
  setUserData,
  onComplete,
  onSignUp,
  onShowLogin,
  ...props
}) => {
  const dispatch = useDispatch()
  const [isAlreadyRegistered, setAlreadyRegistered] = useState(false)
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
    setUserData(pick(['email'])(values))
    onSignUp(values)
      .then(() => {
        dispatch(trackSignUpRequest({ isGoogle: false }))
        onComplete({ isGoogle: false })
      })
      .catch(({ error }) => {
        setSubmitting(false)
        if (error.code === 'UsernameExistsException') {
          setAlreadyRegistered(true)
        }
      })
  }

  const onGoogleSignUpComplete = () => {
    dispatch(trackSignUpRequest({ isGoogle: true }))
    onComplete({ isGoogle: true })
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validate={validateFormValues}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        isValid,
      }) => {
        return (
          <React.Fragment>
            <StyledForm {...props}>
              <CenteredBox>
                <LG>Create an account</LG>
              </CenteredBox>
              <CenteredBox>
                <GoogleButton
                  onComplete={onGoogleSignUpComplete}
                  disabled={!getIsGoogleLoginAvailable()}
                >
                  Sign up with Google
                </GoogleButton>
              </CenteredBox>
              <CenteredBox>or</CenteredBox>
              <CenteredBox>
                <Field>
                  <Input
                    type="email"
                    name="email"
                    autocomplete="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    validation={errors.email ? 'warning' : undefined}
                    placeholder="Email"
                  />
                  <StyledErrorMessage>
                    <ErrorMessage name="email" />
                  </StyledErrorMessage>
                </Field>
                <Field
                  style={{
                    display: values.email.length === 0 ? 'none' : 'block',
                  }}
                >
                  <Input
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    validation={errors.password ? 'warning' : undefined}
                    placeholder="Password (min 8 characters)"
                    style={{ marginTop: '16px' }}
                  />
                  <StyledErrorMessage>
                    <ErrorMessage name="password" />
                  </StyledErrorMessage>
                </Field>
              </CenteredBox>
              <CenteredBox
                style={{
                  display: values.email.length === 0 ? 'none' : 'flex',
                }}
              >
                <Button
                  isStretched
                  isPrimary
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  data-test="sign-up-button"
                >
                  Get Started
                </Button>
              </CenteredBox>
              {isAlreadyRegistered && (
                <StyledWarningAlert type="warning">
                  You are already registered, just{' '}
                  <Link to="/login" onClick={onShowLogin}>
                    log in
                  </Link>
                </StyledWarningAlert>
              )}
              {!getIsGoogleLoginAvailable() && (
                <CenteredBox>
                  <StyledEmailWarningAlert
                    type="warning"
                    style={{ margin: '0' }}
                  >
                    <StyledWarningTitle>
                      Can&apos;t sign up with Google?
                    </StyledWarningTitle>
                    To sign up with Google you need to enable third-party
                    cookies or exit Incognito mode.
                  </StyledEmailWarningAlert>
                </CenteredBox>
              )}
              <StyledToS>
                By creating an account, you agree to the{' '}
                <StyledAnchor
                  href="https://www.iterspace.com/terms"
                  target="_blank"
                >
                  Terms of Service
                </StyledAnchor>{' '}
                and the{' '}
                <StyledAnchor
                  href="https://www.iterspace.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </StyledAnchor>
              </StyledToS>
            </StyledForm>
            <CenteredBox style={{ marginTop: '32px' }} direction="row">
              <span style={{ display: 'inline-flex' }}>
                Already registered?
              </span>
              <StyledLink to="/login" onClick={onShowLogin}>
                Login
              </StyledLink>
            </CenteredBox>
          </React.Fragment>
        )
      }}
    />
  )
}

RegistrationForm.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  onShowLogin: PropTypes.func,
}

RegistrationForm.defaultProps = {
  onShowLogin: () => {},
}

export default RegistrationForm
