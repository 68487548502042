import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import compact from 'lodash/compact'
import useUserInfo from '../../../store/hooks/useUserInfo'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import { getOrganizationsRequest } from '../../../store/organizations/organizations.actions'
import { getUsersRequest } from '../../../store/users/users.actions'
import api from '../../../api'
import analytics from '../../../lib/analytics'

export const ROLES = {
  admin: 'admin',
  member: 'member',
  remove: 'remove',
}

const usersReducer = organization => (acc, user) => {
  const role =
    organization.admins &&
    organization.admins.includes(user.pk.replace('user:', ''))
      ? ROLES.admin
      : ROLES.member
  return [...acc, { ...user, role }]
}

export const getOrganizationMemberIds = _organization =>
  compact((_organization?.admins || []).concat(_organization?.members || []))

const onRoleChange = (
  organizationId,
  dispatch,
  inviterIdentityId,
  onComplete
) => ({ userEmail, userRole }) =>
  api
    .addOrganizationMember({
      organizationId,
      userRole,
      userEmail,
      inviterIdentityId,
    })
    .then(() =>
      dispatch(getOrganizationsRequest()).then(({ organizations }) => {
        const currentUpdatedOrganization = organizations.find(
          org => org?.pk === organizationId
        )
        onComplete(currentUpdatedOrganization, userRole)
        return organizations
      })
    )

const useOrganizationMembers = organizationId => {
  const {
    userIdentityId,
    userIdentityId: inviterIdentityId,
    userEmail,
  } = useUserInfo()
  const dispatch = useDispatch()
  const organization = useSelector(
    fromOrganizations.getOrganizationById(organizationId)
  )

  const admins = get(organization, 'admins', [])
  const members = get(organization, 'members', [])

  const isCurrentUserOrganizationAdmin = admins.includes(userIdentityId)
  const membersUserIds = compact(admins.concat(members))

  const [organizationUsers, setOrganizationUsers] = useState([])

  const fetchUsersCallback = useCallback(
    (currentOrganization, response) => {
      const responseUsers = get(response, 'users', [])
      const invitedUsers = get(currentOrganization, 'invitedUsers', []) || []
      const reduced = responseUsers
        .reduce(usersReducer(currentOrganization), [])
        .concat(
          invitedUsers.map(x => ({
            email: x.userEmail,
            role: x.userRole,
            isPendingRegistration: true,
          }))
        )
      setOrganizationUsers(reduced)
    },
    [usersReducer]
  )

  const fetchUsers = useCallback(
    currentOrganization => {
      const orgMembersIds = getOrganizationMemberIds(currentOrganization)
      if (orgMembersIds.length > 0) {
        const promise = dispatch(getUsersRequest(orgMembersIds))
        if (promise.then) {
          return promise.then(response =>
            fetchUsersCallback(currentOrganization, response)
          )
        }
        return Promise.resolve([])
      }
      return Promise.resolve([])
    },
    [dispatch, fetchUsersCallback]
  )

  const curriedOnRoleChange = onRoleChange(
    organizationId,
    dispatch,
    inviterIdentityId,
    (currentOrganization, userRole) => {
      fetchUsers(currentOrganization)
      // track
      if (userRole === ROLES.admin || userRole === ROLES.member) {
        analytics.trackOrganizationInviteUser({
          organizationId: currentOrganization.pk,
          userIdentityId: inviterIdentityId,
          userRole,
        })
      }

      if (userRole === ROLES.remove) {
        analytics.trackOrganizationRemoveUser({
          organizationId: currentOrganization.pk,
          userIdentityId: inviterIdentityId,
        })
      }
    }
  )

  const onAddUser = curriedOnRoleChange
  const onChangeRole = curriedOnRoleChange

  return {
    fetchUsers,
    isCurrentUserOrganizationAdmin,
    currentUserEmail: userEmail,
    onAddUser,
    onChangeRole,
    organization,
    membersUserIds,
    organizationUsers,
  }
}

export default useOrganizationMembers
