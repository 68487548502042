import { useSelector } from 'react-redux'
import fromProjects from '../projects/projects.selectors'
import fromOrganizations, {
  PERSONAL_ORGANIZATION_ID,
} from '../organizations/organizations.selectors'
import { SHARE_TYPES } from '../../components/molecules/ProjectShareModal/ProjectShare.constants'
import { cleanUserIdentityId } from '../../util/utils'
import useUserInfo from './useUserInfo'

export const canUserAddSessionsToProject = ({
  project,
  organization,
  userIdentityId,
  userEmail,
}) => {
  if (organization.pk === PERSONAL_ORGANIZATION_ID) {
    return true
  }
  const isProjectSharedWithTheWholeOrganization =
    project?.shareType === SHARE_TYPES.anyone

  const organizationMembers = (organization?.members || []).concat(
    organization?.admins || []
  )
  const isCurrentUserOrganizationMember = organizationMembers.includes(
    userIdentityId
  )

  if (
    isProjectSharedWithTheWholeOrganization &&
    isCurrentUserOrganizationMember
  ) {
    return true
  }

  const cleanedUserIdentityId = cleanUserIdentityId(userIdentityId)
  const isProjectOwner =
    project?.createdBy && project?.createdBy === cleanedUserIdentityId

  const isProjectAdministrator = (project?.admin || []).includes(userEmail)

  const isProjectEditor = (project?.edit || []).includes(userEmail)

  if (
    !isProjectSharedWithTheWholeOrganization &&
    (isProjectOwner || isProjectAdministrator || isProjectEditor)
  ) {
    return true
  }

  return false
}

const useCanAddSessions = () => {
  const { userIdentityId, userEmail } = useUserInfo()
  const project = useSelector(fromProjects.getCurrentProject)
  const organization = useSelector(fromOrganizations.getCurrentOrganization)

  return canUserAddSessionsToProject({
    project,
    organization,
    userIdentityId,
    userEmail,
  })
}

export default useCanAddSessions
