const NAMESPACE = 'DESKTOP/LOCAL_SESSIONS'

export const UPSERT_LOCAL_SESSION = `${NAMESPACE}/UPSERT_SESSION`
export const upsertLocalSession = session => ({
  type: UPSERT_LOCAL_SESSION,
  payload: { session },
})

export const DELETE_LOCAL_SESSION = `${NAMESPACE}/DELETE_SESSION`
export const deleteLocalSessionById = sessionId => ({
  type: DELETE_LOCAL_SESSION,
  payload: { sessionId },
})
