import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import Tippy from '@tippyjs/react'
import Flex from '../Flex'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'

const SIZE = 16

const Wrapper = styled(Flex)`
  cursor: pointer;
`

const UnresolvedIcon = styled(CheckCircleOutlineIcon).attrs({
  size: SIZE,
})`
  && {
    fill: ${prop('theme.colors.grey400')};
  }
  &&:hover {
    fill: ${prop('theme.colors.grey600')};
  }
`

const ResolvedIcon = styled(CheckCircleIcon).attrs({
  size: SIZE,
})`
  && {
    fill: ${prop('theme.colors.green')};
  }
  &&:hover {
    fill: ${prop('theme.colors.green600')};
  }
`

const ResolveIcon = ({ isResolved, onClick, ...props }) => {
  const [tooltipContent, setTooltipContent] = useState(
    isResolved ? 'Unresolve' : 'Resolve'
  )

  const onIconClick = () => {
    const newState = !isResolved
    setTooltipContent(newState ? 'Unresolve' : 'Resolve')
    onClick(newState)
  }

  return (
    <>
      <Tippy
        content={tooltipContent}
        placement="top"
        arrow={false}
        touch={false}
      >
        <Wrapper {...props} size="small" isBasic>
          <Flex>
            {!isResolved && <UnresolvedIcon onClick={onIconClick} />}
            {isResolved && <ResolvedIcon onClick={onIconClick} />}
          </Flex>
        </Wrapper>
      </Tippy>
    </>
  )
}

ResolveIcon.propTypes = {
  isResolved: PropTypes.bool,
  onClick: PropTypes.func,
}

ResolveIcon.defaultProps = {
  isResolved: false,
  onClick: () => {},
}

export default ResolveIcon
