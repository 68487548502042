import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import VideoOutlineIcon from 'mdi-react/VideoOutlineIcon'
import RefreshIcon from 'mdi-react/RefreshIcon'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import { Progress } from '@zendeskgarden/react-loaders'
import theme from '../../theme/old'
import Flex from '../../atoms/Flex/Flex'
import IconButton from '../IconButton/IconButton'

const { colors } = theme

const FlexItem = styled(Flex)`
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.grey400};
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0 8px;
`

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  max-width: 59%;

  > div {
    color: ${props => (props.uploaded || props.hasError) && colors.grey800};
  }

  > svg {
    vertical-align: bottom;
    margin-right: 5px;
    min-width: 24px;

    color: ${props => props.uploaded && colors.grey800};
    color: ${props => props.hasError && colors.warning600};
  }
`

const StyledProgress = styled(Progress)`
  // flex-grow: 1;
  width: 100px;
  min-width: 100px;
  border-radius: 6px;
  height: 6px;
  margin: 0 8px;
  color: ${props => props.poorConnection && colors.yellow600};
`

const ProgressSection = styled(Flex)`
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  #size {
    flex: none;
    order: 1;
    flex-grow: 0;
    min-width: 88px;
    color: ${colors.grey600};
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    margin-right: 8px;

    > span {
      color: ${colors.green800};
    }
  }

  #icons {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    #retry {
      opacity: 0;
    }

    svg {
      fill: ${colors.grey800} !important;
    }
  }
  button {
    height: 40px;
    width: 40px;
    svg {
      width: 16px;
    }
  }

  #check {
    svg {
      fill: ${colors.green600} !important;
    }
    :hover,
    :focus {
      background-color: initial;
      cursor: initial;
    }
  }
`

const ErrorWrapper = styled.div`
  color: ${colors.warning600};
  margin: 0 8px;
`

const ErrorMessage = () => <ErrorWrapper>Error Uploading</ErrorWrapper>

function truncateButShowFileExtension(text) {
  let res = 'Unknown'
  if (text) {
    const parts = text.split('.')
    const ext = parts.pop()
    const title = parts.join('')
    res = `${title}.${ext}`

    if (title.length > 30) {
      res = `${title.substring(0, 27)}...${title.substring(
        title.length - 2
      )}.${ext}`
    }
  }
  return res
}

const UploadingItem = ({ ...props }) => (
  // title override just to avoid tooltip to show up. Suggestion: refactor title with name for file name
  <FlexItem {...props} title="">
    <StyledTitle>
      <VideoOutlineIcon size={24} />
      <div title={props.title}>{truncateButShowFileExtension(props.title)}</div>
    </StyledTitle>
    <ProgressSection>
      {props.hasError && [
        <ErrorMessage />,
        <div id="icons">
          <IconButton
            id="retry"
            icon={RefreshIcon}
            onClick={props.onRetry}
            isBasic
            aria-label="retry"
          />
          <IconButton
            id="cancel"
            icon={CloseCircleOutlineIcon}
            onClick={props.onCancel}
            isBasic
            aria-label="cancel"
          />
        </div>,
      ]}
      {!props.hasError &&
        (!props.uploaded ? (
          <>
            <StyledProgress
              poorConnection={props.poorConnection}
              value={props.percentageProgress}
            />
            <div id="size">
              {props.downloaded.toFixed(2)}/{props.total.toFixed(2)} Mb
            </div>
          </>
        ) : (
          <IconButton id="check" icon={CheckIcon} isBasic aria-label="check" />
        ))}
    </ProgressSection>
  </FlexItem>
)

UploadingItem.propTypes = {
  title: PropTypes.string.isRequired,
  percentageProgress: PropTypes.number.isRequired,
  downloaded: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  isLast: PropTypes.bool,
  poorConnection: PropTypes.bool,
  hasError: PropTypes.bool,
  uploaded: PropTypes.bool,
  onCancel: PropTypes.func,
  onRetry: PropTypes.func,
}

UploadingItem.defaultProps = {
  isLast: false,
  poorConnection: false,
  hasError: false,
  uploaded: false,
  onCancel: () => {},
  onRetry: () => {},
}

export default UploadingItem
