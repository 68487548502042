import { useEffect, useState } from 'react'
import MessageTypes from '../../../browser-extension/messageTypes'
import browserExtension from '../../../lib/browserExtension'
import LOCAL_STORAGE_KEYS from '../../../browser-extension/storageKeys'
import useLocalStorage from '../../hooks/useLocalStorage'

const useRecordingPanel = () => {
  const [isMicPresent, setMicPresent] = useState(true)
  const [areMicGrantsProvided, setMicGrantsProvided] = useState(false)

  const getIsMicPresent = () => {
    browserExtension
      .sendMessageToBackground({
        type: MessageTypes.REQUEST_DEVICES,
      })
      .then(response => {
        const devices = response?.devices
        if (devices && devices.length) {
          const areAudioDevicePresent = !!devices.find(
            device => device.kind === 'audioinput'
          )
          setMicPresent(areAudioDevicePresent)
        }
      })
      .catch(error => {
        console.log('get devices error', error)
      })
  }

  const onRequestMicrophonePermissions = () => {
    browserExtension
      .sendMessageToBackground({
        type: MessageTypes.IS_MIC_GRANTED,
      })
      .then(response => {
        setMicGrantsProvided(response.isMicGranted)
        if (response.isMicGranted) {
          getIsMicPresent()
        }
      })
      .catch(error => {
        console.log('onRequestMicrophonePermissions error', error)
      })
  }

  useEffect(() => {
    onRequestMicrophonePermissions()
  }, [])

  const onStartRecording = isMicEnabled => {
    browserExtension.sendMessageToBackground({
      type: MessageTypes.START_RECORDING,
      payload: {
        audio: isMicEnabled,
      },
    })
  }

  const [isMicEnabledStorage, setMicEnabledStorage] = useLocalStorage(
    LOCAL_STORAGE_KEYS.isMicEnabled
  )
  const [isMicEnabled, setMicEnabled] = useState(isMicEnabledStorage)
  const onUpdateMicEnabled = _isMicEnabled => setMicEnabled(_isMicEnabled)

  useEffect(() => {
    setMicEnabledStorage(isMicEnabled)
  }, [isMicEnabled])

  const toggleMicAudio = () => onUpdateMicEnabled(!isMicEnabled)

  return {
    areMicGrantsProvided,
    isMicPresent,
    isMicEnabled,
    onRequestMicrophonePermissions,
    toggleMicAudio,
    onStartRecording,
  }
}

export default useRecordingPanel
