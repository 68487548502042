import {
  DESKTOP_UPDATE_DEVICES,
  DESKTOP_SET_CURRENT_DEVICE,
  DESKTOP_START_RECORDING_REQUEST_SUCCESS,
  DESKTOP_START_RECORDING_REQUEST_FAILURE,
  DESKTOP_STOP_RECORDING,
  DESKTOP_PULL_RECORDING_REQUEST_SUCCESS,
} from './actions'
import { buildReducerForActions } from '../../util/reduxTools'

export const STATE_KEY = 'devices'

const INITIAL_STATE = {
  list: [],
}

const reducer = {
  [DESKTOP_UPDATE_DEVICES]: (state, { payload: { devices } }) => ({
    ...state,
    list: devices,
  }),
  [DESKTOP_SET_CURRENT_DEVICE]: (state, { payload: { currentDevice } }) => ({
    ...state,
    currentDevice,
  }),
  [DESKTOP_START_RECORDING_REQUEST_SUCCESS]: state => ({
    ...state,
    isRecording: true,
  }),
  [DESKTOP_START_RECORDING_REQUEST_FAILURE]: state => ({
    ...state,
    isRecording: false,
  }),
  [DESKTOP_STOP_RECORDING]: state => ({
    ...state,
    isRecording: false,
  }),
  [DESKTOP_PULL_RECORDING_REQUEST_SUCCESS]: (
    state,
    { payload: { videoInfo } }
  ) => ({
    ...state,
    videoInfo,
  }),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
