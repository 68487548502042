const NAMESPACE = 'SMART_ASSISTANT'

export const GET_SUGGESTIONS = `${NAMESPACE}/GET/SUGGESTIONS`
export const GET_SUGGESTIONS_REQUEST = `${GET_SUGGESTIONS}/REQUEST`
export const GET_SUGGESTIONS_REQUEST_SUCCESS = `${GET_SUGGESTIONS_REQUEST}_SUCCESS`
export const GET_SUGGESTIONS_REQUEST_FAILURE = `${GET_SUGGESTIONS_REQUEST}_FAILURE`

export const getSuggestionsRequest = () => ({
  type: GET_SUGGESTIONS_REQUEST,
  payload: {},
  meta: { thunk: GET_SUGGESTIONS_REQUEST },
})

export const getSuggestionsRequestSuccess = (suggestions, thunk) => ({
  type: GET_SUGGESTIONS_REQUEST_SUCCESS,
  payload: { suggestions },
  meta: { thunk },
})

export const getSuggestionsRequestFailure = (e, thunk) => ({
  type: GET_SUGGESTIONS_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})
