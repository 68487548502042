import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { createOrganization as createOrganizationGql } from '../../../graphql/mutations'
import CloseIcon from '../../atoms/Icons/navigation/close.svg'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 24px;
  color: ${prop('theme.style.colors.grey500')};
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const CreateOrganizationModalMobile = ({ isVisible, onClose }) => {
  const inputRef = useRef(null)
  const [title, setTitle] = useState('')

  const onTitleInput = e => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 0)
    }
  }, [isVisible, inputRef?.current])

  const header = (
    <StyledMobileTopBar rightIcon={CloseIcon} onRightButtonClick={onClose}>
      Create Team Workspace
    </StyledMobileTopBar>
  )

  const body = (
    <Body>
      <Field>
        <Input
          ref={inputRef}
          placeholder="Acme Corporation"
          autoFocus
          focused
          onInput={onTitleInput}
        />
      </Field>
    </Body>
  )

  const footer = (
    <Footer>
      <Mutation mutation={gql(createOrganizationGql)}>
        {(createOrganization, { loading, called, data, error }) => {
          if (called && (data || error)) {
            onClose(error, data)
          }
          return (
            <Button
              isPrimary
              disabled={loading}
              onClick={() => {
                if (!title) {
                  return
                }
                createOrganization({
                  variables: {
                    input: {
                      title,
                    },
                  },
                })
              }}
            >
              Create
            </Button>
          )
        }}
      </Mutation>
    </Footer>
  )

  return (
    <Flex>
      {isVisible && (
        <MobileModal>
          <MobileTemplate header={header} body={body} footer={footer} />
        </MobileModal>
      )}
    </Flex>
  )
}

CreateOrganizationModalMobile.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

CreateOrganizationModalMobile.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default CreateOrganizationModalMobile
