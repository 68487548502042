import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import { Field, Textarea } from '@zendeskgarden/react-forms'
import { MD } from '@zendeskgarden/react-typography'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import {
  convertHtmlToDelta,
  convertDeltaToHtml,
} from '../../atoms/RichEditor/quill.utils'
import { MAIN_VIEWS, HEADER_HEIGHT } from '../MainAppPage/MainAppPage.constants'
import Flex from '../../atoms/Flex'
import IconButton from '../../molecules/IconButton'
import useReduxSagaThunkButton from '../../../store/hooks/useReduxSagaThunkButton'
import {
  CREATE_REMOTE_TEST_REQUEST,
  UPDATE_REMOTE_TEST_REQUEST,
} from '../../../store/remoteTests/remoteTests.actions'
import { getRemoteTestUrl } from '../../../lib/urls'
import RichEditor from '../../atoms/RichEditor/RichEditor'
import useUserInfo from '../../../store/hooks/useUserInfo'
import useOrganization from '../../../store/hooks/useOrganization'

const Wrapper = styled(Flex)`
  width: 100vw;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  background-color: ${prop('theme.colors.grey100')};
  align-items: center;
  overflow: auto;
`

const MainColumn = styled(Flex)`
  max-width: 800px;
  min-width: 640px;
  margin-top: 48px;
  margin-bottom: 48px;
  flex-direction: column;
  color: ${prop('theme.colors.grey400')};
`

const WorkspaceRow = styled(Flex)`
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  justify-content: flex-start;
`
const BackButton = styled(IconButton)`
  margin-right: 16px;
`

const StyledTitle = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: flex-start;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`

const StyledInput = styled(Textarea)`
  display: flex;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  border: none;
  padding: 16px;
  margin-left: -16px;
  margin-top: 24px;
  background-color: transparent;
`

const SectionTitle = styled(MD)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${prop('theme.colors.primary')};
  margin-bottom: 8px;
`

const SectionDescription = styled(MD)`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  color: ${prop('theme.colors.grey400')};
`

const focusedEditorCss = css`
  border-color: ${prop('theme.colors.secondary')};
  box-shadow: 0 0 0 3px hsl(266, 83%, 94%);
`
const StyledRichEditor = styled(RichEditor)`
  background: white;
  border: 1px solid ${prop('theme.colors.grey200')};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 40px;
  padding: 24px;
  transition: all 0.3s;

  ${({ isFocused }) => {
    return isFocused ? focusedEditorCss : ''
  }}
`

const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
`

export const REQUEST_RECORDING_SECTIONS = [
  {
    id: 'intro',
    title: 'Introduction message',
    description: 'This is what someone first sees when they receive your test',
  },
  {
    id: 'tasks',
    title: 'Tasks',
    description:
      'Add optional instructions in here if you want them to do something specific while recording',
  },
  {
    id: 'thank-you',
    title: 'Thank you message',
    description: "Participants see this once they've finished recording",
  },
]

const RequestRecordingCreatePage = ({
  remoteTest,
  projectTitle,
  remoteTestTitle,
  setCurrentView,
  setModalTitle,
  setModalUrl,
  setModalVisible,
  onCreate,
  onUpdate,
  ...props
}) => {
  const isUpdate = !!remoteTest

  const { userName } = useUserInfo()
  const { currentOrganization, currentOrganizationId } = useOrganization()

  const initialIntroText = remoteTest
    ? remoteTest?.sections?.find(s => s.id === 'intro')?.text
    : `<b>${userName}</b> ${
        currentOrganizationId === 'personal'
          ? ''
          : `from <b>${currentOrganization.title}</b> `
      }is requesting a screen recording from you.<br>Click the record button below to start recording your screen and voice.`
  const initialTasksText = remoteTest
    ? remoteTest?.sections?.find(s => s.id === 'tasks')?.text
    : `Your screen is now being recorded.<br>......<br>When you're done, come back here to submit your video.`
  const initialThankYouText = remoteTest
    ? remoteTest?.sections?.find(s => s.id === 'thank-you')?.text
    : `🎉 Thank you! Your recording has now been sent to <b>${userName}</b>`

  const [titleText, setTitleText] = useState(remoteTestTitle)

  const {
    buttonNode: createButtonNode,
    isButtonDisabled: isCreateButtonDisabled,
  } = useReduxSagaThunkButton(CREATE_REMOTE_TEST_REQUEST, {
    initialNode: 'Create recruitment link',
    pendingNode: 'Generating...',
    fulfilledNode: 'Ready!',
    rejectedNode: 'An error occurred',
  })

  const {
    buttonNode: updateButtonNode,
    isButtonDisabled: isUpdateButtonDisabled,
  } = useReduxSagaThunkButton(UPDATE_REMOTE_TEST_REQUEST, {
    initialNode: 'Update',
    pendingNode: 'Saving...',
    fulfilledNode: 'Updated!',
    rejectedNode: 'An error occurred',
  })

  const [focusedEditor, setFocusedEditor] = useState(null)
  const [introQuill, setIntroQuill] = useState(null)
  const [tasksQuill, setTasksQuill] = useState(null)
  const [thankYouQuill, setThankYouQuill] = useState(null)

  const getSectionsText = useCallback(() => {
    const introText = convertDeltaToHtml(introQuill)(introQuill.getContents())
    const tasksText = convertDeltaToHtml(tasksQuill)(tasksQuill.getContents())
    const thankYouText = convertDeltaToHtml(thankYouQuill)(
      thankYouQuill.getContents()
    )
    return {
      introText,
      tasksText,
      thankYouText,
    }
  }, [introQuill, tasksQuill, thankYouQuill])

  const onCreateClick = useCallback(() => {
    const { introText, tasksText, thankYouText } = getSectionsText()
    onCreate({ titleText, introText, tasksText, thankYouText }).then(data => {
      setModalTitle(titleText)
      setModalUrl(getRemoteTestUrl(data?.data?.createRemoteTest?.pk))
      setModalVisible(true)
      setCurrentView({ name: MAIN_VIEWS.entry })
    })
  }, [onCreate, titleText, getSectionsText])

  const onUpdateClick = useCallback(() => {
    const { introText, tasksText, thankYouText } = getSectionsText()
    onUpdate({
      remoteTestId: remoteTest.pk,
      titleText,
      introText,
      tasksText,
      thankYouText,
    })
  }, [onUpdate, remoteTest, titleText, getSectionsText])

  return (
    <Wrapper {...props}>
      <MainColumn>
        <WorkspaceRow>
          <BackButton
            icon={ArrowLeftIcon}
            size="small"
            title="Back"
            isBasic
            onClick={() => setCurrentView({ name: MAIN_VIEWS.entry })}
          />
          {projectTitle}
        </WorkspaceRow>
        <StyledTitle>
          <StyledInput
            defaultValue={remoteTestTitle}
            onInput={e => setTitleText(e.target.value)}
          />
        </StyledTitle>
        {REQUEST_RECORDING_SECTIONS.map(section => {
          let defaultValue = ''
          if (section.id === 'intro') {
            defaultValue = initialIntroText
          }
          if (section.id === 'tasks') {
            defaultValue = initialTasksText
          }
          if (section.id === 'thank-you') {
            defaultValue = initialThankYouText
          }

          return (
            <div key={section.id}>
              <SectionTitle>{section.title}</SectionTitle>
              <SectionDescription>{section.description}</SectionDescription>
              <StyledRichEditor
                isFocused={focusedEditor === section.id}
                onMount={quill => {
                  quill.setContents(
                    convertHtmlToDelta(quill)(defaultValue),
                    'silent'
                  )
                  if (section.id === 'intro') {
                    setIntroQuill(quill)
                  }
                  if (section.id === 'tasks') {
                    setTasksQuill(quill)
                  }
                  if (section.id === 'thank-you') {
                    setThankYouQuill(quill)
                  }

                  const { tooltip } = quill.theme
                  tooltip.textbox.setAttribute(
                    'data-link',
                    'https://example.com'
                  )
                }}
                editorConf={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link'],
                  ],
                  autoLink: false,
                  mention: false,
                  formats: null,
                }}
                onFocus={() => setFocusedEditor(section.id)}
                onBlur={() => {
                  setFocusedEditor(null)
                }}
              />
            </div>
          )
        })}
        <StyledIconButton
          icon={isUpdate ? CheckIcon : ArrowRightIcon}
          isRightIcon={!isUpdate}
          isPrimary
          size="medium"
          isDisabled={
            isUpdate ? isUpdateButtonDisabled : isCreateButtonDisabled
          }
          onClick={args =>
            isUpdate ? onUpdateClick(args) : onCreateClick(args)
          }
        >
          {isUpdate ? updateButtonNode : createButtonNode}
        </StyledIconButton>
      </MainColumn>
    </Wrapper>
  )
}

RequestRecordingCreatePage.propTypes = {
  remoteTest: PropTypes.shape({
    pk: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }),
  setCurrentView: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  projectTitle: PropTypes.string,
  remoteTestTitle: PropTypes.string,
  setModalTitle: PropTypes.func,
  setModalUrl: PropTypes.func,
  setModalVisible: PropTypes.func,
}

RequestRecordingCreatePage.defaultProps = {
  remoteTest: null,
  projectTitle: '',
  remoteTestTitle: '',
  setModalTitle: () => {},
  setModalUrl: () => {},
  setModalVisible: () => {},
}

export default RequestRecordingCreatePage
