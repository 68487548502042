import get from 'lodash/get'
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import gql from 'graphql-tag'
import {
  slackIntegration as slackIntegrationGql,
  updateSlackIntegration as updateSlackIntegrationGql,
} from '../../../graphql/queries'
import {
  postSlackMessage as postSlackMessageGql,
  deleteSlack as removeSlackGql,
} from '../../../graphql/mutations'
import fromIntegrationsSlack from './integrations.slack.selectors'
import {
  GET_SLACK_REQUEST,
  getSlackRequestSuccess,
  getSlackRequestFailure,
  UPDATE_SLACK_REQUEST,
  updateSlackRequestSuccess,
  updateSlackRequestFailure,
  POST_SLACK_MESSAGE_REQUEST,
  postSlackMessageRequestSuccess,
  postSlackMessageRequestFailure,
  REMOVE_SLACK_REQUEST,
  removeSlackRequestSuccess,
  removeSlackRequestFailure,
} from './integrations.slack.actions'
import { LOGIN_REQUEST_SUCCESS } from '../../actions'
import { activityBasedAction } from '../../utils.sagas'
import analytics from '../../../lib/analytics'

export function* fetchSlack({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(slackIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.slackIntegration')
    yield put(getSlackRequestSuccess(data))
  } catch (e) {
    yield put(getSlackRequestFailure(e))
    console.error(e)
  }
}

function* updateSlack({ apolloClient }) {
  try {
    const result = yield call(apolloClient.query, {
      query: gql(updateSlackIntegrationGql),
      fetchPolicy: 'no-cache',
    })

    const data = get(result, 'data.updateSlackIntegration', false)
    yield put(updateSlackRequestSuccess(data))
  } catch (e) {
    yield put(updateSlackRequestFailure(e))
    console.error(e)
  }
}

function* startUpdateInterval({ apolloClient }) {
  while (true) {
    try {
      const hasSlack = yield select(fromIntegrationsSlack.getHasSlack) || false
      if (hasSlack) {
        yield call(updateSlack, { apolloClient })
      }
    } catch (e) {
      console.error(e)
    }
    yield delay(3000 * 60)
  }
}

function* startSlackFork({ apolloClient }) {
  yield call(fetchSlack, { apolloClient })

  const hasSlack = yield select(fromIntegrationsSlack.getHasSlack) || false
  if (hasSlack) {
    yield fork(activityBasedAction, fetchSlack, 26000, { apolloClient })
    yield fork(startUpdateInterval, { apolloClient })
  }
}

function* postSlackMessage(
  { apolloClient },
  {
    payload: { sessionId, commentId, replyId, channelId, userId },
    meta: { thunk },
  }
) {
  try {
    const result = yield call(apolloClient.mutate, {
      mutation: gql(postSlackMessageGql),
      variables: {
        sessionId,
        commentId,
        replyId,
        slackChannelId: channelId,
        slackUserId: userId,
      },
    })

    const slackIssue = get(result, 'data.createSlackIssue')
    yield put(postSlackMessageRequestSuccess(slackIssue, thunk))

    try {
      analytics.trackPostSlackMessage()
    } catch (error) {
      console.error(error)
    }
  } catch (e) {
    yield put(postSlackMessageRequestFailure(e, thunk))
    console.error(e)
  }
}

function* removeSlack({ apolloClient }, { meta: { thunk } }) {
  try {
    yield call(apolloClient.mutate, {
      mutation: gql(removeSlackGql),
    })

    yield put(removeSlackRequestSuccess({}, thunk))
    try {
      analytics.trackDisconnectSlack()
    } catch (err) {
      console.error(err)
    }
  } catch (e) {
    yield put(removeSlackRequestFailure(e, thunk))
    console.error(e)
  }
}

export default function* slackSagas({ apolloClient }) {
  yield all([
    yield takeEvery(LOGIN_REQUEST_SUCCESS, startSlackFork, { apolloClient }),
    yield takeLatest(GET_SLACK_REQUEST, fetchSlack, { apolloClient }),
    yield takeLatest(UPDATE_SLACK_REQUEST, updateSlack, { apolloClient }),
    yield takeLatest(POST_SLACK_MESSAGE_REQUEST, postSlackMessage, {
      apolloClient,
    }),
    yield takeLatest(REMOVE_SLACK_REQUEST, removeSlack, { apolloClient }),
  ])
}
