import { useEffect, useState } from 'react'
import queryString from 'query-string'

const useUrlParser = () => {
  const [initialVideoTime, setInitialVideoTime] = useState(0)
  const [initialMarkerPosition, setInitialMarkerPosition] = useState(null)
  useEffect(() => {
    const parsedHash = queryString.parse(window.location.search)

    if (parsedHash?.t) {
      const videoTimeFromUrl = parsedHash.t
      setInitialVideoTime(parseFloat(videoTimeFromUrl, 10))
    }

    if (parsedHash?.m) {
      const markerPosition = parsedHash.m
      const [x = 0, y = 0] = markerPosition.split(',')
      setInitialMarkerPosition({ x, y })
    }
  }, [])

  return {
    initialVideoTime,
    initialMarkerPosition,
  }
}

export default useUrlParser
