import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import {
  Dropdown,
  Menu,
  Item,
  Field,
  Select,
  Separator,
} from '@zendeskgarden/react-dropdowns'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import FaceIcon from 'mdi-react/FaceIcon'
import CogIcon from 'mdi-react/CogIcon'
import CreateOrganizationModal from '../../molecules/CreateOrganizationModal'
import CreateOrganizationModalMobile from '../../molecules/CreateOrganizationModalMobile'
import Flex from '../../atoms/Flex'
import IconButton from '../../molecules/IconButton'
import { isMobile } from '../../../lib/platform'
import WorkspaceLogo from '../../atoms/WorkspaceLogo'
import { PERSONAL_ORGANIZATION_ID } from '../../../store/organizations/organizations.selectors'

const Wrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
`

const StyledSelect = styled(Select)`
  padding: 4px 8px;
`

const StyledPlusCircleOutlineIcon = styled(PlusCircleOutlineIcon)`
  margin-right: 8px;
  && svg {
    fill: ${prop('theme.colors.grey400')};
  }
`

const StyledItem = styled(Item)`
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

const Selection = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const StyledWorkspaceLogo = styled(WorkspaceLogo)`
  display: flex;
  margin-right: 8px;
`

const StyledDropdownItem = styled(Item)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PersonalWorkspaceLogo = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${prop('theme.colors.green200')};
  margin-right: 8px;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${prop('theme.colors.green700')};
  }
`

const ManageWorkspaceIconButton = styled(IconButton)`
  && svg {
    fill: ${prop('theme.colors.grey300')} !important;
  }
`

const OrganizationsSelect = ({
  currentOrganization,
  isCreateOrganizationModalVisible,
  isOrganizationButtonSharingVisible,
  onChangeOrganizationInSession,
  onSetCurrentOrganization,
  organizations,
  setCreateOrganizationModalVisible,
  setOrganizationModalVisible,
  showNewOrganizationButton,
  showOrganizationButtonSharingVisible,
  onSettingsClick,
  ...props
}) => (
  <Wrapper data-test="organizations-select" {...props}>
    <Dropdown
      selectedItem={currentOrganization?.pk}
      onSelect={value => {
        if (value === 'create-new') {
          setCreateOrganizationModalVisible(true)
          return
        }
        onSetCurrentOrganization(value)
        onChangeOrganizationInSession(value)
      }}
    >
      <Field style={{ width: '256px' }}>
        <StyledSelect>
          <Selection>
            {currentOrganization?.pk !== PERSONAL_ORGANIZATION_ID && (
              <StyledWorkspaceLogo
                imgUrl={currentOrganization?.pictureUrl}
                name={currentOrganization?.title}
                backgroundColor={currentOrganization?.backgroundColor}
                size="extrasmall"
              />
            )}
            {currentOrganization?.pk === PERSONAL_ORGANIZATION_ID && (
              <PersonalWorkspaceLogo>
                <FaceIcon size={20} />
              </PersonalWorkspaceLogo>
            )}
            <Flex>{currentOrganization?.title}</Flex>
          </Selection>
        </StyledSelect>
      </Field>
      <Menu>
        {organizations.map(
          ({ pk, title, pictureUrl, backgroundColor }, idx) => (
            <StyledDropdownItem
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${pk}${idx}`}
              value={pk}
              data-test={`workspace-item-${title}`}
            >
              {pk === PERSONAL_ORGANIZATION_ID && (
                <PersonalWorkspaceLogo>
                  <FaceIcon size={20} />
                </PersonalWorkspaceLogo>
              )}
              {pk !== PERSONAL_ORGANIZATION_ID && (
                <StyledWorkspaceLogo
                  imgUrl={pictureUrl}
                  name={title}
                  backgroundColor={backgroundColor}
                  size="extrasmall"
                />
              )}
              {title}
            </StyledDropdownItem>
          )
        )}
        {showNewOrganizationButton && (
          <>
            <Separator />
            <StyledItem value="create-new" data-test="create-new-workspace">
              <StyledPlusCircleOutlineIcon size={20} /> New Workspace
            </StyledItem>
          </>
        )}
      </Menu>
    </Dropdown>
    {isOrganizationButtonSharingVisible && (
      <ManageWorkspaceIconButton
        isBasic
        isPrimary={false}
        style={{
          width: 'auto',
          marginLeft: '16px',
        }}
        size="medium"
        onClick={() => onSettingsClick()}
        icon={CogIcon}
        data-test="show-workspace-members-button"
      >
        Manage workspace
      </ManageWorkspaceIconButton>
    )}
    {!isMobile() && isCreateOrganizationModalVisible && (
      <CreateOrganizationModal
        isVisible={isCreateOrganizationModalVisible}
        onClose={() => {
          setCreateOrganizationModalVisible(false)
        }}
      />
    )}
    {isMobile() && isCreateOrganizationModalVisible && (
      <CreateOrganizationModalMobile
        isVisible={isCreateOrganizationModalVisible}
        onClose={() => {
          setCreateOrganizationModalVisible(false)
        }}
      />
    )}
  </Wrapper>
)

const OrganizationShape = PropTypes.shape({
  pk: PropTypes.string,
  pictureUrl: PropTypes.string,
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
})

OrganizationsSelect.propTypes = {
  currentOrganization: OrganizationShape,
  isCreateOrganizationModalVisible: PropTypes.bool,
  isOrganizationButtonSharingVisible: PropTypes.bool,
  onChangeOrganizationInSession: PropTypes.func,
  onSetCurrentOrganization: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  setCreateOrganizationModalVisible: PropTypes.func,
  setOrganizationModalVisible: PropTypes.func,
  onSettingsClick: PropTypes.func,
  showNewOrganizationButton: PropTypes.bool,
  showOrganizationButtonSharingVisible: PropTypes.bool,
}

OrganizationsSelect.defaultProps = {
  currentOrganization: null,
  isCreateOrganizationModalVisible: false,
  isOrganizationButtonSharingVisible: false,
  onChangeOrganizationInSession: () => {},
  onSetCurrentOrganization: () => {},
  organizations: [],
  setCreateOrganizationModalVisible: () => {},
  setOrganizationModalVisible: () => {},
  onSettingsClick: () => {},
  showNewOrganizationButton: true,
  showOrganizationButtonSharingVisible: true,
}

export default OrganizationsSelect
