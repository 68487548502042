import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import pick from 'lodash/pick'
import { MENTION_CHAR } from '../MentionSuggestion/formatMentionSuggestions'

const useRichEditor = ({
  initialValue,
  mentions = [],
  onAddedMention,
  onHideSuggestions,
  onRemovedMention,
  onShowSuggestions,
  suggestions,
}) => {
  const [currentSuggestions, setCurrentSuggestions] = useState(suggestions)
  const [richEditor, setRichEditor] = useState(null)

  const [mentionText, setMentionText] = useState(null)
  const [mentionCharPosition, setMentionCharPosition] = useState(null)
  const [mentionChar, setMentionChar] = useState(null)

  const filteredSuggestions = text =>
    suggestions.filter(suggestion => {
      const { name, email } = suggestion
      const lowerText = text.toLowerCase()
      return (
        name.toLowerCase().indexOf(lowerText) !== -1 ||
        email.toLowerCase().indexOf(lowerText) !== -1
      )
    })

  const [triggerNode, setTriggerNode] = useState(null)
  const triggerRef = useMemo(() => ({ current: triggerNode }), [triggerNode])
  const [isSuggestionsVisible, setSuggestionsVisible] = useState(false)

  const onMentionSuggestionsTrigger = useCallback(
    ({
      textAfter,
      mentionCharPosition: _mentionCharPosition,
      mentionChar: _mentionChar,
      triggerNode: _triggerNode,
    }) => {
      setTriggerNode(_triggerNode)
      setMentionText(textAfter)
      setMentionCharPosition(_mentionCharPosition)
      setMentionChar(_mentionChar)

      const _suggestions = filteredSuggestions(textAfter)
      setCurrentSuggestions(_suggestions)
      if (_suggestions.length > 0) {
        onShowSuggestions()
        setSuggestionsVisible(true)
        return
      }
      setSuggestionsVisible(false)
      onHideSuggestions()
    },
    [onHideSuggestions]
  )

  const onMentionSelect = useCallback(
    mention => {
      if (mentionCharPosition === undefined) {
        return
      }
      setSuggestionsVisible(false)
      const _mention = pick(mention, ['pk', 'name', 'email'])
      onAddedMention(_mention)
      const mentionLabel = `${MENTION_CHAR}${mention.name}`
      const mentionLength = mentionLabel.length
      const finalPosition = mentionCharPosition + mentionLength
      const mentionTextLength = mentionText ? mentionText.length : 0
      richEditor.deleteText(
        mentionCharPosition,
        MENTION_CHAR.length + mentionTextLength,
        'silent'
      )
      richEditor.insertText(
        mentionCharPosition,
        `${mentionLabel} `,
        'user-mention',
        true,
        'silent'
      )
      richEditor.removeFormat(finalPosition, 1, 'silent')
      richEditor.formatText(finalPosition, 1, 'user-mention', false, 'silent')
      richEditor.setSelection(finalPosition + 1, 0, 'silent')
      onHideSuggestions()
    },
    [
      richEditor,
      mentionText,
      mentionCharPosition,
      mentionChar,
      onHideSuggestions,
      onAddedMention,
    ]
  )

  const [currentText, setCurrentText] = useState(initialValue)
  const previousText = useRef(currentText)
  useEffect(() => {
    if (currentText === previousText.current) {
      return
    }
    if (mentions && mentions.length > 0) {
      mentions.forEach(mention => {
        const { name } = mention
        if (currentText.indexOf(`${MENTION_CHAR}${name}`) === -1) {
          onRemovedMention(mention)
        }
      })
    }
    previousText.current = currentText
  }, [currentText, mentions.length])

  return {
    currentSuggestions,
    isSuggestionsVisible,
    onMentionSelect,
    onMentionSuggestionsTrigger,
    setCurrentSuggestions,
    setRichEditor,
    setSuggestionsVisible,
    triggerRef,
    setCurrentText,
    currentText,
  }
}

export default useRichEditor
