import React from 'react'
import PropTypes from 'prop-types'
import { Notification, Title } from '@zendeskgarden/react-notifications'
import styled from 'styled-components'
import theme from '../../theme/old'

const { colors } = theme

function getBoxColors(type) {
  switch (type) {
    case 'warning':
      return {
        border: colors.yellow800,
        svg: colors.yellow600,
        color: colors.yellow700,
      }
    case 'error':
      return {
        border: colors.warning800,
        svg: colors.warning600,
        color: colors.warning700,
      }
    default:
      return {
        border: colors.grey200,
        svg: colors.grey600,
        color: colors.grey800,
      }
  }
}

const StyledNotification = styled(Notification)`
  height: 20px;
  padding: 10px 40px;
  box-shadow: 0px 4px 8px rgba(7, 8, 34, 0.04), 0px 0px 2px rgba(7, 8, 34, 0.08),
    0px 0px 1px rgba(7, 8, 34, 0.04) !important;
  border-radius: 8px;
  flex: 1 0 auto;

  svg {
    height: 20px;
    width: 20px;
    left: 10px;
    margin-top: -1px;
  }

  ${props => {
    const { type } = props
    const { border, svg, color } = getBoxColors(type)
    return `
      &[type=${type}] {
        border: 1px solid ${border};
        svg {
          color: ${svg};
        }
        > * {
          color: ${color};
        }
      }
    `
  }}
`

const AlertBox = ({ ...props }) => (
  <StyledNotification type={props.type}>
    <Title>{props.title}</Title>
  </StyledNotification>
)

AlertBox.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'error']),
  title: PropTypes.string.isRequired,
}

AlertBox.defaultProps = {
  type: 'info',
}

export default AlertBox
