/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCanalxDev = `query GetCanalxDev($pk: String!, $sk: String!) {
  getCanalxDev(pk: $pk, sk: $sk) {
    pk
    sk
  }
}
`;
export const getCurrentUser = `query GetCurrentUser {
  getCurrentUser {
    pk
    name
    picture
    email
    organizations {
      organizationId
      role
    }
  }
}
`;
export const getAccountByEmail = `query GetAccountByEmail($email: String) {
  getAccountByEmail(email: $email) {
    isCognitoPresent
    isGooglePresent
  }
}
`;
export const listUsers = `query ListUsers($ids: [String]!) {
  listUsers(ids: $ids) {
    items {
      pk
      name
      picture
      email
      organizations {
        organizationId
        role
      }
    }
    nextToken
  }
}
`;
export const getProject = `query GetProject($projectId: String!) {
  getProject(projectId: $projectId) {
    pk
    title
    createdAt
    createdBy
    updatedAt
    updatedBy
    permission
    organizationId
    admin
    edit
    view
    sessions
    remoteTests
    deleted
    shareType
  }
}
`;
export const getSession = `query GetSession($sessionId: String!) {
  getSession(sessionId: $sessionId) {
    pk
    projectId
    title
    description
    createdAt
    createdBy
    updatedAt
    updatedBy
    permissions
    project {
      pk
      title
      createdAt
      createdBy
      updatedAt
      updatedBy
      permission
      organizationId
      admin
      edit
      view
      sessions
      remoteTests
      deleted
      shareType
    }
    owner {
      pk
      name
      picture
      email
      organizations {
        organizationId
        role
      }
    }
    videoUrl
    sharing {
      isPublic
      areCommentsAllowed
    }
    admin
    edit
    view
    spritesCreationProgress
    videoConversionProgress
    commentsCounter
    deleted
    remoteTestId
    remoteTest {
      pk
      projectId
      title
      sections {
        id
        order
        text
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      admin
      edit
      view
    }
    deviceMetaInfo {
      osName
      osVersion
      browserName
      browserVersion
      screenSize {
        width
        height
      }
      windowSize {
        width
        height
      }
      deviceType
      deviceVendor
      deviceEngineName
      deviceEngineVersion
      locale
      timeZone
    }
  }
}
`;
export const getRemoteTest = `query GetRemoteTest($remoteTestId: String!) {
  getRemoteTest(remoteTestId: $remoteTestId) {
    pk
    projectId
    title
    sections {
      id
      order
      text
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    admin
    edit
    view
  }
}
`;
export const getSessionIdByComment = `query GetSessionIdByComment($commentId: String!) {
  getSessionIdByComment(commentId: $commentId)
}
`;
export const listCanalxDevs = `query ListCanalxDevs(
  $filter: TableCanalxDevFilterInput
  $limit: Int
  $nextToken: String
) {
  listCanalxDevs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      pk
      sk
    }
    nextToken
  }
}
`;
export const listUserProjects = `query ListUserProjects($organizationId: String!) {
  listUserProjects(organizationId: $organizationId) {
    items {
      pk
      title
      createdAt
      createdBy
      updatedAt
      updatedBy
      permission
      organizationId
      admin
      edit
      view
      sessions
      remoteTests
      deleted
      shareType
    }
    nextToken
  }
}
`;
export const listUserOrganizations = `query ListUserOrganizations {
  listUserOrganizations {
    items {
      pk
      title
      createdAt
      createdBy
      updatedAt
      updatedBy
      admins
      members
      invitedUsers {
        userEmail
        userRole
      }
      pictureUrl
      backgroundColor
      sharedProjects
      emailDomains
    }
    nextToken
  }
}
`;
export const getOrganizationsSharedByEmailDomain = `query GetOrganizationsSharedByEmailDomain($emailDomain: String!) {
  getOrganizationsSharedByEmailDomain(emailDomain: $emailDomain) {
    organizations {
      pk
      title
      pictureUrl
      backgroundColor
    }
  }
}
`;
export const getOrganizationsSharedViaEmail = `query GetOrganizationsSharedViaEmail($email: String!) {
  getOrganizationsSharedViaEmail(email: $email) {
    organizations {
      pk
      title
      pictureUrl
      backgroundColor
    }
  }
}
`;
export const listUserSessions = `query ListUserSessions($organizationId: String!) {
  listUserSessions(organizationId: $organizationId) {
    items {
      pk
      projectId
      title
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
      permissions
      project {
        pk
        title
        createdAt
        createdBy
        updatedAt
        updatedBy
        permission
        organizationId
        admin
        edit
        view
        sessions
        remoteTests
        deleted
        shareType
      }
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      videoUrl
      sharing {
        isPublic
        areCommentsAllowed
      }
      admin
      edit
      view
      spritesCreationProgress
      videoConversionProgress
      commentsCounter
      deleted
      remoteTestId
      remoteTest {
        pk
        projectId
        title
        sections {
          id
          order
          text
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        admin
        edit
        view
      }
      deviceMetaInfo {
        osName
        osVersion
        browserName
        browserVersion
        screenSize {
          width
          height
        }
        windowSize {
          width
          height
        }
        deviceType
        deviceVendor
        deviceEngineName
        deviceEngineVersion
        locale
        timeZone
      }
    }
    nextToken
  }
}
`;
export const listSessionComments = `query ListSessionComments($pk: String) {
  listSessionComments(pk: $pk) {
    items {
      pk
      sessionId
      text
      createdAt
      createdBy
      updatedAt
      updatedBy
      videoPositionSecs
      videoPoint {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
      videoRectangle {
        topLeft {
          percentage {
            x
            y
          }
          absolute {
            x
            y
          }
        }
        bottomRight {
          percentage {
            x
            y
          }
          absolute {
            x
            y
          }
        }
      }
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      replies {
        id
        text
        createdAt
        createdBy
        updatedAt
        updatedBy
        owner {
          pk
          name
          picture
          email
          organizations {
            organizationId
            role
          }
        }
        mentions {
          pk
          name
          email
        }
      }
      mentions {
        pk
        name
        email
      }
      isResolved
      resolutions {
        createdAt
        createdBy
        status
      }
    }
    nextToken
  }
}
`;
export const getSessionComments = `query GetSessionComments($sessionId: String) {
  getSessionComments(sessionId: $sessionId) {
    items {
      pk
      sessionId
      text
      createdAt
      createdBy
      updatedAt
      updatedBy
      videoPositionSecs
      videoPoint {
        percentage {
          x
          y
        }
        absolute {
          x
          y
        }
      }
      videoRectangle {
        topLeft {
          percentage {
            x
            y
          }
          absolute {
            x
            y
          }
        }
        bottomRight {
          percentage {
            x
            y
          }
          absolute {
            x
            y
          }
        }
      }
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      replies {
        id
        text
        createdAt
        createdBy
        updatedAt
        updatedBy
        owner {
          pk
          name
          picture
          email
          organizations {
            organizationId
            role
          }
        }
        mentions {
          pk
          name
          email
        }
      }
      mentions {
        pk
        name
        email
      }
      isResolved
      resolutions {
        createdAt
        createdBy
        status
      }
    }
    nextToken
  }
}
`;
export const listSmartAssistantSuggestions = `query ListSmartAssistantSuggestions {
  listSmartAssistantSuggestions {
    text
  }
}
`;
export const listProjects = `query ListProjects($organizationId: String!) {
  listProjects(organizationId: $organizationId) {
    items {
      pk
      title
      createdAt
      createdBy
      updatedAt
      updatedBy
      permission
      organizationId
      admin
      edit
      view
      sessions
      remoteTests
      deleted
      shareType
    }
    nextToken
  }
}
`;
export const listSessions = `query ListSessions($projectId: String!) {
  listSessions(projectId: $projectId) {
    items {
      pk
      projectId
      title
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
      permissions
      project {
        pk
        title
        createdAt
        createdBy
        updatedAt
        updatedBy
        permission
        organizationId
        admin
        edit
        view
        sessions
        remoteTests
        deleted
        shareType
      }
      owner {
        pk
        name
        picture
        email
        organizations {
          organizationId
          role
        }
      }
      videoUrl
      sharing {
        isPublic
        areCommentsAllowed
      }
      admin
      edit
      view
      spritesCreationProgress
      videoConversionProgress
      commentsCounter
      deleted
      remoteTestId
      remoteTest {
        pk
        projectId
        title
        sections {
          id
          order
          text
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        admin
        edit
        view
      }
      deviceMetaInfo {
        osName
        osVersion
        browserName
        browserVersion
        screenSize {
          width
          height
        }
        windowSize {
          width
          height
        }
        deviceType
        deviceVendor
        deviceEngineName
        deviceEngineVersion
        locale
        timeZone
      }
    }
    nextToken
  }
}
`;
export const listRemoteTests = `query ListRemoteTests($projectId: String!) {
  listRemoteTests(projectId: $projectId) {
    items {
      pk
      projectId
      title
      sections {
        id
        order
        text
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      admin
      edit
      view
    }
    nextToken
  }
}
`;
export const jiraIntegration = `query JiraIntegration {
  jiraIntegration {
    hasJira
    projects {
      id
      key
      name
      avatarUrl
      isNextGen
    }
    issueTypes {
      id
      description
      iconUrl
      name
      projectId
    }
    requiredFields {
      isRequired
      projectId
      issueTypeId
      type
      name
      key
    }
  }
}
`;
export const updateJiraIntegration = `query UpdateJiraIntegration {
  updateJiraIntegration {
    hasJira
    projects {
      id
      key
      name
      avatarUrl
      isNextGen
    }
    issueTypes {
      id
      description
      iconUrl
      name
      projectId
    }
    requiredFields {
      isRequired
      projectId
      issueTypeId
      type
      name
      key
    }
  }
}
`;
export const trelloIntegration = `query TrelloIntegration {
  trelloIntegration {
    hasTrello
    boards {
      id
      name
      url
    }
    lists {
      id
      name
      idBoard
    }
  }
}
`;
export const updateTrelloIntegration = `query UpdateTrelloIntegration {
  updateTrelloIntegration {
    hasTrello
    boards {
      id
      name
      url
    }
    lists {
      id
      name
      idBoard
    }
  }
}
`;
export const slackIntegration = `query SlackIntegration {
  slackIntegration {
    hasSlack
    channels {
      id
      name
      isChannel
      isGroup
      isIm
      isMpim
      isArchived
      user
      isUserDeleted
      isPrivate
    }
    users {
      id
      name
      realName
      deleted
      color
      avatarUrl
      isBot
    }
  }
}
`;
export const updateSlackIntegration = `query UpdateSlackIntegration {
  updateSlackIntegration {
    hasSlack
    channels {
      id
      name
      isChannel
      isGroup
      isIm
      isMpim
      isArchived
      user
      isUserDeleted
      isPrivate
    }
    users {
      id
      name
      realName
      deleted
      color
      avatarUrl
      isBot
    }
  }
}
`;
export const readPageEvents = `query ReadPageEvents($sessionId: String!) {
  readPageEvents(sessionId: $sessionId) {
    items {
      pk
      sessionId
      createdAt
      createdBy
      events
    }
    nextToken
  }
}
`;
export const getNotifications = `query GetNotifications {
  getNotifications {
    items {
      actionUserId
      actionUserName
      actionUserPictureUrl
      createdAt
      expiresAt
      forUserId
      forUserName
      forUserPictureUrl
      notificationId
      organizationId
      organizationName
      projectId
      projectTitle
      sessionId
      sessionTitle
      commentId
      replyId
      text
      type
      unread
    }
    nextToken
  }
}
`;
export const getUnreadNotificationsCount = `query GetUnreadNotificationsCount {
  getUnreadNotificationsCount {
    count
  }
}
`;
