import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'

const warningCss = css`
  background-color: ${prop('theme.colors.warning100')};
  color: ${prop('theme.colors.warning')};
`

const Wrapper = styled.div`
  padding: 40px 40px 40px 56px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
  position: relative;
  line-height: 28px;
  background-color: white;

  ${({ isWarning }) => isWarning && warningCss}

  a {
    color: ${prop('theme.colors.secondary')};
  }

  a:hover {
    color: ${prop('theme.colors.secondary800')};
  }
`

const RequestRecordingCard = ({ html, children, isWarning, ...props }) => (
  <>
    {html && (
      <Wrapper
        isWarning={isWarning}
        {...props}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )}
    {!html && children && (
      <Wrapper isWarning={isWarning} {...props}>
        {children}
      </Wrapper>
    )}
  </>
)

RequestRecordingCard.propTypes = {
  html: PropTypes.string,
  children: PropTypes.node,
  isWarning: PropTypes.bool,
}

RequestRecordingCard.defaultProps = {
  html: null,
  children: null,
  isWarning: false,
}

export default RequestRecordingCard
