import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '@zendeskgarden/react-buttons'
import SVGInline from 'react-svg-inline'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'
import theme from '../../theme/old'

const getBackgroundHoverColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled && isBasic) {
    return 'transparent'
  }
  if (disabled) {
    return _theme.colors.grey200
  }
  if (isBasic) {
    return _theme.colors.grey200
  }
  if (disabled && isPrimary) {
    return _theme.colors.grey200
  }
  if (isPrimary) {
    return _theme.colors.primary400
  }
  if (isSecondary) {
    return _theme.colors.secondary200
  }
  if (isDanger) {
    return _theme.colors.warning600
  }
  return _theme.colors.primary500
}

const getBackgroundFocusColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled && isBasic) {
    return 'transparent'
  }
  if (disabled) {
    return _theme.colors.grey200
  }
  if (isBasic) {
    return _theme.colors.grey100
  }
  if (disabled && isPrimary) {
    return _theme.colors.grey200
  }
  if (isPrimary) {
    return _theme.colors.primary400
  }
  if (isSecondary) {
    return _theme.colors.secondary100
  }
  if (isDanger) {
    return _theme.colors.warning400
  }
  return _theme.colors.primary500
}

const getBackgroundActiveColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled && isBasic) {
    return 'transparent'
  }
  if (disabled) {
    return _theme.colors.grey200
  }
  if (isBasic) {
    return _theme.colors.grey300
  }
  if (disabled && isPrimary) {
    return _theme.colors.grey200
  }
  if (isPrimary) {
    return _theme.colors.primary300
  }
  if (isSecondary) {
    return _theme.colors.secondary300
  }
  if (isDanger) {
    return _theme.colors.warning700
  }
  return _theme.colors.primary500
}

const getBackgroundColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  disabled,
  isDanger,
}) => {
  if (disabled && isBasic) {
    return 'transparent'
  }
  if (disabled) {
    return _theme.colors.grey200
  }
  if (isBasic) {
    return 'transparent'
  }
  if (isPrimary) {
    return _theme.colors.primary
  }
  if (isDanger) {
    return _theme.colors.warning
  }
  return 'white'
}

const getIconColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled) {
    return _theme.colors.grey600
  }
  if (isBasic) {
    return _theme.colors.grey400
  }
  if (isPrimary) {
    return 'white'
  }
  if (isSecondary) {
    return _theme.colors.secondary
  }
  if (isDanger) {
    return 'white'
  }
  return _theme.colors.primary
}

const getIconHoverColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled) {
    return _theme.colors.grey600
  }
  if (isBasic) {
    return _theme.colors.primary
  }
  if (isPrimary) {
    return 'white'
  }
  if (isSecondary) {
    return _theme.colors.secondary
  }
  if (isDanger) {
    return 'white'
  }

  return _theme.colors.primary
}

const getIconFocusColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled) {
    return _theme.colors.grey600
  }
  if (isBasic) {
    return _theme.colors.primary800
  }
  if (isPrimary) {
    return 'white'
  }
  if (isSecondary) {
    return _theme.colors.secondary
  }
  if (isDanger) {
    return 'white'
  }

  return _theme.colors.primary
}

const getIconActiveColor = ({
  theme: _theme,
  isBasic,
  isPrimary,
  isSecondary,
  disabled,
  isDanger,
}) => {
  if (disabled) {
    return _theme.colors.grey600
  }
  if (isBasic) {
    return _theme.colors.primary900
  }
  if (isPrimary) {
    return 'white'
  }
  if (isSecondary) {
    return _theme.colors.secondary
  }
  if (isDanger) {
    return 'white'
  }

  return _theme.colors.primary
}

const StyledButton = styled(Button)`
  height: ${({ ownsChildren }) => (ownsChildren ? 'auto' : '32px')};
  width: ${({ ownsChildren }) => (ownsChildren ? 'auto' : '32px')};
  min-width: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  ${({ ownsChildren }) => (ownsChildren ? `padding: 0 8px;` : `padding: 0;`)}
  opacity: ${ternary('disabled')('0.5', '1')};

  && { 
    border: none;
    background-color: ${getBackgroundColor};
    color: ${getIconColor};
  }

  &&:focus {
    background-color: ${getBackgroundFocusColor};
    color: ${getIconFocusColor};
  }

  &&:hover {
    background-color: ${getBackgroundHoverColor};
    color: ${getIconHoverColor};
  }

  &&:active {
    background-color: ${getBackgroundActiveColor};
    color: ${getIconActiveColor};
  }

  transition: all 0.2s linear;
  && svg {
    transition: all 0.2s linear;
    fill: ${getIconColor};
  }

  &:hover svg {
    fill: ${getIconHoverColor};
  }

  &:focus svg {
    fill: ${getIconFocusColor};
  }

  &:active svg {
    fill: ${getIconActiveColor};
  }

`

const SVG = styled(SVGInline)`
  height: 24px;
  width: 24px;
  margin-right: ${({ ownsChildren }) => (ownsChildren ? '16px' : '0')};
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  fill: white;
`

const Content = styled(Flex)`
  margin: 0 8px;
`

const Image = styled.img`
  width: 24px;
  height: 24px;
`

const Icon = ({ icon, iconProps, ownsChildren }) => {
  const Component = icon
  return (
    <>
      {typeof icon === 'object' && <Component size={24} {...iconProps} />}
      {typeof icon === 'string' && !icon.includes('<svg') && (
        <Image src={icon} alt="icon" {...iconProps} />
      )}
      {typeof icon !== 'object' &&
        typeof icon === 'string' &&
        icon.includes('<svg') && (
          <SVG
            svg={icon}
            {...iconProps}
            ownsChildren={ownsChildren}
            width="24px"
            height="24px"
          />
        )}
    </>
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
  iconProps: PropTypes.shape({}).isRequired,
  ownsChildren: PropTypes.bool.isRequired,
}

const IconButton = ({
  disabled,
  children,
  icon,
  iconProps,
  isRightIcon,
  ...props
}) => {
  const _icon = (
    <Icon icon={icon} iconProps={iconProps} ownsChildren={!!children} />
  )
  return (
    <StyledButton
      size="small"
      disabled={disabled}
      {...props}
      ownsChildren={!!children}
    >
      {!isRightIcon && _icon}
      {children && <Content>{children}</Content>}
      {isRightIcon && _icon}
    </StyledButton>
  )
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]),
  iconProps: PropTypes.shape({}),
  isRightIcon: PropTypes.bool,
  onClick: PropTypes.func,
}

IconButton.defaultProps = {
  disabled: false,
  children: undefined,
  icon: '',
  iconProps: {
    color: theme.colors.grey400,
  },
  isRightIcon: false,
  onClick: () => {},
}

export default IconButton
