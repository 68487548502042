import React from 'react'
import styled from 'styled-components'
import { isElectron } from '../../../lib/platform'

export const DESKTOP_DRAG_BAR_HEIGHT = isElectron() ? 20 : 0
const Wrapper = styled.div`
  -webkit-app-region: drag;
  height: ${DESKTOP_DRAG_BAR_HEIGHT}px;
  width: 100vw;
`

const DesktopDragBar = () => isElectron() && <Wrapper />

export default DesktopDragBar
