import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Auth from '@aws-amplify/auth'
import last from 'lodash/last'
import uniqBy from 'lodash/uniqBy'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { getUserAttributes, getIdentityId } from '../../../util/auth'
import {
  createUserRequest,
  getUserRequest,
} from '../../../store/user/user.actions'
import fromUser from '../../../store/user/user.selectors'
import Flex from '../../atoms/Flex'
import ProgressBar from '../../atoms/ProgressBar'
import FullScreenLoader from '../../molecules/FullScreenLoader'
import OnboardingBrand from '../../molecules/Onboarding/OnboardingBrand'
import FullNameMobile from '../../pages/Onboarding/FullName/FullNameMobile'
import AvatarMobile from '../../pages/Onboarding/AvatarMobile'
import UserRoleMobile from '../../pages/Onboarding/UserRole/UserRoleMobile'
import OnboardingCompleteMobile from '../../pages/Onboarding/OnboardingComplete/OnboardingCompleteMobile'
import Plan from '../../pages/Onboarding/Plan'
import JoinWorkspaces from '../../pages/Onboarding/JoinWorkspaces'
import CreateWorkspace from '../../pages/Onboarding/CreateWorkspace'
import GoogleSignUpLoader from '../../pages/Onboarding/GoogleSignUpLoader'
import { getRecordingUrlWithVideoPosition } from './Onboarding.utils'
import { getCommentPathName } from '../../../util/paths'
import { COMMON_EMAIL_DOMAINS } from '../../../util/commonEmailDomains'
import { getAppSyncClient } from '../../../lib/appSyncClient'
import { INITIAL_WORKSPACE_ID_LOCAL_STORAGE_KEY } from '../../hooks/useFirstLanding'
import InviteMembers from '../../pages/Onboarding/InviteMembers'
import {
  getOrganizationsSharedByEmailDomain,
  getOrganizationsSharedViaEmail,
} from '../../../graphql/queries'

const CenteredBox = styled(Flex)`
  width: 100%;
  max-width: 660px;
  justify-content: center;
  align-items: center;
`

const ContentBox = styled(Flex)`
  padding-top: 24px;
  width: 100%;
  align-items: center;
`

const StyleOnboardingBrand = styled(OnboardingBrand)`
  max-width: 220px;
`

const StyledProgressBar = styled(ProgressBar)`
  max-width: 460px;
  width: 90%;
  margin-bottom: 16px;
`

const StyledFullNameMobile = styled(FullNameMobile)`
  width: 260px;
`

const VIEWS = {
  fullname: 'fullname',
  avatar: 'avatar',
  role: 'role',
  plan: 'plan',
  workspace: 'workspace',
  inviteMembers: 'inviteMembers',
  joinWorkspaces: 'joinWorkspaces',
  final: 'final',
}

export const handleUserRegistrationData = ({
  userRegistrationData,
  history,
  useReload = false,
}) => {
  if (userRegistrationData?.commentId) {
    const commentPath = getCommentPathName(userRegistrationData?.commentId)
    if (useReload) {
      window.location.href = commentPath
    } else {
      history.push(commentPath)
    }
    return
  }
  const recordingUrlWithMarkerPosition = getRecordingUrlWithVideoPosition(
    window.location.href,
    userRegistrationData
  )
  const recordingPath = recordingUrlWithMarkerPosition.replace(
    window.location.origin,
    ''
  )

  if (useReload) {
    window.location.href = recordingPath
  } else {
    history.push(recordingPath)
  }
}

let _previousView = VIEWS.fullname

const OnboardingMobile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userEmailFromStore = useSelector(fromUser.getEmail)
  const userRegistrationData = useSelector(fromUser.getRegistrationData)
  const [isGoogleSignUp, setGoogleSignUp] = useState(false)
  const [view, setView] = useState(VIEWS.fullname)
  const [previousView, setPreviousView] = useState('')
  useEffect(() => {
    setPreviousView(_previousView)
    _previousView = view
  }, [view])
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userEmailDomain, setUserEmailDomain] = useState('')
  const [pictureUrl, setPictureUrl] = useState('')
  const [avatarBackgroundColor, setAvatarBackgroundColor] = useState('')
  const [userRole, setUserRole] = useState('')
  const [plan, setPlan] = useState('')
  const [identityId, setIdentityId] = useState('')
  const [
    sharedWorkspacesByEmailDomain,
    setSharedWorkspacesByEmailDomain,
  ] = useState([])
  const [sharedWorkspacesViaEmail, setSharedWorkspacesViaEmail] = useState([])
  const [showGoogleSignUpLoader, setShowGoogleSignUpLoader] = useState(false)

  const [workspaceName, setWorkspaceName] = useState(null)
  const [workspacePictureUrl, setWorkspacePictureUrl] = useState(null)
  const [workspaceBackgroundColor, setWorkspaceBackgroundColor] = useState(null)
  const [
    workspaceShareByEmailDomain,
    setWorkspaceShareByEmailDomain,
  ] = useState(null)

  const apolloClient = getAppSyncClient()

  useEffect(() => {
    if (userEmail) {
      apolloClient
        .query({
          query: gql(getOrganizationsSharedByEmailDomain),
          fetchPolicy: 'no-cache',
          variables: {
            emailDomain: last(userEmail.split('@')),
          },
        })
        .then(result => {
          const workspaces =
            result?.data?.getOrganizationsSharedByEmailDomain?.organizations ||
            []
          setSharedWorkspacesByEmailDomain(workspaces)
        })

      apolloClient
        .query({
          query: gql(getOrganizationsSharedViaEmail),
          fetchPolicy: 'no-cache',
          variables: {
            email: userEmail,
          },
        })
        .then(result => {
          const workspaces =
            result?.data?.getOrganizationsSharedViaEmail?.organizations || []
          setSharedWorkspacesViaEmail(workspaces)
        })
    }
  }, [userEmail])

  useEffect(() => {
    const email = userEmailFromStore || userEmail
    if (email && email.includes('@')) {
      setUserEmailDomain(last(email.split('@')))
      return
    }
    setUserEmailDomain('')
  }, [userEmailFromStore, userEmail])

  const setInitialWorkspaceId = workspaceId => {
    localStorage.setItem(INITIAL_WORKSPACE_ID_LOCAL_STORAGE_KEY, workspaceId)
  }

  const onOnboardingComplete = useCallback(
    ({ members = [] }) =>
      dispatch(
        createUserRequest({
          identityId,
          name: userName,
          picture: pictureUrl,
          avatarBackgroundColor,
          email: userEmail,
          role: userRole,
          plan,
          workspaceName,
          workspacePictureUrl,
          workspaceBackgroundColor,
          workspaceShareByEmailDomain,
          workspaceMembers: members,
          isGoogle: isGoogleSignUp,
          userRegistrationData,
        })
      ).finally(() => {
        if (isGoogleSignUp) {
          setShowGoogleSignUpLoader(true)
          setTimeout(() => {
            if (
              (window.location.href.includes('/recording') ||
                window.location.href.includes('/session')) &&
              userRegistrationData
            ) {
              handleUserRegistrationData({
                userRegistrationData,
                history,
                useReload: true,
              })
              return
            }
            window.location.reload()
          }, 4000)
          return
        }
        setView(VIEWS.final)
      }),
    [
      dispatch,
      identityId,
      userName,
      pictureUrl,
      userEmail,
      userRole,
      plan,
      isGoogleSignUp,
      userRegistrationData,
      workspaceName,
      workspacePictureUrl,
      workspaceBackgroundColor,
      workspaceShareByEmailDomain,
    ]
  )

  useEffect(() => {
    dispatch(getUserRequest())
      .then(userData => {
        console.log('>>> userData', userData)
        if (userData.identityId) {
          if (window.location.pathname === '/onboarding') {
            window.location = '/app'
          } else {
            window.location.reload()
          }
          return
        }
        setIsLoadingUser(false)
      })
      .catch(() => setIsLoadingUser(false))

    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(async user => {
        // Google signup
        console.log('>>> currentAuthenticatedUser', user)
        const userAttributes = await getUserAttributes()
        console.log('>>> userAttributes', userAttributes)
        setUserName(userAttributes.name || '')
        setUserEmail(userAttributes.email || '')
        setPictureUrl(userAttributes.picture || '')
        setIdentityId(userAttributes.identityId)
        setGoogleSignUp(true)
      })
      .catch(async () => {
        // Cognito signup
        console.log('>>> currentAuthenticatedUser failed')
        const _identityId = await getIdentityId()
        setIdentityId(_identityId)
        setUserEmail(userEmailFromStore)
        setGoogleSignUp(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const getProgressWidth = () => {
    if (view === VIEWS.fullname) {
      return 20
    }
    if (view === VIEWS.avatar) {
      return 40
    }
    if (view === VIEWS.role) {
      return 60
    }
    if (view === VIEWS.plan) {
      return 80
    }
    if (view === VIEWS.joinWorkspaces) {
      return 80
    }
    if (view === VIEWS.workspace) {
      return 85
    }
    return 90
  }

  return (
    <>
      {(isLoading || isLoadingUser) && <FullScreenLoader />}
      {showGoogleSignUpLoader && <GoogleSignUpLoader />}
      {!isLoading && !isLoadingUser && (
        <>
          <StyleOnboardingBrand />
          <CenteredBox>
            {view !== VIEWS.final && (
              <StyledProgressBar width={getProgressWidth()} />
            )}
            <ContentBox>
              {view === VIEWS.fullname && (
                <StyledFullNameMobile
                  userName={userName}
                  pictureUrl={pictureUrl}
                  onNext={_userName => {
                    setUserName(_userName)
                    setView(VIEWS.avatar)
                  }}
                />
              )}
              {view === VIEWS.avatar && (
                <AvatarMobile
                  userName={userName}
                  pictureUrl={pictureUrl}
                  onNext={({
                    avatarPictureUrl,
                    avatarBackgroundColor: _avatarBackgroundColor,
                  }) => {
                    setPictureUrl(avatarPictureUrl)
                    setAvatarBackgroundColor(_avatarBackgroundColor)
                    setView(VIEWS.role)
                  }}
                />
              )}
              {view === VIEWS.role && (
                <UserRoleMobile
                  userName={userName}
                  onNext={_userRole => {
                    setUserRole(_userRole)
                    if (
                      sharedWorkspacesByEmailDomain.length > 0 ||
                      sharedWorkspacesViaEmail.length > 0
                    ) {
                      setView(VIEWS.joinWorkspaces)
                      return
                    }
                    setView(VIEWS.plan)
                  }}
                />
              )}
              {view === VIEWS.joinWorkspaces && (
                <JoinWorkspaces
                  workspaces={uniqBy(
                    [
                      ...sharedWorkspacesByEmailDomain,
                      ...sharedWorkspacesViaEmail,
                    ],
                    'pk'
                  )}
                  onNext={workspaceId => {
                    setInitialWorkspaceId(workspaceId)
                    onOnboardingComplete({})
                  }}
                  onCreateNewWorkspace={() => setView(VIEWS.workspace)}
                />
              )}
              {view === VIEWS.plan && (
                <Plan
                  userName={userName}
                  onNext={_plan => {
                    setPlan(_plan)
                    if (_plan === 'team') {
                      setView(VIEWS.workspace)
                      return
                    }
                    setTimeout(() => onOnboardingComplete({}), 100)
                    if (!isGoogleSignUp) {
                      setView(VIEWS.final)
                    }
                  }}
                />
              )}
              {view === VIEWS.workspace && (
                <CreateWorkspace
                  userEmailDomain={
                    !COMMON_EMAIL_DOMAINS.includes(userEmailDomain)
                      ? userEmailDomain
                      : ''
                  }
                  onBack={() => setView(previousView)}
                  onNext={({
                    workspaceName: _workspaceName,
                    workspacePictureUrl: _workspacePictureUrl,
                    workspaceBackgroundColor: _workspaceBackgroundColor,
                    workspaceShareByEmailDomain: _workspaceShareByEmailDomain,
                  }) => {
                    setWorkspaceName(_workspaceName)
                    setWorkspacePictureUrl(_workspacePictureUrl)
                    setWorkspaceBackgroundColor(_workspaceBackgroundColor)
                    setWorkspaceShareByEmailDomain(_workspaceShareByEmailDomain)
                    setView(VIEWS.inviteMembers)
                  }}
                />
              )}
              {view === VIEWS.inviteMembers && (
                <InviteMembers
                  onBack={() => setView(previousView)}
                  onNext={({ members = null }) => {
                    setTimeout(
                      () =>
                        onOnboardingComplete({
                          members,
                        }),
                      100
                    )
                  }}
                />
              )}
              {view === VIEWS.final && (
                <OnboardingCompleteMobile email={userEmail} />
              )}
            </ContentBox>
          </CenteredBox>
        </>
      )}
    </>
  )
}

OnboardingMobile.propTypes = {}

export default OnboardingMobile
