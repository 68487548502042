import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useCRUDUser from '../../../store/hooks/useCRUDUser'
import usePictureSettings from '../../organisms/Settings/hooks/usePictureSettings'
import useNameSettings from '../../organisms/Settings/hooks/useNameSettings'
import useUserInfo from '../../../store/hooks/useUserInfo'
import UserSettingsPageMobile from './UserSettingsPageMobile'

const UserSettingsPageMobileContainer = () => {
  const history = useHistory()
  const { onUpdateUserHandler } = useCRUDUser()

  const onNotificationUpdate = useCallback(
    newFrequencyValue => {
      onUpdateUserHandler({
        notifications: {
          frequency: newFrequencyValue,
        },
      })
    },
    [onUpdateUserHandler]
  )

  const {
    userPictureUrl,
    updatePictureUrl: onPictureChange,
  } = usePictureSettings()

  const { userName, updateUser: onNameUpdate } = useNameSettings()

  const {
    userEmail,
    userIdentityId,
    userNotificationsFrequency,
  } = useUserInfo()

  const onBack = () => history.push('/app')

  return (
    <UserSettingsPageMobile
      picture={userPictureUrl}
      onPictureChange={onPictureChange}
      onNotificationUpdate={onNotificationUpdate}
      notificationFrequency={userNotificationsFrequency}
      userName={userName}
      email={userEmail}
      onNameUpdate={newName =>
        onNameUpdate({ identityId: userIdentityId, name: newName })
      }
      onBack={onBack}
    />
  )
}

export default UserSettingsPageMobileContainer
