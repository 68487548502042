import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Field, Hint, Label, Input, Checkbox } from '@zendeskgarden/react-forms'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import WorkspaceLogoSelector from '../../../molecules/WorkspaceLogoSelector'
import IconButton from '../../../molecules/IconButton'
import Flex from '../../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  max-width: 288px;
`

const Left = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  text-align: left;
`

const StyledHint = styled(Hint)`
  font-size: 12px;
`

const Title = styled.h3`
  margin-bottom: 16px;
`

const NextButton = styled(IconButton)`
  align-self: center;
`

const ButtonsCenteredBox = styled(CenteredBox)`
  min-height: 'fit-content';
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const Hr = styled.hr`
  width: 288px;
  opacity: 0.1;
  margin-top: 16px;
  margin-bottom: 0;
`

const StyledLabel = styled(Label)`
  font-weight: normal;
  text-align: left;
`

const CreateWorkspace = ({
  userName,
  userEmailDomain,
  onBack,
  onNext,
  ...props
}) => {
  const [workspaceName, setWorkspaceName] = useState('')
  const [workspacePictureUrl, setWorkspacePictureUrl] = useState('')
  const [workspaceBackgroundColor, setWorkspaceBackgroundColor] = useState('')
  const [isEmailDomainShareActive, setEmailDomainShareActive] = useState(false)

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>Create a Team Workspace</Title>
      </CenteredBox>
      <CenteredBox>
        <Left>
          <Field>
            <Label>Workspace name</Label>
            <Input
              placeholder="Acme"
              onInput={e => setWorkspaceName(e.target.value)}
            />
            <StyledHint>The name of your company or organization</StyledHint>
          </Field>
        </Left>
        {workspaceName !== '' && (
          <Left style={{ marginTop: '40px' }}>
            <Label>Workspace picture</Label>
          </Left>
        )}
      </CenteredBox>
      {workspaceName !== '' && (
        <CenteredBox>
          <WorkspaceLogoSelector
            name={workspaceName || ''}
            onPictureChange={pictureUrl => {
              setWorkspacePictureUrl(pictureUrl)
              setWorkspaceBackgroundColor('')
            }}
            onBackgroundColorSelect={({ backgroundColor }) => {
              setWorkspacePictureUrl('')
              setWorkspaceBackgroundColor(backgroundColor)
            }}
          />
        </CenteredBox>
      )}
      {workspaceName !== '' && userEmailDomain && (
        <>
          <CenteredBox>
            <Hr />
          </CenteredBox>
          <CenteredBox>
            <Field>
              <Checkbox
                checked={isEmailDomainShareActive}
                onChange={() =>
                  setEmailDomainShareActive(!isEmailDomainShareActive)
                }
              >
                <StyledLabel>
                  Let anyone with a @{userEmailDomain} email join this workspace
                </StyledLabel>
              </Checkbox>
            </Field>
          </CenteredBox>
        </>
      )}
      <ButtonsCenteredBox>
        <NextButton
          isPrimary
          isBasic
          type="submit"
          icon={ArrowLeftIcon}
          size="medium"
          onClick={onBack}
          data-test="back"
        >
          Back
        </NextButton>
        <NextButton
          isPrimary
          type="submit"
          disabled={!workspaceName}
          icon={ArrowRightIcon}
          isRightIcon
          size="medium"
          data-test="continue"
          onClick={() =>
            onNext({
              workspaceName,
              workspacePictureUrl,
              workspaceBackgroundColor,
              workspaceShareByEmailDomain: isEmailDomainShareActive,
            })
          }
        >
          Continue
        </NextButton>
      </ButtonsCenteredBox>
    </Wrapper>
  )
}

CreateWorkspace.propTypes = {
  userName: PropTypes.string,
  userEmailDomain: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
}

CreateWorkspace.defaultProps = {
  userName: '',
  userEmailDomain: '',
  onNext: () => {},
  onBack: () => {},
}

export default CreateWorkspace
