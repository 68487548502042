export const getNewPageTitle = (currentTitle, count) => {
  if (currentTitle.includes(')')) {
    const baseTitle = currentTitle.split(')')[1].trim()
    const newTitle = count > 0 ? `(${count}) ${baseTitle}` : baseTitle
    return newTitle
  }

  return count > 0 ? `(${count}) ${currentTitle}` : currentTitle
}

export const updatePageTitle = count => {
  document.title = getNewPageTitle(document.title, count)
}

export const getUpdateNotificationIconUrl = (currentUrl, count) => {
  if (count === 0) {
    return currentUrl.replace('-notifications', '')
  }

  return currentUrl.includes('-notifications')
    ? currentUrl
    : currentUrl
        .replace('.png', '-notifications.png')
        .replace('.ico', '-notifications.ico')
}

export const updatePageFavicon = count => {
  const allIconLinks = document.querySelectorAll("link[rel*='icon']")
  if (count === 0) {
    allIconLinks.forEach(link => {
      const currentHref = link.href
      // eslint-disable-next-line no-param-reassign
      link.href = getUpdateNotificationIconUrl(currentHref, count)
    })
    return
  }

  allIconLinks.forEach(link => {
    const currentHref = link.href
    // eslint-disable-next-line no-param-reassign
    link.href = getUpdateNotificationIconUrl(currentHref, count)
  })
}
