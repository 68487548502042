import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Header, Body, Close } from '@zendeskgarden/react-modals'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import OnboardingMobile, {
  handleUserRegistrationData,
} from '../../../organisms/OnboardingProxy/OnboardingMobile'
import RegistrationFormContainer from './RegistrationFormContainer'
import LoginPageContainer from '../../LoginPage/LoginPageContainer'
import fromUser from '../../../../store/user/user.selectors'
import { getUserRequest } from '../../../../store/user/user.actions'
import { getIsGoogleLoginAvailable } from '../../../../util/auth'

const StyledModal = styled(Modal)`
  width: 50%;
  min-width: 720px;
  max-width: 1280px;
`

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FORM_HEIGHT = 538
const SMALL_FORM_HEIGHT = 320
const StyledRegistrationFormContainer = styled(RegistrationFormContainer)`
  && {
    height: ${getIsGoogleLoginAvailable()
      ? FORM_HEIGHT
      : SMALL_FORM_HEIGHT}px !important;
    margin-top: 0 !important;
  }

  form {
    margin-top: 0 !important;
  }
`

const StyledLoginPageContainer = styled(LoginPageContainer)`
  && {
    height: ${FORM_HEIGHT}px !important;
  }

  img[alt='brand'] {
    display: none;
  }

  form {
    margin-top: 0 !important;
  }
`

const RegistrationModal = ({ initWithLogin, onClose }) => {
  const userRegistrationData = useSelector(fromUser.getRegistrationData)
  const [isOnboardingVisible, setOnboardingVisible] = useState(false)
  const onModalClose = useCallback(() => {
    if (isOnboardingVisible) {
      return null
    }
    onClose()
    return true
  }, [isOnboardingVisible])

  const [isLoginVisible, setLoginVisible] = useState(initWithLogin)

  const dispatch = useDispatch()

  const history = useHistory()
  const onLoginComplete = useCallback(
    ({ useReload = false }) => {
      setTimeout(() => {
        dispatch(getUserRequest())
          .then(() => {
            if (!isEmpty(userRegistrationData)) {
              handleUserRegistrationData({
                userRegistrationData,
                history,
                useReload,
              })

              return
            }

            window.location.reload()
          })
          .catch(err => {
            console.log('>>> err', err)
            setOnboardingVisible(true)
          })
      }, 250)
    },
    [userRegistrationData, handleUserRegistrationData, history]
  )

  return (
    <StyledModal>
      <Header>
        {isLoginVisible ? 'Sign in' : 'Sign up'} to leave unlimited feedback for
        free
      </Header>
      <StyledBody>
        {!isOnboardingVisible && !isLoginVisible && (
          <StyledRegistrationFormContainer
            onComplete={() => setOnboardingVisible(true)}
            onShowLogin={e => {
              e.preventDefault()
              e.stopPropagation()
              setLoginVisible(true)
            }}
          />
        )}
        {!isOnboardingVisible && isLoginVisible && (
          <StyledLoginPageContainer
            isModal
            onGoogleSignInComplete={() => onLoginComplete({ useReload: true })}
            onComplete={() => onLoginComplete({ useReload: false })}
            onShowRegister={e => {
              e.preventDefault()
              e.stopPropagation()
              setLoginVisible(false)
            }}
          />
        )}
        {isOnboardingVisible && <OnboardingMobile />}
      </StyledBody>
      {!isOnboardingVisible && (
        <Close aria-label="Close modal" onClick={onModalClose} />
      )}
    </StyledModal>
  )
}

RegistrationModal.propTypes = {
  onClose: PropTypes.func,
  initWithLogin: PropTypes.bool,
}

RegistrationModal.defaultProps = {
  onClose: () => {},
  initWithLogin: false,
}

export default RegistrationModal
