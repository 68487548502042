import { useCallback, useEffect, useRef, useState } from 'react'
import getFp from 'lodash/fp/get'
import first from 'lodash/first'
import LOCAL_STORAGE_KEYS from '../../browser-extension/storageKeys'
import {
  storageLocalGet,
  storageLocalSet,
} from '../../browser-extension/libs/storage'
import {
  isScreenRecordingSupported,
  muteAudio,
  startRecording,
  stopRecording,
  unmuteAudio,
} from '../../lib/mediaRecorder'

const getAudioDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  const audioDevices = devices.filter(x => x.kind === 'audioinput')

  return audioDevices
}

const useRecording = () => {
  const [isRecording, setRecording] = useState(false)
  const [isMicEnabled, setMicEnabled] = useState(false)
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null)
  const [mediaBlob, setMediaBlob] = useState(null)
  const [currentPreviewStream, setCurrentPreviewStream] = useState(null)
  const [mimeType, setMimeType] = useState(null)
  const [currentAudioDevice, setCurrentAudioDevice] = useState(null)
  const [audioDevices, setAudioDevices] = useState([])

  const currentMediaStream = useRef(null)
  const currentMediaRecorder = useRef(null)
  const previewRef = useRef(null)

  const onDeviceChange = useCallback(() => {
    getAudioDevices().then(currentAudioDevices => {
      const serializedCurrentAudioDevices = currentAudioDevices
        .map(getFp('deviceId'))
        .join(',')
      const serializedPrevAudioDevices = audioDevices
        .map(getFp('deviceId'))
        .join(',')

      if (serializedCurrentAudioDevices !== serializedPrevAudioDevices) {
        setAudioDevices(currentAudioDevices)
      }

      if (!currentAudioDevice) {
        const device = first(
          currentAudioDevices.filter(x => x.deviceId === 'default')
        )
        setCurrentAudioDevice(device)
      }
    })
  }, [setAudioDevices, audioDevices, currentAudioDevice])

  useEffect(() => {
    if (navigator?.mediaDevices) {
      onDeviceChange()
      navigator.mediaDevices.addEventListener('devicechange', onDeviceChange)
    }

    return () => {
      if (navigator?.mediaDevices) {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          onDeviceChange
        )
        setInterval(() => onDeviceChange(), 5000)
      }
    }
  }, [audioDevices, onDeviceChange])

  const toggleMicAudio = useCallback(() => {
    if (isMicEnabled) {
      muteAudio(currentMediaStream.current)
    }

    if (!isMicEnabled) {
      unmuteAudio(currentMediaStream.current)
    }

    setMicEnabled(!isMicEnabled)
  }, [currentMediaStream, isMicEnabled, setMicEnabled])

  const [isMicAvailable, setMicAvailable] = useState(false)
  useEffect(() => {
    if (isMicAvailable) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .catch(() => setMicAvailable(false))
    }
  }, [isMicAvailable])

  useEffect(() => {
    try {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const filteredDevices = devices.filter(d => d.kind === 'audioinput')
        if (filteredDevices.length > 0) {
          setMicAvailable(true)
        } else {
          toggleMicAudio()
        }
      })
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (isMicEnabled) {
      navigator.mediaDevices.getUserMedia({ audio: true })
    }
  }, [isMicEnabled])

  const resetState = () => {
    if (previewRef?.current) {
      previewRef.current.srcObject = null
    }
    setRecording(false)
    setMediaBlobUrl(null)
    setCurrentPreviewStream(null)
    setMimeType(null)
  }

  useEffect(() => {
    if (previewRef?.current) {
      if ('srcObject' in previewRef.current) {
        previewRef.current.srcObject = currentPreviewStream
      } else {
        previewRef.current.src = URL.createObjectURL(currentPreviewStream)
      }
    }
  }, [currentPreviewStream, previewRef?.current])

  const onStop = ({
    mediaBlob: _mediaBlob,
    mediaBlobUrl: _mediaBlobUrl,
    videoMimeType,
  }) => {
    setMediaBlobUrl(_mediaBlobUrl)
    setMediaBlob(_mediaBlob)
    setCurrentPreviewStream(null)
    setMimeType(videoMimeType)
    setRecording(false)
  }

  const resetHandler = useCallback(() => {
    setRecording(false)
    setMediaBlobUrl(null)
    currentMediaStream.current = null
    currentMediaRecorder.current = null
  }, [])

  const [isRecordingAvailable, setRecordingAvailable] = useState(
    isScreenRecordingSupported()
  )
  const startRecordingHandler = () => {
    startRecording({ audio: isMicEnabled, screen: true, onStop })
      .then(({ mediaStream, mediaRecorder }) => {
        console.log('>>> mediaStream', mediaStream)
        setRecording(true)
        currentMediaStream.current = mediaStream
        currentMediaRecorder.current = mediaRecorder
        setCurrentPreviewStream(new MediaStream(mediaStream.getVideoTracks()))
      })
      .catch(err => {
        console.log('>>> err', typeof err)
        console.log('>>> err', err.name)
        console.log('>>> err', err.message)
        if (err?.message?.toLowerCase() === 'invalid state') {
          setRecordingAvailable(false)
        }
      })
    setMediaBlobUrl(null)
  }
  const stopRecordingHandler = () => {
    stopRecording(
      currentMediaRecorder.current,
      currentMediaStream.current
    ).then(() => setRecording(false))
  }

  useEffect(() => {
    storageLocalGet(LOCAL_STORAGE_KEYS.isMicEnabled).then(setMicEnabled)
  }, [])

  useEffect(() => {
    storageLocalSet(LOCAL_STORAGE_KEYS.isMicEnabled, isMicEnabled)
  }, [isMicEnabled])

  return {
    audioDevices,
    currentAudioDevice,
    setCurrentAudioDevice,
    currentPreviewStream,
    isMicAvailable,
    isMicEnabled,
    isRecording,
    isRecordingAvailable,
    mediaBlob,
    mediaBlobUrl,
    mimeType,
    previewRef,
    resetHandler,
    resetState,
    startRecordingHandler,
    stopRecordingHandler,
    toggleMicAudio,
    setMicEnabled,
  }
}

export default useRecording
