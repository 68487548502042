import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import PictureSetting from './PictureSetting'
import NameSetting from './NameSetting'
import EmailSetting from './EmailSetting'
import PasswordSetting from './PasswordSetting'
import NotificationSetting from './NotificationSetting'
import usePictureSettings from './hooks/usePictureSettings'
import useUserInfo from '../../../store/hooks/useUserInfo'
import useCRUDUser from '../../../store/hooks/useCRUDUser'
import IntegrationsSetting from './IntegrationsSetting'

const Wrapper = styled(Flex)``

const commonCss = css`
  border-bottom: 1px solid ${prop('theme.colors.grey100')};
`
const StyledPictureSetting = styled(PictureSetting)`
  ${commonCss}
`
const StyledNameSetting = styled(NameSetting)`
  ${commonCss}
`
const StyledEmailSetting = styled(EmailSetting)`
  ${commonCss}
  padding: 10px 8px 10px 0;
`
const StyledPasswordSetting = styled(PasswordSetting)`
  ${commonCss}
`
const StyledNotificationSetting = styled(NotificationSetting)``

const Settings = ({ ...props }) => {
  const { userName, userNotificationsFrequency } = useUserInfo()
  const { onUpdateUserHandler } = useCRUDUser()

  const { userPictureUrl, updatePictureUrl } = usePictureSettings()

  const onNotificationSettingChange = useCallback(
    newFrequencyValue => {
      onUpdateUserHandler({
        notifications: {
          frequency: newFrequencyValue,
        },
      })
    },
    [onUpdateUserHandler]
  )

  return (
    <Wrapper {...props}>
      <StyledPictureSetting
        name={userName}
        picture={userPictureUrl}
        onPictureChange={newPictureUrl => updatePictureUrl(newPictureUrl)}
      />
      <StyledEmailSetting />
      <StyledNameSetting />
      <StyledPasswordSetting />
      <StyledNotificationSetting
        value={userNotificationsFrequency}
        onChange={onNotificationSettingChange}
      />
      <IntegrationsSetting />
    </Wrapper>
  )
}

Settings.propTypes = {}

Settings.defaultProps = {}

export default Settings
