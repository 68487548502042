import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Chrome, Body, Main } from '@zendeskgarden/react-chrome'
import { withRouter } from 'react-router-dom'
import { Tag } from '@zendeskgarden/react-tags'
import SessionTopBar from '../../organisms/SessionTopBar'
import SessionView from '../../organisms/SessionView'
import { isElectron } from '../../../lib/platform'
import browserExtension from '../../../lib/browserExtension'
import messageTypes from '../../../browser-extension/messageTypes'

const StyledChrome = styled(Chrome)`
  height: ${isElectron() ? 'calc(100vh - 20px)' : '100vh'};
`

const StyledBody = styled(Body)`
  display: flex;
  flex-direction: column;
`

const StyledMain = styled(Main)`
  flex: 1 1;
  order: 1;
`

const ProjectTitle = ({ title = '', isShared }) => (
  <React.Fragment>
    {title} {isShared && <Tag isPill>shared</Tag>}
  </React.Fragment>
)

ProjectTitle.propTypes = {
  title: PropTypes.string,
  isShared: PropTypes.bool,
}

ProjectTitle.defaultProps = {
  title: '',
  isShared: false,
}

const AnonymousMainAppPage = ({ recordingId, recording, ...props }) => {
  const [isAssociate, setIsAssociate] = useState(false)
  useEffect(() => {
    recording.sessionId = recording.id
    browserExtension
      .sendMessageToBackground({
        type: messageTypes.SESSION_CREATED,
        sessionId: recording.id,
        recording: recording,
      })
      .then(response => {
        setIsAssociate(true)
      })
  }, [])
  const [
    registrationModalVisibilityToken,
    setRegistrationModalVisibilityToken,
  ] = useState(null)

  const [
    registrationModalWithLoginToken,
    setRegistrationModalWithLoginToken,
  ] = useState(null)

  const [
    registrationModalWithRegisterToken,
    setRegistrationModalWithRegisterToken,
  ] = useState(null)

  const onSessionLogin = () => {
    setRegistrationModalWithLoginToken(Math.random().toString())
    setRegistrationModalVisibilityToken(Math.random().toString())
  }

  const onSessionRegister = () => {
    setRegistrationModalWithRegisterToken(Math.random().toString())
    setRegistrationModalVisibilityToken(Math.random().toString())
  }

  const isTemporaryRecording = !!recordingId
  const [temporarySessionTitle, setTemporarySessionTitle] = useState('')

  if (!isAssociate) return 'loading'
  return (
    <StyledChrome {...props}>
      <StyledBody>
        <SessionTopBar
          onLogin={() => onSessionLogin()}
          onRegister={onSessionRegister}
          isTemporaryRecording={isTemporaryRecording}
          recordingId={recordingId}
          temporarySessionTitle={temporarySessionTitle}
        />
        <StyledMain>
          <SessionView
            isRecording={false}
            isEditMode
            readOnly
            isAnonymous
            registrationModalVisibilityToken={registrationModalVisibilityToken}
            registrationModalWithLoginToken={registrationModalWithLoginToken}
            registrationModalWithRegisterToken={
              registrationModalWithRegisterToken
            }
            recordingId={recordingId}
            onSetTemporarySessionTitle={title =>
              setTemporarySessionTitle(title)
            }
          />
        </StyledMain>
      </StyledBody>
    </StyledChrome>
  )
}

AnonymousMainAppPage.propTypes = {
  recordingId: PropTypes.string,
}

AnonymousMainAppPage.defaultProps = {
  recordingId: null,
}

export default withRouter(AnonymousMainAppPage)
