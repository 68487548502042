import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import Div100vh from '../Div100vh'

const Wrapper = styled(Div100vh)`
  position: ${prop('position')};
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2200;
  background-color: white;
`

const MobileModal = ({ children, position, ...props }) => (
  <Wrapper {...props} position={position}>
    {children}
  </Wrapper>
)

MobileModal.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(['absolute', 'fixed']),
}

MobileModal.defaultProps = {
  children: null,
  position: 'fixed',
}

export default MobileModal
