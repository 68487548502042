const MessageTypes = {
  INJECT_COUNTDOWN: 'INJECT_COUNTDOWN',
  INJECT_COMMENTS_LAYER: 'INJECT_COMMENTS_LAYER',
  START_RECORDING: 'START_RECORDING',
  PAUSE_RECORDING: 'PAUSE_RECORDING',
  RESUME_RECORDING: 'RESUME_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  REQUEST_VIDEO_BLOB: 'REQUEST_VIDEO_BLOB',
  REQUEST_VIDEO_BLOB_FROM_CONTENT_SCRIPT:
    'REQUEST_VIDEO_BLOB_FROM_CONTENT_SCRIPT',
  SESSION_CREATED: 'SESSION_CREATED',
  SESSION_VIDEO_REQUESTED: 'SESSION_VIDEO_REQUESTED',
  PING: 'PING',
  PONG: 'PONG',
  REQUEST_PERMISSIONS: 'REQUEST_PERMISSIONS',
  REQUEST_DEVICES: 'REQUEST_DEVICES',
  GET_COMMENTS_NUMBER: 'GET_COMMENTS_NUMBER',
  IS_MIC_GRANTED: 'IS_MIC_GRANTED',
  FEATURE_FLAGS_REQUESTED: 'FEATURE_FLAGS_REQUESTED',
  FEATURE_FLAGS_UPDATED: 'FEATURE_FLAGS_UPDATED',
  TRACK_EVENT_REQUESTED: 'TRACK_EVENT_REQUESTED',
  GO_TO_PAGE: 'GO_TO_PAGE',
  UPLOAD_COMPLETED: 'UPLOAD_COMPLETED',
  IS_RECORDING: 'IS_RECORDING',
  WINDOW_CLICK: 'iterspace:extension:window_click',
  WINDOW_CLICK_OUTSIDE: 'iterspace:extension:window_click_outside',
  COMMENT_ADDED: 'iterspace:extension:comment_added',
  MIC_ENABLED: 'iterspace:extension:mic_enabled',
  UPDATE_WIDGET_POSITION: 'iterspace:extension:update_widget_position',
  PAGE_EVENT_SENT: 'iterspace:extension:page_event_sent',
  INTERNAL_RECORDING_STARTED: 'recording:started',
  INTERNAL_RECORDING_STOPPED: 'recording:stopped',
  DEVICE_META_INFO: 'iterspace:extension:device_meta_info',
}

export default MessageTypes
