/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const StyledErrorMessage = styled.div`
  font-style: italic;
  font-size: 12px;
  margin-top: 8px;
  color: #eb5757;
  text-align: left;
`
