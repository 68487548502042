import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import EditableCommentMobile from '../EditableComment/EditableCommentMobile'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import MobileTopBar from '../MobileTopBar'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Footer = styled(Flex)`
  padding: 16px;

  > * {
    margin-bottom: 8px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const EditTextMobile = ({
  isVisible,
  onClose,
  onSubmit,
  title,
  text,
  buttonNode,
  textAreaPlaceholder,
}) => {
  const onModalClose = () => {
    onClose()
  }

  const inputRef = useRef(null)
  const [noteText, setNoteText] = useState(text)

  const header = useMemo(
    () => (
      <StyledMobileTopBar icon={ArrowLeftIcon} onButtonClick={onModalClose}>
        {title}
      </StyledMobileTopBar>
    ),
    []
  )

  const body = useMemo(
    () => (
      <EditableCommentMobile
        isExtended
        onInput={setNoteText}
        inputRef={inputRef}
        showEmojiButton={false}
        style={{
          marginTop: '16px',
        }}
        textAreaProps={{
          minRows: 4,
          defaultValue: text,
          placeholder: textAreaPlaceholder || 'Write a message...',
        }}
      />
    ),
    [inputRef, text, textAreaPlaceholder]
  )

  const footer = useMemo(
    () => (
      <Footer>
        <Button isPrimary onClick={() => onSubmit(noteText)}>
          {buttonNode}
        </Button>
      </Footer>
    ),
    [noteText, buttonNode]
  )

  return (
    isVisible && (
      <MobileModal>
        <MobileTemplate header={header} body={body} footer={footer} />
      </MobileModal>
    )
  )
}

EditTextMobile.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  buttonNode: PropTypes.node,
  textAreaPlaceholder: PropTypes.string,
}

EditTextMobile.defaultProps = {
  text: '',
  title: '',
  isVisible: false,
  onClose: () => {},
  onSubmit: () => {},
  buttonNode: null,
  textAreaPlaceholder: null,
}

export default EditTextMobile
