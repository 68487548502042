import React from 'react'
import useOrganizationMembers from './useOrganizationMembers'
import OrganizationMembers from './OrganizationMembers'
import useOrganization from '../../../store/hooks/useOrganization'

const OrganizationMembersContainer = ({ ...props }) => {
  const { currentOrganizationId } = useOrganization()
  const hookProps = useOrganizationMembers(currentOrganizationId)
  return <OrganizationMembers {...props} {...hookProps} />
}

export default OrganizationMembersContainer
