import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import NewReplyModalMobile from './NewReplyModalMobile'
import fromComments from '../../../store/comments/comments.selectors'

const NewReplyModalMobileContainer = ({ commentId, ...props }) => {
  const comment = useSelector(fromComments.getComment(commentId))
  return <NewReplyModalMobile {...props} comment={comment} />
}

NewReplyModalMobileContainer.propTypes = {
  commentId: PropTypes.string.isRequired,
}

export default NewReplyModalMobileContainer
