import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import prop from 'lodash/fp/prop'
import { LG } from '@zendeskgarden/react-typography'
import { Button } from '@zendeskgarden/react-buttons'
import CloseIcon from 'mdi-react/CloseIcon'
import Flex from '../../atoms/Flex'
import MobileModal from '../MobileModal'
import fromSelectors from '../../../store/selectors'
import { resetUploadProgress } from '../../../store/actions'
import {
  DELETE_SESSION_REQUEST,
  deleteSessionRequest,
} from '../../../store/sessions/sessions.actions'
import useReduxSagaThunkButton from '../../../store/hooks/useReduxSagaThunkButton'

const Wrapper = styled(MobileModal)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`

const Header = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  padding: 8px 8px;
  box-sizing: border-box;
`

const Body = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  align-items: center;
  justify-content: center;
`

const Footer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  padding-bottom: 32px;
`

const Status = styled(LG)`
  margin-bottom: 16px;
`

const SteppedProgress = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: space-between;
  align-items: center;
`

const STEP_WIDTH = 80
const SHUFFLE_STEP_WIDTH = 24
const Step = styled(Flex)`
  height: 4px;
  border-radius: 4px;
  background-color: ${prop('theme.colors.grey200')};
  position: relative;
  width: ${STEP_WIDTH}px;
  margin-right: 8px;

  :last-child {
    margin-right: 0;
  }
`

const shuffleKeyframes = keyframes`
  0% {
    left: 0;
    width: ${SHUFFLE_STEP_WIDTH}px;
  }

  50% {
  width: ${SHUFFLE_STEP_WIDTH + 8}px;
  }

  100% {
    left: ${STEP_WIDTH - SHUFFLE_STEP_WIDTH}px;
    width: ${SHUFFLE_STEP_WIDTH}px;
  }
`

const StepProgress = styled(Flex)`
  height: 4px;
  border-radius: 4px;
  background-color: ${prop('theme.colors.green')};
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ percentage }) => (STEP_WIDTH / 100) * percentage}px;
  ${({ shuffle }) =>
    shuffle
      ? css`
          width: 64px;
          animation: 1.4s ${shuffleKeyframes} ease-in-out infinite alternate;
        `
      : ''}
`

const getLabel = (progress, uploadSessionId) => {
  if (uploadSessionId === '') {
    return 'Ready!'
  }

  return progress !== 100 ? 'Uploading...' : 'Processing...'
}

const UploadModal = ({ percentage, onComplete, onClose, onStop, ...props }) => {
  const dispatch = useDispatch()

  const progress = useSelector(fromSelectors.getUploadProgress)
  const uploadSessionId = useSelector(fromSelectors.getUploadSessionId)
  const videoConversion = useSelector(
    fromSelectors.getUploadVideoConversionProgress
  )
  const spritesCreation = useSelector(
    fromSelectors.getUploadVideoSpritesProgress
  )

  useEffect(() => {
    if (
      progress === 100 &&
      videoConversion === 100 &&
      spritesCreation === 100 &&
      uploadSessionId
    ) {
      setTimeout(() => onComplete(uploadSessionId), 2000)
    }
  }, [progress, videoConversion, spritesCreation, uploadSessionId])

  const isComplete = videoConversion === 100 && spritesCreation === 100

  const label = getLabel(progress, uploadSessionId)

  const shuffle = progress === 100 && !isComplete
  const shufflePercentage = uploadSessionId === '' ? 100 : 0

  const cancelUploadHandler = useCallback(() => {
    dispatch(deleteSessionRequest(uploadSessionId)).finally(() => {
      setTimeout(() => {
        onClose()
        dispatch(resetUploadProgress())
      }, 2000)
    })
  }, [dispatch, uploadSessionId])

  const {
    buttonNode: deleteButtonNode,
    isButtonDisabled: isDeleteButtonDisabled,
  } = useReduxSagaThunkButton(DELETE_SESSION_REQUEST, {
    initialNode: 'Delete recording',
    pendingNode: 'Deleting...',
    fulfilledNode: 'Deleted',
    rejectedNode: 'An error occurred',
    doneNode: 'Deleted',
  })

  return (
    <Wrapper {...props}>
      <Header>
        <CloseIcon onClick={onClose} />
      </Header>
      <Body>
        <Status>{label}</Status>
        <SteppedProgress>
          <Step>
            <StepProgress percentage={100} />
          </Step>
          <Step>
            <StepProgress
              percentage={uploadSessionId !== '' ? progress : 100}
            />
          </Step>
          <Step>
            <StepProgress shuffle={shuffle} percentage={shufflePercentage} />
          </Step>
        </SteppedProgress>
      </Body>
      <Footer>
        {uploadSessionId !== '' && (
          <Button
            isDanger
            isBasic
            onClick={cancelUploadHandler}
            disabled={isDeleteButtonDisabled}
          >
            {deleteButtonNode}
          </Button>
        )}
      </Footer>
    </Wrapper>
  )
}

UploadModal.propTypes = {
  isComplete: PropTypes.bool,
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
  onStop: PropTypes.func,
  percentage: PropTypes.number,
}

UploadModal.defaultProps = {
  isComplete: false,
  onComplete: () => {},
  onClose: () => {},
  onStop: () => {},
  percentage: 0,
}

export default UploadModal
