import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import fromComments from '../comments/comments.selectors'
import fromUsers from '../users/users.selectors'

const useReply = (commentId, replyId) => {
  const comment = useSelector(fromComments.getComment(commentId))

  const [reply, setReply] = useState(null)
  useEffect(() => {
    const _reply = get(comment, 'replies', []).find(({ id }) => id === replyId)
    if (!isEqual(_reply, reply)) {
      setReply(_reply)
    }
  }, [comment, replyId])

  const replyUserId = get(reply, 'createdBy', null)
  const replyUser = useSelector(fromUsers.getUserById(replyUserId))

  return {
    comment,
    reply,
    replyUser,
  }
}

export default useReply
