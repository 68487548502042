import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
import { isIOS } from '../../../lib/platform'
import convertStyle from './convertStyle'

const Wrapper = styled(animated.div)`
  display: flex;
  align-content: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-grow: 0;
`

export const getWindowHeight = () =>
  isIOS()
    ? window.innerHeight
    : document?.documentElement?.clientHeight ?? window.innerHeight

const Div100vh = ({ innerRef, ...props }) => {
  const windowHeight = window.innerHeight
  const [_style, setStyle] = useState({ height: `${getWindowHeight()}px` })

  const updateStyle = () => {
    const _windowHeight = getWindowHeight()
    const convertedStyle = convertStyle({}, _windowHeight)
    setStyle(convertedStyle)
  }

  useLayoutEffect(() => {
    let interval
    let isResized = false
    if (isIOS()) {
      interval = setInterval(() => {
        if (!isResized && window.innerHeight < windowHeight) {
          updateStyle()
          isResized = true
        }
        if (isResized && window.innerHeight === windowHeight) {
          updateStyle()
          isResized = false
        }
      }, 50)
    } else {
      window.addEventListener('resize', updateStyle)
    }

    return () => {
      if (isIOS()) {
        clearInterval(interval)
      } else {
        window.addEventListener('resize', updateStyle)
      }
    }
  }, [])

  return <Wrapper ref={innerRef} {...props} style={_style} />
}

Div100vh.propTypes = {
  innerRef: PropTypes.shape({}),
}

Div100vh.defaultProps = {
  innerRef: null,
}

export default Div100vh
