import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { Field, Label, Toggle, Input } from '@zendeskgarden/react-forms'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import Flex from '../../atoms/Flex'
import MobileTopBar from '../MobileTopBar'
import MobileModal from '../MobileModal'
import IconButton from '../IconButton'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import { SHARE_TYPES } from './ProjectShare.constants'
import ProjectShareTypeSelection from './ProjectShareTypeSelection'
import ProjectOwner from './ProjectOwner'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const Body = styled(Flex)`
  padding: 16px;
`

const EmailTextField = styled(Field)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const ShareableLinkTextField = EmailTextField

const CopyButton = styled(IconButton)`
  order: 0;
  flex: 0 1 116px;
  align-self: auto;
  margin-left: 16px;
  height: 40px;
`

const NormalLabel = styled(Flex)`
  display: inline-block;
  font-weight: normal;
  margin-bottom: 6px;
  flex-direction: row;

  b {
    margin-right: 4px;
  }
`

const Section = styled(Flex)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const NormalText = styled.span`
  font-weight: normal;
`

const ShareModal = ({
  areCommentsAllowedState,
  copyButtonText,
  enrichedUsers,
  isCurrentUserOwner,
  isFormLoading,
  isLogged,
  isModalVisible,
  isPersonalWorkspace,
  isPublicState,
  isShareButtonEnabled,
  isUserContributor,
  onAddUser,
  onModalClose,
  onRemoveUser,
  remoteTestId,
  session,
  setCommentsAllowed,
  setCopyButtonText,
  setIsPublic,
  setShareValue,
  shareableLink,
  title,
  totalContributors,
  totalWorkspaceMembers,
  updateSessionSharing,
  workspaceName,
  shareType,
  projectId,
  onUpdateProjectShareTypeHandler,
  projectOwnerUser,
}) => {
  const [currentShareType, setCurrentShareType] = useState(shareType)
  useEffect(() => {
    if (currentShareType) {
      onUpdateProjectShareTypeHandler(currentShareType)
    }
  }, [currentShareType])

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        rightIcon={CloseIcon}
        onRightButtonClick={onModalClose}
      >
        Share project&nbsp;<NormalText>{title}</NormalText>
      </StyledMobileTopBar>
    ),
    [projectId, title, onModalClose]
  )

  const body = useMemo(
    () => (
      <Body>
        <Flex>
          {!remoteTestId && (
            <Section>
              <Field>
                <NormalLabel>
                  {currentShareType === SHARE_TYPES.anyone && (
                    <>
                      <b>Anyone at {workspaceName}</b>{' '}
                      <span>can open the project with this link</span>
                    </>
                  )}
                  {currentShareType === SHARE_TYPES.selectedMembers && (
                    <>
                      <b>
                        Only {totalContributors} members of {workspaceName}
                      </b>{' '}
                      <span>can open with this link</span>
                    </>
                  )}
                </NormalLabel>
              </Field>
              <Flex direction="row">
                <ShareableLinkTextField>
                  <Input type="text" defaultValue={shareableLink} />
                </ShareableLinkTextField>
                <CopyButton
                  isPrimary
                  icon={ContentCopyIcon}
                  iconProps={{
                    size: 20,
                  }}
                  onClick={() => {
                    copy(shareableLink)
                    setCopyButtonText('Copied')
                    setTimeout(() => {
                      if (copyButtonText) {
                        setCopyButtonText('Copy')
                      }
                    }, 2000)
                  }}
                >
                  {copyButtonText}
                </CopyButton>
              </Flex>
            </Section>
          )}
          {isLogged && isUserContributor && session && (
            <Grid style={{ marginBottom: '16px' }}>
              <Row style={{ padding: '8px 0' }}>
                <Col>
                  <Field>
                    <Toggle
                      checked={isPublicState}
                      onChange={event => {
                        const value = event.target.checked
                        setIsPublic(value)
                        let commentsAllowed = areCommentsAllowedState
                        if (!value) {
                          commentsAllowed = false
                          setCommentsAllowed(commentsAllowed)
                        }
                        updateSessionSharing({
                          isPublic: value,
                          areCommentsAllowed: commentsAllowed,
                        })
                      }}
                    >
                      <Label regular>Public</Label>
                    </Toggle>
                  </Field>
                </Col>
              </Row>

              <Row style={{ padding: '8px 0' }}>
                <Col>
                  <Field>
                    <Toggle
                      disabled={!isPublicState}
                      checked={areCommentsAllowedState}
                      onChange={event => {
                        setCommentsAllowed(event.target.checked)
                        updateSessionSharing({
                          isPublic: isPublicState,
                          areCommentsAllowed: event.target.checked,
                        })
                      }}
                    >
                      <Label regular>Allow comments</Label>
                    </Toggle>
                  </Field>
                </Col>
              </Row>
            </Grid>
          )}
        </Flex>
        {isCurrentUserOwner && !isPersonalWorkspace && (
          <ProjectShareTypeSelection
            currentShareType={currentShareType}
            enrichedUsers={enrichedUsers}
            isInviteButtonDisabled={!isShareButtonEnabled || isFormLoading}
            onAddUser={onAddUser}
            onRemoveUser={onRemoveUser}
            setCurrentShareType={setCurrentShareType}
            setEmailShareValue={setShareValue}
            showUserBox={
              isLogged &&
              (isUserContributor || isCurrentUserOwner) &&
              !isPersonalWorkspace
            }
            totalContributors={totalContributors}
            totalWorkspaceMembers={totalWorkspaceMembers}
            workspaceName={workspaceName}
          />
        )}
        {projectOwnerUser && currentShareType === SHARE_TYPES.anyone && (
          <>
            <Field style={{ marginTop: '8px' }}>
              <NormalLabel>
                Only the owner can change sharing settings
              </NormalLabel>
            </Field>
            <ProjectOwner
              name={projectOwnerUser.name}
              email={projectOwnerUser.email}
              picture={projectOwnerUser.picture}
              isOwner
            />
          </>
        )}
      </Body>
    ),
    [
      areCommentsAllowedState,
      copyButtonText,
      enrichedUsers,
      isCurrentUserOwner,
      isFormLoading,
      isLogged,
      isModalVisible,
      isPublicState,
      isUserContributor,
      onModalClose,
      session,
      setCommentsAllowed,
      setCopyButtonText,
      setIsPublic,
      setShareValue,
      shareableLink,
      title,
      updateSessionSharing,
    ]
  )

  return (
    isModalVisible && (
      <MobileModal>
        <MobileTemplate header={header} body={body} footer={null} />
      </MobileModal>
    )
  )
}

ShareModal.propTypes = {
  remoteTestId: PropTypes.string,
  projectId: PropTypes.string,
  areCommentsAllowedState: PropTypes.bool.isRequired,
  copyButtonText: PropTypes.string.isRequired,
  enrichedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCurrentUserOwner: PropTypes.bool.isRequired,
  isFormLoading: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  isPersonalWorkspace: PropTypes.bool.isRequired,
  isPublicState: PropTypes.bool.isRequired,
  isShareButtonEnabled: PropTypes.bool.isRequired,
  isUserContributor: PropTypes.bool.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  setCommentsAllowed: PropTypes.func.isRequired,
  setCopyButtonText: PropTypes.func.isRequired,
  setIsPublic: PropTypes.func.isRequired,
  setShareValue: PropTypes.func.isRequired,
  shareableLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  totalContributors: PropTypes.number.isRequired,
  totalWorkspaceMembers: PropTypes.number.isRequired,
  updateSessionSharing: PropTypes.func.isRequired,
  workspaceName: PropTypes.string,
  shareType: PropTypes.oneOf(Object.values(SHARE_TYPES)),
  onUpdateProjectShareTypeHandler: PropTypes.func,
  projectOwnerUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
  }),
}

ShareModal.defaultProps = {
  remoteTestId: '',
  projectId: '',
  workspaceName: '',
  shareType: null,
  onUpdateProjectShareTypeHandler: () => {},
  projectOwnerUser: {},
}

export default ShareModal
