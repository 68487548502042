import { buildReducerForActions } from '../../util/reduxTools'
import {
  CREATE_PENDING_UPLOADS,
  DELETE_PENDING_UPLOADS,
  GET_PENDING_UPLOADS,
  RESET_PENDING_UPLOADS,
  SET_PENDING_UPLOADS,
} from './upload.actions'
import { UPLOAD_STATE_WAITING } from './upload.constants'

export const STATE_KEY = 'uploads'

export const INITIAL_STATE = {}

const reducer = {
  [CREATE_PENDING_UPLOADS]: (state, { payload: { sessionId, obj } }) => {
    const curr = { ...state }
    curr[sessionId] = {
      status: UPLOAD_STATE_WAITING,
      name: '',
      size: 0,
      uploaded: 0,
      speed: 0,
      ...obj,
    }
    return curr
  },
  [DELETE_PENDING_UPLOADS]: (state, { payload: { sessionId } }) => {
    const curr = { ...state }
    delete curr[sessionId]
    return {
      ...curr,
    }
  },
  [SET_PENDING_UPLOADS]: (state, { payload: { sessionId, obj } }) => {
    const curr = { ...state }
    curr[sessionId] = { ...curr[sessionId], ...obj }
    return curr
  },
  [RESET_PENDING_UPLOADS]: () => ({}),
  [GET_PENDING_UPLOADS]: state => ({ ...state }),
}

export default buildReducerForActions(INITIAL_STATE, reducer)
