import { set } from 'dot-prop-immutable'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { buildReducerForActions } from '../../util/reduxTools'
import {
  SET_CURRENT_REMOTE_TEST,
  READ_ALL_REMOTE_TEST_REQUEST_SUCCESS,
  UPDATE_REMOTE_TEST_UPLOAD_PROGRESS,
} from './remoteTests.actions'
import { LOGOUT_REQUEST_SUCCESS } from '../actions'

export const STATE_KEY = 'remoteTests'

export const INITIAL_STATE = {
  current: '',
  byId: {},
  allIds: [],
  publicUploads: {},
}

const setRemoteTests = (
  state,
  { payload: { remoteTests = [], projectId } }
) => {
  const byId = filter(state.byId, x => x.projectId !== projectId).reduce(
    (acc, session) => {
      acc[session.pk] = session
      return acc
    },
    {}
  )
  const allIds = map(byId, x => x.pk)

  const baseState = {
    byId,
    allIds,
  }
  return remoteTests.reduce((acc, remoteTest) => {
    acc.byId[remoteTest.pk] = remoteTest
    if (!acc.allIds.includes(remoteTest.pk)) {
      acc.allIds.push(remoteTest.pk)
    }
    return acc
  }, baseState)
}

const reducer = {
  [SET_CURRENT_REMOTE_TEST]: (state, { payload: { remoteTestId } }) =>
    set(state, 'current', remoteTestId),
  [READ_ALL_REMOTE_TEST_REQUEST_SUCCESS]: setRemoteTests,
  [UPDATE_REMOTE_TEST_UPLOAD_PROGRESS]: (
    state,
    { payload: { progress, remoteTestId } }
  ) => set(state, `publicUploads.${remoteTestId}.progress`, progress),
  [LOGOUT_REQUEST_SUCCESS]: () => INITIAL_STATE,
}

export default buildReducerForActions(INITIAL_STATE, reducer)
