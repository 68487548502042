import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import { Formik, Form, ErrorMessage } from 'formik'
import { StyledErrorMessage } from '../../../commonStyledComponents'
import Flex from '../../../atoms/Flex'

const Wrapper = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
`

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const Title = styled.h3`
  margin-bottom: 8px;
`

const Description = styled.span`
  font-style: italic;
  color: ${prop('theme.colors.grey300')};
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledInput = styled(Input)`
  width: 100%;
`

const FullNameTextField = styled(Field)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin-bottom: 16px;
  && {
    width: 100%;
  }
`

const NextButton = styled(Button)`
  && {
    margin-top: 16px;
  }
`

const validateFormValues = values => {
  const errors = {}
  if (!values.fullName) {
    errors.fullName = 'The name is required'
  }
  return errors
}

const FullName = ({ userName, pictureUrl, onNext, ...props }) => {
  const [avatarName, setAvatarName] = useState(userName)

  const formInitialValues = {
    fullName: userName,
  }

  return (
    <Wrapper {...props}>
      <CenteredBox>
        <Title>What&apos;s your name?</Title>
        <Description>This will be displayed to your teammates</Description>
      </CenteredBox>
      <CenteredBox direction="row">
        <Formik
          isInitialValid={() => !!userName}
          initialValues={formInitialValues}
          validate={validateFormValues}
          onSubmit={values => {
            if (values.fullName) {
              onNext(avatarName)
            }
          }}
          render={({ values, errors, handleBlur, handleChange, isValid }) => (
            <StyledForm>
              <FullNameTextField>
                <StyledInput
                  type="text"
                  name="fullName"
                  onChange={e => {
                    setAvatarName(e.target.value || '')
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.fullName}
                  validation={errors.fullName ? 'warning' : undefined}
                  placeholder="John Doe"
                  data-variant="green"
                />
                <ErrorMessage component={StyledErrorMessage} name="fullName" />
              </FullNameTextField>
              <NextButton isPrimary type="submit" disabled={!isValid}>
                Continue
              </NextButton>
            </StyledForm>
          )}
        />
      </CenteredBox>
    </Wrapper>
  )
}

FullName.propTypes = {
  userName: PropTypes.string,
  pictureUrl: PropTypes.string,
  onNext: PropTypes.func,
}

FullName.defaultProps = {
  userName: '',
  pictureUrl: '',
  onNext: () => {},
}

export default FullName
