import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { Button } from '@zendeskgarden/react-buttons'
import copy from 'copy-to-clipboard'
import isEmpty from 'lodash/isEmpty'
import Flex from '../../atoms/Flex'
import useCRUDComment from '../../../store/hooks/useCRUDComment'
import useCRUDReply from '../../../store/hooks/useCRUDReply'
import useUserInfo from '../../../store/hooks/useUserInfo'
import { getCommentUrl, getReplyUrl } from '../../../lib/urls'
import CommentEditableText from './CommentEditableText'
import SingleComment from './SingleComment'
import CommentReply from './CommentReply'
import CommentEvent from './CommentEvent'
import useCanAddCommentsToSession from '../../hooks/useCanAddCommentsToSession'
import useScrollIntoView from '../../hooks/useScrollIntoView'
import { ternary } from '../../../util/utils'
import { CommentShape } from '../../propTypes'
import Loader from '../../atoms/Loader'
import theme from '../../theme/old'

const cardHighlightedCss = css`
  ${prop('theme.boxShadowSelected')};
  border: 1px solid ${prop('theme.colors.yellow')};
`

const cardBlurredCss = css`
  border: 1px solid ${prop('theme.colors.grey200')};
`

const Wrapper = styled(Flex)`
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  ${prop('theme.boxShadows.elevation3')};
  background-color: white;
  cursor: pointer;

  ${({ isHighlighted }) => (isHighlighted ? cardHighlightedCss : '')}
  ${({ isBlurred }) => (isBlurred ? cardBlurredCss : '')}
`

const StyledSingleComment = styled(SingleComment)`
  margin-bottom: ${ternary('hasReplies')('16px', '0')};
`

const SubmitBox = styled(Flex)`
  padding: 0;
  margin-top: 8px;
`

const SubmitButton = styled(Button)`
  && {
    width: auto;
    align-self: flex-end;
  }
`

const StyledCommentEditableText = styled(CommentEditableText)`
  width: 100%;
  margin-top: ${ternary('isCreateNewComment')('0', '16px')};
`

const CommentDialog = ({
  autoFocusToken,
  comment,
  isHighlighted,
  isBlurred,
  isCreateNewComment,
  isCommentCopyLinkEnabled,
  isJiraEnabled,
  isTrelloEnabled,
  isSlackEnabled,
  isResolved,
  tagText,
  videoCurrentTime,
  videoPoint,
  videoRectangle,
  onCommentCreate,
  onCommentDelete,
  onCommentEdit,
  onCommentClick,
  onReplyFocus,
  onReplyEdit,
  onCopyLink,
  onCreateJiraIssue,
  onCreateTrelloCard,
  onSendSlackMessage,
  onResolveCommentClick,
  currentUserName,
  showJira,
  showTrello,
  showSlack,
  isInlineEdit,
  isCommentInEditMode,
  replyIdInEditMode,
  ...props
}) => {
  const commentId = comment?.pk
  const [isResolvedState, setResolvedState] = useState(isResolved)
  useEffect(() => {
    setResolvedState(isResolved)
  }, [isResolved])

  const { userIdentityId } = useUserInfo()
  const canAddComments = useCanAddCommentsToSession()
  const {
    commentUser,
    onCreateCommentHandler,
    onUpdateCommentHandler,
    onDeleteCommentHandler,
    isCreateCommentPending,
    isUpdateCommentPending,
    isDeleteCommentPending,
  } = useCRUDComment(commentId)

  const { onCreateReplyHandler, isCreateReplyLoading } = useCRUDReply(commentId)
  const [newReactionMentions, setNewReactionMentions] = useState([])
  const [currentMentions, setCurrentMentions] = useState(
    comment?.mentions || []
  )
  useEffect(() => setCurrentMentions(comment?.mentions || []), [
    comment?.mentions,
  ])

  const placeholder = !commentId
    ? 'Leave a feedback'
    : 'Reply or mention others with @'

  const [newReactionText, setNewReactionText] = useState('')
  const [commentResetToken, setCommentResetToken] = useState('')

  const onCreateCommentSubmit = useCallback(() => {
    onCreateCommentHandler(
      newReactionText,
      videoCurrentTime,
      videoPoint,
      videoRectangle,
      newReactionMentions
    ).then(() => {
      onCommentCreate()
      setCommentResetToken(Math.random().toString())
      setNewReactionMentions([])
    })
  }, [
    newReactionText,
    newReactionMentions,
    onCommentCreate,
    onCreateCommentHandler,
    setCommentResetToken,
    setNewReactionMentions,
    videoCurrentTime,
    videoPoint,
    videoRectangle,
  ])

  const commentEvents = (comment?.replies || [])
    .concat(comment?.resolutions || [])
    .sort((a, b) => a.createdAt - b.createdAt)

  const commentRef = useRef(null)
  useScrollIntoView(commentRef, isHighlighted)

  return (
    <Wrapper
      isHighlighted={isHighlighted}
      isBlurred={isBlurred}
      onClick={() => onCommentClick({ videoPositionSecs: videoCurrentTime })}
      ref={commentRef}
      {...props}
    >
      {!isEmpty(comment) && (
        <StyledSingleComment
          isInlineEdit={isInlineEdit}
          isEditMode={isCommentInEditMode}
          hasReplies={commentEvents?.length > 0}
          isCopyLinkEnabled={isCommentCopyLinkEnabled}
          isJiraEnabled={isJiraEnabled}
          isTrelloEnabled={isTrelloEnabled}
          isSlackEnabled={isSlackEnabled}
          isResolved={isResolvedState}
          mentions={currentMentions}
          userName={commentUser?.name || comment?.owner?.name}
          pictureUrl={commentUser?.picture || comment?.owner?.picture}
          text={comment?.text}
          canEdit={userIdentityId === comment?.createdBy}
          createdAt={comment?.createdAt}
          isReply={false}
          isSelected={isHighlighted}
          isBlurred={isBlurred}
          tagText={tagText}
          showJira={showJira}
          showTrello={showTrello}
          showSlack={showSlack}
          isLoading={
            isCreateCommentPending ||
            isUpdateCommentPending ||
            isDeleteCommentPending
          }
          onUpdate={(text, mentions) =>
            onUpdateCommentHandler(
              commentId,
              text,
              comment?.videoPositionSecs,
              mentions
            )
          }
          onEdit={() => onCommentEdit(commentId)}
          onDelete={() => {
            onDeleteCommentHandler(commentId)
            onCommentDelete()
          }}
          onCopyLink={() => {
            copy(getCommentUrl(commentId))
            onCopyLink()
          }}
          onCreateJiraIssue={() => {
            onCreateJiraIssue({
              description: comment?.text,
              resourceId: commentId,
              resourceUrl: getCommentUrl(commentId),
            })
          }}
          onCreateTrelloCard={() => {
            onCreateTrelloCard({
              description: comment?.text,
              commentId: comment?.pk,
            })
          }}
          onSendSlackMessage={() => {
            onSendSlackMessage({
              commentId: comment?.pk,
            })
          }}
          onResolveClick={resolveValue => {
            // workaround to understand if the user is logged
            if (currentUserName) {
              setResolvedState(resolveValue)
            }
            onResolveCommentClick({
              commentId: comment?.pk,
              isResolved: resolveValue,
            })
          }}
        />
      )}
      {commentEvents.map((event, index) => {
        const isReply = event.__typename === 'Reply'
        if (isReply) {
          const reply = event
          const isEditMode = replyIdInEditMode === reply.id

          return (
            <CommentReply
              key={reply.id}
              commentId={commentId}
              replyId={reply.id}
              isEditMode={isEditMode}
              mentions={reply.mentions}
              showSlack={showSlack}
              onCopyLink={() => onCopyLink()}
              isInlineEdit={isInlineEdit}
              onEdit={() => onReplyEdit(commentId, reply.id)}
              onCreateJiraIssue={() => {
                onCreateJiraIssue({
                  description: reply?.text,
                  resourceId: reply.id,
                  resourceUrl: getReplyUrl(commentId, reply.id),
                })
              }}
              onCreateTrelloCard={() => {
                onCreateTrelloCard({
                  description: reply?.text,
                  commentId: reply.id,
                })
              }}
              onSendSlackMessage={() => {
                onSendSlackMessage({
                  commentId: comment?.pk,
                  replyId: reply.id,
                })
              }}
              data-test="comment-reply"
              style={{
                marginBottom: index === commentEvents.length - 1 ? '0' : '16px',
              }}
            />
          )
        }

        const isResolutionEvent = event.__typename === 'ResolutionEvent'
        if (isResolutionEvent) {
          const resolutionEvent = event
          return (
            <CommentEvent
              key={`resolution-${resolutionEvent.status}-${resolutionEvent.createdAt}`}
              commentId={commentId}
              resolutionCreatedAt={resolutionEvent.createdAt}
              resolutionStatus={resolutionEvent.status}
              data-test="comment-resolution-event"
              style={{
                marginBottom: index === commentEvents.length - 1 ? '0' : '16px',
              }}
            />
          )
        }

        return null
      })}
      {canAddComments && (
        <StyledCommentEditableText
          style={{
            display: isHighlighted || isCreateNewComment ? 'flex' : 'none',
          }}
          isCreateNewComment={isCreateNewComment}
          mentions={newReactionMentions}
          placeholder={placeholder}
          onChange={setNewReactionText}
          onAddedMention={newMention => {
            setNewReactionMentions([...newReactionMentions, newMention])
          }}
          onRemovedMention={removedMention => {
            setNewReactionMentions([
              ...newReactionMentions.filter(
                ({ pk }) => pk !== removedMention.pk
              ),
            ])
          }}
          resetToken={commentResetToken}
          autoFocusToken={autoFocusToken}
          data-test="comment-dialog-editable-text"
          onFocus={onReplyFocus}
        />
      )}
      {newReactionText.trim() !== '' && (isHighlighted || isCreateNewComment) && (
        <SubmitBox>
          <SubmitButton
            isPrimary
            isStretched
            size="small"
            disabled={isCreateReplyLoading || isCreateCommentPending}
            onClick={() => {
              if (!comment) {
                onCreateCommentSubmit()
              }

              if (comment) {
                onCreateReplyHandler(newReactionText, newReactionMentions).then(
                  () => {
                    setCommentResetToken(Math.random().toString())
                    setNewReactionMentions([])
                  }
                )
              }
            }}
            data-test="add-comment-button"
          >
            {isCreateCommentPending ? (
              <Loader color={theme.colors.grey} size={16} />
            ) : (
              'Add comment'
            )}
          </SubmitButton>
        </SubmitBox>
      )}
    </Wrapper>
  )
}

const VideoPoint = PropTypes.shape({
  absolute: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  percentage: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
})

const VideoRectanglePoint = PropTypes.shape({
  topLeft: VideoPoint,
  bottomRight: VideoPoint,
})

CommentDialog.propTypes = {
  autoFocusToken: PropTypes.string,
  tagText: PropTypes.string,
  currentUserName: PropTypes.string,
  comment: CommentShape,
  isHighlighted: PropTypes.bool,
  isBlurred: PropTypes.bool,
  isCreateNewComment: PropTypes.bool,
  isCommentCopyLinkEnabled: PropTypes.bool,
  isInlineEdit: PropTypes.bool,
  isJiraEnabled: PropTypes.bool,
  isTrelloEnabled: PropTypes.bool,
  isSlackEnabled: PropTypes.bool,
  isResolved: PropTypes.bool,
  videoCurrentTime: PropTypes.number,
  videoPoint: VideoPoint,
  videoRectangle: VideoRectanglePoint,
  onCommentCreate: PropTypes.func,
  onCommentClick: PropTypes.func,
  onCommentDelete: PropTypes.func,
  onCommentEdit: PropTypes.func,
  onReplyFocus: PropTypes.func,
  onReplyEdit: PropTypes.func,
  onCopyLink: PropTypes.func,
  onCreateJiraIssue: PropTypes.func,
  onCreateTrelloCard: PropTypes.func,
  onSendSlackMessage: PropTypes.func,
  onResolveCommentClick: PropTypes.func,
  showJira: PropTypes.bool,
  showTrello: PropTypes.bool,
  showSlack: PropTypes.bool,
  isCommentInEditMode: PropTypes.bool,
  replyIdInEditMode: PropTypes.string,
}

CommentDialog.defaultProps = {
  autoFocusToken: '',
  tagText: null,
  currentUserName: null,
  comment: null,
  isHighlighted: false,
  isInlineEdit: true,
  isBlurred: false,
  isCreateNewComment: false,
  isCommentCopyLinkEnabled: true,
  isJiraEnabled: false,
  isTrelloEnabled: false,
  isSlackEnabled: false,
  isResolved: false,
  videoCurrentTime: null,
  videoPoint: null,
  videoRectangle: null,
  onCommentCreate: () => {},
  onCommentClick: () => {},
  onCommentDelete: () => {},
  onCommentEdit: () => {},
  onReplyFocus: () => {},
  onReplyEdit: () => {},
  onCopyLink: () => {},
  onCreateJiraIssue: () => {},
  onCreateTrelloCard: () => {},
  onSendSlackMessage: () => {},
  onResolveCommentClick: () => {},
  showJira: true,
  showTrello: true,
  showSlack: true,
  isCommentInEditMode: false,
  replyIdInEditMode: null,
}

CommentDialog.whyDidYouRender = false
export default React.memo(CommentDialog)
