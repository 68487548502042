import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import Flex from '../../atoms/Flex'
import { ternary } from '../../../util/utils'
import useScrollIntoView from '../../hooks/useScrollIntoView'
import { PAGE_EVENT_TYPES } from './pageEvents.constants'
import PageEventIcon from './PageEventIcon'

const getColor = ({ eventType, theme }) => {
  if (
    eventType === PAGE_EVENT_TYPES.consoleError ||
    eventType === PAGE_EVENT_TYPES.error ||
    eventType === PAGE_EVENT_TYPES.webErrorRequest
  ) {
    return theme.colors.warning
  }

  return 'inherit'
}

const getBackgroundColor = ({ eventType, theme }) => {
  if (
    eventType === PAGE_EVENT_TYPES.consoleError ||
    eventType === PAGE_EVENT_TYPES.error ||
    eventType === PAGE_EVENT_TYPES.webErrorRequest
  ) {
    return theme.colors.warning
  }

  if (eventType === PAGE_EVENT_TYPES.consoleWarn) {
    return theme.colors.yellow600
  }

  return theme.colors.primary
}

const DotBox = styled(Flex)`
  margin: 0;
  height: 24px;
  align-items: center;
  justify-content: center;
`

const Dot = styled(Flex)`
  width: 8px;
  height: 8px;
  background-color: ${prop('theme.colors.grey')};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
`

const Wrapper = styled(Flex)`
  flex-direction: row;
  width: 100%;
  min-width: 24px;
  word-break: break-all;
  color: ${getColor};

  svg {
    fill: ${getBackgroundColor} !important;
  }

  ${Dot} {
    background-color: ${getBackgroundColor};
  }
`

const IconBox = styled(Flex)`
  margin: 0 12px;
`

const TextBox = styled(Flex)`
  flex-grow: 1;
  display: block;
  min-height: 24px;
  line-height: 24px;
  font-weight: ${ternary('isCurrent')('600', 'normal')};
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 1px dashed ${prop('theme.colors.grey200')};
`

const PageEvent = ({ eventType, isCurrent, textNode, ...props }) => {
  const ref = useRef(null)
  useScrollIntoView(ref, isCurrent)
  return (
    <Wrapper {...props} eventType={eventType} ref={ref}>
      <DotBox>
        <Dot style={{ opacity: isCurrent ? '1' : '0' }} />
      </DotBox>
      <IconBox>
        <PageEventIcon eventType={eventType} isCurrent={isCurrent} />
      </IconBox>
      <TextBox isCurrent={isCurrent}>{textNode}</TextBox>
    </Wrapper>
  )
}

PageEvent.propTypes = {
  eventType: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
  textNode: PropTypes.element,
}

PageEvent.defaultProps = {
  isCurrent: false,
  textNode: null,
}

export default PageEvent
