import React from 'react'
import PropTypes from 'prop-types'
import useLocalStorage from '../../hooks/useLocalStorage'
import PageEventsFilter from './PageEventsFilter'

const PageEventsFilterContainer = ({ onFilterUpdate, ...props }) => {
  const [selectedFilters, setSelectedFilters] = useLocalStorage(
    'page-events-filter-items'
  )
  return (
    <PageEventsFilter
      {...props}
      selectedItems={selectedFilters}
      onFilterUpdate={items => {
        setSelectedFilters(items)
        onFilterUpdate(items)
      }}
    />
  )
}

PageEventsFilterContainer.propTypes = {
  onFilterUpdate: PropTypes.func,
}

PageEventsFilterContainer.defaultProps = {
  onFilterUpdate: () => {},
}

export default PageEventsFilterContainer
