import path from 'path'
import get from 'lodash/get'
import {
  take,
  takeLatest,
  all,
  select,
  fork,
  call,
  delay,
  put,
} from 'redux-saga/effects'
import {
  DESKTOP_INIT,
  DESKTOP_START_RECORDING_REQUEST,
  DESKTOP_STOP_RECORDING,
  DESKTOP_PULL_RECORDING_REQUEST,
  DESKTOP_PULL_RECORDING_REQUEST_SUCCESS,
  updateDevices,
  startRecordingRequestSuccess,
  startRecordingRequestFailure,
  pullRecordingRequestSuccess,
  pullRecordingRequestFailure,
  pullRecordingRequest,
} from './actions'
import fromDesktop from './selectors'
import adbClient from '../../lib/adbClient'
import iosRecorderClient from '../../lib/iosRecorderClient'
import video from '../../lib/video'

function* getDevices() {
  try {
    const isIOSRecorderRunning = yield call(iosRecorderClient.isRecorderRunning)
    const isQuickTimeRunning = yield call(iosRecorderClient.isQuickTimeRunning)

    let iosRecorderStartedThisSession = false
    let iosDevices = []
    if (!isIOSRecorderRunning && isQuickTimeRunning) {
      yield call(iosRecorderClient.startRecorder)
      iosRecorderStartedThisSession = true
    } else if (isIOSRecorderRunning && isQuickTimeRunning) {
      iosDevices = yield call(iosRecorderClient.listDevices)
      if (iosDevices.length === 0) {
        yield call(iosRecorderClient.killRecorder)
      }
    } else if (!isQuickTimeRunning) {
      yield call(iosRecorderClient.killRecorder)
      yield call(iosRecorderClient.openQuickTime)
    }

    if (
      isQuickTimeRunning &&
      iosDevices.length === 0 &&
      !iosRecorderStartedThisSession
    ) {
      yield call(iosRecorderClient.killRecorder)
    }

    const androidDevices = yield call(adbClient.listDevices)
    yield put(updateDevices(androidDevices.concat(iosDevices)))
  } catch (err) {
    console.error(err)
  }
}

function* watchForDevices() {
  while (true) {
    try {
      yield call(getDevices)
    } catch (err) {
      console.error(err)
    }
    yield delay(5000)
  }
}

function* startRecording({ meta: { thunk } }) {
  try {
    const deviceId = yield select(fromDesktop.getCurrentDevice())
    const deviceInfo = yield select(fromDesktop.getCurrentDeviceInfo)
    const deviceType = get(deviceInfo, 'type')
    let worker
    let targetFolder
    if (deviceType === 'ios') {
      targetFolder = path.join(iosRecorderClient.getAppPath(), 'recordings')
      yield call(iosRecorderClient.startRecording, deviceId, targetFolder)
    }
    if (deviceType === 'android') {
      worker = yield call(adbClient.startRecording, deviceId)
    }
    yield put(startRecordingRequestSuccess({}, thunk))

    while (true) {
      yield take(DESKTOP_STOP_RECORDING)
      if (deviceType === 'ios') {
        yield call(iosRecorderClient.stopRecording, deviceId)
      }
      if (deviceType === 'android') {
        yield call(adbClient.stopRecording, worker)
        yield delay(1000)
      }
      yield put(pullRecordingRequest({ iosTargetFolder: targetFolder }))
      break
    }
  } catch (err) {
    yield put(startRecordingRequestFailure(err, thunk))
  }
}

function* pullRecording({ payload: { iosTargetFolder }, meta: { thunk } }) {
  try {
    const deviceId = yield select(fromDesktop.getCurrentDevice())
    const deviceInfo = yield select(fromDesktop.getCurrentDeviceInfo)
    const deviceType = get(deviceInfo, 'type')
    let videoPath
    if (deviceType === 'ios') {
      videoPath = yield call(
        iosRecorderClient.getLastRecordingPath,
        iosTargetFolder
      )
    }

    if (deviceType === 'android') {
      const targetFolder = path.join(adbClient.getAppPath(), 'recordings')
      videoPath = yield call(adbClient.pullRecording, deviceId, targetFolder)
    }

    // optimistic waiting for the file to be pulled to the computer
    yield delay(3000)

    const durationInSecs = yield call(video.getVideoDuration, videoPath)
    const videoWidth = yield call(video.getVideoWidth, videoPath)
    const videoHeight = yield call(video.getVideoHeight, videoPath)

    yield put(
      pullRecordingRequestSuccess(
        {
          path: videoPath,
          duration: durationInSecs,
          durationUnit: 'seconds',
          videoWidth,
          videoHeight,
        },
        thunk
      )
    )
  } catch (err) {
    yield put(pullRecordingRequestFailure(err, thunk))
  }
}

function* onPullRecordingSuccess() {
  try {
    yield delay(1000)
    yield call(iosRecorderClient.killRecorder)
  } catch (err) {
    console.error(err)
  }
}

function* initDesktop() {
  yield fork(watchForDevices)
}

export default function* desktopSagas() {
  yield all([
    yield takeLatest(DESKTOP_INIT, initDesktop),
    yield takeLatest(DESKTOP_START_RECORDING_REQUEST, startRecording),
    yield takeLatest(DESKTOP_PULL_RECORDING_REQUEST, pullRecording),
    yield takeLatest(
      DESKTOP_PULL_RECORDING_REQUEST_SUCCESS,
      onPullRecordingSuccess
    ),
  ])
}
