import React, { useRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import FolderIcon from 'mdi-react/FolderIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import TrashIcon from 'mdi-react/TrashIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import Flex from '../../atoms/Flex'
import SheetList from '../../atoms/SheetList'
import EditTextMobile from '../../molecules/EditTextMobile'
import ConfirmationModalMobile from '../../molecules/ConfirmationModalMobile'
import MobileModal from '../../molecules/MobileModal'
import MobileTopBar from '../../molecules/MobileTopBar'
import UploadButton from '../../molecules/UploadButton'
import UploadModal from '../../molecules/UploadModal'
import WorkspaceUpsellModalMobile from '../../molecules/WorkspaceUpsellModal/WorkspaceUpsellModalMobile'
import ProjectShareModal from '../../molecules/ProjectShareModal'
import SectionTitleMobile from '../../molecules/SectionTitleMobile'
import SessionsListMobile from '../../organisms/SessionsListMobile'
import MobileTemplate from '../../templates/MobileTemplate'
import { BAR_HEIGHT } from '../../templates/MobileTemplate/MobileTemplate'
import CreateOrganizationPage from '../CreateOrganizationPage/CreateOrganizationPage'
import fromProjects from '../../../store/projects/projects.selectors'
import fromOrganizations, {
  PERSONAL_ORGANIZATION_ID,
} from '../../../store/organizations/organizations.selectors'
import useCRUDProject from '../../../store/hooks/useCRUDProject'
import useReduxSagaThunkButton from '../../../store/hooks/useReduxSagaThunkButton'
import useRecordingUpload from '../../hooks/useRecordingUpload'
import useNavigation from '../../hooks/useNavigation'
import theme from '../../theme/old'
import {
  UPDATE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
} from '../../../store/projects/projects.actions'

const StyledMobileTopBar = styled(MobileTopBar)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100vw;
  height: ${BAR_HEIGHT}px;
`

const TitleBox = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
`

const StyledSessionsListMobile = styled(SessionsListMobile)`
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: intrinsic;
  overflow-y: scroll;
`

const StyledUploadButton = styled(UploadButton)`
  padding: 0 8px 8px 8px;
  width: calc(100% - 16px);
  margin-left: 8px;
`

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 8px;
`

const ProjectPageMobile = ({ onBack, isVisible }) => {
  const currentOrganizationId = useSelector(
    fromOrganizations.getCurrentOrganizationId
  )

  const currentProject = useSelector(fromProjects.getCurrentProject)
  const projectId = get(currentProject, 'pk')
  const projectTitle = get(currentProject, 'title')

  const history = useHistory()
  const { goToSession } = useNavigation()

  const inputRef = useRef(null)
  const { handleFiles, onUpload, uploadProgress } = useRecordingUpload(inputRef)

  const { onUpdateProjectHandler, onDeleteProjectHandler } = useCRUDProject(
    projectId
  )

  const [isProjectSheetVisible, setProjectSheetVisible] = useState(false)
  const [isRenameProjectModalVisible, setRenameProjectModalVisible] = useState(
    false
  )
  const [isDeleteProjectModalVisible, setDeleteProjectModalVisible] = useState(
    false
  )
  const [isShareModalVisible, setShareModalVisible] = useState(false)
  const [isUploadModalVisible, setUploadModalVisible] = useState(false)
  const [
    isOrganizationUpsellModalVisible,
    setOrganizationUpsellModalVisible,
  ] = useState(false)
  const [
    isCreateOrganizationModalVisible,
    setCreateOrganizationModalVisible,
  ] = useState(false)

  const header = useMemo(
    () => (
      <StyledMobileTopBar
        icon={ArrowLeftIcon}
        onButtonClick={onBack}
        rightIcon={DotsVerticalIcon}
        onRightButtonClick={() => setProjectSheetVisible(true)}
      >
        <StyledFolderIcon size={16} />
        {projectTitle}
      </StyledMobileTopBar>
    ),
    [projectTitle]
  )

  const handleFilesCallback = () =>
    setTimeout(() => setUploadModalVisible(true), 3000)

  const body = (
    <>
      <TitleBox>
        <SectionTitleMobile>Recordings</SectionTitleMobile>
      </TitleBox>
      <StyledUploadButton
        percentage={uploadProgress}
        onClick={() => {
          onUpload()
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept=".mov,.mp4,.webm"
          multiple={false}
          onChange={handleFiles(handleFilesCallback)}
          style={{ display: 'none' }}
        />
      </StyledUploadButton>
      <StyledSessionsListMobile
        onLoadingSessionClick={() => {
          setUploadModalVisible(true)
        }}
        onSessionClick={({ sessionId }) => {
          goToSession({
            sessionId,
            isNew: false,
          })
        }}
      />
    </>
  )

  const projectSheetItems = [
    {
      text: 'Rename project',
      icon: <PencilIcon />,
      onClick: () => setRenameProjectModalVisible(true),
    },
    {
      text: 'Share project',
      icon: <AccountMultipleIcon />,
      onClick: () => {
        if (currentOrganizationId === PERSONAL_ORGANIZATION_ID) {
          setOrganizationUpsellModalVisible(true)
          return
        }
        setShareModalVisible(true)
      },
    },
    {
      text: 'Delete project',
      icon: <TrashIcon />,
      onClick: () => setDeleteProjectModalVisible(true),
    },
  ]

  const { buttonNode: renameProjectButtonNode } = useReduxSagaThunkButton(
    UPDATE_PROJECT_REQUEST,
    {
      initialNode: 'Rename',
      pendingNode: 'Updating...',
      fulfilledNode: 'Updated!',
      rejectedNode: 'An error occurred',
    }
  )

  const { buttonNode: deleteProjectButtonNode } = useReduxSagaThunkButton(
    DELETE_PROJECT_REQUEST,
    {
      initialNode: 'Delete',
      pendingNode: 'Deleting...',
      fulfilledNode: 'Deleted!',
      rejectedNode: 'An error occurred',
    }
  )

  const renameProjectModal = useMemo(
    () => (
      <EditTextMobile
        isVisible={isRenameProjectModalVisible}
        title="Rename project"
        text={projectTitle}
        buttonNode={renameProjectButtonNode}
        onSubmit={text => {
          onUpdateProjectHandler(text).finally(() =>
            setTimeout(() => setRenameProjectModalVisible(false), 1000)
          )
        }}
        onClose={() => setRenameProjectModalVisible(false)}
      />
    ),
    [isRenameProjectModalVisible, projectTitle, renameProjectButtonNode]
  )

  return (
    <Flex>
      {isVisible && (
        <MobileModal>
          <MobileTemplate
            header={header}
            body={body}
            footer={null}
            bodyStyle={{ backgroundColor: theme.colors.grey100 }}
          />
        </MobileModal>
      )}
      <SheetList
        onClose={() => setProjectSheetVisible(false)}
        isVisible={isProjectSheetVisible}
        items={projectSheetItems}
      />
      {renameProjectModal}
      <ConfirmationModalMobile
        title="Delete folder"
        text="Do you really want to delete the project?"
        submitButtonNode={deleteProjectButtonNode}
        isVisible={isDeleteProjectModalVisible}
        onClose={() => {
          setDeleteProjectModalVisible(false)
        }}
        onSubmit={() =>
          onDeleteProjectHandler(projectId).finally(() =>
            setTimeout(() => {
              // NOTE: workaround to trigger the prop change in ProjectPageMobile
              // TODO: find a better solution
              /*
              changeView({ name: MAIN_VIEWS.entry, props: { showProject: true } })
              changeView({ name: MAIN_VIEWS.entry, props: { showProject: false } })
              */
              history.push('/app')
              setDeleteProjectModalVisible(false)
            }, 2000)
          )
        }
      />
      {isShareModalVisible && (
        <ProjectShareModal
          isVisible={isShareModalVisible}
          projectId={projectId}
          onClose={() => {
            setTimeout(() => setShareModalVisible(false), 100)
          }}
        />
      )}
      {isUploadModalVisible && (
        <UploadModal
          isVisible={isUploadModalVisible}
          onClose={() => {
            setUploadModalVisible(false)
          }}
          onComplete={sessionId => {
            goToSession({
              sessionId,
              isNew: false,
            })
          }}
        />
      )}
      <WorkspaceUpsellModalMobile
        isVisible={isOrganizationUpsellModalVisible}
        onCreate={() => {
          setCreateOrganizationModalVisible(true)
          setOrganizationUpsellModalVisible(false)
        }}
        onClose={() => setOrganizationUpsellModalVisible(false)}
      />
      {isCreateOrganizationModalVisible && (
        <CreateOrganizationPage
          onClose={() => {
            setCreateOrganizationModalVisible(false)
          }}
        />
      )}
    </Flex>
  )
}

ProjectPageMobile.propTypes = {
  isVisible: PropTypes.bool,
  onBack: PropTypes.func,
}

ProjectPageMobile.defaultProps = {
  isVisible: false,
  onBack: () => {},
}

ProjectPageMobile.whyDidYouRender = false

export default withApollo(ProjectPageMobile)
