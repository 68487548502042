import React from 'react'
import styled from 'styled-components'
import Flex from '../../atoms/Flex'
import useUserInfo from '../../../store/hooks/useUserInfo'

const Wrapper = styled(Flex)``

const SettingRow = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  padding: 16px;
`
const Label = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  letter-spacing: 0.05em;
  font-weight: 500;
`
const Edited = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
`
const Email = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: center;
  font-weight: 300;
  letter-spacing: 0.06em;
`

const EmailSetting = ({ ...props }) => {
  const { userEmail } = useUserInfo()
  return (
    <>
      <Wrapper {...props}>
        <SettingRow>
          <Label>Email</Label>
          <Edited>
            <Email>{userEmail}</Email>
          </Edited>
        </SettingRow>
      </Wrapper>
    </>
  )
}

export default EmailSetting
