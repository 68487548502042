const NAMESPACE = 'REMOTE_TEST'

export const SET_CURRENT_REMOTE_TEST = `${NAMESPACE}/SET_CURRENT`
export const setCurrentRemoteTest = remoteTestId => ({
  type: SET_CURRENT_REMOTE_TEST,
  payload: { remoteTestId },
})

export const CREATE_REMOTE_TEST = `${NAMESPACE}/CREATE`
export const CREATE_REMOTE_TEST_REQUEST = `${CREATE_REMOTE_TEST}/REQUEST`
export const CREATE_REMOTE_TEST_REQUEST_SUCCESS = `${CREATE_REMOTE_TEST}/REQUEST_SUCCESS`
export const CREATE_REMOTE_TEST_REQUEST_FAILURE = `${CREATE_REMOTE_TEST}/REQUEST_FAILURE`

export const createRemoteTestRequest = ({ projectId, title, sections }) => ({
  type: CREATE_REMOTE_TEST_REQUEST,
  payload: { projectId, title, sections },
  meta: { thunk: CREATE_REMOTE_TEST_REQUEST },
})

export const createRemoteTestRequestSuccess = (payload, thunk) => ({
  type: CREATE_REMOTE_TEST_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const createRemoteTestRequestFailure = (e, thunk) => ({
  type: CREATE_REMOTE_TEST_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const READ_REMOTE_TEST = `${NAMESPACE}/READ`
export const READ_REMOTE_TEST_REQUEST = `${READ_REMOTE_TEST}/REQUEST`
export const READ_REMOTE_TEST_REQUEST_SUCCESS = `${READ_REMOTE_TEST}/REQUEST_SUCCESS`
export const READ_REMOTE_TEST_REQUEST_FAILURE = `${READ_REMOTE_TEST}/REQUEST_FAILURE`

export const readRemoteTestRequest = ({ remoteTestId }) => ({
  type: READ_REMOTE_TEST_REQUEST,
  payload: { remoteTestId },
  meta: { thunk: READ_REMOTE_TEST_REQUEST },
})

export const readRemoteTestRequestSuccess = (payload, thunk) => ({
  type: READ_REMOTE_TEST_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const readRemoteTestRequestFailure = (e, thunk) => ({
  type: READ_REMOTE_TEST_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const READ_ALL_REMOTE_TEST = `${NAMESPACE}/READ_ALL`
export const READ_ALL_REMOTE_TEST_REQUEST = `${READ_ALL_REMOTE_TEST}/REQUEST`
export const READ_ALL_REMOTE_TEST_REQUEST_SUCCESS = `${READ_ALL_REMOTE_TEST}/REQUEST_SUCCESS`
export const READ_ALL_REMOTE_TEST_REQUEST_FAILURE = `${READ_ALL_REMOTE_TEST}/REQUEST_FAILURE`

export const readAllRemoteTestRequest = ({ projectId }) => ({
  type: READ_ALL_REMOTE_TEST_REQUEST,
  payload: { projectId },
  meta: { thunk: READ_ALL_REMOTE_TEST_REQUEST },
})

export const readAllRemoteTestRequestSuccess = (
  { remoteTests, projectId },
  thunk
) => ({
  type: READ_ALL_REMOTE_TEST_REQUEST_SUCCESS,
  payload: { remoteTests, projectId },
  meta: { thunk },
})

export const readAllRemoteTestRequestFailure = (e, thunk) => ({
  type: READ_ALL_REMOTE_TEST_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPDATE_REMOTE_TEST = `${NAMESPACE}/UPDATE`
export const UPDATE_REMOTE_TEST_REQUEST = `${UPDATE_REMOTE_TEST}/REQUEST`
export const UPDATE_REMOTE_TEST_REQUEST_SUCCESS = `${UPDATE_REMOTE_TEST}/REQUEST_SUCCESS`
export const UPDATE_REMOTE_TEST_REQUEST_FAILURE = `${UPDATE_REMOTE_TEST}/REQUEST_FAILURE`

export const updateRemoteTestRequest = ({ remoteTestId, title, sections }) => ({
  type: UPDATE_REMOTE_TEST_REQUEST,
  payload: { remoteTestId, title, sections },
  meta: { thunk: UPDATE_REMOTE_TEST_REQUEST },
})

export const updateRemoteTestRequestSuccess = (payload, thunk) => ({
  type: UPDATE_REMOTE_TEST_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const updateRemoteTestRequestFailure = (e, thunk) => ({
  type: UPDATE_REMOTE_TEST_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_REMOTE_TEST = `${NAMESPACE}/DELETE`
export const DELETE_REMOTE_TEST_REQUEST = `${DELETE_REMOTE_TEST}/REQUEST`
export const DELETE_REMOTE_TEST_REQUEST_SUCCESS = `${DELETE_REMOTE_TEST}/REQUEST_SUCCESS`
export const DELETE_REMOTE_TEST_REQUEST_FAILURE = `${DELETE_REMOTE_TEST}/REQUEST_FAILURE`

export const deleteRemoteTestRequest = ({ remoteTestId }) => ({
  type: DELETE_REMOTE_TEST_REQUEST,
  payload: { remoteTestId },
  meta: { thunk: DELETE_REMOTE_TEST_REQUEST },
})

export const deleteRemoteTestRequestSuccess = (payload, thunk) => ({
  type: DELETE_REMOTE_TEST_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteRemoteTestRequestFailure = (e, thunk) => ({
  type: DELETE_REMOTE_TEST_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const DELETE_REMOTE_TEST_SESSION = `${NAMESPACE}/SESSION_DELETE`
export const DELETE_REMOTE_TEST_SESSION_REQUEST = `${DELETE_REMOTE_TEST_SESSION}/REQUEST`
export const DELETE_REMOTE_TEST_SESSION_REQUEST_SUCCESS = `${DELETE_REMOTE_TEST_SESSION_REQUEST}/REQUEST_SUCCESS`
export const DELETE_REMOTE_TEST_SESSION_REQUEST_FAILURE = `${DELETE_REMOTE_TEST_SESSION_REQUEST}/REQUEST_FAILURE`

export const deleteRemoteTestSessionRequest = ({ sessionId }) => ({
  type: DELETE_REMOTE_TEST_SESSION_REQUEST,
  payload: { sessionId },
  meta: { thunk: DELETE_REMOTE_TEST_SESSION_REQUEST },
})

export const deleteRemoteTestSessionRequestSuccess = (payload, thunk) => ({
  type: DELETE_REMOTE_TEST_SESSION_REQUEST_SUCCESS,
  payload,
  meta: { thunk },
})

export const deleteRemoteTestSessionRequestFailure = (e, thunk) => ({
  type: DELETE_REMOTE_TEST_SESSION_REQUEST_FAILURE,
  payload: e,
  error: true,
  meta: { thunk },
})

export const UPLOAD_REMOTE_TEST_SCREEN_RECORDING = `${NAMESPACE}/UPLOAD_SCREEN_RECORDING`
export const uploadRemoteTestScreenRecording = ({
  projectId,
  remoteTestId,
  content,
  mimeType,
}) => ({
  type: UPLOAD_REMOTE_TEST_SCREEN_RECORDING,
  payload: { projectId, remoteTestId, content, mimeType },
})

export const RESET_REMOTE_TEST_UPLOAD_PROGRESS = `${NAMESPACE}/RESET_UPLOAD_PROGRESS`
export const resetRemoteTestUploadProgress = () => ({
  type: RESET_REMOTE_TEST_UPLOAD_PROGRESS,
  payload: {},
})

export const UPDATE_REMOTE_TEST_UPLOAD_PROGRESS = `${NAMESPACE}/UPDATE_UPLOAD_PROGRESS`
export const updateRemoteTestUploadProgress = (progress, remoteTestId) => ({
  type: UPDATE_REMOTE_TEST_UPLOAD_PROGRESS,
  payload: { progress, remoteTestId },
})
