import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CenterTemplate from '../../../templates/CenterTemplate'
import Flex from '../../../atoms/Flex'
import Brand from '../../../atoms/Brand'
import Quote from '../../../molecules/Quote/Quote'
import RegistrationForm from './RegistrationFormContainer'

const StyledCenterTemplate = styled(CenterTemplate)`
  padding: 24px;
  box-sizing: border-box;
`

const Row = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  width: 100%;
`

const Left = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
`

const Right = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  justify-content: center;
  align-items: center;
`

const StyledBrand = styled(Brand)`
  width: 60%;
  max-width: 300px;
`

const RegistrationPage = ({ onComplete, ...props }) => {
  return (
    <StyledCenterTemplate {...props}>
      <Row>
        <Left>{false && <Quote />}</Left>
        <Right>
          <StyledBrand />
          <RegistrationForm onComplete={onComplete} />
        </Right>
      </Row>
    </StyledCenterTemplate>
  )
}

RegistrationPage.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

export default RegistrationPage
