export const linear = 'linear'
export const ease = 'ease'
export const easeIn = 'ease-in'
export const easeOut = 'ease-out'
export const easeInOut = 'ease-in-out'

/*  Something meant to disappear, but starts slowly */
export const easeInQuad = 'cubic-bezier(.55, .085, .68, .53)'
export const easeInCubic = 'cubic-bezier(.550, .055, .675, .19)'
export const easeInQuart = 'cubic-bezier(.895, .03, .685, .22)'
export const easeInQuint = 'cubic-bezier(.755, .05, .855, .06)'
export const easeInExpo = 'cubic-bezier(.95, .05, .795, .035)'
export const easeInCirc = 'cubic-bezier(.6, .04, .98, .335)'

/* Most common */
export const easeOutQuad = 'cubic-bezier(.25, .46, .45, .94)'
export const easeOutCubic = 'cubic-bezier(.215, .61, .355, 1)'
export const easeOutQuart = 'cubic-bezier(.165, .84, .44, 1)'
export const easeOutQuint = 'cubic-bezier(.23, 1, .32, 1)'
export const easeOutExpo = 'cubic-bezier(.19, 1, .22, 1)'
export const easeOutCirc = 'cubic-bezier(.075, .82, .165, 1)'

/* Already on the screen */
export const easeInOutQuad =
  'cubic-bezier(.455, .03, .515, .955)' /* Super Slow */
export const easeInOutCubic = 'cubic-bezier(.645, .045, .355, 1)' /* Slow */
export const easeInOutQuart = 'cubic-bezier(.77, 0, .175, 1)' /* Medium */
export const easeInOutQuint = 'cubic-bezier(.86, 0, .07, 1)' /* Fast */
export const easeInOutExpo = 'cubic-bezier(1, 0, 0, 1)' /* Super fast */
export const easeInOutCirc =
  'cubic-bezier(.785, .135, .15, .86)' /* Mid with slow end */
