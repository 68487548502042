import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import spritesCache from '../../../lib/spritesCache'
import SessionCard from './SessionCard'
import { getSpriteSize } from './SessionCardUtils'
import useCanDeleteSession from '../../../store/hooks/useCanDeleteSession'
import useShareModal from '../ShareModal/useShareModal'
import useUploads from '../../hooks/useUploads'
import fromSessions from '../../../store/sessions/sessions.selectors'

const getBase64Image = e => {
  const img = e.target
  const spriteSize = getSpriteSize(e)
  const canvas = document.createElement('canvas')
  const imgWidth = spriteSize.width * 3
  const imgHeight = spriteSize.height * 3
  canvas.width = imgWidth
  canvas.height = imgHeight
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, imgWidth, imgHeight)

  return canvas.toDataURL('image/jpg')
}

const SessionCardContainer = ({ sessionId, posterUrl, ...props }) => {
  const cachedImage = spritesCache.getSprite(sessionId, 0)

  const { isUploading } = useUploads()
  const isUploadingVideo = isUploading(sessionId)
  const canDelete = useCanDeleteSession(sessionId)

  const { isUserContributor, isCurrentUserOwner } = useShareModal({
    sessionId,
  })

  const session = useSelector(fromSessions.getSessionById(sessionId))

  if (session && !session.videoConversionProgress) return <></>
  return (
    !isUploadingVideo && (
      <SessionCard
        sessionId={sessionId}
        imageData={cachedImage?.data}
        imageUrl={cachedImage ? '' : posterUrl}
        isLoading={isUploadingVideo}
        spriteSize={cachedImage?.size}
        settingsShowDelete={canDelete}
        settingsShowMove={isUserContributor || isCurrentUserOwner}
        onImageLoad={(e, spriteSize) => {
          if (!cachedImage) {
            const _imageData = getBase64Image(e)
            spritesCache.setSprite({
              sessionId,
              spriteIndex: 0,
              imageDataBase64: _imageData,
              size: spriteSize,
            })
          }
        }}
        {...props}
      />
    )
  )
}

SessionCardContainer.propTypes = {
  sessionId: PropTypes.string.isRequired,
  posterUrl: PropTypes.string,
}

SessionCardContainer.defaultProps = {
  posterUrl: '',
}

export default SessionCardContainer
