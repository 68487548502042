import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@zendeskgarden/react-buttons'
import { Field, Input } from '@zendeskgarden/react-forms'
import {
  Modal,
  Header,
  Body,
  Footer,
  FooterItem,
} from '@zendeskgarden/react-modals'
import Flex from '../../atoms/Flex'
import { StyledErrorMessage } from '../../commonStyledComponents'
import useChangePassword from './hooks/useChangePassword'

const CenteredBox = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
`

const ErrorMessage = styled(StyledErrorMessage)`
  justify-content: flex-start;
  align-items: flex-start;
`

const SuccessMessage = styled(StyledErrorMessage)`
  color: green;
  justify-content: flex-start;
  align-items: flex-start;
`

const PasswordSettingModal = ({ isVisible, onClose }) => {
  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const repeatNewPasswordRef = useRef(null)

  const {
    isChangingPassword,
    isChangePasswordFailed,
    isChangePasswordDone,
    changePasswordHandler,
    onChange,
    formErrors,
    isFormValid,
    submitError,
  } = useChangePassword({
    oldPasswordRef: currentPasswordRef,
    newPasswordRef,
    repeatPasswordRef: repeatNewPasswordRef,
  })

  useEffect(() => {
    if (currentPasswordRef?.current) {
      setTimeout(() => currentPasswordRef.current.focus(), 0)
    }
  }, [isVisible, currentPasswordRef?.current])

  useEffect(() => {
    if (isChangePasswordDone) {
      setTimeout(() => {
        onClose()
      }, 2000)
    }
  }, [isChangePasswordDone])

  return (
    <>
      {isVisible && (
        <Modal isAnimated={false} data-test="modify-password-modal">
          <Header>Modify your password</Header>
          <Body>
            <CenteredBox>
              <Field>
                <Input
                  ref={currentPasswordRef}
                  type="password"
                  placeholder="Type your current password"
                  autoFocus
                  focused
                  onInput={onChange}
                />
                {formErrors.oldPassword && (
                  <ErrorMessage>{formErrors.oldPassword}</ErrorMessage>
                )}
              </Field>
            </CenteredBox>
            <CenteredBox>
              <Field>
                <Input
                  ref={newPasswordRef}
                  type="password"
                  placeholder="Type your new password"
                  onInput={onChange}
                />
                {formErrors.newPassword && (
                  <ErrorMessage>{formErrors.newPassword}</ErrorMessage>
                )}
              </Field>
            </CenteredBox>
            <CenteredBox>
              <Field>
                <Input
                  ref={repeatNewPasswordRef}
                  type="password"
                  placeholder="Type again your new password"
                  onInput={onChange}
                />
                {formErrors.repeatPassword && (
                  <ErrorMessage>{formErrors.repeatPassword}</ErrorMessage>
                )}
              </Field>
            </CenteredBox>
          </Body>
          <Footer>
            <FooterItem style={{ flexDirection: 'column' }}>
              <Flex direction="row">
                <Button
                  isBasic
                  style={{ marginRight: '16px' }}
                  onClick={onClose}
                  data-test="change-password-cancel"
                >
                  Cancel
                </Button>
                <Button
                  isPrimary
                  onClick={() => {
                    changePasswordHandler(
                      currentPasswordRef.current.value,
                      newPasswordRef.current.value
                    )
                  }}
                  disabled={!isFormValid || isChangingPassword}
                  data-test="change-password-button"
                >
                  Change password
                </Button>
              </Flex>
              <Flex>
                {isChangePasswordFailed && (
                  <ErrorMessage style={{ alignSelf: 'flex-end' }}>
                    {submitError}
                  </ErrorMessage>
                )}
                {isChangePasswordDone && (
                  <SuccessMessage>Password updated</SuccessMessage>
                )}
              </Flex>
            </FooterItem>
          </Footer>
        </Modal>
      )}
    </>
  )
}

PasswordSettingModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

PasswordSettingModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

export default PasswordSettingModal
