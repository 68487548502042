import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import last from 'lodash/last'
import { getPrefixedOrganizationId } from '../../../util/ids'
import WorkspaceSettingsPage from './WorkspaceSettingsPage'
import useUserInfo from '../../../store/hooks/useUserInfo'
import useMainAppPage from '../MainAppPage/useMainAppPage'
import SettingsModal from '../../organisms/Settings/SettingsModal'
import useCRUDOrganization from '../../../store/hooks/useCRUDOrganization'
import useOrganization from '../../../store/hooks/useOrganization'
import useOrganizationMembers from '../../molecules/OrganizationMembers/useOrganizationMembers'
import fromOrganizations from '../../../store/organizations/organizations.selectors'
import fromUsers from '../../../store/users/users.selectors'
import { COMMON_EMAIL_DOMAINS } from '../../../util/commonEmailDomains'

const WorkspaceSettingsPageContainer = ({ organizationId, ...props }) => {
  const history = useHistory()
  const { userName } = useUserInfo()

  const prefixedOrganizationId = getPrefixedOrganizationId(organizationId)

  const {
    organization,
    onUpdateOrganizationHandler,
    isUpdateOrganizationPending,
    isUpdateOrganizationRejected,
    onDeleteOrganizationHandler,
  } = useCRUDOrganization(prefixedOrganizationId)

  const { isCurrentUserOrganizationAdmin } = useOrganizationMembers(
    prefixedOrganizationId
  )

  const { onUpdateWorkspaceEmailDomains } = useOrganization()

  const { onLogoutHandler } = useMainAppPage(null)
  const onLogoutClickHandler = () => {
    onLogoutHandler()
  }

  const onBack = () => history.push('/app')

  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false)
  const onSettingsClickHandler = () => {
    setSettingsModalVisible(true)
  }

  const onWorkspaceUpdate = workspace => onUpdateOrganizationHandler(workspace)

  const organizationUserIds = useSelector(
    fromOrganizations.getCurrentOrganizationMembers
  )
  const organizationUsers = useSelector(
    fromUsers.getUsersByIds(organizationUserIds)
  )
  const availableWorkspaceEmailDomains = organizationUsers.reduce(
    (acc, user) => {
      if (!user?.email) {
        return acc
      }
      const emailDomain = last(user.email.split('@'))
      if (
        !acc.includes(emailDomain) &&
        !COMMON_EMAIL_DOMAINS.includes(emailDomain)
      ) {
        return [...acc, emailDomain]
      }
      return acc
    },
    []
  )

  const workspaceEmailDomains = organization.emailDomains

  return (
    <>
      <WorkspaceSettingsPage
        {...props}
        onBack={onBack}
        workspaceName={organization?.title}
        workspacePictureUrl={organization?.pictureUrl}
        workspaceBackgroundColor={organization?.backgroundColor}
        userName={userName}
        onLogout={onLogoutClickHandler}
        onSettings={onSettingsClickHandler}
        onWorkspaceUpdate={onWorkspaceUpdate}
        onUpdateWorkspaceEmailDomains={onUpdateWorkspaceEmailDomains}
        onWorkspaceTitleUpdate={title =>
          onWorkspaceUpdate({
            ...organization,
            title,
          })
        }
        onWorkspaceDelete={onDeleteOrganizationHandler}
        isWorkspaceTitleRequestLoading={isUpdateOrganizationPending}
        isWorkspaceTitleRequestRejected={isUpdateOrganizationRejected}
        isCurrentUserOrganizationAdmin={isCurrentUserOrganizationAdmin}
        availableWorkspaceEmailDomains={availableWorkspaceEmailDomains}
        workspaceEmailDomains={workspaceEmailDomains}
      />
      <SettingsModal
        isVisible={isSettingsModalVisible}
        onClose={() => setSettingsModalVisible(false)}
      />
    </>
  )
}

WorkspaceSettingsPageContainer.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default WorkspaceSettingsPageContainer
