import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import fromUnixTime from 'date-fns/fromUnixTime'
import fromNotificationActivities from '../../../store/notifications/notifications.selectors'
import { setNotificationsReadRequest } from '../../../store/notifications/notifications.actions'
import ActivitiesList from './ActivitiesList'
import { isMobile } from '../../../lib/platform'
import {
  getOrganizationUrl,
  getProjectUrl,
  getSessionUrl,
  getCommentUrl,
  getReplyUrl,
} from '../../../lib/urls'

export const NOTIFICATION_TYPE = {
  reply: 'reply',
  comment: 'comment',
  mention: 'mention',
  organizationInvitation: 'organization-invitation',
  projectInvitation: 'project-invitation',
  sessionInvitation: 'session-invitation',
}

const getActivityNode = notification => {
  if (notification.type === NOTIFICATION_TYPE.reply) {
    return (
      <>
        <b>{notification.actionUserName}</b> replied to a conversation in{' '}
        <b>{notification.sessionTitle}</b>
      </>
    )
  }
  if (notification.type === NOTIFICATION_TYPE.comment) {
    return (
      <>
        <b>{notification.actionUserName}</b> started a new conversation in{' '}
        <b>{notification.sessionTitle}</b>
      </>
    )
  }
  if (notification.type === NOTIFICATION_TYPE.mention) {
    return (
      <>
        <b>{notification.actionUserName}</b> mentioned you in{' '}
        <b>{notification.sessionTitle}</b>
      </>
    )
  }
  if (notification.type === NOTIFICATION_TYPE.organizationInvitation) {
    return (
      <>
        <b>{notification.actionUserName}</b> added you to the workspace{' '}
        <b>{notification.organizationName}</b>
      </>
    )
  }
  if (notification.type === NOTIFICATION_TYPE.projectInvitation) {
    return (
      <>
        <b>{notification.actionUserName}</b> shared the project{' '}
        <b>{notification.projectTitle}</b> with you
      </>
    )
  }
  if (notification.type === NOTIFICATION_TYPE.sessionInvitation) {
    return (
      <>
        <b>{notification.actionUserName}</b> shared the recording{' '}
        <b>{notification.sessionTitle}</b> with you
      </>
    )
  }
  return null
}

const getMessageText = notification => {
  if (
    [
      NOTIFICATION_TYPE.reply,
      NOTIFICATION_TYPE.comment,
      NOTIFICATION_TYPE.mention,
    ].includes(notification.type)
  ) {
    return notification.text
  }
  return null
}

const getOnClick = dispatch => notification => {
  const linkTarget = isMobile() ? '_self' : '_blank'
  const baseAction = () =>
    dispatch(
      setNotificationsReadRequest({
        notificationIds: [notification.notificationId],
      })
    )
  if (
    notification.type === NOTIFICATION_TYPE.reply ||
    notification.type === NOTIFICATION_TYPE.mention
  ) {
    return () => {
      baseAction()
      const url = notification.replyId
        ? getReplyUrl(notification.commentId, notification.replyId)
        : getCommentUrl(notification.commentId)
      window.open(url, linkTarget)
    }
  }
  if (notification.type === NOTIFICATION_TYPE.comment) {
    return () => {
      baseAction()
      window.open(getCommentUrl(notification.commentId), linkTarget)
    }
  }
  if (notification.type === NOTIFICATION_TYPE.sessionInvitation) {
    return () => {
      baseAction()
      window.open(getSessionUrl(notification.sessionId), linkTarget)
    }
  }
  if (notification.type === NOTIFICATION_TYPE.projectInvitation) {
    return () => {
      baseAction()
      window.open(getProjectUrl(notification.projectId), linkTarget)
    }
  }
  if (notification.type === NOTIFICATION_TYPE.organizationInvitation) {
    return () => {
      baseAction()
      window.open(getOrganizationUrl(notification.organizationId), linkTarget)
    }
  }

  return () => null
}

const ActivitiesListContainer = ({ ...props }) => {
  const dispatch = useDispatch()
  const notifications = useSelector(
    fromNotificationActivities.getAllNotifications
  )
  const adaptedNotifications = notifications?.map(notification => {
    return {
      notificationId: notification.notificationId,
      userPictureUrl: notification.actionUserPictureUrl,
      userName: notification.actionUserName,
      activityNode: getActivityNode(notification),
      messageText: getMessageText(notification),
      timeAgo: formatDistanceToNow(fromUnixTime(notification.createdAt), {
        addSuffix: true,
      }),
      isUnread: !!notification?.unread,
      onClick: getOnClick(dispatch)(notification),
    }
  })

  return <ActivitiesList {...props} notifications={adaptedNotifications} />
}

export default ActivitiesListContainer
