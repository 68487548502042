/* eslint-disable no-irregular-whitespace */
import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { useDispatch } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import styled, { withTheme, css } from 'styled-components'
import axios from 'axios'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import pick from 'lodash/fp/pick'
import pipe from 'lodash/fp/pipe'
import prop from 'lodash/fp/prop'
import sortByFp from 'lodash/fp/sortBy'
import reverse from 'lodash/fp/reverse'
import sortBy from 'lodash/sortBy'
import { compareUnsorted } from 'js-deep-equals'
import { withApollo } from 'react-apollo'
import { Button } from '@zendeskgarden/react-buttons'
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs'
import PlayIcon from 'mdi-react/PlayIcon'
import deepEqual from 'fast-deep-equal/es6/react'
import { MD, XL } from '@zendeskgarden/react-typography'
import { DESKTOP_DRAG_BAR_HEIGHT } from '../../atoms/DesktopDragBar/DesktopDragBar'
import Flex from '../../atoms/Flex'
import PhoneView from '../../atoms/PhoneView'
import Loader from '../../atoms/Loader'
import { PLAYBACK_STATUSES } from '../../molecules/Player/Player'
import CommentDialog from '../../molecules/CommentDialog'
import CommentsEmptyState from '../../molecules/CommentsEmptyState'
import ConfirmationModal from '../../molecules/ConfirmationModal'
import IconButton from '../../molecules/IconButton'
import LinkModal from '../../molecules/LinkModal'
import Player from '../../molecules/Player'
import Stopwatch from '../../molecules/Stopwatch'
import CreateJiraIssueModal from '../../molecules/CreateJiraIssueModal'
import CreateTrelloCardModal from '../../molecules/CreateTrelloCardModal'
import RegistrationModal from '../../pages/Onboarding/RegistrationPage/RegistrationModal'
import { isElectron } from '../../../lib/platform'
import api from '../../../api'
import useDeepEffect from '../../hooks/useDeepEffect'
import browserExtension from '../../../lib/browserExtension'
import useSessionView from './useSessionView'
import useCanAddCommentsToSession from '../../hooks/useCanAddCommentsToSession'
import useJira from '../../hooks/useJira'
import { addNotification } from '../../../store/ui-notifications/ui-notifications.actions'
import messageTypes from '../../../browser-extension/messageTypes'
import ConsoleComingSoonEmptyStateSvg from '../../../assets/console_coming_soon_empty_state.svg'
import {
  checkIfVideoExists,
  addContinueButtonBreakPointsToSortedComments,
  sortCommentsByPosition,
  addAnonymousUserToComments,
  filterEmptyComments,
} from './SessionView.utils'
import {
  ternary,
  getRecordingTitleFromUrl,
  getDefaultSessionTitle,
} from '../../../util/utils'
import PageEvents from '../PageEvents'
import { HEADER_HEIGHT } from '../../pages/MainAppPage/MainAppPage.constants'
import PageEventsFilter from '../../molecules/PageEventsFilter'
import EmptyStateMobile from '../../molecules/EmptyStateMobile'
import useTrello from '../../hooks/useTrello'
import useSlack from '../../hooks/useSlack'
import SessionInfo from '../SessionInfo'
import SendSlackMessageModal from '../../molecules/SendSlackMessageModal'
import VideoOverlay from './VideoOverlay'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'
import VideoUploading from '../../molecules/VideoUploading'

const COMMENTS_TOLERANCE_SECS = 0.75

const TABS = {
  comments: 'comments',
  console: 'console',
  info: 'info',
}

let adbClient
if (isElectron()) {
  // eslint-disable-next-line global-require
  const lib = require('../../../lib/adbClient')
  adbClient = lib.default
} else {
  // eslint-disable-next-line global-require
  adbClient = require('../../../lib/adbClientMock')
}

const safariFlexFix = css`
  flex-shrink: 0;
  flex-basis: auto;
`

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: stretch;

  height: calc(100vh - ${HEADER_HEIGHT}px - ${DESKTOP_DRAG_BAR_HEIGHT}px);
`
const BaseBox = styled.div`
  order: 0;
  flex: 1 1 50%;
  align-self: stretch;
  background-color: #f8f9f9;
  overflow: visible;
  border-right: 1px solid #d8dcde;
`

const VIDEO_WIDTH_PERCENTAGE = 70
const LeftBox = styled(BaseBox)`
  flex: 1 1 ${VIDEO_WIDTH_PERCENTAGE}%;
`

const RightBox = styled(BaseBox)`
  max-width: 640px;
  flex: 1 1 ${100 - VIDEO_WIDTH_PERCENTAGE}%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  border-right: 0;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${DESKTOP_DRAG_BAR_HEIGHT}px);
  overflow: hidden;
  ${({ isEditMode, isRecording }) =>
    !isEditMode && !isRecording
      ? 'display: flex; flex-direction: column; justify-content: center;'
      : ''}
`

const StyledGrid = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`

const PhonePreviewRow = styled(Flex)`
  order: 0;
  flex: 10 1 auto;
  align-self: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 16px;
`

const FooterRow = styled(Flex)`
  order: 0;
  flex: 0 1 40px;
  align-items: stretch;
  width: 100%;
  max-width: 978px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  margin-bottom: 8px;
`

const CommentsContainer = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
  padding-bottom: 40px;
  align-content: flex-start;
  justify-content: ${ternary('isEmpty')('center', 'flex-start')};
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  min-height: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;

  && {
    overflow-y: scroll;
  }
`

const StyledCommentDialog = styled(CommentDialog)`
  ${safariFlexFix}
`

// const TOOLTIP_WIDTH = 150
const TOOLTIP_HEIGHT = 20
const AddCommentCursorTooltip = styled(Flex)`
  font-size: 12px;
  color: white;
  padding: 2px 16px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  text-align: center;
  line-height: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: auto;
  height: ${TOOLTIP_HEIGHT}px;
  visibility: hidden;
  z-index: 24;
`

const ContinueButton = styled(IconButton)`
  margin-bottom: 16px;
  min-height: 30px;
  width: 100%;
`

const CenteredContainer = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  z-index: 10;
  opacity: 0.75;
  align-self: center;
  margin-top: -27px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const InitialOverlay = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(19, 18, 28, 0.8);
  z-index: 10;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 48;
`

const getVideoRecordingFn = (isTemporaryRecording, isAnonymous) => {
  if (isTemporaryRecording) {
    return api.getExtensionRecording
  }
  if (isAnonymous) {
    return api.getPublicRecording
  }
  return api.getRecording
}

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  && {
    overflow: hidden;
  }
`

const StyledTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  height: 40px;
  min-height: 40px;
  ${safariFlexFix};
`

const StyledTab = styled(Tab)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const CommentsTabPanel = styled(TabPanel)`
  flex-grow: 1;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  height: calc(100% - 40px);
  overflow: hidden;

  > div {
    height: calc(100% - 40px);
  }
`

const ConsoleTabPanel = styled(TabPanel)`
  display: block;
  overflow: auto;
  height: calc(100% - 40px);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 0px;

  && {
    overflow: hidden;
  }

  && {
    height: calc(100% - 40px);
  }
`

const InfoTabPanel = styled(TabPanel)``

const StyledPageEventsFilter = styled(PageEventsFilter)`
  width: 100%;
  margin: 0;
  padding: 0;
`

const StyledPageEvents = styled(PageEvents)`
  padding: 16px 16px 16px 8px;
  box-sizing: border-box;
`

const ConsoleEmptyStateTitle = styled(XL)`
  margin-top: 16px;
`

const ConsoleEmptyStateDescription = styled(MD)`
  margin-top: 16px;
  color: ${prop('theme.colors.grey')};
`

const StyledSessionInfo = styled(SessionInfo)`
  padding: 16px;
`

const SessionView = ({
  client,
  initialCommentId,
  isAnonymous,
  isEditMode,
  onExit,
  readOnly,
  theme,
  onShowShareModal,
  onShowUserSettings,
  onSetTemporarySessionTitle,
  registrationModalVisibilityToken = '',
  registrationModalWithLoginToken,
  registrationModalWithRegisterToken,
  recordingId,
  ...props
}) => {
  const isTemporaryRecording = !!recordingId

  const {
    allUsers,
    comments,
    currentDeviceId,
    currentProject,
    editMode,
    getVideoInfo,
    isDeleteModalVisible,
    isLinkModalVisible,
    isRecording,
    isUserLogged,
    isUserOwnSession,
    linkModalUrl,
    onAnonymourReplyFocus,
    onAnonymousVideoClick,
    onCreateSessionResourcesHandler,
    onResolveCommentClick,
    onSessionDelete,
    onStartRecording,
    onStopRecording,
    onUpdateSessionDescription,
    onUpdateSessionTitle,
    onUploadExtensionRecording,
    pageEvents: loadedPageEvents,
    session,
    sessionId,
    sessionTitle,
    setDeleteModalVisible,
    setLinkModalVisible,
    setVideoDuration,
    setVideoUrl,
    sprites,
    userName,
    videoDuration,
    videoPath,
    videoUrl,
    sessionCreatorUserName,
    sessionCreatorPictureUrl,
  } = useSessionView({
    isEditMode,
    onExit,
    isAnonymous,
    isTemporaryRecording,
  })

  const [isInitialOverlayVisible, setInitialOverlayVisible] = useState(
    !initialCommentId
  )

  const [isSkipCommentsEnabled, setSkipCommentsEnabled] = useState(undefined)
  const [commentsToleranceSecs, setCommentsToleranceSecs] = useState(
    COMMENTS_TOLERANCE_SECS
  )

  const { hasJira: isJiraEnabled, onCreateJiraIssue } = useJira()
  const { hasTrello: isTrelloEnabled } = useTrello()
  const { hasSlack: isSlackEnabled } = useSlack()

  const [isFetchingVideo, setFetchingVideo] = useState(true)

  const commentsContainerRef = useRef(null)

  const phoneViewContainerRef = useRef(null)
  const [phoneViewMaxWidth, setPhoneViewMaxWidth] = useState(1024)
  const [phoneViewMaxHeight, setPhoneViewMaxHeight] = useState(650)

  const onSetPhoneViewMaxSize = useCallback(() => {
    if (phoneViewContainerRef && phoneViewContainerRef.current) {
      setPhoneViewMaxHeight(
        phoneViewContainerRef.current.getBoundingClientRect().height - 64
      )
      setPhoneViewMaxWidth(
        (window.innerWidth / 100) * VIDEO_WIDTH_PERCENTAGE - 64 || 1024
      )
    }
  }, [phoneViewContainerRef, setPhoneViewMaxHeight, setPhoneViewMaxWidth])

  useEffect(() => {
    onSetPhoneViewMaxSize()
    window.addEventListener('resize', onSetPhoneViewMaxSize)

    return () => {
      window.removeEventListener('resize', onSetPhoneViewMaxSize)
    }
  }, [onSetPhoneViewMaxSize])

  const [isPlayerFocused, setPlayerFocused] = useState(true)

  const [isVideoLoaded, setVideoLoaded] = useState(false)
  const [videoRef, setVideoRef] = useState(null)
  const [isVideoPlaying, setVideoPlaying] = useState(false)
  const [videoCurrentTime, setVideoCurrentTime] = useState(0)
  const [playerDrivenTime, setPlayerDrivenTime] = useState(0)
  const [playerDrivenTimeToken, setPlayerDrivenTimeToken] = useState('')

  const [crossedComments, setCrossedComments] = useState([])
  const [currentActiveCommentId, setCurrentActiveCommentId] = useState(
    `comment:${initialCommentId}`
  )
  const [videoAutoPlay, setVideoAutoPlay] = useState(false)
  useEffect(() => {
    if (videoDuration && isVideoLoaded && initialCommentId) {
      const _commentId = `comment:${initialCommentId}`
      const initialComment = comments.find(x => x.pk === _commentId)
      setVideoCurrentTime(initialComment.videoPositionSecs)
      setPlayerDrivenTime(initialComment.videoPositionSecs)
      setVideoPlaying(false)
      setVideoAutoPlay(false)
      setCurrentActiveCommentId(_commentId)
    }

    if (videoDuration && isVideoLoaded && !initialCommentId) {
      /* NOTE: autoplay is deprecated */
      // setVideoPlaying(true)
      // setVideoAutoPlay(true)
    }
  }, [isVideoLoaded, videoDuration])

  const [isRecordingStopped, setRecordingStopped] = useState(false)
  const [deviceScreenSize, setDeviceScreenSize] = useState({
    width: 0,
    height: 0,
  })

  const [commentVideoMarkers, setCommentVideoMarkers] = useState([])
  useLayoutEffect(() => {
    const commentsWithVideoPoint = crossedComments.filter(
      comment => comment?.videoPoint || comment?.videoRectangle
    )
    if (commentsWithVideoPoint.length > 0) {
      const videoMarkers = commentsWithVideoPoint.reduce(
        (acc, comment) => [
          ...acc,
          {
            videoPoint: comment.videoPoint,
            videoRectangle: comment.videoRectangle,
            time: comment.videoPositionSecs,
            commentId: comment.pk,
            number: comment?.number,
            isBlurred: comment.pk !== currentActiveCommentId,
          },
        ],
        []
      )

      if (!deepEqual(commentVideoMarkers, videoMarkers)) {
        setCommentVideoMarkers(videoMarkers)
      }
      return
    }
    if (!deepEqual(commentVideoMarkers, [])) {
      setCommentVideoMarkers([])
    }
  }, [
    crossedComments,
    videoCurrentTime,
    commentVideoMarkers,
    currentActiveCommentId,
  ])

  const videoBoxClickListener = e => {
    if (
      e.path &&
      e.path.find &&
      !e.path.find(
        el =>
          el.className &&
          el.className.includes &&
          el.className.includes('phone-view')
      )
    ) {
      setPlayerFocused(false)
    }
  }

  const [lastCrossedCommentId, setLastCrossedCommentId] = useState(null)

  const onUpdatePosition = time => {
    setPlayerDrivenTime(time)
    setInitialOverlayVisible(false)
    // trick, not nice I know
    setTimeout(() => {
      setLastCrossedCommentId(null)
    }, 1000)
  }

  const [filteredComments, setFilteredComments] = useState([])

  const [isVideoEncodingOnTheCloud, setIsVideoEncodingOnTheCloud] = useState(
    false
  )
  const [isVideoMissing, setVideoMissing] = useState(false)
  /*
  const [isVideoFromExtensionMissing, setVideoFromExtensionMissing] = useState(
    false
  )
  */

  const [pageEvents, setPageEvents] = useState(loadedPageEvents)
  useEffect(() => {
    if (loadedPageEvents?.length) {
      setPageEvents(loadedPageEvents)
    }
  }, [loadedPageEvents?.length])

  const handleVideoFromExtension = () => {
    if (!isUserOwnSession) {
      return
    }
    let response = {}
    if (sessionId) {
      response = JSON.parse(
        localStorage.getItem(sessionId.replace('session:', ''))
      )
    }
    if (recordingId) {
      response = JSON.parse(localStorage.getItem(recordingId))
    }
    if (response?.error === 'video_not_found') {
      // setVideoFromExtensionMissing(true)
    }

    if (response?.blobUrl) {
      setFetchingVideo(false)
      const { blobUrl } = response
      getVideoInfo(blobUrl).then(info => {
        setDeviceScreenSize(pick(['width', 'height'])(info))
        setVideoDuration(info.duration)
        setVideoUrl(blobUrl)
        setIsVideoEncodingOnTheCloud(false)
      })
      if (!isTemporaryRecording) {
        onUploadExtensionRecording(blobUrl)
      }
    }

    if (response?.url) {
      const defaultTitle = getRecordingTitleFromUrl(response?.url)
      onSetTemporarySessionTitle(defaultTitle)
      if (!sessionTitle) {
        onUpdateSessionTitle(defaultTitle)
      }
    }

    if (!response?.url) {
      onUpdateSessionTitle(getDefaultSessionTitle(currentProject))
    }

    if (isTemporaryRecording && response?.comments) {
      setFilteredComments(
        pipe(
          sortCommentsByPosition,
          addContinueButtonBreakPointsToSortedComments(COMMENTS_TOLERANCE_SECS),
          addAnonymousUserToComments
        )(response.comments)
      )
    }

    if (isTemporaryRecording && response?.pageEvents?.events?.length) {
      setPageEvents(response.pageEvents.events || [])
    }

    let commentsFromExtension = []
    let pageEventsFromExtension = []
    let deviceMetaInfoFromExtension = {}
    if (
      !isTemporaryRecording &&
      response?.comments?.length > 0 &&
      filteredComments.length === 0
    ) {
      commentsFromExtension = response?.comments
    }

    if (
      !isTemporaryRecording &&
      response?.pageEvents?.events?.length > 0 &&
      pageEvents.length === 0
    ) {
      pageEventsFromExtension = response?.pageEvents?.events
    }

    if (!isTemporaryRecording && response?.deviceMetaInfo) {
      deviceMetaInfoFromExtension = response?.deviceMetaInfo
    }

    onCreateSessionResourcesHandler(
      commentsFromExtension,
      pageEventsFromExtension,
      deviceMetaInfoFromExtension
    )

    if (pageEventsFromExtension.length > 0) {
      setPageEvents(response?.pageEvents?.events)
    }
  }

  const onVideoUrlReceived = url => {
    setFetchingVideo(false)
    getVideoInfo(url).then(info => {
      setDeviceScreenSize(pick(['width', 'height'])(info))
      setVideoDuration(info.duration)
      setVideoUrl(url)
    })
  }

  useEffect(() => {
    if (!editMode && !isRecording) {
      onStartRecording()
    }

    const getRecordingFn = getVideoRecordingFn(
      isTemporaryRecording,
      isAnonymous
    )

    getRecordingFn(recordingId || sessionId)
      .then(({ data }) => {
        const url = get(data, 'url')
        if (url) {
          checkIfVideoExists(url)
            .then(() => onVideoUrlReceived(url))
            .catch(() => {
              handleVideoFromExtension()
            })
          return
        }

        handleVideoFromExtension()
        if (get(data, 'result') === 'polling') {
          setIsVideoEncodingOnTheCloud(true)
        }
        if (get(data, 'result') === 'no_video_available') {
          setFetchingVideo(false)
          if (!isUserOwnSession) {
            setVideoMissing(true)
          }
        }
      })
      .catch(() => {
        handleVideoFromExtension()
      })

    if (isElectron() && isRecording) {
      adbClient.getDeviceScreenSize(currentDeviceId).then(size => {
        if (size && !isNaN(size.width)) {
          setDeviceScreenSize(size)
        }
      })
    }

    return () => {
      onExit()
    }
  }, [])

  let isVideoEncodingOnTheCloudInterval = -1
  useEffect(() => {
    if (isVideoEncodingOnTheCloud) {
      isVideoEncodingOnTheCloudInterval = setInterval(() => {
        const getRecordingFn = getVideoRecordingFn(
          isTemporaryRecording,
          isAnonymous
        )
        getRecordingFn(recordingId || sessionId).then(({ data }) => {
          const url = get(data, 'url')
          if (url) {
            checkIfVideoExists(url).then(() => {
              onVideoUrlReceived(url)
              setIsVideoEncodingOnTheCloud(false)
              clearInterval(isVideoEncodingOnTheCloudInterval)
            })
          }
        })
      }, 2000)
    }
  }, [isVideoEncodingOnTheCloud])

  const [deviceIp, setDeviceIp] = useState('')
  const [isMirroringActive, setMirroringActive] = useState(false)
  useEffect(() => {
    if (isElectron()) {
      adbClient.getDeviceIP(currentDeviceId).then(ip => {
        if (ip) {
          setDeviceIp(ip)
          axios.get(`http://${ip}:6868`).then(response => {
            setMirroringActive(response.status === 200)
          })
        }
      })
    }
  }, [])

  const onCommentClick = useCallback(
    ({ videoPositionSecs, commentId }) => {
      setVideoCurrentTime(videoPositionSecs)
      setPlayerDrivenTime(videoPositionSecs)
      setPlayerDrivenTimeToken(Math.random().toString())
      setVideoPlaying(false)
      setCurrentActiveCommentId(commentId)
      setInitialOverlayVisible(false)
    },
    [
      setVideoCurrentTime,
      setPlayerDrivenTime,
      setVideoPlaying,
      setInitialOverlayVisible,
    ]
  )

  useDeepEffect(
    () => {
      setFilteredComments(
        pipe(
          filterEmptyComments,
          sortCommentsByPosition,
          addContinueButtonBreakPointsToSortedComments(COMMENTS_TOLERANCE_SECS)
        )(comments)
      )
    },
    [comments, allUsers],
    compareUnsorted
  )

  const tryPreviousCommentInCurrentFrame = sortedComments => {
    const currentFrameComments = pipe(
      sortByFp(['number']),
      reverse
    )(
      sortedComments.filter(
        comment => comment.videoPositionSecs === videoCurrentTime
      )
    )
    if (currentFrameComments.length > 0) {
      const currentActiveComment = currentFrameComments.find(
        comment => comment.pk === currentActiveCommentId
      )

      if (currentActiveComment) {
        const previousComment = currentFrameComments.find(
          comment => comment.number < currentActiveComment.number
        )
        if (!previousComment) {
          return false
        }
        onCommentClick({
          videoPositionSecs: previousComment.videoPositionSecs,
          commentId: previousComment.pk,
        })
        return true
      }

      onCommentClick({
        videoPositionSecs: currentFrameComments[0].videoPositionSecs,
        commentId: currentFrameComments[0].pk,
      })
      return true
    }
    return false
  }

  const onPreviousComment = () => {
    const sortedReversedComments = pipe(
      sortByFp(['videoPositionSecs']),
      reverse
    )(filteredComments)

    const previousComment = sortedReversedComments.find(
      comment => videoCurrentTime > comment.videoPositionSecs
    )

    if (tryPreviousCommentInCurrentFrame(sortedReversedComments)) {
      return
    }
    if (previousComment) {
      onCommentClick({
        videoPositionSecs: previousComment.videoPositionSecs,
        commentId: previousComment.pk,
      })
    }
  }

  const tryNextCommentInCurrentFrame = sortedComments => {
    const currentFrameComments = sortedComments.filter(
      comment => comment.videoPositionSecs === videoCurrentTime
    )
    if (currentFrameComments.length > 0) {
      const currentActiveComment = currentFrameComments.find(
        comment => comment.pk === currentActiveCommentId
      )
      if (currentActiveComment) {
        const nextComment = currentFrameComments.find(
          comment => comment.number > currentActiveComment.number
        )
        if (!nextComment) {
          return false
        }
        onCommentClick({
          videoPositionSecs: nextComment.videoPositionSecs,
          commentId: nextComment.pk,
        })
        return true
      }

      onCommentClick({
        videoPositionSecs: currentFrameComments[0].videoPositionSecs,
        commentId: currentFrameComments[0].pk,
      })
      return true
    }
    return false
  }
  const onNextComment = () => {
    const sortedComments = sortBy(filteredComments, ['videoPositionSecs'])

    if (tryNextCommentInCurrentFrame(sortedComments)) {
      return
    }

    const nextComment = sortedComments.find(
      comment => comment.videoPositionSecs > videoCurrentTime
    )
    if (nextComment) {
      onCommentClick({
        videoPositionSecs: nextComment.videoPositionSecs,
        commentId: nextComment.pk,
      })
    }
  }

  const onRewind = () => {
    const nextTime = Math.max(0.01, videoCurrentTime - 10)
    onUpdatePosition(nextTime)
    setVideoCurrentTime(nextTime)
  }
  const onForward = () => {
    const nextTime = videoCurrentTime + 10
    onUpdatePosition(nextTime)
    setVideoCurrentTime(nextTime)
  }

  const [playbackRateTrigger, setPlaybackRateTrigger] = useState(undefined)
  const [toggleAudioTrigger, setToggleAudioTrigger] = useState(undefined)
  const [
    removeNewVideoMarkerTrigger,
    setRemoveNewVideoMarkerTrigger,
  ] = useState(undefined)

  const keyDownListener = e => {
    if (e.code === 'Escape' || e.keyCode === 27) {
      setRemoveNewVideoMarkerTrigger(Math.random().toString())
    }

    const focuseEl = document.activeElement
    if (
      focuseEl.hasAttribute('contenteditable') ||
      focuseEl.tagName.toLowerCase() === 'input' ||
      focuseEl.tagName.toLowerCase() === 'textarea'
    ) {
      return
    }

    if (e.code === 'Space' || e.keyCode === 32) {
      setVideoPlaying(!isVideoPlaying)
      setInitialOverlayVisible(false)
    }
    if (e.code === 'Period' || e.keyCode === 190) {
      onForward()
    }
    if (e.code === 'Comma' || e.keyCode === 188) {
      onRewind()
    }
    if (e.code === 'ArrowLeft' || e.keyCode === 37) {
      setVideoPlaying(false)
      onPreviousComment()
    }
    if (e.code === 'ArrowRight' || e.keyCode === 39) {
      setVideoPlaying(false)
      onNextComment()
    }
    if (e.code === 'ArrowUp' || e.keyCode === 38) {
      setPlaybackRateTrigger(Math.random().toString())
    }
    if (e.code === 'KeyM' || e.keyCode === 77) {
      setToggleAudioTrigger(Math.random().toString())
    }
  }
  useEffect(() => {
    window.document.addEventListener('click', videoBoxClickListener)
    return () => {
      window.document.removeEventListener('click', videoBoxClickListener)
    }
  }, [])

  useEffect(() => {
    window.document.addEventListener('keydown', keyDownListener)
    return () => {
      window.document.removeEventListener('keydown', keyDownListener)
    }
  }, [
    isPlayerFocused,
    isVideoPlaying,
    filteredComments?.length,
    videoCurrentTime,
    currentActiveCommentId,
  ])

  const { width = 0, height = 0 } = deviceScreenSize

  const onPhoneViewLoaded = useCallback(
    _videoRef => {
      setVideoLoaded(true)
      setVideoRef(_videoRef)
    },
    [setVideoLoaded, setVideoRef]
  )

  const [selectedTab, setSelectedTab] = useState(TABS.comments)
  const [consoleEventsNumber, setConsoleEventsNumber] = useState(0)

  const onPlay = () => {
    setInitialOverlayVisible(false)
    setVideoPlaying(true)
  }

  const player = useMemo(
    () =>
      editMode &&
      !isRecording && (
        <Player
          videoRef={videoRef}
          videoStatus={
            isVideoPlaying
              ? PLAYBACK_STATUSES.playing
              : PLAYBACK_STATUSES.paused
          }
          videoLengthSecs={videoDuration}
          currentPositionSecs={videoCurrentTime}
          onPlay={onPlay}
          onPause={() => setVideoPlaying(false)}
          onUpdatePosition={time => {
            onUpdatePosition(time)
          }}
          onSkipComments={_isSkipCommentsEnabled =>
            setSkipCommentsEnabled(_isSkipCommentsEnabled)
          }
          onPlaybackRate={rate =>
            setCommentsToleranceSecs(COMMENTS_TOLERANCE_SECS * rate)
          }
          pageEvents={pageEvents}
          tickers={filteredComments}
          playbackRateTrigger={playbackRateTrigger}
          toggleAudioTrigger={toggleAudioTrigger}
          onPreviousComment={onPreviousComment}
          onNextComment={onNextComment}
          onRewind={onRewind}
          onForward={onForward}
          onTickerClick={ticker => {
            const comment = filteredComments.find(
              _comment => _comment.pk === ticker.pk
            )
            if (comment) {
              onCommentClick({
                videoPositionSecs: comment?.videoPositionSecs,
                commentId: comment?.pk,
              })
              setSelectedTab(TABS.comments)
            }
          }}
          onTickersCrossed={({ tickers }) => {
            if (!deepEqual(tickers, crossedComments)) {
              setCrossedComments(tickers)
              if (!currentActiveCommentId) {
                setCurrentActiveCommentId(get(tickers, '[0].pk', null))
              }
            }

            if (tickers.length === 0) {
              setCurrentActiveCommentId(null)
            }
          }}
          sprites={sprites}
        />
      ),
    [
      editMode,
      isRecording,
      isVideoPlaying,
      videoDuration,
      videoCurrentTime,
      filteredComments,
      sprites,
      crossedComments,
      pageEvents,
      onPlay,
    ]
  )

  const phoneViewRef = useRef(null)
  const cursorTooltipRef = useRef(null)
  const mouseMoveHandler = e => {
    cursorTooltipRef.current.style.right = `${window.innerWidth -
      e.clientX +
      24}px`
    cursorTooltipRef.current.style.top = `${e.clientY - TOOLTIP_HEIGHT / 2}px`
  }

  useEffect(() => {
    if (
      phoneViewRef?.current &&
      cursorTooltipRef?.current &&
      phoneViewContainerRef?.current
    ) {
      phoneViewContainerRef.current.addEventListener('mousemove', e => {
        const tagName = e?.target?.tagName
        const shouldShow = tagName.toLowerCase() === 'video'
        const visibility = shouldShow ? 'visible' : 'hidden'
        cursorTooltipRef.current.style.visibility = visibility
        if (shouldShow) {
          mouseMoveHandler(e)
        }
      })
      phoneViewContainerRef.current.addEventListener('mouseenter', e => {
        cursorTooltipRef.current.style.visibility = 'visible'
        mouseMoveHandler(e)
      })
      phoneViewContainerRef.current.addEventListener('mouseleave', () => {
        cursorTooltipRef.current.style.visibility = 'hidden'
      })
    }
  }, [
    phoneViewRef?.current,
    cursorTooltipRef?.current,
    phoneViewContainerRef?.current,
  ])

  const canAddComments = useCanAddCommentsToSession()

  const [
    isJiraCreateIssueModalVisible,
    setJiraCreateIssueModalVisible,
  ] = useState(false)
  const [jiraIssueDescription, setJiraIssueDescription] = useState(false)
  const [jiraIssueResourceId, setJiraIssueResourceId] = useState('')
  const [jiraIssueResourceUrl, setJiraIssueResourceUrl] = useState('')

  const [
    isTrelloCreateCardModalVisible,
    setTrelloCreateCardModalVisible,
  ] = useState(false)
  const [trelloCardDescription, setTrelloCardDescription] = useState('')
  const [trelloCardCommentId, setTrelloCardCommentId] = useState(null)

  const [
    isSlackSendMessageModalVisible,
    setSlackSendMessageModalVisible,
  ] = useState(false)
  const [slackCommentId, setSlackCommentId] = useState(null)
  const [slackReplyId, setSlackReplyId] = useState(null)

  const [isRegistrationModalVisible, setRegistrationModalVisible] = useState(
    false
  )
  useEffect(() => {
    if (!registrationModalVisibilityToken) {
      return
    }
    setRegistrationModalVisible(true)
  }, [registrationModalVisibilityToken])

  const [
    initRegistrationModalWithLogin,
    setInitRegistrationModalWithLogin,
  ] = useState(false)

  useEffect(() => {
    setInitRegistrationModalWithLogin(true)
  }, [registrationModalWithLoginToken])

  useEffect(() => {
    setInitRegistrationModalWithLogin(false)
  }, [registrationModalWithRegisterToken])

  useLayoutEffect(() => {
    if (isSkipCommentsEnabled) {
      return
    }
    const crossedComment = filteredComments.find(
      comment =>
        videoCurrentTime >= comment.videoPositionSecs - 0.1 &&
        videoCurrentTime <= comment.videoPositionSecs + commentsToleranceSecs
    )

    if (
      !isSkipCommentsEnabled &&
      crossedComment?.pk &&
      crossedComment.pk !== lastCrossedCommentId &&
      selectedTab === TABS.comments
    ) {
      /* pause on comments */
      setVideoPlaying(false)
      const stopVideo = () => {
        setVideoCurrentTime(crossedComment.videoPositionSecs)
        setPlayerDrivenTime(crossedComment.videoPositionSecs)
        setVideoPlaying(false)
      }
      setTimeout(() => {
        stopVideo()
      }, 0)
      setTimeout(() => {
        stopVideo()
      }, 250)
      setTimeout(() => {
        stopVideo()
      }, 500)
      setLastCrossedCommentId(crossedComment.pk)
    }
  }, [
    commentsToleranceSecs,
    videoCurrentTime,
    videoRef?.current,
    selectedTab,
    filteredComments?.length,
    lastCrossedCommentId,
    isSkipCommentsEnabled,
  ])

  const dispatch = useDispatch()
  const showCopySnackbar = () =>
    dispatch(
      addNotification({
        notificationId: uuidv1(),
        text: 'Link copied to clipboard',
      })
    )

  const [pageEventsFilters, setPageEventsFilters] = useState(undefined)

  return (
    <Wrapper {...props}>
      {isDeleteModalVisible && (
        <ConfirmationModal
          title="Delete recording"
          text="Are you sure to delete the recording?"
          actionButtonText="Delete"
          onClose={() => {
            setDeleteModalVisible(false)
          }}
          onSubmit={() => {
            onSessionDelete(sessionId)
          }}
        />
      )}
      {isLinkModalVisible && (
        <LinkModal
          isVisible={isLinkModalVisible}
          url={linkModalUrl}
          onClose={() => {
            setLinkModalVisible(false)
          }}
        />
      )}
      <LeftBox
        onClick={e => {
          if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA') {
            setPlayerFocused(true)
          }
        }}
        className="phone-view"
      >
        <StyledGrid>
          <PhonePreviewRow ref={phoneViewContainerRef}>
            <Flex
              alignSelf="center"
              justifyContent="center"
              style={{ textAlign: 'center' }}
            >
              {!editMode && (
                <>
                  <Stopwatch
                    isStopped={isRecordingStopped}
                    style={{ marginBottom: '16px' }}
                  />
                  <Button
                    isPrimary
                    disabled={isRecordingStopped}
                    onClick={() => {
                      setRecordingStopped(true)
                      onStopRecording()
                    }}
                  >
                    Stop session
                  </Button>
                </>
              )}
              {(isFetchingVideo || isVideoEncodingOnTheCloud) && (
                <CenteredContainer>
                  <Loader />
                  {isVideoEncodingOnTheCloud && (
                    <MD style={{ marginTop: '16px' }}>
                      Bear with us, it could take a few minutes
                    </MD>
                  )}
                </CenteredContainer>
              )}
              {isVideoMissing && !videoUrl && (
                <CenteredContainer>
                  <VideoUploading
                    userName={sessionCreatorUserName}
                    pictureUrl={sessionCreatorPictureUrl}
                  />
                </CenteredContainer>
              )}
              {editMode && !isRecording && width > 0 && (
                <PhoneView
                  posterNode={
                    !isFetchingVideo &&
                    isInitialOverlayVisible && (
                      <InitialOverlay onClick={onPlay}>
                        <VideoOverlay
                          onPlay={onPlay}
                          showText={comments?.length > 0}
                        />
                      </InitialOverlay>
                    )
                  }
                  isTemporaryRecording={isTemporaryRecording}
                  isAnonymous={isAnonymous}
                  externalRef={phoneViewRef}
                  autoPlay={videoAutoPlay}
                  width={width}
                  height={height}
                  maxWidth={phoneViewMaxWidth}
                  maxHeight={phoneViewMaxHeight}
                  focused={isPlayerFocused}
                  isPlaying={isVideoPlaying}
                  currentTime={playerDrivenTime}
                  currentTimeToken={playerDrivenTimeToken}
                  removeNewVideoMarkerTrigger={removeNewVideoMarkerTrigger}
                  url={
                    isMirroringActive
                      ? `http://192.168.1.108:6868/screen_stream.mjpeg`
                      : deviceIp
                  }
                  videoPath={videoUrl || videoPath}
                  onTimeUpdate={currentTime => setVideoCurrentTime(currentTime)}
                  onLoaded={onPhoneViewLoaded}
                  onPause={() => setVideoPlaying(false)}
                  videoMarkers={commentVideoMarkers}
                  onMarkerClick={commentId => {
                    setCurrentActiveCommentId(commentId)
                  }}
                  onShowRegistrationModal={() =>
                    setRegistrationModalVisible(true)
                  }
                  onReplyFocus={({ commentId }) => {
                    if (isAnonymous || isTemporaryRecording) {
                      setRegistrationModalVisible(true)
                      onAnonymourReplyFocus({ commentId })
                    }
                  }}
                  onNewVideoMarker={event => {
                    if (!event) {
                      return
                    }

                    const {
                      time,
                      point: { percentage, absolute },
                    } = event

                    if (isAnonymous || isTemporaryRecording) {
                      setRegistrationModalVisible(true)
                      onAnonymousVideoClick({
                        time,
                        point: { percentage, absolute },
                      })
                    } else {
                      setVideoCurrentTime(time)
                    }
                  }}
                  data-test="recording-video-box"
                />
              )}
            </Flex>
            <AddCommentCursorTooltip ref={cursorTooltipRef}>
              {canAddComments || isTemporaryRecording
                ? 'Click or drag to comment'
                : 'view only'}
            </AddCommentCursorTooltip>
          </PhonePreviewRow>
          <FooterRow>{player}</FooterRow>
        </StyledGrid>
      </LeftBox>
      <RightBox>
        <StyledTabs selectedItem={selectedTab} onChange={setSelectedTab}>
          <StyledTabList>
            <StyledTab item={TABS.comments}>Comments</StyledTab>
            <StyledTab item={TABS.console}>
              Console
              {consoleEventsNumber > 0 ? ` (${consoleEventsNumber})` : ''}
            </StyledTab>
            <StyledTab item={TABS.info}>Info</StyledTab>
          </StyledTabList>
          <CommentsTabPanel item={TABS.comments}>
            <CommentsContainer
              ref={commentsContainerRef}
              isEmpty={filteredComments.length === 0}
            >
              {(editMode || isRecording) && (
                <>
                  {filteredComments.length === 0 && (
                    <CommentsEmptyState
                      isCaption={!isAnonymous}
                      isSmall
                      onInviteClick={() => onShowShareModal()}
                    />
                  )}
                  {filteredComments.map(comment => {
                    const {
                      pk: commentId,
                      number,
                      continueButtonBreakPoint,
                    } = comment

                    const isHighlighted =
                      !isVideoPlaying && currentActiveCommentId === commentId

                    const isBlurred =
                      !isVideoPlaying &&
                      !!crossedComments.find(
                        ({ pk: _pk }) => _pk === commentId
                      ) &&
                      currentActiveCommentId !== commentId

                    return (
                      <React.Fragment key={commentId}>
                        <StyledCommentDialog
                          key={commentId}
                          comment={comment}
                          isHighlighted={isHighlighted}
                          isBlurred={isBlurred}
                          isCommentCopyLinkEnabled={!isTemporaryRecording}
                          isJiraEnabled={isJiraEnabled}
                          isTrelloEnabled={isTrelloEnabled}
                          isSlackEnabled={isSlackEnabled}
                          tagText={number}
                          isResolved={comment?.isResolved}
                          currentUserName={userName}
                          showJira={isUserLogged}
                          showTrello={isUserLogged}
                          showSlack={isUserLogged}
                          onCommentClick={() => {
                            onCommentClick({
                              videoPositionSecs: comment?.videoPositionSecs,
                              commentId: comment?.pk,
                            })
                          }}
                          onCopyLink={() => showCopySnackbar()}
                          onReplyFocus={() => {
                            if (isAnonymous) {
                              setRegistrationModalVisible(true)
                              onAnonymourReplyFocus({ commentId })
                            }
                          }}
                          onCreateJiraIssue={({
                            description,
                            resourceId,
                            resourceUrl,
                          }) => {
                            if (!isJiraEnabled) {
                              onShowUserSettings()
                              return
                            }
                            setJiraCreateIssueModalVisible(true)
                            setJiraIssueDescription(description)
                            setJiraIssueResourceId(resourceId)
                            setJiraIssueResourceUrl(resourceUrl)
                          }}
                          onCreateTrelloCard={({
                            commentId: _commentId,
                            description,
                          }) => {
                            if (!isTrelloEnabled) {
                              onShowUserSettings()
                              return
                            }
                            setTrelloCreateCardModalVisible(true)
                            setTrelloCardCommentId(_commentId)
                            setTrelloCardDescription(description)
                          }}
                          onSendSlackMessage={({
                            commentId: _commentId,
                            replyId: _replyId,
                          }) => {
                            if (!isSlackEnabled) {
                              onShowUserSettings()
                              return
                            }
                            setSlackSendMessageModalVisible(true)
                            setSlackCommentId(_commentId)
                            setSlackReplyId(_replyId)
                          }}
                          onResolveCommentClick={({
                            commentId: _commentId,
                            isResolved,
                          }) => {
                            if (isAnonymous || isTemporaryRecording) {
                              setRegistrationModalVisible(true)
                              return
                            }
                            onResolveCommentClick({
                              commentId: _commentId,
                              isResolved,
                            })
                          }}
                        />
                        {(isHighlighted || isBlurred) &&
                          continueButtonBreakPoint && (
                            <Tippy content="Hit spacebar" arrow>
                              <div>
                                <ContinueButton
                                  icon={PlayIcon}
                                  isPrimary
                                  isStretched
                                  isBasic
                                  onClick={() => setVideoPlaying(true)}
                                >
                                  Continue
                                </ContinueButton>
                              </div>
                            </Tippy>
                          )}
                      </React.Fragment>
                    )
                  })}
                </>
              )}
            </CommentsContainer>
          </CommentsTabPanel>
          <ConsoleTabPanel item={TABS.console}>
            {consoleEventsNumber === 0 && (
              <EmptyStateMobile
                icon={<img src={ConsoleComingSoonEmptyStateSvg} alt="" />}
              >
                <>
                  <ConsoleEmptyStateTitle>
                    No events logged
                  </ConsoleEmptyStateTitle>
                  <ConsoleEmptyStateDescription>
                    The console hasn&#39;t logged any event during the
                    recording.
                  </ConsoleEmptyStateDescription>
                </>
              </EmptyStateMobile>
            )}
            {consoleEventsNumber > 0 && (
              <StyledPageEventsFilter
                onFilterUpdate={items => setPageEventsFilters(items)}
              />
            )}
            <StyledPageEvents
              events={pageEvents}
              filters={pageEventsFilters}
              currentVideoTime={videoCurrentTime}
              onClick={time => {
                setVideoPlaying(false)
                setPlayerDrivenTime(time)
              }}
              onEventsFiltered={filteredEvents =>
                setConsoleEventsNumber(filteredEvents.length)
              }
            />
          </ConsoleTabPanel>
          <InfoTabPanel item={TABS.info}>
            <StyledSessionInfo
              {...(session?.deviceMetaInfo || {})}
              description={session?.description}
              isReadOnly={!isUserOwnSession}
              onDescriptionChange={onUpdateSessionDescription}
            />
          </InfoTabPanel>
        </StyledTabs>
      </RightBox>
      {isRegistrationModalVisible && (
        <RegistrationModal
          onClose={() => setRegistrationModalVisible(false)}
          initWithLogin={initRegistrationModalWithLogin}
        />
      )}
      {isJiraCreateIssueModalVisible && (
        <CreateJiraIssueModal
          isVisible={isJiraCreateIssueModalVisible}
          initialDescription={jiraIssueDescription}
          resourceId={jiraIssueResourceId}
          resourceUrl={jiraIssueResourceUrl}
          sessionId={sessionId}
          onCreate={values =>
            onCreateJiraIssue(values).then(jiraIssue =>
              dispatch(
                addNotification({
                  notificationId: uuidv1(),
                  text: 'Jira issue created',
                  isPersisted: true,
                  callToAction: {
                    type: 'link',
                    link: jiraIssue?.data?.url,
                    text: jiraIssue?.data?.key,
                  },
                })
              )
            )
          }
          onClose={() => setJiraCreateIssueModalVisible(false)}
        />
      )}

      {isTrelloCreateCardModalVisible && (
        <CreateTrelloCardModal
          initialDescription={trelloCardDescription}
          commentId={trelloCardCommentId}
          sessionId={sessionId}
          onClose={() => setTrelloCreateCardModalVisible(false)}
        />
      )}

      {isSlackSendMessageModalVisible && (
        <SendSlackMessageModal
          commentId={slackCommentId}
          replyId={slackReplyId}
          onClose={() => setSlackSendMessageModalVisible(false)}
        />
      )}
    </Wrapper>
  )
}

SessionView.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  deviceScreenSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  initialCommentId: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  onExit: PropTypes.func,
  readOnly: PropTypes.bool,
  theme: PropTypes.shape({}),
  onShowShareModal: PropTypes.func,
  onShowUserSettings: PropTypes.func,
  onSetTemporarySessionTitle: PropTypes.func,
  registrationModalVisibilityToken: PropTypes.string,
  registrationModalWithLoginToken: PropTypes.string,
  registrationModalWithRegisterToken: PropTypes.string,
  recordingId: PropTypes.string,
}

SessionView.defaultProps = {
  deviceScreenSize: { width: 0, height: 0 },
  initialCommentId: null,
  isRecording: false,
  isAnonymous: false,
  onExit: () => {},
  readOnly: true,
  theme: {},
  onShowShareModal: () => {},
  onShowUserSettings: () => {},
  onSetTemporarySessionTitle: () => {},
  registrationModalVisibilityToken: '',
  registrationModalWithLoginToken: '',
  registrationModalWithRegisterToken: '',
  recordingId: null,
}

SessionView.whyDidYouRender = false
export default React.memo(withTheme(withApollo(SessionView)))
