import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { XL } from '@zendeskgarden/react-typography'
import { Button } from '@zendeskgarden/react-buttons'
import { ternary } from '../../../util/utils'
import Flex from '../../atoms/Flex'
import Sheet from '../../atoms/Sheet'
import NewOrganizationExplainer from './NewOrganizationExplainer'
import useOrganization from '../../../store/hooks/useOrganization'

const OrganizationCard = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  border-radius: 8px;
  padding: 16px;
  color: ${prop('theme.colors.primary')};
  background-color: ${ternary('isCurrent')(
    prop('theme.colors.secondary200'),
    'white'
  )};
`

const OrganizationText = styled(Flex)`
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
`

const CurrentOrganization = styled(Flex)`
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  text-transform: uppercase;
  justify-content: flex-end;
  align-self: center;
  font-size: 12px;
  color: ${prop('theme.colors.secondary')};
`

const Title = styled(XL)`
  text-align: center;
  margin-bottom: 16px;
`

const ContentBox = styled(Flex)`
  padding: 16px;
`

const StyledButton = styled(Button)`
  margin-top: 16px;
`

const StyledNewOrganizationExplainer = styled(NewOrganizationExplainer)`
  margin-top: 16px;
`

const OrganizationSheet = ({ isVisible, onClose, onCreate, ...props }) => {
  const {
    organizations,
    currentOrganizationId,
    onSetCurrentOrganization,
  } = useOrganization()

  return (
    <Sheet {...props} isVisible={isVisible} onClose={onClose}>
      <ContentBox>
        <Title>All Workspaces</Title>
        {organizations.map(organization => (
          <OrganizationCard
            key={organization.pk}
            isCurrent={organization.pk === currentOrganizationId}
            onClick={() => {
              onSetCurrentOrganization(organization.pk)
              onClose()
            }}
          >
            <OrganizationText>{organization.title}</OrganizationText>
            {organization.pk === currentOrganizationId && (
              <CurrentOrganization>current</CurrentOrganization>
            )}
          </OrganizationCard>
        ))}
        {organizations.length === 1 && (
          <StyledNewOrganizationExplainer onCreate={onCreate} />
        )}
        {organizations.length > 1 && (
          <StyledButton size={null} onClick={onCreate} isPrimary>
            Create Team Workspace
          </StyledButton>
        )}
      </ContentBox>
    </Sheet>
  )
}

OrganizationSheet.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  isVisible: PropTypes.bool,
}

OrganizationSheet.defaultProps = {
  onClose: () => {},
  onCreate: () => {},
  isVisible: false,
}

export default OrganizationSheet
