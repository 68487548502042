import React from 'react'
import PropTypes from 'prop-types'
import useOrganizationModal from '../OrganizationMembers/useOrganizationMembers'
import OrganizationModalMobile from './OrganizationModalMobile'
import useOrganization from '../../../store/hooks/useOrganization'

const OrganizationModalMobileContainer = ({ ...props }) => {
  const { currentOrganizationId } = useOrganization()
  const hookProps = useOrganizationModal(currentOrganizationId)
  return <OrganizationModalMobile {...props} {...hookProps} />
}

OrganizationModalMobileContainer.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default OrganizationModalMobileContainer
